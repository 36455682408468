import React, { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, Box, Control, Title, Field } from "rbx";

import { useModal, useAuth } from "../../../../../context";
import {
  PageHeader,
  ListSearchInput,
  QrReaderModal,
} from "../../../../../components";
import {
  SINGLE_JOB_QUERY,
  SINGLE_JOB_ORDER_QUERY,
  SINGLE_JOB_ORDER_TEST_QUERY,
} from "../../../../../graphql";
import { customToast as toast } from "../../../../../utils";
import { LabelPrintQuantity } from "../components";

const LabelCreatePage = () => {
  const { setModalOpen } = useModal();

  const client = useApolloClient();
  const { state: authState } = useAuth();

  const [where, setWhere] = useState({});

  const searchJob = async (e) => {
    if (e.key === "Enter" && where.JobID) {
      try {
        const { data: jobsData } = await client.query({
          query: SINGLE_JOB_QUERY,
          variables: {
            where: {
              JobID: parseInt(where.JobID, 10),
            },
          },
        });

        if (jobsData?.findUniqueJobs?.JobID) {
          if (authState?.user?.LabID === jobsData?.findUniqueJobs?.LabID) {
            const win = window.open(
              `/lims/labels/JobID-${jobsData.findUniqueJobs.JobID}`
            );
            win.focus();
          } else {
            toast.error("This job belongs to a different lab");
          }
        } else {
          throw new Error();
        }
      } catch (err) {
        toast.error("Error. Please try a different Job ID");
      }
    }
  };

  const searchJobOrder = async (e) => {
    if (e.key === "Enter" && where.JobOrderID) {
      try {
        const { data: jobOrdersData } = await client.query({
          query: SINGLE_JOB_ORDER_QUERY,
          variables: {
            where: {
              JobOrderID: parseInt(where.JobOrderID, 10),
            },
          },
        });

        if (jobOrdersData?.findUniqueJobOrders?.JobOrderID) {
          if (
            authState?.user?.LabID ===
            jobOrdersData?.findUniqueJobOrders?.Job?.LabID
          ) {
            if (jobOrdersData?.findUniqueJobOrders?.JobOrderTests?.length) {
              const win = window.open(
                `/lims/labels/JobOrderID-${jobOrdersData.findUniqueJobOrders.JobOrderID}`
              );
              win.focus();
            } else {
              toast.error(
                "Error. The entered order doesn't have any Job Order Test related"
              );
            }
          } else {
            toast.error("This order belongs to a different lab");
          }
        } else {
          throw new Error();
        }
      } catch (err) {
        toast.error("Error. Please try a different Job Order ID");
      }
    }
  };

  const searchJobOrderTest = async (e) => {
    if (e.key === "Enter" && where.JobOrderTestID) {
      try {
        const { data: jobOrderTestsData } = await client.query({
          query: SINGLE_JOB_ORDER_TEST_QUERY,
          variables: {
            where: {
              JobOrderTestID: parseInt(where.JobOrderTestID, 10),
            },
          },
        });

        if (jobOrderTestsData?.findUniqueJobOrderTests?.JobOrderTestID) {
          if (
            authState?.user?.LabID ===
            jobOrderTestsData?.findUniqueJobOrderTests?.JobOrder?.Job?.LabID
          ) {
            const win = window.open(
              `/lims/labels/JobOrderTestID-${jobOrderTestsData.findUniqueJobOrderTests.JobOrderTestID}`
            );
            win.focus();
          } else {
            toast.error("This order belongs to a different lab");
          }
        } else {
          throw new Error();
        }
      } catch (err) {
        toast.error("Error. Please try a different Job Order Test ID");
      }
    }
  };

  const searchAndPrintManyJobOrder = async (e) => {
    if (e.key === "Enter" && where.JobOrderID) {
      try {
        const { data: jobOrdersData } = await client.query({
          query: SINGLE_JOB_ORDER_QUERY,
          variables: {
            where: {
              JobOrderID: parseInt(where.JobOrderID, 10),
            },
          },
        });

        if (jobOrdersData?.findUniqueJobOrders?.JobOrderID) {
          if (
            authState?.user?.LabID ===
            jobOrdersData?.findUniqueJobOrders?.Job?.LabID
          ) {
            if (jobOrdersData?.findUniqueJobOrders?.JobOrderTests?.length) {
              setModalOpen(
                true,
                <LabelPrintQuantity
                  JobOrderID={jobOrdersData.findUniqueJobOrders.JobOrderID}
                />
              );
            } else {
              toast.error(
                "Error. The entered order doesn't have any Job Order Test related"
              );
            }
          } else {
            toast.error("This order belongs to a different lab");
          }
        } else {
          throw new Error();
        }
      } catch (err) {
        toast.error("Error. Please try a different Job Order ID");
      }
    }
  };

  const scanCode = (e, prop) => {
    e.preventDefault();
    setModalOpen(
      true,
      <QrReaderModal
        onClose={({ result }) => setWhere({ [prop]: parseInt(result, 10) })}
      />
    );
  };

  return (
    <div>
      <PageHeader title="Print Labels" />
      <div style={{ justifyContent: "center", display: "flex" }}>
        <Box style={{ width: "50%", textAlign: "center" }}>
          <Control style={{ marginBottom: 10 }}>
            <Title subtitle>Create Full Label Set</Title>
            <ListSearchInput
              id="job-search"
              placeholder="Enter Job ID"
              onChange={(name, value) => {
                setWhere({
                  JobID: parseInt(value, 10),
                });
              }}
              onKeyPress={searchJob}
            />
          </Control>
          <Field
            kind="group"
            style={{ justifyContent: "center", marginBottom: 30 }}
          >
            <Control>
              <Button
                color="primary"
                onClick={() => searchJob({ key: "Enter" })}
              >
                Create Job Labels
              </Button>
            </Control>
            <Control>
              <Button onClick={(e) => scanCode(e, "JobID")}>
                Scan QR Code
              </Button>
            </Control>
          </Field>

          <Control style={{ marginBottom: 10 }}>
            <Title subtitle>Create Order Label Set</Title>
            <ListSearchInput
              placeholder="Enter Job Order ID"
              onChange={(name, value) => {
                setWhere({
                  JobOrderID: parseInt(value, 10),
                });
              }}
              onKeyPress={searchJobOrder}
            />
          </Control>
          <Field
            kind="group"
            style={{ justifyContent: "center", marginBottom: 30 }}
          >
            <Control>
              <Button
                color="primary"
                onClick={() => searchJobOrder({ key: "Enter" })}
              >
                Create Order Labels
              </Button>
            </Control>
            <Control>
              <Button onClick={(e) => scanCode(e, "JobOrderID")}>
                Scan QR Code
              </Button>
            </Control>
          </Field>

          <Control style={{ marginBottom: 10 }}>
            <Title subtitle>Create Label For A Test</Title>
            <ListSearchInput
              placeholder="Enter Job Order Test ID"
              onChange={(name, value) => {
                setWhere({
                  JobOrderTestID: parseInt(value, 10),
                });
              }}
              onKeyPress={searchJobOrderTest}
            />
          </Control>
          <Field
            kind="group"
            style={{ justifyContent: "center", marginBottom: 30 }}
          >
            <Control>
              <Button
                color="primary"
                onClick={() => searchJobOrderTest({ key: "Enter" })}
              >
                Create Test Labels
              </Button>
            </Control>
            <Control>
              <Button onClick={(e) => scanCode(e, "JobOrderTestID")}>
                Scan QR Code
              </Button>
            </Control>
          </Field>

          <Control style={{ marginBottom: 10 }}>
            <Title subtitle>Create Order Label</Title>
            <ListSearchInput
              placeholder="Enter Job Order ID"
              onChange={(name, value) => {
                setWhere({
                  JobOrderID: parseInt(value, 10),
                });
              }}
              onKeyPress={searchAndPrintManyJobOrder}
            />
          </Control>
          <Field
            kind="group"
            style={{ justifyContent: "center", marginBottom: 30 }}
          >
            <Control>
              <Button
                color="primary"
                onClick={() => searchAndPrintManyJobOrder({ key: "Enter" })}
              >
                Create Order Label
              </Button>
            </Control>
            <Control>
              <Button onClick={(e) => scanCode(e, "JobOrderID")}>
                Scan QR Code
              </Button>
            </Control>
          </Field>
        </Box>
      </div>
    </div>
  );
};

LabelCreatePage.propTypes = {};

export default LabelCreatePage;
