import React from "react";
import PropTypes from "prop-types";
import { Box } from "rbx";

const AnalyticsBox = ({ title, value, units }) => (
  <div className="analytics-item">
    <div className="box-title">
      <span>{title}</span>
    </div>
    <Box>
      <div className="box-value">
        {value}
        {units && <span>{units}</span>}
      </div>
    </Box>
  </div>
);
AnalyticsBox.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  units: PropTypes.string,
};

AnalyticsBox.defaultProps = {
  units: "",
};

export default AnalyticsBox;
