import React from "react";
import PropTypes from "prop-types";

import { Switch, Route } from "react-router-dom";

import TestListPage from "./TestsList";
import AnalytesListPage from "./Analytes";
import ActionLimitTemplatesListPage from "./ActionLimitTemplates";
import TestCategoriesListPage from "./TestCategories";

const TestRouter = ({ routePermissions }) => (
  <Switch>
    <Route path="/tests/tests-list/:TestID?">
      <TestListPage routePermissions={routePermissions} />
    </Route>
    <Route path="/tests/analytes">
      <AnalytesListPage routePermissions={routePermissions} />
    </Route>
    <Route path="/tests/action-limit-templates">
      <ActionLimitTemplatesListPage routePermissions={routePermissions} />
    </Route>
    <Route path="/tests/test-categories/:TestCategoryID?">
      <TestCategoriesListPage routePermissions={routePermissions} />
    </Route>
  </Switch>
);

TestRouter.propTypes = {
  routePermissions: PropTypes.array,
};

TestRouter.defaultProps = {
  routePermissions: [],
};

export default TestRouter;
