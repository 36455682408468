import { gql } from "@apollo/client";

export const ALL_QC_TARGETS_QUERY = gql`
  query ALL_QC_TARGETS_QUERY(
    $where: QCTargetsWhereInput
    $orderBy: [QCTargetsOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyQCTargets(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      QCTargetID
      QCSampleType {
        Name
      }
      TestCategory {
        Name
      }
      Name
      States
      TargetValues
      CreatedBy
      CreatedDateTime
      ModifiedBy
      ModifiedDateTime
    }
    aggregateQCTargets(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const SINGLE_QC_TARGET_QUERY = gql`
  query SINGLE_QC_TARGET_QUERY($where: QCTargetsWhereUniqueInput!) {
    findUniqueQCTargets(where: $where) {
      QCTargetID
      QCSampleTypeID
      TestCategoryID
      Name
      States
      TargetValues
      CreatedBy
      CreatedDateTime
      ModifiedBy
      ModifiedDateTime
    }
  }
`;
