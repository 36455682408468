/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { useTable, useSortBy, useExpanded } from "react-table";

import { Table as RbxTable } from "rbx";

import "./DataTable.scss";

const Table = ({
  columns,
  data,
  onRowClick,
  initialSortBy,
  onChangeSort,
  renderRowSubComponent,
  isSubRow,
  hiddenColumns,
}) => {
  const tableState = {
    pageIndex: 0,
    sortBy: initialSortBy,
    hiddenColumns,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { ...tableState },
    },
    useSortBy,
    useExpanded
  );

  const renderTableHeaderSort = (column) => {
    if (column.isSorted) {
      return column.isSortedDesc ? " ▼" : " ▲";
    }
    return "";
  };

  useEffect(() => {
    onChangeSort(sortBy);
  }, [onChangeSort, sortBy]);

  return (
    <div
      className="data-table-container"
      style={{ width: isSubRow ? "110%" : "100%" }}
    >
      <div className="table-container">
        <RbxTable {...getTableProps()} bordered fullwidth hoverable narrow>
          <RbxTable.Head>
            {headerGroups.map((headerGroup, i) => (
              <RbxTable.Row
                key={`head-row-${i}`}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column, j) => (
                  <RbxTable.Heading
                    key={`heading-${j}`}
                    className={column.type === "number" ? "right" : "left"}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={
                      typeof column.maxWidth === "string"
                        ? { width: column.maxWidth }
                        : { maxWidth: column.maxWidth || 400 }
                    }
                  >
                    {column.render("Header")}
                    <span>{renderTableHeaderSort(column)}</span>
                  </RbxTable.Heading>
                ))}
              </RbxTable.Row>
            ))}
          </RbxTable.Head>
          <RbxTable.Body {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={`body-row-${i}`}>
                  <RbxTable.Row
                    {...row.getRowProps()}
                    style={
                      row.isExpanded
                        ? { backgroundColor: "rgba(36, 168, 100, 30%)" }
                        : null
                    }
                    onClick={() => onRowClick(row.original)}
                  >
                    {row.cells.map((cell, j) => (
                      <RbxTable.Cell
                        key={`body-cell-${j}`}
                        className={
                          cell.column?.type === "number" ? "right" : "left"
                        }
                        data-title={cell?.column?.Header || ""}
                        style={
                          typeof cell.column?.maxWidth === "string"
                            ? { width: cell.column.maxWidth }
                            : { maxWidth: cell.column?.maxWidth || 400 }
                        }
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </RbxTable.Cell>
                    ))}
                  </RbxTable.Row>
                  {row.isExpanded ? (
                    <RbxTable.Row {...row.getRowProps()}>
                      {renderRowSubComponent({ row })}
                    </RbxTable.Row>
                  ) : null}
                </React.Fragment>
              );
            })}
          </RbxTable.Body>
        </RbxTable>
      </div>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  onRowClick: PropTypes.func,
  initialSortBy: PropTypes.array,
  onChangeSort: PropTypes.func,
  renderRowSubComponent: PropTypes.func,
  isSubRow: PropTypes.bool,
  hiddenColumns: PropTypes.array,
};

Table.defaultProps = {
  columns: [],
  data: [],
  initialSortBy: [],
  onRowClick: () => null,
  onChangeSort: () => null,
  renderRowSubComponent: () => null,
  isSubRow: false,
  hiddenColumns: [],
};
export default Table;
