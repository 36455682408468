import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Title, Column, Control, Button, Label, Input, Field } from "rbx";

import { useAuth } from "../../../../context/AuthContext";
import { FileUploader } from "../../../../components";
import { customToast as toast } from "../../../../utils";
import {
  CREATE_EQUIPMENT_ATTACHMENT_MUTATION,
  UPDATE_EQUIPMENT_ATTACHMENT_MUTATION,
  ALL_EQUIPMENT_ATTACHMENTS_QUERY,
} from "../../../../graphql";

const DEFAULT_STATE = {
  Description: "",
  AttachmentURL: "",
};

const convertInputToVariables = (variables, keys, adding = true) =>
  keys.reduce((acc, curr) => {
    if (variables[curr] !== null || variables[curr] !== undefined) {
      acc[curr] = variables[curr];
    } else {
      acc[curr] = null;
    }

    return acc;
  }, {});

const AddEditEquipmentAttachmentModal = ({
  onComplete,
  EquipmentID,
  Attachment,
}) => {
  const isEdit = !!Object.keys(Attachment).length;

  const [inputs, setInputs] = useState(
    isEdit
      ? {
          Description: Attachment.Description,
        }
      : {
          ...DEFAULT_STATE,
        }
  );

  const [createEquipmentAttachment] = useMutation(
    CREATE_EQUIPMENT_ATTACHMENT_MUTATION
  );
  const [updateEquipmentAttachment] = useMutation(
    UPDATE_EQUIPMENT_ATTACHMENT_MUTATION
  );

  const { state: authState } = useAuth();

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const uploadAttachmentPath = useMemo(() => {
    const date = new Date();
    return `/Files/Equipment/Attachments/${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${EquipmentID}`;
  }, [EquipmentID]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isEdit) {
      try {
        await createEquipmentAttachment({
          variables: {
            data: {
              ...convertInputToVariables(
                inputs,
                Object.keys(DEFAULT_STATE),
                true
              ),
              CreatedBy: authState.user.Username,
              ModifiedBy: authState.user.Username,
              Equipment: {
                connect: {
                  EquipmentID: parseInt(EquipmentID, 10),
                },
              },
            },
          },
          refetchQueries: [
            {
              query: ALL_EQUIPMENT_ATTACHMENTS_QUERY,
              variables: {
                where: {
                  EquipmentID: {
                    equals: parseInt(EquipmentID, 10),
                  },
                },
              },
            },
          ],
        });
        toast.success("Attachment created successfully.");
        onComplete();
      } catch (err) {
        toast.error("Error creating Attachment.");
      }
    } else {
      if (!inputs.Description.length) {
        toast.error("Attachment description can't be empty.");
        return;
      }
      try {
        await updateEquipmentAttachment({
          variables: {
            data: {
              Description: {
                set: inputs.Description,
              },
              ModifiedBy: {
                set: authState.user.Username,
              },
            },
            where: {
              EquipmentAttachmentID: parseInt(
                Attachment.EquipmentAttachmentID,
                10
              ),
            },
          },
          refetchQueries: [
            {
              query: ALL_EQUIPMENT_ATTACHMENTS_QUERY,
              variables: {
                where: {
                  EquipmentID: {
                    equals: parseInt(Attachment.EquipmentID, 10),
                  },
                },
              },
            },
          ],
        });
        toast.success("Attachment updated successfully.");
        onComplete();
      } catch (err) {
        toast.error("Error updating Attachment.");
      }
    }
  };

  const title = isEdit ? "Edit Attachment" : "Create Attachment";
  return (
    <React.Fragment>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>{title}</Title>
        </div>
        <div className="page-head-end">
          <Button.Group>
            <Button size="small" type="button" onClick={() => onComplete()}>
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              form="add-equipment-attachment-form"
              size="small"
              type="submit"
              onClick={onSubmit}
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <form id="add-equipment-attachment-form" onSubmit={onSubmit}>
        <Column.Group multiline>
          <Column size={12}>
            <Control expanded size="small">
              <Label>Description</Label>
              <Input
                required
                maxLength={250}
                name="Description"
                placeholder="Description"
                size="small"
                value={inputs.Description}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Control>
          </Column>
          {!isEdit && (
            <Column size={12}>
              <Field kind="group">
                <Control expanded style={{ width: "100%" }}>
                  <FileUploader
                    label="Attachment"
                    name="AttachmentURL"
                    uploadPath={uploadAttachmentPath}
                    value={inputs?.AttachmentURL}
                    onChange={(name, value) => handleChange(name, value)}
                  />
                </Control>
              </Field>
            </Column>
          )}
        </Column.Group>
      </form>
      <hr />
    </React.Fragment>
  );
};

AddEditEquipmentAttachmentModal.propTypes = {
  onComplete: PropTypes.func,
  EquipmentID: PropTypes.string,
  Attachment: PropTypes.object,
};

AddEditEquipmentAttachmentModal.defaultProps = {
  onComplete: (e) => e,
  EquipmentID: "",
  Attachment: {},
};

export default AddEditEquipmentAttachmentModal;
