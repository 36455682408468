const legacyJobOrders = {
  name: "legacyJobOrders",
  label: "Legacy Job Orders",
  icon: "archive",
  permissions: ["JOBS"],
  active: (location) => {
    const [, p] = location.pathname.split("/");
    return /legacy-orders/.test(p);
  },
  to: "/legacy-orders",
};

export default legacyJobOrders;
