import { gql } from "@apollo/client";

export const ALL_ANALYTES_QUERY = gql`
  query ALL_ANALYTES_QUERY(
    $where: AnalytesWhereInput
    $orderBy: [AnalytesOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyAnalytes(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      AnalyteID
      TestCategoryID
      Name
      Active
      TestCategory {
        TestCategoryID
        Name
        Code
        Description
      }
    }
    aggregateAnalytes(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const SINGLE_ANALYTE_QUERY = gql`
  query SINGLE_ANALYTE_QUERY($where: AnalytesWhereUniqueInput!) {
    findUniqueAnalytes(where: $where) {
      AnalyteID
      TestCategoryID
      Name
      Active
      TestCategory {
        AnalyteID
        TestCategoryID
        Name
        Code
        Description
      }
    }
  }
`;

export const FIRST_ANALYTE_QUERY = gql`
  query FIRST_ANALYTE_QUERY($where: AnalytesWhereInput!) {
    findFirstAnalytes(where: $where) {
      AnalyteID
      TestCategoryID
    }
  }
`;
