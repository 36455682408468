import React, { useState } from "react";
import PropTypes from "prop-types";
import { Column, Field, Input, Label, Icon, Control } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UpdatePassword = ({
  inputs,
  onChange,
  disabled,
  onSubmit,
  formId,
  adding,
  oldPassword,
  setOldPassword,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPassword(!showPassword);
  };

  return (
    <form id={formId} onSubmit={onSubmit}>
      <Column.Group multiline>
        <Column size={12}>
          <Field kind="group">
            <Control expanded size="small">
              <Label>Username</Label>
              <Input
                disabled
                required
                autoComplete="off"
                maxLength={250}
                name="Username"
                placeholder="Username"
                size="small"
                value={inputs.Username}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded size="small">
              <Label>Old Password</Label>
              <Input
                autoComplete="off"
                disabled={disabled}
                maxLength={250}
                name="OldPassword"
                placeholder="Old Password"
                size="small"
                type={showPassword ? "text" : "password"}
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
              <Icon
                className="password-visibility"
                onClick={toggleShowPassword}
              >
                <FontAwesomeIcon icon={showPassword ? "eye" : "eye-slash"} />
              </Icon>
            </Control>
          </Field>
        </Column>
        <Column size={12}>
          <Field kind="group">
            <Control expanded size="small" style={{ width: "33%" }}>
              <Label>New Password</Label>
              <Input
                autoComplete="off"
                disabled={disabled}
                maxLength={250}
                name="Password"
                placeholder="New Password"
                size="small"
                type={showPassword ? "text" : "password"}
                value={inputs.Password || ""}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
              <Icon
                className="password-visibility"
                onClick={toggleShowPassword}
              >
                <FontAwesomeIcon icon={showPassword ? "eye" : "eye-slash"} />
              </Icon>
            </Control>
            <Control expanded size="small" style={{ width: "33%" }}>
              <Label>Confirm New Password</Label>
              <Input
                autoComplete="off"
                disabled={disabled}
                maxLength={250}
                name="ConfirmPassword"
                placeholder="Confirm New Password"
                size="small"
                type={showPassword ? "text" : "password"}
                value={inputs.ConfirmPassword || ""}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
              <Icon
                className="password-visibility"
                onClick={toggleShowPassword}
              >
                <FontAwesomeIcon icon={showPassword ? "eye" : "eye-slash"} />
              </Icon>
              {inputs.Password !== "" &&
                inputs.ConfirmPassword !== "" &&
                inputs.Password !== inputs.ConfirmPassword && (
                  <div className="text-danger">Passwords don&lsquo;t match</div>
                )}
            </Control>
          </Field>
        </Column>
      </Column.Group>
    </form>
  );
};

UpdatePassword.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  formId: PropTypes.string.isRequired,
  oldPassword: PropTypes.string.isRequired,
  setOldPassword: PropTypes.func.isRequired,
  adding: PropTypes.bool,
};

UpdatePassword.defaultProps = {
  onSubmit: () => null,
  disabled: false,
  adding: false,
};

export default UpdatePassword;
