import { gql } from "@apollo/client";

export const UPDATE_JOB_ORDER_MUTATION = gql`
  mutation UPDATE_JOB_ORDER_MUTATION(
    $data: JobOrdersUpdateInput!
    $where: JobOrdersWhereUniqueInput!
  ) {
    updateJobOrders(data: $data, where: $where) {
      JobOrderID
    }
  }
`;

export const UPDATE_JOB_MUTATION = gql`
  mutation UPDATE_JOB_MUTATION(
    $data: JobsUpdateInput!
    $where: JobsWhereUniqueInput!
  ) {
    updateJobs(data: $data, where: $where) {
      JobID
    }
  }
`;

export const CREATE_JOB_AFTER_PAYMENT_MUTATION = gql`
  mutation CREATE_JOB_AFTER_PAYMENT_MUTATION(
    $data: CreateJobAfterPaymentInput!
  ) {
    createJobAfterPayment(data: $data) {
      JobID
      UserID
      LabID
      Total
      Credit
      CouponID
      Discount
      Shipping
      ShippingPrice
      Net
    }
  }
`;

export const SEND_CONTACT_JOB_ORDER_EMAIL_MUTATION = gql`
  mutation SEND_CONTACT_JOB_ORDER_EMAIL_MUTATION($data: SendEmailInput!) {
    sendJobOrderEmailInformation(data: $data)
  }
`;

export const SEND_ORDER_COMPLETE_EMAIL_MUTATION = gql`
  mutation SEND_ORDER_COMPLETE_EMAIL_MUTATION(
    $where: JobOrdersWhereUniqueInput!
  ) {
    sendJobOrderCompleteEmail(where: $where)
  }
`;

export const CANCEL_JOB_MUTATION = gql`
  mutation CANCEL_JOB_MUTATION(
    $data: JobLogsCreateInput!
    $where: JobOrdersWhereInput
  ) {
    cancelJob(data: $data, where: $where)
  }
`;
