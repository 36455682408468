import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { orderBy } from "lodash";

const MMTCInfoSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
  where,
  itemName,
  user,
}) => {
  const MMTCInfo = user?.MMTCInfo;

  const options = MMTCInfo
    ? orderBy(
        Object.keys(MMTCInfo)
          .filter((key) => MMTCInfo[key]?.Item === itemName)
          .map((key) => ({
            name: MMTCInfo[key].Name,
            value: key,
          })),
        [(obj) => obj.name],
        ["asc"]
      )
    : [];

  const indicateRequired = required && (value === "" || value === null);
  const handleChange = (e) => {
    onChange(e.target.name, e.target.value);
  };

  return (
    <Control expanded>
      {label && <Label>{label}</Label>}
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(options) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          required={required}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
            textTransform: "capitalize",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          <Select.Option value="" />
          {Array.isArray(options) &&
            options.map((code) => (
              <Select.Option key={code.name} value={code.name}>
                {code.name}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};
MMTCInfoSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  required: PropTypes.bool,
  where: PropTypes.object,
  itemName: PropTypes.string,
  user: PropTypes.object.isRequired,
};

MMTCInfoSelect.defaultProps = {
  value: "",
  name: "",
  label: "",
  itemName: "",
  size: "small",
  required: false,
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  where: {},
};
export default MMTCInfoSelect;
