import { gql } from "@apollo/client";

export const LIST_SYSTEM_CODES_QUERY = gql`
  query LIST_SYSTEM_CODES_QUERY(
    $where: SystemCodesWhereInput
    $orderBy: [SystemCodesOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManySystemCodes(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      RecId
      CodeId
      CodeDescription
      CodeName
      Category
      Type
      Active
    }
    aggregateSystemCodes(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const ALL_SYSTEM_CODES_QUERY = gql`
  query ALL_SYSTEM_CODES_QUERY(
    $where: SystemCodesWhereInput
    $orderBy: [SystemCodesOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManySystemCodes(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      RecId
      Type
      Category
      CodeId
      CodeDescription
      Category
      CodeName
    }
  }
`;

export const SINGLE_SYSTEM_CODE_QUERY = gql`
  query SINGLE_SYSTEM_CODE_QUERY($where: SystemCodesWhereUniqueInput!) {
    findUniqueSystemCodes(where: $where) {
      RecId
      CodeId
      CodeDescription
      CodeName
      Category
      Type
      Active
    }
  }
`;

export const FIRST_SYSTEM_CODE_QUERY = gql`
  query FIRST_SYSTEM_CODE_QUERY($where: SystemCodesWhereInput) {
    findFirstSystemCodes(where: $where) {
      RecId
      CodeId
      CodeDescription
    }
  }
`;
