import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select, Field } from "rbx";
import { useQuery } from "@apollo/client";
import { orderBy } from "lodash";
import { ALL_TESTS_WITH_QUEUE_COUNT_QUERY } from "../../graphql";

const TestQueueCountSelect = ({
  label,
  size,
  name,
  onChange,
  disabled,
  value,
  required,
  id,
  fullWidth,
  getValue,
  setValue,
  where,
}) => {
  const { data: getTestsData } = useQuery(ALL_TESTS_WITH_QUEUE_COUNT_QUERY, {
    variables: { where },
  });

  const codes = getTestsData?.getTestsWithQueueCount
    ? orderBy(getTestsData.getTestsWithQueueCount, [(obj) => obj.Name], ["asc"])
    : [];

  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value));
  };

  return (
    <Field kind="group">
      <Control expanded size={size}>
        {label && <Label>{label}</Label>}
        <Select.Container fullwidth size={size}>
          <Select
            disabled={disabled}
            id={id}
            name={name}
            required={required}
            style={{
              border: indicateRequired ? "1px #e63362 solid" : "",
              borderRadius: "4px",
              boxShadow: indicateRequired
                ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
                : "",
            }}
            value={getValue(value)}
            onChange={handleChange}
          >
            <Select.Option value="" />
            {codes.map((item) => (
              <Select.Option key={item.TestID} value={item.TestID}>
                {item.Name} {item.sampleCount ? `(${item.sampleCount})` : ""}
              </Select.Option>
            ))}
          </Select>
        </Select.Container>
      </Control>
    </Field>
  );
};

TestQueueCountSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  where: PropTypes.string,
};

TestQueueCountSelect.defaultProps = {
  size: "small",
  fullWidth: false,
  disabled: false,
  value: "",
  name: "",
  label: "",
  required: false,
  id: null,
  where: "",
  getValue: (x) => x,
  setValue: (x) => x,
  onChange: () => null,
};

export default TestQueueCountSelect;
