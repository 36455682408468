/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const DELETE_MANY_ACTION_LIMIT_TEMPLATE_PRODUCT_CATEGORIES = gql`
  mutation DELETE_MANY_ACTION_LIMIT_TEMPLATE_PRODUCT_CATEGORIES(
    $where: ActionLimitTemplateProductCategoriesWhereInput!
  ) {
    deleteManyActionLimitTemplateProductCategories(where: $where) {
      count
    }
  }
`;
