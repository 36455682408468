/* eslint-disable max-lines-per-function */
import React from "react";
import PropTypes from "prop-types";
import { Field, Label, Control } from "rbx";
import BooleanInput from "../../../../../../components/BooleanInput";
import { SystemCodeSelect } from "../../../../../../components";

const SystemCodeSelectWithMemory = ({
  inputs,
  label,
  name,
  onChange,
  placeholder,
  maxLength,
  disabled,
  expanded,
  children,
  required,
  where,
}) => (
  <Control expanded={expanded} size="small">
    <Label style={{ height: 19 }}>{label}</Label>
    <Field.Body>
      <Field kind="addons">
        <Control>
          <BooleanInput
            disabled={disabled}
            name={`${name}Changed`}
            value={inputs[`${name}Changed`]}
            onChange={(nameChanged, checked) =>
              onChange(nameChanged, checked ? 1 : 0)
            }
          />
        </Control>
        <Control expanded={expanded}>
          <SystemCodeSelect
            disabled={!inputs[`${name}Changed`] || false || disabled}
            name={name}
            placeholder={placeholder}
            size="small"
            value={
              inputs[`${name}Changed`]
                ? inputs.OverrideData[name]
                : inputs[name] || ""
            }
            where={where}
            onChange={onChange}
          />
        </Control>
      </Field>
    </Field.Body>
  </Control>
);
SystemCodeSelectWithMemory.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  where: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  children: PropTypes.node,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
};

SystemCodeSelectWithMemory.defaultProps = {
  disabled: false,
  required: false,
  expanded: true,
  maxLength: 249,
  label: "",
  placeholder: "",
  children: null,
};

export default SystemCodeSelectWithMemory;
