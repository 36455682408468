import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useApolloClient } from "@apollo/client";
import PropTypes from "prop-types";
import {
  PageHeader,
  DataTable,
  IconButton,
  Confirmation,
} from "../../../../components";
import { useModal } from "../../../../context/ModalContext";
import { TestCategoryModal, TestCategoryFilters } from "../components";

import {
  LIST_TEST_CATEGORIES_QUERY,
  DELETE_TEST_CATEGORY_MUTATION,
  FIRST_ANALYTE_QUERY,
} from "../../../../graphql";
import { customToast as toast } from "../../../../utils";
import COLUMNS from "./columns";

const INITIAL_WHERE = {};

const TestCategoriesListPage = ({ routePermissions }) => {
  const history = useHistory();
  const client = useApolloClient();
  const [modalOpened, setModalOpened] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [where, setWhere] = useState(INITIAL_WHERE);

  const { TestCategoryID } = useParams();

  const { setModalOpen } = useModal();
  const orderBy = [{ id: "Name", desc: false }];

  const [deleteTestCategory] = useMutation(DELETE_TEST_CATEGORY_MUTATION);

  const [canUpdate, setCanUpdate] = useState(true);

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  const handleRowClick = (row) => {
    if (canUpdate) {
      setModalOpened(false);
      setModalOpen(
        true,
        <TestCategoryModal
          TestCategoryID={row.TestCategoryID}
          onComplete={() => {
            setModalOpen(false);
          }}
        />
      );
    }
  };

  const handleCreateTestCategory = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModalOpen(
      true,
      <TestCategoryModal
        onComplete={() => {
          setModalOpen(false);
        }}
      />
    );
  };

  useEffect(() => {
    if (TestCategoryID && !modalOpened) {
      setModalOpened(true);
      setModalOpen(
        true,
        <TestCategoryModal
          TestCategoryID={TestCategoryID}
          onComplete={() => {
            setModalOpen(false);
          }}
        />
      );
    }
  }, [TestCategoryID, history, modalOpened, setModalOpen]);

  const handleFilterChange = (name, value) => {
    setWhere((prev) => {
      if (value) {
        return {
          ...prev,
          [name]: value,
        };
      }
      return Object.keys(prev)
        .filter((x) => x !== name)
        .reduce((a, c) => ({ ...a, [c]: prev[c] }), {});
    });
  };

  const handleDelete = async (row) => {
    const { data: relatedAnalyte } = await client.query({
      query: FIRST_ANALYTE_QUERY,
      variables: {
        where: {
          TestCategoryID: {
            equals: parseInt(row.TestCategoryID, 10),
          },
        },
      },
    });
    if (relatedAnalyte?.findFirstAnalytes) {
      toast.error(
        "This Test Category can't be deleted because there's related Analytes"
      );
    } else {
      const performDelete = async () => {
        await deleteTestCategory({
          variables: {
            where: {
              TestCategoryID: parseInt(row.TestCategoryID, 10),
            },
          },
          refetchQueries: [
            {
              query: LIST_TEST_CATEGORIES_QUERY,
              variables: {
                where: {},
              },
            },
          ],
        });
        setModalOpen(false);
        toast.success("Test Category deleted successfully");
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to remove this Test Category?"
          onCancel={() => setModalOpen(false, "")}
          onConfirm={performDelete}
        />
      );
    }
  };

  return (
    <div className="test-categories-list-page">
      <PageHeader title="Test Categories">
        <IconButton
          icon="filter"
          onClick={(e) => {
            e.preventDefault();
            setShowFilters((prev) => !prev);
          }}
        />
        {canUpdate && (
          <IconButton icon="plus" onClick={handleCreateTestCategory} />
        )}
      </PageHeader>
      {showFilters && (
        <TestCategoryFilters
          inputs={where}
          onChange={handleFilterChange}
          onReset={() => setWhere(INITIAL_WHERE)}
        />
      )}
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateTestCategories"
        columns={COLUMNS(handleDelete, canUpdate)}
        name="findManyTestCategories"
        orderBy={orderBy}
        query={LIST_TEST_CATEGORIES_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

TestCategoriesListPage.propTypes = {
  routePermissions: PropTypes.array,
};

TestCategoriesListPage.defaultProps = {
  routePermissions: [],
};

export default TestCategoriesListPage;
