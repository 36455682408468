const equipmentRoutes = {
  name: "equipment",
  label: "Equipment",
  icon: "cogs",
  permissions: ["EQPMNT"],
  active: (location) => {
    const [, p] = location.pathname.split("/");
    return /equipment/.test(p);
  },
  to: "/equipment",
};

export default equipmentRoutes;
