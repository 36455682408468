/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button, Column, Control, Label, Input } from "rbx";
import { BooleanInput } from "../../../../../../components";

const RejectReasonModal = ({ onComplete, onCancel, displayMany, results }) => {
  const [reasonForChange, setReasonForChange] = useState("");
  const [sameReasonForAll, setSameReasonForAll] = useState(false);

  useEffect(() => {
    if (displayMany) {
      setReasonForChange(
        displayMany.map((item, index) => ({
          SessionID: item.SessionID,
          value: "",
        }))
      );
    }
  }, [displayMany]);

  useEffect(() => {
    if (sameReasonForAll && displayMany) {
      setReasonForChange([""]);
    } else if (displayMany) {
      setReasonForChange(
        displayMany.map((item, index) => ({
          SessionID: item.SessionID,
          value: "",
        }))
      );
    }
  }, [displayMany, sameReasonForAll]);

  const handleChange = (value, index = undefined) => {
    if (index || index === 0) {
      setReasonForChange((prev) =>
        prev.map((item, i) => (i === index ? { ...item, value } : item))
      );
    } else {
      setReasonForChange(value);
    }
  };

  const handleComplete = (e) => {
    e.preventDefault();
    if (displayMany && !sameReasonForAll) {
      const resultsToReturn = results.map((result, i) => {
        if (
          (result.Status === "Rejected" || result.Status === "Restored") &&
          result.needSave
        ) {
          const rejectNote = reasonForChange.find(
            (note) => note.SessionID === result.SessionID
          );
          return { ...result, rejectNote: rejectNote.value };
        }
        return result;
      });
      onComplete(resultsToReturn);
    } else if (displayMany && sameReasonForAll) {
      const resultsToReturn = results.map((result) =>
        (result.Status === "Rejected" || result.Status === "Restored") &&
        result.needSave
          ? { ...result, rejectNote: reasonForChange[0].value }
          : result
      );
      onComplete(resultsToReturn);
    } else if (!displayMany) {
      const resultsToReturn = results.map((result) =>
        (result.Status === "Rejected" || result.Status === "Restored") &&
        result.needSave
          ? { ...result, rejectNote: reasonForChange }
          : result
      );
      onComplete(resultsToReturn);
    }
  };

  return (
    <div>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Reason for Change</Title>
        </div>
        <div className="page-head-end">
          <Button.Group>
            <Button
              color="primary"
              size="small"
              type="button"
              onClick={() => onCancel()}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={!reasonForChange.length}
              form="add-referrer-form"
              size="small"
              type="submit"
              onClick={handleComplete}
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      {!displayMany ? (
        <Column.Group multiline>
          <Column desktop={{ size: 12 }} tablet={{ size: 12 }}>
            <Control expanded size="small">
              <Label>Reason For Change</Label>
              <Input
                required
                maxLength={250}
                name="ReasonForChange"
                placeholder="Reason For Change"
                size="small"
                value={reasonForChange}
                onChange={(e) => handleChange(e.target.value)}
              />
            </Control>
          </Column>
        </Column.Group>
      ) : (
        <React.Fragment>
          <div style={{ justifyContent: "end", display: "flex" }}>
            <BooleanInput
              label={`Use Same Reason for ${displayMany.length} Results`}
              value={sameReasonForAll}
              onChange={(name, value) => setSameReasonForAll(value)}
            />
          </div>
          {Array.isArray(reasonForChange) &&
            reasonForChange?.map((item, index) => (
              <Control
                key={`${displayMany[index]?.SessionID}-${index}`}
                expanded
                size="small"
              >
                <Label>
                  {sameReasonForAll
                    ? `${
                        displayMany[index]?.Status === "Rejected"
                          ? "Reject"
                          : "Restore"
                      } Reason`
                    : `${
                        displayMany[index]?.Status === "Rejected"
                          ? "Reject"
                          : "Restore"
                      } Reason for Session ${displayMany[index]?.SessionID}`}
                </Label>
                <Input
                  required
                  maxLength={250}
                  name="ReasonForChange"
                  placeholder="Reason For Change"
                  size="small"
                  value={item.value}
                  onChange={(e) => handleChange(e.target.value, index)}
                />
              </Control>
            ))}
        </React.Fragment>
      )}
    </div>
  );
};

RejectReasonModal.propTypes = {
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  displayMany: PropTypes.array,
  results: PropTypes.object.isRequired,
};

RejectReasonModal.defaultProps = {
  onComplete: (e) => e,
  onCancel: (e) => e,
  displayMany: null,
};

export default RejectReasonModal;
