import React, { useState } from "react";
import { Control, Title, Box, Field, Button } from "rbx";
import { useApolloClient } from "@apollo/client";
import { useModal, useAuth } from "../../../../../context";
import {
  SINGLE_JOB_ORDERS_QUERY,
  FIND_FIRST_JOB_ORDER_TEST_SAMPLE_QUERY,
} from "../../../../../graphql";
import {
  Loader,
  PageHeader,
  ListSearchInput,
  QrReaderModal,
} from "../../../../../components";
import { customToast as toast } from "../../../../../utils";
import { MoistureWaterFilthModal } from "../components";

const MoistureWaterFilthSearchPage = () => {
  const client = useApolloClient();
  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();

  const [loading, setLoading] = useState(false);
  const [where, setWhere] = useState({});

  const openMoistureWaterFilthModal = (jobOrderData) => {
    const moistureTests = jobOrderData.JobOrderTests?.filter(
      (item) => item.Test.Code === "MOIST"
    ).reduce((acc, curr) => {
      const samples = curr.JobOrderTestSamples.map((sample) => ({
        ...sample,
        OriginalStatus: sample.Status,
      }));
      return [...acc, ...samples];
    }, []);
    const waterTests = jobOrderData.JobOrderTests?.filter(
      (item) => item.Test.Code === "WATER"
    ).reduce((acc, curr) => {
      const samples = curr.JobOrderTestSamples.map((sample) => ({
        ...sample,
        OriginalStatus: sample.Status,
      }));
      return [...acc, ...samples];
    }, []);
    const filthTests = jobOrderData.JobOrderTests?.filter(
      (item) => item.Test.Code === "FORM"
    ).reduce((acc, curr) => {
      const samples = curr.JobOrderTestSamples.map((sample) => ({
        ...sample,
        OriginalStatus: sample.Status,
      }));
      return [...acc, ...samples];
    }, []);

    const types = {};

    if (Array.isArray(moistureTests) && moistureTests.length) {
      types.Moisture = moistureTests;
    }
    if (Array.isArray(waterTests) && waterTests.length) {
      types.Water = waterTests;
    }
    if (Array.isArray(filthTests) && filthTests.length) {
      types.Filth = filthTests;
    }

    if (Object.keys(types).length) {
      setModalOpen(
        true,
        <MoistureWaterFilthModal
          JobOrderID={parseInt(jobOrderData.JobOrderID, 10)}
          types={types}
          onComplete={() => setModalOpen(false)}
        />
      );
    } else {
      toast.error(
        `Order ${jobOrderData.JobOrderID} does not require Moisture, Water, or Filth`
      );
    }
  };

  const searchJobOrder = async (e) => {
    if (e.key === "Enter" && where.JobOrderID) {
      try {
        setLoading(true);
        if (where.JobOrderID.includes("_")) {
          const { data: jobOrderTestData } = await client.query({
            query: FIND_FIRST_JOB_ORDER_TEST_SAMPLE_QUERY,
            variables: {
              where: {
                SessionID: {
                  equals: where.JobOrderID,
                },
              },
            },
          });
          if (jobOrderTestData?.findFirstJobOrderTestSamples) {
            const { data: jobOrderData } = await client.query({
              query: SINGLE_JOB_ORDERS_QUERY,
              variables: {
                where: {
                  JobOrderID: parseInt(
                    jobOrderTestData.findFirstJobOrderTestSamples.JobOrderID,
                    10
                  ),
                },
              },
            });
            if (
              authState?.user?.LabID ===
              jobOrderData?.findUniqueJobOrders?.Job?.LabID
            ) {
              if (jobOrderData?.findUniqueJobOrders) {
                openMoistureWaterFilthModal(jobOrderData.findUniqueJobOrders);
              } else {
                toast.error("There's no Order with entered information");
              }
            } else {
              toast.error("This order belongs to a different lab");
            }
          }
        } else {
          const { data: jobOrderData } = await client.query({
            query: SINGLE_JOB_ORDERS_QUERY,
            variables: {
              where: {
                JobOrderID: parseInt(where.JobOrderID, 10),
              },
            },
          });
          if (
            authState?.user?.LabID ===
            jobOrderData?.findUniqueJobOrders?.Job?.LabID
          ) {
            if (jobOrderData?.findUniqueJobOrders) {
              openMoistureWaterFilthModal(jobOrderData.findUniqueJobOrders);
            } else {
              toast.error("There's no Order with entered information");
            }
          } else {
            toast.error("This order belongs to a different lab");
          }
        }
      } catch (err) {
        toast.error("Error trying to search");
      } finally {
        setLoading(false);
      }
    }
  };

  const scanCode = (e) => {
    e.preventDefault();
    setModalOpen(
      true,
      <QrReaderModal
        onClose={({ result }) => setWhere({ JobOrderID: parseInt(result, 10) })}
      />
    );
  };

  if (loading) return <Loader />;

  return (
    <div className="per-unit-calculator-page">
      <PageHeader title="Moisture, Water, Filth & Foreign" />
      <div style={{ justifyContent: "center", display: "flex" }}>
        <Box style={{ width: "50%", textAlign: "center" }}>
          <Control style={{ marginBottom: 10 }}>
            <Title subtitle>Search by Job Order ID or Session ID</Title>
            <ListSearchInput
              placeholder="Enter Job Order ID or Session ID..."
              onChange={(name, value) => {
                setWhere({
                  JobOrderID: value,
                });
              }}
              onKeyPress={searchJobOrder}
            />
          </Control>
          <Field kind="group" style={{ justifyContent: "center" }}>
            <Control>
              <Button
                color="primary"
                onClick={() => searchJobOrder({ key: "Enter" })}
              >
                Search
              </Button>
            </Control>
            <Control>
              <Button onClick={scanCode}>Scan QR Code</Button>
            </Control>
          </Field>
        </Box>
      </div>
    </div>
  );
};

export default MoistureWaterFilthSearchPage;
