/* eslint-disable react/prop-types */
import React from "react";
import { Generic, Icon, Tag, Button } from "rbx";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { RePrepQtySelect } from "../../../../../../components";
import { STATUS_COLORS } from "../../../../../../constants";

const DEFAULT_COLUMNS = [
  {
    Header: "Session ID",
    id: "SessionID",
    accessor: "SessionID",
    Cell: ({ cell, row }) =>
      row.original.IncludesFailedResult ? (
        <span>
          {cell.value}
          <Icon
            className="has-tooltip-right has-tooltip-arrow has-tooltip-danger"
            color="danger"
            data-tooltip="Includes Failed Result"
            size="small"
          >
            <FontAwesomeIcon icon="exclamation" />
          </Icon>
        </span>
      ) : (
        cell.value
      ),
  },
  {
    Header: "Test Category",
    id: "category",
    accessor: "JobOrderTest",
    Cell: ({ cell, row }) =>
      row.original?.Batches
        ? row.original.Batches.TestCategory.Name
        : cell.value?.Test?.TestTestCategories?.map(
            (ttc, i) =>
              `${ttc.TestCategory?.Name}${
                i !== cell.value?.Test?.TestTestCategories.length - 1
                  ? ", "
                  : ""
              }`
          ),
  },
  {
    Header: "Test Name",
    id: "testName",
    accessor: "JobOrderTest",
    Cell: ({ cell, row }) => cell.value?.Test?.Name || "",
  },
  {
    Header: "Batch ID",
    id: "BatchID",
    accessor: "BatchID",
    Cell: ({ cell }) => (
      <Link to={`/lims/batch-records/${cell.value}/batch-info`}>
        {cell.value}
      </Link>
    ),
  },
  {
    Header: "Prep No.",
    id: "PrepNo",
    accessor: "PrepNo",
  },
  {
    Header: "Result Status",
    id: "CodeDescription",
    accessor: "ResultStatus.CodeDescription",
    Cell: ({ cell }) =>
      cell.value ? (
        <Tag color={STATUS_COLORS.ResultStatus[cell.value]}>{cell.value}</Tag>
      ) : (
        <Tag color={STATUS_COLORS.ResultStatus.New}>New Sample</Tag>
      ),
  },
];

export const generateColumns = (
  handleClickReprep,
  handleImportantChange,
  handleDelete,
  disabled,
  canDeleteSamples,
  canArchiveTests
) => [
  ...DEFAULT_COLUMNS,
  {
    Header: "Important",
    id: "Important",
    accessor: "Important",
    Cell: ({ cell, row }) => (
      <Button
        color="white"
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          handleImportantChange(!cell.value, row.original);
        }}
      >
        <Icon color={cell.value ? "primary" : "grey"}>
          <FontAwesomeIcon icon="flag" />
        </Icon>
      </Button>
    ),
  },
  {
    Header: "Actions",
    id: "Replicate",
    accessor: "Result",
    Cell: ({ cell, row }) => {
      const { original: jobOrderInfo } = row;

      return (
        <RePrepQtySelect
          disabled={
            disabled || jobOrderInfo?.JobOrderTest?.Test?.Code === "FORM"
          }
          handleClickReprep={handleClickReprep}
          row={row}
        />
      );
    },
  },
  {
    Header: "",
    id: "delete",
    Cell: (cell) =>
      cell.row.original.PrepNo !==
        cell.row.original.JobOrderTest?.ResultToDisplay && canDeleteSamples ? (
        <Generic
          style={{ textAlign: "center" }}
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(cell.row.original);
          }}
        >
          <Icon color="danger">
            <FontAwesomeIcon icon="times" />
          </Icon>
        </Generic>
      ) : null,
  },
];

export default DEFAULT_COLUMNS;
