import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const DELETE_MANY_QC_SAMPLES = gql`
  mutation DELETE_MANY_QC_SAMPLES($where: QCSamplesWhereInput!) {
    deleteManyQCSamples(where: $where) {
      count
    }
  }
`;

export const UPDATE_QC_SAMPLE_MUTATION = gql`
  mutation UPDATE_QC_SAMPLE_MUTATION(
    $data: QCSamplesUpdateInput!
    $where: QCSamplesWhereUniqueInput!
  ) {
    updateQCSamples(data: $data, where: $where) {
      QCSampleID
    }
  }
`;

