import { format } from "date-fns";
import { Generic, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DEFAULT_COLUMNS = [
  {
    Header: "Group ID",
    id: "GroupID",
    accessor: "GroupID",
  },
  {
    Header: "Name",
    id: "Name",
    accessor: "Name",
  },
  {
    Header: "Description",
    id: "Description",
    accessor: "Description",
  },
  {
    Header: "Permissions",
    id: "Permissions",
    accessor: "UserGroupPermissions",
    Cell: ({ cell }) =>
      cell?.value
        ?.map((x) => x?.Permission?.Name)
        .filter(Boolean)
        .join(", "),
    disableSortBy: true,
  },
  {
    Header: "Created On",
    id: "CreatedDateTime",
    accessor: "CreatedDateTime",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM/dd/yyyy hh:mm a") : "--",
  },
  {
    Header: "Modified On",
    id: "ModifiedDateTime",
    accessor: "ModifiedDateTime",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM/dd/yyyy hh:mm a") : "--",
  },
  {
    Header: "Created By",
    id: "CreatedBy",
    accessor: "CreatedBy",
  },
  {
    Header: "Modified By",
    id: "ModifiedBy",
    accessor: "ModifiedBy",
  },
];

export const generateColumns = (handleDelete, canUpdate) =>
  canUpdate
    ? [
        ...DEFAULT_COLUMNS,
        {
          Header: "",
          id: "delete",
          Cell: (cell) => (
            <Generic
              style={{ textAlign: "center" }}
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(cell.row.original);
              }}
            >
              <Icon color="danger">
                <FontAwesomeIcon icon="times" />
              </Icon>
            </Generic>
          ),
        },
      ]
    : [...DEFAULT_COLUMNS];

export default DEFAULT_COLUMNS;
