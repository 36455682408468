import { microCfuConversionConstants } from "../../../../../../utils/batchConstants";
import { productCategoryMatrix } from "../../../../../../constants";

// build the string that is displayed in the calculated results section
const buildCalculatedFieldString = (
  sample,
  rawResult,
  calculatedResult,
  actionLimitTemplateAnalyte,
  testCategoryCode,
  analyteName,
  batchResultUnits
) => {
  let stringResult = "";

  // if results exist, build string using action limit template values
  if (!!rawResult && !!calculatedResult) {
    // MICRO
    if (testCategoryCode.includes("MICRO")) {
      const units = batchResultUnits?.calculatedUnits || "";
      // MICRO MG INC no qc means not detected, numerical value less than threshold means detected
      if (testCategoryCode === "MICRO MG INC") {
        if (calculatedResult.toLowerCase().trim() === "no cq") {
          return "Not Detected";
        }
        if (parseFloat(calculatedResult)) {
          if (sample?.QCSampleType?.Name === "Positive Control") {
            return parseFloat(calculatedResult) <= 35
              ? "Detected"
              : "Not Detected";
          }
          if (sample?.QCSampleType?.Name === "Negative Control") {
            return "Detected";
          }
          return parseFloat(calculatedResult) <= 40
            ? "Detected"
            : "Not Detected";
        }
      }
      // MICRO MG ENUM no qc means not detected
      if (testCategoryCode === "MICRO MG ENUM") {
        if (calculatedResult.toLowerCase().trim() === "no cq") {
          return "Not Detected";
        }
        // PC and NC numerical value needs to reference threshold
        if (sample?.QCSampleType?.Name === "Positive Control") {
          return parseFloat(calculatedResult) <= 35
            ? "Detected"
            : "Not Detected";
        }
        if (sample?.QCSampleType?.Name === "Negative Control") {
          if (analyteName === "total aerobic count") {
            const productCategory =
              sample?.JobOrder?.OverrideDataCodeDescriptions &&
              JSON.parse(sample?.JobOrder?.OverrideDataCodeDescriptions)
                ?.ProductCategory
                ? JSON.parse(sample?.JobOrder?.OverrideDataCodeDescriptions)
                    ?.ProductCategory
                : sample?.JobOrder?.Category?.CodeDescription || null;

            const matrix = Object.keys(productCategoryMatrix).find((key) =>
              productCategoryMatrix[key].includes(productCategory)
            );

            const cfuConversionObject = microCfuConversionConstants.find(
              (obj) => obj.matrix === matrix && obj.analyte === analyteName
            );

            return parseFloat(calculatedResult) >
              cfuConversionObject?.negativeThreshold
              ? "Not Detected"
              : "Detected";
          }
          return "Detected";
        }
        // otherwise numerical value needs to reference action limit template
        if (parseFloat(calculatedResult)) {
          if (parseFloat(calculatedResult) < actionLimitTemplateAnalyte.LOD) {
            return `<${actionLimitTemplateAnalyte.LOD} ${units}`;
          }
          if (
            parseFloat(calculatedResult) >= actionLimitTemplateAnalyte.LOD &&
            parseFloat(calculatedResult) <
              actionLimitTemplateAnalyte.AllowableCriteria
          ) {
            return `${calculatedResult} ${units}`;
          }
          if (
            parseFloat(calculatedResult) >= actionLimitTemplateAnalyte.LOD &&
            parseFloat(calculatedResult) <=
              actionLimitTemplateAnalyte.UpperLimit
          ) {
            return `${calculatedResult} ${units}`;
          }
          if (
            parseFloat(calculatedResult) > actionLimitTemplateAnalyte.UpperLimit
          ) {
            return `>${actionLimitTemplateAnalyte.UpperLimit} ${units}`;
          }
        }
      }
      // MICRO BMX GENUP convert absence / resence to detected / not detected
      if (testCategoryCode === "MICRO BMX GENUP") {
        if (calculatedResult.toLowerCase().trim() === "presumed absence") {
          return "Not Detected";
        }
        if (calculatedResult.toLowerCase().trim() === "presumed presence") {
          return "Detected";
        }
      }
      // other MICRO display calculated result
      if (testCategoryCode === "MICRO BMX TEMPO") {
        if (
          parseFloat(calculatedResult) &&
          parseFloat(calculatedResult) > 4900000
        ) {
          return `>4.9mil ${units}`;
        }
        return `${calculatedResult} ${units}`;
      }
      if (testCategoryCode === "MICRO 3M ENUM") {
        if (
          parseFloat(calculatedResult) < actionLimitTemplateAnalyte.LOD ||
          parseFloat(calculatedResult) === 0 ||
          calculatedResult.toLowerCase().trim() === "no cq"
        ) {
          return "Not Detected";
        }
        if (parseFloat(calculatedResult) >= actionLimitTemplateAnalyte.LOD) {
          return `${calculatedResult} ${units}`;
        }
      }
      return "";
    }
    // NON MICRO

    // most samples refer to the InSampleLOD/InSampleLOQ and use calculatedUnits
    let lodToCompare = parseFloat(actionLimitTemplateAnalyte?.InSampleLOD);
    let loqToCompare = parseFloat(actionLimitTemplateAnalyte?.InSampleLOQ);
    let unitType = "calculatedUnits";

    // LC PEST MYCO - toxin analytes use a different calculated unit
    if (testCategoryCode.includes("MYCO") && analyteName.includes("atoxin")) {
      unitType = "toxinCalculatedUnits";
    }

    // QCSamples except SD refer to the LOD/LOQ (InVile) and use rawUnits since no calculation is done for calculated value
    if (sample?.QCSample && sample?.QCSampleType?.Name !== "Sample Duplicate") {
      lodToCompare = parseFloat(actionLimitTemplateAnalyte?.LOD);
      loqToCompare = parseFloat(actionLimitTemplateAnalyte?.LOQ);
      unitType = "rawUnits";
    }

    // compare calculated result to action limit values to build string
    if (calculatedResult < lodToCompare) {
      stringResult = "ND";
    } else if (
      calculatedResult >= lodToCompare &&
      calculatedResult < loqToCompare
    ) {
      stringResult = "<LOQ";
    } else if (calculatedResult >= loqToCompare) {
      const units = batchResultUnits?.[unitType] || "";
      stringResult = calculatedResult.toString().concat(" ", units);
    }
  }
  // if no raw result but the sample has results imported, display NT (not tested)
  // else if (
  //   !rawResult &&
  //   !calculatedResult &&
  //   sample?.AnalyteResults &&
  //   Object.keys(sample?.AnalyteResults)?.length
  // ) {
  //   stringResult = "NT";
  // }

  return stringResult;
};

export default buildCalculatedFieldString;
