import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const ALL_EQUIPMENT_TESTS_QUERY = gql`
  query ALL_EQUIPMENT_TESTS_QUERY(
    $where: EquipmentTestsWhereInput
    $orderBy: [EquipmentTestsOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyEquipmentTests(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      EquipmentTestID
      EquipmentID
      TestID
      CreatedDateTime
      ModifiedDateTime
      CreatedBy
      ModifiedBy
    }
  }
`;
