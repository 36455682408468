import { toast } from "react-toastify";
import QRCode from "qrcode";
import valid from "card-validator";
import { format, isWeekend, add, compareAsc, startOfDay } from "date-fns";
import { Label, Image, Control } from "rbx";
import { GSLHolidays } from "../constants";

export { default as STATES } from "./states";

export { default as Parser } from "./parser";

export const customToast = {
  success: (message) => {
    toast.success(message, { autoClose: 1000, pauseOnHover: false });
  },
  error: (message) => {
    toast.error(message, { autoClose: 4000 });
  },
  warning: (message) => {
    toast.warning(message, { autoClose: 4000 });
  },
};

export const convertInputToVariables = (variables, keys) =>
  keys.reduce((acc, curr) => {
    if (variables[curr]) {
      acc[curr] = variables[curr];
    } else {
      acc[curr] = null;
    }
    return acc;
  }, {});

export const formatCurrency = (n) => {
  if (n) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(n);
  }
  return "$0.00";
};

export const unmaskCurrency = (maskedValue = "") =>
  parseFloat(maskedValue.replace(/\D/g, "") || 0, 10) / 100;

export const debounce = (fn, ms = 0) => {
  let timeoutId;
  return function foo(...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const flattenObject = (obj, prefix = "") =>
  Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? `${prefix}.` : "";
    if (obj[k] !== null && typeof obj[k] === "object")
      Object.assign(acc, flattenObject(obj[k], pre + k));
    else acc[pre + k] = obj[k];
    return acc;
  }, {});

export const parseQueryResultWithColumns = (x, columns) => {
  const obj = flattenObject(x);
  return columns.reduce((acc, curr) => {
    if (
      obj[`${curr.accessor}`] === null ||
      typeof obj[`${curr.accessor}`] === "undefined" ||
      (typeof obj[`${curr.accessor}`] === "string" &&
        !obj[`${curr.accessor}`].length)
    ) {
      acc[curr.Header] = "";
    } else if (curr.Cell) {
      const result = curr.Cell({
        cell: {
          value: obj[`${curr.accessor}`],
          row: { original: x },
        },
      });

      acc[curr.Header] =
        // if Cell fn returns a react element
        result &&
        (typeof result.type === "function" ||
          (typeof result.type === "object" && String(result.props.value)))
          ? result.props.value
          : result;
    } else if (
      typeof obj[`${curr.accessor}`] === "string" &&
      obj[`${curr.accessor}`].includes(",")
    ) {
      acc[curr.Header] = String(obj[`${curr.accessor}`]).replace(/"/gi, "''"); // .replace(/,/gi, " ");
    } else {
      acc[curr.Header] = obj[`${curr.accessor}`];
    }
    return acc;
  }, {});
};

export const download = (filename, type, content) => {
  const el = document.createElement("a");
  el.setAttribute(
    "href",
    `data:text/${type};charset=utf-8,${encodeURIComponent(content)}`
  );
  el.setAttribute("download", `${filename}`);
  el.style.display = "none";
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
};

export const generateCSV = (headers, data) =>
  `${headers.join(",")}\n${data
    .map((d) =>
      headers
        .map((h) =>
          h.toLowerCase().includes("zip")
            ? `"=""${d[h]}"""`
            : JSON.stringify(d[h])
        )
        .join(",")
    )
    .join("\n")}`;

export const downloadCSV = async (
  client,
  { query, where, orderByMulti },
  dataName,
  columns,
  dataToWorkWith
) => {
  let items;
  if (dataToWorkWith) {
    items = dataToWorkWith;
  } else {
    const { data } = await client.query({
      query,
      variables: {
        where,
        orderByMulti,
        first: null,
      },
      fetchPolicy: "network-only",
    });
    items = data[dataName].Payload || data[dataName];
  }

  const result =
    items && items.map((x) => parseQueryResultWithColumns(x, columns));
  if (result && result[0]) {
    const CSV = generateCSV(Object.keys(result[0]), result);
    return download(`${dataName}.csv`, "csv", CSV);
  }
  toast.error("No Data to Export");
  return null;
};

export const clampNumber = (num, min, max) =>
  Math.max(Math.min(num, Math.max(min, max)), Math.min(min, max));

export const getDaysDiffBetweenDates = (dateInitial, dateFinal) =>
  Math.round((dateFinal - dateInitial) / (1000 * 3600 * 24));

export const throttle = (fn, wait) => {
  let inThrottle;
  let lastFn;
  let lastTime;
  return function innerFn(...args) {
    const context = this;
    if (!inThrottle) {
      fn.apply(context, args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(() => {
        if (Date.now() - lastTime >= wait) {
          fn.apply(context, args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
};

export const deepFlatten = (arr) =>
  [].concat(...arr.map((v) => (Array.isArray(v) ? deepFlatten(v) : v)));

export const getPermissionsFromUserGroupMemberships = (
  userGroupMemberships = []
) => [
  ...new Set(
    deepFlatten(
      userGroupMemberships?.map((ug) =>
        Array.isArray(ug?.UserGroup?.UserGroupPermissions)
          ? ug.UserGroup.UserGroupPermissions.map((ugp) => ({
              Code: ugp?.Permission?.Code,
              Update: ugp?.Update,
              View: ugp?.View,
            }))
          : []
      )
    )
  ),
];

export const generateQRCode = (value) => {
  let imgUrl = "";
  QRCode.toDataURL(value, (err, url) => {
    imgUrl = url;
  });
  return imgUrl;
};

export const verifyCBDURL = (JobID, JobOrderID) => {
  let ret = `https://www.verifycbd.com/report/${JobID.toString(16)}z`;
  if (JobOrderID) {
    ret += JobOrderID.toString(16);
  }
  return ret;
};

export const formatPhoneNumber = (phoneNumber) => {
  const cleaned =
    typeof phoneNumber === "string"
      ? phoneNumber.replace(/\D/g, "")
      : (phoneNumber && phoneNumber[1]?.replace(/\D/g, "")) || "";
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [
      true,
      [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(""),
    ];
  }
  return [
    false,
    typeof phoneNumber === "string"
      ? phoneNumber
      : (phoneNumber && phoneNumber[1]) || "",
  ];
};

export const validateCreditCardNumber = (str) => {
  const numberValidation = valid.number(str);
  return [numberValidation.isValid, numberValidation.card?.type];
};

export const getDateEST = (
  options = { timeZone: "America/New_York", hour12: false }
) => new Date().toLocaleString("en-US", options);

export const prettyBytes = (num, precision = 3, addSpace = true) => {
  const UNITS = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  if (Math.abs(num) < 1) return num + (addSpace ? " " : "") + UNITS[0];
  const exponent = Math.min(
    Math.floor(Math.log10(num < 0 ? -num : num) / 3),
    UNITS.length - 1
  );
  const n = Number(
    ((num < 0 ? -num : num) / 1000 ** exponent).toPrecision(precision)
  );
  return (num < 0 ? "-" : "") + n + (addSpace ? " " : "") + UNITS[exponent];
};

export const readFile = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsText(file);
  });

export const formatISODate = (date) =>
  format(new Date(date), "dd/mm/yyyy H:mm:ss");

export const generateCSVFunction = (dataName, items, columns) => {
  const result =
    items && items.map((x) => parseQueryResultWithColumns(x, columns));
  if (result && result[0]) {
    const CSV = generateCSV(Object.keys(result[0]), result);
    return download(`${dataName}.csv`, "csv", CSV);
  }
  toast.error("No Data to Export");
  return null;
};

export const csvToArray = (str, delimiter = ",") => {
  const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

  const rows = str.slice(str.indexOf("\n") + 1).split("\n");

  const arr = rows.map((row) => {
    const values = row.split(/,+(?=(?:(?:[^"]*"){2})*[^"]*$)/g);

    const el = headers.reduce((object, header, index) => {
      // eslint-disable-next-line no-param-reassign
      object[header.trim()] = values[index]?.trim();
      return object;
    }, {});
    return el;
  });

  return arr;
};

export const HomoTestsNeeded = (batchSize) => {
  const batchRange = [
    [1, 249, 3], // min, max, homo tests
    [250, 499, 6],
    [500, 749, 9],
    [750, 1199, 12],
    [1200, 1399, 14],
    [1400, 1599, 16],
    [1600, 1799, 18],
    [1800, 1999, 20],
    [2000, 2499, 22],
    [2500, 2999, 24],
    [3000, 3999, 26],
    [4000, 5000, 28],
  ];
  let testToReturn = 0;
  batchRange.forEach((batch) => {
    const min = batch[0];
    const max = batch[1];
    const tests = batch[2];
    if (batchSize >= min && batchSize <= max) {
      testToReturn = tests;
    } else if (batchSize > 5000) {
      const quotient = Math.floor(batchSize / 5000);
      const remainder = batchSize % 5000;
      if (remainder === 0) {
        testToReturn = 28 * quotient;
      } else if (remainder >= min && remainder <= max) {
        testToReturn = 28 * quotient + tests;
      }
    }
  });
  return testToReturn;
};
export const labelBuilder = (value, label) => {
  const labelValue = label.map((val) => (
    <p key={Math.random() * 100} className="code-label">
      {val}
    </p>
  ));
  return (
    <Control
      key={Math.random() * 100}
      className="individual-label-wrapper"
      size="small"
    >
      <Image.Container>
        <Image alt={value} src={generateQRCode(value)} />
      </Image.Container>
      <Label>{labelValue}</Label>
    </Control>
  );
};

export const lowercaseKeys = (obj) => {
  if (!obj) {
    return {};
  }
  return Object.keys(obj).reduce((acc, key) => {
    acc[key.toLowerCase().trim()] = obj[key];
    return acc;
  }, {});
};

// check if a date is a weekend or holiday
export const isDateHoliday = (date) => {
  if (isWeekend(new Date(date))) {
    return true;
  }

  return !!GSLHolidays.find(
    (holiday) => format(new Date(date), "d-MMM-yy") === holiday
  );
};

// calculate order due date
export const calculateOrderDueDate = (
  labState,
  substanceType,
  shippingType,
  userTurnAroundDays
) => {
  // find expected turn around duration for this order
  let expectedTurnAroundDays = 5;
  if (labState === "AZ") {
    expectedTurnAroundDays = 5;
  } else if (substanceType === "Hemp") {
    expectedTurnAroundDays = 5;
  } else if (shippingType === "Standard") {
    if (userTurnAroundDays) {
      expectedTurnAroundDays = userTurnAroundDays;
    } else {
      expectedTurnAroundDays = 5;
    }
  } else if (shippingType === "Expedited") {
    expectedTurnAroundDays = 3;
  } else if (shippingType === "Urgent") {
    expectedTurnAroundDays = 2;
  }

  // find the due date using the expected turn around
  // excludes weekends and holidays
  let totalDaysAdded = 0;
  let workDaysAddedd = 0;
  const receiveDate = new Date();
  while (workDaysAddedd < expectedTurnAroundDays) {
    const dateToCheck = add(new Date(receiveDate), {
      days: totalDaysAdded,
    });
    if (!isDateHoliday(dateToCheck)) {
      workDaysAddedd += 1;
    }
    totalDaysAdded += 1;
  }

  const dueDate = add(new Date(receiveDate), {
    days: totalDaysAdded - 1,
  });

  return dueDate;
};

export const subtractLocalTimeZoneOffset = (date) => {
  const localDate = new Date(date);
  localDate.setTime(
    localDate.getTime() - localDate.getTimezoneOffset() * 60 * 1000
  );
  return localDate;
};

export const removeLocalTimeZoneOffset = (date) => {
  const localDate = new Date(date);
  localDate.setTime(
    localDate.getTime() + localDate.getTimezoneOffset() * 60 * 1000
  );
  return localDate;
};

// get number of business days between two dates
// excludes weekends and holidays
export const getBusinessDays = (startDate, endDate) => {
  const StartDate = startOfDay(new Date(removeLocalTimeZoneOffset(startDate)));
  const EndDate = startOfDay(new Date(removeLocalTimeZoneOffset(endDate)));

  let days = 0;
  let dateToCheck = StartDate;
  while (compareAsc(dateToCheck, EndDate) !== 1) {
    const t = add(new Date(dateToCheck), {
      days: 1,
    });
    if (!isDateHoliday(t)) {
      days += 1;
    }
    dateToCheck = new Date(t);
  }
  return days;
};

export const getIsPostTransitionDate = (labState, transitionDate) => {
  if (labState !== "AZ") {
    return false;
  }
  const transition = startOfDay(removeLocalTimeZoneOffset(transitionDate));
  const today = removeLocalTimeZoneOffset(new Date());

  const isPostTransitionDate = compareAsc(transition, today);

  return isPostTransitionDate !== 1;
};
