import React, { useState } from "react";
import { Box, Input, Field, Control, Label, Button } from "rbx";

import { toast } from "react-toastify";
import { useApolloClient } from "@apollo/client";
import { useAuth } from "../../../../context";

import {
  GENERATE_COA_QUERY,
  SINGLE_PRIVATE_LABEL_COA_QUERY,
} from "../../../../graphql";

import { PageHeader } from "../../../../components";

import "./PrivateLabelFindPage.scss";

const { REACT_APP_COA_URL } = process.env;

class CustomPrivateLabelFindPageError extends Error {}

const PrivateLabelFindPage = () => {
  const { state: authState } = useAuth();

  const client = useApolloClient();

  const [coaId, setCoaId] = useState("");

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const { data: PrivateLabelCOAData } = await client.query({
        query: SINGLE_PRIVATE_LABEL_COA_QUERY,
        variables: {
          where: {
            COAID: parseInt(coaId, 10),
          },
        },
        fetchPolicy: "network-only",
      });

      const privateLabelCoa = PrivateLabelCOAData?.findUniquePrivateLabelCOAs;

      if (!privateLabelCoa.UserNotified) {
        throw new CustomPrivateLabelFindPageError(
          "COA is not ready yet. Please wait to be notified or try again later."
        );
      }
      if (
        privateLabelCoa?.OwnerUserID === String(authState?.user?.OwnerUserID) &&
        privateLabelCoa?.PrivateLabelUserID ===
          String(authState?.user?.PrivateLabelUserID)
      ) {
        const { data: GenerateCOAData } = await client.query({
          query: GENERATE_COA_QUERY,
          variables: {
            jobOrderId: parseInt(privateLabelCoa?.JobOrderID, 10),
            coaId: parseInt(privateLabelCoa?.COAID, 10),
          },
          fetchPolicy: "network-only",
        });

        const coa = GenerateCOAData?.generateCertificateOfAnalysis;

        if (coa?.version) {
          window.open(
            `${REACT_APP_COA_URL}/plcoa/pdf/${privateLabelCoa.JobOrderID}/${coa.version}?cachebuster=1?coaId=${privateLabelCoa?.COAID}`,
            "_blank"
          );
        }
      }
    } catch (err) {
      const message =
        err instanceof CustomPrivateLabelFindPageError
          ? err.message
          : "Error downloading COA. Please contact support";
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="private-label-find-page">
      <PageHeader title="Private Label Download COA Page" />
      <form id="find-coa" onSubmit={handleSubmit}>
        <Box>
          <Field kind="group">
            <Control>
              <Label size="medium">Enter COA ID</Label>
              <Input
                required
                disabled={loading}
                type="text"
                value={coaId}
                onChange={(e) => setCoaId(e.target.value)}
              />
            </Control>
            <Control>
              <Label size="medium">&nbsp;</Label>
              <Button
                color="primary"
                disabled={loading || !coaId}
                state={loading ? "loading" : ""}
                type="submit"
              >
                Download
              </Button>
            </Control>
          </Field>
        </Box>
      </form>
    </div>
  );
};

export default PrivateLabelFindPage;
