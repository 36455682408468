/* eslint-disable react/prop-types */
import React from "react";
import { format, differenceInCalendarDays } from "date-fns";
import { Generic, Icon, Tag } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const statusColors = {
  Status: {
    false: "danger",
    true: "cyan",
  },
};

const DEFAULT_COLUMNS = [
  {
    Header: "Status",
    id: "Active",
    accessor: "Active",
    Cell: ({ cell }) => (
      <Tag color={statusColors.Status[cell.value]} style={{ width: 120 }}>
        {cell.value ? "IN USE" : "OUT OF SERVICE"}
      </Tag>
    ),
  },
  {
    Header: "Lab Name",
    id: "LabID",
    accessor: "Lab.Company",
  },
  {
    Header: "Name / EID",
    id: "Name",
    accessor: "Name",
  },
  {
    Header: "Calibration Due Date",
    id: "CalibrationDueDate",
    accessor: "CalibrationDueDate",
    disableSortBy: true,
    Cell: ({ cell }) =>
      cell?.value ? (
        <Generic
          style={
            differenceInCalendarDays(new Date(cell.value), new Date()) < 30
              ? { color: "red" }
              : {}
          }
        >
          {format(new Date(cell.value), "MM-dd-yyyy")}
        </Generic>
      ) : (
        ""
      ),
  },
  {
    Header: "Maintenance Due Date",
    id: "MaintenanceDueDate",
    accessor: "MaintenanceDueDate",
    disableSortBy: true,
    Cell: ({ cell }) =>
      cell?.value ? (
        <Generic
          style={
            differenceInCalendarDays(new Date(cell.value), new Date()) < 30
              ? { color: "red" }
              : {}
          }
        >
          {format(new Date(cell.value), "MM-dd-yyyy")}
        </Generic>
      ) : (
        ""
      ),
  },
];

export const generateColumns = (handleDelete, canUpdate) => [
  ...DEFAULT_COLUMNS,
  {
    Header: "",
    id: "delete",
    Cell: (cell) =>
      canUpdate && (
        <Generic
          style={{ textAlign: "center" }}
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(cell.row.original);
          }}
        >
          <Icon color="danger">
            <FontAwesomeIcon icon="times" />
          </Icon>
        </Generic>
      ),
  },
];

export default DEFAULT_COLUMNS;
