import { gql } from "@apollo/client";

export const ALL_JOB_ORDER_TESTS_QUERY = gql`
  query ALL_JOB_ORDER_TESTS_QUERY(
    $where: JobOrderTestsWhereInput
    $orderBy: [JobOrderTestsOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyJobOrderTests(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      JobOrderTestID
      JobOrderID
      JobID
      LabJobOrderTestID
      UserID
      UserTestID
      TestID
      Weight
      UserPrice
      HideOnCoA
      TestStatus
      Approved
      Result
      RePrepFlag
      Archived
      Test {
        TestID
        Name
        Code
        Description
        EquipmentTests {
          EquipmentTestID
          EquipmentID
          Equipment {
            EquipmentID
            LabID
            Name
            Manufacturer
            SerialNumber
          }
        }
      }
      TestApproval {
        ApprovalID
        ApprovalDate
        User {
          UserID
          FirstName
          LastName
          Username
        }
      }
      JobOrder {
        JobOrderID
        OrderName
        OrderReceiveDate
        OrderCompleteDate
        User {
          UserID
          Username
        }
      }
    }
    aggregateJobOrderTests(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const SINGLE_JOB_ORDER_TEST_QUERY = gql`
  query SINGLE_JOB_ORDER_TEST_QUERY($where: JobOrderTestsWhereUniqueInput!) {
    findUniqueJobOrderTests(where: $where) {
      JobOrderTestID
      JobOrderID
      JobID
      Archived
      LabJobOrderTestID
      UserID
      UserTestID
      TestID
      Result
    }
  }
`;

export const ALL_HOMOGENEITY_JOB_ORDER_TEST_RESULTS_QUERY = gql`
  query ALL_HOMOGENEITY_JOB_ORDER_TEST_RESULTS_QUERY(
    $where: JobOrderTestsWhereInput!
  ) {
    findManyHomogeneityJobOrderTestResults(where: $where) {
      JobOrderTestID
      ResultToDisplay
      JobOrder {
        JobOrderID
        LabelClaim
        OverrideData
        DisplayUnit
        OverrideDataCodeDescriptions
        Category {
          CodeDescription
        }
        ActualLabelClaims {
          ActualLabelClaimID
          LabelClaim
          Analyte {
            AnalyteID
            Name
          }
        }
      }
      Batch {
        Lab {
          State
        }
        TestSamples {
          Weight
          Volume
          Dilution
          TestCategory {
            Code
          }
          JobOrderTestSample {
            JobOrderTestSampleID
            SessionID
            Result
            PrepNo
            JobOrderTest {
              JobOrderTestID
            }
          }
        }
      }
    }
  }
`;
