import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation login($input: AuthInput!) {
    login(input: $input) {
      User {
        UserID
        Username
        FirstName
        LastName
        Email
        MMTCInfo
        LoginType
        Company
        Address
        CartCount
        City
        State
        Zip
        Terms
        LabID
        BillingAddress
        ShippingMultiplier
        SMSNotifications
        EmailNotifications
        ShowOnVerifyHemp
        VerifyHempCategories
        AllowDupeBatchOrderNumber
        UserGroupMemberships {
          UserGroupMembershipID
          UserGroup {
            GroupID
            UserGroupPermissions {
              UserGroupPermissionID
              Update
              View
              Permission {
                PermissionID
                Code
              }
            }
          }
        }
        Type {
          CodeDescription
        }
        Lab {
          LabID
          Company
          State
          PaymentOptions
        }
        ChildClientIDs
      }
      Token
    }
  }
`;

export const LOGIN_PRIVATE_LABEL_MUTATION = gql`
  mutation loginPrivateLabel($input: AuthInput!) {
    loginPrivateLabel(input: $input) {
      PrivateLabelUser {
        PrivateLabelUserID
        OwnerUserID
        Username
        RevShare
        Status
        CreateDate
      }
      Token
    }
  }
`;

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation requestPasswordReset($input: ForgotPasswordInput!) {
    requestPasswordReset(input: $input)
  }
`;

export const COMPLETE_PASSWORD_RESET_MUTATION = gql`
  mutation completePasswordReset($input: CompletePasswordInput!) {
    completePasswordReset(input: $input)
  }
`;

export const LOGIN_AS_CLIENT_MUTATION = gql`
  mutation LOGIN_AS_CLIENT_MUTATION($input: StringFilter!) {
    loginAsClient(input: $input) {
      User {
        UserID
        Username
        FirstName
        LastName
        Email
        MMTCInfo
        LoginType
        Company
        Address
        CartCount
        City
        State
        Zip
        Terms
        LabID
        BillingAddress
        ShippingMultiplier
        SMSNotifications
        EmailNotifications
        ShowOnVerifyHemp
        VerifyHempCategories
        AllowDupeBatchOrderNumber
        UserGroupMemberships {
          UserGroupMembershipID
          UserGroup {
            GroupID
            UserGroupPermissions {
              UserGroupPermissionID
              Update
              View
              Permission {
                PermissionID
                Code
              }
            }
          }
        }
        Type {
          CodeDescription
        }
        Lab {
          LabID
          Company
          State
          PaymentOptions
        }
        ChildClientIDs
      }
      Token
    }
  }
`;
