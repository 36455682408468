const userGroups = {
  name: "user-groups",
  label: "User Groups",
  icon: "user",
  permissions: ["GROUPS"],
  active: (location) => {
    const [, p] = location.pathname.split("/");
    return /user-groups/.test(p);
  },
  to: "/user-groups",
};

export default userGroups;
