/* eslint-disable no-extra-boolean-cast */
import React from "react";
import PropTypes from "prop-types";

// components
import { Switch, Route } from "react-router-dom";
import { Tab } from "rbx";

// internal components
import IndividualTest from "../../routes/IndividualTest";
import PackageTests from "../../routes/PackageTests";

const NewTestTabs = ({
  tab,
  history,
  onChange,
  tests,
  selectedPackage,
  onPackageChange,
  inputs,
}) => (
  <React.Fragment key="company-tabs">
    <Tab.Group kind="boxed">
      <Tab
        active={tab === "individual-test"}
        onClick={() => {
          // eslint-disable-next-line no-extra-boolean-cast
          !!inputs.CartID
            ? history.push(`/new-test/individual-test/${inputs.CartID}`)
            : history.push("/new-test/individual-test");
        }}
      >
        Individual Test
      </Tab>
      <Tab
        active={tab === "package-test"}
        onClick={() => {
          !!inputs.CartID
            ? history.push(`/new-test/package-test/${inputs.CartID}`)
            : history.push("/new-test/package-test");
        }}
      >
        Test Packages
      </Tab>
    </Tab.Group>
    <Switch>
      <Route path="/new-test/individual-test">
        <IndividualTest
          tests={tests.filter((test) => !!test.show)}
          onChange={onChange}
        />
      </Route>
      <Route path="/new-test/package-test">
        <PackageTests
          selectedPackage={selectedPackage}
          onChange={onPackageChange}
        />
      </Route>
    </Switch>
  </React.Fragment>
);

NewTestTabs.propTypes = {
  tab: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  tests: PropTypes.array.isRequired,
  selectedPackage: PropTypes.object,
  onPackageChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
};

NewTestTabs.defaultProps = {
  selectedPackage: null,
};

export default NewTestTabs;
