import React from "react";
import PropTypes from "prop-types";
import { Image } from "rbx";

import { generateQRCode } from "../../../../utils";

const ImageCell = ({ value, alt }) => (
  <Image.Container size={48}>
    <Image 
      alt={alt}
      src={generateQRCode(value)}
    />
  </Image.Container>
  );

ImageCell.propTypes = {
  value: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default ImageCell;
