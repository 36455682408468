import React from "react";
import PropTypes from "prop-types";
import { Box, Field, Control, Label, Button, Column } from "rbx";
import { DateRangeSelect, LabLocationSelect } from "../../../components";

const CreditLedgerFilters = ({
  handleFilterChange,
  handleFiltersReset,
  inputs,
  canSearchByLab,
}) => (
  <Box id="job-filters">
    <Column.Group>
      {canSearchByLab && (
        <Column size={4}>
          <Field>
            <LabLocationSelect
              showLabel
              getValue={(x) => (typeof x?.is?.LabID?.equals === "number" ? x?.is?.LabID?.equals : "")}
              name="User"
              setValue={(x) =>
                typeof x === "number" ? { is: { LabID: { equals: x } } } : ""
              }
              size="small"
              value={inputs?.User}
              onChange={handleFilterChange}
            />
          </Field>
        </Column>
      )}
      <Column size={canSearchByLab ? 7: 11}>
        <Field>
          <DateRangeSelect
            end={inputs?.JobCreateDate?.lte}
            handleChange={(value) => handleFilterChange("JobCreateDate", value)}
            start={inputs?.JobCreateDate?.gte}
          />
        </Field>
      </Column>
      <Column size={1}>
        <Field style={{ display: "flex", justifyContent: "flex-end" }}>
          <Control>
            <Label>&nbsp;</Label>
            <Button color="primary" size="small" onClick={handleFiltersReset}>
              Reset
            </Button>
          </Control>
        </Field>
      </Column>
    </Column.Group>
  </Box>
);

CreditLedgerFilters.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  handleFiltersReset: PropTypes.func.isRequired,
  inputs: PropTypes.object,
  canSearchByLab: PropTypes.bool,
};

CreditLedgerFilters.defaultProps = {
  inputs: {},
  canSearchByLab: false,
};

export default CreditLedgerFilters;
