import { gql } from "@apollo/client";

export const ALL_SAMPLE_TRACKINGS_QUERY = gql`
  query ALL_SAMPLE_TRACKINGS_QUERY(
    $where: SampleTrackingWhereInput
    $orderBy: [SampleTrackingOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    sampleTrackings(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      SampleTrackingID
      Value
      Name
      JobOrders {
        JobOrderID
      }
      Lab {
        Company
      }
    }
    aggregateSampleTracking(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const LIST_SAMPLE_TRACKINGS_QUERY = gql`
  query LIST_SAMPLE_TRACKINGS_QUERY(
    $where: SampleTrackingWhereInput
    $orderBy: [SampleTrackingOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    sampleTrackings(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      SampleTrackingID
      Value
      Name
      JobOrders {
        JobOrderID
        OrderName
        SampleLocationDate
        SampleLocation
      }
      Lab {
        Company
      }
    }
  }
`;

export const SINGLE_SAMPLE_TRACKING_QUERY = gql`
  query SINGLE_SAMPLE_TRACKING_QUERY($where: SampleTrackingWhereUniqueInput!) {
    sampleTracking(where: $where) {
      SampleTrackingID
      Value
      Name
      JobOrders {
        JobOrderID
        OrderName
      }
      LabID
    }
  }
`;
