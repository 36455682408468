import { gql } from "@apollo/client";

export const ALL_LEGACY_JOB_ORDERS_QUERY = gql`
  query ALL_LEGACY_JOB_ORDERS_QUERY(
    $where: LegacyJobOrdersWhereInput
    $orderBy: [LegacyJobOrdersOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyLegacyJobOrders(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      LegacyJobOrderID
      JobOrderID
      JobID
      OrderName
      BatchOrderNumber
      COA
      OP
      ShowOnVerifyHemp
      VerifyHempCategory
      LegacyUserID
      User {
        UserID
        Username
      }
      OrderCreateDate
      OrderReceiveDate
    }
    aggregateLegacyJobOrders(where: $where) {
      count {
        _all
      }
    }
  }
`;
export const ALL_JOB_ORDERS_AND_LEGACY_JOB_ORDERS_QUERY = gql`
  query ALL_JOB_ORDERS_AND_LEGACY_JOB_ORDERS_QUERY(
    $where: JobOrdersWhereInput!
    $orderBy: [JobOrdersOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyJobOrdersAndLegacyJobOrders(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      __typename
      ... on JobOrders {
        JobOrderID
        JobID
        OrderName
        BatchOrderNumber
        ShowOnVerifyHemp
        VerifyHempCategory
        UserID
        OverrideData
        User {
          UserID
          Username
          COASettings
        }
        OrderCompleteDate
        OrderCreateDate
        OrderReceiveDate
        TestsCount
        JobOrderStatus {
          RecId
          CodeDescription
        }
        Job {
          ShippingSystemCode {
            RecId
            CodeDescription
          }
          Payment {
            RecId
            CodeDescription
          }
        }
      }
      ... on LegacyJobOrders {
        LegacyJobOrderID
        JobOrderID
        JobID
        OrderName
        BatchOrderNumber
        COA
        OP
        ShowOnVerifyHemp
        VerifyHempCategory
        LegacyUserID
        User {
          UserID
          Username
        }
        OrderCreateDate
        OrderReceiveDate
      }
    }
    aggregateJobOrdersAndLegacyJobOrders(where: $where) {
      count {
        _all
      }
    }
  }
`;
