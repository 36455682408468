import React from "react";
import PropTypes from "prop-types";
import { Box, Field, Control, Label, Button, Column } from "rbx";
import { LabLocationSelect } from "../../../../components";

const EquipmentFilters = ({
  handleFilterChange,
  handleFiltersReset,
  inputs,
}) => (
  <Box id="job-filters">
    <Column.Group>
      <Column size={11}>
        <Field>
          <LabLocationSelect
            showLabel
            getValue={(x) => (typeof x?.equals === "number" ? x?.equals : "")}
            name="LabID"
            setValue={(x) => (typeof x === "number" ? { equals: x } : {})}
            size="small"
            value={inputs?.LabID}
            onChange={handleFilterChange}
          />
        </Field>
      </Column>
      <Column size={1}>
        <Field style={{ display: "flex", justifyContent: "flex-end" }}>
          <Control>
            <Label>&nbsp;</Label>
            <Button color="primary" size="small" onClick={handleFiltersReset}>
              Reset
            </Button>
          </Control>
        </Field>
      </Column>
    </Column.Group>
  </Box>
);

EquipmentFilters.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  handleFiltersReset: PropTypes.func.isRequired,
  inputs: PropTypes.object,
};

EquipmentFilters.defaultProps = {
  inputs: {},
};

export default EquipmentFilters;
