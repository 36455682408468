import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Box, Title } from "rbx";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import BooleanInput from "../../../../components/BooleanInput";

import {
  ALL_TESTS_QUERY,
  ALL_EQUIPMENT_TESTS_QUERY,
  CREATE_EQUIPMENT_TEST_MUTATION,
  DELETE_EQUIPMENT_TEST_MUTATION,
} from "../../../../graphql";
import "./EquipmentTests.scss";

import { useAuth } from "../../../../context";

const EquipmentTests = ({ EquipmentID, disabled }) => {
  const { state: authState } = useAuth();

  const [createEquipmentTest] = useMutation(CREATE_EQUIPMENT_TEST_MUTATION);
  const [deleteEquipmentTest] = useMutation(DELETE_EQUIPMENT_TEST_MUTATION);

  const { data: FindManyTestsData } = useQuery(ALL_TESTS_QUERY);
  const { data: FindManyEquipmentTestsData } = useQuery(
    ALL_EQUIPMENT_TESTS_QUERY,
    {
      variables: { where: { EquipmentID: { equals: parseInt(EquipmentID, 10) } } },
    }
  );

  const [tests, setTests] = useState([]);

  const [inputs, setInputs] = useState({});

  useEffect(() => {
    const foundTests = FindManyTestsData?.findManyTests;
    if (Array.isArray(foundTests)) {
      setTests(foundTests);
    }
  }, [FindManyTestsData]);

  useEffect(() => {
    const equipTests = FindManyEquipmentTestsData?.findManyEquipmentTests;
    if (Array.isArray(equipTests)) {
      setInputs((prev) => ({
        ...prev,
        ...equipTests.reduce((a, c) => ({ ...a, [c.TestID]: true }), {}),
      }));
    }
  }, [FindManyEquipmentTestsData]);

  const handleChangeTest = async (TestID, value) => {
    setInputs((prev) => ({ ...prev, [TestID]: value }));

    const date = new Date();

    const refetchQueries = [
      {
        query: ALL_EQUIPMENT_TESTS_QUERY,
        variables: {
          where: { EquipmentID: { equals: parseInt(EquipmentID, 10) } },
        },
      },
    ];

    if (value) {
      await createEquipmentTest({
        variables: {
          data: {
            Test: {
              connect: { TestID: parseInt(TestID, 10) },
            },
            Equipment: {
              connect: { EquipmentID: parseInt(EquipmentID, 10) },
            },
            CreatedDateTime: date,
            ModifiedDateTime: date,
            CreatedBy: authState?.user?.Username,
            ModifiedBy: authState?.user?.Username,
          },
        },
        refetchQueries,
      });
    } else {
      await deleteEquipmentTest({
        variables: {
          where: {
            EquipmentID_TestID: {
              EquipmentID: parseInt(EquipmentID, 10),
              TestID: parseInt(TestID, 10),
            },
          },
        },
        refetchQueries,
      });
    }
    toast.success('Equipment tests updated successfully.');
  };

  return (
    <Box>
      <Title subtitle>Tests</Title>
      <div className="equipment-tests-container">
        {tests.map((test) => (
          <BooleanInput
            key={test.TestID}
            disabled={disabled}
            label={test.Name}
            name={test.TestID}
            value={inputs[test.TestID]}
            onChange={handleChangeTest}
          />
        ))}
      </div>
    </Box>
  )
};

EquipmentTests.propTypes = {
  EquipmentID: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default EquipmentTests;
