/* eslint-disable react/prop-types */
import { TableCell } from "../../../../components/DataTable";

const DEFAULT_COLUMNS = [
  {
    Header: "Name",
    id: "Name",
    accessor: "Name",
  },
  {
    Header: "Code",
    id: "Code",
    accessor: "Code",
  },
  {
    Header: "Default Price",
    id: "CategoryPrice",
    accessor: "CategoryPrice",
    Cell: TableCell.Currency,
  },
  {
    Header: "Status",
    id: "Active",
    accessor: "Active",
    Cell: ({ cell }) => (cell?.value ? "Active" : "Inactive"),
  },
  {
    Header: "Description",
    id: "Description",
    accessor: "Description",
    Cell: ({ cell }) =>
      cell.value.length > 125 ? `${cell.value.slice(0, 125)}...` : cell.value,
  },
];

export default DEFAULT_COLUMNS;
