/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Generic, Icon, Box } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import ImportResultsFileListItems from "./ImportResultsFileListItems";

const Container = styled.div`
  min-height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1rem;
  margin: 1rem auto;
`;

const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 40rem;
  width: 100%;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: #f1f1f1;
  transition: background-color 0.2s ease;
  /* border-bottom: solid 1px #e0dede; */
  border-radius: 0px 20px 0px 0px;
  padding: 0.75rem 0.5rem 0.5rem 0.5rem;
`;

const Title = styled.h4`
  padding: 0px 8px;
  transition: background-color ease 0.2s;
  flex-grow: 1;
  user-select: none;
  position: relative;
  &:focus {
    outline: 2px solid #998dd9;
    outline-offset: 2px;
  }
`;

const Subtitle = styled.p`
  font-size: 10px;
  padding: 0px 8px;
  color: #7a7a7a;
`;

const Alert = styled.div`
  padding-left: 10px;
  font-weight: bold;
`;

const ImportResultsFileList = ({
  files,
  removeFile,
  approvedSamples,
  removeApprovedFile,
}) => (
  <React.Fragment>
    {approvedSamples &&
    (approvedSamples?.testSamples.length ||
      approvedSamples?.qCSamples.length) ? (
      <React.Fragment>
        <Box
          style={{ backgroundColor: "#ffe699", marginTop: "10px", padding: 1 }}
        >
          <Container>
            <Alert>
              The following samples have already been approved and the results
              cannot be re-uploaded.
            </Alert>
            {approvedSamples?.testSamples
              ?.sort((a, b) => a.SessionID.localeCompare(b.SessionID))
              .map((file, index) => (
                <FileContainer key={`${index}-${file.SessionID}`}>
                  <Header>
                    <div style={{ width: "90%", marginRight: "0.25rem" }}>
                      <Title>{file.SessionID}</Title>
                      <Subtitle>{file.type}</Subtitle>
                    </div>
                    <div style={{ width: "10%", textAlign: "center" }}>
                      <Generic
                        className="delete-package-button"
                        onClick={() =>
                          removeApprovedFile("testSamples", file.SessionID)
                        }
                      >
                        <Icon color="primary">
                          <FontAwesomeIcon icon="trash-alt" />
                        </Icon>
                      </Generic>
                    </div>
                  </Header>
                </FileContainer>
              ))}
            {approvedSamples?.qCSamples
              ?.sort((a, b) => a.SessionID.localeCompare(b.SessionID))
              .map((file, index) => (
                <FileContainer key={`${index}-${file.SessionID}`}>
                  <Header>
                    <div style={{ width: "90%", marginRight: "0.25rem" }}>
                      <Title>{file.SessionID}</Title>
                      <Subtitle>{file.type}</Subtitle>
                    </div>
                    <div style={{ width: "10%", textAlign: "center" }}>
                      <Generic
                        className="delete-package-button"
                        onClick={() =>
                          removeApprovedFile("qCSamples", file.SessionID)
                        }
                      >
                        <Icon color="primary">
                          <FontAwesomeIcon icon="trash-alt" />
                        </Icon>
                      </Generic>
                    </div>
                  </Header>
                </FileContainer>
              ))}
          </Container>
        </Box>
        <hr />
      </React.Fragment>
    ) : null}
    <Container>
      {files
        .sort((a, b) => a.sessionId.localeCompare(b.sessionId))
        .map((file, index) => (
          <FileContainer key={`${index}-${file.sessionId}`}>
            <Header>
              <div style={{ width: "90%", marginRight: "0.25rem" }}>
                <Title>{file.sessionId}</Title>
                <Subtitle>{file.type}</Subtitle>
              </div>
              <div style={{ width: "10%", textAlign: "center" }}>
                <Generic
                  className="delete-package-button"
                  onClick={() => removeFile(file)}
                >
                  <Icon color="primary">
                    <FontAwesomeIcon icon="trash-alt" />
                  </Icon>
                </Generic>
              </div>
            </Header>
            {/* <ImportResultsFileListItems
            listId={`${file.sessionId} - ${file.type}`}
            name={`${file.sessionId} - ${file.type}`}
            result={file.result}
            style={{
              borderRadius: "0px 0px 20px 20px",
            }}
          /> */}
          </FileContainer>
        ))}
    </Container>
  </React.Fragment>
);

ImportResultsFileList.propTypes = {
  files: PropTypes.arrayOf(File),
  removeFile: PropTypes.func,
  approvedSamples: PropTypes.object,
  removeApprovedFile: PropTypes.func,
};

ImportResultsFileList.defaultProps = {
  files: [],
  removeFile: () => {},
  approvedSamples: null,
  removeApprovedFile: () => null,
};

export default ImportResultsFileList;
