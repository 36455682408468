/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Control, Title, Section } from "rbx";
import { useLazyQuery, useQuery } from "@apollo/client";
import Collapse from "rc-collapse";
import { sortBy } from "lodash";
import "rc-collapse/assets/index.css";

import Checkbox from "../../../../../components/BooleanInput/Checkbox";
import ActionLimitTemplateTable from "../ActionLimitTemplateTable";
import Loader from "../../../../../components/Loader/Loader";
import { MICRO_COLUMNS, NON_MICRO_COLUMNS } from "../columns";
import {
  ALL_ANALYTES_QUERY,
  ALL_ACTION_LIMIT_TEMPLATE_ANALYTES_QUERY,
  LIST_TEST_CATEGORIES_QUERY,
  FIRST_TEST_CATEGORY_QUERY,
} from "../../../../../graphql";
import "./EditActionLimitTemplateAnalytesTable.scss";

const EditActionLimitTemplateAnalytesTable = ({
  aggregateName,
  aggregateKey,
  query,
  where,
  orderBy,
  onRowClick,
  fetchPolicy,
  typeOfRequest,
  name,
  setUpdateData,
  canUpdate,
  inputs,
}) => {
  const [orderByMulti, setOrderByMulti] = useState([]);
  const [selectAllByCategory, setSelectAllByCategory] = useState({});
  const [testCategoriesData, setTestCategoriesData] = useState({});
  const { data: getFirstTestCategory } = useQuery(FIRST_TEST_CATEGORY_QUERY, {
    variables: {
      where: {
        TestCategoryID: { equals: parseInt(inputs.TestCategory, 10) },
      },
    },
  });
  const selectedTestType = useMemo(() => {
    if (getFirstTestCategory?.findFirstTestCategories) {
      return getFirstTestCategory?.findFirstTestCategories.Name;
    }
    return null;
  }, [getFirstTestCategory]);

  useEffect(() => {
    setOrderByMulti(orderBy);
  }, [where]);

  const [getAllAnalytesData, { data: allAnalytesData }] = useLazyQuery(
    ALL_ANALYTES_QUERY,
    {
      fetchPolicy,
    }
  );

  const [
    getAllActionLimitTemplatesAnalytesData,
    { data: allActionLimitTemplatesAnalytesData },
  ] = useLazyQuery(ALL_ACTION_LIMIT_TEMPLATE_ANALYTES_QUERY, {
    fetchPolicy,
  });
  const { data: getTestCategoriesData } = useQuery(LIST_TEST_CATEGORIES_QUERY);

  const initializeState = () => {
    if (Array.isArray(getTestCategoriesData?.findManyTestCategories)) {
      let dataObj = {};
      let selectObj = {};
      getTestCategoriesData.findManyTestCategories.forEach((cat) => {
        dataObj = {
          ...dataObj,
          [cat.Name]: [],
        };

        selectObj = {
          ...selectObj,
          [cat.Name]: false,
        };
      });
      setTestCategoriesData(dataObj);
      setSelectAllByCategory(selectObj);
    }
  };

  const assignActionLimitTemplateAnalyteToArray = (obj) => {
    setTestCategoriesData((prev) => {
      const newObj = {
        ...prev,
        [obj.Analyte.TestCategory.Name]: [obj].concat(
          prev[obj.Analyte.TestCategory.Name]
        ),
      };

      let sortedObj = { ...newObj };

      const notSortedArr = sortedObj[obj.Analyte.TestCategory.Name];
      const sortedArr = sortBy(notSortedArr, (item) =>
        item.Analyte.Name.toUpperCase()
      );
      sortedObj = {
        ...sortedObj,
        [obj.Analyte.TestCategory.Name]: sortedArr,
      };

      return sortedObj;
    });
  };

  const handleInputChange = (index, fieldName, value, item) => {
    setTestCategoriesData((prev) => {
      const parentObj = prev[item.Analyte.TestCategory.Name];
      const childObj = parentObj[index];
      const element = {
        ...childObj,
        [fieldName]: value,
      };
      parentObj[index] = element;

      return {
        ...prev,
        [item.Analyte.TestCategory.Name]: parentObj,
      };
    });
  };

  useEffect(() => {
    const updatedArr = [];
    Object.keys(testCategoriesData).forEach((key) => {
      testCategoriesData[key].forEach((item) => {
        updatedArr.push(item);
      });
    });
    setUpdateData(updatedArr);
  }, [testCategoriesData]);

  useEffect(() => {
    getAllAnalytesData({
      variables: {
        where: {
          Active: {
            equals: true,
          },
        },
        orderBy: {
          Name: "desc",
        },
      },
    });

    getAllActionLimitTemplatesAnalytesData({
      variables: {
        where,
        orderBy: orderByMulti.map((x) => {
          if (x.id.includes(":")) {
            const sorts = x.id.split(":");
            return { [sorts[0]]: { [sorts[1]]: x.desc ? "desc" : "asc" } };
          }
          return { [x.id]: x.desc ? "desc" : "asc" };
        }),
      },
    });
  }, [fetchPolicy, getAllAnalytesData, getAllActionLimitTemplatesAnalytesData]);

  useEffect(() => {
    initializeState();
    const fetchedData = [];
    if (
      allAnalytesData?.findManyAnalytes &&
      allActionLimitTemplatesAnalytesData?.findManyActionLimitTemplateAnalytes
    ) {
      // push all of the existing limit action template analytes for that template
      allActionLimitTemplatesAnalytesData.findManyActionLimitTemplateAnalytes.forEach(
        (item) => {
          const obj = {
            ActionLimitTemplateAnalyteID: item.ActionLimitTemplateAnalyteID,
            ActionLevel: item.ActionLevel === "0" ? "0.000" : item.ActionLevel,
            Analyte: item.Analyte,
            LOQ: item.LOQ === "0" ? "0.000" : item.LOQ,
            LOD: item.LOD === "0" ? "0.000" : item.LOD,
            InSampleLOQ: item.InSampleLOQ === "0" ? "0.000" : item.InSampleLOQ,
            InSampleLOD: item.InSampleLOD === "0" ? "0.000" : item.InSampleLOD,
            AllowableCriteria: item.AllowableCriteria,
            UpperLimit:
              item.UpperLimit === "0" ? "0.000" : item.UpperLimit || null,
            Active: true,
          };
          fetchedData.push(obj);
          assignActionLimitTemplateAnalyteToArray(obj);
        }
      );

      // push all of the non existing limit action template analytes for that template
      allAnalytesData.findManyAnalytes.forEach((item) => {
        if (!fetchedData.some((e) => e.Analyte.AnalyteID === item.AnalyteID)) {
          const obj = {
            Analyte: item,
            ActionLevel: "0.0",
            LOQ: "0.000",
            LOD: "0.000",
            InSampleLOQ: "0.000",
            InSampleLOD: "0.000",
            AllowableCriteria: "",
            UpperLimit: "0.000",
            Active: false,
          };
          fetchedData.push(obj);
          assignActionLimitTemplateAnalyteToArray(obj);
        }
      });
    }

    setUpdateData(fetchedData);
  }, [allAnalytesData, allActionLimitTemplatesAnalytesData]);

  const handleSelectAllByType = (e, analyteName) => {
    setSelectAllByCategory((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));

    setTestCategoriesData((prev) => {
      let arr = prev[e.target.name];
      arr = arr.map((item) => ({
        ...item,
        Active: e.target.checked,
      }));
      return {
        ...prev,
        [e.target.name]: arr,
      };
    });
  };

  const renderTables = () => (
    <div key={selectedTestType}>
      <Collapse accordion={false}>
        <div className="my-header-class">
          <Control className="table-group-title">
            <Title size={5}>{selectedTestType}</Title>
            {canUpdate && (
              <Checkbox
                checked={selectAllByCategory[selectedTestType]}
                label={`Select all ${selectedTestType}`}
                name={selectedTestType}
                onChange={(e) => handleSelectAllByType(e)}
              />
            )}
          </Control>
          <ActionLimitTemplateTable
            canUpdate={canUpdate}
            columns={
              /MICRO/.test(selectedTestType.toUpperCase())
                ? MICRO_COLUMNS
                : NON_MICRO_COLUMNS
            }
            data={testCategoriesData[selectedTestType]}
            isMicro={/MICRO/.test(selectedTestType.toUpperCase())}
            onChange={handleInputChange}
          />
        </div>
      </Collapse>
    </div>
  );

  if (
    !Array.isArray(
      allActionLimitTemplatesAnalytesData?.findManyActionLimitTemplateAnalytes
    )
  ) {
    return <Loader />;
  }

  if (
    Array.isArray(
      allActionLimitTemplatesAnalytesData?.findManyActionLimitTemplateAnalytes
    ) &&
    allAnalytesData?.findManyAnalytes?.length === 0
  ) {
    return (
      <Title subtitle style={{ color: "gray" }}>
        Nothing here...
      </Title>
    );
  }
  return (
    <Section className="table-section" id="action-limit-template-table">
      <Control className="table-group-title">
        <Title size={4}>Analytes</Title>
      </Control>
      <hr />
      {selectedTestType && (
        <div>{renderTables()}</div>
      )}
    </Section>
  );
};

EditActionLimitTemplateAnalytesTable.propTypes = {
  aggregateName: PropTypes.string.isRequired,
  aggregateKey: PropTypes.string,
  query: PropTypes.object.isRequired,
  where: PropTypes.object,
  orderBy: PropTypes.array,
  onRowClick: PropTypes.func,
  fetchPolicy: PropTypes.string,
  typeOfRequest: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setUpdateData: PropTypes.func.isRequired,
  canUpdate: PropTypes.bool.isRequired,
  inputs: PropTypes.object.isRequired,
};

EditActionLimitTemplateAnalytesTable.defaultProps = {
  onRowClick: () => null,
  where: {},
  orderBy: [],
  fetchPolicy: "network-only",
  aggregateKey: "_all",
};

export default EditActionLimitTemplateAnalytesTable;
