import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Table,
  Field,
  Control,
  Label,
  Input,
  Checkbox,
  Button,
} from "rbx";
import { SystemCodeSelect } from "../../../../components";
import { formatCurrency } from "../../../../utils";

const CartCheckoutForm = ({
  inputs,
  onChange,
  totals,
  onCheckout,
  creditLayers,
  user,
  applyCoupon,
  hasMicro,
}) => {
  const processingOptionWhere = useMemo(() => {
    const baseWhere = {
      Type: { equals: "User" },
      Category: { equals: "Job" },
      CodeName: { equals: "ProcessingOption" },
    };
    if (hasMicro) {
      return {
        AND: [
          baseWhere,
          {
            CodeId: { not: { equals: "2" } },
          },
        ],
      };
    }
    return baseWhere;
  }, [hasMicro]);

  return (
    <Box style={{ position: "relative", padding: "5rem" }}>
      <Table style={{ width: "100%" }}>
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan="3" style={{ padding: "0.5em 0em" }}>
              Subtotal
            </Table.Cell>
            <Table.Cell style={{ textAlign: "end", padding: "0.5em 0em" }}>
              {formatCurrency(totals.subtotal)}
            </Table.Cell>
          </Table.Row>
          {inputs.CouponCode?.CouponID ? (
            <Table.Row>
              <Table.Cell colSpan="3" style={{ padding: "0.5em 0em" }}>
                Discount
              </Table.Cell>
              <Table.Cell
                style={{
                  textAlign: "end",
                  padding: "0.5em 0em",
                  color: "#079e42",
                }}
              >
                -{formatCurrency(totals.discount)}
              </Table.Cell>
            </Table.Row>
          ) : null}
          {totals.usedCredits ? (
            <Table.Row>
              <Table.Cell colSpan="3" style={{ padding: "0.5em 0em" }}>
                Credits Applied
              </Table.Cell>
              <Table.Cell
                style={{
                  padding: "0.5em 0em",
                  textAlign: "end",
                  color: "#079e42",
                }}
              >
                -{formatCurrency(totals.usedCredits)}
              </Table.Cell>
            </Table.Row>
          ) : null}
          <Table.Row>
            <Table.Cell colSpan="3" style={{ padding: "0.5em 0em" }}>
              Processing
            </Table.Cell>
            <Table.Cell style={{ textAlign: "end", padding: "0.5em 0em" }}>
              {formatCurrency(totals.processing)}
            </Table.Cell>
          </Table.Row>
          {inputs?.TermsCode?.CodeDescription ? (
            <Table.Row>
              <Table.Cell colSpan="3" style={{ padding: "0.5em 0em" }}>
                Currently you are on
              </Table.Cell>
              <Table.Cell
                style={{
                  textAlign: "end",
                  padding: "0.5em 0em",
                  color: "#079e42",
                }}
              >
                {inputs?.TermsCode?.CodeDescription}
              </Table.Cell>
            </Table.Row>
          ) : null}
          <Table.Row style={{ fontWeight: "bold" }}>
            <Table.Cell colSpan="3" style={{ padding: "0.5em 0em" }}>
              Total
            </Table.Cell>
            <Table.Cell
              style={{
                textAlign: "end",
                padding: "0.5em 0em",
                color: "#079e42",
              }}
            >
              {formatCurrency(totals.total - totals.usedCredits)}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Field style={{ display: "flex", justifyContent: "space-between" }}>
        {creditLayers ? (
          <div>
            <Label>
              <Checkbox
                checked={inputs.credits}
                name="credits"
                onChange={(e) => onChange(e.target.name, e.target.checked)}
              />
              &nbsp;&nbsp;Credits available:&nbsp;&nbsp;
            </Label>
            <span
              style={{
                color: totals.usedCredits > 0 ? "#4a4a4a" : "#079e42",
                textDecoration:
                  totals.usedCredits > 0 ? "line-through" : "none",
              }}
            >
              {formatCurrency(creditLayers)}
            </span>
            {totals.usedCredits > 0 ? (
              <span
                style={{
                  color: "#079e42",
                }}
              >
                &nbsp;{formatCurrency(creditLayers - totals.usedCredits)}
              </span>
            ) : null}
          </div>
        ) : null}
      </Field>
      <br />
      <Field
        kind="group"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Control expanded>
          <Label>Coupon discount</Label>
          <Input
            name="CouponCode"
            placeholder="Coupon discount"
            size="small"
            value={inputs.CouponCode?.Code || inputs.CouponCode}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>&nbsp;</Label>
          <Button size="small" type="button" onClick={applyCoupon}>
            {inputs.CouponCode?.CouponID ? "Remove" : "Apply"}
          </Button>
        </Control>
        <SystemCodeSelect
          removeEmpty
          label="Processing Option"
          name="ProcessingOption"
          size="small"
          value={inputs.ProcessingOption}
          where={processingOptionWhere}
          onChange={onChange}
        />
      </Field>
      <br />
      <Field>
        <Label>
          <Checkbox
            checked={inputs.useSavedBillingAddress}
            name="useSavedBillingAddress"
            onChange={(e) => onChange(e.target.name, e.target.checked)}
          />
          &nbsp; Use saved Billing Addresses
        </Label>
      </Field>
      {!inputs.useSavedBillingAddress && (
        <div>
          <Field kind="group">
            <Control expanded>
              <Label>First Name</Label>
              <Input
                name="BillingFirstname"
                size="small"
                value={inputs.BillingFirstname}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded>
              <Label>Last Name</Label>
              <Input
                name="BillingLastname"
                size="small"
                value={inputs.BillingLastname}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field>
            <Control expanded>
              <Label>Company</Label>
              <Input
                name="BillingCompany"
                size="small"
                value={inputs.BillingCompany}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field>
            <Control expanded>
              <Label>Address</Label>
              <Input
                name="BillingAddress"
                size="small"
                value={inputs.BillingAddress}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field>
            <Control expanded>
              <Label>Address 2</Label>
              <Input
                name="BillingAddress2"
                size="small"
                value={inputs.BillingAddress2}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded>
              <Label>City</Label>
              <Input
                name="BillingCity"
                size="small"
                value={inputs.BillingCity}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded>
              <Label>State</Label>
              <Input
                name="BillingState"
                size="small"
                value={inputs.BillingState}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded>
              <Label>Zip/Postal</Label>
              <Input
                name="BillingZip"
                size="small"
                value={inputs.BillingZip}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
        </div>
      )}
      <hr />
      <div style={{ textAlign: "center", marginBottom: "1rem" }}>
        <Label>
          <Checkbox
            checked={inputs.terms}
            name="terms"
            onChange={(e) => onChange(e.target.name, e.target.checked)}
          />
          &nbsp; I agree to the&nbsp;
          <a
            className="green"
            href={
              user?.LabState === "AZ"
                ? "https://www.smithers.com/arizona-terms-conditions"
                : "https://actlab.com/terms-and-conditions/"
            }
            rel="noreferrer"
            target="_blank"
          >
            terms and conditions
          </a>
        </Label>
      </div>
      <div style={{ textAlign: "center" }}>
        <Button color="primary" disabled={!inputs.terms} onClick={onCheckout}>
          Checkout
        </Button>
      </div>
    </Box>
  );
};
CartCheckoutForm.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  totals: PropTypes.object.isRequired,
  onCheckout: PropTypes.func.isRequired,
  creditLayers: PropTypes.number,
  user: PropTypes.object.isRequired,
  applyCoupon: PropTypes.func,
  hasMicro: PropTypes.bool,
};
CartCheckoutForm.defaultProps = {
  inputs: {},
  onChange: () => null,
  creditLayers: 0,
  applyCoupon: () => null,
  hasMicro: false,
};
export default CartCheckoutForm;
