import React from "react";
import { Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";

import EquipmentListPage from "./EquipmentListPage";
import EquipmentEditPage from "./EquipmentEditPage";

const EquipmentRouter = ({ routePermissions }) => (
  <Switch>
    <Route path="/equipment/:EquipmentID">
      <EquipmentEditPage routePermissions={routePermissions} />
    </Route>
    <Route path="/equipment">
      <EquipmentListPage routePermissions={routePermissions} />
    </Route>
  </Switch>
);

EquipmentRouter.propTypes = {
  routePermissions: PropTypes.array,
};

EquipmentRouter.defaultProps = {
  routePermissions: [],
};

export default EquipmentRouter;
