/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { getRoutes } from "../Navigation/routes";
import { useAuth } from "../../context";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { state: authState } = useAuth();

  const [, path] = rest.location.pathname.split("/");

  const routes = getRoutes(authState?.loginType);

  const availableRoutes = routes.filter((route) =>
    Array.isArray(route.permissions)
      ? route.permissions?.some((perm) =>
          authState?.permissions?.some((x) => perm === x.Code)
        )
      : true
  );

  let routePermissions = [];
  
  const checkPermissions = (to, permissions) => {
    if (to === path) {
      routePermissions = authState.permissions.filter((perm) =>
        permissions.some((p) => p === perm.Code)
      );
      return true;
    }

    return false;
  };
  
  const isAuth = availableRoutes
    .map((item) => {
      if (item.to) {
        const [, to] = item.to.split("/");
        return checkPermissions(to, item.permissions);
      }
      if (item.root) {
        const [, to] = item.root.split("/");
        return checkPermissions(to, item.permissions);
      }
      return true;
    })
    .includes(true);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? (
          <Component {...props} routePermissions={routePermissions} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  component: PropTypes.any.isRequired,
};
