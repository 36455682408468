import { gql } from "@apollo/client";

export const DELETE_TEST_ANALYTE_MUTATION = gql`
  mutation DELETE_TEST_ANALYTE_MUTATION($where: TestAnalytesWhereUniqueInput!) {
    deleteTestAnalytes(where: $where) {
      TestAnalyteID
    }
  }
`;

export const CREATE_TEST_ANALYTE_MUTATION = gql`
  mutation CREATE_TEST_ANALYTE_MUTATION($data: TestAnalytesCreateInput!) {
    createTestAnalytes(data: $data) {
      TestAnalyteID
      TestID
    }
  }
`;

export const UPDATE_TEST_ANALYTE_MUTATION = gql`
  mutation UPDATE_TEST_ANALYTE_MUTATION(
    $data: TestAnalytesUpdateInput!
    $where: TestAnalytesWhereUniqueInput!
  ) {
    updateTestAnalytes(data: $data, where: $where) {
      TestAnalyteID
      TestID
    }
  }
`;

export const CREATE_MANY_TEST_ANALYTE_MUTATION = gql`
  mutation CREATE_MANY_TEST_ANALYTE_MUTATION(
    $data: [TestAnalytesCreateManyInput!]!
  ) {
    createManyTestAnalytes(data: $data) {
      count
    }
  }
`;

export const DELETE_MANY_TEST_ANALYTES_MUTATION = gql`
  mutation DELETE_MANY_TEST_ANALYTES_MUTATION($where: TestAnalytesWhereInput) {
    deleteManyTestAnalytes(where: $where) {
      count
    }
  }
`;
