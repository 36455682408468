const productCategoryMatrix = {
  flower: [
    "Biomass",
    "Cannagars/Cannarillos",
    "Clone",
    "Flower",
    "Flower - Cured",
    "Flower - Uncured",
    "Flower - Wet",
    "Flower/Trim - Mixed",
    "Fresh Frozen",
    "Plant",
    "Popcorn Buds",
    "Pre-Roll",
    "Root",
    "Seed",
    "Sprouts",
    "Stem/Stalk",
    "Sugar Leaf",
    "Trim",
  ],
  gummy: ["Gummy", "Soft Chew"],
  mip: [
    "Air",
    "All",
    "Baked Goods",
    "Bath Bomb",
    "Bath Salts",
    "Batter/Badder",
    "Beverage",
    "Body Oil",
    "Bottle Rinse",
    "Budder",
    "Capsule/Tablet",
    "Caviar",
    "Caviar Preroll",
    "Chocolate",
    "Concentrate/Extract - Liquid",
    "Concentrate/Extract - Other",
    "Concentrate/Extract - Solid",
    "Concentrates/Extracts",
    "Crude",
    "Crumble",
    "Crystalline Solid",
    "Diamonds",
    "Distillate",
    "Edible/Food",
    "Emulsion",
    "Enhanced/Infused Preroll",
    "Full Extract Cannabis Oil",
    "Hard Lozenge",
    "Hash - Hard",
    "Hash - Ice Water",
    "Hash - Oil",
    "Honeycomb",
    "Ice Cream",
    "Ingestible - Other",
    "Ingestibles",
    "Isolate",
    "Kief",
    "Leaf - Fresh",
    "Liquid Fats (Oils)",
    "Live Resin",
    "Live Rosin",
    "Live Budder",
    "Lotion/Salve/Balm",
    "Moon Rock",
    "Orally-Dissolving Product",
    "Patch",
    "Pet/Animal Products",
    "Press",
    "RSO",
    "Rosin",
    "Sauce",
    "Shatter",
    "Shisha",
    "Soap",
    "Soil",
    "Solid Fats (Butter, Sat Fats)",
    "Squeeze",
    "Sugar Wax",
    "Suppository",
    "Swabs",
    "Syrup/Honey",
    "THC Drops",
    "Tarantula",
    "Tincture",
    "Topical - Other",
    "Topicals",
    "Transdermal Patch",
    "Vape - Oil",
    "Vape Hardware",
    "Vape Oil",
    "Water",
    "Wax",
  ],
};

export default productCategoryMatrix;
