/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  Title,
  Fieldset,
  Button,
  Control,
  Input,
  Field,
  Label,
  File,
} from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAuth } from "../../../../../../context/AuthContext";
import {
  customToast as toast,
  downloadCSV,
  csvToArray,
} from "../../../../../../utils";
import QCTargetForm from "../QCTargetForm";
import { Loader } from "../../../../../../components";
import {
  CREATE_QC_TARGET_MUTATION,
  ALL_QC_TARGETS_QUERY,
  SINGLE_QC_TARGET_QUERY,
  UPDATE_QC_TARGET_MUTATION,
  ALL_ANALYTES_QUERY,
} from "../../../../../../graphql";

const INITIAL_STATE = {
  Name: "",
  States: "",
  Active: true,
  Analytes: [],
};

const COLUMNS = [
  {
    Header: "Name",
    id: "Name",
    accessor: "Name",
  },
  {
    Header: "TargetValue",
    id: "TargetValue",
    accessor: "TargetValue",
  },
];

const QCTargetModal = ({ onComplete, QCTargetID, disabled }) => {
  const { state: authState } = useAuth();

  const [loading, setLoading] = useState(!!QCTargetID);
  const [csvFileName, setCSVFileName] = useState("Choose a file");
  const [inputs, setInputs] = useState({
    ...INITIAL_STATE,
  });

  const [getQCTargetData, resultQCTargetData] = useLazyQuery(
    SINGLE_QC_TARGET_QUERY
  );
  const [getAnalytesData, resultAnalytesData] =
    useLazyQuery(ALL_ANALYTES_QUERY);
  const [createQCTarget] = useMutation(CREATE_QC_TARGET_MUTATION);
  const [updateQCTarget] = useMutation(UPDATE_QC_TARGET_MUTATION);

  useEffect(() => {
    if (QCTargetID) {
      getQCTargetData({
        variables: {
          where: { QCTargetID: parseInt(QCTargetID, 10) },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [QCTargetID, getQCTargetData]);

  useEffect(() => {
    if (resultQCTargetData?.data?.findUniqueQCTargets) {
      const {
        data: { findUniqueQCTargets },
      } = resultQCTargetData;

      const existingTargetValues = Object.keys(
        findUniqueQCTargets.TargetValues
      )?.reduce((accumulator, key) => {
        accumulator[key.toLowerCase().trim()] =
          findUniqueQCTargets.TargetValues[key];
        return accumulator;
      }, {});

      setInputs((prev) => ({
        ...prev,
        ...findUniqueQCTargets,
        TargetValues: existingTargetValues,
      }));
      setLoading(false);
    }
  }, [resultQCTargetData]);

  useEffect(() => {
    if (inputs.TestCategoryID) {
      getAnalytesData({
        variables: {
          where: {
            TestCategoryID: { equals: parseInt(inputs.TestCategoryID, 10) },
          },
          orderBy: {
            Name: "asc",
          },
        },
      });
    }
  }, [getAnalytesData, inputs.TestCategoryID]);

  useEffect(() => {
    if (resultAnalytesData?.data?.findManyAnalytes) {
      const {
        data: { findManyAnalytes },
      } = resultAnalytesData;
      const test = findManyAnalytes.map((analyte) => ({
        ...analyte,
        TargetValue: inputs.TargetValues
          ? inputs.TargetValues[analyte.Name.toLowerCase().trim()] || null
          : null,
      }));
      setInputs((prev) => ({
        ...prev,
        Analytes: [...test],
      }));
      setLoading(false);
    }
  }, [resultAnalytesData, inputs.TargetValues]);

  const handleChange = (fieldName, value, analyte, index) => {
    if (fieldName === "TargetValue") {
      setInputs((prev) => {
        const analyteObj = prev.Analytes[index];
        const element = {
          ...analyteObj,
          [fieldName]: value,
        };

        const newAnalytes = [...prev.Analytes];
        newAnalytes[index] = element;
        return {
          ...prev,
          Analytes: [...newAnalytes],
        };
      });
    } else {
      setInputs((prev) => ({
        ...prev,
        [fieldName]: value,
      }));
    }
  };

  const handleExportCSV = () => {
    downloadCSV(
      null,
      {
        query: null,
        where: null,
        orderByMulti: null,
      },
      "QCTargetValues",
      COLUMNS,
      inputs.Analytes
    );
  };

  const handleCSVUpload = ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (validity.valid) {
      const reader = new FileReader();
      // eslint-disable-next-line func-names
      reader.onload = function (e) {
        const data = csvToArray(reader.result);
        if (!data) {
          toast.error("Please upload a valid .csv file.");
          return;
        }

        const analytes = data.reduce((acc, curr) => {
          if (curr.Name && curr.TargetValue) {
            acc.push({
              Name: curr.Name.replace(/['"]+/g, ""),
              TargetValue: curr.TargetValue.replace(/['"]+/g, ""),
            });
          } else if (curr.Name) {
            acc.push({
              Name: curr.Name.replace(/['"]+/g, ""),
              TargetValue: "",
            });
          }
          return acc;
        }, []);

        if (!analytes.length) {
          toast.error("Please upload a valid .csv file.");
          return;
        }

        setInputs((prev) => ({
          ...prev,
          Analytes: analytes,
        }));

        setCSVFileName(file.name);
      };
      reader.readAsText(file);
    } else {
      toast.error("Please upload a valid .csv file.");
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const targetValues = inputs.Analytes.reduce((acc, analyte) => {
        const { Name, TargetValue } = analyte;
        return { ...acc, [Name]: TargetValue || 0 };
      }, {});
      if (QCTargetID) {
        const data = {
          Name: {
            set: inputs.Name,
          },
          QCSampleType: {
            connect: {
              QCSampleTypeID: parseInt(inputs.QCSampleTypeID, 10),
            },
          },
          TestCategory: {
            connect: {
              TestCategoryID: parseInt(inputs.TestCategoryID, 10),
            },
          },
          States: {
            set: Array.isArray(inputs.States)
              ? inputs.States.join(",")
              : inputs.States,
          },
          TargetValues: targetValues,
          ModifiedBy: { set: authState?.user?.Username },
        };
        await updateQCTarget({
          variables: {
            data,
            where: {
              QCTargetID: parseInt(QCTargetID, 10),
            },
          },
          refetchQueries: [
            {
              query: ALL_QC_TARGETS_QUERY,
              variables: {
                where: {},
              },
              fetchPolicy: "network-only",
            },
            {
              query: SINGLE_QC_TARGET_QUERY,
              variables: {
                where: { QCTargetID: parseInt(QCTargetID, 10) },
              },
              fetchPolicy: "network-only",
            },
          ],
        });

        toast.success("QC Target updated successfully.");
      } else {
        const data = {
          Name: inputs.Name,
          QCSampleType: {
            connect: {
              QCSampleTypeID: parseInt(inputs.QCSampleTypeID, 10),
            },
          },
          TestCategory: {
            connect: {
              TestCategoryID: parseInt(inputs.TestCategoryID, 10),
            },
          },
          States: inputs.States.join(","),
          TargetValues: targetValues,
          CreatedBy: authState?.user?.Username,
        };
        await createQCTarget({
          variables: {
            data,
          },
          refetchQueries: [
            {
              query: ALL_QC_TARGETS_QUERY,
              variables: {
                where: {},
              },
            },
          ],
        });
        toast.success("QC Target created successfully.");
      }
      onComplete();
    } catch (err) {
      toast.error(
        `Error ${QCTargetID ? "updating" : "creating"} QC Target. ${
          err.message
        }`
      );
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader style={{ height: "330px" }} />;
  }

  return (
    <React.Fragment>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>{QCTargetID ? "Update" : "Create"} QC Target</Title>
        </div>
        <div className="page-head-end">
          <Button.Group>
            <Button
              color="primary"
              size="small"
              type="button"
              onClick={onComplete}
            >
              <span>Cancel</span>
            </Button>
            {!disabled && (
              <Button
                color="primary"
                form="qc-target-form"
                size="small"
                type="submit"
              >
                <span>Submit</span>
              </Button>
            )}
          </Button.Group>
        </div>
      </header>
      <hr />
      <Fieldset>
        <QCTargetForm
          adding
          disabled={disabled}
          formId="qc-target-form"
          inputs={inputs}
          onChange={handleChange}
          onSubmit={handleSave}
        />
      </Fieldset>
      <br />
      <div className="main-table-container">
        <Field kind="group">
          <Control>
            <Title subtitle>Target Values</Title>
          </Control>
          {!disabled && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Control style={{ marginRight: "1rem" }}>
                <Label>&nbsp;</Label>
                <Button
                  color="primary"
                  size="small"
                  type="button"
                  onClick={handleExportCSV}
                >
                  <span>.csv Download</span>
                </Button>
              </Control>
              <Control>
                <Label>Upload QC Target Values .csv File</Label>
                <File hasName color="primary" size="small">
                  <File.Label>
                    <File.Input
                      accept=".csv"
                      disabled={disabled}
                      name="file"
                      onChange={handleCSVUpload}
                    />
                    <File.CTA>
                      <File.Icon>
                        <FontAwesomeIcon icon="upload" />
                      </File.Icon>
                    </File.CTA>
                    <File.Name>{csvFileName}</File.Name>
                  </File.Label>
                </File>
              </Control>
            </div>
          )}
        </Field>
        <hr />
        <div
          className="row-container body"
          style={{
            gridTemplateColumns: `1fr repeat(1, 1fr)`,
          }}
        >
          {inputs.Analytes?.map((element, index) => (
            <div
              className="row-container body"
              style={{
                marginRight: "2rem",
                marginLeft: "2rem",
                gridTemplateColumns: `1fr repeat(1, 1fr)`,
              }}
            >
              <Control>
                <Input readOnly size="small" value={element.Name} />
              </Control>
              <Control>
                <Input
                  disabled={disabled}
                  name="TargetValue"
                  size="small"
                  value={element.TargetValue}
                  onChange={(e) =>
                    handleChange(e.target.name, e.target.value, element, index)
                  }
                />
              </Control>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

QCTargetModal.propTypes = {
  onComplete: PropTypes.func,
  QCTargetID: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

QCTargetModal.defaultProps = {
  onComplete: (e) => e,
  QCTargetID: null,
};

export default QCTargetModal;
