import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import { useAuth } from "../../../context/AuthContext";
import { DataTable, PageHeader, ListSearchInput } from "../../../components";
import {
  UPDATE_JOB_ORDER_MUTATION,
  ALL_JOB_ORDERS_AND_LEGACY_JOB_ORDERS_QUERY,
  UPDATE_LEGACY_JOB_ORDER_MUTATION,
} from "../../../graphql";
import { generateColumns } from "./columns";
import { debounce } from "../../../utils";

const CoasListPage = () => {
  const { state: authState } = useAuth();
  const {
    user: { UserID, ChildClientIDs },
  } = authState;

  const INITIAL_STATE = {
    UserID: {
      in: [UserID, ...ChildClientIDs],
    },
    JobOrderStatus: {
      is: {
        CodeDescription: { equals: "Completed" },
      },
    },
  }

  const [where, setWhere] = useState(INITIAL_STATE);

  const [updateJobOrders] = useMutation(UPDATE_JOB_ORDER_MUTATION);
  const [updateLegacyJobOrders] = useMutation(UPDATE_LEGACY_JOB_ORDER_MUTATION);

  const handleReset = () => {
    setWhere(INITIAL_STATE);
  };


  const handleUpdate = async (JobOrderID, name, value, isLegacy = false) => {
    if (isLegacy) {
      await updateLegacyJobOrders({
        variables: {
          where: {
            LegacyJobOrderID: JobOrderID,
          },
          data: {
            [name]: {
              set: value,
            },
          },
        },
        refetchQueries: [
          {
            query: ALL_JOB_ORDERS_AND_LEGACY_JOB_ORDERS_QUERY,
            variables: {
              where,
            },
            fetchPolicy: "network-only",
          },
        ],
      });
    } else {
      await updateJobOrders({
        variables: {
          where: {
            JobOrderID,
          },
          data: {
            [name]: {
              set: value,
            },
          },
        },
        refetchQueries: [
          {
            query: ALL_JOB_ORDERS_AND_LEGACY_JOB_ORDERS_QUERY,
            variables: {
              where,
            },
            fetchPolicy: "network-only",
          },
        ],
      });
    }
  };

  const orderBy = [{ id: "OrderCreateDate", desc: true }];

  const COLUMNS = generateColumns(handleUpdate);

  return (
    <div>
      <PageHeader title="Manage Certificate of Analysis Files">
        <ListSearchInput
          onChange={debounce((name, value) => {
            if (value) {
              const newWhere = {
                ...INITIAL_STATE,
                ...where,
              };
              if (/^[0-9]+$/.test(value)) {
                newWhere.JobID = { equals: parseInt(value, 10) };
                newWhere.JobOrderID = { equals: parseInt(value, 10) };
              } else {
                newWhere.OrderName = { contains: value };
                newWhere.BatchOrderNumber = { contains: value };
              }
              setWhere(newWhere);
            } else {
              handleReset();
            }
          }, 500)}
        />
      </PageHeader>
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateJobOrdersAndLegacyJobOrders"
        columns={COLUMNS}
        hiddenColumns={
          !ChildClientIDs.length
            ? ["Username", "OrderCompleteDate", "OrderCreateDate"]
            : ["OrderCompleteDate", "OrderCreateDate"]
        }
        name="findManyJobOrdersAndLegacyJobOrders"
        orderBy={orderBy}
        query={ALL_JOB_ORDERS_AND_LEGACY_JOB_ORDERS_QUERY}
        where={where}
      />
    </div>
  );
};

export default CoasListPage;
