/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import DataTable from "../../../components/DataTable";
import { IconButton, Loader, PageHeader } from "../../../components";
import { generateColumns } from "./columns";

import {
  LIST_USER_GROUPS_QUERY,
  DELETE_USER_GROUP_MUTATION,
  DELETE_MANY_USER_GROUP_PERMISSIONS_MUTATION,
} from "../../../graphql";

import { useModal } from "../../../context";
import UserGroupModal from "../components/UserGroupModal";

import Confirmation from "../../../components/Confirmation";

const UserGroupListPage = ({ routePermissions }) => {
  const { setModalOpen } = useModal();

  const [loading, setLoading] = useState(false);

  const [canUpdate, setCanUpdate] = useState(true);

  const [deleteUserGroup] = useMutation(DELETE_USER_GROUP_MUTATION);
  const [deleteManyUserGroupPermissions] = useMutation(
    DELETE_MANY_USER_GROUP_PERMISSIONS_MUTATION
  );

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  const handleAddClick = () => {
    setModalOpen(
      true,
      <UserGroupModal onComplete={() => setModalOpen(false, "")} />
    );
  };

  const handleRowClick = ({ GroupID }) => {
    setModalOpen(
      true,
      <UserGroupModal
        GroupID={GroupID}
        disabled={!canUpdate}
        onComplete={() => setModalOpen(false, "")}
      />
    );
  };

  const COLUMNS = useMemo(() => {
    const handleDelete = (userGroup) => {
      const performDelete = async () => {
        try {
          setModalOpen(false, "");
          setLoading(true);

          await deleteManyUserGroupPermissions({
            variables: {
              where: {
                GroupID: {
                  equals: parseInt(userGroup.GroupID, 10),
                },
              },
            },
          });

          await deleteUserGroup({
            variables: {
              where: {
                GroupID: parseInt(userGroup.GroupID, 10),
              },
            },
            refetchQueries: [
              {
                query: LIST_USER_GROUPS_QUERY,
              },
            ],
          });
          toast.success("User Group deleted successfully.");
        } catch (err) {
          toast.error(`Error deleting User Group.`);
        } finally {
          setLoading(false);
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this User Group?"
          onCancel={() => setModalOpen(false, "")}
          onConfirm={performDelete}
        />
      );
    };
    return generateColumns(handleDelete);
  }, [deleteUserGroup, setModalOpen]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="user-group-list-page">
      <PageHeader title="User Groups">
        {canUpdate && <IconButton icon="plus" onClick={handleAddClick} />}
      </PageHeader>
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateUserGroups"
        columns={COLUMNS}
        fetchPolicy="cache-and-network"
        name="findManyUserGroups"
        orderBy={[{ id: "GroupID", desc: false }]}
        query={LIST_USER_GROUPS_QUERY}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

UserGroupListPage.propTypes = {
  routePermissions: PropTypes.array,
};

UserGroupListPage.defaultProps = {
  routePermissions: [],
};

export default UserGroupListPage;
