const homogeneityProductCategoryCodeDescriptions = [
  "Gummy",
  "Soft Chew",
  "Baked Goods",
  "Beverage",
  "Capsule/Tablet",
  "Chocolate",
  "Edible/Food",
  "Emulsion",
  "Hard Lozenge",
  "Ice Cream",
  "Orally-Dissolving Product",
  "Syrup/Honey",
];

export default homogeneityProductCategoryCodeDescriptions;
