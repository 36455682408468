const accounting = {
  name: "accounting",
  label: "Accounting",
  icon: "dollar-sign",
  permissions: ["ACCT"],
  active: (location) => {
    const [, p] = location.pathname.split("/");
    return /accounting/.test(p);
  },
  links: [
    {
      name: "Payment Ledger",
      to: "/accounting/payment-ledger",
    },
    {
      name: "Private Label Commissions",
      to: "/accounting/private-label-commissions",
    },
  ],
  root: "/accounting",
};

export default accounting;
