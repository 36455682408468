import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Control,
  Input,
  Label,
  Box,
  Column,
  Field,
  Select,
  Icon,
  Button,
} from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { customToast as toast, clampNumber } from "../../../../utils";
import { BooleanInput } from "../../../../components";

const PrivateLabel = ({
  inputs,
  onChange,
  handleSave,
  disabled,
  handleUpdatePrivateLabelPassword,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleBlur = (e) => {
    e.preventDefault();
    if (parseInt(e.target.value, 10) > 50) {
      onChange(e.target.name, clampNumber(parseInt(e.target.value, 10), 1, 50));
      toast.warning("Commission must be lower than 50%");
    } else {
      onChange(e.target.name, e.target.value);
    }
  };

  const toggleShowPassword = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPassword(!showPassword);
  };

  return (
    <form id="edit-client-form" onSubmit={handleSave}>
      <Column.Group style={{ justifyContent: "center" }}>
        <Column size={6}>
          <Box>
            <Field>
              <Control expanded>
                <Label>&nbsp;</Label>
                <BooleanInput
                  disabled={disabled}
                  label="Activate this user as a Private Label Client"
                  name="Status"
                  value={inputs.Status}
                  onChange={(name, value) => onChange(name, value ? 1 : 0)}
                />
              </Control>
            </Field>
            <Field>
              <Control expanded>
                <Label>Rev. Share %</Label>
                <Select.Container fullwidth size="small">
                  <Select
                    disabled={disabled}
                    name="RevShare"
                    value={inputs.RevShare}
                    onBlur={handleBlur}
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                  >
                    {[...Array(51).keys()].map((x) => (
                      <Select.Option key={x} value={x}>
                        {x}
                      </Select.Option>
                    ))}
                  </Select>
                </Select.Container>
              </Control>
            </Field>
            <Field>
              <Control expanded>
                <Label>Private Label Username</Label>
                <Input
                  disabled={disabled}
                  name="Username"
                  size="small"
                  type="text"
                  value={inputs.Username}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                />
              </Control>
            </Field>
            {inputs.adding ? (
              <Field>
                <Control expanded>
                  <Label>Private Label Password</Label>
                  <Input
                    disabled={disabled}
                    name="Password"
                    size="small"
                    type={showPassword ? "text" : "password"}
                    value={inputs.Password}
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                  />
                  <Icon
                    className="password-visibility"
                    onClick={toggleShowPassword}
                  >
                    <FontAwesomeIcon
                      icon={showPassword ? "eye" : "eye-slash"}
                    />
                  </Icon>
                </Control>
              </Field>
            ) : (
              <React.Fragment>
                <br />
                <Field>
                  <Button
                    color="primary"
                    size="small"
                    onClick={handleUpdatePrivateLabelPassword}
                  >
                    Update Private Label Password
                  </Button>
                </Field>
              </React.Fragment>
            )}
          </Box>
        </Column>
      </Column.Group>
    </form>
  );
};

PrivateLabel.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  handleSave: PropTypes.func,
  disabled: PropTypes.bool,
  handleUpdatePrivateLabelPassword: PropTypes.func,
};

PrivateLabel.defaultProps = {
  inputs: {},
  onChange: () => null,
  handleSave: () => null,
  disabled: false,
  handleUpdatePrivateLabelPassword: () => null,
};

export default PrivateLabel;
