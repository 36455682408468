import React from "react";
import PropTypes from "prop-types";

import { Switch, Route } from "react-router-dom";

import LabListPage from "./LabListPage";
import LabPage from "./LabPage";

const LabRouter = ({ routePermissions }) => (
  <Switch>
    <Route path="/labs/lab/:LabID/:tab">
      <LabPage routePermissions={routePermissions} />
    </Route>
    <Route path="/labs">
      <LabListPage routePermissions={routePermissions} />
    </Route>
  </Switch>
);

LabRouter.propTypes = {
  routePermissions: PropTypes.array,
};

LabRouter.defaultProps = {
  routePermissions: [],
};

export default LabRouter;
