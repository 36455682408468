/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Button } from "rbx";
import { toast } from "react-toastify";
import { useApolloClient } from "@apollo/client";
import { TableCell } from "../../../components/DataTable/TableCell";
import SwitchInput from "../../../components/SwitchInput";
import VerifyHempCategorySelect from "../../../components/VerifyHempCategorySelect/VerifyHempCategorySelect";

import { FIND_FIRST_SNAPSHOT_QUERY } from "../../../graphql";

const { REACT_APP_COA_URL } = process.env;

const DEFAULT_COLUMNS = [
  {
    Header: "User",
    id: "Username",
    accessor: "User.Username",
  },
  {
    Header: "Job ID",
    id: "JobID",
    accessor: "JobID",
  },
  {
    Header: "Order ID",
    id: "JobOrderID",
    accessor: "JobOrderID",
  },
  {
    Header: "Order Name",
    id: "OrderName",
    accessor: "OrderName",
    Cell: ({ cell, row }) => (
      <TableCell.Wrapped
        value={row.original?.OverrideData?.OrderName || cell.value}
      />
    ),
  },
  {
    Header: "Batch Number",
    id: "BatchOrderNumber",
    accessor: "BatchOrderNumber",
    Cell: ({ cell, row }) =>
      row.original?.OverrideData?.BatchOrderNumber || cell.value,
  },
  {
    Header: "CoA",
    id: "CoA",
    name: "COA download",
    Cell: ({ cell, row }) => {
      const client = useApolloClient();
      const [loading, setLoading] = useState(false);

      const handleClick = async () => {
        try {
          setLoading(true);
          const { data: FindFirstSnapshotData } = await client.query({
            query: FIND_FIRST_SNAPSHOT_QUERY,
            variables: {
              where: {
                JobOrderID: { equals: parseInt(row.original.JobOrderID, 10) },
                PrivateLabelCOAID: { equals: null },
              },
              orderBy: [{ Version: "desc" }],
            },
            fetchPolicy: "network-only",
          });
          const snapshot = FindFirstSnapshotData?.findFirstSnapshots;
          if (!snapshot) {
            throw new Error();
          }

          window.open(
            `${REACT_APP_COA_URL}/coa/pdf/${row.original.JobOrderID}/${snapshot.Version}`,
            "_blank"
          );
        } catch (err) {
          toast.error(
            "Whoops! We encountered an error opening this COA. Please try again."
          );
        } finally {
          setLoading(false);
        }
      };

      if (!row.original.LegacyJobOrderID) {
        return (
          <Button
            as="a"
            color="primary"
            state={loading ? "loading" : ""}
            style={{ borderRadius: "20px 0 20px 20px" }}
            target="_blank"
            onClick={handleClick}
          >
            Download
          </Button>
        );
      }

      return row.original.COA ? (
        <Button
          as="a"
          color="primary"
          href={row.original.COA}
          style={{ borderRadius: "20px 0 20px 20px" }}
          target="_blank"
        >
          View
        </Button>
      ) : (
        <span />
      );
    },
    disableSortBy: true,
  },
  {
    Header: "QRCode",
    id: "QRCode",
    accessor: "JobOrderID",
    Cell: ({ cell, row }) => {
      if (row.original.LegacyJobOrderID) {
        return row.original.COA ? (
          <TableCell.Image alt="Testing" value={row.original.COA} />
        ) : (
          <span />
        );
      }
      const jobHexCode = Number(row.original.JobID).toString(16);
      const orderHexCode = Number(cell.value).toString(16);
      return (
        <TableCell.Image
          alt="Testing"
          value={`https://www.verifycbd.com/reportv2/${jobHexCode}z${orderHexCode}`}
        />
      );
    },
    disableSortBy: true,
  },
  {
    Header: "Public URL",
    id: "PublicURL",
    accessor: "JobOrderID",
    Cell: ({ cell, row }) => {
      if (row.original.LegacyJobOrderID) {
        return row.original.COA ? (
          <Button
            color="primary"
            style={{ borderRadius: "20px 0 20px 20px" }}
            onClick={async () => {
              await navigator.clipboard.writeText(row.original.COA);
            }}
          >
            Copy
          </Button>
        ) : (
          <span />
        );
      }
      const jobHexCode = Number(row.original.JobID).toString(16);
      const orderHexCode = Number(cell.value).toString(16);
      return (
        <Button
          color="primary"
          style={{ borderRadius: "20px 0 20px 20px" }}
          onClick={async () => {
            await navigator.clipboard.writeText(
              `https://www.verifycbd.com/reportv2/${jobHexCode}z${orderHexCode}`
            );
          }}
        >
          Copy
        </Button>
      );
    },
    disableSortBy: true,
  },
  {
    Header: "One Pager",
    id: "OP",
    accessor: "OP",
    Cell: ({ cell, row }) => {
      const client = useApolloClient();
      const [loading, setLoading] = useState(false);

      const handleClick = async () => {
        try {
          setLoading(true);
          const { data: FindFirstSnapshotData } = await client.query({
            query: FIND_FIRST_SNAPSHOT_QUERY,
            variables: {
              where: {
                JobOrderID: { equals: parseInt(row.original.JobOrderID, 10) },
                PrivateLabelCOAID: { equals: null },
              },
              orderBy: [{ Version: "desc" }],
            },
            fetchPolicy: "network-only",
          });
          const snapshot = FindFirstSnapshotData?.findFirstSnapshots;
          if (!snapshot) {
            throw new Error();
          }
          window.open(
            `${REACT_APP_COA_URL}/${row.original.User?.COASettings?.COA_SOP}/pdf/${row.original.JobOrderID}/${snapshot.Version}`,
            "_blank"
          );
        } catch (err) {
          toast.error(
            "Whoops! We encountered an error opening this COA. Please try again."
          );
        } finally {
          setLoading(false);
        }
      };

      if (row.original.LegacyJobOrderID) {
        return cell.value ? (
          <Button
            as="a"
            color="primary"
            href={cell.value}
            rel="noreferrer"
            state={loading ? "loading" : ""}
            style={{ borderRadius: "20px 0 20px 20px" }}
            target="_blank"
          >
            Download
          </Button>
        ) : (
          <span />
        );
      }
      return row.original.User?.COASettings?.COA_SOP &&
        row.original.User?.COASettings?.COA_SOP !== "0" ? (
        <Button
          as="a"
          color="primary"
          rel="noreferrer"
          state={loading ? "loading" : ""}
          style={{ borderRadius: "20px 0 20px 20px" }}
          target="_blank"
          onClick={handleClick}
        >
          Download
        </Button>
      ) : (
        <span />
      );
    },
    disableSortBy: true,
  },
  {
    Header: "OrderCompleteDate",
    id: "OrderCompleteDate",
    accessor: "OrderCompleteDate",
  },
  {
    Header: "OrderCreateDate",
    id: "OrderCreateDate",
    accessor: "OrderCreateDate",
  },
];

export const generateColumns = (handleUpdate) => [
  ...DEFAULT_COLUMNS,
  {
    Header: "Show On Verify Hemp",
    id: "ShowOnVerifyHemp",
    accessor: "ShowOnVerifyHemp",
    name: "ShowOnVerifyHemp",
    Cell: ({ cell, row }) => (
      <SwitchInput
        name="ShowOnVerifyHemp"
        value={cell.value}
        onChange={(name) => {
          handleUpdate(
            row.original.LegacyJobOrderID || row.values.JobOrderID,
            name,
            1 - cell.value,
            !!row.original.LegacyJobOrderID
          );
        }}
      />
    ),
    disableSortBy: true,
  },
  {
    Header: "Verify Hemp Category",
    id: "VerifyHempCategorySelect",
    accessor: "VerifyHempCategory",
    Cell: ({ cell, row }) => (
      <VerifyHempCategorySelect
        legacyValue={parseFloat(cell.value) ? "" : cell.value}
        name="VerifyHempCategory"
        setValue={(x) => (typeof x === "string" ? { equals: x } : "")}
        value={cell.value}
        where={{
          UserID: {
            equals: Number(row.original.User?.UserID),
          },
        }}
        onChange={(name, value) => {
          handleUpdate(
            row.original.LegacyJobOrderID || row.values.JobOrderID,
            name,
            value.toString(),
            !!row.original.LegacyJobOrderID
          );
        }}
      />
    ),
    disableSortBy: true,
  },
];

export default DEFAULT_COLUMNS;
