import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";

import { PageHeader, DataTable, IconButton } from "../../../../components";
import { useModal } from "../../../../context";
import TestModal from "../components/TestModal";

import { LIST_TESTS_QUERY } from "../../../../graphql";
import COLUMNS from "./columns";
import "../../Test.scss";

const TestListPage = (props) => {
  const history = useHistory();
  const [modalOpened, setModalOpened] = useState(false);
  const { TestID } = useParams();

  const { setModalOpen } = useModal();
  const where = {};
  const orderBy = [{ id: "Name", desc: false }];
  const [canUpdate, setCanUpdate] = useState(true);

  const handleRowClick = (testItem) => {
    history.push(`/tests/tests-list/${testItem.TestID}`);
    setModalOpened(false);
  };

  const handleCreateTest = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push("/tests/tests-list");
    setModalOpen(
      true,
      <TestModal
        canUpdate={canUpdate}
        onComplete={() => {
          setModalOpen(false);
        }}
      />
    );
  };

  const { routePermissions } = props;

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  useEffect(() => {
    if (TestID && !modalOpened) {
      setModalOpened(true);
      setModalOpen(
        true,
        <TestModal
          TestID={TestID}
          canUpdate={canUpdate}
          onComplete={() => {
            history.push("/tests/tests-list");
            setModalOpen(false);
          }}
        />
      );
    }
  }, [TestID, history, modalOpened, setModalOpen, canUpdate]);

  return (
    <div className="test-list-page">
      <PageHeader title="Tests">
        {canUpdate && <IconButton icon="plus" onClick={handleCreateTest} />}
      </PageHeader>
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateTests"
        columns={COLUMNS}
        name="findManyTests"
        orderBy={orderBy}
        query={LIST_TESTS_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

TestListPage.propTypes = {
  routePermissions: PropTypes.array,
};

TestListPage.defaultProps = {
  routePermissions: [],
};

export default TestListPage;
