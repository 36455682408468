/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Control, Field, Title } from "rbx";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import {
  CREATE_USER_GROUP_MUTATION,
  UPDATE_USER_GROUP_MUTATION,
  LIST_USER_GROUPS_QUERY,
  DELETE_MANY_USER_GROUP_PERMISSIONS_MUTATION,
  SINGLE_USER_GROUP_QUERY,
  CREATE_MANY_USER_GROUP_PERMISSIONS_MUTATION,
  ALL_USER_GROUP_PERMISSIONS_QUERY,
  UPDATE_DIFFERENT_USER_GROUP_PERMISSIONS_MUTATION,
} from "../../../../graphql";

import UserGroupForm from "../UserGroupForm";
import { useAuth } from "../../../../context";
import { Loader } from "../../../../components";
import UserGroupPermissions from "../UserGroupPermissions";

const INITIAL_STATE = {
  GroupID: "",
  Name: "",
  Description: "",
};

const UserGroupCreateModal = ({ GroupID, onComplete, disabled }) => {
  const { state: authState } = useAuth();

  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({ ...INITIAL_STATE });
  const [chmodInputs, setchModInputs] = useState({});
  const [originalPermissionsIds, setOriginalPermissionsIds] = useState([]);

  const [createUserGroup] = useMutation(CREATE_USER_GROUP_MUTATION);
  const [updateUserGroup] = useMutation(UPDATE_USER_GROUP_MUTATION);
  const [createManyUserGroupPermissions] = useMutation(
    CREATE_MANY_USER_GROUP_PERMISSIONS_MUTATION
  );
  const [deleteManyUserGroupPermissions] = useMutation(
    DELETE_MANY_USER_GROUP_PERMISSIONS_MUTATION
  );
  const [updateDifferentUserGroupPermissions] = useMutation(
    UPDATE_DIFFERENT_USER_GROUP_PERMISSIONS_MUTATION
  );

  const [getUserGroup, { data: GetUserGroupData }] = useLazyQuery(
    SINGLE_USER_GROUP_QUERY,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    if (GroupID) {
      getUserGroup({
        variables: {
          where: { GroupID: parseInt(GroupID, 10) },
        },
      });
    }
  }, [GroupID, getUserGroup]);

  useEffect(() => {
    const userGroup = GetUserGroupData?.findUniqueUserGroups;
    if (userGroup) {
      setInputs((prev) => ({
        ...prev,
        ...Object.keys(INITIAL_STATE).reduce(
          (acc, curr) => ({
            ...acc,
            [curr]: userGroup[curr],
          }),
          {}
        ),
      }));
    }
  }, [GetUserGroupData?.findUniqueUserGroups]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const permissionsIds = Object.keys(chmodInputs);

    try {
      setLoading(true);
      const refetchQueries = [
        {
          query: LIST_USER_GROUPS_QUERY,
        },
      ];

      if (GroupID) {
        const permissionsToCreate = [];
        const permissionsToUpdate = [];

        permissionsIds.forEach((permissionID) => {
          if (!originalPermissionsIds.includes(permissionID)) {
            const obj = {
              ...chmodInputs[permissionID],
              PermissionID: parseInt(permissionID, 10),
              GroupID,
              CreatedBy: authState?.user?.Username,
              ModifiedBy: authState?.user?.Username,
            };

            permissionsToCreate.push(obj);
          } else if (originalPermissionsIds.includes(permissionID)) {
            const obj = {
              Update: {
                set: chmodInputs[permissionID].Update,
              },
              View: {
                set: chmodInputs[permissionID].View,
              },
              PermissionID: parseInt(permissionID, 10),
              UserGroupID: parseInt(GroupID, 10),
              ModifiedBy: {
                set: authState?.user?.Username,
              },
            };
            permissionsToUpdate.push(obj);
          }
        });

        const permissionsToDelete = originalPermissionsIds.filter(
          (originalPermissionsID) => {
            const permissionsToUpdateArr = permissionsToUpdate.map(
              (permissionToUpdate) => String(permissionToUpdate.PermissionID)
            );
            if (!permissionsToUpdateArr.includes(originalPermissionsID))
              return originalPermissionsID;
          }
        );

        if (permissionsToCreate.length) {
          await createManyUserGroupPermissions({
            variables: {
              data: permissionsToCreate,
            },
            refetchQueries: [
              {
                query: ALL_USER_GROUP_PERMISSIONS_QUERY,
                variables: {
                  where: { GroupID: { equals: parseInt(GroupID, 10) } },
                },
              },
            ],
          });
        }

        if (permissionsToDelete.length) {
          const arrayToDelete = permissionsToDelete.map(
            (permissionToDelete) => ({
              PermissionID: {
                equals: parseInt(permissionToDelete, 10),
              },
              GroupID: {
                equals: GroupID,
              },
            })
          );
          await deleteManyUserGroupPermissions({
            variables: {
              where: {
                OR: arrayToDelete,
              },
            },
            refetchQueries: [
              {
                query: ALL_USER_GROUP_PERMISSIONS_QUERY,
                variables: {
                  where: { GroupID: { equals: parseInt(GroupID, 10) } },
                },
              },
            ],
          });
        }

        if (permissionsToUpdate.length) {
          await updateDifferentUserGroupPermissions({
            variables: {
              data: permissionsToUpdate,
            },
            refetchQueries: [
              {
                query: ALL_USER_GROUP_PERMISSIONS_QUERY,
                variables: {
                  where: { GroupID: { equals: parseInt(GroupID, 10) } },
                },
              },
            ],
          });
        }

        await updateUserGroup({
          variables: {
            data: {
              Name: { set: inputs.Name },
              Description: { set: inputs.Description },
              ModifiedBy: { set: authState?.user?.Username },
            },
            where: {
              GroupID: parseInt(GroupID, 10),
            },
          },
          refetchQueries,
        });
      } else {
        const newGroup = await createUserGroup({
          variables: {
            data: {
              Name: inputs.Name,
              Description: inputs.Description,
              CreatedBy: authState?.user?.Username,
              ModifiedBy: authState?.user?.Username,
            },
          },
          refetchQueries,
        });

        const permissionsToCreate = [];
        permissionsIds.forEach((permissionID) => {
          const obj = {
            ...chmodInputs[permissionID],
            PermissionID: parseInt(permissionID, 10),
            GroupID: newGroup.data.createUserGroups.GroupID,
            CreatedBy: authState?.user?.Username,
            ModifiedBy: authState?.user?.Username,
          };
          permissionsToCreate.push(obj);
        });

        if (permissionsToCreate.length) {
          await createManyUserGroupPermissions({
            variables: {
              data: permissionsToCreate,
            },
            refetchQueries: [
              {
                query: ALL_USER_GROUP_PERMISSIONS_QUERY,
                variables: {
                  where: {
                    GroupID: { equals: newGroup.data.createUserGroups.GroupID },
                  },
                },
              },
              {
                query: LIST_USER_GROUPS_QUERY,
              },
            ],
          });
        }
      }
      toast.success(
        `User group ${GroupID ? "updated" : "created"} successfully.`
      );
      onComplete();
    } catch (err) {
      toast.error(`Error ${GroupID ? "updat" : "creat"}ing user group.`);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleChmodChange = (permissions) => {
    setchModInputs(permissions);
  };

  const setOriginalPermissions = (originalPerms) => {
    setOriginalPermissionsIds(originalPerms);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <form id="user-group-create-form" onSubmit={handleSubmit}>
      <div className="page-head">
        <div className="page-head-start">
          <Title size={4}>{GroupID ? "Update" : "Create"} User Group</Title>
        </div>
        <div className="page-head-end">
          <Field kind="group">
            <Control>
              <Button
                color="primary"
                size="small"
                type="button"
                onClick={onComplete}
              >
                Cancel
              </Button>
            </Control>
            {!disabled && (
              <Control>
                <Button
                  color="primary"
                  form="user-group-create-form"
                  size="small"
                  type="submit"
                >
                  Submit
                </Button>
              </Control>
            )}
          </Field>
        </div>
      </div>
      <hr />
      <UserGroupForm
        disabled={disabled}
        editing={!!GroupID}
        inputs={inputs}
        onChange={handleChange}
      />
      <UserGroupPermissions
        GroupID={GroupID}
        disabled={disabled}
        setOriginalPermissions={setOriginalPermissions}
        onChange={handleChmodChange}
      />
      <hr />
    </form>
  );
};

UserGroupCreateModal.propTypes = {
  GroupID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onComplete: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
};
UserGroupCreateModal.defaultProps = {
  GroupID: "",
  onComplete: () => null,
};

export default UserGroupCreateModal;
