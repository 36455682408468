import React from "react";
import { Generic, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";

const DEFAULT_COLUMNS = [
  {
    Header: "Code",
    id: "Code",
    type: "number",
    accessor: "Code",
  },
  {
    Header: "Type",
    id: "Type",
    accessor: "Type",
  },
  {
    Header: "Discount",
    id: "Discount",
    accessor: "DiscountType",
    Cell: ({ cell, row }) =>
      cell?.value?.CodeId === "%"
        ? `${row.original.Percent}%`
        : `$${row.original.Discount}`,
  },
  {
    Header: "Create Date",
    id: "CreateDate",
    accessor: "CreateDate",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM/dd/yyyy") : "--",
  },
  {
    Header: "Start Date",
    id: "StartDate",
    accessor: "StartDate",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM/dd/yyyy") : "--",
  },
  {
    Header: "Expire Date",
    id: "ExpireDate",
    accessor: "ExpireDate",
    Cell: ({ cell }) =>
      cell?.value && cell?.value !== "-1"
        ? format(new Date(cell.value), "MM/dd/yyyy")
        : "--",
  },
  {
    Header: "Uses",
    id: "Uses",
    type: "number",
    accessor: "Uses",
    Cell: ({ cell }) => (cell?.value === -1 ? "unlimited" : cell?.value),
  },
  {
    Header: "Used",
    id: "Used",
    type: "number",
    accessor: "Used",
  },
  {
    Header: "Created By",
    id: "CreatedBy",
    accessor: "User.Username",
    disableSortBy: true,
  },
  {
    Header: "Lab",
    id: "Lab",
    accessor: "Lab.Company",
    disableSortBy: true,
  }
];

export const generateColumns = (handleDelete, canUpdate) => [
  ...DEFAULT_COLUMNS,
  {
    Header: "",
    id: "delete",
    Cell: (cell) =>
      canUpdate && (
        <Generic
          style={{ textAlign: "center" }}
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(cell.row.original);
          }}
        >
          <Icon color="danger">
            <FontAwesomeIcon icon="times" />
          </Icon>
        </Generic>
      ),
  },
];

export default DEFAULT_COLUMNS;
