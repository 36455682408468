import React from "react";
import PropTypes from "prop-types";
import { Field, Box, Control, Button, Label } from "rbx";
import {
  TestCategorySelect,
  SystemCodeSelect,
} from "../../../../../../components";

const BatchFilters = ({ onChange, inputs, onReset }) => (
  <Box>
    <Field kind="group">
      <TestCategorySelect
        getValue={(x) => x?.equals || ""}
        id="TestCategoryID"
        label="Test Category"
        name="TestCategoryID"
        setValue={(x) => (typeof x === "number" ? { equals: x } : "")}
        size="small"
        value={inputs.TestCategoryID}
        onChange={onChange}
      />
      <SystemCodeSelect
        getValue={(x) => (x?.equals ? parseInt(x.equals, 10) : "")}
        id="status"
        label="Status"
        name="Status"
        setValue={(x) =>
          typeof x === "number" ? { equals: x } : ""
        }
        size="small"
        value={inputs.Status}
        where={{
          Type: { equals: "System" },
          Category: { equals: "Batch" },
          CodeName: { equals: "Status" },
        }}
        onChange={onChange}
      />
      <Control>
        <Label>&nbsp;</Label>
        <Button color="primary" size="small" onClick={onReset}>
          Reset
        </Button>
      </Control>
    </Field>
  </Box>
);

BatchFilters.propTypes = {
  onChange: PropTypes.func,
  inputs: PropTypes.object,
  onReset: PropTypes.func,
};

BatchFilters.defaultProps = {
  onChange: (e) => e,
  inputs: {},
  onReset: (e) => e,
};

export default BatchFilters;
