import { gql } from "@apollo/client";

export const CREATE_JOB_ORDER_TEST_MUTATION = gql`
  mutation CREATE_JOB_ORDER_TEST_MUTATION($data: JobOrderTestsCreateInput!) {
    createJobOrderTests(data: $data) {
      JobOrderTestID
      JobOrderID
    }
  }
`;

export const UPDATE_JOB_ORDER_TEST_MUTATION = gql`
  mutation UPDATE_JOB_ORDER_TEST_MUTATION(
    $data: JobOrderTestsUpdateInput!
    $where: JobOrderTestsWhereUniqueInput!
  ) {
    updateJobOrderTests(data: $data, where: $where) {
      JobOrderTestID
      JobOrderID
    }
  }
`;

export const CREATE_JOB_ORDER_TEST_SAMPLES = gql`
  mutation CREATE_JOB_ORDER_TEST_SAMPLES(
    $data: JobOrderTestSamplesCreateInput!
  ) {
    createJobOrderTestSamples(data: $data) {
      SessionID
    }
  }
`;

export const DELETE_JOB_ORDER_TEST_SAMPLE_MUTATION = gql`
  mutation DELETE_JOB_ORDER_TEST_SAMPLE_MUTATION(
    $where: JobOrderTestSamplesWhereUniqueInput!
  ) {
    deleteJobOrderTestSamples(where: $where) {
      JobOrderTestSampleID
    }
  }
`;

export const DELETE_MANY_JOB_ORDER_TEST_SAMPLES_MUTATION = gql`
  mutation DELETE_MANY_JOB_ORDER_TEST_SAMPLES_MUTATION(
    $where: JobOrderTestSamplesWhereInput
  ) {
    deleteManyJobOrderTestSamples(where: $where) {
      count
    }
  }
`;

export const UPDATE_DIFFERENT_JOB_ORDER_TEST_SAMPLES = gql`
  mutation UPDATE_DIFFERENT_JOB_ORDER_TEST_SAMPLES(
    $data: [UpdateDifferentJobOrderTestSamplesInput!]!
  ) {
    updateDifferentJobOrderTestSamples(data: $data)
  }
`;

export const UPDATE_MANY_JOB_ORDER_TEST_SAMPLES_STATUS_MUTATION = gql`
  mutation UPDATE_MANY_JOB_ORDER_TEST_SAMPLES_STATUS_MUTATION(
    $data: JobOrderTestSampleUpdateArrayInput!
  ) {
    updateManyJobOrderTestSampleStatus(data: $data)
  }
`;

export const UPDATE_JOB_ORDER_TEST_SAMPLE = gql`
  mutation UPDATE_JOB_ORDER_TEST_SAMPLE(
    $data: JobOrderTestSamplesUpdateInput!
    $where: JobOrderTestSamplesWhereUniqueInput!
  ) {
    updateJobOrderTestSamples(data: $data, where: $where) {
      JobOrderTestSampleID
    }
  }
`;

export const UPDATE_MANY_JOB_ORDER_TEST_MUTATION = gql`
  mutation UPDATE_MANY_JOB_ORDER_TEST_MUTATION(
    $data: JobOrderTestsUpdateManyMutationInput!
    $where: JobOrderTestsWhereInput
  ) {
    updateManyJobOrderTests(data: $data, where: $where) {
      count
    }
  }
`;

export const UPDATE_MANY_JOB_ORDER_TEST_SAMPLES_MUTATION = gql`
  mutation UPDATE_MANY_JOB_ORDER_TEST_SAMPLES_MUTATION(
    $data: JobOrderTestSamplesUpdateManyMutationInput!
    $where: JobOrderTestSamplesWhereInput
  ) {
    updateManyJobOrderTestSamples(data: $data, where: $where) {
      count
    }
  }
`;

export const UPDATE_JOB_ORDER_TEST_SAMPLES_BATCH = gql`
  mutation UPDATE_JOB_ORDER_TEST_SAMPLES_BATCH(
    $where: JobOrderTestSamplesWhereInput!
  ) {
    updateJobOrderTestSamplesBatch(where: $where)
  }
`;
