import React from "react";
import PropTypes from "prop-types";

import { Switch, Route } from "react-router-dom";

import UserGroupListPage from "./UserGroupListPage";

const UserGroupRouter = ({ routePermissions }) => (
  <Switch>
    <Route path="/user-groups">
      <UserGroupListPage routePermissions={routePermissions} />
    </Route>
  </Switch>
);

UserGroupRouter.propTypes = {
  routePermissions: PropTypes.array,
};

UserGroupRouter.defaultProps = {
  routePermissions: [],
};

export default UserGroupRouter;
