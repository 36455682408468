/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useMemo, useContext, useState, useEffect } from "react";
import { useMutation, useApolloClient } from "@apollo/client";
import { remove } from "lodash";
import { ModalContext } from "../../../../../../context/ModalContext";
import { customToast as toast } from "../../../../../../utils";
import { DataTable, ReasonModal } from "../../../../../../components";
import {
  ALL_JOB_ORDER_TEST_SAMPLES_QUERY,
  UPDATE_JOB_ORDER_MUTATION,
  CREATE_JOB_ORDER_TEST_SAMPLES,
  UPDATE_JOB_ORDER_TEST_SAMPLE,
  CREATE_BATCH_LOG_MUTATION,
  DELETE_JOB_ORDER_TEST_SAMPLE_MUTATION,
  SINGLE_BATCH_QUERY,
  UPDATE_BATCH_MUTATION,
  UPDATE_JOB_ORDER_TEST_MUTATION,
  CREATE_JOB_LOG_MUTATION,
} from "../../../../../../graphql";
import { useAuth } from "../../../../../../context";
import { generateColumns } from "./columns";
import "./SamplesList.scss";

const SamplesList = ({ jobOrderID, disabled, isHomo }) => {
  const orderBy = [{ id: "SessionID", desc: false }];
  const client = useApolloClient();
  const { state: authState } = useAuth();
  const { setModalOpen } = useContext(ModalContext);

  const [canDeleteSamples, setCanDeleteSamples] = useState(false);
  const [canArchiveTests, setCanArchiveTests] = useState(false);
  const [where, setWhere] = useState({});

  const [updateJobOrder] = useMutation(UPDATE_JOB_ORDER_MUTATION);
  const [deleteJobOrderTestSample] = useMutation(
    DELETE_JOB_ORDER_TEST_SAMPLE_MUTATION
  );
  const [createJobOrderTestSamples] = useMutation(
    CREATE_JOB_ORDER_TEST_SAMPLES
  );
  const [createBatchLogs] = useMutation(CREATE_BATCH_LOG_MUTATION);
  const [updateBatch] = useMutation(UPDATE_BATCH_MUTATION);
  const [updateJobOrderTestSample] = useMutation(UPDATE_JOB_ORDER_TEST_SAMPLE);
  const [updateJobOrderTest] = useMutation(UPDATE_JOB_ORDER_TEST_MUTATION);
  const [createJobLog] = useMutation(CREATE_JOB_LOG_MUTATION);

  useEffect(() => {
    if (Array.isArray(authState.permissions) && authState.permissions.length) {
      const foundCanDeleteSamples = authState.permissions.find(
        (permission) => permission.Code === "DEL_SAMPLES"
      );
      if (foundCanDeleteSamples && foundCanDeleteSamples.Update) {
        setCanDeleteSamples(true);
      } else {
        setCanDeleteSamples(false);
      }

      const foundCanArchiveTests = authState.permissions.find(
        (permission) => permission.Code === "ARCH_TESTS"
      );
      if (foundCanArchiveTests && foundCanArchiveTests.Update) {
        setCanArchiveTests(true);
      } else {
        setCanArchiveTests(false);
      }
    } else {
      setCanDeleteSamples(false);
      setCanArchiveTests(false);
    }
  }, [authState]);

  useEffect(() => {
    if (isHomo) {
      setWhere({
        JobOrderID: {
          equals: parseInt(jobOrderID, 10),
        },
        JobOrderTest: {
          is: {
            Test: {
              is: {
                Code: {
                  equals: "HOMO",
                },
              },
            },
          },
        },
      });
    } else {
      setWhere({
        JobOrderID: {
          equals: parseInt(jobOrderID, 10),
        },
        JobOrderTest: {
          is: {
            Test: {
              isNot: {
                Code: {
                  equals: "HOMO",
                },
              },
            },
          },
        },
      });
    }
  }, [isHomo, jobOrderID]);

  const deleteSampleFromBatchAndCreateLog = async (
    BatchID,
    SessionID,
    reasonForChange
  ) => {
    const { data: foundBatch } = await client.query({
      query: SINGLE_BATCH_QUERY,
      variables: {
        where: {
          BatchID: parseInt(BatchID, 10),
        },
      },
    });

    if (foundBatch.findUniqueBatches) {
      const batchTestsData = [...foundBatch.findUniqueBatches.Tests];
      remove(batchTestsData, (item) => item?.SessionID === SessionID);

      await updateBatch({
        variables: {
          where: {
            BatchID: parseInt(BatchID, 10),
          },
          data: {
            Tests: batchTestsData,
          },
        },
      });

      await createBatchLogs({
        variables: {
          data: {
            Change: `Deleted Job Order Test Sample with Session ID ${SessionID}`,
            Batches: {
              connect: {
                BatchID: parseInt(BatchID, 10),
              },
            },
            ReasonForChange: reasonForChange,
            CreatedBy: authState.user.Username,
          },
        },
      });
    }
  };

  const columns = useMemo(() => {
    const refetchQueries = [
      {
        query: ALL_JOB_ORDER_TEST_SAMPLES_QUERY,
        variables: {
          where: { JobOrderID: { equals: parseInt(jobOrderID, 10) } },
        },
        fetchPolicy: "network-only",
      },
    ];

    const handleClickReplicate = async (jobOrderTestSample, replicateQty) => {
      try {
        await updateJobOrderTestSample({
          variables: {
            where: {
              JobOrderTestSampleID: parseInt(
                jobOrderTestSample.JobOrderTestSampleID,
                10
              ),
            },
            data: {
              Replicated: { set: true },
            },
          },
          refetchQueries,
        });
        [...Array(replicateQty).keys()].forEach(async (x) => {
          const prepNo = x + 1 + jobOrderTestSample.PrepNo;
          await createJobOrderTestSamples({
            variables: {
              data: {
                SessionID: `${jobOrderTestSample.JobID}_${jobOrderTestSample.JobOrderID}_${jobOrderTestSample.JobOrderTestID}`,
                JobOrderTest: {
                  connect: {
                    JobOrderTestID: parseInt(
                      jobOrderTestSample.JobOrderTestID,
                      10
                    ),
                  },
                },
                JobOrder: {
                  connect: {
                    JobOrderID: parseInt(jobOrderTestSample.JobOrderID, 10),
                  },
                },
                Job: {
                  connect: {
                    JobID: parseInt(jobOrderTestSample.JobID, 10),
                  },
                },
                PrepNo: prepNo,
                Important: jobOrderTestSample.Important,
                CreatedBy: authState?.user?.Username,
                ModifiedBy: authState?.user?.Username,
              },
            },
          });
        });
      } catch (err) {
        toast.error("Error saving changes");
      }
    };

    const handleImportantChange = async (value, row) => {
      try {
        await updateJobOrderTestSample({
          variables: {
            where: {
              JobOrderTestSampleID: parseInt(row.JobOrderTestSampleID, 10),
            },
            data: {
              Important: { set: value },
            },
          },
          refetchQueries,
        });
        toast.success("Sample updated successfully");
      } catch (err) {
        toast.error(`Error updating sample`);
      }
    };

    const handleDelete = async (jobOrderTestSample) => {
      try {
        // find other samples for this jobOrderTest
        const { data: samplesWithSameTestID } = await client.query({
          query: ALL_JOB_ORDER_TEST_SAMPLES_QUERY,
          variables: {
            where: {
              JobOrderTestID: {
                equals: parseInt(jobOrderTestSample.JobOrderTestID, 10),
              },
              JobOrderTestSampleID: {
                not: {
                  equals: parseInt(jobOrderTestSample.JobOrderTestSampleID, 10),
                },
              },
            },
            orderBy: [{ PrepNo: "asc" }],
          },
        });
        // if there are no other samples on this JobOrderTest the user must have permission to archive the test
        if (
          !(
            Array.isArray(samplesWithSameTestID?.findManyJobOrderTestSamples) &&
            samplesWithSameTestID?.findManyJobOrderTestSamples?.length
          )
        ) {
          if (!canArchiveTests) {
            throw Error("You do not have permission to archive this test.");
          }
        }

        setModalOpen(
          true,
          <ReasonModal
            message="Are you sure you would like to delete this Sample?"
            onCancel={() => {
              setModalOpen(false);
              toast.error("A reason for change must be provided.");
            }}
            onConfirm={async (reasonForChange) => {
              try {
                // if there are no other samples on this JobOrderTest and the user has permission
                if (
                  !(
                    Array.isArray(
                      samplesWithSameTestID?.findManyJobOrderTestSamples
                    ) &&
                    samplesWithSameTestID?.findManyJobOrderTestSamples?.length
                  )
                ) {
                  // archive the jobOrderTest
                  await updateJobOrderTest({
                    variables: {
                      where: {
                        JobOrderTestID: parseInt(
                          jobOrderTestSample.JobOrderTestID,
                          10
                        ),
                      },
                      data: {
                        Archived: {
                          set: true,
                        },
                      },
                    },
                  });
                  // create job log for archived test
                  await createJobLog({
                    variables: {
                      data: {
                        JobID: parseInt(jobOrderTestSample.JobID, 10),
                        JobOrderID: parseInt(jobOrderTestSample.JobOrderID, 10),
                        Change: `Archived Job Order Test: ${parseInt(
                          jobOrderTestSample.JobOrderTestID,
                          10
                        )}`,
                        CreatedBy: authState.user.Username,
                        ModifiedBy: authState.user.Username,
                        ReasonForChange: reasonForChange,
                      },
                    },
                  });
                }

                // remove jobOrderTestSample from batch
                if (jobOrderTestSample.BatchID) {
                  await deleteSampleFromBatchAndCreateLog(
                    jobOrderTestSample.BatchID,
                    jobOrderTestSample.SessionID,
                    reasonForChange
                  );
                }

                // create job log for deleted sample
                await createJobLog({
                  variables: {
                    data: {
                      JobID: parseInt(jobOrderTestSample.JobID, 10),
                      JobOrderID: parseInt(jobOrderTestSample.JobOrderID, 10),
                      Change: `Deleted Sample: ${jobOrderTestSample.SessionID}`,
                      CreatedBy: authState.user.Username,
                      ModifiedBy: authState.user.Username,
                      ReasonForChange: reasonForChange,
                    },
                  },
                });

                // delete jobOrderTestSample
                await deleteJobOrderTestSample({
                  variables: {
                    where: {
                      JobOrderTestSampleID: parseInt(
                        jobOrderTestSample.JobOrderTestSampleID,
                        10
                      ),
                    },
                  },
                  refetchQueries: [
                    {
                      query: ALL_JOB_ORDER_TEST_SAMPLES_QUERY,
                      variables: {
                        where: {
                          JobOrderID: { equals: parseInt(jobOrderID, 10) },
                        },
                      },
                    },
                  ],
                });
                setModalOpen(false);
                toast.success("Sample deleted");
              } catch (err) {
                toast.error("An error has ocurred");
              }
            }}
          />
        );
      } catch (err) {
        toast.error(`${err}`);
      }
    };

    return generateColumns(
      handleClickReplicate,
      handleImportantChange,
      handleDelete,
      disabled,
      canDeleteSamples,
      canArchiveTests
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authState.user.Username,
    client,
    createJobOrderTestSamples,
    jobOrderID,
    updateJobOrder,
    updateJobOrderTestSample,
    canDeleteSamples,
    canArchiveTests,
  ]);

  return (
    <DataTable
      aggregateKey="_all"
      aggregateName="aggregateJobOrderTestSamples"
      columns={columns}
      name="findManyJobOrderTestSamples"
      orderBy={orderBy}
      query={ALL_JOB_ORDER_TEST_SAMPLES_QUERY}
      where={where}
    />
  );
};

SamplesList.propTypes = {};

export default SamplesList;
