import React, { useState, useEffect, useMemo } from "react";
import { Box, Title, Field, Control, Label, Column, Icon, Button } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";

import { customToast as toast } from "../../utils";
import {
  PageHeader,
  SwitchInput,
  Loader,
  Confirmation,
} from "../../components";
import { useAuth, useModal } from "../../context";
import {
  CREATE_VERIFY_HEMP_CATEGORIES,
  UPDATE_VERIFY_HEMP_CATEGORIES,
  ALL_VERIFY_HEMP_CATEGORIES_QUERY,
  UPDATE_USER_MUTATION,
  DELETE_VERIFY_HEMP_CATEGORIES,
  SINGLE_USER_QUERY,
  CHECK_AUTH_QUERY,
} from "../../graphql";
import { MMTCInfo, HempCategories } from "./components";

const INITIAL_STATE = {
  SMSNotifications: false,
  EmailNotifications: false,
  ShowOnVerifyHemp: false,
};

const ClientSettingsPage = () => {
  const { state: authState } = useAuth();
  const { setModalOpen } = useModal();
  const [hempCategory, setHempCategory] = useState(null);
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [mmtcInputs, setMmtcInputs] = useState(null);

  const [updateUser] = useMutation(UPDATE_USER_MUTATION);
  const [createHempCategory] = useMutation(CREATE_VERIFY_HEMP_CATEGORIES);
  const [updateHempCategory] = useMutation(UPDATE_VERIFY_HEMP_CATEGORIES);
  const [deleteHempCategory] = useMutation(DELETE_VERIFY_HEMP_CATEGORIES);
  const [getClientData, resultClientData] = useLazyQuery(SINGLE_USER_QUERY);

  const [getHempCategories, { data: getVerifyHempCategoriesData }] =
    useLazyQuery(ALL_VERIFY_HEMP_CATEGORIES_QUERY);

  const { data: getUserData } = useQuery(CHECK_AUTH_QUERY, {
    fetchPolicy: "network-only",
  });

  const uploadHempCategoryPath = useMemo(() => {
    const date = new Date();
    return hempCategory?.Name
      ? `/Images/Clients/Settings/${date.getFullYear()}-${String(
          date.getMonth() + 1
        ).padStart(2, "0")}/${hempCategory.Name}`
      : "";
  }, [hempCategory]);

  useEffect(() => {
    if (authState?.user) {
      getClientData({
        variables: {
          where: { UserID: parseInt(authState?.user.UserID, 10) },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [authState, getClientData]);

  useEffect(() => {
    if (resultClientData?.data) {
      const {
        data: { findUniqueUsers },
      } = resultClientData;

      setInputs({
        SMSNotifications: findUniqueUsers.SMSNotifications,
        EmailNotifications: findUniqueUsers.EmailNotifications,
        ShowOnVerifyHemp: findUniqueUsers.ShowOnVerifyHemp,
      });
    }
  }, [resultClientData]);

  // useEffect(() => {
  //   if (authState?.user) {
  //     setInputs({
  //       SMSNotifications: authState.user.SMSNotifications,
  //       EmailNotifications: authState.user.EmailNotifications,
  //       ShowOnVerifyHemp: authState.user.ShowOnVerifyHemp,
  //     });
  //   }
  // }, [authState]);

  useEffect(() => {
    if (authState?.user) {
      getHempCategories({
        variables: {
          where: {
            UserID: {
              equals: Number(authState.user.UserID),
            },
          },
          orderBy: { CategoryID: "asc" },
        },
      });
    }
  }, [authState, getHempCategories]);

  const handleChange = async (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (
      ["SMSNotifications", "EmailNotifications", "ShowOnVerifyHemp"].includes(
        name
      )
    ) {
      await updateUser({
        variables: {
          data: {
            [name]: { set: value ? 1 : 0 },
          },
          where: {
            UserID: Number(authState.user.UserID),
          },
        },
        refetchQueries: [
          {
            query: SINGLE_USER_QUERY,
            variables: {
              where: { UserID: parseInt(authState?.user.UserID, 10) },
            },
            fetchPolicy: "network-only",
          },
          {
            query: CHECK_AUTH_QUERY,
          },
        ],
      });
    }
  };

  const handleHempCategoryChange = (name, value) => {
    setHempCategory((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleMmtcChange = (name, value) => {
    setMmtcInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDisplayHempCategory = (show, data = null) => {
    if (!show) {
      setHempCategory(false);
    } else {
      setHempCategory(data);
    }
  };

  const handleHempCategorySave = async (e) => {
    e.preventDefault();
    if (hempCategory.CategoryID) {
      await updateHempCategory({
        variables: {
          data: {
            UserID: { set: parseInt(authState.user.UserID, 10) },
            Name: { set: hempCategory.Name },
            ImageURL: { set: hempCategory.ImageURL },
          },
          where: {
            CategoryID: hempCategory.CategoryID,
          },
        },
        refetchQueries: [
          {
            query: ALL_VERIFY_HEMP_CATEGORIES_QUERY,
            variables: {
              where: {
                UserID: {
                  equals: Number(authState.user.UserID),
                },
              },
              orderBy: { CategoryID: "asc" },
            },
          },
        ],
      });
    } else {
      await createHempCategory({
        variables: {
          data: {
            UserID: parseInt(authState.user.UserID, 10),
            Name: hempCategory.Name,
            ImageURL: hempCategory.ImageURL,
          },
        },
        refetchQueries: [
          {
            query: ALL_VERIFY_HEMP_CATEGORIES_QUERY,
            variables: {
              where: {
                UserID: {
                  equals: Number(authState.user.UserID),
                },
              },
              orderBy: { CategoryID: "asc" },
            },
          },
        ],
      });
    }
    setHempCategory(null);
  };

  const handleDeleteHempCategory = (row) => {
    const performDelete = async () => {
      await deleteHempCategory({
        variables: {
          where: {
            CategoryID: parseInt(row.CategoryID, 10),
          },
        },
        refetchQueries: [
          {
            query: ALL_VERIFY_HEMP_CATEGORIES_QUERY,
            variables: {
              where: {
                UserID: {
                  equals: Number(authState.user.UserID),
                },
              },
              orderBy: { CategoryID: "asc" },
            },
          },
        ],
      });
      setModalOpen(false, "");
      setHempCategory(null);
    };
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to remove this Hemp Category?"
        onCancel={() => setModalOpen(false, "")}
        onConfirm={performDelete}
      />
    );
  };

  const handleSaveMmtcItem = async (e) => {
    e.preventDefault();
    try {
      const newId = mmtcInputs.id || uuidv4();
      await updateUser({
        variables: {
          data: {
            MMTCInfo: {
              ...getUserData?.checkAuth?.MMTCInfo,
              [newId]: {
                Item: mmtcInputs.Item,
                Name: mmtcInputs.Name,
              },
            },
          },
          where: {
            UserID: Number(authState.user.UserID),
          },
        },
        refetchQueries: [
          {
            query: SINGLE_USER_QUERY,
            variables: {
              where: { UserID: parseInt(authState?.user.UserID, 10) },
            },
            fetchPolicy: "network-only",
          },
          {
            query: CHECK_AUTH_QUERY,
          },
        ],
      });
      setMmtcInputs(null);
      toast.success("Information saved succesfully.");
    } catch (err) {
      toast.error("Unable to save.");
    }
  };

  const handleMmtcDelete = (item) => {
    const performDelete = async () => {
      const newMMTCInfo = Object.keys(getUserData.checkAuth.MMTCInfo).reduce(
        (prev, curr) =>
          curr !== item.id
            ? {
                ...prev,
                [curr]: getUserData.checkAuth.MMTCInfo[curr],
              }
            : prev,
        {}
      );
      await updateUser({
        variables: {
          data: {
            MMTCInfo: newMMTCInfo,
          },
          where: {
            UserID: Number(authState.user.UserID),
          },
        },
        refetchQueries: [
          {
            query: SINGLE_USER_QUERY,
            variables: {
              where: { UserID: parseInt(authState?.user.UserID, 10) },
            },
            fetchPolicy: "network-only",
          },
          {
            query: CHECK_AUTH_QUERY,
          },
        ],
      });
      setModalOpen(false, "");
    };
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to remove this item?"
        onCancel={() => setModalOpen(false, "")}
        onConfirm={performDelete}
      />
    );
  };

  if (!authState?.user) return <Loader />;

  return (
    <div>
      <PageHeader title="Edit your settings" />
      <form>
        <Column.Group multiline>
          <Column size={12}>
            <Field>
              <Control size="small">
                <Label>Show COAs on verifyhemp.com</Label>
                <SwitchInput
                  name="ShowOnVerifyHemp"
                  size="small"
                  value={inputs.ShowOnVerifyHemp}
                  onChange={handleChange}
                />
              </Control>
            </Field>
          </Column>
          <Column mobile={{ size: 12 }} size={6}>
            <Box>
              <HempCategories
                authState={authState}
                data={
                  getVerifyHempCategoriesData?.findManyVerifyHempCategories ||
                  []
                }
                inputs={hempCategory}
                setInputs={setHempCategory}
                uploadHempCategoryPath={uploadHempCategoryPath}
                onChange={handleHempCategoryChange}
                onDelete={handleDeleteHempCategory}
                onHandleDisplayChange={handleDisplayHempCategory}
                onSave={handleHempCategorySave}
              />
            </Box>
          </Column>
          <Column mobile={{ size: 12 }} size={6}>
            <Box>
              <div className="page-head">
                <div className="page-head-start">
                  <Title size={6}>Client Information</Title>
                </div>
                <div className="page-head-end">
                  <Field kind="group">
                    <Control>
                      <Button
                        color="primary"
                        size="small"
                        type="button"
                        onClick={() => {
                          setMmtcInputs({
                            Item: "",
                            Name: "",
                          });
                        }}
                      >
                        <Icon>
                          <FontAwesomeIcon icon="plus" />
                        </Icon>
                        <span>Add</span>
                      </Button>
                    </Control>
                  </Field>
                </div>
              </div>
              <hr />
              <MMTCInfo
                data={getUserData?.checkAuth?.MMTCInfo}
                inputs={mmtcInputs}
                setInputs={setMmtcInputs}
                onChange={handleMmtcChange}
                onDelete={handleMmtcDelete}
                onSave={handleSaveMmtcItem}
              />
            </Box>
          </Column>
        </Column.Group>
      </form>
    </div>
  );
};

export default ClientSettingsPage;
