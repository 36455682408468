import React, { useState } from "react";
import PropTypes from "prop-types";
import { Title, Button, Column, Control, Label, Field, Input } from "rbx";
import { FileUploader } from "../../../../components";

const EquipmentLogsModal = ({ onComplete, onCancel, uploadAttachmentPath }) => {
  const [reasonForChange, setReasonForChange] = useState("");
  const [attachmentURL, setAttachmentURL] = useState("");
  
  return (
    <div>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Reason For Change</Title>
        </div>
        <div className="page-head-end">
          <Button.Group>
            <Button
              color="primary"
              size="small"
              type="button"
              onClick={() => onCancel()}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={!reasonForChange.length}
              form="add-referrer-form"
              size="small"
              type="submit"
              onClick={() => onComplete(reasonForChange, attachmentURL)}
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Column.Group multiline>
        <Column desktop={{ size: 12 }} tablet={{ size: 12 }}>
          <Control expanded size="small">
            <Label>Reason For Change</Label>
            <Input
              required
              maxLength={250}
              name="ReasonForChange"
              placeholder="Reason For Change"
              size="small"
              value={reasonForChange}
              onChange={(e) => setReasonForChange(e.target.value)}
            />
          </Control>
        </Column>
        <Column desktop={{ size: 12 }} tablet={{ size: 12 }}>
          <Field kind="group">
            <Control expanded style={{ width: "100%" }}>
              <FileUploader
                label="Attachment"
                name="AttachmentURL"
                uploadPath={uploadAttachmentPath}
                value={attachmentURL}
                onChange={(name, value) => setAttachmentURL(value)}
              />
            </Control>
          </Field>
        </Column>
      </Column.Group>
    </div>
  );
};

EquipmentLogsModal.propTypes = {
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  uploadAttachmentPath: PropTypes.func.isRequired,
};

EquipmentLogsModal.defaultProps = {
  onComplete: (e) => e,
  onCancel: (e) => e,
};


export default EquipmentLogsModal;