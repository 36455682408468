import { gql } from "@apollo/client";

export const ALL_TEST_ANALYTES_QUERY = gql`
  query ALL_TEST_ANALYTES_QUERY(
    $where: TestAnalytesWhereInput
    $orderBy: [TestAnalytesOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyTestAnalytes(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      TestAnalyteID
      TestID
      AnalyteID
      CreatedBy
      ModifiedBy
      CreatedDateTime
      ModifiedDateTime
    }
    aggregateTestAnalytes(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const SINGLE_TEST_ANALYTE_QUERY = gql`
  query SINGLE_TEST_ANALYTE_QUERY($where: TestAnalytesWhereUniqueInput!) {
    findUniqueTestAnalytes(where: $where) {
      TestAnalyteID
      TestID
      AnalyteID
      CreatedBy
      ModifiedBy
      CreatedDateTime
      ModifiedDateTime
    }
  }
`;
