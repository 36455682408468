/* eslint-disable max-lines-per-function */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-prototype-builtins */
/* eslint-disable guard-for-in */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { useModal, useAuth } from "../../../../context";
import { PageHeader, Confirmation } from "../../../../components";
import EditActionLimitTemplateAnalytesTable from "../components/EditActionLimitTemplateAnalytesTable";
import { customToast as toast } from "../../../../utils";
import {
  ALL_ACTION_LIMIT_TEMPLATES_QUERY,
  ALL_ACTION_LIMIT_TEMPLATE_ANALYTES_QUERY,
  SINGLE_ACTION_LIMIT_TEMPLATE_QUERY,
  UPDATE_ACTION_LIMIT_TEMPLATE_MUTATION,
  CREATE_ACTION_LIMIT_TEMPLATE_MUTATION,
  NEW_ACTION_LIMIT_TEMPLATE_ANALYTES,
  UPDATE_DIFFERENT_ACTION_LIMIT_TEMPLATE_ANALYTE_MUTATION,
  DELETE_MANY_ACTION_LIMIT_TEMPLATE_ANALYTES,
  DELETE_MANY_ACTION_LIMIT_TEMPLATE_PRODUCT_CATEGORIES,
  DELETE_ACTION_LIMIT_TEMPLATE_ON_CASCADE_MUTATION,
} from "../../../../graphql";
import Loader from "../../../../components/Loader";
import ActionLimitTemplatesForm from "../components/ActionLimitTemplateForm";
import NewActionLimitTemplateTable from "../components/NewActionLimitTemplateTable";
import "./ActionLimitTemplatesListPage.scss";

const INITIAL_STATE = {
  TemplateID: "",
  ProductCategories: [],
  TestCategory: "",
  TemplateName: "",
  TemplateState: "",
  TemplateType: "Compliance",
  IsPrimary: false,
  SubstanceType: "",
  MedicalType: null,
  MachineType: "",
};

const ActionLimitTemplatesListPage = ({ routePermissions }) => {
  const client = useApolloClient();
  const { state: authState } = useAuth();
  const [loading, setLoading] = useState(false);
  const [templateWhere, setTemplateWhere] = useState({});
  const [templateToBeSelected, setTemplateToBeSelected] = useState(false);
  const [inputs, setInputs] = useState({
    ...INITIAL_STATE,
  });
  const [showForm, setShowForm] = useState(false);
  const [actionLimitTemplateAnalyteWhere, setActionLimitTemplateAnalyteWhere] =
    useState({});

  const [canUpdate, setCanUpdate] = useState(true);

  const [typeOfRequest, setTypeOfRequest] = useState("create");

  const { setModalOpen } = useModal();

  const newActionLimitTemplateAnalytesData = useRef();
  const updateActionLimitTemplateAnalytesData = useRef();

  const [getActionLimitTemplate, { data: actionLimitTemplateData }] =
    useLazyQuery(SINGLE_ACTION_LIMIT_TEMPLATE_QUERY);

  const [createActionLimitTemplate] = useMutation(
    CREATE_ACTION_LIMIT_TEMPLATE_MUTATION
  );

  const [updateActionLimitTemplate] = useMutation(
    UPDATE_ACTION_LIMIT_TEMPLATE_MUTATION
  );

  const [updateDifferentActionLimitTemplateAnalytes] = useMutation(
    UPDATE_DIFFERENT_ACTION_LIMIT_TEMPLATE_ANALYTE_MUTATION
  );

  const [newActionLimitTemplateAnalytes] = useMutation(
    NEW_ACTION_LIMIT_TEMPLATE_ANALYTES
  );

  const [deleteManyActionLimitTemplateAnalytes] = useMutation(
    DELETE_MANY_ACTION_LIMIT_TEMPLATE_ANALYTES
  );

  const [deleteTemplateProductCategories] = useMutation(
    DELETE_MANY_ACTION_LIMIT_TEMPLATE_PRODUCT_CATEGORIES
  );

  const [deleteActionLimitTemplateOnCascade] = useMutation(
    DELETE_ACTION_LIMIT_TEMPLATE_ON_CASCADE_MUTATION
  );

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  useEffect(() => {
    if (actionLimitTemplateData?.findUniqueActionLimitTemplates) {
      const newInputs = {};
      Object.keys(inputs).forEach((key) => {
        newInputs[key] =
          actionLimitTemplateData.findUniqueActionLimitTemplates[key];
      });
      newInputs.ProductCategories =
        actionLimitTemplateData.findUniqueActionLimitTemplates.ProductCategories?.map(
          (category) => {
            const result = {
              value: category.Category.RecId,
              label: category.Category.CodeDescription,
            };
            return result;
          }
        );

      setInputs(newInputs);
      setActionLimitTemplateAnalyteWhere({
        TemplateID: {
          equals: parseInt(
            actionLimitTemplateData.findUniqueActionLimitTemplates.TemplateID,
            10
          ),
        },
      });
      setLoading(false);
      setShowForm(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionLimitTemplateData]);

  const handleTemplateChange = (name, value) => {
    if (name === "TemplateID") {
      setTemplateWhere({ TemplateID: value });
    }
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTemplateEdit = useCallback(() => {
    setLoading(true);
    setTypeOfRequest("edit");
    getActionLimitTemplate({
      variables: {
        where: templateWhere,
      },
      fetchPolicy: "network-only",
    });
  }, [getActionLimitTemplate, templateWhere]);

  const handleTemplateCreate = () => {
    setTypeOfRequest("create");
    const nextState = { ...INITIAL_STATE };
    if (authState?.user?.Lab?.Company !== "All Location") {
      nextState.TemplateState = authState.user.Lab.State;
    }
    setInputs(nextState);
    setShowForm(true);
  };

  const handleTemplateClone = () => {
    setLoading(true);
    setTypeOfRequest("clone");
    getActionLimitTemplate({
      variables: {
        where: templateWhere,
      },
    });
  };

  const handleSubmit = async () => {
    const requiredKeys = [
      "TemplateName",
      "TemplateType",
      "TemplateState",
      "TestCategory",
    ];

    const isInvalid = requiredKeys.some((key) => {
      if (
        key === "TestCategory" &&
        (typeof inputs[key] === "string" || inputs[key] < 1)
      ) {
        return true;
      }
      return !String(inputs[key].length);
    });

    if (isInvalid) {
      toast.error("Please fill out all of the fields.");
      return;
    }

    if (typeOfRequest === "edit") {
      setLoading(true);
      try {
        const data = Object.keys(INITIAL_STATE).reduce((acc, curr) => {
          if (curr === "TestCategory" && inputs[curr] === "") {
            return {
              ...acc,
              [curr]: {
                set: 0,
              },
            };
          }
          if (curr === "MedicalType" && inputs[curr] === "") {
            return {
              ...acc,
              [curr]: {
                set: null,
              },
            };
          }
          if (inputs[curr] !== null) {
            return {
              ...acc,
              [curr]: {
                set: inputs[curr],
              },
            };
          }
          return acc;
        }, {});
        const TemplateID = actionLimitTemplateAnalyteWhere.TemplateID.equals;

        await deleteTemplateProductCategories({
          variables: {
            where: {
              TemplateID: { equals: parseInt(TemplateID, 10) },
            },
          },
        });

        const productCategories = inputs.ProductCategories.map((category) => {
          const result = {
            Category: { connect: { RecId: parseInt(category.value, 10) } },
          };
          return result;
        });

        delete data.TemplateID;
        await updateActionLimitTemplate({
          variables: {
            data: {
              ...data,
              ProductCategories: {
                create: [...productCategories],
              },
            },
            where: {
              TemplateID,
            },
          },
          refetchQueries: [
            {
              query: SINGLE_ACTION_LIMIT_TEMPLATE_QUERY,
              variables: {
                where: {
                  TemplateID,
                },
              },
              fetchPolicy: "network-only",
            },
            {
              query: ALL_ACTION_LIMIT_TEMPLATES_QUERY,
              fetchPolicy: "network-only",
            },
          ],
        });

        // if action limit templates analytes exists, it's updated
        const arrayToUpdate = [];

        updateActionLimitTemplateAnalytesData.current.forEach((item) => {
          if (
            item.hasOwnProperty("ActionLimitTemplateAnalyteID") &&
            item.Active
          ) {
            const obj = {
              ActionLimitTemplateAnalyteID: {
                ActionLimitTemplateAnalyteID: item.ActionLimitTemplateAnalyteID,
              },
              ActionLevel: {
                set: item.ActionLevel,
              },
              LOD: {
                set: item.LOD,
              },
              LOQ: {
                set: item.LOQ,
              },
              InSampleLOQ: {
                set: item.InSampleLOQ,
              },
              InSampleLOD: {
                set: item.InSampleLOD,
              },
              AllowableCriteria: {
                set: item.AllowableCriteria,
              },
              UpperLimit: {
                set: item.UpperLimit,
              },
            };
            delete obj.Active;
            delete obj.Analyte;
            arrayToUpdate.push(obj);
          }
        });

        // if it's marked as active and it doesn't exist, it's created
        const arrayToCreate = updateActionLimitTemplateAnalytesData.current
          .map((item) => {
            if (
              !item.hasOwnProperty("ActionLimitTemplateAnalyteID") &&
              item.Active &&
              parseInt(inputs.TestCategory, 10) ===
                parseInt(item.Analyte?.TestCategory?.TestCategoryID, 10)
            ) {
              const obj = {
                ...item,
                AnalyteID: {
                  AnalyteID: parseInt(item.Analyte.AnalyteID, 10),
                },
                TemplateID: {
                  TemplateID: parseInt(TemplateID, 10),
                },
              };
              delete obj.Active;
              delete obj.Analyte;
              return obj;
            }
            return null;
          })
          .filter((item) => item);

        // if action limit template is inactive, gets deleted
        const arrayToDelete = [];

        updateActionLimitTemplateAnalytesData.current.forEach((item) => {
          if (
            item.hasOwnProperty("ActionLimitTemplateAnalyteID") &&
            !item.Active
          ) {
            const obj = {
              ActionLimitTemplateAnalyteID: {
                equals: parseInt(item.ActionLimitTemplateAnalyteID, 10),
              },
            };
            arrayToDelete.push(obj);
          }
        });

        if (arrayToUpdate.length) {
          await updateDifferentActionLimitTemplateAnalytes({
            variables: {
              data: arrayToUpdate,
            },
          });
        }

        if (arrayToCreate.length) {
          await newActionLimitTemplateAnalytes({
            variables: {
              data: arrayToCreate,
            },
            refetchQueries: [
              {
                query: ALL_ACTION_LIMIT_TEMPLATES_QUERY,
                fetchPolicy: "network-only",
              },
              {
                query: ALL_ACTION_LIMIT_TEMPLATE_ANALYTES_QUERY,
                fetchPolicy: "network-only",
                variables: {
                  orderBy: { TemplateID: "desc" },
                },
              },
            ],
          });
        }

        if (arrayToDelete.length) {
          await deleteManyActionLimitTemplateAnalytes({
            variables: {
              where: {
                OR: arrayToDelete,
              },
            },
          });
        }

        toast.success("Action Limit Template updated successfully.");
      } catch (err) {
        toast.error("Error updating Action Limit Template");
      } finally {
        setLoading(false);
      }
    } else if (typeOfRequest === "create") {
      try {
        setLoading(true);
        const { TemplateID, ...data } = inputs;
        const productCategories = data.ProductCategories.map((category) => {
          const result = {
            Category: { connect: { RecId: parseInt(category.value, 10) } },
          };
          return result;
        });
        const newTemplate = await createActionLimitTemplate({
          variables: {
            data: {
              ...data,
              ProductCategories: {
                create: [...productCategories],
              },
              MedicalType: data.MedicalType === "" ? null : data.MedicalType,
            },
          },
        });
        const newTemplateID =
          newTemplate.data.createActionLimitTemplates.TemplateID;
        const currentData = newActionLimitTemplateAnalytesData.current;

        const arrayToCreate = currentData
          .map((item) => {
            if (
              item.Active &&
              parseInt(inputs.TestCategory, 10) ===
                parseInt(item.Analyte?.TestCategory?.TestCategoryID, 10)
            ) {
              return {
                ActionLevel: item.ActionLevel,
                LOD: item.LOD,
                LOQ: item.LOQ,
                InSampleLOQ: item.InSampleLOQ,
                InSampleLOD: item.InSampleLOD,
                AllowableCriteria: item.AllowableCriteria,
                UpperLimit: item.UpperLimit,
                AnalyteID: {
                  AnalyteID: parseInt(item.Analyte.AnalyteID, 10),
                },
                TemplateID: {
                  TemplateID: parseInt(newTemplateID, 10),
                },
              };
            }
            return null;
          })
          .filter((item) => item);
        await newActionLimitTemplateAnalytes({
          variables: {
            data: arrayToCreate,
          },
          refetchQueries: [
            {
              query: ALL_ACTION_LIMIT_TEMPLATES_QUERY,
              fetchPolicy: "network-only",
            },
            {
              query: ALL_ACTION_LIMIT_TEMPLATE_ANALYTES_QUERY,
              fetchPolicy: "network-only",
              variables: {
                orderBy: { TemplateID: "desc" },
              },
            },
          ],
        });
        setTemplateWhere({ TemplateID: parseInt(newTemplateID, 10) });
        setTemplateToBeSelected(true);

        toast.success("Action Limit Template created successfully.");
      } catch (err) {
        toast.error("Error updating Action Limit Template");
      } finally {
        setLoading(false);
      }
    } else if (typeOfRequest === "clone") {
      try {
        setLoading(true);
        const dataToCreate = Object.keys(INITIAL_STATE).reduce((acc, curr) => {
          if (inputs[curr] !== null && curr !== "TemplateID") {
            return {
              ...acc,
              [curr]: inputs[curr],
            };
          }
          return acc;
        }, {});

        const productCategories = inputs.ProductCategories.map((category) => {
          const result = {
            Category: { connect: { RecId: parseInt(category.value, 10) } },
          };
          return result;
        });

        const newTemplate = await createActionLimitTemplate({
          variables: {
            data: {
              ...dataToCreate,
              ProductCategories: {
                create: [...productCategories],
              },
            },
          },
        });
        const newTemplateID =
          newTemplate.data.createActionLimitTemplates.TemplateID;

        const arrayToCreate = [];
        updateActionLimitTemplateAnalytesData.current.forEach((item) => {
          if (item.Active) {
            const obj = {
              ...item,
              AnalyteID: {
                AnalyteID: parseInt(item.Analyte.AnalyteID, 10),
              },
              TemplateID: {
                TemplateID: parseInt(newTemplateID, 10),
              },
            };
            delete obj.Active;
            delete obj.Analyte;
            delete obj.ActionLimitTemplateAnalyteID;
            arrayToCreate.push(obj);
          }
        });

        await newActionLimitTemplateAnalytes({
          variables: {
            data: arrayToCreate,
          },
          refetchQueries: [
            {
              query: ALL_ACTION_LIMIT_TEMPLATES_QUERY,
              fetchPolicy: "network-only",
            },
            {
              query: ALL_ACTION_LIMIT_TEMPLATE_ANALYTES_QUERY,
              fetchPolicy: "network-only",
              variables: {
                orderBy: { TemplateID: "desc" },
              },
            },
          ],
        });

        setNewData([]);
        setInputs({ ...INITIAL_STATE });
        setShowForm(false);
        toast.success("Action Limit Template created successfully.");
      } catch (err) {
        toast.error("Error updating Action Limit Template");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSetTemplateAsPrimary = async () => {
    const { data } = await client.query({
      query: ALL_ACTION_LIMIT_TEMPLATES_QUERY,
      variables: {
        where: {
          IsPrimary: {
            equals: true,
          },
        },
      },
      fetchPolicy: "network-only",
    });

    const confirmationMsg = data?.findManyActionLimitTemplates.length
      ? `This action will deactivate ${data.findManyActionLimitTemplates[0].TemplateName}
      as the primary global action limit template and will make this template the new primary global
      action limit template. Do you want to proceed?`
      : "This action will make this template the new primary global action limit template. Do you want to proceed?";

    const performSetPrimaryFlag = async () => {
      setLoading(true);
      setModalOpen(false, "");
      try {
        const TemplateID = parseInt(inputs.TemplateID, 10);
        const templatesToUpdate = [
          updateActionLimitTemplate({
            variables: {
              data: {
                IsPrimary: {
                  set: true,
                },
              },
              where: {
                TemplateID,
              },
            },
            refetchQueries: [
              {
                query: SINGLE_ACTION_LIMIT_TEMPLATE_QUERY,
                variables: {
                  where: {
                    TemplateID,
                  },
                },
                fetchPolicy: "network-only",
              },
            ],
          }),
        ];

        if (data?.findManyActionLimitTemplates.length) {
          templatesToUpdate.push(
            updateActionLimitTemplate({
              variables: {
                data: {
                  IsPrimary: {
                    set: false,
                  },
                },
                where: {
                  TemplateID: parseInt(
                    data?.findManyActionLimitTemplates[0].TemplateID,
                    10
                  ),
                },
              },
              refetchQueries: [
                {
                  query: SINGLE_ACTION_LIMIT_TEMPLATE_QUERY,
                  variables: {
                    where: {
                      TemplateID: parseInt(
                        data?.findManyActionLimitTemplates[0].TemplateID,
                        10
                      ),
                    },
                  },
                  fetchPolicy: "network-only",
                },
              ],
            })
          );
        }
        await Promise.all(templatesToUpdate);
      } catch (err) {
        toast.error("Error updating Template");
      } finally {
        setLoading(false);
      }
    };

    setModalOpen(
      true,
      <Confirmation
        message={confirmationMsg}
        onCancel={() => setModalOpen(false, "")}
        onConfirm={performSetPrimaryFlag}
      />
    );
  };

  const handleDeleteTemplate = async () => {
    const performTemplateDeletion = async () => {
      setLoading(true);
      setModalOpen(false, "");
      try {
        const { data: deletedTemplate } =
          await deleteActionLimitTemplateOnCascade({
            variables: {
              where: {
                TemplateID: parseInt(inputs.TemplateID, 10),
              },
            },
            refetchQueries: [
              {
                query: ALL_ACTION_LIMIT_TEMPLATES_QUERY,
                fetchPolicy: "network-only",
              },
            ],
          });

        // eslint-disable-next-line no-extra-boolean-cast
        if (!!deletedTemplate?.deleteActionLimitTemplateOnCascade) {
          toast.success("Template deleted");
          setNewData([]);
          setInputs({ ...INITIAL_STATE });
          setShowForm(false);
        } else {
          throw new Error();
        }
      } catch (err) {
        toast.error("Error deleting template");
      } finally {
        setLoading(false);
      }
    };

    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you would like to delete this Action Limit Template?"
        onCancel={() => setModalOpen(false, "")}
        onConfirm={performTemplateDeletion}
      />
    );
  };

  useEffect(() => {
    if (templateToBeSelected && templateWhere) {
      setTemplateToBeSelected(false);
      handleTemplateEdit();
    }
  }, [templateToBeSelected, templateWhere, handleTemplateEdit]);

  if (loading) {
    return <Loader style={{ height: "330px" }} />;
  }

  const setNewData = (data) => {
    newActionLimitTemplateAnalytesData.current = data;
  };

  const setUpdateData = (data) => {
    updateActionLimitTemplateAnalytesData.current = data;
  };

  return (
    <div className="action-limit-list-page">
      <PageHeader title="Manage Action Limit Templates">
        {showForm && canUpdate && (
          <Button color="primary" size="small" onClick={handleSubmit}>
            Save
          </Button>
        )}
        {inputs.IsPrimary && (
          <React.Fragment>
            <Icon size="medium">
              <FontAwesomeIcon icon="exclamation-circle" />
            </Icon>
            Primary Template
          </React.Fragment>
        )}
        {!inputs.IsPrimary &&
          showForm &&
          typeOfRequest === "edit" &&
          canUpdate && (
            <React.Fragment>
              <Button
                color="primary"
                size="small"
                onClick={handleSetTemplateAsPrimary}
              >
                Make Primary Template
              </Button>
              <Button color="error" size="small" onClick={handleDeleteTemplate}>
                Delete Template
              </Button>
            </React.Fragment>
          )}
      </PageHeader>
      <ActionLimitTemplatesForm
        canUpdate={canUpdate}
        formId="action-limit-template-form"
        handleTemplateClone={handleTemplateClone}
        handleTemplateCreate={handleTemplateCreate}
        inputs={inputs}
        setTypeOfRequest={setTypeOfRequest}
        showForm={showForm}
        typeOfRequest={typeOfRequest}
        onChange={handleTemplateChange}
        onEdit={handleTemplateEdit}
        onSubmit={() => {}}
      />
      {showForm && (
        <React.Fragment>
          {typeOfRequest === "edit" || typeOfRequest === "clone" ? (
            <EditActionLimitTemplateAnalytesTable
              aggregateKey="_all"
              aggregateName="aggregateActionLimitTemplateAnalytes"
              canUpdate={canUpdate}
              inputs={inputs}
              name="findManyActionLimitTemplateAnalytes"
              orderBy={[{ id: "TemplateID", desc: true }]}
              query={ALL_ACTION_LIMIT_TEMPLATE_ANALYTES_QUERY}
              setUpdateData={setUpdateData}
              typeOfRequest={typeOfRequest}
              where={actionLimitTemplateAnalyteWhere}
            />
          ) : (
            <NewActionLimitTemplateTable
              aggregateKey="_all"
              aggregateName="aggregateAnalytes"
              canUpdate={canUpdate}
              inputs={inputs}
              setNewActionLimitTemplateAnalytesData={setNewData}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

ActionLimitTemplatesListPage.propTypes = {
  routePermissions: PropTypes.array,
};

ActionLimitTemplatesListPage.defaultProps = {
  routePermissions: [],
};

export default ActionLimitTemplatesListPage;
