import React from "react";
import PropTypes from "prop-types";
import { Column, Field, Input, Label, Control } from "rbx";
import { PhoneNumberInput } from "../../../../components";

const AccountDetails = ({
  inputs,
  onChange,
  disabled,
  onSubmit,
  formId,
  adding,
}) => (
  <form id={formId} onSubmit={onSubmit}>
    <Column.Group multiline>
      <Column desktop={{ size: 12 }} tablet={{ size: 12 }}>
        <Field kind="group">
          <Control expanded size="small">
            <Label>Client</Label>
            <Input
              disabled
              required
              autoComplete="off"
              maxLength={250}
              name="Username"
              placeholder="Username"
              size="small"
              value={inputs.Username}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          {!adding && (
            <Control expanded size="small">
              <Label>UserID</Label>
              <Input disabled size="small" value={inputs.UserID} />
            </Control>
          )}
        </Field>
        <Field kind="group">
          <Control expanded size="small">
            <Label>First Name</Label>
            <Input
              disabled={disabled}
              maxLength={250}
              name="FirstName"
              placeholder="Firstname"
              size="small"
              value={inputs.FirstName}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded size="small">
            <Label>Last Name</Label>
            <Input
              required
              disabled={disabled}
              maxLength={250}
              name="LastName"
              placeholder="LastName"
              size="small"
              value={inputs.LastName}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field kind="group">
          <Control expanded size="small">
            <Label>Email</Label>
            <Input
              required
              disabled={disabled}
              maxLength={250}
              name="Email"
              placeholder="Email"
              size="small"
              type="email"
              value={inputs.Email}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <PhoneNumberInput
            required
            disabled={disabled}
            label="Phone"
            name="Phone"
            size="small"
            value={inputs.Phone}
            onChange={onChange}
          />
          <Control expanded size="small">
            <Label>Company</Label>
            <Input
              disabled={disabled}
              maxLength={250}
              name="Company"
              placeholder="Company"
              size="small"
              value={inputs.Company}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
      </Column>
    </Column.Group>
  </form>
);

AccountDetails.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  formId: PropTypes.string.isRequired,
  adding: PropTypes.bool,
};

AccountDetails.defaultProps = {
  onSubmit: () => null,
  disabled: false,
  adding: false,
};

export default AccountDetails;
