const findQCSampleResults = (batchResults) => {
  const lcsSamples = batchResults.filter(
    (result) => result.QCSample?.QCSampleType?.Name === "LCS"
  );
  const lcsDupeSamples = batchResults.filter(
    (result) => result.QCSample?.QCSampleType?.Name === "LCS Dupe"
  );
  const msSample = batchResults.find(
    (result) => result.QCSample?.QCSampleType?.Name === "Matrix Spike"
  );
  const msPrimarySample = batchResults.find(
    (result) => result.SessionID === msSample?.PrimarySampleSessionID
  );
  const sampleDupeSample = batchResults.find(
    (result) => result.QCSample?.QCSampleType?.Name === "Sample Duplicate"
  );
  const sampleDupePrimarySample = batchResults.find(
    (result) => result.SessionID === sampleDupeSample?.PrimarySampleSessionID
  );
  const blankSamples = batchResults.filter(
    (result) => result.QCSample?.QCSampleType?.Name === "Blank"
  );
  const methodBlankSamples = batchResults.filter(
    (result) => result.QCSample?.QCSampleType?.Name === "Method Blank"
  );
  const ccvSamples = batchResults.filter(
    (result) => result.QCSample?.QCSampleType?.Name === "CCV"
  );
  return {
    lcsSamples,
    lcsDupeSamples,
    msSample,
    msPrimarySample,
    sampleDupeSample,
    sampleDupePrimarySample,
    blankSamples,
    methodBlankSamples,
    ccvSamples,
  };
};

export default findQCSampleResults;
