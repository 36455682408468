import React from "react";

import { Switch, Route } from "react-router-dom";

import LegacyJobOrdersListPage from "./LegacyJobOrdersListPage";

const LegacyJobOrderRouter = () => (
  <Switch>
    <Route path="/legacy-orders">
      <LegacyJobOrdersListPage />
    </Route>
  </Switch>
);

export default LegacyJobOrderRouter;
