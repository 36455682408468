import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Control, Field, Input, Label } from "rbx";

import { useApolloClient } from "@apollo/client";

import { toast } from "react-toastify";
import {
  CHECK_COUPON_VALIDITY_QUERY,
  ALL_SYSTEM_CODES_QUERY,
} from "../../../../../../graphql";

const PrivateLabelApplyCoupon = ({ onChange, orderTotal }) => {
  const [couponCode, setCouponCode] = useState("");
  const [isValidated, setIsValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  const client = useApolloClient();

  const applyCoupon = async (e) => {
    try {
      setLoading(true);
      const { data: couponData } = await client.query({
        query: CHECK_COUPON_VALIDITY_QUERY,
        variables: {
          where: {
            Code: { equals: couponCode },
          },
        },
      });
      const validCoupon = couponData?.checkCouponValidity;
      if (validCoupon) {
        let discount = 0;

        const { data: systemCodes } = await client.query({
          query: ALL_SYSTEM_CODES_QUERY,
        });
        const couponType = systemCodes.findManySystemCodes.find(
          (code) => code.RecId === couponData.checkCouponValidity.Type
        );

        if (couponType?.CodeId === "$") {
          discount = validCoupon.Discount;
        } else if (couponType?.CodeId === "%") {
          discount = parseFloat(
            (
              (couponData.checkCouponValidity.Percent * orderTotal) /
              100
            ).toFixed(2)
          );
        }

        onChange("CouponID", validCoupon.CouponID);
        onChange("CouponDiscount", discount);

        toast.success("Your discount has been applied");
      } else {
        toast.error("The coupon entered is not valid");
      }
      setIsValidated(!!validCoupon);
    } catch (err) {
      onChange("CouponCode", "");
      setIsValidated(false);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const removeCoupon = () => {
    onChange("CouponID", "");
    onChange("CouponDiscount", 0);
    setCouponCode("");
    setIsValidated(false);
  };

  return (
    <Field kind="group">
      <Control>
        <Label>Coupon Code</Label>
        <Input
          disabled={loading || isValidated}
          type="text"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
        />
      </Control>
      {!isValidated ? (
        <Control>
          <Label>&nbsp;</Label>
          <Button
            color="primary"
            disabled={loading || isValidated}
            state={loading ? "loading" : ""}
            type="button"
            onClick={applyCoupon}
          >
            Apply Coupon
          </Button>
        </Control>
      ) : (
        <Control>
          <Label>&nbsp;</Label>
          <Button
            disabled={loading}
            state={loading ? "loading" : ""}
            type="button"
            onClick={removeCoupon}
          >
            Remove Coupon
          </Button>
        </Control>
      )}
    </Field>
  );
};

PrivateLabelApplyCoupon.propTypes = {
  onChange: PropTypes.func,
  orderTotal: PropTypes.number.isRequired,
};

PrivateLabelApplyCoupon.defaultProps = {
  onChange: () => {},
};
export default PrivateLabelApplyCoupon;
