import { gql } from "@apollo/client";

export const CREATE_USER_GROUP_MEMBERSHIP_MUTATION = gql`
  mutation CREATE_USER_GROUP_MEMBERSHIP_MUTATION(
    $data: UserGroupMembershipsCreateInput!
  ) {
    createUserGroupMemberships(data: $data) {
      UserGroupMembershipID
    }
  }
`;

export const DELETE_USER_GROUP_MEMBERSHIP_MUTATION = gql`
  mutation DELETE_USER_GROUP_MEMBERSHIP_MUTATION(
    $where: UserGroupMembershipsWhereUniqueInput!
  ) {
    deleteUserGroupMemberships(where: $where) {
      UserGroupMembershipID
    }
  }
`;
