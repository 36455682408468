import React from "react";
import PropTypes from "prop-types";
import { Control, Box, Column, Title } from "rbx";

import { BooleanInput } from "../../../../components";

const TestSettings = ({ inputs, onChange, handleSave, disabled }) => (
  // const handlePrecisionChange = (e) => {
  //   e.preventDefault();

  //   onChange(e.target.name, e.target.value, "TestSettings");
  // };

  <form id="edit-client-form" onSubmit={handleSave}>
    <Column.Group multiline>
      {/* <Column mobile={{ size: 12 }} size={6}>
          <Box>
            <Title subtitle>Test Decimal Precision</Title>
            <Field kind="group">
              <Control expanded>
                <Label>CANN</Label>
                <Select.Container fullwidth size="small">
                  <Select
                    disabled={disabled}
                    name="CANN"
                    value={inputs?.TestSettings?.CANN}
                    onBlur={handlePrecisionChange}
                    onChange={(e) =>
                      onChange(e.target.name, e.target.value, "TestSettings")
                    }
                  >
                    {[...Array(4).keys()].map((x) => (
                      <Select.Option key={x} value={x}>
                        {x}
                      </Select.Option>
                    ))}
                  </Select>
                </Select.Container>
              </Control>
              <Control expanded>
                <Label>TOX</Label>
                <Select.Container fullwidth size="small">
                  <Select
                    disabled={disabled}
                    name="TOX"
                    value={inputs?.TestSettings?.TOX}
                    onBlur={handlePrecisionChange}
                    onChange={(e) =>
                      onChange(e.target.name, e.target.value, "TestSettings")
                    }
                  >
                    {[...Array(4).keys()].map((x) => (
                      <Select.Option key={x} value={x}>
                        {x}
                      </Select.Option>
                    ))}
                  </Select>
                </Select.Container>
              </Control>
            </Field>
            <Field kind="group">
              <Control expanded>
                <Label>MICRO</Label>
                <Select.Container fullwidth size="small">
                  <Select
                    disabled={disabled}
                    name="MICRO"
                    value={inputs?.TestSettings?.MICRO}
                    onBlur={handlePrecisionChange}
                    onChange={(e) =>
                      onChange(e.target.name, e.target.value, "TestSettings")
                    }
                  >
                    {[...Array(4).keys()].map((x) => (
                      <Select.Option key={x} value={x}>
                        {x}
                      </Select.Option>
                    ))}
                  </Select>
                </Select.Container>
              </Control>
              <Control expanded>
                <Label>METAL</Label>
                <Select.Container fullwidth size="small">
                  <Select
                    disabled={disabled}
                    name="METAL"
                    value={inputs?.TestSettings?.METAL}
                    onBlur={handlePrecisionChange}
                    onChange={(e) =>
                      onChange(e.target.name, e.target.value, "TestSettings")
                    }
                  >
                    {[...Array(4).keys()].map((x) => (
                      <Select.Option key={x} value={x}>
                        {x}
                      </Select.Option>
                    ))}
                  </Select>
                </Select.Container>
              </Control>
            </Field>
            <Field kind="group">
              <Control expanded>
                <Label>WATER</Label>
                <Select.Container fullwidth size="small">
                  <Select
                    disabled={disabled}
                    name="WATER"
                    value={inputs?.TestSettings?.WATER}
                    onBlur={handlePrecisionChange}
                    onChange={(e) =>
                      onChange(e.target.name, e.target.value, "TestSettings")
                    }
                  >
                    {[...Array(4).keys()].map((x) => (
                      <Select.Option key={x} value={x}>
                        {x}
                      </Select.Option>
                    ))}
                  </Select>
                </Select.Container>
              </Control>
              <Control expanded>
                <Label>PEST</Label>
                <Select.Container fullwidth size="small">
                  <Select
                    disabled={disabled}
                    name="PEST"
                    value={inputs?.TestSettings?.PEST}
                    onBlur={handlePrecisionChange}
                    onChange={(e) =>
                      onChange(e.target.name, e.target.value, "TestSettings")
                    }
                  >
                    {[...Array(4).keys()].map((x) => (
                      <Select.Option key={x} value={x}>
                        {x}
                      </Select.Option>
                    ))}
                  </Select>
                </Select.Container>
              </Control>
            </Field>
            <Field kind="group">
              <Control expanded>
                <Label>SOLV</Label>
                <Select.Container fullwidth size="small">
                  <Select
                    disabled={disabled}
                    name="SOLV"
                    value={inputs?.TestSettings?.SOLV}
                    onBlur={handlePrecisionChange}
                    onChange={(e) =>
                      onChange(e.target.name, e.target.value, "TestSettings")
                    }
                  >
                    {[...Array(4).keys()].map((x) => (
                      <Select.Option key={x} value={x}>
                        {x}
                      </Select.Option>
                    ))}
                  </Select>
                </Select.Container>
              </Control>
              <Control expanded>
                <Label>TERP</Label>
                <Select.Container fullwidth size="small">
                  <Select
                    disabled={disabled}
                    name="TERP"
                    value={inputs?.TestSettings?.TERP}
                    onBlur={handlePrecisionChange}
                    onChange={(e) =>
                      onChange(e.target.name, e.target.value, "TestSettings")
                    }
                  >
                    {[...Array(4).keys()].map((x) => (
                      <Select.Option key={x} value={x}>
                        {x}
                      </Select.Option>
                    ))}
                  </Select>
                </Select.Container>
              </Control>
            </Field>
          </Box>
        </Column> */}
      <Column mobile={{ size: 12 }} size={6}>
        <Box>
          <Title subtitle>Other Settings</Title>
          <Control>
            <BooleanInput
              disabled={disabled}
              label="Hide Graphs on CoA"
              name="_HideGraphs"
              // eslint-disable-next-line
              value={inputs?.TestSettings?._HideGraphs === "1"}
              onChange={(name, checked) =>
                onChange(name, checked ? "1" : "0", "TestSettings")
              }
            />
          </Control>
        </Box>
      </Column>
    </Column.Group>
  </form>
);
TestSettings.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  handleSave: PropTypes.func,
  disabled: PropTypes.bool,
};

TestSettings.defaultProps = {
  inputs: {},
  onChange: (e) => e,
  handleSave: (e) => e,
  disabled: false,
};

export default TestSettings;
