/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { useDropzone } from "react-dropzone";
import { Input, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

import { deepFlatten, readFile, Parser } from "../../../../../../utils";

const activeStyle = {
  backgroundColor: "#f0fff6",
  borderStyle: "solid",
};

const ImportResultsDropZone = ({ files, onChange }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (acceptedFiles) => {
      try {
        const result = await Promise.all(acceptedFiles.map(readFile));
        const parsed = result.map(Parser.parse.bind(Parser));
        onChange("files", [...files, ...deepFlatten(parsed)]);
      } catch (err) {
        toast.error(err.message);
      }
    },
  });

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
    }),
    [isDragActive]
  );

  return (
    <div className="import-results-drop-zone-container">
      <div className="import-results-drop-zone" {...getRootProps({ style })}>
        <Input {...getInputProps()} />
        <div className="import-results-drop-zone-content">
          <p>
            <Icon style={{ fontSize: 44 }}>
              <FontAwesomeIcon icon="vial" />
            </Icon>
          </p>
          <p>Drop Test Results Here</p>
        </div>
      </div>
    </div>
  );
};

ImportResultsDropZone.propTypes = {
  files: PropTypes.arrayOf(File),
  onChange: PropTypes.func,
};

ImportResultsDropZone.defaultProps = {
  files: [],
  onChange: () => { },
};
export default ImportResultsDropZone;
