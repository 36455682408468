import React from "react";

import { Switch, Route } from "react-router-dom";

import JobOrdersListPage from "./JobOrdersListPage";

const JobOrderRouter = () => (
  <Switch>
    <Route path="/job-orders">
      <JobOrdersListPage />
    </Route>
  </Switch>
);

export default JobOrderRouter;
