import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_LEGACY_JOB_ORDER_MUTATION = gql`
  mutation UPDATE_LEGACY_JOB_ORDER_MUTATION(
    $data: LegacyJobOrdersUpdateInput!
    $where: LegacyJobOrdersWhereUniqueInput!
  ) {
    updateLegacyJobOrders(data: $data, where: $where) {
      LegacyJobOrderID
    }
  }
`;
