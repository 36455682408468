import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field, Control, Label, Input, Button, Title } from "rbx";

const ReasonModal = ({ onConfirm, onCancel, message }) => {
  const [reason, setReason] = useState("");
  return (
    <React.Fragment>
      <Title subtitle>{message}</Title>
      <hr />
      <Field>
        <Control expanded>
          <Label>Enter a reason</Label>
          <Input
            name="reason"
            placeholder="Reason"
            size="small"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </Control>
      </Field>
      <Field align="centered" kind="group">
        <Control>
          <Button onClick={onCancel}>Cancel</Button>
        </Control>
        <Control>
          <Button color="primary" onClick={() => onConfirm(reason)}>
            Confirm
          </Button>
        </Control>
      </Field>
    </React.Fragment>
  );
};

ReasonModal.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  message: PropTypes.string.isRequired,
};
ReasonModal.defaultProps = {
  onConfirm: () => null,
  onCancel: () => null,
};

export default ReasonModal;
