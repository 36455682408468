import { gql } from "@apollo/client";

export const ALL_TEST_CATEGORIES_QUERY = gql`
  query ALL_TEST_CATEGORIES_QUERY(
    $where: TestCategoriesWhereInput
    $orderBy: [TestCategoriesOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyTestCategories(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      TestCategoryID
      Name
      Code
      Description
      CreatedDateTime
      ModifiedDateTime
      CreatedBy
      ModifiedBy
    }
  }
`;

export const LIST_TEST_CATEGORIES_QUERY = gql`
  query LIST_TEST_CATEGORIES_QUERY(
    $where: TestCategoriesWhereInput
    $orderBy: [TestCategoriesOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyTestCategories(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      TestCategoryID
      Name
      Code
      Description
      CreatedDateTime
      ModifiedDateTime
      CreatedBy
      ModifiedBy
    }
    aggregateTestCategories(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const SINGLE_TEST_CATEGORY_QUERY = gql`
  query SINGLE_TEST_CATEGORY_QUERY($where: TestCategoriesWhereUniqueInput!) {
    findUniqueTestCategories(where: $where) {
      TestCategoryID
      Name
      Code
      Description
      CreatedDateTime
      ModifiedDateTime
      CreatedBy
      ModifiedBy
    }
  }
`;

export const FIRST_TEST_CATEGORY_QUERY = gql`
  query FIRST_TEST_CATEGORY_QUERY($where: TestCategoriesWhereInput!) {
    findFirstTestCategories(where: $where) {
      TestCategoryID
      Name
      Code
      Description
    }
  }
`;
