import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faCode,
  faFilePdf,
  faBroom,
  faUser,
  faAngleDown,
  faSignOutAlt,
  faHome,
  faCogs,
  faBuilding,
  faFile,
  faList,
  faBriefcaseMedical,
  faChevronUp,
  faChevronDown,
  faArrowLeft,
  faArrowRight,
  faTimes,
  faUsers,
  faPlus,
  faMinus,
  faPhone,
  faAt,
  faAddressBook,
  faSync,
  faSyncAlt,
  faFilter,
  faPrint,
  faSearch,
  faCalendarTimes,
  faCopy,
  faEdit,
  faUpload,
  faDownload,
  faExternalLinkAlt,
  faDesktop,
  faVials,
  faUserMd,
  faFlask,
  faDollarSign,
  faMoneyBill,
  faVial,
  faCircle,
  faEye,
  faEyeSlash,
  faFileExport,
  faCog,
  faPercentage,
  faTrashAlt,
  faClone,
  faEnvelope,
  faArrowUp,
  faArrowDown,
  faShoppingCart,
  faCreditCard,
  faCalendar,
  faCheck,
  faMoneyBillWave,
  faTicketAlt,
  faFolderPlus,
  faCalculator,
  faExclamationCircle,
  faExclamation,
  faExclamationTriangle,
  faChevronLeft,
  faPen,
  faClipboardList,
  faClipboardCheck,
  faTasks,
  faRedo,
  faSkull,
  faListAlt,
  faFlag,
  faBan,
  faInfoCircle,
  faArchive,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCcVisa,
  faCcDiscover,
  faCcMastercard,
  faCcAmex,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faExclamationCircle,
  faExclamation,
  faExclamationTriangle,
  faBroom,
  faSync,
  faUser,
  faAngleDown,
  faSignOutAlt,
  faSyncAlt,
  faHome,
  faCogs,
  faBuilding,
  faFile,
  faBriefcaseMedical,
  faList,
  faChevronUp,
  faChevronDown,
  faArrowLeft,
  faArrowRight,
  faTimes,
  faUsers,
  faPlus,
  faMinus,
  faPhone,
  faAt,
  faAddressBook,
  faFilter,
  faPrint,
  faSearch,
  faCalendarTimes,
  faCopy,
  faEdit,
  faUpload,
  faDownload,
  faExternalLinkAlt,
  faDesktop,
  faVials,
  faUserMd,
  faFlask,
  faDollarSign,
  faMoneyBill,
  faVial,
  faCircle,
  faEye,
  faEyeSlash,
  faFileExport,
  faPercentage,
  faTrashAlt,
  faClone,
  faCog,
  faEnvelope,
  faArrowUp,
  faArrowDown,
  faShoppingCart,
  faCreditCard,
  faCcVisa,
  faCcDiscover,
  faCcMastercard,
  faCcAmex,
  faCalendar,
  faCheck,
  faMoneyBillWave,
  faTicketAlt,
  faFolderPlus,
  faCalculator,
  faChevronLeft,
  faPen,
  faClipboardList,
  faClipboardCheck,
  faTasks,
  faRedo,
  faSkull,
  faListAlt,
  faFlag,
  faBan,
  faFilePdf,
  faCode,
  faInfoCircle,
  faArchive
);
