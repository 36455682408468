import React, { useState } from "react";
import PropTypes from "prop-types";

import { Column, Field, Input, Label, Icon, Control, Help } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  PhoneNumberInput,
  LabLocationSelect,
  StateSelect,
  BooleanInput,
} from "../../../../components";
import EmployeeGroups from "../EmployeeGroups";

const EmployeeForm = ({
  inputs,
  onChange,
  disabled,
  onSubmit,
  formId,
  adding,
  UserID,
  isLabDisabled,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPassword(!showPassword);
  };

  return (
    <form id={formId} onSubmit={onSubmit}>
      <Column.Group multiline>
        <Column desktop={{ size: 6 }} tablet={{ size: 12 }}>
          <Field kind="group">
            <Control expanded size="small">
              <Label>Username</Label>
              <Input
                required
                disabled={disabled}
                maxLength={250}
                name="Username"
                placeholder="Username"
                size="small"
                value={inputs.Username}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            {!adding && (
              <Control expanded size="small">
                <Label>UserID</Label>
                <Input disabled size="small" value={inputs.UserID} />
              </Control>
            )}
          </Field>
          <Field kind="group">
            <Control expanded size="small">
              <Label>First Name</Label>
              <Input
                disabled={disabled}
                maxLength={250}
                name="FirstName"
                placeholder="Firstname"
                size="small"
                value={inputs.FirstName}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded size="small">
              <Label>Last Name</Label>
              <Input
                required
                disabled={disabled}
                maxLength={250}
                name="LastName"
                placeholder="LastName"
                size="small"
                value={inputs.LastName}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>

          <Field kind="group">
            <Control expanded size="small">
              <Label>Email</Label>
              <Input
                required
                disabled={disabled}
                maxLength={250}
                name="Email"
                placeholder="Email"
                size="small"
                type="email"
                value={inputs.Email}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <PhoneNumberInput
              required
              disabled={disabled}
              label="Phone"
              name="Phone"
              size="small"
              value={inputs.Phone}
              onChange={onChange}
            />
          </Field>
        </Column>
        <Column desktop={{ size: 6 }} tablet={{ size: 12 }}>
          <Field kind="group">
            <Control expanded>
              <LabLocationSelect
                required
                disabled={disabled || (adding && isLabDisabled)}
                id="LabID"
                label="Lab Location"
                name="LabID"
                size="small"
                value={inputs.LabID}
                onChange={onChange}
              />
            </Control>
            <Control>
              <Label>Active</Label>
              <BooleanInput
                disabled={disabled}
                name="Status"
                value={inputs.Status}
                onChange={(name, value) => onChange(name, value)}
              />
            </Control>
          </Field>
          <Field>
            <Control expanded size="small">
              <Label>Address</Label>
              <Input
                disabled={disabled}
                maxLength={250}
                name="Address"
                placeholder="Address"
                size="small"
                value={inputs.Address}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded size="small" style={{ width: "33%" }}>
              <Label>City</Label>
              <Input
                disabled={disabled}
                maxLength={250}
                name="City"
                placeholder="City"
                size="small"
                value={inputs.City}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded size="small" style={{ width: "33%" }}>
              <StateSelect
                disabled={disabled}
                id="State"
                label="State"
                name="State"
                size="small"
                value={inputs.State}
                onChange={onChange}
              />
            </Control>
            <Control expanded size="small" style={{ width: "33%" }}>
              <Label>Zip</Label>
              <Input
                disabled={disabled}
                maxLength={50}
                name="Zip"
                placeholder="Zip"
                size="small"
                value={inputs.Zip}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
        </Column>
        {adding && (
          <Column size={12}>
            <Field kind="group">
              <Control expanded size="small" style={{ width: "33%" }}>
                <Label>Password</Label>
                <Input
                  disabled={disabled}
                  maxLength={250}
                  name="Password"
                  placeholder="Password"
                  size="small"
                  type={showPassword ? "text" : "password"}
                  value={inputs.Password}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                />
                <Icon
                  className="password-visibility"
                  onClick={toggleShowPassword}
                >
                  <FontAwesomeIcon icon={showPassword ? "eye" : "eye-slash"} />
                </Icon>
              </Control>
              <Control expanded size="small" style={{ width: "33%" }}>
                <Label>Confirm Password</Label>
                <Input
                  disabled={disabled}
                  maxLength={250}
                  name="ConfirmPassword"
                  placeholder="Confirm Password"
                  size="small"
                  type={showPassword ? "text" : "password"}
                  value={inputs.ConfirmPassword}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                />
                <Icon
                  className="password-visibility"
                  onClick={toggleShowPassword}
                >
                  <FontAwesomeIcon icon={showPassword ? "eye" : "eye-slash"} />
                </Icon>
                {inputs.Password !== "" &&
                  inputs.ConfirmPassword !== "" &&
                  inputs.Password !== inputs.ConfirmPassword && (
                    <Help color="danger"> Passwords don&lsquo;t match</Help>
                  )}
              </Control>
            </Field>
          </Column>
        )}
      </Column.Group>
      {!adding && (
        <React.Fragment>
          <hr />
          <EmployeeGroups UserID={UserID} disabled={disabled} />
        </React.Fragment>
      )}
    </form>
  );
};

EmployeeForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  formId: PropTypes.string.isRequired,
  adding: PropTypes.bool,
  UserID: PropTypes.string,
  isLabDisabled: PropTypes.bool,
};

EmployeeForm.defaultProps = {
  onSubmit: () => null,
  disabled: false,
  adding: false,
  UserID: null,
  isLabDisabled: true,
};

export default EmployeeForm;
