import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { Button } from "rbx";
import { useParams, useHistory } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";

import LabTabs from "../components/LabTabs";
import { Loader, PageHeader } from "../../../components";

import { customToast as toast } from "../../../utils";

import {
  LIST_LABS_QUERY,
  UPDATE_LAB_MUTATION,
  SINGLE_LAB_QUERY,
} from "../../../graphql";

const INITIAL_STATE = {
  LabID: "",
  Company: "",
  CorporateCustomerServiceEmail: "clientservices@greenscientificlabs.com",
  OnsiteCustomerServiceEmail: "",
  Address: "",
  City: "",
  State: "",
  Zip: "",
  Tests: [],
  Phone: "",
  LicenseNumber: "",
  PaymentOptions: {
    CC: false,
    COD: false,
    PP: false,
    Terms: false,
    PPClientID: "",
    PPClientSecret: "",
  },
  ShowQualifiers: false,
  LabDirectorOneName: "",
  LabDirectorTwoTitle: "",
  LabDirectorTwoSignature: "",
  LabDirectorTwoName: "",
  LabDirectorOneTitle: "",
  LabDirectorOneSignature: "",
};

const LabPage = ({ routePermissions }) => {
  const { LabID, tab } = useParams();
  const history = useHistory();

  const [canUpdate, setCanUpdate] = useState(true);
  const [editMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [inputs, setInputs] = useState(INITIAL_STATE);

  const [updateLab] = useMutation(UPDATE_LAB_MUTATION);
  const [getLabData, resultLabData] = useLazyQuery(SINGLE_LAB_QUERY);

  const uploadLabSignaturePath = useMemo(() => {
    const date = new Date();
    return `/Images/Labs/Signature/${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${LabID}`;
  }, [LabID]);

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  useEffect(() => {
    if (LabID && !fetched && LabID !== "new") {
      setFetched(true);
      getLabData({
        variables: {
          where: { LabID: parseInt(LabID, 10) },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [fetched, LabID, getLabData, inputs, resultLabData]);

  useEffect(() => {
    if (resultLabData?.data) {
      const {
        data: { findUniqueLabs },
      } = resultLabData;

      const cleanObject = Object.fromEntries(
        Object.entries(findUniqueLabs).filter(([_, v]) => v !== null)
      );

      setInputs((prev) => ({
        ...prev,
        ...cleanObject,
      }));
      setLoading(false);
    }
  }, [resultLabData]);

  const updatePicture = async (data) => {
    try {
      setLoading(true);
      await updateLab({
        variables: {
          where: { LabID: parseInt(LabID, 10) },
          data,
        },
        refetchQueries: [
          {
            query: SINGLE_LAB_QUERY,
            variables: {
              where: { LabID: parseInt(LabID, 10) },
            },
            fetchPolicy: "network-only",
          },
        ],
      });
      toast.success("Signature updated successfully");
    } catch (err) {
      toast.error("Error updating Signature");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (name, value) => {
    const names = name.split(".");
    if (names.length === 1) {
      setInputs((prev) => ({
        ...prev,
        [name]: value,
      }));
      if (
        name === "LabDirectorOneSignature" ||
        name === "LabDirectorTwoSignature"
      ) {
        updatePicture({ [name]: { set: value } });
      }
    } else {
      setInputs((prev) => ({
        ...prev,
        [names[0]]: {
          ...prev[names[0]],
          [names[1]]: value,
        },
      }));
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setLoading(true);
      if (LabID && LabID !== "new") {
        const obj = Object.keys(INITIAL_STATE).reduce((acc, curr) => {
          if (inputs[curr] !== null) {
            return {
              ...acc,
              [curr]: {
                set: inputs[curr],
              },
            };
          }
          return acc;
        }, {});
        delete obj.LabID;
        await updateLab({
          variables: {
            data: {
              ...obj,
              PaymentOptions: inputs.PaymentOptions,
            },
            where: {
              LabID: parseInt(LabID, 10),
            },
          },
          refetchQueries: [
            {
              query: LIST_LABS_QUERY,
              variables: {
                where: {},
              },
              fetchPolicy: "network-only",
            },
            {
              query: SINGLE_LAB_QUERY,
              variables: {
                where: { LabID: parseInt(LabID, 10) },
              },
              fetchPolicy: "network-only",
            },
          ],
        });
        toast.success("Laboratory Updated successfully");
      }
    } catch (err) {
      toast.error("Error updating Laboratory");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push("/labs");
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="employee-list-page">
      <PageHeader title={`Laboratory ${inputs.Company}`}>
        {canUpdate && (
          <React.Fragment>
            <Button disabled={editMode} size="small" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              rounded
              color="primary"
              disabled={editMode}
              form="lab-account-details"
              size="small"
              type="submit"
            >
              Save
            </Button>
          </React.Fragment>
        )}
      </PageHeader>
      <LabTabs
        LabID={LabID}
        disabled={editMode || !canUpdate}
        history={history}
        inputs={inputs}
        tab={tab}
        uploadLabSignaturePath={uploadLabSignaturePath}
        onChange={handleChange}
        onSubmit={handleSave}
      />
    </div>
  );
};

LabPage.propTypes = {
  routePermissions: PropTypes.array,
};

LabPage.defaultProps = {
  routePermissions: [],
};

LabPage.propTypes = {};

export default LabPage;
