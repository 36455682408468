/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import { Control, Input } from "rbx";
import { BooleanInput } from "../../../../../components";

const ActionLimitTemplateTable = ({
  columns,
  data,
  onChange,
  canUpdate,
  isMicro,
}) => (
  <div className="main-table-container">
    <div
      className="row-container header"
      style={{
        gridTemplateColumns: isMicro
          ? `0.5fr repeat(${columns.length - 1}, 1fr)`
          : `0.7fr repeat(${columns.length - 1}, 1fr)`,
      }}
    >
      {columns.map((column) => (
        <div key={column.id}>{column.Header}</div>
      ))}
    </div>
    <hr />
    {data.map((element, index) => (
      <React.Fragment key={`${element.Analyte.AnalyteID}-${index}`}>
        <div
          className="row-container body"
          style={{
            gridTemplateColumns: isMicro
              ? `0.5fr repeat(${columns.length - 1}, 1fr)`
              : `0.7fr repeat(${columns.length - 1}, 1fr)`,
          }}
        >
          <BooleanInput
            disabled={!canUpdate}
            name="Active"
            value={element.Active}
            onChange={(name, value) => onChange(index, name, value, element)}
          />
          <Control>
            <Input readOnly size="small" value={element.Analyte.Name} />
          </Control>

          {!isMicro && (
            <React.Fragment>
              <Control>
                <Input
                  disabled={!canUpdate}
                  name="ActionLevel"
                  size="small"
                  value={element.ActionLevel}
                  onChange={(e) =>
                    onChange(index, e.target.name, e.target.value, element)
                  }
                />
              </Control>
              <Control>
                <Input
                  disabled={!canUpdate}
                  name="LOD"
                  size="small"
                  value={element.LOD}
                  onChange={(e) =>
                    onChange(index, e.target.name, e.target.value, element)
                  }
                />
              </Control>
              <Control>
                <Input
                  disabled={!canUpdate}
                  name="LOQ"
                  size="small"
                  value={element.LOQ}
                  onChange={(e) =>
                    onChange(index, e.target.name, e.target.value, element)
                  }
                />
              </Control>
              <Control>
                <Input
                  disabled={!canUpdate}
                  name="InSampleLOD"
                  size="small"
                  value={element.InSampleLOD}
                  onChange={(e) =>
                    onChange(index, e.target.name, e.target.value, element)
                  }
                />
              </Control>
              <Control>
                <Input
                  disabled={!canUpdate}
                  name="InSampleLOQ"
                  size="small"
                  value={element.InSampleLOQ}
                  onChange={(e) =>
                    onChange(index, e.target.name, e.target.value, element)
                  }
                />
              </Control>
            </React.Fragment>
          )}
          {isMicro && (
            <React.Fragment>
              <Control>
                <Input
                  disabled={!canUpdate}
                  name="LOD"
                  size="small"
                  value={element.LOD}
                  onChange={(e) =>
                    onChange(index, e.target.name, e.target.value, element)
                  }
                />
              </Control>
              <Control>
                <Input
                  disabled={!canUpdate}
                  name="AllowableCriteria"
                  size="small"
                  value={element.AllowableCriteria}
                  onChange={(e) =>
                    onChange(index, e.target.name, e.target.value, element)
                  }
                />
              </Control>
              <Control>
                <Input
                  disabled={!canUpdate}
                  name="UpperLimit"
                  size="small"
                  value={element.UpperLimit}
                  onChange={(e) =>
                    onChange(
                      index,
                      e.target.name,
                      e.target.value || null,
                      element
                    )
                  }
                />
              </Control>
            </React.Fragment>
          )}
        </div>
        {index !== data.length - 1 && (
          <hr style={{ backgroundColor: "#e8e7e7", height: 0.5 }} />
        )}
      </React.Fragment>
    ))}
  </div>
);
ActionLimitTemplateTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  onChange: PropTypes.func,
  canUpdate: PropTypes.bool.isRequired,
  isMicro: PropTypes.bool.isRequired,
};

ActionLimitTemplateTable.defaultProps = {
  columns: [],
  data: [],
  onChange: () => null,
};
export default ActionLimitTemplateTable;
