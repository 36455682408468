import React from "react";
import PropTypes from "prop-types";
import { Column, Field, Input, Label, Control, Textarea } from "rbx";

const TestCategoryForm = ({ inputs, onChange, disabled, onSubmit }) => {
  const handleChange = (e) => {
    onChange(e.target.name, e.target.value);
  };

  return (
    <form id="test-category-form" onSubmit={onSubmit}>
      <Column.Group multiline>
        <Column size={12}>
          <Field kind="group">
            <Control expanded size="small">
              <Label>Name</Label>
              <Input
                required
                disabled={disabled}
                maxLength={250}
                name="Name"
                placeholder="Name"
                size="small"
                value={inputs.Name}
                onChange={handleChange}
              />
            </Control>
            <Control expanded size="small">
              <Label>Code</Label>
              <Input
                required
                disabled={disabled}
                maxLength={250}
                name="Code"
                placeholder="Code"
                size="small"
                value={inputs.Code}
                onChange={handleChange}
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded size="medium">
              <Label>Description</Label>
              <Textarea
                required
                disabled={disabled}
                maxLength={250}
                name="Description"
                placeholder="Description"
                size="small"
                value={inputs.Description}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
        </Column>
      </Column.Group>
    </form>
  )
};

TestCategoryForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
};

TestCategoryForm.defaultProps = {
  onSubmit: () => null,
  disabled: false,
};

export default TestCategoryForm;
