import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation, useLazyQuery } from "@apollo/client";
import { Title, Fieldset, Button } from "rbx";
import { customToast as toast } from "../../../../../../utils";
import SampleTrackingForm from "../SampleLocationForm";
import Loader from "../../../../../../components/Loader";
import {
  CREATE_SAMPLE_TRACKING_MUTATION,
  ALL_SAMPLE_TRACKINGS_QUERY,
  SINGLE_SAMPLE_TRACKING_QUERY,
  UPDATE_SAMPLE_TRACKING_MUTATION,
} from "../../../../../../graphql";

const INITIAL_STATE = {
  Value: "",
  Name: "",
};

const convertInputToVariables = (variables, keys, adding = true) =>
  keys.reduce((acc, curr) => {
    if (variables[curr]) {
      acc[curr] = variables[curr];
    } else {
      acc[curr] = null;
    }
    return acc;
  }, {});

const SampleLocationModal = ({ onComplete, SampleTrackingID }) => {
  const [loading, setLoading] = useState(!!SampleTrackingID);
  const [createSampleTracking] = useMutation(CREATE_SAMPLE_TRACKING_MUTATION);
  const [updateSampleTracking] = useMutation(UPDATE_SAMPLE_TRACKING_MUTATION);
  const [inputs, setInputs] = useState({
    ...INITIAL_STATE,
  });
  
  const [getSampleTrackingData, resultSampleTrackingData] = useLazyQuery(
    SINGLE_SAMPLE_TRACKING_QUERY
    );
    
    useEffect(() => {
      if (SampleTrackingID) {
        getSampleTrackingData({
          variables: {
            where: { SampleTrackingID: parseInt(SampleTrackingID, 10) },
          },
          fetchPolicy: "network-only",
        });
      }
    }, [SampleTrackingID, getSampleTrackingData]);

  useEffect(() => {
    if (resultSampleTrackingData?.data?.sampleTracking) {
      const {
        data: { sampleTracking },
      } = resultSampleTrackingData;
      setInputs((prev) => ({
        ...prev,
        ...sampleTracking,
      }));
      setLoading(false);
    }
  }, [resultSampleTrackingData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (SampleTrackingID) {
        const obj = Object.keys(INITIAL_STATE).reduce((acc, curr) => {
          if (inputs[curr] !== null) {
            return {
              ...acc,
              [curr]: {
                set: inputs[curr],
              },
            };
          }
          return acc;
        }, {});
        await updateSampleTracking({
          variables: {
            data: {
              ...obj,
              Lab: {
                connect: {
                  LabID: parseInt(inputs.LabID, 10),
                },
              },
            },
            where: {
              SampleTrackingID: parseInt(SampleTrackingID, 10),
            },
          },
          refetchQueries: [
            {
              query: ALL_SAMPLE_TRACKINGS_QUERY,
              variables: {
                where: {},
              },
              fetchPolicy: "network-only",
            },
            {
              query: SINGLE_SAMPLE_TRACKING_QUERY,
              variables: {
                where: { SampleTrackingID: parseInt(SampleTrackingID, 10) },
              },
              fetchPolicy: "network-only",
            },
          ],
        });

        toast.success("Sample Location updated successfully.");
      } else {
        await createSampleTracking({
          variables: {
            data: {
              ...convertInputToVariables(
                inputs,
                Object.keys(INITIAL_STATE),
                true
              ),
              Lab: {
                connect: {
                  LabID: parseInt(inputs.LabID, 10),
                },
              },
            },
          },
          refetchQueries: [
            {
              query: ALL_SAMPLE_TRACKINGS_QUERY,
              variables: {
                where: {},
              },
            },
          ],
        });
        toast.success("Sample Location created successfully.");
      }
      onComplete();
    } catch (err) {
      toast.error(
        `Error ${SampleTrackingID ? "updating" : "creating"} Sample Location.`
      );
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader style={{ height: "330px" }} />;
  }
  return (
    <React.Fragment>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {SampleTrackingID ? "Update" : "Create"} Sample Location
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group>
            <Button
              rounded
              color="primary"
              size="small"
              type="button"
              onClick={onComplete}
            >
              <span>Cancel</span>
            </Button>
            <Button
              rounded
              color="primary"
              form="add-sample-location-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Fieldset>
        <SampleTrackingForm
          adding
          formId="add-sample-location-form"
          inputs={inputs}
          onChange={handleChange}
          onSubmit={handleSave}
        />
      </Fieldset>
      <hr />
    </React.Fragment>
  );
};

SampleLocationModal.propTypes = {
  onComplete: PropTypes.func,
  SampleTrackingID: PropTypes.string,
};

SampleLocationModal.defaultProps = {
  onComplete: (e) => e,
  SampleTrackingID: null,
};

export default SampleLocationModal;
