import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const CREATE_CREDIT_LEDGER_MUTATION = gql`
  mutation CREATE_CREDIT_LEDGER_MUTATION($data: CreditLedgerCreateInput!) {
    createCreditLedger(data: $data) {
      CreditID
    }
  }
`;
