import React from "react";
import { PropTypes } from "prop-types";
import { Column, Field, Control, Label, Button, Box } from "rbx";
import { endOfDay, startOfDay, isValid } from "date-fns";
import {
  DateInput,
  LabLocationSelect,
  FilterInput,
} from "../../../../components";

const JobOrdersFilters = ({ where, onChange, onReset, lab }) => (
  <Box>
    <Column.Group>
      <Column>
        <Field>
          <FilterInput
            getValue={(x) => x?.is?.Username?.equals || ""}
            label="Client"
            name="User"
            placeholder="Client"
            setValue={(x) =>
              x
                ? {
                    is: { Username: { equals: x } },
                  }
                : ""
            }
            value={where?.User}
            onChange={onChange}
          />
        </Field>
      </Column>
      <Column>
        <Field>
          <DateInput
            getValue={(x) => {
              const date = x?.gte;
              return date ? date.substr(0, 10) : "";
            }}
            label="Completed Date Start"
            name="OrderCompleteDate"
            setValue={(x) => {
              if (
                x &&
                isValid(new Date(x.split("-").map((y) => parseInt(y, 10))))
              ) {
                return {
                  lte: where?.OrderCompleteDate?.lte,
                  gte: startOfDay(
                    new Date(x.split("-").map((y) => parseInt(y, 10)))
                  ).toISOString(),
                };
              }
              return "";
            }}
            value={where?.OrderCompleteDate}
            onChange={onChange}
          />
        </Field>
      </Column>
      <Column>
        <Field>
          <DateInput
            getValue={(x) => {
              const date = x?.lte;
              return date ? date.substr(0, 10) : "";
            }}
            label="Completed Date End"
            name="OrderCompleteDate"
            setValue={(x) => {
              if (
                x &&
                isValid(new Date(x.split("-").map((y) => parseInt(y, 10))))
              ) {
                return {
                  lte: endOfDay(
                    new Date(x.split("-").map((y) => parseInt(y, 10)))
                  ).toISOString(), // this one is displaying as one day ahead becasue of timezone (4 hours) effects the start date as well by 4 hours
                  gte: where?.OrderCompleteDate?.gte,
                };
              }
              return "";
            }}
            value={where?.OrderCompleteDate}
            onChange={onChange}
          />
        </Field>
      </Column>
      {lab?.Company === "All Location" && (
        <Column>
          <Field>
            <LabLocationSelect
              showLabel
              getValue={(x) =>
                typeof x?.is?.LabID?.equals === "number"
                  ? x?.is?.LabID?.equals
                  : ""
              }
              name="Job"
              setValue={(x) =>
                typeof x === "number" ? { is: { LabID: { equals: x } } } : ""
              }
              size="small"
              value={where?.Job}
              onChange={onChange}
            />
          </Field>
        </Column>
      )}
      <Column size={1}>
        <Control>
          <Label>&nbsp;</Label>
          <Button color="primary" size="small" onClick={onReset}>
            Reset
          </Button>
        </Control>
      </Column>
    </Column.Group>
  </Box>
);
JobOrdersFilters.propTypes = {
  where: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onReset: PropTypes.func,
  lab: PropTypes.object.isRequired,
};
JobOrdersFilters.defaultProps = {
  onChange: () => null,
  onReset: () => null,
};
export default JobOrdersFilters;
