import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";

import DataTable from "../../../../components/DataTable";

import {
  LIST_LABS_TESTS_QUERY,
  DELETE_LAB_TEST_MUTATION,
} from "../../../../graphql";
import PageHeader from "../../../../components/PageHeader";
import Confirmation from "../../../../components/Confirmation";
import LabTestModal from "../../components/LabTestModal";
import { useModal } from "../../../../context/ModalContext";
import { customToast as toast } from "../../../../utils";
import { generateColumns } from "./columns";

const TestInfo = ({ inputs, onChange, disabled }) => {
  const { LabID } = useParams();
  const where = { LabID: { equals: parseInt(LabID, 10) } };
  const orderBy = [{ id: "TestID", desc: false }];
  const { setModalOpen } = useModal();
  const [deleteLabTest] = useMutation(DELETE_LAB_TEST_MUTATION);

  const COLUMNS = useMemo(() => {
    const handleDelete = (labTest) => {
      const performDelete = async () => {
        try {
          setModalOpen(false, "");
          await deleteLabTest({
            variables: {
              where: {
                LabTestID: parseInt(labTest.LabTestID, 10),
              },
            },
            refetchQueries: [
              {
                query: LIST_LABS_TESTS_QUERY,
                variables: {
                  where: {},
                },
              },
            ],
          });
          toast.success("Laboratory Test deleted successfully.");
        } catch (err) {
          toast.error(`Error deleting Laboratory Test.`);
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this Laboratory Test?"
          onCancel={() => setModalOpen(false, "")}
          onConfirm={performDelete}
        />
      );
    };
    return generateColumns(handleDelete);
  }, [deleteLabTest, setModalOpen]);

  const handleRowClick = (labItem) => {
    setModalOpen(
      true,
      <LabTestModal
        LabID={LabID}
        LabTestID={labItem.LabTestID}
        onComplete={() => setModalOpen(false, "")}
      />
    );
  };

  const handleCreateLab = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModalOpen(
      true,
      <LabTestModal
        LabID={LabID}
        LabTestID={null}
        onComplete={() => setModalOpen(false, "")}
      />
    );
  };

  return (
    <div className="lab-test-list-page">
      <PageHeader
        buttonText="Create Lab Test"
        title="Laboratory Test"
        onClick={handleCreateLab}
      />
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateLabTests"
        columns={COLUMNS}
        name="findManyLabTests"
        orderBy={orderBy}
        query={LIST_LABS_TESTS_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

TestInfo.propTypes = {
  inputs: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

TestInfo.defaultProps = {
  inputs: {},
  disabled: false,
  onChange: () => null,
};
export default TestInfo;
