/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from "react";
import { format } from "date-fns";
import { Generic, Button } from "rbx";
import { Link } from "react-router-dom";

const DEFAULT_COLUMNS = [
  {
    Header: "Description",
    id: "Description",
    accessor: "Description",
  },
  {
    Header: "Created By",
    id: "CreatedBy",
    accessor: "CreatedBy",
  },
  {
    Header: "Modified By",
    id: "ModifiedBy",
    accessor: "ModifiedBy",
  },
  {
    Header: "Created At",
    id: "CreatedDateTime",
    accessor: "CreatedDateTime",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM-dd-yyyy") : "",
  },
  {
    Header: "Modified At",
    id: "ModifiedDateTime",
    accessor: "ModifiedDateTime",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM-dd-yyyy") : "",
  },
];

export const generateColumns = () => [
  ...DEFAULT_COLUMNS,
  {
    Header: "Download",
    id: "download",
    Cell: (cell) =>
      cell.row.original.AttachmentURL ? (
        <Generic
          style={{ textAlign: "center" }}
          type="button"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Link
            download
            target="_blank"
            to={{ pathname: cell.row.original.AttachmentURL }}
          >
            <Button rounded color="primary" size="small">
              <span>Download</span>
            </Button>
          </Link>
        </Generic>
      ) : (
        "--"
      ),
  },
];

export default DEFAULT_COLUMNS;
