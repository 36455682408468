import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Fieldset, Button, Column, Field, Control, Input, Label } from "rbx";
import { customToast as toast } from "../../../utils";
import { PageHeader } from "../../../components";
import UserSelect from "../../../components/UserSelect";
import {
  CREATE_CREDIT_LEDGER_MUTATION,
  ALL_CREDIT_LEDGER_QUERY,
} from "../../../graphql";
import { useAuth } from "../../../context";

const DEFAULT_STATE = {
  User: "",
  Credit: "",
  Reason: "",
};

const convertInputToVariables = (variables, keys) =>
  keys.reduce((acc, curr) => {
    if (variables[curr]) {
      acc[curr] = variables[curr];
    } else {
      acc[curr] = null;
    }
    return acc;
  }, {});

const AddCreditModal = ({ onComplete }) => {
  const { state: authState } = useAuth();
  const [createCredit] = useMutation(CREATE_CREDIT_LEDGER_MUTATION);

  const [inputs, setInputs] = useState({
    ...DEFAULT_STATE,
  });

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      await createCredit({
        variables: {
          data: {
            ...convertInputToVariables(inputs, Object.keys(DEFAULT_STATE)),
            User: {
              connect: { UserID: parseInt(inputs.User, 10) },
            },
            CreatedBy: authState?.user?.Username,
          },
        },
        refetchQueries: [
          {
            query: ALL_CREDIT_LEDGER_QUERY,
            variables: {
              where: {},
              orderBy: { UserID: "asc" },
            },
          },
        ],
      });
      toast.success("Credit created successfully.");
      onComplete();
    } catch (err) {
      toast.error("Error creating credit.");
    }
  };

  return (
    <React.Fragment>
      <PageHeader title="Credit">
        <Button.Group>
          <Button
            color="primary"
            size="small"
            type="button"
            onClick={() => onComplete()}
          >
            <span>Cancel</span>
          </Button>
          <Button
            color="primary"
            form="add-referrer-form"
            size="small"
            type="submit"
            onClick={(e) => handleSave(e)}
          >
            <span>Submit</span>
          </Button>
        </Button.Group>
      </PageHeader>
      <Fieldset>
        <Column.Group multiline>
          <Column desktop={{ size: 6 }} tablet={{ size: 12 }}>
            <Field kind="group">
              <UserSelect
                label="Client"
                name="User"
                value={inputs.User}
                where={{
                  OR: [
                    { Type: { is: { CodeDescription: { equals: "Client" } } } },
                    {
                      Type: {
                        is: { CodeDescription: { equals: "Parent Client" } },
                      },
                    },
                  ],
                }}
                onChange={handleChange}
              />
            </Field>
          </Column>
          <Column desktop={{ size: 6 }} tablet={{ size: 12 }}>
            <Field kind="group">
              <Control expanded size="small">
                <Label>Amount</Label>
                <Input
                  required
                  name="Credit"
                  placeholder="Credit"
                  size="small"
                  type="number"
                  value={inputs.Credit}
                  onChange={(e) =>
                    handleChange(
                      e.target.name,
                      Math.abs(parseFloat(e.target.value))
                    )
                  }
                />
              </Control>
            </Field>
          </Column>
          <Column desktop={{ size: 12 }} tablet={{ size: 12 }}>
            <Field kind="group">
              <Control expanded size="small">
                <Label>Reason</Label>
                <Input
                  required
                  maxLength={250}
                  name="Reason"
                  placeholder="Reason"
                  size="small"
                  value={inputs.Reason}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </Control>
            </Field>
          </Column>
        </Column.Group>
      </Fieldset>
    </React.Fragment>
  );
};
AddCreditModal.propTypes = {
  onComplete: PropTypes.func,
};

AddCreditModal.defaultProps = {
  onComplete: (e) => e,
};

export default AddCreditModal;
