import React from "react";

import { Switch, Route } from "react-router-dom";

import CoasListPage from "./CoasListPage";

const CoasRouter = (props) => (
  <Switch>
    <Route path="/coas">
      <CoasListPage />
    </Route>
  </Switch>
);

export default CoasRouter;
