import React from "react";
import PropTypes from "prop-types";
import { CreditCard, PaymentForm } from "rswp-sdk";
import { Control } from "rbx";
import { customToast as toast } from "../../../../utils";

const { REACT_APP_APLLICATION_ID, REACT_APP_LOCATION_ID } = process.env;

const PaymentFormComponent = ({
  inputs,
  onChange,
  onSubmit,
  setValidExpiration,
  validExpiration,
  validCreditCard,
  setValidCreditCart,
  loading,
  disabled,
  paymentAmount,
}) => (
  <Control expanded id="card-input-container">
    <PaymentForm
      applicationId={REACT_APP_APLLICATION_ID}
      cardTokenizeResponseReceived={async (response) => {
        if (response.status === "OK" && !!response.token) {
          await onSubmit({ token: response.token });
        } else {
          toast.error("Error processing card");
        }
      }}
      createVerificationDetails={() => ({
        amount: paymentAmount.toString(),
        intent: "CHARGE",
        currencyCode: "USD",
        billingContact: {
          addressLines: [
            inputs.billToAddress || "",
            inputs.billToAddress2 || "",
          ],
          familyName: inputs.billToLastName,
          givenName: inputs.billToFirstName,
          countryCode: inputs.billToCountry,
          city: inputs.billToCity,
        },
      })}
      locationId={REACT_APP_LOCATION_ID}
    >
      <CreditCard
        buttonProps={{
          isLoading: loading,
          css: {
            borderRadius: "20px 0px 20px 20px",
            background: "linear-gradient(to right, #3bb78e, #059e42)",
            maxWidth: "fit-content",
            padding: "5px 20px",
            margin: "auto",
          },
        }}
      >
        Submit Payment
      </CreditCard>
    </PaymentForm>
  </Control>
);
PaymentFormComponent.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setValidExpiration: PropTypes.func.isRequired,
  validExpiration: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  validCreditCard: PropTypes.bool,
  setValidCreditCart: PropTypes.func,
  paymentAmount: PropTypes.number,
};
PaymentFormComponent.defaultProps = {
  validExpiration: false,
  loading: false,
  disabled: false,
  validCreditCard: false,
  setValidCreditCart: () => null,
  paymentAmount: 0,
};

export default PaymentFormComponent;
