import React from "react";
import PropTypes from "prop-types";
import { Box, Field, Control, Label, Button } from "rbx";

import LabLocationSelect from "../../../../../../components/LabLocationSelect";

const SampleLocationFilters = ({
  handleFilterChange,
  handleFiltersReset,
  inputs,
}) => (
  <Box>
    <Field>
      <LabLocationSelect
        getValue={(x) => (typeof x?.equals === "number" ? x?.equals : "")}
        id="LabName"
        label="Lab Name"
        name="Lab"
        setValue={(x) =>
          typeof x === "number" ? { is: { LabID: { equals: x } } } : {}
        }
        value={inputs?.Lab?.is?.LabID}
        onChange={handleFilterChange}
      />
      <Control>
        <Label>&nbsp;</Label>
        <Button color="primary" size="small" onClick={handleFiltersReset}>
          Reset
        </Button>
      </Control>
    </Field>
  </Box>
);

SampleLocationFilters.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  handleFiltersReset: PropTypes.func.isRequired,
  inputs: PropTypes.object,
};

SampleLocationFilters.defaultProps = {
  inputs: {},
};

export default SampleLocationFilters;
