import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Title, Fieldset, Button } from "rbx";
import { customToast as toast } from "../../../../utils";
import EquipmentForm from "../EquipmentForm";
import {
  CREATE_EQUIPMENT_MUTATION,
  ALL_EQUIPMENT_QUERY,
} from "../../../../graphql";

const DEFAULT_STATE = {
  Name: "",
  Manufacturer: "",
  SerialNumber: "",
  ModelNumber: "",
  CalibrationCycleID: "",
  LastCalibrationDate: new Date().toISOString(),
  MaintenanceCycleID: "",
  LastMaintenanceDate: new Date().toISOString(),
  ImageURL: "",
  Active: false,
  SamplePrep: false,
};

const convertInputToVariables = (variables, keys, adding = true) =>
  keys.reduce((acc, curr) => {
    if (variables[curr] !== null || variables[curr] !== undefined) {
      acc[curr] = variables[curr];
    } else {
      acc[curr] = null;
    }

    return acc;
  }, {});

const AddEquipmentModal = ({ onComplete }) => {
  const [createEquipment] = useMutation(CREATE_EQUIPMENT_MUTATION);

  const [inputs, setInputs] = useState({
    ...DEFAULT_STATE,
  });

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();

    try {
      const createData = {
        ...convertInputToVariables(inputs, Object.keys(DEFAULT_STATE), true),
        Lab: {
          connect: {
            LabID: parseInt(inputs.LabID, 10),
          },
        },
        MaintenanceCycleSystemCode: {
          connect: {
            RecId: parseInt(inputs.MaintenanceCycleID, 10) || "",
          },
        },
        CalibrationCycleSystemCode: {
          connect: {
            RecId: parseInt(inputs.CalibrationCycleID, 10) || "",
          },
        },
      };
      delete createData.MaintenanceCycleID;
      delete createData.CalibrationCycleID;
      await createEquipment({
        variables: {
          data: createData,
        },
        refetchQueries: [
          {
            query: ALL_EQUIPMENT_QUERY,
            variables: {
              where: {},
            },
          },
        ],
      });
      toast.success("Equipment created successfully.");
      onComplete();
    } catch (err) {
      toast.error("Error creating Equipment.");
    }
  };

  return (
    <React.Fragment>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Create Equipment</Title>
        </div>
        <div className="page-head-end">
          <Button.Group>
            <Button size="small" type="button" onClick={() => onComplete()}>
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              form="add-equipment-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Fieldset>
        <EquipmentForm
          adding
          formId="add-equipment-form"
          inputs={inputs}
          onChange={handleChange}
          onSubmit={handleSave}
        />
      </Fieldset>
      <hr />
    </React.Fragment>
  );
};

AddEquipmentModal.propTypes = {
  onComplete: PropTypes.func,
};

AddEquipmentModal.defaultProps = {
  onComplete: (e) => e,
};

export default AddEquipmentModal;
