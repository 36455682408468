import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import { Field, Control, Title, Section } from "rbx";

import { useQuery } from "@apollo/client";
import Table from "./Table";
import Pagination from "./Pagination";

import { useLocalStorage } from "../../hooks";
import Loader from "../Loader";

const DataTable = ({
  aggregateName,
  aggregateKey,
  name,
  columns,
  query,
  where,
  orderBy,
  onRowClick,
  fetchPolicy,
  renderRowSubComponent,
  useSubRows,
  title,
  hiddenColumns,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [orderByMulti, setOrderByMulti] = useState(orderBy);

  const [defaultPageSize, setDefaultPageSize] = useLocalStorage(
    `DEFAULT_PAGE_SIZE`,
    25
  );

  const handleChangeSort = useCallback(async (sortBy) => {
    setOrderByMulti(sortBy);
  }, []);

  const { data } = useQuery(query, {
    variables: {
      where,
      orderBy: orderByMulti.map((x) => {
        if (x.id.includes(":")) {
          const sorts = x.id.split(":");
          return { [sorts[0]]: { [sorts[1]]: x.desc ? "desc" : "asc" } };
        }
        return { [x.id]: x.desc ? "desc" : "asc" };
      }),
      take: defaultPageSize,
      skip: currentPage * defaultPageSize,
    },
    fetchPolicy,
  });

  useEffect(() => {
    setCurrentPage(0);
  }, [where]);

  if (!Array.isArray(data?.[name])) {
    return <Loader />;
  }

  const recordCount = data?.[aggregateName]?.count
    ? data[aggregateName].count[aggregateKey]
    : 0;

  const pageCount = data?.[aggregateName]?.count
    ? Math.ceil(recordCount / defaultPageSize)
    : "1";
  if (Array.isArray(data?.[name]) && data?.[name]?.length === 0) {
    return (
      <Title subtitle style={{ color: "gray", padding: "1.5rem" }}>
        Nothing here...
      </Title>
    );
  }

  const tableData = useSubRows
    ? data?.[name].map((item) => {
        const subRows = [{ Username: item.Username }];
        return { ...item, subRows };
      })
    : data?.[name];

  return (
    <Section className="table-section">
      {!!title.length && <Title size={3}>{title}</Title>}
      <Table
        columns={columns}
        data={tableData}
        hiddenColumns={hiddenColumns}
        initialSortBy={orderByMulti}
        renderRowSubComponent={renderRowSubComponent}
        onChangeSort={handleChangeSort}
        onRowClick={onRowClick}
      />
      <Field align="right" kind="group">
        <Control>
          <Pagination
            canNextPage={currentPage + 1 < pageCount}
            canPreviousPage={currentPage + 1 > 1}
            page={currentPage + 1}
            pageSize={defaultPageSize}
            pages={pageCount}
            recordCount={recordCount}
            setPageSize={setDefaultPageSize}
            onClickNext={() => {
              setCurrentPage((prev) => prev + 1);
            }}
            onClickPrev={() => {
              setCurrentPage((prev) => prev - 1);
            }}
          />
        </Control>
      </Field>
    </Section>
  );
};

DataTable.propTypes = {
  aggregateName: PropTypes.string.isRequired,
  aggregateKey: PropTypes.string,
  name: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  query: PropTypes.object.isRequired,
  where: PropTypes.object,
  orderBy: PropTypes.array,
  onRowClick: PropTypes.func,
  fetchPolicy: PropTypes.string,
  renderRowSubComponent: PropTypes.func,
  useSubRows: PropTypes.bool,
  title: PropTypes.string,
  hiddenColumns: PropTypes.array,
};

DataTable.defaultProps = {
  onRowClick: () => null,
  where: {},
  orderBy: [],
  fetchPolicy: "cache-and-network",
  aggregateKey: "_all",
  renderRowSubComponent: () => null,
  useSubRows: false,
  title: "",
  hiddenColumns: [],
};
export default DataTable;
