const creditLedger = {
  name: "creditLedger",
  label: "Credit Ledger",
  icon: "folder-plus",
  permissions: ["CRDT_LDGR"],
  active: (location) => {
    const [, p] = location.pathname.split("/");
    return /credit-ledger/.test(p);
  },
  to: "/credit-ledger",
};

export default creditLedger;
