import { gql } from "@apollo/client";

export const LIST_USER_GROUPS_QUERY = gql`
  query LIST_USER_GROUPS_QUERY(
    $where: UserGroupsWhereInput
    $orderBy: [UserGroupsOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyUserGroups(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      GroupID
      Name
      Description
      UserGroupPermissions {
        UserGroupPermissionID
        Permission {
          PermissionID
          Name
        }
      }
      CreatedDateTime
      ModifiedDateTime
      CreatedBy
      ModifiedBy
    }
    aggregateUserGroups(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const ALL_USER_GROUPS_QUERY = gql`
  query ALL_USER_GROUPS_QUERY(
    $where: UserGroupsWhereInput
    $orderBy: [UserGroupsOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyUserGroups(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      GroupID
      Name
      Description
      CreatedDateTime
      ModifiedDateTime
      CreatedBy
      ModifiedBy
    }
  }
`;

export const SINGLE_USER_GROUP_QUERY = gql`
  query SINGLE_USER_GROUP_QUERY($where: UserGroupsWhereUniqueInput!) {
    findUniqueUserGroups(where: $where) {
      GroupID
      Name
      Description
      CreatedDateTime
      ModifiedDateTime
      CreatedBy
      ModifiedBy
    }
  }
`;
