import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Field, Box, Control, Button, Label, Input, Column, Radio } from "rbx";

import {
  TestCategorySelect,
  ActionLimitTemplateSelect,
  SystemCodeSelect,
  SystemCodeMultiSelect,
} from "../../../../../components";

import StateSelect from "../../../../../components/StateSelect";
import { useAuth } from "../../../../../context";

const ActionLimitTemplateForm = ({
  onSubmit,
  formId,
  inputs,
  onChange,
  onEdit,
  handleTemplateClone,
  handleTemplateCreate,
  showForm,
  canUpdate,
}) => {
  const { state: authState } = useAuth();
  const handleChange = (e) => {
    onChange(e.target.name, e.target.value);
  };

  const handleRadioChange = (e) => {
    onChange(e.target.name, e.target.labels[0].innerText.trim());
  };

  const handleTemplateEdit = (e) => {
    e.preventDefault();
    if (inputs.TemplateID !== "") {
      onEdit();
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    handleTemplateCreate();
  };

  const handleClone = (e) => {
    e.preventDefault();
    if (inputs.TemplateID !== "") {
      handleTemplateClone();
    }
  };

  const canUpdateState = useMemo(
    () => authState?.user?.Lab?.Company === "All Location",
    [authState]
  );

  return (
    <form id={formId}>
      <Column.Group>
        <Column size={12}>
          <Column className="action-limit-select-column" size={12}>
            <Field kind="group">
              <Control expanded size="small">
                <ActionLimitTemplateSelect
                  required
                  label="Action Limit Templates"
                  name="TemplateID"
                  size="small"
                  value={inputs.TemplateID}
                  where={
                    authState?.user?.Lab?.Company !== "All Location"
                      ? {
                          TemplateState: {
                            equals: authState?.user?.Lab?.State,
                          },
                        }
                      : {}
                  }
                  onChange={(name, value) => onChange(name, value)}
                />
              </Control>

              <Control>
                <Label>&nbsp;</Label>
                <Button
                  color="primary"
                  size="small"
                  onClick={handleTemplateEdit}
                >
                  {canUpdate
                    ? "Edit Selected Template"
                    : "View Selected Template"}
                </Button>
              </Control>
              {canUpdate && (
                <React.Fragment>
                  <Control>
                    <Label>&nbsp;</Label>
                    <Button color="primary" size="small" onClick={handleCreate}>
                      New Template
                    </Button>
                  </Control>
                  <Control>
                    <Label>&nbsp;</Label>
                    <Button color="primary" size="small" onClick={handleClone}>
                      Clone Selected Template
                    </Button>
                  </Control>
                </React.Fragment>
              )}
            </Field>
          </Column>
          {showForm && (
            <Box>
              <React.Fragment>
                <Field kind="group">
                  <Control expanded size="small">
                    <Label>Template Name</Label>
                    <Input
                      required
                      disabled={!canUpdate}
                      maxLength={250}
                      name="TemplateName"
                      placeholder="Template Name"
                      size="small"
                      type="text"
                      value={inputs.TemplateName}
                      onChange={handleChange}
                    />
                  </Control>
                  <Control expanded size="small">
                    <StateSelect
                      disabled={!canUpdate || !canUpdateState}
                      id="State"
                      label="State Compliance"
                      name="TemplateState"
                      size="small"
                      value={inputs.TemplateState}
                      onChange={(name, value) => onChange(name, value)}
                    />
                  </Control>
                  <Control expanded size="small">
                    <SystemCodeSelect
                      required
                      disabled={!canUpdate}
                      label="Substance Type"
                      name="SubstanceType"
                      size="small"
                      value={inputs.SubstanceType}
                      where={{
                        Type: { equals: "Substance" },
                        Category: { equals: "Type" },
                        CodeName: { equals: "SubstanceType" },
                      }}
                      onChange={onChange}
                    />
                  </Control>
                  <Control>
                    <Label>Template Type</Label>
                    <Label>
                      <Radio
                        defaultChecked={inputs.TemplateType === "Compliance"}
                        disabled={!canUpdate}
                        name="TemplateType"
                        onChange={handleRadioChange}
                      />{" "}
                      Compliance
                    </Label>
                    <Label>
                      <Radio
                        defaultChecked={inputs.TemplateType === "Custom"}
                        disabled={!canUpdate}
                        name="TemplateType"
                        onChange={handleRadioChange}
                      />{" "}
                      Custom
                    </Label>
                  </Control>
                </Field>
                <Field kind="group">
                  <Control expanded size="small">
                    <TestCategorySelect
                      disabled={!canUpdate}
                      id="TestCategory"
                      label="Test Category"
                      name="TestCategory"
                      size="small"
                      value={inputs.TestCategory}
                      onChange={onChange}
                    />
                  </Control>
                  <Control expanded size="small">
                    <SystemCodeMultiSelect
                      disabled={!canUpdate}
                      id="ProductCategories"
                      label="Product Categories"
                      name="ProductCategories"
                      size="small"
                      value={inputs.ProductCategories}
                      where={{
                        Type: { equals: "Product" },
                        Category: { equals: "Category" },
                        Active: { equals: 1 },
                      }}
                      onChange={onChange}
                    />
                  </Control>
                  {inputs.TemplateState === "AZ" && (
                    <Control expanded size="small">
                      <SystemCodeSelect
                        required
                        disabled={!canUpdate}
                        label="Medical Type"
                        name="MedicalType"
                        size="small"
                        value={inputs.MedicalType}
                        where={{
                          Type: { equals: "Medical" },
                          Category: { equals: "Type" },
                          CodeName: { equals: "MedicalType" },
                        }}
                        onChange={onChange}
                      />
                    </Control>
                    // <Control expanded size="small">
                    //   <SystemCodeSelect
                    //     required
                    //     disabled={!canUpdate}
                    //     label="Machine Type"
                    //     name="MachineType"
                    //     size="small"
                    //     value={inputs.MachineType}
                    //     where={{
                    //       Type: { equals: "Machine" },
                    //       Category: { equals: "Type" },
                    //       CodeName: { equals: "MachineType" },
                    //     }}
                    //     onChange={onChange}
                    //   />
                    // </Control>
                  )}
                </Field>
              </React.Fragment>
            </Box>
          )}
        </Column>
      </Column.Group>
    </form>
  );
};

ActionLimitTemplateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formId: PropTypes.string.isRequired,
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  showForm: PropTypes.bool.isRequired,
  handleTemplateCreate: PropTypes.func.isRequired,
  handleTemplateClone: PropTypes.func.isRequired,
  canUpdate: PropTypes.bool,
};

ActionLimitTemplateForm.defaultProps = {
  canUpdate: true,
};

export default ActionLimitTemplateForm;
