import { gql } from "@apollo/client";

export const DELETE_VERIFY_HEMP_CATEGORIES = gql`
  mutation DELETE_VERIFY_HEMP_CATEGORIES($where: VerifyHempCategoriesWhereUniqueInput!) {
    deleteVerifyHempCategories(where: $where) {
      CategoryID
      UserID
    }
  }
`;

export const CREATE_VERIFY_HEMP_CATEGORIES = gql`
  mutation CREATE_VERIFY_HEMP_CATEGORIES($data: VerifyHempCategoriesCreateInput!) {
    createVerifyHempCategories(data: $data) {
      CategoryID
      UserID
      Name
      ImageURL
    }
  }
`;

export const UPDATE_VERIFY_HEMP_CATEGORIES = gql`
  mutation UPDATE_VERIFY_HEMP_CATEGORIES(
    $data: VerifyHempCategoriesUpdateInput!
    $where: VerifyHempCategoriesWhereUniqueInput!
  ) {
    updateVerifyHempCategories(data: $data, where: $where) {
      CategoryID
      UserID
      Name
      ImageURL
    }
  }
`;
