import { gql } from "@apollo/client";

export const FIND_FIRST_QC_SAMPLE_QUERY = gql`
  query FIND_FIRST_QC_SAMPLE_QUERY($where: QCSamplesWhereInput!) {
    findFirstQCSamples(where: $where) {
      QCSampleID
      BatchID
      SessionID
      Qualifiers
      Batches {
        Lab {
          State
        }
        TestCategory {
          Name
          Code
          TestCategoryID
        }
      }
    }
  }
`;

export const ALL_QC_SAMPLES_QUERY = gql`
  query ALL_QC_SAMPLES_QUERY($where: QCSamplesWhereInput) {
    findManyQCSamples(where: $where) {
      QCSampleID
      SessionID
      PrimarySampleSessionID
      ResultStatus {
        CodeDescription
      }
      Batches {
        Lab {
          State
        }
        LabID
        TestCategory {
          Name
          Code
          TestCategoryID
        }
      }
    }
  }
`;
