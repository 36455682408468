import React from "react";
import PropTypes from "prop-types";
import { Column, Field, Input, Label, Control } from "rbx";

import { StatusSelect, StateMultiSelect } from "../../../../../../components";

const SystemCodeForm = ({
  inputs,
  onChange,
  disabled,
  onSubmit,
  formId,
  adding,
}) => (
  <form id={formId} onSubmit={onSubmit}>
    <Column.Group multiline>
      <Column size={12}>
        <Field kind="group">
          <Control expanded size="small">
            <Label>Name</Label>
            <Input
              required
              disabled={disabled}
              maxLength={250}
              name="Name"
              placeholder="Name"
              size="small"
              type="text"
              value={inputs.Name}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field kind="group">
          <Control expanded size="small">
            <Label>States</Label>
            <StateMultiSelect
              required
              disabled={disabled}
              label="States"
              maxSelected={10}
              name="States"
              placeHolder="States"
              value={Array.isArray(inputs.States) ? inputs.States : inputs.States.split(",")}
              onChange={onChange}
            />
          </Control>
          <Control expanded size="small">
            <StatusSelect
              required
              id="Active"
              label="Active"
              name="Active"
              size="small"
              value={inputs.Active ? 1 : 0}
              onChange={(name, value) => {
                onChange(name, value === 1)
              }}
            />
          </Control>
        </Field>
      </Column>
    </Column.Group>
  </form>
);

SystemCodeForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  formId: PropTypes.string.isRequired,
  adding: PropTypes.bool,
};

SystemCodeForm.defaultProps = {
  onSubmit: () => null,
  disabled: false,
  adding: false,
};

export default SystemCodeForm;
