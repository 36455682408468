/* eslint-disable react/destructuring-assignment */
import React from "react";
import { Generic, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableCell } from "../../../../components/DataTable/TableCell";

const DEFAULT_COLUMNS = [
  {
    Header: "Test Name",
    id: "Test:Name",
    accessor: "Test.Name",
  },
  {
    Header: "Plants",
    id: "Plants",
    accessor: "Plants",
    Cell: (obj) => (
      <TableCell.Currency value={JSON.stringify(obj.cell.value)} />
    ),
  },
  {
    Header: "Extracts",
    id: "Extracts",
    accessor: "Extracts",
    Cell: (obj) => (
      <TableCell.Currency value={JSON.stringify(obj.cell.value)} />
    ),
  },
  {
    Header: "Ingestibles",
    id: "Ingestibles",
    accessor: "Ingestibles",
    Cell: (obj) => (
      <TableCell.Currency value={JSON.stringify(obj.cell.value)} />
    ),
  },
  {
    Header: "Topicals",
    id: "Topicals",
    accessor: "Topicals",
    Cell: (obj) => (
      <TableCell.Currency value={JSON.stringify(obj.cell.value)} />
    ),
  },
  {
    Header: "AnimalProducts",
    id: "AnimalProducts",
    accessor: "AnimalProducts",
    Cell: (obj) => (
      <TableCell.Currency value={JSON.stringify(obj.cell.value)} />
    ),
  },
  {
    Header: "Other",
    id: "Other",
    accessor: "Other",
    Cell: (obj) => (
      <TableCell.Currency value={JSON.stringify(obj.cell.value)} />
    ),
  },
];

export const generateColumns = (handleDelete) => [
  ...DEFAULT_COLUMNS,
  {
    Header: "",
    id: "delete",
    Cell: (cell) => (
      <Generic
        style={{ textAlign: "center" }}
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(cell.row.original);
        }}
      >
        <Icon color="danger">
          <FontAwesomeIcon icon="times" />
        </Icon>
      </Generic>
    ),
  },
];

export default DEFAULT_COLUMNS;
