/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Button } from "rbx";
import { useParams, useHistory } from "react-router-dom";
import { useMutation, useApolloClient } from "@apollo/client";
import PropTypes from "prop-types";

import PerUnitCalculatorForm from "../components/PerUnitCalculatorForm";
import { Loader, PageHeader } from "../../../../../components";
import { customToast as toast } from "../../../../../utils";
import {
  SINGLE_JOB_ORDER_QUERY,
  UPDATE_JOB_ORDER_MUTATION,
} from "../../../../../graphql";

const INITIAL_STATE = {
  StructuralRigidity: "Solid",
  Weight: "",
  Units: "",
  DisplayName: "",
  Density: "",
};

const INITIAL_JOB_ORDER_STATE = {
  ProductCategory: "",
  SubstanceType: "",
};

const PerUnitCalculatorEditPage = ({ routePermissions }) => {
  const history = useHistory();
  const { JobOrderID } = useParams();

  const client = useApolloClient();

  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [jobOrderInfo, setJobOrderInfo] = useState(INITIAL_JOB_ORDER_STATE);
  const [canUpdate, setCanUpdate] = useState(true);

  const [updateJobOrders] = useMutation(UPDATE_JOB_ORDER_MUTATION);

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  useEffect(async () => {
    const { data: jobOrderData } = await client.query({
      query: SINGLE_JOB_ORDER_QUERY,
      variables: {
        where: {
          JobOrderID,
        },
      },
      fetchPolicy: "network-only",
    });
    if (jobOrderData?.findUniqueJobOrders) {
      const { OverrideData, ProductCategory, SubstanceType } =
        jobOrderData.findUniqueJobOrders;

      setJobOrderInfo({
        ProductCategory: OverrideData?.ProductCategory || ProductCategory,
        SubstanceType: OverrideData?.SubstanceType || SubstanceType,
      });
      if (
        jobOrderData.findUniqueJobOrders.DisplayUnit ||
        jobOrderData.findUniqueJobOrders.OverrideData
      ) {
        setInputs((prevState) => {
          const keys = Object.keys(prevState);
          const newState = { ...jobOrderData.findUniqueJobOrders.OverrideData };
          keys.forEach((key) => {
            newState[key] =
              jobOrderData.findUniqueJobOrders.OverrideData?.[key] ||
              jobOrderData.findUniqueJobOrders.DisplayUnit?.[key] ||
              prevState[key];
          });
          return newState;
        });
      }
    } else {
      toast.error("Job Order ID does not exist.");
    }
  }, []);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await updateJobOrders({
        variables: {
          where: {
            JobOrderID: parseInt(JobOrderID, 10),
          },
          data: {
            OverrideData: inputs,
          },
        },
        refetchQueries: [
          {
            query: SINGLE_JOB_ORDER_QUERY,
            variables: {
              where: {
                JobOrderID,
              },
            },
            fetchPolicy: "network-only",
          },
        ],
      });
      toast.success("Display Unit updated successfully.");
      onComplete();
    } catch (err) {
      toast.error("Error Updating Display Unit.");
    }
    setLoading(false);
  };

  const onComplete = () => {
    history.push("/lims/per-unit-calculator");
  };

  if (loading) return <Loader />;

  return (
    <div className="per-unit-calculator-edit-page">
      <PageHeader title="Per Unit Calculator">
        <Button
          color="primary"
          disabled={!canUpdate}
          form="per-unit-calculator-form"
          type="submit"
          onClick={handleSave}
        >
          Save
        </Button>
        <Button rounded color="primary" type="button" onClick={onComplete}>
          Cancel
        </Button>
      </PageHeader>
      <PerUnitCalculatorForm
        JobOrderID={JobOrderID}
        disabled={!canUpdate}
        formId="per-unit-calculator-form"
        inputs={inputs}
        jobOrderInfo={jobOrderInfo}
        onChange={handleChange}
        onSubmit={handleSave}
      />
    </div>
  );
};

PerUnitCalculatorEditPage.propTypes = {
  routePermissions: PropTypes.array,
};

PerUnitCalculatorEditPage.defaultProps = {
  routePermissions: [],
};

export default PerUnitCalculatorEditPage;
