const systemCodes = {
  name: "systemCodes",
  label: "System Codes",
  icon: "users",
  permissions: ["CODES"],
  active: (location) => {
    const [, p] = location.pathname.split("/");
    return /system-codes/.test(p);
  },
  to: "/system-codes",
};

export default systemCodes;
