import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Field, Control, Label, Input, Title, Select, Tag, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@apollo/client";
import { useAuth } from "../../../../../../context";
import { FIRST_SYSTEM_CODE_QUERY } from "../../../../../../graphql";
import { STATUS_COLORS } from "../../../../../../constants";
import {
  BooleanInput,
  IconButton,
  EquipmentSelect,
} from "../../../../../../components";

const FormSectionGroup = ({
  disabled,
  sample,
  handleChangeResult,
  handleChangeStatus,
  handleChangeRejectNote,
  testType,
}) => {
  const [canRestoreSamples, setCanRestoreSamples] = useState(false);
  const { state: authState } = useAuth();
  const { data: approvedSystemCodeData } = useQuery(FIRST_SYSTEM_CODE_QUERY, {
    variables: {
      where: {
        Category: { equals: "JobOrderSample" },
        CodeName: { equals: "ResultStatus" },
        CodeId: { equals: "A" },
      },
    },
  });
  const { data: rejectedSystemCodeData } = useQuery(FIRST_SYSTEM_CODE_QUERY, {
    variables: {
      where: {
        Category: { equals: "JobOrderSample" },
        CodeName: { equals: "ResultStatus" },
        CodeId: { equals: "R" },
      },
    },
  });
  const { data: restoredSystemCodeData } = useQuery(FIRST_SYSTEM_CODE_QUERY, {
    variables: {
      where: {
        Category: { equals: "JobOrderSample" },
        CodeName: { equals: "ResultStatus" },
        CodeId: { equals: "RS" },
      },
    },
  });

  const {
    JobOrderTestSampleID,
    SessionID,
    Result,
    Status,
    ResultStatus,
    OriginalStatus,
    RejectNote,
  } = sample;

  const disableInputs =
    disabled ||
    OriginalStatus === approvedSystemCodeData?.findFirstSystemCodes.RecId ||
    OriginalStatus === rejectedSystemCodeData?.findFirstSystemCodes.RecId;

  useEffect(() => {
    if (Array.isArray(authState.permissions) && authState.permissions.length) {
      const foundRest = authState.permissions.find(
        (permission) => permission.Code === "REST_SAMPLES"
      );
      if (foundRest && foundRest.Update) {
        setCanRestoreSamples(true);
      } else {
        setCanRestoreSamples(false);
      }
    } else {
      setCanRestoreSamples(false);
    }
  }, [authState]);

  return (
    <React.Fragment key={JobOrderTestSampleID}>
      <Title subtitle>
        {Result?.Result === "Fail" && (
          <Icon
            className="has-tooltip-right has-tooltip-arrow has-tooltip-danger"
            color="danger"
            data-tooltip="Includes Failed Result"
            size="small"
          >
            <FontAwesomeIcon icon="exclamation" />
          </Icon>
        )}
        {SessionID}{" "}
        {ResultStatus?.CodeDescription && (
          <Tag
            color={STATUS_COLORS.ResultStatus[ResultStatus?.CodeDescription]}
            size="small"
          >
            {ResultStatus?.CodeDescription}
          </Tag>
        )}
      </Title>
      <Field key={JobOrderTestSampleID} multiline kind="group">
        <Control expanded size="small">
          <Label>Weight</Label>
          <Input
            autoComplete="off"
            disabled={disableInputs}
            maxLength={250}
            name="Weight"
            placeholder="Weight"
            required={testType !== "Filth" && Result?.Percent}
            size="small"
            type="number"
            value={Result?.Weight || ""}
            onChange={(e) =>
              handleChangeResult(
                testType,
                JobOrderTestSampleID,
                e.target.name,
                e.target.value
              )
            }
          />
        </Control>
        {testType !== "Filth" && (
          <Control expanded size="small">
            <Label>
              {testType === "Water" ? "Water Activity (Aw)" : `${testType} %`}
            </Label>
            <Input
              autoComplete="off"
              disabled={disableInputs}
              maxLength={250}
              name="Percent"
              placeholder={`${testType} %`}
              required={testType !== "Filth" && Result?.Weight}
              size="small"
              type="number"
              value={Result?.Percent || ""}
              onChange={(e) =>
                handleChangeResult(
                  testType,
                  JobOrderTestSampleID,
                  e.target.name,
                  e.target.value
                )
              }
            />
          </Control>
        )}
        <EquipmentSelect
          fullWidth
          returnFullValue
          disabled={disableInputs}
          label="Balance EID#"
          name="EquipmentID"
          size="small"
          value={Result?.EquipmentID || ""}
          where={{ SamplePrep: { equals: true } }}
          onChange={(name, value) =>
            handleChangeResult(
              testType,
              JobOrderTestSampleID,
              name,
              value?.EquipmentID
            )
          }
        />
        {testType === "Water" && (
          <EquipmentSelect
            fullWidth
            returnFullValue
            disabled={disableInputs}
            label="Balance EID#"
            name="Equipment2ID"
            size="small"
            value={Result?.Equipment2ID || ""}
            where={{ SamplePrep: { equals: true } }}
            onChange={(name, value) =>
              handleChangeResult(
                testType,
                JobOrderTestSampleID,
                name,
                value?.EquipmentID
              )
            }
          />
        )}
        <Control expanded size="small">
          <Label>Result</Label>
          <Select.Container fullwidth size="small">
            <Select
              disabled={
                disableInputs || testType !== "Filth" || !Result?.Weight
              }
              required={
                (testType === "Filth" && Result?.Weight) ||
                (testType !== "Filth" && Result?.Percent)
              }
              style={{ width: "100%" }}
              value={Result?.Result}
              onChange={(e) => {
                handleChangeResult(
                  testType,
                  JobOrderTestSampleID,
                  "Result",
                  e.target.value
                );
                if (testType === "Filth") {
                  handleChangeStatus(
                    testType,
                    JobOrderTestSampleID,
                    approvedSystemCodeData?.findFirstSystemCodes.RecId,
                    true
                  );
                }
              }}
            >
              <Select.Option value="" />
              <Select.Option value="Pass">Pass</Select.Option>
              <Select.Option value="Fail">Fail</Select.Option>
            </Select>
          </Select.Container>
        </Control>
        <Control
          style={{
            width: "100px",
            textAlign: "center",
          }}
        >
          {disableInputs ? (
            <React.Fragment>
              <Label>Restore</Label>
              <IconButton
                color={
                  Status === restoredSystemCodeData?.findFirstSystemCodes.RecId
                    ? "warning"
                    : ""
                }
                disabled={!canRestoreSamples}
                icon="redo"
                isSelected={
                  Status === restoredSystemCodeData?.findFirstSystemCodes.RecId
                }
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  handleChangeStatus(
                    testType,
                    JobOrderTestSampleID,
                    restoredSystemCodeData?.findFirstSystemCodes.RecId,
                    Status !==
                      restoredSystemCodeData?.findFirstSystemCodes.RecId
                  );
                }}
              />
            </React.Fragment>
          ) : (
            <Field kind="group">
              <Control size="small">
                <Label>Approve</Label>
                <BooleanInput
                  color="cyan"
                  disabled={
                    !Result?.Weight ||
                    (!Result?.Percent && testType !== "Filth") ||
                    !Result?.Result
                  }
                  name="approve"
                  value={
                    Status ===
                    approvedSystemCodeData?.findFirstSystemCodes.RecId
                  }
                  onChange={(name, value) =>
                    handleChangeStatus(
                      testType,
                      JobOrderTestSampleID,
                      approvedSystemCodeData?.findFirstSystemCodes.RecId,
                      Status !==
                        approvedSystemCodeData?.findFirstSystemCodes.RecId
                    )
                  }
                />
              </Control>
              <Control size="small">
                <Label>Reject</Label>
                <BooleanInput
                  color="error"
                  disabled={
                    !Result?.Weight ||
                    (!Result?.Percent && testType !== "Filth") ||
                    !Result?.Result
                  }
                  name="reject"
                  value={
                    Status ===
                    rejectedSystemCodeData?.findFirstSystemCodes.RecId
                  }
                  onChange={(name, value) =>
                    handleChangeStatus(
                      testType,
                      JobOrderTestSampleID,
                      rejectedSystemCodeData?.findFirstSystemCodes.RecId,
                      Status !==
                        rejectedSystemCodeData?.findFirstSystemCodes.RecId
                    )
                  }
                />
              </Control>
            </Field>
          )}
        </Control>
      </Field>
      <Field>
        {Status === rejectedSystemCodeData?.findFirstSystemCodes.RecId &&
          OriginalStatus !==
            rejectedSystemCodeData?.findFirstSystemCodes.RecId && (
            <Control expanded size="small">
              <Label>Reason For Rejection</Label>
              <Input
                required
                maxLength={250}
                name="RejectNote"
                placeholder="Reason For Rejection"
                size="small"
                value={RejectNote || ""}
                onChange={(e) =>
                  handleChangeRejectNote(
                    testType,
                    JobOrderTestSampleID,
                    e.target.value
                  )
                }
              />
            </Control>
          )}
      </Field>
    </React.Fragment>
  );
};

FormSectionGroup.propTypes = {
  disabled: PropTypes.bool,
  sample: PropTypes.object.isRequired,
  handleChangeStatus: PropTypes.func,
  handleChangeResult: PropTypes.func,
  handleChangeRejectNote: PropTypes.func,
  testType: PropTypes.string.isRequired,
};

FormSectionGroup.defaultProps = {
  disabled: false,
  handleChangeStatus: () => null,
  handleChangeResult: () => null,
  handleChangeRejectNote: () => null,
};
export default FormSectionGroup;
