/* eslint-disable react/prop-types */
import { format } from "date-fns";

// eslint-disable-next-line import/prefer-default-export
export const COLUMNS = [
  {
    Header: "Date",
    id: "CreatedDateTime",
    accessor: "CreatedDateTime",
    maxWidth: "10%",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM-dd-yyyy HH:mm:ss") : "",
  },
  {
    Header: "User",
    id: "CreatedBy",
    accessor: "CreatedBy",
    maxWidth: "10%",
  },
  {
    Header: "Change",
    id: "Change",
    accessor: "Change",
    maxWidth: "40%",
    Cell: ({ cell }) => <p style={{ whiteSpace: "normal" }}>{cell.value}</p>
  },
  {
    Header: "Reason",
    id: "ReasonForChange",
    accessor: "ReasonForChange",
    maxWidth: "25%",
  },
];
