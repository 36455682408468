import { gql } from "@apollo/client";

export const DELETE_SAMPLE_TRACKING_MUTATION = gql`
  mutation DELETE_SAMPLE_TRACKING_MUTATION(
    $where: SampleTrackingWhereUniqueInput!
  ) {
    deleteSampleTracking(where: $where) {
      SampleTrackingID
    }
  }
`;

export const CREATE_SAMPLE_TRACKING_MUTATION = gql`
  mutation CREATE_SAMPLE_TRACKING_MUTATION($data: SampleTrackingCreateInput!) {
    createSampleTracking(data: $data) {
      SampleTrackingID
    }
  }
`;

export const UPDATE_SAMPLE_TRACKING_MUTATION = gql`
  mutation UPDATE_SAMPLE_TRACKING_MUTATION(
    $data: SampleTrackingUpdateInput!
    $where: SampleTrackingWhereUniqueInput!
  ) {
    updateSampleTracking(data: $data, where: $where) {
      SampleTrackingID
    }
  }
`;
