import { gql } from "@apollo/client";

export const ALL_ACTION_LIMIT_TEMPLATE_ANALYTES_QUERY = gql`
  query ALL_ACTION_LIMIT_TEMPLATE_ANALYTES_QUERY(
    $where: ActionLimitTemplateAnalytesWhereInput
    $orderBy: [ActionLimitTemplateAnalytesOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyActionLimitTemplateAnalytes(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      ActionLimitTemplateAnalyteID
      TemplateID
      AnalyteID
      ActionLevel
      LOQ
      LOD
      InSampleLOQ
      InSampleLOD
      AllowableCriteria
      UpperLimit
      Analyte {
        AnalyteID
        Name
        Active
        TestCategory {
          TestCategoryID
          Name
        }
      }
    }
    aggregateActionLimitTemplateAnalytes(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const SINGLE_ACTION_LIMIT_TEMPLATE_ANALYTE_QUERY = gql`
  query SINGLE_ACTION_LIMIT_TEMPLATE_ANALYTE_QUERY(
    $where: ActionLimitTemplateAnalytesWhereUniqueInput!
  ) {
    findUniqueActionLimitTemplateAnalytes(where: $where) {
      ActionLimitTemplateAnalyteID
      TemplateID
      AnalyteID
      ActionLevel
      LOQ
      LOD
      InSampleLOQ
      InSampleLOD
      AllowableCriteria
      UpperLimit
      Analyte {
        AnalyteID
        Name
        Active
        TestCategory {
          TestCategoryID
          Name
        }
      }
    }
  }
`;
