import React, { useEffect, useState } from "react";
import { Button, Tab } from "rbx";
import { useLazyQuery, useApolloClient, useMutation } from "@apollo/client";
import { Link, useLocation, Switch } from "react-router-dom";

import { useAuth } from "../../../context/AuthContext";
import {
  SINGLE_USER_QUERY,
  UPDATE_USER_MUTATION,
  UPDATE_PASSWORD_MUTATION,
  IS_USER_PASSWORD_VALID,
} from "../../../graphql";
import { AccountDetails, ProfilePicture, UpdatePassword } from "../routes";
import { PageHeader } from "../../../components";
import { usePublicIP } from "../../../hooks";

import { customToast as toast } from "../../../utils";

const INITIAL_STATE = {
  UserID: "",
  Username: "",
  FirstName: "",
  LastName: "",
  Email: "",
  Status: "",
  Address: "",
  Address2: "",
  Phone: "",
  City: "",
  State: "",
  Zip: "",
  ProfilePic: "",
  isPrivateLabel: "",
  Terms: "",
  TimeZone: "",
  Company: "",
  ProcessingOption: "",
  ReferrerID: "",
  Commission: "",
  Referrer2ID: "",
  Commission2: "",
  BillingAddress: {
    BillingAddress: "",
    BillingAddress2: "",
    BillingCity: "",
    BillingCompany: "",
    BillingFirstName: "",
    BillingLastName: "",
    BillingState: "",
    BillingZip: "",
  },
  ShippingAddress: {
    ShippingAddress: "",
    ShippingAddress2: "",
    ShippingCity: "",
    ShippingCompany: "",
    ShippingFirstName: "",
    ShippingLastName: "",
    ShippingState: "",
    ShippingZip: "",
  },
  COAAddress: {
    COAAddress: "",
    COAAddress2: "",
    COACity: "",
    COAEmail: "",
    COALogo: "",
    COAName: "",
    COAPhone: "",
    COAState: "",
    COAZip: "",
  },
  COASettings: {
    COA_SOP: "0",
    ShowSummary: "0",
  },
  Notes: {
    ClientNotes: "",
    TestNotes: "",
  },
  TestSettings: {
    CANN: "",
    TOX: "",
    MICRO: "",
    METAL: "",
    MOIST: "",
    WATER: "",
    PEST: "",
    SOLV: "",
    TERP: "",
    _HideGraphs: "",
  },
};

const EditProfilePage = () => {
  const location = useLocation();
  const { state: authState } = useAuth();
  const {
    user: { UserID },
  } = authState;

  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [oldPassword, setOldPassword] = useState("");

  const [getClientData, resultClientData] = useLazyQuery(SINGLE_USER_QUERY);
  const [updateClient] = useMutation(UPDATE_USER_MUTATION);
  const [updatePassword] = useMutation(UPDATE_PASSWORD_MUTATION);

  const client = useApolloClient();
  const IP = usePublicIP();

  useEffect(() => {
    if (UserID) {
      getClientData({
        variables: {
          where: { UserID: parseInt(UserID, 10) },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [UserID, getClientData]);

  useEffect(() => {
    if (resultClientData?.data) {
      const {
        data: { findUniqueUsers },
      } = resultClientData;

      const cleanObject = Object.fromEntries(
        Object.entries(findUniqueUsers).filter(([_, v]) => v !== null)
      );

      setInputs((prev) => ({
        ...prev,
        ...cleanObject,
      }));
    }
  }, [resultClientData]);

  const handleChange = async (name, value, entity = null) => {
    if (entity) {
      setInputs((prev) => ({
        ...prev,
        [entity]: {
          ...prev[entity],
          [name]: value,
        },
      }));
    } else {
      setInputs((prev) => ({
        ...prev,
        [name]: value,
      }));
      if (name === "ProfilePic") {
        await updateClient({
          variables: {
            data: {
              ProfilePic: { set: value },
            },
            where: {
              UserID: parseInt(UserID, 10),
            },
          },
          refetchQueries: [
            {
              query: SINGLE_USER_QUERY,
              variables: {
                where: { UserID: parseInt(UserID, 10) },
              },
              fetchPolicy: "network-only",
            },
          ],
        });
      }
    }
  };

  const handlePasswordUpdate = async () => {
    const { Username, Password, ConfirmPassword } = inputs;
    try {
      const {
        data: { isUserPasswordValid },
      } = await client.query({
        query: IS_USER_PASSWORD_VALID,
        fetchPolicy: "network-only",
        variables: {
          input: {
            Username,
            Password: oldPassword,
            IP,
            UserAgent: window.navigator.userAgent,
          },
        },
      });

      if (!isUserPasswordValid.isValid) {
        throw new Error();
      }

      await updatePassword({
        variables: {
          data: {
            Password,
            ConfirmPassword,
          },
          where: {
            UserID: parseInt(UserID, 10),
          },
        },
      });
      return true;
    } catch (err) {
      return false;
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      if (inputs.Password && inputs.ConfirmPassword) {
        const wasPasswordChanged = await handlePasswordUpdate();
        if (!wasPasswordChanged) {
          throw new Error();
        }
      }

      const obj = Object.keys(INITIAL_STATE).reduce((acc, curr) => {
        if (
          inputs[curr] !== null &&
          curr !== "BillingAddress" &&
          curr !== "ShippingAddress" &&
          curr !== "COAAddress" &&
          curr !== "COASettings"
        ) {
          return {
            ...acc,
            [curr]: {
              set: inputs[curr],
            },
          };
        }
        return acc;
      }, {});
      if (obj.Terms?.set) {
        obj.Terms = { set: parseInt(obj.Terms.set, 10) };
      } else {
        delete obj.Terms;
      }
      if (obj.ProcessingOption?.set) {
        obj.ProcessingOption = { set: parseInt(obj.ProcessingOption.set, 10) };
      } else {
        delete obj.ProcessingOption;
      }

      await updateClient({
        variables: {
          data: {
            ...obj,
            BillingAddress: inputs.BillingAddress,
            ShippingAddress: inputs.ShippingAddress,
            COAAddress: inputs.COAAddress,
            COASettings: inputs.COASettings,
            Notes: inputs.Notes,
            TestSettings: inputs.TestSettings,
          },
          where: {
            UserID: parseInt(UserID, 10),
          },
        },
        refetchQueries: [
          {
            query: SINGLE_USER_QUERY,
            variables: {
              where: { UserID: parseInt(UserID, 10) },
            },
            fetchPolicy: "network-only",
          },
        ],
      });
      toast.success("Updated successfully");
    } catch (err) {
      toast.error("Error updating Client");
    }
  };

  return (
    <div className="client-page">
      <PageHeader title="Edit Profile">
        <Button
          color="primary"
          form="edit-client-form"
          type="submit"
          onClick={handleSave}
        >
          Save
        </Button>
      </PageHeader>
      <Tab.Group kind="boxed">
        <Tab
          active={location.pathname.includes("account-details")}
          as={Link}
          to="/profile/account-details"
        >
          Account Details
        </Tab>
        <Tab
          active={location.pathname.includes("profile-picture")}
          as={Link}
          to="/profile/profile-picture"
        >
          Profile Picture
        </Tab>
        <Tab
          active={location.pathname.includes("update-password")}
          as={Link}
          to="/profile/update-password"
        >
          Update Password
        </Tab>
        {/* <Tab
          active={location.pathname.includes("shipping-address")}
          as={Link}
          to="/profile/shipping-address"
        >
          Shipping Address
        </Tab>
        <Tab
          active={location.pathname.includes("billing-address")}
          as={Link}
          to="/profile/billing-address"
        >
          Billing Address
        </Tab> */}
      </Tab.Group>
      <Switch>
        <Switch path="/profile/account-details">
          <AccountDetails
            formId="edit-profile-form"
            inputs={inputs}
            onChange={handleChange}
            onSubmit={handleSave}
          />
        </Switch>
        <Switch path="/profile/profile-picture">
          <ProfilePicture
            formId="edit-profile-form"
            inputs={inputs}
            userID={UserID}
            onChange={handleChange}
            onSubmit={handleSave}
          />
        </Switch>
        <Switch path="/profile/update-password">
          <UpdatePassword
            formId="edit-profile-form"
            inputs={inputs}
            oldPassword={oldPassword}
            setOldPassword={setOldPassword}
            onChange={handleChange}
            onSubmit={handleSave}
          />
        </Switch>
        {/* <Switch path="/profile/shipping-address">
          <ShippingAddress
            formId="edit-profile-form"
            inputs={inputs}
            onChange={handleChange}
            onSubmit={handleSave}
          />
        </Switch>
        <Switch path="/profile/billing-address">
          <BillingAddress
            formId="edit-profile-form"
            inputs={inputs}
            onChange={handleChange}
            onSubmit={handleSave}
          />
        </Switch> */}
      </Switch>
    </div>
  );
};

EditProfilePage.propTypes = {};

export default EditProfilePage;
