import React, { useMemo, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useParams, useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { ALL_COUPONS_QUERY, DELETE_COUPON_MUTATION } from "../../../graphql";
import { generateColumns } from "./columns";
import {
  PageHeader,
  DataTable,
  IconButton,
  Confirmation,
  Loader,
} from "../../../components";
import { useModal, useAuth } from "../../../context";
import { customToast as toast } from "../../../utils";
import CouponModal from "../components/CouponModal";

const CouponListPage = ({ routePermissions }) => {
  const { CouponID } = useParams();
  const history = useHistory();
  const { state: authState } = useAuth();
  const { setModalOpen } = useModal();

  const [modalOpened, setModalOpened] = useState(false);
  const [canUpdate, setCanUpdate] = useState(true);

  const [deleteCoupon] = useMutation(DELETE_COUPON_MUTATION);
  const [where, setWhere] = useState({});
  const [loading, setLoading] = useState(true);

  const orderBy = [{ id: "Code", desc: false }];

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  useEffect(() => {
    if (CouponID && !modalOpened && canUpdate) {
      setModalOpened(true);
      setModalOpen(
        true,
        <CouponModal
          CouponID={CouponID}
          canUpdate={canUpdate}
          onComplete={() => {
            history.push("/coupons");
            setModalOpen(false);
          }}
        />
      );
    }
  }, [CouponID, history, modalOpened, setModalOpen, canUpdate]);

  useEffect(() => {
    if (
      authState?.user?.LabID &&
      authState?.user?.Lab?.Company !== "All Location"
    ) {
      setWhere((prev) => ({
        ...prev,
        Lab: {
          is: {
            LabID: {
              equals: parseInt(authState.user.LabID, 10),
            },
          },
        },
      }));
      setLoading(false);
    } else if (authState?.user?.Lab?.Company === "All Location") {
      setLoading(false);
    }
  }, [authState]);

  const COLUMNS = useMemo(() => {
    const handleDelete = (coupon) => {
      const performDelete = async () => {
        try {
          setModalOpen(false, "");
          await deleteCoupon({
            variables: {
              where: {
                CouponID: parseInt(coupon.CouponID, 10),
              },
            },
            refetchQueries: [
              {
                query: ALL_COUPONS_QUERY,
                variables: {
                  where: {},
                },
              },
            ],
          });
          toast.success("Coupon deleted successfully.");
        } catch (err) {
          toast.error(`Error deleting Coupon.`);
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this Coupon?"
          onCancel={() => setModalOpen(false, "")}
          onConfirm={performDelete}
        />
      );
    };
    return generateColumns(handleDelete, canUpdate);
  }, [deleteCoupon, setModalOpen, canUpdate]);

  const handleRowClick = (couponItem) => {
    if (canUpdate) {
      history.push(`/coupons/${couponItem.CouponID}`);
      setModalOpened(false);
    }
  };

  const handleCreateCoupon = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push("/coupons");
    setModalOpen(
      true,
      <CouponModal
        canUpdate={canUpdate}
        onComplete={() => {
          history.push("/coupons");
          setModalOpen(false);
        }}
      />
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="coupon-list-page">
      <PageHeader title="Coupons">
        {canUpdate && <IconButton icon="plus" onClick={handleCreateCoupon} />}
      </PageHeader>
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateCoupons"
        columns={COLUMNS}
        name="findManyCoupons"
        orderBy={orderBy}
        query={ALL_COUPONS_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

CouponListPage.propTypes = {
  routePermissions: PropTypes.array,
};

CouponListPage.defaultProps = {
  routePermissions: [],
};

export default CouponListPage;
