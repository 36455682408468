/* eslint-disable max-lines-per-function */
import FindQCSampleResults from "./FindQCSampleResults";
import {
  CalculateAnalyteResult,
  CalculatePercentRecovery,
  CalculateMatrixPercentRecovery,
  CalculateRelativePercentDifference,
  FindAnalyteRawResult,
} from "../../../../../../../utils/batchCalculations";
import { summedAnalytes } from "../../../../../../../utils/batchConstants";

const assignSystemQualifiers = (batchResults, allQualifiers, labState) => {
  const {
    lcsSamples,
    lcsDupeSamples,
    msSample,
    msPrimarySample,
    sampleDupeSample,
    sampleDupePrimarySample,
    blankSamples,
    methodBlankSamples,
    ccvSamples,
  } = FindQCSampleResults(batchResults);

  const testCategoryAnalytes = batchResults.find(
    (item) => item?.TestCategory?.Analytes?.length
  )?.TestCategory?.Analytes;

  const testCategoryCode = batchResults.find((item) => item?.TestCategory?.Code)
    ?.TestCategory?.Code;

  return batchResults
    .filter((sample) => sample.JobOrderTestSample)
    .map((sample) => {
      const sampleQualifiers = {};
      const summedAnalytesToAdd = [];
      const actionLimitTemplateAnalytes =
        sample?.ActionLimitTemplate?.ActionLimitTemplateAnalytes;

      testCategoryAnalytes
        .filter((analyte) => analyte.Active)
        .forEach((analyte) => {
          const actionLimitTemplateAnalyte = actionLimitTemplateAnalytes?.find(
            (a) => a.AnalyteID.toString() === analyte.AnalyteID
          );
          const analyteName = analyte.Name.toLowerCase().trim();

          const summedAnalyte = summedAnalytes.find(
            (obj) =>
              obj.testCategoryCode === testCategoryCode &&
              (!obj.state || obj.state === labState) &&
              obj.sumAnalyteName === analyteName
          );

          if (actionLimitTemplateAnalyte && !summedAnalyte) {
            // find and calculate all needed values for this analyte
            const analyteQualifiers = [];
            const actionLevel = parseFloat(
              actionLimitTemplateAnalyte?.ActionLevel
            );
            const inSampleLOQ = parseFloat(
              actionLimitTemplateAnalyte?.InSampleLOQ
            );

            let blankRawValueAboveLoq = false;
            let methodBlankRawValueAboveLoq = false;
            let relPercDiffernceLcsLcsDupeAbove20 = false;

            const findCalculatedResult = (selectedSample) =>
              parseFloat(
                CalculateAnalyteResult(
                  selectedSample,
                  testCategoryCode,
                  analyteName,
                  labState
                )
              );

            const findRawResult = (selectedSample) =>
              parseFloat(
                FindAnalyteRawResult(
                  selectedSample,
                  testCategoryCode,
                  analyteName,
                  labState
                )
              );

            const findTargetValue = (selectedSample) =>
              parseFloat(selectedSample?.TargetValues?.[analyteName]) ||
              parseFloat(
                selectedSample?.QCTarget?.TargetValues?.[analyteName]
              ) ||
              null;

            const findInSampleLoqValue = (selectedSample) =>
              parseFloat(
                selectedSample?.ActionLimitTemplate?.ActionLimitTemplateAnalytes?.find(
                  (a) => a.AnalyteID.toString() === analyte.AnalyteID
                )?.InSampleLOQ
              );

            const findInSampleLodValue = (selectedSample) =>
              parseFloat(
                selectedSample?.ActionLimitTemplate?.ActionLimitTemplateAnalytes?.find(
                  (a) => a.AnalyteID.toString() === analyte.AnalyteID
                )?.InSampleLOD
              );

            // for this sample
            const sampleCalculatedValue = findCalculatedResult(sample);

            const sampleCalculatedvalueBelowLOQ = !!(
              (sampleCalculatedValue || sampleCalculatedValue === 0) &&
              sampleCalculatedValue < inSampleLOQ
            );
            const sampleCalculatedValueBelowActionLevel = !!(
              (sampleCalculatedValue || sampleCalculatedValue === 0) &&
              sampleCalculatedValue < actionLevel
            );

            // blank QCSamples
            blankSamples.forEach((blankSample) => {
              const rawResult = findRawResult(blankSample);
              const loq = parseFloat(
                blankSample?.ActionLimitTemplate?.ActionLimitTemplateAnalytes?.find(
                  (a) => a.AnalyteID.toString() === analyte.AnalyteID
                )?.LOQ
              );
              if (rawResult || rawResult === 0) {
                if (rawResult >= loq) {
                  blankRawValueAboveLoq = true;
                }
              }
            });

            // method blank QCSamples
            methodBlankSamples.forEach((methodBlankSample) => {
              const rawResult = findRawResult(methodBlankSample);
              const loq = parseFloat(
                methodBlankSample?.ActionLimitTemplate?.ActionLimitTemplateAnalytes?.find(
                  (a) => a.AnalyteID.toString() === analyte.AnalyteID
                )?.LOQ
              );
              if (rawResult || rawResult === 0) {
                if (rawResult >= loq) {
                  methodBlankRawValueAboveLoq = true;
                }
              }
            });

            // matrix spike QCSample
            const matrixSpikeQCValues = {
              result: findRawResult(msSample),
              target: findTargetValue(msSample),
            };

            const sampleToCompareMS = msPrimarySample || sampleDupeSample;
            const rawResultToCompareMS = findRawResult(sampleToCompareMS);

            const matrixSpikePercRecovery =
              (matrixSpikeQCValues.result ||
                matrixSpikeQCValues.result === 0) &&
              (rawResultToCompareMS || rawResultToCompareMS === 0)
                ? CalculateMatrixPercentRecovery(
                    matrixSpikeQCValues.result,
                    rawResultToCompareMS,
                    matrixSpikeQCValues.target,
                    true
                  )
                : null;

            // lcs QCSamples
            const lcsQCValues = lcsSamples
              .map((lcsSample) => ({
                result: findRawResult(lcsSample),
                target: findTargetValue(lcsSample),
              }))
              .filter(
                (lcsSample) => lcsSample.result || lcsSample.result === 0
              );

            const lcsPercRecoveries = lcsQCValues
              .map((lcsSample) =>
                CalculatePercentRecovery(
                  lcsSample.result,
                  lcsSample.target,
                  true
                )
              )
              .filter((val) => val || val === 0);

            // lcs dupe QCSamples
            const lcsDupeQCValues = lcsDupeSamples
              .map((lcsDupeSample) => ({
                result: findRawResult(lcsDupeSample),
                target: findTargetValue(lcsDupeSample),
              }))
              .filter(
                (lcsDupeSample) =>
                  lcsDupeSample.result || lcsDupeSample.result === 0
              );

            const lcsDupePercRecoveries = lcsDupeQCValues
              .map((lcsDupeSample) =>
                CalculatePercentRecovery(
                  lcsDupeSample.result,
                  lcsDupeSample.target,
                  true
                )
              )
              .filter((val) => val || val === 0);

            lcsDupeQCValues.forEach((lcsDupeQCValue) => {
              lcsQCValues.forEach((lcsQCValue) => {
                const rpd = CalculateRelativePercentDifference(
                  lcsQCValue.result,
                  lcsDupeQCValue.result
                );
                if (rpd > 20) {
                  relPercDiffernceLcsLcsDupeAbove20 = true;
                }
              });
            });

            // sample dupe QCSample
            const sampleDupeCalculatedValue =
              findCalculatedResult(sampleDupeSample);
            const sampleDupePrimaryCalculatedValue = findCalculatedResult(
              sampleDupePrimarySample
            );
            const sampleDupeLOD = findInSampleLodValue(sampleDupeSample);
            const sampleDupeLOQ = findInSampleLoqValue(sampleDupeSample);

            // determine values to use in calculation for rpd
            let sampleDupeValueForRPD = sampleDupeCalculatedValue;
            if (sampleDupeCalculatedValue < sampleDupeLOD) {
              sampleDupeValueForRPD = 0;
            } else if (sampleDupeCalculatedValue < sampleDupeLOQ) {
              sampleDupeValueForRPD = sampleDupeLOQ;
            }
            let sampleDupePrimaryValueForRPD = sampleDupePrimaryCalculatedValue;
            if (sampleDupePrimaryCalculatedValue < sampleDupeLOD) {
              sampleDupePrimaryValueForRPD = 0;
            } else if (sampleDupePrimaryCalculatedValue < sampleDupeLOQ) {
              sampleDupePrimaryValueForRPD = sampleDupeLOQ;
            }

            const relPercDiffernceSampleDupePrimaryAbove20 =
              (sampleDupeCalculatedValue || sampleDupeCalculatedValue === 0) &&
              (sampleDupePrimaryCalculatedValue ||
                sampleDupePrimaryCalculatedValue === 0)
                ? !!(
                    CalculateRelativePercentDifference(
                      sampleDupeValueForRPD,
                      sampleDupePrimaryValueForRPD
                    ) > 20
                  )
                : false;

            // ccv QCSamples
            const ccvQCValues = ccvSamples
              .map((ccvSample) => ({
                result: findRawResult(ccvSample),
                target: findTargetValue(ccvSample),
              }))
              .filter(
                (ccvSample) => ccvSample.result || ccvSample.result === 0
              );
            const ccvPercRecoveries = ccvQCValues
              .map((ccvSample) =>
                CalculatePercentRecovery(
                  ccvSample.result,
                  ccvSample.target,
                  true
                )
              )
              .filter((val) => val || val === 0);

            switch (testCategoryCode) {
              case "CANN":
              case "CE":
                // B1
                if (
                  (blankRawValueAboveLoq || methodBlankRawValueAboveLoq) &&
                  sampleCalculatedvalueBelowLOQ
                ) {
                  analyteQualifiers.push("B1");
                }
                // M1
                if (
                  matrixSpikePercRecovery !== null &&
                  matrixSpikePercRecovery > 120 &&
                  (lcsPercRecoveries.find((x) => x > 80 && x < 120) ||
                    lcsDupePercRecoveries.find((x) => x > 80 && x < 120))
                ) {
                  analyteQualifiers.push("M1");
                }
                // M2
                if (
                  matrixSpikePercRecovery !== null &&
                  matrixSpikePercRecovery < 80 &&
                  (lcsPercRecoveries.find((x) => x > 80 && x < 120) ||
                    lcsDupePercRecoveries.find((x) => x > 80 && x < 120))
                ) {
                  analyteQualifiers.push("M2");
                }
                // R1
                if (lcsDupeSamples.length) {
                  if (
                    relPercDiffernceLcsLcsDupeAbove20 &&
                    (lcsPercRecoveries.find((x) => x > 80 && x < 120) ||
                      lcsDupePercRecoveries.find((x) => x > 80 && x < 120))
                  ) {
                    analyteQualifiers.push("R1");
                  }
                } else if (
                  relPercDiffernceSampleDupePrimaryAbove20 &&
                  lcsPercRecoveries.find((x) => x > 80 && x < 120)
                ) {
                  analyteQualifiers.push("R1");
                }
                break;
              case "AGILENT LC PEST MYCO":
              case "SSI LC PEST MYCO":
              case "GC PEST":
                // B2
                if (
                  (blankRawValueAboveLoq || methodBlankRawValueAboveLoq) &&
                  sampleCalculatedValueBelowActionLevel
                ) {
                  analyteQualifiers.push("B2");
                }
                // L1
                if (
                  (lcsPercRecoveries.find((x) => x < 70 || x > 130) ||
                    lcsDupePercRecoveries.find((x) => x < 70 || x > 130)) &&
                  sampleCalculatedValueBelowActionLevel
                ) {
                  analyteQualifiers.push("L1");
                }
                // M1
                if (
                  matrixSpikePercRecovery !== null &&
                  matrixSpikePercRecovery > 130 &&
                  (lcsPercRecoveries.find((x) => x > 70 && x < 130) ||
                    lcsDupePercRecoveries.find((x) => x > 70 && x < 130))
                ) {
                  analyteQualifiers.push("M1");
                }
                // M2
                if (
                  matrixSpikePercRecovery !== null &&
                  matrixSpikePercRecovery < 70 &&
                  (lcsPercRecoveries.find((x) => x > 70 && x < 130) ||
                    lcsDupePercRecoveries.find((x) => x > 70 && x < 130))
                ) {
                  analyteQualifiers.push("M2");
                }
                // R1
                if (
                  relPercDiffernceLcsLcsDupeAbove20 &&
                  (lcsPercRecoveries.find((x) => x > 70 && x < 130) ||
                    lcsDupePercRecoveries.find((x) => x > 70 && x < 130))
                ) {
                  analyteQualifiers.push("R1");
                }
                // V1
                if (
                  ccvPercRecoveries.find((x) => x > 130) &&
                  sampleCalculatedValueBelowActionLevel
                ) {
                  analyteQualifiers.push("V1");
                }
                break;
              case "SOLV":
                // B2
                if (
                  (blankRawValueAboveLoq || methodBlankRawValueAboveLoq) &&
                  sampleCalculatedValueBelowActionLevel
                ) {
                  analyteQualifiers.push("B2");
                }

                if (analyteName === "propane" || analyteName === "butane") {
                  // L1
                  if (
                    (lcsPercRecoveries.find((x) => x < 60 || x > 140) ||
                      lcsDupePercRecoveries.find((x) => x < 60 || x > 140)) &&
                    sampleCalculatedValueBelowActionLevel
                  ) {
                    analyteQualifiers.push("L1");
                  }
                  // M1
                  if (
                    matrixSpikePercRecovery !== null &&
                    matrixSpikePercRecovery > 140 &&
                    (lcsPercRecoveries.find((x) => x > 60 && x < 140) ||
                      lcsDupePercRecoveries.find((x) => x > 60 && x < 140))
                  ) {
                    analyteQualifiers.push("M1");
                  }
                  // M2
                  if (
                    matrixSpikePercRecovery !== null &&
                    matrixSpikePercRecovery < 60 &&
                    (lcsPercRecoveries.find((x) => x > 60 && x < 140) ||
                      lcsDupePercRecoveries.find((x) => x > 60 && x < 140))
                  ) {
                    analyteQualifiers.push("M2");
                  }
                  // R1
                  if (
                    relPercDiffernceLcsLcsDupeAbove20 &&
                    (lcsPercRecoveries.find((x) => x > 60 && x < 140) ||
                      lcsDupePercRecoveries.find((x) => x > 60 && x < 140))
                  ) {
                    analyteQualifiers.push("R1");
                  }
                } else {
                  // L1
                  if (
                    (lcsPercRecoveries.find((x) => x < 70 || x > 130) ||
                      lcsDupePercRecoveries.find((x) => x < 70 || x > 130)) &&
                    sampleCalculatedValueBelowActionLevel
                  ) {
                    analyteQualifiers.push("L1");
                  }
                  // M1
                  if (
                    matrixSpikePercRecovery !== null &&
                    matrixSpikePercRecovery > 130 &&
                    (lcsPercRecoveries.find((x) => x > 70 && x < 130) ||
                      lcsDupePercRecoveries.find((x) => x > 70 && x < 130))
                  ) {
                    analyteQualifiers.push("M1");
                  }
                  // M2
                  if (
                    matrixSpikePercRecovery !== null &&
                    matrixSpikePercRecovery < 70 &&
                    (lcsPercRecoveries.find((x) => x > 70 && x < 130) ||
                      lcsDupePercRecoveries.find((x) => x > 70 && x < 130))
                  ) {
                    analyteQualifiers.push("M2");
                  }
                  // R1
                  if (
                    relPercDiffernceLcsLcsDupeAbove20 &&
                    (lcsPercRecoveries.find((x) => x > 70 && x < 130) ||
                      lcsDupePercRecoveries.find((x) => x > 70 && x < 130))
                  ) {
                    analyteQualifiers.push("R1");
                  }
                }
                // V1
                if (
                  ccvPercRecoveries.find((x) => x > 130) &&
                  sampleCalculatedValueBelowActionLevel
                ) {
                  analyteQualifiers.push("V1");
                }
                break;
              case "METAL":
                // B2
                if (
                  (blankRawValueAboveLoq || methodBlankRawValueAboveLoq) &&
                  sampleCalculatedValueBelowActionLevel
                ) {
                  analyteQualifiers.push("B2");
                }
                // L1
                if (
                  (lcsPercRecoveries.find((x) => x < 70 || x > 130) ||
                    lcsDupePercRecoveries.find((x) => x < 70 || x > 130)) &&
                  sampleCalculatedValueBelowActionLevel
                ) {
                  analyteQualifiers.push("L1");
                }
                // M1
                if (
                  matrixSpikePercRecovery !== null &&
                  matrixSpikePercRecovery > 125 &&
                  (lcsPercRecoveries.find((x) => x > 80 && x < 120) ||
                    lcsDupePercRecoveries.find((x) => x > 80 && x < 120))
                ) {
                  analyteQualifiers.push("M1");
                }
                // M2
                if (
                  matrixSpikePercRecovery !== null &&
                  matrixSpikePercRecovery < 75 &&
                  (lcsPercRecoveries.find((x) => x > 80 && x < 120) ||
                    lcsDupePercRecoveries.find((x) => x > 80 && x < 120))
                ) {
                  analyteQualifiers.push("M2");
                }
                // R1
                if (
                  relPercDiffernceLcsLcsDupeAbove20 &&
                  (lcsPercRecoveries.find((x) => x > 80 && x < 120) ||
                    lcsDupePercRecoveries.find((x) => x > 80 && x < 120))
                ) {
                  analyteQualifiers.push("R1");
                }
                // V1
                if (
                  ccvPercRecoveries.find((x) => x > 130) &&
                  sampleCalculatedValueBelowActionLevel
                ) {
                  analyteQualifiers.push("V1");
                }
                break;
              case "TERP":
                // B1
                if (
                  (blankRawValueAboveLoq || methodBlankRawValueAboveLoq) &&
                  sampleCalculatedvalueBelowLOQ
                ) {
                  analyteQualifiers.push("B1");
                }
                // M1
                if (
                  matrixSpikePercRecovery !== null &&
                  matrixSpikePercRecovery > 130 &&
                  (lcsPercRecoveries.find((x) => x > 70 && x < 130) ||
                    lcsDupePercRecoveries.find((x) => x > 70 && x < 130))
                ) {
                  analyteQualifiers.push("M1");
                }
                // M2
                if (
                  matrixSpikePercRecovery !== null &&
                  matrixSpikePercRecovery < 70 &&
                  (lcsPercRecoveries.find((x) => x > 70 && x < 130) ||
                    lcsDupePercRecoveries.find((x) => x > 70 && x < 130))
                ) {
                  analyteQualifiers.push("M2");
                }
                // R1
                if (lcsDupeSamples.length) {
                  if (
                    relPercDiffernceLcsLcsDupeAbove20 &&
                    (lcsPercRecoveries.find((x) => x > 70 && x < 130) ||
                      lcsDupePercRecoveries.find((x) => x > 70 && x < 130))
                  ) {
                    analyteQualifiers.push("R1");
                  }
                } else if (
                  relPercDiffernceSampleDupePrimaryAbove20 &&
                  lcsPercRecoveries.find((x) => x > 70 && x < 130)
                ) {
                  analyteQualifiers.push("R1");
                }
                break;
              default:
                break;
            }
            const result = analyteQualifiers?.map(
              (item) =>
                allQualifiers?.find((qualifier) => qualifier.Name === item)
                  ?.QualifierID || null
            );
            sampleQualifiers[analyteName] = [...result];
          } else if (summedAnalyte) {
            summedAnalytesToAdd.push(summedAnalyte);
          }
        });
      summedAnalytesToAdd.forEach((summedAnalyte) => {
        const addendQualifiers = summedAnalyte?.addendAnalyteNames?.reduce(
          (acc, addendAnalyteName) => {
            if (sampleQualifiers[addendAnalyteName]?.length) {
              const result = acc;
              sampleQualifiers[addendAnalyteName].forEach((qualifier) => {
                if (!result.find((x) => x === qualifier)) {
                  result.push(qualifier);
                }
              });
              return result;
            }
            return acc;
          },
          []
        );
        sampleQualifiers[summedAnalyte.sumAnalyteName] = [...addendQualifiers];
      });
      return {
        JobOrderTestSample: {
          JobOrderTestSampleID: {
            set: parseInt(sample.JobOrderTestSample?.JobOrderTestSampleID, 10),
          },
          Qualifiers: sampleQualifiers,
        },
      };
    });
};

export default assignSystemQualifiers;
