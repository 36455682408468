import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation, useLazyQuery } from "@apollo/client";
import { Title, Fieldset, Button } from "rbx";

import { useAuth } from "../../../../../../context/AuthContext";
import { customToast as toast } from "../../../../../../utils";
import QualifierForm from "../QualifierForm";
import Loader from "../../../../../../components/Loader";
import {
  CREATE_QUALIFIER_MUTATION,
  ALL_QUALIFIERS_QUERY,
  SINGLE_QUALIFIER_QUERY,
  UPDATE_QUALIFIER_MUTATION,
} from "../../../../../../graphql";

const INITIAL_STATE = {
  Name: "",
  States: "",
  Active: true,
};

const QualifierModal = ({ onComplete, QualifierID }) => {
  const [loading, setLoading] = useState(!!QualifierID);
  const [createQualifier] = useMutation(CREATE_QUALIFIER_MUTATION);
  const [updateQualifier] = useMutation(UPDATE_QUALIFIER_MUTATION);
  const { state: authState } = useAuth();

  const [inputs, setInputs] = useState({
    ...INITIAL_STATE,
  });

  const [getQualifierData, resultQualifierData] = useLazyQuery(
    SINGLE_QUALIFIER_QUERY
  );

  useEffect(() => {
    if (QualifierID) {
      getQualifierData({
        variables: {
          where: { QualifierID: parseInt(QualifierID, 10) },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [QualifierID, getQualifierData]);

  useEffect(() => {
    if (resultQualifierData?.data?.findUniqueQualifiers) {
      const {
        data: { findUniqueQualifiers },
      } = resultQualifierData;
      setInputs((prev) => ({
        ...prev,
        ...findUniqueQualifiers,
      }));
      setLoading(false);
    }
  }, [resultQualifierData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (QualifierID) {
        await updateQualifier({
          variables: {
            data: {
              Name: {
                set: inputs.Name
              },
              States: {
                set: Array.isArray(inputs.States) ? inputs.States.join(",") : inputs.States,
              },
              Active: {
                set: inputs.Active,
              },
              ModifiedBy: {
                set: authState?.user?.Username,
              },
            },
            where: {
              QualifierID: parseInt(QualifierID, 10),
            },
          },
          refetchQueries: [
            {
              query: ALL_QUALIFIERS_QUERY,
              variables: {
                where: {},
              },
              fetchPolicy: "network-only",
            },
            {
              query: SINGLE_QUALIFIER_QUERY,
              variables: {
                where: { QualifierID: parseInt(QualifierID, 10) },
              },
              fetchPolicy: "network-only",
            },
          ],
        });

        toast.success("Qualifier updated successfully.");
      } else {
        await createQualifier({
          variables: {
            data: {
              Name: inputs.Name,
              States: inputs.States.join(","),
              Active: inputs.Active,
              CreatedBy: authState?.user?.Username,
            },
          },
          refetchQueries: [
            {
              query: ALL_QUALIFIERS_QUERY,
              variables: {
                where: {},
              },
            },
          ],
        });
        toast.success("Qualifier created successfully.");
      }
      onComplete();
    } catch (err) {
      toast.error(`Error ${QualifierID ? "updating" : "creating"} Qualifier.`);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader style={{ height: "330px" }} />;
  }
  return (
    <React.Fragment>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>{QualifierID ? "Update" : "Create"} Qualifier</Title>
        </div>
        <div className="page-head-end">
          <Button.Group>
            <Button
              color="primary"
              size="small"
              type="button"
              onClick={onComplete}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              form="qualifier-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Fieldset>
        <QualifierForm
          adding
          formId="qualifier-form"
          inputs={inputs}
          onChange={handleChange}
          onSubmit={handleSave}
        />
      </Fieldset>
      <hr />
    </React.Fragment>
  );
};

QualifierModal.propTypes = {
  onComplete: PropTypes.func,
  QualifierID: PropTypes.string,
};

QualifierModal.defaultProps = {
  onComplete: (e) => e,
  QualifierID: null,
};

export default QualifierModal;
