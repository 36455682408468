const tests = {
  name: "tests",
  label: "Tests",
  icon: "vial",
  permissions: ["TEST"],
  active: (location) => {
    const [, p] = location.pathname.split("/");
    return /tests/.test(p);
  },
  links: [
    {
      name: "Tests",
      to: "/tests/tests-list",
    },
    {
      name: "Analytes",
      to: "/tests/analytes",
    },
    {
      name: "Manage Action Limit Templates",
      to: "/tests/action-limit-templates",
    },
    {
      name: "Test Categories",
      to: "/tests/test-categories",
    },
  ],
  root: "/tests",
};

export default tests;
