import React from "react";

import { Switch, Route } from "react-router-dom";

import JobReceiptPage from "./JobReceiptPage";

const JobReceiptRouter = (props) => (
  <Switch>
    <Route path="/job-receipt/:JobID">
      <JobReceiptPage />
    </Route>
  </Switch>
);

export default JobReceiptRouter;
