// eslint-disable-next-line import/prefer-default-export
const DEFAULT_COLUMNS = [
  {
    Header: "Active",
    id: "Active",
    accessor: "Active",
  },
  {
    Header: "Analyte",
    id: "Analyte",
    accessor: "Analyte.Name",
  },
];

export const NON_MICRO_COLUMNS = [
  ...DEFAULT_COLUMNS,
  {
    Header: "Action Limit (ppm)",
    id: "ActionLevel",
    accessor: "ActionLevel",
  },
  {
    Header: "LOD (ppm)",
    id: "LOD",
    accessor: "LOD",
  },
  {
    Header: "LOQ (ppm)",
    id: "LOQ",
    accessor: "LOQ",
  },
  {
    Header: "In-Sample LOD (ppm)",
    id: "InSampleLOD",
    accessor: "InSampleLOD",
  },
  {
    Header: "In-Sample LOQ (ppm)",
    id: "InSampleLOQ",
    accessor: "InSampleLOQ",
  },
];

export const MICRO_COLUMNS = [
  ...DEFAULT_COLUMNS,
  {
    Header: "LOD (cfu/g)",
    id: "LOD",
    accessor: "LOD",
  },
  {
    Header: "Allowable Criteria (cfu/g)",
    id: "AllowableCriteria",
    accessor: "AllowableCriteria",
  },
  {
    Header: "Upper Limit (cfu/g)",
    id: "UpperLimit",
    accessor: "UpperLimit",
  },
];
