import { gql } from "@apollo/client";

export const UPDATE_CART_MUTATION = gql`
  mutation UPDATE_CART_MUTATION(
    $data: CartUpdateInput!
    $where: CartWhereUniqueInput!
  ) {
    updateCart(data: $data, where: $where) {
      CartID
    }
  }
`;

export const CREATE_CART_MUTATION = gql`
  mutation CREATE_CART_MUTATION($data: CartCreateInput!) {
    createCart(data: $data) {
      CartID
    }
  }
`;

export const DELETE_CART_MUTATION = gql`
  mutation DELETE_CART_MUTATION($where: CartWhereUniqueInput!) {
    deleteCart(where: $where) {
      CartID
    }
  }
`;
