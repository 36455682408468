import React from "react";
import PropTypes from "prop-types";
import { Column, Title, Box, Control, Label, Input, Field, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserSelect from "../../../../components/UserSelect";
import { customToast as toast, clampNumber } from "../../../../utils";
import "../../Client.scss";

const ReferrerInfo = ({ handleSave, inputs, onChange, disabled }) => {
  const handleCommissionChange = (e) => {
    e.preventDefault();
    if (parseInt(e.target.value, 10) > 50) {
      onChange(e.target.name, clampNumber(parseInt(e.target.value, 10), 1, 50));
      toast.warning("Commission must be lower than 50%");
    } else if (parseInt(e.target.value, 10) < 1) {
      onChange(e.target.name, clampNumber(parseInt(e.target.value, 10), 1, 50));
      toast.warning("Commission must be greater than 1%");
    } else {
      onChange(e.target.name, e.target.value);
    }
  };

  return (
    <form id="edit-client-form" style={{ width: "100%" }} onSubmit={handleSave}>
      <Column.Group multiline>
        <Column size={12}>
          <Title subtitle>Referrer Commisions</Title>
        </Column>

        <Column size={6} table={{ size: 12 }}>
          <Box>
            <Field kind="group">
              <UserSelect
                disabled={disabled}
                label="Assign Referrer"
                name="ReferrerID"
                value={inputs.ReferrerID}
                where={{ Type: { is: { CodeId: { equals: "4" } } } }}
                onChange={onChange}
              />
              <Control iconRight>
                <Label>Referrer Commision Percentage</Label>
                <Input
                  disabled={disabled}
                  name="Commission"
                  size="small"
                  value={inputs.Commission}
                  onBlur={handleCommissionChange}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                />
                <Icon align="right" style={{ marginTop: "1.5rem" }}>
                  <FontAwesomeIcon icon="percentage" />
                </Icon>
              </Control>
            </Field>
          </Box>
        </Column>
        <Column size={6} table={{ size: 12 }}>
          <Box>
            <Field kind="group">
              <UserSelect
                disabled={disabled}
                label="Assign Referrer 2"
                name="Referrer2ID"
                value={inputs.Referrer2ID}
                where={{ Type: { is: { CodeId: { equals: "4" } } } }}
                onChange={onChange}
              />
              <Control iconRight>
                <Label>Referrer Commision Percentage</Label>
                <Input
                  disabled={disabled}
                  name="Commission2"
                  size="small"
                  value={inputs.Commission2}
                  onBlur={handleCommissionChange}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                />
                <Icon align="right" style={{ marginTop: "1.5rem" }}>
                  <FontAwesomeIcon icon="percentage" />
                </Icon>
              </Control>
            </Field>
          </Box>
        </Column>
      </Column.Group>
    </form>
  );
};

ReferrerInfo.propTypes = {
  inputs: PropTypes.object,
  handleSave: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

ReferrerInfo.defaultProps = {
  inputs: {},
  handleSave: (e) => e,
  onChange: (e) => e,
  disabled: false,
};

export default ReferrerInfo;
