import { gql } from "@apollo/client";

export const ALL_JOB_LOGS_QUERY = gql`
  query ALL_JOB_LOGS_QUERY(
    $where: JobLogsWhereInput
    $orderBy: [JobLogsOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyJobLogs(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      JobLogID
      JobID
      JobOrderID
      Change
      ReasonForChange
      CreatedBy
      CreatedDateTime
      ModifiedBy
      ModifiedDateTime
    } 
    aggregateJobLogs(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const SINGLE_JOB_LOG_QUERY = gql`
  query SINGLE_JOB_LOG_QUERY($where: JobLogsWhereUniqueInput!) {
    findUniqueJobLogs(where: $where) {
      JobLogID
      JobID
      JobOrderID
      Change
      CreatedBy
      CreatedDateTime
      ModifiedBy
      ModifiedDateTime
    }
  }
`;
