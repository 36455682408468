import { gql } from "@apollo/client";

export const CREATE_JOB_ORDER_DISPLAY_UNIT_MUTATION = gql`
  mutation CREATE_JOB_ORDER_DISPLAY_UNIT_MUTATION(
    $data: JobOrderDisplayUnitsCreateInput!
  ) {
    createJobOrderDisplayUnits(data: $data) {
      JobOrderID
      JobOrders {
        ProductCategory
        SubstanceType
      }
      JobOrderDisplayUnitID
      StructuralRigidity
      Weight
      Units
      Density
      DisplayName
    }
  }
`;

export const UPDATE_JOB_ORDER_DISPLAY_UNIT_MUTATION = gql`
  mutation UPDATE_JOB_ORDER_DISPLAY_UNIT_MUTATION(
    $data: JobOrderDisplayUnitsUpdateInput!,
    $where: JobOrderDisplayUnitsWhereUniqueInput!,
  ) {
    updateJobOrderDisplayUnits(data: $data, where: $where) {
      JobOrderID
      JobOrders {
        ProductCategory
        SubstanceType
      }
      JobOrderDisplayUnitID
      StructuralRigidity
      Weight
      Units
      Density
      DisplayName
    }
  }
`;

export const DELETE_JOB_ORDER_DISPLAY_UNIT_MUTATION = gql`
  mutation DELETE_JOB_ORDER_DISPLAY_UNIT_MUTATION(
    $where: JobOrderDisplayUnitsWhereUniqueInput!
  ) {
    deleteJobOrderDisplayUnits(where: $where) {
      JobOrderDisplayUnitID
    }
  }
`;
