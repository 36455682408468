const jobOrders = {
  name: "jobOrders",
  label: "Job Orders",
  icon: "vials",
  permissions: ["JOBS"],
  active: (location) => {
    const [, p] = location.pathname.split("/");
    return /job-orders/.test(p);
  },
  to: "/job-orders",
};

export default jobOrders;
