import { gql } from "@apollo/client";

export const CREATE_PERMISSION_MUTATION = gql`
  mutation CREATE_PERMISSION_MUTATION($data: PermissionsCreateInput!) {
    createPermissions(data: $data) {
      PermissionID
    }
  }
`;

export const UPDATE_PERMISSION_MUTATION = gql`
  mutation UPDATE_PERMISSION_MUTATION(
    $data: PermissionsUpdateInput!
    $where: PermissionsWhereUniqueInput!
  ) {
    updatePermissions(data: $data, where: $where) {
      PermissionID
    }
  }
`;

export const DELETE_PERMISSION_MUTATION = gql`
  mutation DELETE_PERMISSION_MUTATION($where: PermissionsWhereUniqueInput!) {
    deletePermissions(where: $where) {
      PermissionID
    }
  }
`;
