import { gql } from "@apollo/client";

export const UPDATE_LAB_MUTATION = gql`
  mutation UPDATE_LAB_MUTATION(
    $data: LabsUpdateInput!
    $where: LabsWhereUniqueInput!
  ) {
    updateLabs(data: $data, where: $where) {
      LabID
    }
  }
`;

export const CREATE_LAB_MUTATION = gql`
  mutation CREATE_LAB_MUTATION($data: LabsCreateInput!) {
    createLabs(data: $data) {
      LabID
    }
  }
`;
