import React from "react";
import PropTypes from "prop-types";
import { Title, Tag } from "rbx";
import "./PageHeader.scss";

const PageHeader = ({ title, style, children, alert, alertColor }) => (
  <div className="page-head-container">
    <div className="page-head">
      <div className="page-head-start">
        <Title size={3}>{title}</Title>
        {alert && <Tag color={alertColor}>{alert}</Tag>}
      </div>
      <div className="page-head-end">{children}</div>
    </div>
  </div>
);

PageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  style: PropTypes.object,
  alert: PropTypes.string,
  alertColor: PropTypes.string,
};

PageHeader.defaultProps = {
  title: "",
  children: null,
  style: null,
  alert: null,
  alertColor: "",
};

export default PageHeader;
