/* eslint-disable react/prop-types */
import React from "react";
import { format } from "date-fns";
import { Icon, Tag, Button } from "rbx";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TableCell } from "../../../../../components/DataTable";
import { STATUS_COLORS } from "../../../../../constants";

const { REACT_APP_COA_URL } = process.env;

const DEFAULT_COLUMNS = [
  {
    Header: "Username",
    id: "Username",
    accessor: "User.Username",
    disableSortBy: true,
  },
  {
    Header: "Job ID",
    id: "JobID",
    type: "number",
    accessor: "Job.JobID",
    Cell: ({ cell }) => (
      <TableCell.Link to={`/lims/intake/${cell.value}`} value={cell.value} />
    ),
  },
  {
    Header: "Order ID",
    id: "JobOrderID",
    type: "number",
    accessor: "JobOrderID",
    Cell: ({ cell }) => (
      <TableCell.Link
        to={`/lims/job-receipt/${cell.row.original.JobID}`}
        value={cell.value}
      />
    ),
  },
  {
    Header: "Order Name",
    id: "OrderName",
    accessor: "OrderName",
    Cell: ({ cell, row }) => (
      <TableCell.Wrapped
        value={row.original?.OverrideData?.OrderName || cell.value}
      />
    ),
  },
  {
    Header: "Create Date",
    id: "OrderCreateDate",
    accessor: "OrderCreateDate",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM-dd-yyyy") : "",
  },
  {
    Header: "Receive Date",
    id: "OrderReceiveDate",
    accessor: "OrderReceiveDate",
    disableSortBy: true,
    Cell: ({ cell }) =>
      cell?.value ? (
        format(new Date(cell.value), "MM-dd-yyyy")
      ) : (
        <Tag color="warning">Not Received Yet</Tag>
      ),
  },
  {
    Header: "Tests",
    id: "TestsCount",
    accessor: "TestsCount",
    disableSortBy: true,
    Cell: ({ cell }) => (
      <Link
        to={`/lims/test-status/${cell.row.original.JobID}/${cell.row.original.JobOrderID}`}
      >
        {cell.value}
      </Link>
    ),
  },
  {
    Header: "Results",
    id: "OrderIncludesFailedResult",
    accessor: "OrderIncludesFailedResult",
    Cell: (cell) =>
      cell.value ? (
        <Tag color="danger">Includes Fail</Tag>
      ) : (
        <Tag color="cyan">All Pass</Tag>
      ),
  },
];

export const generateColumns = (
  handlePushToFinalReview,
  handleReviewFlagToggle,
  canUpdate
) => [
  ...DEFAULT_COLUMNS,
  {
    Header: "View HTML",
    id: "viewHTML",
    accessor: "JobOrderID",
    Cell: (cell) =>
      canUpdate && (
        <Button
          as="a"
          color="primary"
          href={`${REACT_APP_COA_URL}/coa/html/${cell?.value}/`}
          style={{ borderRadius: "20px 0 20px 20px" }}
          target="_blank"
        >
          <Icon>
            <FontAwesomeIcon icon="code" />
          </Icon>
        </Button>
      ),
  },
  {
    Header: "Push to Final Review",
    id: "pushToFinalReview",
    Cell: (cell) =>
      canUpdate && (
        <Button
          color="primary"
          disabled={cell.row.original.ReviewFlag}
          style={{ borderRadius: "20px 0 20px 20px" }}
          onClick={(e) => {
            e.stopPropagation();
            handlePushToFinalReview(cell.row.original);
          }}
        >
          <Icon>
            <FontAwesomeIcon icon="check" />
          </Icon>
        </Button>
      ),
  },
  {
    Header: "Review Flag",
    id: "reviewFlag",
    Cell: (cell) =>
      canUpdate && (
        <Button
          color="white"
          onClick={(e) => {
            e.stopPropagation();
            handleReviewFlagToggle(cell.row.original);
          }}
        >
          <Icon color={cell.row.original.ReviewFlag ? "danger" : "grey"}>
            <FontAwesomeIcon icon="flag" />
          </Icon>
        </Button>
      ),
  },
];

export { DEFAULT_COLUMNS, STATUS_COLORS };
