import { gql } from "@apollo/client";

export const ALL_JOB_ORDERS_QUERY = gql`
  query ALL_JOB_ORDERS_QUERY(
    $where: JobOrdersWhereInput
    $orderBy: [JobOrdersOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyJobOrders(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      JobOrderID
      JobID
      OrderName
      OrderCompleteDate
      OrderCreateDate
      OrderReceiveDate
      OrderDueDate
      TestsCount
      OrderIncludesFailedResult
      ReviewFlag
      SampleLocationDate
      ApprovedTestsCount
      OverrideData
      User {
        UserID
        Username
      }
      Job {
        JobID
        UserID
        ShippingSystemCode {
          RecId
          CodeDescription
        }
        Payment {
          RecId
          CodeDescription
        }
      }
      JobOrderStatus {
        RecId
        CodeDescription
      }
      User {
        Username
      }
      ProductCategorySystemCode {
        RecId
        CodeDescription
      }
    }
    aggregateJobOrders(where: $where) {
      count {
        _all
      }
    }
  }
`;
export const JOB_ORDERS_TAT_GROUP_QUERY = gql`
  query JOB_ORDERS_TAT_GROUP_QUERY($where: JobOrdersWhereInput!) {
    findManyJobOrdersGroupedByTAT(where: $where) {
      DaysOld
      Orders
    }
  }
`;
export const JOB_ORDERS_DASHBOARD_ANALYTICS_QUERY = gql`
  query JOB_ORDERS_DASHBOARD_ANALYTICS_QUERY {
    findManyJobOrdersDashboardAnalytics {
      Overdue
      New
      InProcess
      AverageTAT
    }
  }
`;

export const JOB_ORDERS_FINANCE_EXPORT_QUERY = gql`
  query JOB_ORDERS_FINANCE_EXPORT_QUERY($where: JobOrdersWhereInput!) {
    jobOrderFinanceExport(where: $where) {
      JobOrderID
      PackageName
      TestName
      OrderTotal
      TermsDescription
      Job {
        JobID
        Total
        Credit
        Discount
        JobCreateDate
        Net
        Lab {
          Company
        }
        CouponID
        Coupon {
          CouponID
          Code
          Discount
          Percent
          DiscountType {
            CodeId
          }
        }
      }
      User {
        Company
        Username
        FirstName
        LastName
      }
    }
  }
`;

export const SINGLE_JOB_ORDERS_QUERY = gql`
  query SINGLE_JOB_ORDERS_QUERY($where: JobOrdersWhereUniqueInput!) {
    findUniqueJobOrders(where: $where) {
      Moisture
      Weight
      JobOrderID
      JobID
      UserID
      OrderStatus
      OrderName
      BatchOrderNumber
      LotID
      SubstanceType
      ProductCategory
      ProductCategorySystemCode {
        CodeDescription
      }
      OrderCreateDate
      SampleLocation
      SampleLocationDate
      ShowOnVerifyHemp
      TestsCount
      TestType
      Important
      NetWeight
      ServingSize
      CustomerSampleID
      VerifyHempCategory
      ComplianceReviewBy
      FinalReviewBy
      ReviewFlag
      StateRetest
      OriginalCOA
      SubstanceTypeSystemCode {
        RecId
        CodeDescription
      }
      JobOrderStatus {
        RecId
        CodeDescription
      }
      Category {
        RecId
        CodeDescription
      }
      JobOrderTests {
        JobOrderTestID
        Result
        TestID
        TestStatus
        Test {
          TestID
          Name
          Code
        }
        JobOrderTestSamples {
          JobOrderTestSampleID
          JobID
          JobOrderID
          JobOrderTestID
          Status
          SessionID
          Result
          ResultStatus {
            CodeDescription
            RecId
            CodeId
          }
        }
      }
      Job {
        LabID
        Lab {
          State
        }
      }
    }
  }
`;

export const ALL_JOBS_QUERY = gql`
  query ALL_JOBS_QUERY(
    $where: JobsWhereInput
    $orderBy: [JobsOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyJobs(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      JobID
      UserID
      LabID
      Total
      Credit
      CouponID
      Discount
      Shipping
      ShippingPrice
      Net
      BillingAddress
      ShippingAddress
      PaymentType
      PaymentStatus
      PaymentAmount
      PaymentReceived
      PaymentPicture
      PaymentID
      JobStatus
      ReceiveStatus
      JobCreateDate
      JobPaymentDate
      JobReceiveDate
      JobOrders {
        JobOrderID
      }
      User {
        UserID
        Username
        FirstName
        LastName
        Company
      }
      Payment {
        RecId
        CodeDescription
      }
      Lab {
        LabID
        Company
      }
      ShippingSystemCode {
        RecId
        CodeDescription
      }
    }
    aggregateJobs(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const SINGLE_JOB_QUERY = gql`
  query SINGLE_JOB_QUERY($where: JobsWhereUniqueInput!) {
    findUniqueJobs(where: $where) {
      JobID
      UserID
      LabID
      Total
      Credit
      CouponID
      Discount
      Shipping
      ShippingSystemCode {
        CodeDescription
      }
      ShippingPrice
      Net
      BillingAddress
      ShippingAddress
      PaymentType
      PaymentStatus
      PaymentAmount
      PaymentReceived
      PaymentPicture
      PaymentID
      JobStatus
      ReceiveStatus
      JobCreateDate
      JobPaymentDate
      JobReceiveDate
      Lab {
        State
      }
      Coupon {
        CouponID
        Code
        Percent
        Discount
        Type
      }
      User {
        UserID
        Username
        FirstName
        LastName
        Notes
        Company
        MMTCInfo
        TurnAroundDays
        COASettings
      }
      Payment {
        RecId
        CodeDescription
      }
      JobOrders {
        JobOrderID
        JobID
        OrderName
        BatchOrderNumber
        OverrideData
        TestsCount
        TestType
        SubstanceType
        ClientNotes
        SubstanceTypeSystemCode {
          CodeDescription
        }
        ProductCategory
        LabelClaim
        NetWeight
        ServingSize
        CustomerSampleID
        DisplayUnit
        MMTCInfo
        BatchInfo
        NetWeightUnit
        Picture
        Status
        PickupWeight
        InternalNotes
        COANotes
        OrderCreateDate
        Important
        OriginalCOA
        StateRetest
        OrderStatus
        OrderIncludesFailedResult
        LotID
        HidePerPackage
        JobOrderStatus {
          RecId
          CodeName
          CodeDescription
        }
        Category {
          RecId
          CodeName
          CodeDescription
        }
        JobOrderTests {
          JobOrderTestID
          JobOrderID
          JobID
          LabJobOrderTestID
          UserID
          UserTestID
          TestID
          Weight
          UserPrice
          HideOnCoA
          TestStatus
          Approved
          Result
          RePrepFlag
          Test {
            TestID
            Name
            Code
            Description
          }
        }
        ActualLabelClaims {
          ActualLabelClaimID
          AnalyteID
          LabelClaim
        }
      }
    }
  }
`;

export const SINGLE_JOB_ORDER_QUERY = gql`
  query SINGLE_JOB_ORDER_QUERY($where: JobOrdersWhereUniqueInput!) {
    findUniqueJobOrders(where: $where) {
      JobOrderID
      JobID
      OrderName
      BatchOrderNumber
      OverrideData
      TestsCount
      Important
      TestType
      SubstanceType
      LabelClaim
      NetWeight
      ServingSize
      CustomerSampleID
      MMTCInfo
      BatchInfo
      Moisture
      Weight
      ShowOnVerifyHemp
      ProductCategory
      VerifyHempCategory
      InternalNotes
      ComplianceReviewBy
      FinalReviewBy
      ReviewFlag
      StateRetest
      OriginalCOA
      JobOrderTests {
        JobOrderTestID
        Result
      }
      Job {
        JobID
        LabID
      }
      DisplayUnit
    }
  }
`;

export const SINGLE_JOB_ORDER_TEST_QUERY = gql`
  query SINGLE_JOB_ORDER_TEST_QUERY($where: JobOrderTestsWhereUniqueInput!) {
    findUniqueJobOrderTests(where: $where) {
      JobOrderTestID
      JobOrderID
      JobID
      LabJobOrderTestID
      UserID
      UserTestID
      TestID
      Weight
      UserPrice
      HideOnCoA
      TestStatus
      Approved
      UploadUserID
      UploadLog
      RePrepFlag
      ResultToDisplay
      Result
      ResultReprep
      Dilution
      Notes
      JobOrder {
        JobOrderID
        Job {
          JobID
          LabID
        }
      }
    }
  }
`;

export const ALL_JOB_ORDER_TESTS_QUERY = gql`
  query ALL_JOB_ORDER_TESTS_QUERY(
    $where: JobOrderTestsWhereInput
    $orderBy: [JobOrderTestsOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyJobOrderTests(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      JobOrderTestID
      JobOrderID
      JobID
      LabJobOrderTestID
      UserID
      UserTestID
      TestID
      Weight
      UserPrice
      HideOnCoA
      TestStatus
      Approved
      Archived
      UploadUserID
      UploadLog
      RePrepFlag
      ResultToDisplay
      Result
      ResultReprep
      Dilution
      Notes
      Test {
        TestID
        Name
      }
      JobOrder {
        OrderName
        OrderCreateDate
        OrderCompleteDate
        OverrideData
        ProductCategorySystemCode {
          RecId
          CodeDescription
        }
        User {
          Username
        }
        Job {
          ShippingSystemCode {
            RecId
            CodeDescription
          }
        }
      }
      JobOrderTestSamples {
        JobOrderTestSampleID
        SessionID
        BatchID
        ResultStatus {
          CodeDescription
        }
      }
    }
    aggregateJobOrderTests(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const ALL_JOBS_BY_LAB_QUERY = gql`
  query ALL_JOBS_BY_LAB_QUERY($where: JobsWhereInput) {
    SumByLabs(where: $where) {
      labName
      sumDiscount
      sumTotal
      sumShipping
      sumNet
    }
  }
`;

export const LIST_REFERRER_COMMISSIONS_QUERY = gql`
  query LIST_REFERRER_COMMISSIONS_QUERY($where: JobOrdersWhereInput) {
    ReferrerCommissions(where: $where) {
      referrer {
        UserID
        Username
      }
      total
      JobOrdersCommissions {
        JobOrder {
          JobOrderID
          OrderCreateDate
          OrderName
          OverrideData
          JobOrderStatus {
            CodeDescription
          }
        }
        Job {
          JobID
          PaymentAmount
          Payment {
            CodeDescription
          }
        }
        Username
        Percentage
        Total
      }
    }
  }
`;

export const LIST_PRIVATE_LABEL_COMMISSIONS_QUERY = gql`
  query LIST_PRIVATE_LABEL_COMMISSIONS_QUERY(
    $where: PrivateLabelCommissionsInput
  ) {
    PrivateLabelCommissions(where: $where) {
      plUser {
        PrivateLabelUserID
        Username
      }
      total
      JobOrdersCommissions {
        JobOrder {
          JobOrderID
          OrderCreateDate
          OrderName
          JobOrderStatus {
            CodeDescription
          }
        }
        Job {
          JobID
          PaymentAmount
          Payment {
            CodeDescription
          }
        }
        PrivateLabelCOA {
          COAID
          JobOrderID
          PaymentAmount
          PurchaseDate
          CouponDiscount
        }
        Username
        Percentage
        Total
      }
    }
  }
`;

export const ALL_JOB_ORDER_TEST_SAMPLES_QUERY = gql`
  query ALL_JOB_ORDER_TEST_SAMPLES_QUERY(
    $where: JobOrderTestSamplesWhereInput
    $orderBy: [JobOrderTestSamplesOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyJobOrderTestSamples(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      JobOrderTestSampleID
      JobOrderTestID
      JobOrderID
      JobID
      SessionID
      BatchID
      Status
      PrepNo
      Replicated
      Important
      Result
      ResultStatus {
        CodeDescription
      }
      IncludesFailedResult
      JobOrder {
        OrderName
        BatchOrderNumber
        OverrideData
        OrderReceiveDate
        OrderDueDate
        Category {
          CodeDescription
        }
        ProductCategory
        User {
          Username
        }
        Job {
          JobReceiveDate
          ShippingSystemCode {
            CodeDescription
          }
        }
      }
      JobOrderTest {
        ResultToDisplay
        Test {
          Name
          TestID
          Code
          TestTestCategories {
            TestCategory {
              Name
            }
          }
          TestAnalytes {
            Analyte {
              Name
              Active
              TestCategoryID
            }
          }
        }
      }
      Batches {
        BatchID
        LabID
        Lab {
          State
        }
        TestCategory {
          Name
          Code
          TestCategoryID
        }
      }
    }
    aggregateJobOrderTestSamples(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const SINGLE_JOB_ORDER_TEST_SAMPLE_QUERY = gql`
  query SINGLE_JOB_ORDER_TEST_SAMPLE_QUERY(
    $where: JobOrderTestSamplesWhereInput!
  ) {
    findFirstJobOrderTestSamples(where: $where) {
      JobOrderTestSampleID
      JobOrderTestID
      JobOrderID
      JobID
      SessionID
      BatchID
      Qualifiers
      Batches {
        TestCategory {
          Name
          Code
        }
      }
      Job {
        Lab {
          State
        }
      }
      JobOrderTest {
        JobOrderTestID
        Test {
          TestTestCategories {
            TestCategory {
              Analytes {
                AnalyteID
                Name
              }
            }
            TestCategoryID
          }
        }
      }
    }
  }
`;

export const FIND_FIRST_JOB_ORDER_TEST_SAMPLE_QUERY = gql`
  query FIND_FIRST_JOB_ORDER_TEST_SAMPLE_QUERY(
    $where: JobOrderTestSamplesWhereInput
  ) {
    findFirstJobOrderTestSamples(where: $where) {
      JobID
      JobOrderID
    }
  }
`;

export const GENERATE_JOB_RECEIPT_QUERY = gql`
  query GENERATE_JOB_RECEIPT_QUERY(
    $where: JobsWhereUniqueInput!
    $data: JobReceiptInput
  ) {
    generateJobReceipt(where: $where, data: $data)
  }
`;

export const VALIDATE_JOB_ORDER_ID_QUERY = gql`
  query VALIDATE_JOB_ORDER_ID_QUERY($JobOrderID: Int!) {
    validateJobOrderID(JobOrderID: $JobOrderID) {
      JobOrderID
      OrderTotal
    }
  }
`;
