import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Field, Control, Input, Label, Button, Title } from "rbx";
import {
  SINGLE_JOB_ORDER_TEST_QUERY,
  UPDATE_JOB_ORDER_TEST_MUTATION,
  ALL_JOB_ORDER_TESTS_QUERY,
} from "../../../../../../graphql";
import { Loader } from "../../../../../../components";
import { customToast as toast } from "../../../../../../utils";

const TestResultModal = ({ JobOrderTestID, onComplete, JobOrderID }) => {
  const [getJobOrderTest, { data: jobOrderTestData }] = useLazyQuery(
    SINGLE_JOB_ORDER_TEST_QUERY
  );
  const [updateJobOrderTest] = useMutation(UPDATE_JOB_ORDER_TEST_MUTATION);

  const [loading, setLoading] = useState(true);
  const [inputs, setInputs] = useState([]);

  useEffect(() => {
    getJobOrderTest({
      variables: {
        where: {
          JobOrderTestID: parseInt(JobOrderTestID, 10),
        },
      },
    });
  }, [getJobOrderTest, JobOrderTestID]);

  useEffect(() => {
    if (jobOrderTestData?.findUniqueJobOrderTests) {
      const { Result } = jobOrderTestData?.findUniqueJobOrderTests;
      const newInputValues = Object.keys(Result).map((key) => ({
        name: key,
        value: Result[key],
      }));
      setInputs(newInputValues);
      setLoading(false);
    }
  }, [jobOrderTestData]);

  const handleChange = (name, value) => {
    setInputs((prev) =>
      prev.map((input) => (input.name === name ? { ...input, value } : input))
    );
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const resultToSave = inputs.reduce((acc, curr) => {
        if (inputs[curr] !== null) {
          return {
            ...acc,
            [curr.name]: Number(curr.value),
          };
        }
        return acc;
      }, {});
      await updateJobOrderTest({
        variables: {
          data: {
            Result: resultToSave,
          },
          where: {
            JobOrderTestID: parseInt(JobOrderTestID, 10),
          },
        },
        refetchQueries: [
          {
            query: ALL_JOB_ORDER_TESTS_QUERY,
            variables: {
              where: {
                JobOrderID: { equals: parseInt(JobOrderID, 10) },
              },
              take: 25,
              skip: 0,
            },
          },
          {
            query: SINGLE_JOB_ORDER_TEST_QUERY,
            variables: {
              where: {
                JobOrderTestID: parseInt(JobOrderTestID, 10),
              },
            },
          },
        ],
      });
      onComplete();
    } catch (err) {
      toast.error("Error on saving Results");
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loader />;

  return (
    <form id="result-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Update Results</Title>
        </div>
        <div className="page-head-end">
          <Button.Group>
            <Button size="small" type="button" onClick={() => onComplete()}>
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={inputs.Password !== inputs.ConfirmPassword}
              form="result-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Field multiline kind="group">
        {inputs.map(({ name, value }) => (
          <Control key={name}>
            <Label>{name}</Label>
            <Input
              name={name}
              size="small"
              value={value}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </Control>
        ))}
      </Field>
    </form>
  );
};

TestResultModal.propTypes = {
  JobOrderTestID: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  JobOrderID: PropTypes.string.isRequired,
};

export default TestResultModal;
