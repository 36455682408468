import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

import CreditLedgerPage from "./CreditLedgerPage/CreditLedgerPage";

const CreditLedgerRouter = ( { routePermissions }) => (
  <Switch>
    <Route path="/credit-ledger">
      <CreditLedgerPage routePermissions={routePermissions} />
    </Route>
  </Switch>
);

CreditLedgerRouter.propTypes = {
  routePermissions: PropTypes.array,
};

CreditLedgerRouter.defaultProps = {
  routePermissions: [],
};


export default CreditLedgerRouter;
