/* eslint-disable max-lines-per-function */
import FindQCSampleResults from "./FindQCSampleResults";
import {
  CalculateAnalyteResult,
  CalculatePercentRecovery,
  CalculateMatrixPercentRecovery,
  CalculateRelativePercentDifference,
  FindAnalyteRawResult,
} from "../../../../../../../utils/batchCalculations";

const assignQualifierAlerts = (batchResults, sample, labState, analyte) => {
  let alert = false;
  const {
    lcsSamples,
    lcsDupeSamples,
    msSample,
    msPrimarySample,
    sampleDupeSample,
    sampleDupePrimarySample,
    blankSamples,
    methodBlankSamples,
    ccvSamples,
  } = FindQCSampleResults(batchResults);

  const testCategoryCode = batchResults.find((item) => item?.TestCategory?.Code)
    ?.TestCategory?.Code;

  const actionLimitTemplateAnalytes =
    sample?.ActionLimitTemplate?.ActionLimitTemplateAnalytes;

  const actionLimitTemplateAnalyte = actionLimitTemplateAnalytes?.find(
    (a) => a.AnalyteID.toString() === analyte.AnalyteID
  );

  if (actionLimitTemplateAnalyte) {
    // find and calculate all needed values for this analyte
    const analyteName = analyte.Name.toLowerCase().trim();
    const actionLevel = parseFloat(actionLimitTemplateAnalyte?.ActionLevel);
    const inSampleLOQ = parseFloat(actionLimitTemplateAnalyte?.InSampleLOQ);

    let blankRawValueAboveLoq = false;
    let methodBlankRawValueAboveLoq = false;
    let relPercDiffernceLcsLcsDupeAbove20 = false;

    const findCalculatedResult = (selectedSample) =>
      parseFloat(
        CalculateAnalyteResult(
          selectedSample,
          testCategoryCode,
          analyteName,
          labState
        )
      );

    const findRawResult = (selectedSample) =>
      parseFloat(
        FindAnalyteRawResult(
          selectedSample,
          testCategoryCode,
          analyteName,
          labState
        )
      );

    const findTargetValue = (selectedSample) =>
      parseFloat(selectedSample?.TargetValues?.[analyteName]) ||
      parseFloat(selectedSample?.QCTarget?.TargetValues?.[analyteName]) ||
      null;

    const findInSampleLoqValue = (selectedSample) =>
      parseFloat(
        selectedSample?.ActionLimitTemplate?.ActionLimitTemplateAnalytes?.find(
          (a) => a.AnalyteID.toString() === analyte.AnalyteID
        )?.InSampleLOQ
      );

    const findInSampleLodValue = (selectedSample) =>
      parseFloat(
        selectedSample?.ActionLimitTemplate?.ActionLimitTemplateAnalytes?.find(
          (a) => a.AnalyteID.toString() === analyte.AnalyteID
        )?.InSampleLOD
      );

    // for this sample
    const sampleCalculatedValue = findCalculatedResult(sample);

    const sampleCalculatedvalueAboveOQ = !!(
      (sampleCalculatedValue || sampleCalculatedValue === 0) &&
      sampleCalculatedValue < inSampleLOQ
    );
    const sampleCalculatedValueAboveActionLevel = !!(
      (sampleCalculatedValue || sampleCalculatedValue === 0) &&
      sampleCalculatedValue > actionLevel
    );

    // blank QCSamples
    blankSamples.forEach((blankSample) => {
      const rawResult = findRawResult(blankSample);
      const loq = parseFloat(
        blankSample?.ActionLimitTemplate?.ActionLimitTemplateAnalytes?.find(
          (a) => a.AnalyteID.toString() === analyte.AnalyteID
        )?.LOQ
      );
      if (rawResult || rawResult === 0) {
        if (rawResult >= loq) {
          blankRawValueAboveLoq = true;
        }
      }
    });

    // method blank QCSamples
    methodBlankSamples.forEach((methodBlankSample) => {
      const rawResult = findRawResult(methodBlankSample);
      const loq = parseFloat(
        methodBlankSample?.ActionLimitTemplate?.ActionLimitTemplateAnalytes?.find(
          (a) => a.AnalyteID.toString() === analyte.AnalyteID
        )?.LOQ
      );
      if (rawResult || rawResult === 0) {
        if (rawResult >= loq) {
          methodBlankRawValueAboveLoq = true;
        }
      }
    });

    // matrix spike QCSample
    const matrixSpikeQCValues = {
      result: findRawResult(msSample),
      target: findTargetValue(msSample),
    };
    const sampleToCompareMS = msPrimarySample || sampleDupeSample;
    const rawResultToCompareMS = findRawResult(sampleToCompareMS);

    const matrixSpikePercRecovery =
      (matrixSpikeQCValues.result || matrixSpikeQCValues.result === 0) &&
      (rawResultToCompareMS || rawResultToCompareMS === 0)
        ? CalculateMatrixPercentRecovery(
            matrixSpikeQCValues.result,
            rawResultToCompareMS,
            matrixSpikeQCValues.target,
            true
          )
        : null;

    // lcs QCSamples
    const lcsQCValues = lcsSamples
      .map((lcsSample) => ({
        result: findRawResult(lcsSample),
        target: findTargetValue(lcsSample),
      }))
      .filter((lcsSample) => lcsSample.result || lcsSample.result === 0);

    const lcsPercRecoveries = lcsQCValues
      .map((lcsSample) =>
        CalculatePercentRecovery(lcsSample.result, lcsSample.target, true)
      )
      .filter((val) => val || val === 0);

    // lcs dupe QCSamples
    const lcsDupeQCValues = lcsDupeSamples
      .map((lcsDupeSample) => ({
        result: findRawResult(lcsDupeSample),
        target: findTargetValue(lcsDupeSample),
      }))
      .filter(
        (lcsDupeSample) => lcsDupeSample.result || lcsDupeSample.result === 0
      );

    const lcsDupePercRecoveries = lcsDupeQCValues
      .map((lcsDupeSample) =>
        CalculatePercentRecovery(
          lcsDupeSample.result,
          lcsDupeSample.target,
          true
        )
      )
      .filter((val) => val || val === 0);

    lcsDupeQCValues.forEach((lcsDupeQCValue) => {
      lcsQCValues.forEach((lcsQCValue) => {
        const rpd = CalculateRelativePercentDifference(
          lcsQCValue.result,
          lcsDupeQCValue.result
        );
        if (rpd > 20) {
          relPercDiffernceLcsLcsDupeAbove20 = true;
        }
      });
    });

    // sample dupe QCSample
    const sampleDupeCalculatedValue = findCalculatedResult(sampleDupeSample);
    const sampleDupePrimaryCalculatedValue = findCalculatedResult(
      sampleDupePrimarySample
    );
    const sampleDupeLOD = findInSampleLodValue(sampleDupeSample);
    const sampleDupeLOQ = findInSampleLoqValue(sampleDupeSample);

    // determine values to use in calculation for rpd
    let sampleDupeValueForRPD = sampleDupeCalculatedValue;
    if (sampleDupeCalculatedValue < sampleDupeLOD) {
      sampleDupeValueForRPD = 0;
    } else if (sampleDupeCalculatedValue < sampleDupeLOQ) {
      sampleDupeValueForRPD = sampleDupeLOQ;
    }
    let sampleDupePrimaryValueForRPD = sampleDupePrimaryCalculatedValue;
    if (sampleDupePrimaryCalculatedValue < sampleDupeLOD) {
      sampleDupePrimaryValueForRPD = 0;
    } else if (sampleDupePrimaryCalculatedValue < sampleDupeLOQ) {
      sampleDupePrimaryValueForRPD = sampleDupeLOQ;
    }

    const relPercDiffernceSampleDupePrimaryAbove20 =
      (sampleDupeCalculatedValue || sampleDupeCalculatedValue === 0) &&
      (sampleDupePrimaryCalculatedValue ||
        sampleDupePrimaryCalculatedValue === 0)
        ? !!(
            CalculateRelativePercentDifference(
              sampleDupeValueForRPD,
              sampleDupePrimaryValueForRPD
            ) > 20
          )
        : false;

    // ccv QCSamples
    const ccvQCValues = ccvSamples
      .map((ccvSample) => ({
        result: findRawResult(ccvSample),
        target: findTargetValue(ccvSample),
      }))
      .filter((ccvSample) => ccvSample.result || ccvSample.result === 0);
    const ccvPercRecoveries = ccvQCValues
      .map((ccvSample) =>
        CalculatePercentRecovery(ccvSample.result, ccvSample.target, true)
      )
      .filter((val) => val || val === 0);

    switch (testCategoryCode) {
      case "CANN":
      case "CE":
        // B1
        if (
          (blankRawValueAboveLoq || methodBlankRawValueAboveLoq) &&
          sampleCalculatedvalueAboveOQ
        ) {
          alert = true;
        }
        // M1
        if (
          matrixSpikePercRecovery !== null &&
          matrixSpikePercRecovery > 120 &&
          lcsPercRecoveries.find((x) => x < 80 || x > 120) &&
          (!lcsDupeSamples.length ||
            lcsDupePercRecoveries.find((x) => x < 80 || x > 120))
        ) {
          alert = true;
        }
        // M2
        if (
          matrixSpikePercRecovery !== null &&
          matrixSpikePercRecovery < 80 &&
          lcsPercRecoveries.find((x) => x < 80 || x > 120) &&
          (!lcsDupeSamples.length ||
            lcsDupePercRecoveries.find((x) => x < 80 || x > 120))
        ) {
          alert = true;
        }
        // R1
        if (lcsDupeSamples.length) {
          if (
            relPercDiffernceLcsLcsDupeAbove20 &&
            lcsPercRecoveries.find((x) => x < 80 || x > 120) &&
            lcsDupePercRecoveries.find((x) => x < 80 || x > 120)
          ) {
            alert = true;
          }
        } else if (
          relPercDiffernceSampleDupePrimaryAbove20 &&
          lcsPercRecoveries.find((x) => x < 80 || x > 120)
        ) {
          alert = true;
        }
        break;
      case "AGILENT LC PEST MYCO":
      case "SSI LC PEST MYCO":
      case "GC PEST":
        // B2
        if (
          (blankRawValueAboveLoq || methodBlankRawValueAboveLoq) &&
          sampleCalculatedValueAboveActionLevel
        ) {
          alert = true;
        }
        // L1
        if (
          (lcsPercRecoveries.find((x) => x < 70 || x > 130) ||
            lcsDupePercRecoveries.find((x) => x < 70 || x > 130)) &&
          sampleCalculatedValueAboveActionLevel
        ) {
          alert = true;
        }
        // M1
        if (
          matrixSpikePercRecovery !== null &&
          matrixSpikePercRecovery > 130 &&
          lcsPercRecoveries.find((x) => x < 70 || x > 130) &&
          (!lcsDupeSamples.length ||
            lcsDupePercRecoveries.find((x) => x < 70 || x > 130))
        ) {
          alert = true;
        }
        // M2
        if (
          matrixSpikePercRecovery !== null &&
          matrixSpikePercRecovery < 70 &&
          lcsPercRecoveries.find((x) => x < 70 || x > 130) &&
          (!lcsDupeSamples.length ||
            lcsDupePercRecoveries.find((x) => x < 70 || x > 130))
        ) {
          alert = true;
        }
        // R1
        if (
          relPercDiffernceLcsLcsDupeAbove20 &&
          lcsPercRecoveries.find((x) => x < 70 || x > 130) &&
          (!lcsDupeSamples.length ||
            lcsDupePercRecoveries.find((x) => x < 70 || x > 130))
        ) {
          alert = true;
        }
        // V1
        if (
          ccvPercRecoveries.find((x) => x > 130) &&
          sampleCalculatedValueAboveActionLevel
        ) {
          alert = true;
        }
        break;
      case "SOLV":
        // B2
        if (
          (blankRawValueAboveLoq || methodBlankRawValueAboveLoq) &&
          sampleCalculatedValueAboveActionLevel
        ) {
          alert = true;
        }

        if (analyteName === "propane" || analyteName === "butane") {
          // L1
          if (
            (lcsPercRecoveries.find((x) => x < 60 || x > 140) ||
              lcsDupePercRecoveries.find((x) => x < 60 || x > 140)) &&
            sampleCalculatedValueAboveActionLevel
          ) {
            alert = true;
          }
          // M1
          if (
            matrixSpikePercRecovery !== null &&
            matrixSpikePercRecovery > 140 &&
            lcsPercRecoveries.find((x) => x < 60 || x > 140) &&
            (!lcsDupeSamples.length ||
              lcsDupePercRecoveries.find((x) => x < 60 || x > 140))
          ) {
            alert = true;
          }
          // M2
          if (
            matrixSpikePercRecovery !== null &&
            matrixSpikePercRecovery < 60 &&
            lcsPercRecoveries.find((x) => x < 60 || x > 140) &&
            (!lcsDupeSamples.length ||
              lcsDupePercRecoveries.find((x) => x < 60 || x > 140))
          ) {
            alert = true;
          }
          // R1
          if (
            relPercDiffernceLcsLcsDupeAbove20 &&
            lcsPercRecoveries.find((x) => x < 60 || x > 140) &&
            (!lcsDupeSamples.length ||
              lcsDupePercRecoveries.find((x) => x < 60 || x > 140))
          ) {
            alert = true;
          }
        } else {
          // L1
          if (
            (lcsPercRecoveries.find((x) => x < 70 || x > 130) ||
              lcsDupePercRecoveries.find((x) => x < 70 || x > 130)) &&
            sampleCalculatedValueAboveActionLevel
          ) {
            alert = true;
          }
          // M1
          if (
            matrixSpikePercRecovery !== null &&
            matrixSpikePercRecovery > 130 &&
            lcsPercRecoveries.find((x) => x < 70 || x > 130) &&
            (!lcsDupeSamples.length ||
              lcsDupePercRecoveries.find((x) => x < 70 || x > 130))
          ) {
            alert = true;
          }
          // M2
          if (
            matrixSpikePercRecovery !== null &&
            matrixSpikePercRecovery < 70 &&
            lcsPercRecoveries.find((x) => x < 70 || x > 130) &&
            (!lcsDupeSamples.length ||
              lcsDupePercRecoveries.find((x) => x < 70 || x > 130))
          ) {
            alert = true;
          }
          // R1
          if (
            relPercDiffernceLcsLcsDupeAbove20 &&
            lcsPercRecoveries.find((x) => x < 70 || x > 130) &&
            (!lcsDupeSamples.length ||
              lcsDupePercRecoveries.find((x) => x < 70 || x > 130))
          ) {
            alert = true;
          }
        }
        // V1
        if (
          ccvPercRecoveries.find((x) => x > 130) &&
          sampleCalculatedValueAboveActionLevel
        ) {
          alert = true;
        }
        break;
      case "METAL":
        // B2
        if (
          (blankRawValueAboveLoq || methodBlankRawValueAboveLoq) &&
          sampleCalculatedValueAboveActionLevel
        ) {
          alert = true;
        }
        // L1
        if (
          (lcsPercRecoveries.find((x) => x < 70 || x > 130) ||
            lcsDupePercRecoveries.find((x) => x < 70 || x > 130)) &&
          sampleCalculatedValueAboveActionLevel
        ) {
          alert = true;
        }
        // M1
        if (
          matrixSpikePercRecovery !== null &&
          matrixSpikePercRecovery > 125 &&
          lcsPercRecoveries.find((x) => x < 80 || x > 120) &&
          (!lcsDupeSamples.length ||
            lcsDupePercRecoveries.find((x) => x < 80 || x > 120))
        ) {
          alert = true;
        }
        // M2
        if (
          matrixSpikePercRecovery !== null &&
          matrixSpikePercRecovery < 75 &&
          lcsPercRecoveries.find((x) => x < 80 || x > 120) &&
          (!lcsDupeSamples.length ||
            lcsDupePercRecoveries.find((x) => x < 80 || x > 120))
        ) {
          alert = true;
        }
        // R1
        if (
          relPercDiffernceLcsLcsDupeAbove20 &&
          lcsPercRecoveries.find((x) => x < 80 || x > 120) &&
          (!lcsDupeSamples.length ||
            lcsDupePercRecoveries.find((x) => x < 80 || x > 120))
        ) {
          alert = true;
        }
        break;
      case "TERP":
        // B1
        if (
          (blankRawValueAboveLoq || methodBlankRawValueAboveLoq) &&
          sampleCalculatedvalueAboveOQ
        ) {
          alert = true;
        }
        // M1
        if (
          matrixSpikePercRecovery !== null &&
          matrixSpikePercRecovery > 130 &&
          lcsPercRecoveries.find((x) => x < 70 || x > 130) &&
          (!lcsDupeSamples.length ||
            lcsDupePercRecoveries.find((x) => x < 70 || x > 130))
        ) {
          alert = true;
        }
        // M2
        if (
          matrixSpikePercRecovery !== null &&
          matrixSpikePercRecovery < 70 &&
          lcsPercRecoveries.find((x) => x < 70 || x > 130) &&
          (!lcsDupeSamples.length ||
            lcsDupePercRecoveries.find((x) => x < 70 || x > 130))
        ) {
          alert = true;
        }
        // R1
        if (lcsDupeSamples.length) {
          if (
            relPercDiffernceLcsLcsDupeAbove20 &&
            lcsPercRecoveries.find((x) => x < 70 || x > 130) &&
            lcsDupePercRecoveries.find((x) => x < 70 || x > 130)
          ) {
            alert = true;
          }
        } else if (
          relPercDiffernceSampleDupePrimaryAbove20 &&
          lcsPercRecoveries.find((x) => x < 70 || x > 130)
        ) {
          alert = true;
        }
        break;
      default:
        break;
    }
  }

  return alert;
};

export default assignQualifierAlerts;
