import { gql } from "@apollo/client";

export const LIST_USERS_QUERY = gql`
  query LIST_USERS_QUERY(
    $where: UsersWhereInput
    $orderBy: [UsersOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyUsers(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      UserID
      Username
      FirstName
      LastName
      AccountType
      Phone
      AccountLevel
      Email
      LabID
      Status
      Lab {
        LabID
        Company
      }
    }
    aggregateUsers(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const SINGLE_USER_QUERY = gql`
  query SINGLE_USER_QUERY($where: UsersWhereUniqueInput!) {
    findUniqueUsers(where: $where) {
      UserID
      Username
      FirstName
      LastName
      AccountType
      Email
      Status
      Address
      Address2
      Phone
      City
      State
      Zip
      ProfilePic
      LabID
      isPrivateLabel
      AccountLevel
      Terms
      TimeZone
      Company
      SSN
      BillingAddress
      ShippingAddress
      ProcessingOption
      COASettings
      COAAddress
      ShippingMultiplier
      ShowOnVerifyHemp
      EmailNotifications
      SMSNotifications
      MMTCInfo
      LicenseNumber
      TurnAroundDays
      ParentClientID
      AllowDupeBatchOrderNumber
      Lab {
        LabID
        Company
      }
      UserProficiencies {
        RecId
        UserID
        ProficiencyID
        Proficiency {
          ProficiencyID
          Proficiency
        }
      }
      ReferrerID
      Commission
      Referrer2ID
      Commission2
      Notes
      PrivateLabelUsers {
        PrivateLabelUserID
        OwnerUserID
        Username
        Password
        RevShare
        Status
        CreateDate
      }
      TestSettings
      UserProcessingOptions {
        SystemCodeID
        ProcessingOption {
          CodeDescription
        }
      }
      Type {
        CodeDescription
        RecId
      }
      ChildClientIDs
    }
  }
`;

export const ALL_USERS_QUERY = gql`
  query ALL_USERS_QUERY(
    $where: UsersWhereInput
    $orderBy: [UsersOrderByInput!]
  ) {
    findManyUsers(where: $where, orderBy: $orderBy) {
      UserID
      Username
      FirstName
      LastName
      AccountType
      Phone
      AccountLevel
      Email
      Status
      LabID
      Lab {
        LabID
        Company
      }
    }
  }
`;

export const SINGLE_FIRST_USER_TEST_QUERY = gql`
  query SINGLE_FIRST_USER_TEST_QUERY($where: UserTestsWhereInput!) {
    findFirstUserTests(where: $where) {
      UserTestID
      UserID
      TestID
      UserPrice
    }
  }
`;

export const VALIDATE_PASSWORD_RESET_QUERY = gql`
  query VALIDATE_PASSWORD_RESET_QUERY($where: UsersWhereInput!) {
    validatePasswordResetToken(where: $where) {
      UserID
      Username
    }
  }
`;
