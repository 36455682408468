import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/client";
import { Title, Fieldset, Button } from "rbx";
import { customToast as toast } from "../../../../utils";
import ClientForm from "../ClientForm";
import {
  CREATE_USER_MUTATION,
  LIST_USERS_QUERY,
  CREATE_MANY_USER_PROCESSING_OPTIONS_MUTATION,
  FIRST_SYSTEM_CODE_QUERY,
} from "../../../../graphql";
import { useAuth } from "../../../../context";

const DEFAULT_STATE = {
  Username: "",
  FirstName: "",
  LastName: "",
  Company: "",
  Email: "",
  Phone: "",
  Status: 1,
  Address: "",
  Address2: "",
  City: "",
  State: "",
  Zip: "",
  ProfilePic: "",
  LabID: "",
  isPrivateLabel: "",
  Password: "",
  SSN: "",
  TimeZone: "",
  Terms: "",
  ProcessingOption: "",
  UserProcessingOptions: [],
};

const convertInputToVariables = (variables, keys, adding = true) =>
  keys.reduce((acc, curr) => {
    if (variables[curr]) {
      acc[curr] = variables[curr];
    } else {
      acc[curr] = null;
    }

    return acc;
  }, {});

const AddClientModal = ({ onComplete }) => {
  const [createClient] = useMutation(CREATE_USER_MUTATION);
  const { data: clientAccountType } = useQuery(FIRST_SYSTEM_CODE_QUERY, {
    variables: {
      where: {
        CodeName: {
          equals: "CodeName",
        },
        CodeId: {
          equals: "0",
        },
      },
    },
  });

  const [createProcessingOptions] = useMutation(
    CREATE_MANY_USER_PROCESSING_OPTIONS_MUTATION
  );

  const { state: authState } = useAuth();

  const [inputs, setInputs] = useState({
    ...DEFAULT_STATE,
  });

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const d = convertInputToVariables(inputs, Object.keys(DEFAULT_STATE), true);
    delete d.UserProcessingOptions;
    delete d.LabID;
    if (!d.Email) {
      toast.error("Please press enter after adding an email");
    } else {
      try {
        const { findFirstSystemCodes: clientCode } = clientAccountType;
        const { data: createClientData } = await createClient({
          variables: {
            data: {
              ...d,
              ProcessingOption: d.ProcessingOption || 0,
              Type: {
                connect: {
                  RecId: clientCode?.RecId || 1,
                },
              },
              Lab: {
                connect: {
                  LabID: parseInt(inputs.LabID, 10),
                },
              },
              TimeZone: inputs.TimeZone.toString(),
              Terms: inputs.Terms ? parseInt(inputs.Terms, 10) : undefined,
              isPrivateLabel: 0,
            },
          },

          refetchQueries: [
            {
              query: LIST_USERS_QUERY,
              variables: {
                where: { Type: { is: { CodeId: { in: ["0"] } } } },
                orderBy: { Username: "asc" },
              },
            },
          ],
        });
        if (createClientData?.createUsers?.UserID) {
          inputs.UserProcessingOptions.forEach(async (item) => {
            await createProcessingOptions({
              variables: {
                data: {
                  UserID: parseInt(createClientData.createUsers.UserID, 10),
                  SystemCodeID: parseInt(item.value, 10),
                  CreatedBy: authState.user.Username,
                  CreatedDateTime: new Date(),
                },
              },
            });
          });
          toast.success("Client created successfully.");
          onComplete(createClientData.createUsers.UserID);
        }
      } catch (err) {
        toast.error("Error creating Client.");
      }
    }
  };

  return (
    <React.Fragment>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Create Client</Title>
        </div>
        <div className="page-head-end">
          <Button.Group>
            <Button size="small" type="button" onClick={() => onComplete()}>
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={inputs.Password !== inputs.ConfirmPassword}
              form="add-client-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Fieldset>
        <ClientForm
          adding
          formId="add-client-form"
          inputs={inputs}
          onChange={handleChange}
          onSubmit={handleSave}
        />
      </Fieldset>
      <hr />
    </React.Fragment>
  );
};

AddClientModal.propTypes = {
  onComplete: PropTypes.func,
};

AddClientModal.defaultProps = {
  onComplete: (e) => e,
};

export default AddClientModal;
