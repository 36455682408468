import React from "react";
import PropTypes from "prop-types";
import { Title, Field, Button, Control, Input, Label, Image, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileUploader } from "../../../../components";
import Table from "../../../../components/DataTable/Table";
import "../../../../components/DataTable/DataTable.scss";
import COLUMNS from "./columns";

const HempCategories = ({
  inputs,
  onChange,
  uploadHempCategoryPath,
  onDelete,
  onSave,
  authState,
  onHandleDisplayChange,
  setInputs,
  data,
}) => (
  <React.Fragment>
    <div className="page-head">
      <div className="page-head-start">
        <Title size={6}>Verify Hemp Categories</Title>
      </div>
      <div className="page-head-end">
        <Field kind="group">
          <Control>
            <Button
              color="primary"
              size="small"
              type="button"
              onClick={() => {
                onHandleDisplayChange(true, { Name: "" });
              }}
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
              <span>Add</span>
            </Button>
          </Control>
        </Field>
      </div>
    </div>
    <hr />
    {inputs && (
      <React.Fragment>
        <Field kind="group">
          <Control expanded>
            <Label>
              {inputs.CategoryID ? "Edit Category" : "Add Category"}
            </Label>
            <Input
              name="Name"
              size="small"
              value={inputs.Name}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded size="small">
            <FileUploader
              dirPath={uploadHempCategoryPath}
              fullwidth={false}
              label="Picture"
              name="ImageURL"
              value={inputs.ImageURL}
              onChange={onChange}
            />
          </Control>
          <Control expanded>
            <Label>&nbsp;</Label>
            <Button
              color="primary"
              disabled={!inputs.Name}
              size="small"
              type="button"
              onClick={onSave}
            >
              Save
            </Button>
          </Control>
        </Field>
        {inputs.ImageURL && (
          <Field kind="group">
            <Control expanded size="small">
              <Image.Container size={128}>
                <Image src={inputs.ImageURL} />
              </Image.Container>
            </Control>
          </Field>
        )}
      </React.Fragment>
    )}
    <div className="table-section">
      <Table
        columns={COLUMNS(onDelete)}
        data={data}
        onRowClick={(row) => {
          setInputs(row);
        }}
      />
    </div>
  </React.Fragment>
);

HempCategories.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  uploadHempCategoryPath: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  authState: PropTypes.object.isRequired,
  onHandleDisplayChange: PropTypes.func,
  setInputs: PropTypes.func,
  data: PropTypes.array,
};
HempCategories.defaultProps = {
  inputs: {},
  onChange: () => null,
  onDelete: () => null,
  onSave: () => null,
  onHandleDisplayChange: () => null,
  setInputs: () => null,
  data: [],
};
export default HempCategories;
