import { gql } from "@apollo/client";

export const CREATE_USER_GROUP_PERMISSION_MUTATION = gql`
  mutation CREATE_USER_GROUP_PERMISSION_MUTATION(
    $data: UserGroupPermissionsCreateInput!
  ) {
    createUserGroupPermissions(data: $data) {
      UserGroupPermissionID
    }
  }
`;

export const DELETE_USER_GROUP_PERMISSION_MUTATION = gql`
  mutation DELETE_USER_GROUP_PERMISSION_MUTATION(
    $where: UserGroupPermissionsWhereUniqueInput!
  ) {
    deleteUserGroupPermissions(where: $where) {
      UserGroupPermissionID
    }
  }
`;

export const CREATE_MANY_USER_GROUP_PERMISSIONS_MUTATION = gql`
  mutation CREATE_USER_GROUP_PERMISSION_MUTATION(
    $data: [UserGroupPermissionsCreateManyInput!]!
  ) {
    createManyUserGroupPermissions(data: $data) {
      count
    }
  }
`;

export const DELETE_MANY_USER_GROUP_PERMISSIONS_MUTATION = gql`
  mutation DELETE_MANY_USER_GROUP_PERMISSIONS_MUTATION($where: UserGroupPermissionsWhereInput!) {
    deleteManyUserGroupPermissions(where: $where) {
      count
    }
  }
`;

export const UPDATE_DIFFERENT_USER_GROUP_PERMISSIONS_MUTATION = gql`
  mutation UPDATE_DIFFERENT_USER_GROUP_PERMISSIONS_MUTATION(
    $data: [UserGroupPermissionInput!]!
  ) {
    updateDifferentUserGroupPermissions(data: $data)
  }
`;
