/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Column from "./Column";

const Container = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
`;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const reorderItemMap = ({ itemMap, source, destination }) => {
  const current = [...itemMap[source.droppableId]];
  const next = [...itemMap[destination.droppableId]];
  const target = current[source.index];
  const founded = next.find(
    (n) => n.content.Test.TestID === target.content.Test.TestID
  );
  if (!founded) {
    if (source.droppableId === destination.droppableId) {
      const reordered = reorder(current, source.index, destination.index);
      const result = {
        ...itemMap,
        [source.droppableId]: reordered,
      };
      return {
        itemMap: result,
      };
    }
    next.splice(destination.index, 0, {
      ...target,
      id: `${destination.droppableId}-${target.id}`,
    });
    const result = {
      ...itemMap,
      [source.droppableId]: current,
      [destination.droppableId]: next,
    };
    return {
      itemMap: result,
      target,
    };
  }
  return {
    itemMap,
  };
};

const PackagesBoard = ({
  isCombineEnabled,
  withScrollableColumns,
  state,
  setState,
  onChange,
  handleHeaderChange,
  removePackageTest,
  removePackage,
  createPackageTest,
  reorderPackageTests,
  disabled,
  handleMedicalChange,
  onDisplayAsTestChange,
  onPackageStateChange,
}) => {
  const onDragEnd = (result) => {
    if (!disabled) {
      if (result.type !== "COLUMN") {
        if (result.combine) {
          const column = state.columns[result.source.droppableId];
          const withQuoteRemoved = [...column];
          // withQuoteRemoved.splice(result.source.index, 1);
          const columns = {
            ...state.columns,
            [result.source.droppableId]: withQuoteRemoved,
          };
          setState((prev) => ({ ...prev, columns }));
          return;
        }

        // dropped nowhere
        if (!result.destination) {
          return;
        }

        const { source, destination } = result;
        // did not move anywhere - can bail early
        if (
          source.droppableId === destination.droppableId &&
          source.index === destination.index
        ) {
          return;
        }

        if (destination.droppableId !== "UserTest") {
          if (
            source.droppableId === destination.droppableId &&
            source.index !== destination.index
          ) {
            reorderPackageTests(source, destination);
          } else {
            // current.splice(source.index, 1);
            const data = reorderItemMap({
              itemMap: state.columns,
              source,
              destination,
            });

            setState((prev) => ({
              ...prev,
              columns: data.itemMap,
            }));
            createPackageTest(
              data.target,
              destination.droppableId,
              destination.index
            );
          }
        } else if (source.droppableId !== destination.droppableId) {
          const current = [...state.columns[source.droppableId]];
          const target = current[source.index];
          removePackageTest(source.droppableId, target);
        }
      }
    }
  };

  return (
    <React.Fragment>
      <DragDropContext isDragDisabled={disabled} onDragEnd={onDragEnd}>
        <Droppable
          ignoreContainerClipping
          direction="horizontal"
          droppableId="board"
          isCombineEnabled={isCombineEnabled}
          isDropDisabled={disabled}
          type="COLUMN"
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              style={{ display: "flex" }}
              {...provided.droppableProps}
            >
              <div style={{ position: "relative", marginRight: "16px" }}>
                <Column
                  key="UserTest"
                  disabled={disabled}
                  displayAsTest={state.displayAsTests?.UserTest}
                  handleHeaderChange={handleHeaderChange}
                  handleMedicalChange={handleMedicalChange}
                  index="0"
                  isCombineEnabled={isCombineEnabled}
                  isScrollable={withScrollableColumns}
                  items={state.columns?.UserTest}
                  mainItems={state.columns?.UserTest}
                  medicalType={state.medicalTypes?.UserTest}
                  name="UserTest"
                  packageID="UserTest"
                  packagePrice={state.packagePrices?.UserTest}
                  removePackage={removePackage}
                  removePackageTest={removePackageTest}
                  title={state.names?.UserTest}
                  onChange={onChange}
                  onDisplayAsTestChange={onDisplayAsTestChange}
                />
              </div>
              <Container>
                {state.ordered
                  .sort()
                  .reverse()
                  .filter((key) => key !== "UserTest")
                  .map((key, index) => (
                    <Column
                      key={key}
                      disabled={disabled}
                      displayAsTest={state.displayAsTests[key]}
                      handleHeaderChange={handleHeaderChange}
                      handleMedicalChange={handleMedicalChange}
                      index={index}
                      isCombineEnabled={isCombineEnabled}
                      isScrollable={withScrollableColumns}
                      items={state.columns[key]}
                      mainItems={state.columns.UserTest}
                      medicalType={state.medicalTypes[key]}
                      name={key}
                      packageID={key}
                      packagePrice={state.packagePrices[key]}
                      packageState={state.packageStates[key]}
                      removePackage={removePackage}
                      removePackageTest={removePackageTest}
                      title={state.names[key]}
                      onChange={onChange}
                      onDisplayAsTestChange={onDisplayAsTestChange}
                      onPackageStateChange={onPackageStateChange}
                    />
                  ))}
              </Container>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </React.Fragment>
  );
};

PackagesBoard.propTypes = {
  isCombineEnabled: PropTypes.bool,
  withScrollableColumns: PropTypes.bool,
  state: PropTypes.object,
  setState: PropTypes.func,
  onChange: PropTypes.func,
  handleHeaderChange: PropTypes.func,
  removePackageTest: PropTypes.func,
  removePackage: PropTypes.func,
  createPackageTest: PropTypes.func,
  reorderPackageTests: PropTypes.func,
  disabled: PropTypes.func,
  handleMedicalChange: PropTypes.func,
  onDisplayAsTestChange: PropTypes.func,
  onPackageStateChange: PropTypes.func,
};

PackagesBoard.defaultProps = {
  isCombineEnabled: false,
  withScrollableColumns: false,
  state: {},
  setState: () => null,
  onChange: () => null,
  handleHeaderChange: () => null,
  removePackageTest: () => null,
  removePackage: () => null,
  createPackageTest: () => null,
  reorderPackageTests: () => null,
  disabled: false,
  handleMedicalChange: () => null,
  onDisplayAsTestChange: () => null,
  onPackageStateChange: () => null,
};

export default PackagesBoard;
