/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Field, Column } from "rbx";

import { generateColumns } from "./columns";
import { DataTable, PageHeader } from "../../../../components";
import { ALL_EQUIPMENT_LOGS_QUERY } from "../../../../graphql";
import EquipmentLogTypeSelect from "../../../../components/EquipmentLogTypeSelect/EquipmentLogTypeSelect";

const Logs = ({ EquipmentID }) => {
  const [where, setWhere] = useState({
    EquipmentID: {
      equals: parseInt(EquipmentID, 10),
    },
  });

  const orderBy = [{ id: "EquipmentID", desc: false }];

  const COLUMNS = useMemo(() => generateColumns());

  const handleFilterChange = (value) => {
    setWhere({
      AND: [
        {
          EquipmentID: {
            equals: parseInt(EquipmentID, 10),
          },
        },
        {
          LogType: value
            ? {
                equals: parseInt(value, 10),
              }
            : undefined,
        },
      ],
    });
  };

  return (
    <div>
      <PageHeader title="Logs">
        <Column.Group>
          <Column>
            <Field>
              <EquipmentLogTypeSelect
                label="Log Type"
                onChange={handleFilterChange}
              />
            </Field>
          </Column>
        </Column.Group>
      </PageHeader>
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateEquipmentLogs"
        columns={COLUMNS}
        name="findManyEquipmentLogs"
        orderBy={orderBy}
        query={ALL_EQUIPMENT_LOGS_QUERY}
        where={where}
      />
    </div>
  );
};

Logs.propTypes = {
  EquipmentID: PropTypes.string.isRequired,
};

export default Logs;
