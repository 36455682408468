/* eslint-disable max-lines-per-function */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-extend-native */
/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useMutation, useApolloClient, useQuery } from "@apollo/client";
import { Icon, Box, Column, Button, Field, Control, Generic } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getTime, addMilliseconds } from "date-fns";

import { TestItem, BatchDetailsForm } from "../components";
import BuildNewBatchSampleRows from "./BuildNewBatchSampleRows";
import { microSpeciesMapping } from "../../../../../utils/batchConstants";
import {
  PageHeader,
  Loader,
  QCSampleTypeSelect,
  BooleanInput,
} from "../../../../../components";
import { customToast as toast } from "../../../../../utils";
import { useAuth } from "../../../../../context";
import {
  CREATE_BATCH_MUTATION,
  CHECK_SESSION_ID_QUERY,
  ALL_TEST_CATEGORIES_QUERY,
  ALL_BATCHES_QUERY,
  FIRST_SYSTEM_CODE_QUERY,
  ALL_QC_SAMPLE_TYPES_QUERY,
  ALL_LABS_QUERY,
} from "../../../../../graphql";

import "../BatchRecords.scss";

Array.prototype.move = function move(from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
  return this;
};

const INITIAL_SAMPLE_ITEM = {
  SessionID: "",
  OrderName: "",
  Weight: "",
  Volume: "",
  Dilution: "",
  PrepUserID: "",
  sampleDuplicate: false,
  matrixSpike: false,
};

const INITIAL_STATE = {
  TestCategoryID: "",
  Tests: [],
  Diluents: [
    {
      Diluent: "",
      Expiration: "",
    },
  ],
  DilutedBy: "",
  LoadedBy: "",
  AnalyzedBy: "",
  SoyLotNumber: "",
  SoyLotExpiration: "",
  SolutionLotNumber: "",
  SolutionLotExpiration: "",
  IncubationStartDateTime: "",
  IncubationStartedBy: "",
  IncubationEndDateTime: "",
  IncubationEndedBy: "",
  Lab: "",
  ReadyForLoading: false,
};

const AddBatchPage = () => {
  const { state: authState } = useAuth();
  const client = useApolloClient();
  const history = useHistory();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [testCategory, setTestCategory] = useState({});
  const [qcSampleTypeID, setQCSampleTypeID] = useState("1");
  const [lab, setLab] = useState({});
  const [isMicro, setIsMicro] = useState(false);
  const [createBatch] = useMutation(CREATE_BATCH_MUTATION);

  const [loading, setLoading] = useState(false);

  const { data: getLabsData } = useQuery(ALL_LABS_QUERY, {});

  const { data: getQCSampleTypeData } = useQuery(ALL_QC_SAMPLE_TYPES_QUERY, {});

  const { data: getTestCategoriesData } = useQuery(ALL_TEST_CATEGORIES_QUERY, {
    variables: { orderBy: { Name: "asc" } },
  });
  const testCategories = getTestCategoriesData?.findManyTestCategories;

  const references = useRef([]);

  useEffect(() => {
    if (authState.user?.LabID && authState.user?.LabID !== "9999") {
      setInputs((prev) => ({
        ...prev,
        Lab: authState.user.LabID,
      }));

      if (getLabsData?.findManyLabs) {
        setLab(
          getLabsData.findManyLabs.find(
            (_lab) =>
              parseInt(_lab.LabID, 10) === parseInt(authState.user.LabID, 10)
          )
        );
      }
    }
  }, [authState.user?.LabID, getLabsData?.findManyLabs]);

  useEffect(() => {
    if (!inputs.Tests.length && testCategory.TestCategoryID && lab.State) {
      let increment = 0;
      const initialTimestamp = getTime(new Date());
      const Tests = BuildNewBatchSampleRows(testCategory, lab).map(
        (item, i) => {
          const result = item;
          if (item.Code) {
            const timestamp = getTime(
              addMilliseconds(initialTimestamp, increment)
            ).toString();
            result.SessionID = `${item.Code}_${timestamp.substring(
              0,
              8
            )}_${timestamp.substring(8)}`;
            increment += 1;
          } else {
            result.SessionID = "";
          }
          if (!item.sampleDuplicate) {
            result.sampleDuplicate = false;
          }
          if (!item.matrixSpike) {
            result.matrixSpike = false;
          }
          return result;
        }
      );
      setInputs((prev) => ({
        ...prev,
        Tests,
      }));
    }
  }, [inputs.Tests, testCategory, lab]);

  const handleTestsChange = (name, value, index) => {
    const newTests = inputs.Tests.map((item, i) => {
      if (name === "SessionID" && value) {
        return i === index ? { ...item, [name]: value } : item;
      }
      if (name === "SessionID" && !value) {
        return i === index ? { ...item, [name]: value, OrderName: "" } : item;
      }
      if (i === index) {
        return { ...item, [name]: true };
      }
      if (item[name]) {
        return { ...item, [name]: false };
      }
      return item;
    });
    setInputs((prev) => ({
      ...prev,
      Tests: newTests,
    }));
  };

  const handleFocusById = (index) => {
    references.current[index]?.focus();
  };

  const handleSessionBlur = async (index) => {
    try {
      const foundTest = inputs.Tests.find((item, i) => i === index);
      const sessionIdAlreadyExists = inputs.Tests.find(
        (item, i) =>
          item.SessionID &&
          foundTest.SessionID &&
          item.SessionID === foundTest.SessionID &&
          i !== index
      );
      const [JobID, JobOrderID, JobOrderTestID] =
        foundTest.SessionID.split("_");
      if (!sessionIdAlreadyExists) {
        if (JobID && JobOrderID && JobOrderTestID) {
          const { data: foundJobOrderTestSample } = await client.query({
            query: CHECK_SESSION_ID_QUERY,
            variables: {
              where: {
                JobID: { equals: parseInt(JobID, 10) },
                JobOrderID: { equals: parseInt(JobOrderID, 10) },
                JobOrderTestID: { equals: parseInt(JobOrderTestID, 10) },
                SessionID: { equals: foundTest.SessionID },
              },
              TestCategory: {
                TestCategoryID: parseInt(testCategory.TestCategoryID, 10),
              },
              Lab: {
                LabID: parseInt(inputs.Lab, 10),
              },
            },
          });
          if (!foundJobOrderTestSample?.checkIfSessionIdExists) {
            toast.error(`Session ID ${foundTest.SessionID} is not valid`);
            handleFocusById(index);
          } else {
            const tests = Object.assign([], [...inputs.Tests]);
            const newTestObj = {
              ...tests[index],
              JobOrderTestSampleID:
                foundJobOrderTestSample.checkIfSessionIdExists
                  .JobOrderTestSampleID,
              OrderName:
                foundJobOrderTestSample.checkIfSessionIdExists.JobOrder
                  .OrderName,
              showMSSDFields:
                foundJobOrderTestSample.checkIfSessionIdExists.JobOrderTest
                  ?.Test?.Code !== "TOX",
            };

            // if MICRO, add JobOrderID and names of species from TestAnalytes. used onSave new batch to add QCSamples
            if (isMicro) {
              const testSpecies = [];
              foundJobOrderTestSample.checkIfSessionIdExists.JobOrderTest?.Test?.TestAnalytes?.forEach(
                (obj) => {
                  if (
                    obj.Analyte?.Active &&
                    obj?.Analyte?.TestCategoryID === testCategory.TestCategoryID
                  ) {
                    const speciesObj = microSpeciesMapping.find((mapObj) =>
                      mapObj?.analytes.includes(obj.Analyte?.Name)
                    );
                    if (
                      speciesObj &&
                      !testSpecies.includes(speciesObj?.species)
                    ) {
                      testSpecies.push(speciesObj.species);
                    }
                  }
                }
              );

              newTestObj.testSpecies = testSpecies;
              newTestObj.jobOrderID =
                foundJobOrderTestSample.checkIfSessionIdExists.JobOrderID;
              newTestObj.testName =
                foundJobOrderTestSample.checkIfSessionIdExists.JobOrderTest?.Test?.Name;
            }

            const newTests = tests.map((item, i) =>
              i === index ? { ...newTestObj } : item
            );
            setInputs((prev) => ({
              ...prev,
              Tests: newTests,
            }));
            handleFocusById(index + 1);
          }
        } else if (foundTest.SessionID) {
          handleFocusById(index);
        }
      } else {
        handleFocusById(index);
        toast.error("This Session ID already exists on this batch");
      }
    } catch (err) {
      handleFocusById(index);
      toast.error(err.message);
    }
  };

  // for micro batches, need to add multiple QCSamples based on sessionIDs entered before creating a new batch
  const updateSamplesBeforeCreatingMicroBatch = () => {
    const initialTimestamp = getTime(new Date());
    let increment = 0;

    const primarySampleDupeSample = inputs.Tests.find((t) => t.sampleDuplicate);

    const PositiveControlQCSampleType =
      getQCSampleTypeData?.findManyQCSampleTypes?.find(
        (type) => type.Code === "PC"
      );
    const NegativeControlQCSampleType =
      getQCSampleTypeData?.findManyQCSampleTypes?.find(
        (type) => type.Code === "NC"
      );
    const SampleDupeQCSampleType =
      getQCSampleTypeData?.findManyQCSampleTypes?.find(
        (type) => type.Code === "SD"
      );

    const positiveControlSamplesToAdd = [];
    const negativeControlSamplesToAdd = [];
    const sampleDupeSamplesToAdd = [];

    const enteredSamples = inputs.Tests.map((test) => {
      // create a PC and NC QCSample for every species represented in the JobOrderTestSamples
      if (test?.testSpecies?.length) {
        test?.testSpecies?.forEach((speciesName) => {
          const existingSpecies = positiveControlSamplesToAdd.find(
            (controlSample) => controlSample.Species === speciesName
          );
          if (!existingSpecies) {
            const posTimestamp = getTime(
              addMilliseconds(initialTimestamp, increment)
            ).toString();
            const negTimestamp = getTime(
              addMilliseconds(initialTimestamp, increment + 1)
            ).toString();
            increment += 2;

            positiveControlSamplesToAdd.push({
              ...INITIAL_SAMPLE_ITEM,
              OrderName: PositiveControlQCSampleType?.Name,
              SessionID: `${
                PositiveControlQCSampleType?.Code
              }_${posTimestamp.substring(0, 8)}_${posTimestamp.substring(8)}`,
              PrimarySampleSessionID: test.SessionID,
              Species: speciesName,
            });
            negativeControlSamplesToAdd.push({
              ...INITIAL_SAMPLE_ITEM,
              OrderName: NegativeControlQCSampleType?.Name,
              SessionID: `${
                NegativeControlQCSampleType?.Code
              }_${negTimestamp.substring(0, 8)}_${negTimestamp.substring(8)}`,
              PrimarySampleSessionID: test.SessionID,
              Species: speciesName,
            });
          }
        });
      }
      // create an SD QCSample for every JobOrderTestSample on the same JobOrder as the JobOrderTestSample checked as the SD
      if (
        primarySampleDupeSample?.jobOrderID === test.jobOrderID &&
        !(testCategory.Code.includes("MICRO BMX") && lab.State === "AZ")
      ) {
        const timestamp = getTime(
          addMilliseconds(initialTimestamp, increment)
        ).toString();
        increment += 1;
        sampleDupeSamplesToAdd.push({
          ...INITIAL_SAMPLE_ITEM,
          OrderName: SampleDupeQCSampleType?.Name,
          SessionID: `${SampleDupeQCSampleType?.Code}_${timestamp.substring(
            0,
            8
          )}_${timestamp.substring(8)}`,
          PrimarySampleSessionID: test.SessionID,
        });
      }
      const result = { ...test };
      delete result.testSpecies;
      delete result.jobOrderID;
      delete result.testName;
      return result;
    });

    return [
      ...negativeControlSamplesToAdd,
      ...positiveControlSamplesToAdd,
      ...sampleDupeSamplesToAdd,
      ...enteredSamples,
    ];
  };

  // Make validations before returning the batch records to save
  const getBatchPayload = () => {
    let payload = [];
    // if MICRO Batch, add corresponding QCSamples
    payload = isMicro ? updateSamplesBeforeCreatingMicroBatch() : inputs?.Tests;

    // if ReadyForLoading is true, load just the records with SessionID
    payload = inputs?.ReadyForLoading
      ? payload.filter((test) => test.SessionID)
      : payload;

    return payload;
  };

  const handleSave = async () => {
    const batchPayload = getBatchPayload();
    setLoading(true);
    try {
      if (!Object.keys(testCategory).length) {
        toast.error("Select a Test Category");
        return;
      }

      const inputsWithValues = Object.keys(inputs).reduce((acc, curr) => {
        if (inputs[curr] !== "") {
          acc[curr] = inputs[curr];
        }
        return acc;
      }, {});

      // eslint-disable-next-line prefer-destructuring
      let currentDate = new Date()
        .toISOString()
        .split("T")[0]
        .replace(/-/g, "");
      currentDate = currentDate.substring(2, currentDate.length);

      const testCategoryCode = testCategory.Code.replace(/\s/g, "_");
      const where = {
        Name: {
          contains: `${currentDate}-${testCategoryCode}`,
        },
      };

      const { data: newBatchStatus } = await client.query({
        query: FIRST_SYSTEM_CODE_QUERY,
        variables: {
          where: {
            Category: { equals: "Batch" },
            CodeName: { equals: "Status" },
            CodeId: { equals: "N" },
          },
        },
      });

      const { data: foundBatches } = await client.query({
        query: ALL_BATCHES_QUERY,
        variables: {
          where,
        },
        fetchPolicy: "network-only",
      });

      let Name = `${currentDate}-${testCategoryCode}-1`;

      if (
        Array.isArray(foundBatches?.findManyBatches) &&
        foundBatches?.findManyBatches?.length
      ) {
        const batchesDays = foundBatches.findManyBatches.reduce((acc, curr) => {
          const day = Number(curr.Name[curr.Name.length - 1]);
          acc.push(day);
          return acc;
        }, []);

        Name = `${currentDate}-${testCategoryCode}-${
          Math.max(...batchesDays) + 1
        }`;
      }

      const data = {
        ...inputsWithValues,
        Tests: batchPayload,
        TestCategory: {
          connect: {
            TestCategoryID: testCategory.TestCategoryID,
          },
        },
        User: {
          connect: {
            UserID: parseInt(authState?.user?.UserID, 10),
          },
        },
        Lab: {
          connect: {
            LabID: parseInt(inputs.Lab, 10),
          },
        },
        BatchStatus: {
          connect: {
            RecId: parseInt(newBatchStatus?.findFirstSystemCodes?.RecId, 10),
          },
        },
        Name,
      };

      const { data: newBatchData } = await createBatch({
        variables: {
          data,
        },
      });

      toast.success("Batch record created");
      history.push(
        `/lims/batch-records/${newBatchData.createBatches.BatchID}/batch-info`
      );
    } catch (err) {
      toast.error("Unable to create Batch record");
    } finally {
      setLoading(false);
    }
  };

  const handleAddRow = (e) => {
    e.preventDefault();
    setInputs((prev) => ({
      ...prev,
      Tests: [...prev.Tests, INITIAL_SAMPLE_ITEM],
    }));
  };

  const handleAddQCRow = (e) => {
    e.preventDefault();
    try {
      const qcSampleType = getQCSampleTypeData?.findManyQCSampleTypes?.find(
        (type) => type.QCSampleTypeID === qcSampleTypeID
      );
      if (qcSampleType) {
        if (!qcSampleType.AllowMultiple) {
          const matchingQCSample = inputs.Tests.find(
            (test) => test.OrderName === qcSampleType.Name
          );
          if (matchingQCSample) {
            toast.error(
              `A ${qcSampleType.Name} sample already exists on this batch`
            );
            return false;
          }
        }
        const timestamp = getTime(new Date()).toString();
        setInputs((prev) => ({
          ...prev,
          Tests: [
            ...prev.Tests,
            {
              ...INITIAL_SAMPLE_ITEM,
              OrderName: qcSampleType?.Name,
              SessionID: `${qcSampleType?.Code}_${timestamp.substring(
                0,
                8
              )}_${timestamp.substring(8)}`,
            },
          ],
        }));
      } else {
        toast.error("Unable to add QC sample");
      }
    } catch {
      toast.error("Unable to add QC sample");
    }
    return false;
  };

  const handleMoveTestUp = (index) => {
    if (index > 0) {
      const tests = Object.assign([], [...inputs.Tests]);
      tests.move(index, index - 1);
      setInputs((prev) => ({
        ...prev,
        Tests: tests,
      }));
    }
  };

  const handleMoveTestDown = (index) => {
    if (index <= inputs.Tests.length) {
      const tests = Object.assign([], [...inputs.Tests]);
      tests.move(index, index + 1);
      setInputs((prev) => ({
        ...prev,
        Tests: tests,
      }));
    }
  };

  const handleDeleteTest = (index) => {
    const tests = inputs.Tests.filter((item, i) => i !== index);
    setInputs((prev) => ({
      ...prev,
      Tests: tests,
    }));
    if (tests.length < 20) {
      setInputs((prev) => ({
        ...prev,
        Tests: [...prev.Tests, INITIAL_SAMPLE_ITEM],
      }));
    }
  };

  const handleSelectTestCategory = (val) => {
    const selectedCategory = testCategories.find(
      (x) => x.TestCategoryID === val.toString()
    );
    setIsMicro(!!selectedCategory.Code?.includes("MICRO"));
    setTestCategory(selectedCategory);
  };

  const handleSessionIdEnterKeyPress = (index) => {
    handleSessionBlur(index);
  };

  const handleInputsChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "Lab" && getLabsData.findManyLabs) {
      setLab(
        getLabsData.findManyLabs.find(
          (_lab) => parseInt(_lab.LabID, 10) === value
        )
      );
    }
  };

  const handleQCTypeChange = (name, val) => {
    setQCSampleTypeID(val);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <PageHeader title="Create Batch">
        <Button
          color="default"
          size="small"
          onClick={() => history.push("/lims/batch-records")}
        >
          Cancel
        </Button>
        <Button color="primary" size="small" onClick={handleSave}>
          Save
        </Button>
      </PageHeader>
      <Column.Group centered multiline>
        <Column size={12}>
          <BatchDetailsForm
            editing={false}
            formId="batch-details-form"
            handleSelectTestCategory={handleSelectTestCategory}
            inputs={inputs}
            testCategory={testCategory}
            onChange={handleInputsChange}
          />
        </Column>
        <Column size={isMicro ? 8 : 6}>
          <br />
          {testCategory.TestCategoryID && inputs.Lab && (
            <Box className="tests-container">
              <Generic
                className={`test-item-container ${
                  isMicro ? "title-grid-4" : "title-grid-3"
                }`}
              >
                <b className="center">Order Name</b>
                <b className="center">Session ID</b>
                {isMicro && <b className="center">Test Name</b>}
                <b className="center">Actions</b>
              </Generic>
              {inputs?.Tests?.map((test, index) => (
                <TestItem
                  key={`key-${index}`}
                  editing
                  checkIfSessionIdExists={handleSessionBlur}
                  index={index}
                  inputs={test}
                  isMicro={isMicro}
                  references={references}
                  showMS={!isMicro}
                  showSD={
                    isMicro ||
                    !!inputs?.Tests?.find(
                      (sample) => sample.OrderName === "Sample Duplicate"
                    )
                  }
                  onChange={handleTestsChange}
                  onDelete={handleDeleteTest}
                  onEnterKeyPress={handleSessionIdEnterKeyPress}
                  onMoveRowDown={handleMoveTestDown}
                  onMoveRowUp={handleMoveTestUp}
                />
              ))}
              <React.Fragment>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <Field kind="group">
                    <Button
                      color="primary"
                      size="small"
                      style={{ marginRight: "1rem" }}
                      onClick={handleAddRow}
                    >
                      <Icon>
                        <FontAwesomeIcon icon="plus" />
                      </Icon>
                      <span>Add Order Sample</span>
                    </Button>
                    {!isMicro && (
                      <Control id="reprep-btn-group" size="small">
                        <Button
                          color="primary"
                          size="small"
                          onClick={handleAddQCRow}
                        >
                          <Icon>
                            <FontAwesomeIcon icon="plus" />
                          </Icon>
                          <span>Add QC Sample</span>
                        </Button>
                        <QCSampleTypeSelect
                          name="QCSampleTypeID"
                          value={qcSampleTypeID}
                          onChange={handleQCTypeChange}
                        />
                      </Control>
                    )}
                    <Control>
                      <BooleanInput
                        label="Ready For Loading"
                        name="ReadyForLoading"
                        value={inputs.ReadyForLoading}
                        onChange={(name, value) =>
                          setInputs((prev) => ({
                            ...prev,
                            [name]: value,
                          }))
                        }
                      />
                    </Control>
                  </Field>
                </div>
              </React.Fragment>
              {isMicro && (
                <div className="qc-note">
                  * QC Samples will be added automatically
                </div>
              )}
            </Box>
          )}
        </Column>
      </Column.Group>
    </React.Fragment>
  );
};

export default AddBatchPage;
