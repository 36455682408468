import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

import PaymentLedgerRouter from "./PaymentLedger";
import PrivateLabelCommissionsRouter from "./PrivateLabelCommissions";

const AccountingRouter = ({ routePermissions }) => (
  <Switch>
    <Route path="/accounting/payment-ledger">
      <PaymentLedgerRouter routePermissions={routePermissions} />
    </Route>
    <Route path="/accounting/private-label-commissions">
      <PrivateLabelCommissionsRouter />
    </Route>
  </Switch>
);

AccountingRouter.propTypes = {
  routePermissions: PropTypes.array,
};

AccountingRouter.defaultProps = {
  routePermissions: [],
};

export default AccountingRouter;
