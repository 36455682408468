import React from "react";
import PropTypes from "prop-types";
import { Box, Field, Control, Label, Button, Column } from "rbx";
import {
  LabLocationSelect,
  TestQueueCountSelect,
  ReceivedStatusSelect,
} from "../../../../components";

const SampleQueueFilters = ({
  onLabChange,
  onFiltersReset,
  where,
  selectedTest,
  onChangeTest,
  labID,
  testQueueSelectCountWhere,
  onReceivedStatusChange,
  showReceivedStatus,
}) => (
  <Box>
    <Column.Group>
      <Column size={labID?.toString() === "9999" ? 3.6 : 5.5}>
        <TestQueueCountSelect
          id="TestID"
          label="Test Name"
          name="TestID"
          size="small"
          value={selectedTest}
          where={testQueueSelectCountWhere}
          onChange={onChangeTest}
        />
      </Column>
      {labID?.toString() === "9999" && (
        <Column size={3.6}>
          <Field>
            <LabLocationSelect
              showLabel
              getValue={(x) => (typeof x?.equals === "number" ? x?.equals : "")}
              name="LabID"
              setValue={(x) => (typeof x === "number" ? { equals: x } : {})}
              size="small"
              value={where?.Job?.is?.LabID || ""}
              onChange={onLabChange}
            />
          </Field>
        </Column>
      )}
      {showReceivedStatus && (
        <Column size={labID?.toString() === "9999" ? 3.6 : 5.5}>
          <Field>
            <ReceivedStatusSelect
              getValue={(x) => {
                if (x?.is?.OrderReceiveDate?.not) {
                  return 1;
                }
                if (x?.is?.OrderReceiveDate) {
                  return 0;
                }
                return "";
              }}
              id="JobOrder"
              label="Received/Not Received"
              name="JobOrder"
              setValue={(x) => {
                if (Number.isInteger(x)) {
                  return x
                    ? { is: { OrderReceiveDate: { not: { equals: null } } } }
                    : { is: { OrderReceiveDate: { equals: null } } };
                }
                return null;
              }}
              size="small"
              value={where.JobOrder}
              onChange={onReceivedStatusChange}
            />
          </Field>
        </Column>
      )}
      <Column size={1}>
        <Field>
          <Control>
            <Label>&nbsp;</Label>
            <Button color="primary" size="small" onClick={onFiltersReset}>
              Reset
            </Button>
          </Control>
        </Field>
      </Column>
    </Column.Group>
  </Box>
);

SampleQueueFilters.propTypes = {
  onLabChange: PropTypes.func.isRequired,
  where: PropTypes.object.isRequired,
  selectedTest: PropTypes.number.isRequired,
  onChangeTest: PropTypes.func.isRequired,
  onFiltersReset: PropTypes.func.isRequired,
  labID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  testQueueSelectCountWhere: PropTypes.string.isRequired,
  onReceivedStatusChange: PropTypes.func.isRequired,
  showReceivedStatus: PropTypes.bool,
};

SampleQueueFilters.defaultProps = {
  showReceivedStatus: false,
};

export default SampleQueueFilters;
