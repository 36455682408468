/* eslint-disable max-lines-per-function */
import {
  CalculateAnalyteResult,
  CalculateMatrixPercentRecovery,
  CalculatePercentRecovery,
  CalculateRelativePercentDifference,
  FindAnalyteRawResult,
} from "../../../../../../utils/batchCalculations";

import { microCfuConversionConstants } from "../../../../../../utils/batchConstants";
import { productCategoryMatrix } from "../../../../../../constants";

const findBatchResultsConstants = (
  batchResults,
  testCategoryCode,
  labState,
  isButaneOrPropane
) => {
  const lfbSample = batchResults.find(
    (result) => result.QCSample?.QCSampleType?.Name === "LFB"
  );
  const lfbDupeSample = batchResults.find(
    (result) => result.QCSample?.QCSampleType?.Name === "LFB Dupe"
  );
  const lcsSample = batchResults.find(
    (result) => result.QCSample?.QCSampleType?.Name === "LCS"
  );
  const lcsDupeSample = batchResults.find(
    (result) => result.QCSample?.QCSampleType?.Name === "LCS Dupe"
  );
  const msSample = batchResults.find(
    (result) => result.QCSample?.QCSampleType?.Name === "Matrix Spike"
  );
  const msDupeSample = batchResults.find(
    (result) => result.QCSample?.QCSampleType?.Name === "Matrix Spike Duplicate"
  );
  const sampleDupeSample = batchResults.find(
    (result) => result.QCSample?.QCSampleType?.Name === "Sample Duplicate"
  );

  let lfbMaxPercRecovery = null;
  let lfbMinPercRecovery = null;
  let lfbMaxRPD = null;

  let lcsMaxPercRecovery = null;
  let lcsMinPercRecovery = null;
  let lcsMaxRPD = null;

  let matrixMaxPercRecovery = null;
  let matrixMinPercRecovery = null;
  let matrixMaxRPD = null;

  let sdMaxRPD = null;

  let ccvMaxPercRecovery = null;
  let ccvMinPercRecovery = null;

  switch (testCategoryCode) {
    case "CE":
    case "CANN":
      lfbMaxPercRecovery = 120;
      lfbMinPercRecovery = 80;
      lfbMaxRPD = 15;
      lcsMaxPercRecovery = 120;
      lcsMinPercRecovery = 80;
      lcsMaxRPD = 20;
      matrixMaxPercRecovery = 120;
      matrixMinPercRecovery = 80;
      matrixMaxRPD = 15;
      sdMaxRPD = labState === "FL" ? 15 : 20;
      ccvMaxPercRecovery = 120;
      ccvMinPercRecovery = 80;
      break;
    case "SOLV":
      lfbMaxPercRecovery = labState === "FL" && isButaneOrPropane ? 140 : 130;
      lfbMinPercRecovery = labState === "FL" && isButaneOrPropane ? 60 : 70;
      lfbMaxRPD = 30;
      lcsMaxPercRecovery = 130;
      lcsMinPercRecovery = 70;
      lcsMaxRPD = 20;
      matrixMaxPercRecovery =
        labState === "FL" && isButaneOrPropane ? 140 : 130;
      matrixMinPercRecovery = labState === "FL" && isButaneOrPropane ? 60 : 70;
      matrixMaxRPD = 30;
      sdMaxRPD = 30;
      ccvMaxPercRecovery = labState === "FL" ? 120 : 130;
      ccvMinPercRecovery = labState === "FL" ? 80 : 70;
      break;
    case "METAL":
      lfbMaxPercRecovery = 120;
      lfbMinPercRecovery = 80;
      lfbMaxRPD = 20;
      lcsMaxPercRecovery = 120;
      lcsMinPercRecovery = 80;
      lcsMaxRPD = 20;
      matrixMaxPercRecovery = labState === "FL" ? 120 : 125;
      matrixMinPercRecovery = labState === "FL" ? 80 : 75;
      matrixMaxRPD = 20;
      sdMaxRPD = 20;
      ccvMaxPercRecovery = labState === "FL" ? 120 : 110;
      ccvMinPercRecovery = labState === "FL" ? 80 : 90;
      break;
    case "AGILENT LC PEST MYCO":
    case "SSI LC PEST MYCO":
    case "GC PEST":
      lfbMaxPercRecovery = labState === "FL" ? 130 : 120;
      lfbMinPercRecovery = labState === "FL" ? 70 : 80;
      lfbMaxRPD = 20;
      lcsMaxPercRecovery = 130;
      lcsMinPercRecovery = 70;
      lcsMaxRPD = 20;
      matrixMaxPercRecovery = 130;
      matrixMinPercRecovery = 70;
      matrixMaxRPD = 20;
      sdMaxRPD = 20;
      ccvMaxPercRecovery = labState === "FL" ? 120 : 130;
      ccvMinPercRecovery = labState === "FL" ? 80 : 70;
      break;
    case "TERP":
      lfbMaxPercRecovery = 130;
      lfbMinPercRecovery = 70;
      lfbMaxRPD = 20;
      lcsMaxPercRecovery = 130;
      lcsMinPercRecovery = 70;
      lcsMaxRPD = 20;
      matrixMaxPercRecovery = 130;
      matrixMinPercRecovery = 70;
      matrixMaxRPD = 20;
      sdMaxRPD = labState === "FL" ? 30 : 20;
      ccvMaxPercRecovery = 130;
      ccvMinPercRecovery = 70;
      break;
    default:
      break;
  }

  return {
    lfbSample,
    lfbDupeSample,
    lcsSample,
    lcsDupeSample,
    msSample,
    msDupeSample,
    sampleDupeSample,
    lfbMaxRPD,
    lfbMaxPercRecovery,
    lfbMinPercRecovery,
    lcsMaxPercRecovery,
    lcsMinPercRecovery,
    lcsMaxRPD,
    matrixMaxPercRecovery,
    matrixMinPercRecovery,
    matrixMaxRPD,
    sdMaxRPD,
    ccvMaxPercRecovery,
    ccvMinPercRecovery,
  };
};

const determineQCPassFail = (
  sample,
  actionLimitTemplateAnalyte,
  labState,
  calculatedResult,
  targetValue,
  testCategoryCode,
  analyteName,
  batchResults,
  analyteID
) => {
  // find key items within the full list of batch results
  const {
    lfbSample,
    lfbDupeSample,
    lcsSample,
    lcsDupeSample,
    msSample,
    msDupeSample,
    sampleDupeSample,
    lfbMaxRPD,
    lfbMaxPercRecovery,
    lfbMinPercRecovery,
    lcsMaxPercRecovery,
    lcsMinPercRecovery,
    lcsMaxRPD,
    matrixMaxPercRecovery,
    matrixMinPercRecovery,
    matrixMaxRPD,
    sdMaxRPD,
    ccvMaxPercRecovery,
    ccvMinPercRecovery,
  } = findBatchResultsConstants(
    batchResults,
    testCategoryCode,
    labState,
    !!(analyteName.includes("butane") || analyteName.includes("propane"))
  );

  const primarySampleResult = sample?.PrimarySampleSessionID
    ? batchResults.find(
        (result) => result.SessionID === sample.PrimarySampleSessionID
      )
    : null;
  const QCSampleType = sample?.QCSample?.QCSampleType?.Name;

  // all categories Blank and Method Blank
  if (QCSampleType === "Method Blank" || QCSampleType === "Blank") {
    if (actionLimitTemplateAnalyte) {
      if (labState === "FL") {
        const pass =
          !calculatedResult ||
          parseFloat(calculatedResult) <=
            parseFloat(actionLimitTemplateAnalyte.LOD);
        return [
          pass,
          pass
            ? ""
            : `Above LOD: ${calculatedResult} > ${actionLimitTemplateAnalyte.LOD}`,
        ];
      }
      const pass =
        !calculatedResult ||
        parseFloat(calculatedResult) <=
          parseFloat(actionLimitTemplateAnalyte.LOQ);
      return [
        pass,
        pass
          ? ""
          : `Above LOQ: ${calculatedResult} > ${actionLimitTemplateAnalyte.LOQ}`,
      ];
    }
  }
  // all categories LFB and LFB Dupe
  else if (QCSampleType === "LFB" || QCSampleType === "LFB Dupe") {
    if (targetValue) {
      const percRecovery = CalculatePercentRecovery(
        parseFloat(calculatedResult),
        targetValue
      );
      const percRevocerPass = !!(
        !calculatedResult ||
        ((percRecovery || percRecovery === 0) &&
          percRecovery <= lfbMaxPercRecovery &&
          percRecovery >= lfbMinPercRecovery)
      );
      const sampleToCompare =
        QCSampleType === "LFB" ? lfbDupeSample : lfbSample;
      const calculatedResultToCompare = CalculateAnalyteResult(
        sampleToCompare,
        testCategoryCode,
        analyteName,
        labState
      );
      const relPercDiffernce = CalculateRelativePercentDifference(
        parseFloat(calculatedResult),
        parseFloat(calculatedResultToCompare)
      );
      const relPercDifferncePass = !!(
        !calculatedResult ||
        !calculatedResultToCompare ||
        ((relPercDiffernce || relPercDiffernce === 0) &&
          relPercDiffernce <= lfbMaxRPD)
      );
      if (percRevocerPass && relPercDifferncePass) {
        return [true];
      }
      const failReason = `${
        percRevocerPass
          ? ""
          : `Percent Recovery: ${
              percRecovery > lfbMaxPercRecovery
                ? `${percRecovery} > ${lfbMaxPercRecovery}`
                : `${percRecovery} < ${lfbMinPercRecovery}`
            } `
      }${percRevocerPass === relPercDifferncePass ? ",\n" : ""}${
        relPercDifferncePass
          ? ""
          : `Relative Percent Difference: ${relPercDiffernce} > ${lfbMaxRPD}`
      }`;
      return [false, failReason];
    }
  }
  // all categories LCS
  else if (QCSampleType === "LCS") {
    if (targetValue) {
      const percRecovery = CalculatePercentRecovery(
        parseFloat(calculatedResult),
        targetValue
      );
      const percRevocerPass = !!(
        !calculatedResult ||
        ((percRecovery || percRecovery === 0) &&
          percRecovery <=
            (analyteName === "butane" || analyteName === "propane"
              ? 140
              : lcsMaxPercRecovery) &&
          percRecovery >=
            (analyteName === "butane" || analyteName === "propane"
              ? 60
              : lcsMinPercRecovery))
      );
      if (lcsDupeSample) {
        const calculatedResultToCompare = CalculateAnalyteResult(
          lcsDupeSample,
          testCategoryCode,
          analyteName,
          labState
        );
        const relPercDiffernce = CalculateRelativePercentDifference(
          parseFloat(calculatedResult),
          parseFloat(calculatedResultToCompare)
        );

        const relPercDifferncePass = !!(
          !calculatedResult ||
          !calculatedResultToCompare ||
          ((relPercDiffernce || relPercDiffernce === 0) &&
            relPercDiffernce <= lcsMaxRPD)
        );
        if (percRevocerPass && relPercDifferncePass) {
          return [true];
        }
        const failReason = `${
          percRevocerPass
            ? ""
            : `Percent Recovery: ${
                percRecovery >
                (analyteName === "butane" || analyteName === "propane"
                  ? 140
                  : lcsMaxPercRecovery)
                  ? `${percRecovery} > ${
                      analyteName === "butane" || analyteName === "propane"
                        ? 140
                        : lcsMaxPercRecovery
                    }`
                  : `${percRecovery} < ${
                      analyteName === "butane" || analyteName === "propane"
                        ? 60
                        : lcsMinPercRecovery
                    }`
              }`
        }${percRevocerPass === relPercDifferncePass ? ",\n" : ""}${
          relPercDifferncePass
            ? ""
            : `Relative Percent Difference: ${relPercDiffernce} > ${lcsMaxRPD}`
        }`;
        return [false, failReason];
      }

      return percRevocerPass
        ? [true]
        : [
            false,
            `Percent Recovery: ${
              percRecovery >
              (analyteName === "butane" || analyteName === "propane"
                ? 140
                : lcsMaxPercRecovery)
                ? `${percRecovery} > ${
                    analyteName === "butane" || analyteName === "propane"
                      ? 140
                      : lcsMaxPercRecovery
                  }`
                : `${percRecovery} < ${
                    analyteName === "butane" || analyteName === "propane"
                      ? 60
                      : lcsMinPercRecovery
                  }`
            }`,
          ];
    }
  }
  // all categories LCS Dupe
  else if (QCSampleType === "LCS Dupe") {
    if (targetValue) {
      const percRecovery = CalculatePercentRecovery(
        parseFloat(calculatedResult),
        targetValue
      );
      const percRevocerPass = !!(
        !calculatedResult ||
        ((percRecovery || percRecovery === 0) &&
          percRecovery <=
            (analyteName === "butane" || analyteName === "propane"
              ? 140
              : lcsMaxPercRecovery) &&
          percRecovery >=
            (analyteName === "butane" || analyteName === "propane"
              ? 60
              : lcsMinPercRecovery))
      );
      if (lcsSample) {
        const calculatedResultToCompare = CalculateAnalyteResult(
          lcsSample,
          testCategoryCode,
          analyteName,
          labState
        );
        const relPercDiffernce = CalculateRelativePercentDifference(
          parseFloat(calculatedResult),
          parseFloat(calculatedResultToCompare)
        );
        const relPercDifferncePass = !!(
          !calculatedResult ||
          !calculatedResultToCompare ||
          ((relPercDiffernce || relPercDiffernce === 0) &&
            relPercDiffernce <= lcsMaxRPD)
        );
        if (percRevocerPass && relPercDifferncePass) {
          return [true];
        }
        const failReason = `${
          percRevocerPass
            ? ""
            : `Percent Recovery: ${
                percRecovery >
                (analyteName === "butane" || analyteName === "propane"
                  ? 140
                  : lcsMaxPercRecovery)
                  ? `${percRecovery} > ${
                      analyteName === "butane" || analyteName === "propane"
                        ? 140
                        : lcsMaxPercRecovery
                    }`
                  : `${percRecovery} < ${
                      analyteName === "butane" || analyteName === "propane"
                        ? 60
                        : lcsMinPercRecovery
                    }`
              }`
        }${percRevocerPass === relPercDifferncePass ? ",\n" : ""}${
          relPercDifferncePass
            ? ""
            : `Relative Percent Difference: ${relPercDiffernce} > ${lcsMaxRPD}`
        }`;
        return [false, failReason];
      }

      return percRevocerPass
        ? [true]
        : [
            false,
            `Percent Recovery: ${
              percRecovery >
              (analyteName === "butane" || analyteName === "propane"
                ? 140
                : lcsMaxPercRecovery)
                ? `${percRecovery} > ${
                    analyteName === "butane" || analyteName === "propane"
                      ? 140
                      : lcsMaxPercRecovery
                  }`
                : `${percRecovery} < ${
                    analyteName === "butane" || analyteName === "propane"
                      ? 60
                      : lcsMinPercRecovery
                  }`
            }`,
          ];
    }
  }
  // all categories Matrix Spike and Matrix Spike Duplicate
  else if (
    QCSampleType === "Matrix Spike" ||
    QCSampleType === "Matrix Spike Duplicate"
  ) {
    if (targetValue) {
      // using raw result to compare since MS and MSD calculated result is same as raw but primary or SD use calculation
      const sampleToCompare = primarySampleResult || sampleDupeSample;
      const rawResultToCompare = FindAnalyteRawResult(
        sampleToCompare,
        testCategoryCode,
        analyteName,
        labState
      );
      const percRecovery = CalculateMatrixPercentRecovery(
        parseFloat(calculatedResult),
        parseFloat(rawResultToCompare),
        targetValue,
        true
      );
      const percRevocerPass = !!(
        !calculatedResult ||
        !rawResultToCompare ||
        ((percRecovery || percRecovery === 0) &&
          percRecovery <= matrixMaxPercRecovery &&
          percRecovery >= matrixMinPercRecovery)
      );
      if (labState !== "FL") {
        if (percRevocerPass) {
          return [true];
        }
        return [
          false,
          `Percent Recovery: ${
            percRecovery > matrixMaxPercRecovery
              ? `${percRecovery} > ${matrixMaxPercRecovery}`
              : `${percRecovery} < ${matrixMinPercRecovery}`
          } `,
        ];
      }
      const sampleToCompareRpd =
        QCSampleType === "Matrix Spike" ? msDupeSample : msSample;

      const calculatedResultToCompareRpd = CalculateAnalyteResult(
        sampleToCompareRpd,
        testCategoryCode,
        analyteName,
        labState
      );
      const relPercDiffernce = CalculateRelativePercentDifference(
        parseFloat(calculatedResult),
        parseFloat(calculatedResultToCompareRpd)
      );

      const relPercDifferncePass = !!(
        !calculatedResult ||
        !calculatedResultToCompareRpd ||
        ((relPercDiffernce || relPercDiffernce === 0) &&
          relPercDiffernce <= matrixMaxRPD)
      );
      if (percRevocerPass && relPercDifferncePass) {
        return [true];
      }
      const failReason = `${
        percRevocerPass
          ? ""
          : `Percent Recovery: ${
              percRecovery > matrixMaxPercRecovery
                ? `${percRecovery} > ${matrixMaxPercRecovery}`
                : `${percRecovery} < ${matrixMinPercRecovery}`
            }`
      }${percRevocerPass === relPercDifferncePass ? ",\n" : ""}${
        relPercDifferncePass
          ? ""
          : `Relative Percent Difference: ${relPercDiffernce} > ${matrixMaxRPD}`
      }`;
      return [false, failReason];
    }
  }
  // all categories Sample Dupe
  else if (QCSampleType === "Sample Duplicate") {
    const calculatedResultToCompare = CalculateAnalyteResult(
      primarySampleResult,
      testCategoryCode,
      analyteName,
      labState
    );
    // determine values to use in calculation for rpd
    let rpdValueForThisSample = parseFloat(calculatedResult);
    if (
      parseFloat(calculatedResult) <
      parseFloat(actionLimitTemplateAnalyte.InSampleLOD)
    ) {
      rpdValueForThisSample = 0;
    } else if (
      parseFloat(calculatedResult) <
      parseFloat(actionLimitTemplateAnalyte.InSampleLOQ)
    ) {
      rpdValueForThisSample = parseFloat(
        actionLimitTemplateAnalyte.InSampleLOQ
      );
    }
    let rpdValueForComparedSample = parseFloat(calculatedResultToCompare);
    if (
      parseFloat(calculatedResultToCompare) <
      parseFloat(actionLimitTemplateAnalyte.InSampleLOD)
    ) {
      rpdValueForComparedSample = 0;
    } else if (
      parseFloat(calculatedResultToCompare) <
      parseFloat(actionLimitTemplateAnalyte.InSampleLOQ)
    ) {
      rpdValueForComparedSample = parseFloat(
        actionLimitTemplateAnalyte.InSampleLOQ
      );
    }
    const relPercDiffernce = CalculateRelativePercentDifference(
      rpdValueForThisSample,
      rpdValueForComparedSample
    );
    if (
      !calculatedResult ||
      !calculatedResultToCompare ||
      ((relPercDiffernce || relPercDiffernce === 0) &&
        relPercDiffernce <= sdMaxRPD)
    ) {
      return [true];
    }
    return [
      false,
      `Relative Percent Difference: ${relPercDiffernce} > ${sdMaxRPD}`,
    ];
  }
  // all categories CCV and ICV
  else if (QCSampleType === "CCV" || QCSampleType === "ICV") {
    if (targetValue) {
      const percRecovery = CalculatePercentRecovery(
        parseFloat(calculatedResult),
        targetValue
      );
      if (
        !calculatedResult ||
        ((percRecovery || percRecovery === 0) &&
          percRecovery <= ccvMaxPercRecovery &&
          percRecovery >= ccvMinPercRecovery)
      ) {
        return [true];
      }
      return [
        false,
        `Percent Recovery: ${
          percRecovery > ccvMaxPercRecovery
            ? `${percRecovery} > ${ccvMaxPercRecovery}`
            : `${percRecovery} < ${ccvMinPercRecovery}`
        } `,
      ];
    }
  }
  return [];
};

const determineMicroQCPassFail = (
  sample,
  actionLimitTemplateAnalyte,
  labState,
  calculatedResult,
  testCategoryCode,
  analyteName,
  batchResults
) => {
  // always pass if no allowable criteria
  if (
    actionLimitTemplateAnalyte.AllowableCriteria === "NA" ||
    !actionLimitTemplateAnalyte.AllowableCriteria
  ) {
    return [true];
  }

  // MICRO SAMPLE DUPE
  // commpare to result of PrimarySampleSessionID sample
  if (sample?.QCSampleType?.Name === "Sample Duplicate") {
    const primarySample = sample?.PrimarySampleSessionID
      ? batchResults.find(
          (result) => result.SessionID === sample.PrimarySampleSessionID
        )
      : null;
    const primarySampleCalculatedResult = CalculateAnalyteResult(
      primarySample,
      testCategoryCode,
      analyteName,
      labState
    );
    if (testCategoryCode === "MICRO MG ENUM") {
      // SD passes if both SD and Primary are <lod(includes no cq) or both are >lod
      if (
        calculatedResult.toLowerCase().trim() === "no cq" ||
        (parseFloat(calculatedResult) &&
          parseFloat(calculatedResult) < actionLimitTemplateAnalyte.LOD)
      ) {
        return [
          !!(
            primarySampleCalculatedResult.toLowerCase().trim() === "no cq" ||
            parseFloat(primarySampleCalculatedResult) <
              actionLimitTemplateAnalyte.LOD
          ),
        ];
      }
      return [
        !!(
          parseFloat(calculatedResult) &&
          parseFloat(primarySampleCalculatedResult) &&
          parseFloat(calculatedResult) > actionLimitTemplateAnalyte.LOD ===
            parseFloat(primarySampleCalculatedResult) >
              actionLimitTemplateAnalyte.LOD
        ),
      ];
    }
    if (testCategoryCode === "MICRO MG INC") {
      // SD passes if equal to Primary or both above 40 or both below 40
      return [
        !!(
          calculatedResult === primarySampleCalculatedResult ||
          (parseFloat(calculatedResult) &&
            parseFloat(primarySampleCalculatedResult) &&
            parseFloat(calculatedResult) > 40 ===
              parseFloat(primarySampleCalculatedResult) > 40)
        ),
      ];
    }
    if (testCategoryCode === "MICRO BMX TEMPO") {
      // SD passes if both SD and Primany are <100 or both >100
      return [
        !!(
          (calculatedResult.toLowerCase().trim() === "<100" ||
            (parseFloat(calculatedResult) &&
              parseFloat(calculatedResult) < 100)) ===
          (primarySampleCalculatedResult.toLowerCase().trim() === "<100" ||
            (parseFloat(primarySampleCalculatedResult) &&
              parseFloat(primarySampleCalculatedResult) < 100))
        ),
      ];
    }
    // MICRO BMX GENUP
    // SD passes if equal to Primary
    if (testCategoryCode === "MICRO BMX GENUP") {
      return [!!(calculatedResult === primarySampleCalculatedResult)];
    }
    // MICRO 3M ENUM
    // SD passes if Detected/NotDetected matches primary sample
    if (testCategoryCode === "MICRO 3M ENUM") {
      if (
        (parseFloat(calculatedResult) < actionLimitTemplateAnalyte.LOD ||
          parseFloat(calculatedResult) === 0 ||
          calculatedResult.toLowerCase().trim() === "no cq") ===
        (parseFloat(primarySampleCalculatedResult) <
          actionLimitTemplateAnalyte.LOD ||
          parseFloat(primarySampleCalculatedResult) === 0 ||
          primarySampleCalculatedResult.toLowerCase().trim() === "no cq")
      ) {
        return [true];
      }
      return [false];
    }
  }

  // MICRO POS AND NEG CONTROL
  if (
    testCategoryCode === "MICRO MG INC" ||
    testCategoryCode === "MICRO MG ENUM"
  ) {
    // PC passes if 35 or below
    if (sample?.QCSampleType?.Name === "Positive Control") {
      return [
        !!(parseFloat(calculatedResult) && parseFloat(calculatedResult) <= 35),
      ];
    }
    // NC
    if (sample?.QCSampleType?.Name === "Negative Control") {
      // MG ENUM: analyte total aerobic countchecks for threnshold based on the product category and passes if above threshold
      if (
        testCategoryCode === "MICRO MG ENUM" &&
        analyteName === "total aerobic count"
      ) {
        const productCategory =
          sample?.JobOrder?.OverrideDataCodeDescriptions &&
          JSON.parse(sample?.JobOrder?.OverrideDataCodeDescriptions)
            ?.ProductCategory
            ? JSON.parse(sample?.JobOrder?.OverrideDataCodeDescriptions)
                ?.ProductCategory
            : sample?.JobOrder?.Category?.CodeDescription || null;
        const matrix = Object.keys(productCategoryMatrix).find((key) =>
          productCategoryMatrix[key].includes(productCategory)
        );
        const cfuConversionObject = microCfuConversionConstants.find(
          (obj) => obj.matrix === matrix && obj.analyte === analyteName
        );
        return [
          !!(
            parseFloat(calculatedResult) &&
            parseFloat(calculatedResult) >
              cfuConversionObject?.negativeThreshold
          ),
        ];
      }
      // all MG INC and MG ENUM other analytes NC passes if no cq
      return [!!(calculatedResult.toLowerCase().trim() === "no cq")];
    }
  }
  // BMX GENUP
  if (testCategoryCode === "MICRO BMX GENUP") {
    // PC passes if presence
    if (sample?.QCSampleType?.Name === "Positive Control") {
      return [
        !!(calculatedResult.toLowerCase().trim() === "presumed presence"),
      ];
    }
    // NC passes if absence
    if (sample?.QCSampleType?.Name === "Negative Control") {
      return [!!(calculatedResult.toLowerCase().trim() === "presumed absence")];
    }
  }
  // BMX TEMPO compase PC and NC to thresholds
  if (testCategoryCode === "MICRO BMX TEMPO") {
    if (sample?.QCSampleType?.Name === "Positive Control") {
      if (calculatedResult.toLowerCase().trim().substring(0, 1) === "=") {
        return [
          !!(
            parseFloat(calculatedResult.toLowerCase().trim().substring(1)) >=
            100
          ),
        ];
      }
      return [
        !!(
          calculatedResult.toLowerCase().trim() === "≥100" ||
          (parseFloat(calculatedResult) && parseFloat(calculatedResult) >= 100)
        ),
      ];
    }
    if (sample?.QCSampleType?.Name === "Negative Control") {
      return [
        !!(
          calculatedResult.toLowerCase().trim() === "<0.25" ||
          (parseFloat(calculatedResult) && parseFloat(calculatedResult) < 0.25)
        ),
      ];
    }
  }

  // 3M ENUM
  if (testCategoryCode === "MICRO 3M ENUM") {
    // PC passes if Detected
    if (sample?.QCSampleType?.Name === "Positive Control") {
      return [
        !(
          parseFloat(calculatedResult) < actionLimitTemplateAnalyte.LOD ||
          parseFloat(calculatedResult) === 0 ||
          calculatedResult.toLowerCase().trim() === "no cq"
        ),
      ];
    }
    // NC passes if Not Detected
    if (sample?.QCSampleType?.Name === "Negative Control") {
      return [
        !!(
          parseFloat(calculatedResult) < actionLimitTemplateAnalyte.LOD ||
          parseFloat(calculatedResult) === 0 ||
          calculatedResult.toLowerCase().trim() === "no cq"
        ),
      ];
    }
  }
  return [false];
};

const determineMicroPassFail = (
  actionLimitTemplateAnalyte,
  calculatedResult,
  testCategoryCode
) => {
  // passes if no allowable criteria, presumed absence, or no cq
  if (
    actionLimitTemplateAnalyte.AllowableCriteria === "NA" ||
    !actionLimitTemplateAnalyte.AllowableCriteria ||
    calculatedResult.toLowerCase().trim() === "presumed absence" ||
    calculatedResult.toLowerCase().trim() === "no cq"
  ) {
    return [true];
  }
  // fails if presumed presence
  if (calculatedResult.toLowerCase().trim() === "presumed presence") {
    return [false];
  }
  // MG INC passes if above 40
  if (
    testCategoryCode === "MICRO MG INC" &&
    !Number.isNaN(parseFloat(calculatedResult))
  ) {
    return [!!(parseFloat(calculatedResult) > 40)];
  }
  // MG ENUM refers to action limit template values
  if (
    testCategoryCode === "MICRO MG ENUM" &&
    !Number.isNaN(parseFloat(calculatedResult))
  ) {
    if (parseFloat(calculatedResult) < actionLimitTemplateAnalyte.LOD) {
      return [true];
    }
    if (
      parseFloat(calculatedResult) >= actionLimitTemplateAnalyte.LOD &&
      parseFloat(calculatedResult) <
        actionLimitTemplateAnalyte.AllowableCriteria
    ) {
      return [true];
    }
    if (
      parseFloat(calculatedResult) >= actionLimitTemplateAnalyte.LOD &&
      parseFloat(calculatedResult) <= actionLimitTemplateAnalyte.UpperLimit
    ) {
      return [false];
    }
    if (parseFloat(calculatedResult) > actionLimitTemplateAnalyte.UpperLimit) {
      return [false];
    }
  }
  // BMX TEMPO passes if below 100
  if (testCategoryCode === "MICRO BMX TEMPO") {
    return [
      !!(
        calculatedResult.toLowerCase().trim() === "<100" ||
        (parseFloat(calculatedResult) && parseFloat(calculatedResult) < 100)
      ),
    ];
  }
  // 3M ENUM passes if below allowable criteria
  if (testCategoryCode === "MICRO 3M ENUM") {
    return [
      parseFloat(calculatedResult) === 0 ||
        !!(
          parseFloat(calculatedResult) <
          actionLimitTemplateAnalyte.AllowableCriteria
        ),
    ];
  }
  return [false];
};

const determineAnalytePassFail = (
  sampleType,
  sample,
  actionLimitTemplateAnalyte,
  labState,
  calculatedResult,
  targetValue,
  testCategoryCode,
  analyteName,
  batchResults,
  analyteID
) => {
  // MICRO
  if (testCategoryCode.includes("MICRO")) {
    if (sampleType === "QCSample") {
      return determineMicroQCPassFail(
        sample,
        actionLimitTemplateAnalyte,
        labState,
        calculatedResult,
        testCategoryCode,
        analyteName,
        batchResults
      );
    }
    return determineMicroPassFail(
      actionLimitTemplateAnalyte,
      calculatedResult,
      testCategoryCode
    );
  }

  // QC Samples
  if (sampleType === "QCSample") {
    return determineQCPassFail(
      sample,
      actionLimitTemplateAnalyte,
      labState,
      calculatedResult,
      targetValue,
      testCategoryCode,
      analyteName,
      batchResults,
      analyteID
    );
  }
  // JobOrderTestSamples
  // CANN and TERP always pass, analytes cannot fail
  if (
    testCategoryCode === "CANN" ||
    testCategoryCode === "CE" ||
    testCategoryCode === "TERP"
  ) {
    // Homogeneity test sample analytes do not pass or fail
    if (sample?.JobOrderTest?.Test?.Code === "HOMO") {
      return [];
    }
    return [true];
  }
  // for other test categories, refer to action level
  if (
    parseFloat(calculatedResult) <=
    parseFloat(actionLimitTemplateAnalyte?.ActionLevel)
  ) {
    return [true];
  }
  return [false];
};

export default determineAnalytePassFail;
