import React from "react";
import PropTypes from "prop-types";
import { Column, Field, Input, Label, Control } from "rbx";

import { LabLocationSelect } from "../../../../../../components";

const SampleLocationForm = ({
  inputs,
  onChange,
  disabled,
  onSubmit,
  formId,
  adding,
}) => (
  <form id={formId} onSubmit={onSubmit}>
    <Column.Group multiline>
      <Column size={12}>
        <Field kind="group">
          <Control expanded size="small">
            <Label>Name</Label>
            <Input
              required
              disabled={disabled}
              maxLength={250}
              name="Name"
              placeholder="Name"
              size="small"
              value={inputs.Name}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded size="small">
            <Label>Value</Label>
            <Input
              required
              disabled={disabled}
              maxLength={250}
              name="Value"
              placeholder="Value"
              size="small"
              value={inputs.Value}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded size="small">
            <LabLocationSelect
              required
              id="LabID"
              label="Lab to Use"
              name="LabID"
              size="small"
              value={inputs.LabID}
              onChange={onChange}
            />
          </Control>
        </Field>
      </Column>
    </Column.Group>
  </form>
);

SampleLocationForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  formId: PropTypes.string.isRequired,
  adding: PropTypes.bool,
};

SampleLocationForm.defaultProps = {
  onSubmit: () => null,
  disabled: false,
  adding: false,
};

export default SampleLocationForm;
