import React from "react";
import PropTypes from "prop-types";

// components
import { Switch, Route } from "react-router-dom";
import { Tab } from "rbx";

// internal components
import AccountDetails from "../../routes/AccountDetails";
import PaymentOptions from "../../routes/PaymentOptions";
import TestInfo from "../../routes/TestInfo";

const LabTabs = ({
  LabID,
  tab,
  history,
  onChange,
  inputs,
  disabled,
  onSubmit,
  uploadLabSignaturePath,
}) => (
  <React.Fragment key="company-tabs">
    <Tab.Group kind="boxed">
      <Tab
        active={tab === "account-details"}
        onClick={() => history.push(`/labs/lab/${LabID}/account-details`)}
      >
        Account Details
      </Tab>
      {/* {LabID && LabID !== "new" && (
        <Tab
          active={tab === "test-info"}
          onClick={() => history.push(`/labs/lab/${LabID}/test-info`)}
        >
          Test Info
        </Tab>
      )} */}
      {LabID && LabID !== "new" && (
        <Tab
          active={tab === "payment-options"}
          onClick={() => history.push(`/labs/lab/${LabID}/payment-options`)}
        >
          Payment Options
        </Tab>
      )}
    </Tab.Group>
    <Switch>
      <Route path="/labs/lab/:LabID/account-details">
        <AccountDetails
          adding={LabID === "new"}
          disabled={disabled}
          inputs={inputs}
          uploadLabSignaturePath={uploadLabSignaturePath}
          onChange={onChange}
          onSubmit={onSubmit}
        />
      </Route>
      <Route path="/labs/lab/:LabID/test-info">
        <TestInfo disabled={disabled} inputs={inputs} onChange={onChange} />
      </Route>
      <Route path="/labs/lab/:LabID/payment-options">
        <PaymentOptions
          disabled={disabled}
          inputs={inputs}
          onChange={onChange}
          onSubmit={onSubmit}
        />
      </Route>
    </Switch>
  </React.Fragment>
);

LabTabs.propTypes = {
  LabID: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  inputs: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  uploadLabSignaturePath: PropTypes.string.isRequired,
};

LabTabs.defaultProps = {};

export default LabTabs;
