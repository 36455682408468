import React from "react";
import PropTypes from "prop-types";
import { Column, Field, Box, Input, Control, Label } from "rbx";
import StateSelect from "../../../../components/StateSelect";

const ShippingAddress = ({ inputs, onChange, handleSave }) => (
  <form id="edit-profile-form" onSubmit={handleSave}>
    <Column.Group>
      <Column>
        <Box>
          <Field kind="group">
            <Control expanded>
              <Label>First name</Label>
              <Input
                name="ShippingFirstName"
                size="small"
                value={inputs?.ShippingAddress?.ShippingFirstName}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, "ShippingAddress")
                }
              />
            </Control>
            <Control expanded>
              <Label>Last name</Label>
              <Input
                name="ShippingLastName"
                size="small"
                value={inputs?.ShippingAddress?.ShippingLastName}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, "ShippingAddress")
                }
              />
            </Control>
          </Field>
          <Field>
            <Control>
              <Label>Company</Label>
              <Input
                name="ShippingCompany"
                size="small"
                value={inputs?.ShippingAddress?.ShippingCompany}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, "ShippingAddress")
                }
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded>
              <Label>Address</Label>
              <Input
                name="ShippingAddress"
                size="small"
                value={inputs?.ShippingAddress?.ShippingAddress}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, "ShippingAddress")
                }
              />
            </Control>
            <Control expanded>
              <Label>Address 2</Label>
              <Input
                name="ShippingAddress2"
                size="small"
                value={inputs?.ShippingAddress?.ShippingAddress2}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, "ShippingAddress")
                }
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded>
              <Label>City</Label>
              <Input
                name="ShippingCity"
                size="small"
                value={inputs?.ShippingAddress?.ShippingCity}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, "ShippingAddress")
                }
              />
            </Control>
            <StateSelect
              id="ShippingState"
              label="State/Province"
              name="ShippingState"
              size="small"
              value={inputs?.ShippingAddress?.ShippingState}
              onChange={(name, value) =>
                onChange(name, value, "ShippingAddress")
              }
            />
            <Control expanded>
              <Label>Zip/Postal</Label>
              <Input
                name="ShippingZip"
                size="small"
                value={inputs?.ShippingAddress?.ShippingZip}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, "ShippingAddress")
                }
              />
            </Control>
          </Field>
        </Box>
      </Column>
    </Column.Group>
  </form>
);

export default ShippingAddress;

ShippingAddress.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  handleSave: PropTypes.func,
};

ShippingAddress.defaultProps = {
  inputs: {},
  onChange: (e) => e,
  handleSave: (e) => e,
};
