import React from "react";
import PropTypes from "prop-types";
import QrReader from "react-qr-reader";
import { customToast as toast } from "../../utils";

const QrReaderModal = ({ onClose }) => {
  const handleScan = (data) => {
    if (data) {
      onClose({
        result: data,
      });
    }
  };

  const handleError = (err) => {
    toast.error(err.message);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <QrReader
        delay={300}
        style={{
          width: "50%",
        }}
        onError={handleError}
        onScan={handleScan}
      />
    </div>
  );
};
QrReaderModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default QrReaderModal;
