/* eslint-disable */
import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Control } from "rbx";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import CurrencyInput from "../../../../components/CurrencyInput";
import BooleanInput from "../../../../components/BooleanInput";

const reorder = (list, startIndex, endIndex, mainItemReorder = false) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  if (mainItemReorder) {
    const newResult = result.map((r, i) => ({
      ...r,
      ShowOrder: i + 1,
      edited: true,
    }));
    return newResult;
  }
  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: 5,
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? "lightgreen" : "transparent",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#f9f9f9" : "transparent",
});

const TestInfoList = ({ userTests, handleChange, setUserTests, disabled }) => {
  const [state, setState] = useState({
    items: [],
  });

  const generateContent = useCallback(
    (userTest) => (
      <div className="test-info-item">
        <h1>{userTest.Test.Name}</h1>
        <Control>
          <BooleanInput
            name="Active"
            disabled={disabled}
            value={userTest.Active}
            onChange={(name, value) =>
              handleChange(
                userTest.UserTestID ? userTest.UserTestID : userTest.newID,
                name,
                value ? 1 : 0
              )
            }
          />
        </Control>
        <Control>
          <CurrencyInput
            required
            disabled={!userTest.Active || disabled}
            maxLength={250}
            name="UserPrice"
            placeholder="UserPrice"
            size="small"
            value={userTest.UserPrice}
            onChange={(name, value) => {
              handleChange(
                userTest.UserTestID ? userTest.UserTestID : userTest.newID,
                name,
                value
              );
            }}
          />
        </Control>
      </div>
    ),
    [handleChange]
  );

  useEffect(() => {
    if (userTests?.length > 0) {
      setState({
        items: userTests.map((ut) => ({
          id: ut.UserTestID ? ut.UserTestID : String(ut.newID),
          content: generateContent(ut),
        })),
      });
    }
  }, [userTests, generateContent]);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const mainItems = reorder(
      userTests,
      result.source.index,
      result.destination.index,
      true
    );
    const items = reorder(
      state.items,
      result.source.index,
      result.destination.index
    );
    setState({
      items,
    });
    setUserTests(mainItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {state.items.map((item, index) => (
              <Draggable draggableId={item.id} index={index} key={item.id}>
                {(nextProvided, nextSnapshot) => (
                  <div
                    ref={nextProvided.innerRef}
                    {...nextProvided.draggableProps}
                    {...nextProvided.dragHandleProps}
                    style={getItemStyle(
                      nextSnapshot.isDragging,
                      nextProvided.draggableProps.style
                    )}
                  >
                    {item.content}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

TestInfoList.propTypes = {
  userTests: PropTypes.array,
  handleChange: PropTypes.func,
  setUserTests: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

TestInfoList.defaultProps = {
  userTests: [],
  handleChange: (e) => e,
  disabled: false,
};

export default TestInfoList;
