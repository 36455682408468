/* eslint-disable react/prop-types */
import React from "react";
import { Generic, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BooleanInput from "../../../../../components/BooleanInput";

const DEFAULT_COLUMNS = [
  {
    Header: "Test",
    id: "TestCategoryID",
    accessor: "TestCategory.Name",
  },
  {
    Header: "Analyte",
    id: "AnalyteID",
    accessor: "Name",
  },
];

export const generateColumns = (handleDelete, handleInputChange, canUpdate) =>
  canUpdate
    ? [
        ...DEFAULT_COLUMNS,
        {
          Header: "Active",
          id: "Active",
          accessor: "Active",
          Cell: ({ cell }) => (
            <BooleanInput
              name="Active"
              value={cell.value}
              onChange={(name, value) =>
                handleInputChange(cell.row.original.AnalyteID, value)
              }
            />
          ),
        },
        {
          Header: "",
          id: "delete",
          Cell: (cell) => (
            <Generic
              style={{ textAlign: "center" }}
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(cell.row.original);
              }}
            >
              <Icon color="danger">
                <FontAwesomeIcon icon="times" />
              </Icon>
            </Generic>
          ),
        },
      ]
    : [
        ...DEFAULT_COLUMNS,
        {
          Header: "Active",
          id: "Active",
          accessor: "Active",
          Cell: ({ cell }) => (
            <BooleanInput
              disabled
              name="Active"
              value={cell.value}
              onChange={(name, value) =>
                handleInputChange(cell.row.original.AnalyteID, value)
              }
            />
          ),
        },
      ];

export default DEFAULT_COLUMNS;
