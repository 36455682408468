import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GENERATE_COA_QUERY = gql`
  query GENERATE_COA_QUERY($jobOrderId: Int!, $coaId: Int, $version: Int) {
    generateCertificateOfAnalysis(
      jobOrderId: $jobOrderId
      coaId: $coaId
      version: $version
    ) {
      version
    }
  }
`;
