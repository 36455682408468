import React from "react";
import { useHistory } from "react-router-dom";
import AddEmployeeModal from "../../Employee/components/AddEmployeeModal";
import AddClientModal from "../../Client/components/AddClientModal";
import AddReferrerModal from "../../Referrer/components/AddReferrerModal";
import { useModal } from "../../../context/ModalContext";
import { useAuth } from "../../../context/AuthContext";
import {
  EmployeeMainPage,
  ClientMainPage,
  PrivateLabelMainPage,
} from "../components";
import { Loader } from "../../../components";
import "./DashboardPage.scss";

const DashboardPage = () => {
  const { setModalOpen } = useModal();
  const history = useHistory();
  const { state: authState } = useAuth();

  const handleCreateEmployee = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModalOpen(
      true,
      <AddEmployeeModal
        onComplete={(UserID) => {
          setModalOpen(false);
          if (UserID) {
            history.push(`/employees/${UserID}`);
          }
        }}
      />
    );
  };

  const handleCreateClient = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModalOpen(
      true,
      <AddClientModal
        onComplete={(UserID) => {
          setModalOpen(false);
          if (UserID) {
            history.push(`/clients/${UserID}/account-details`);
          }
        }}
      />
    );
  };

  const handleCreateReferrer = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModalOpen(
      true,
      <AddReferrerModal
        onComplete={(UserID) => {
          setModalOpen(false);
          if (UserID) {
            history.push(`/referrers/${UserID}`);
          }
        }}
      />
    );
  };

  const handleCreateOrderTest = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push("/new-test/individual-test");
  };

  const handleCreateOrderCOA = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push("/pl/order");
  };

  const handleDownloadPurchasedCOA = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push("/pl/find");
  };

  if (!authState?.user) return <Loader />;

  return (
    <React.Fragment>
      {authState.user.LoginType === "Employee" && (
        <EmployeeMainPage
          onCreateClient={handleCreateClient}
          onCreateEmployee={handleCreateEmployee}
          onCreateReferrer={handleCreateReferrer}
        />
      )}
      {authState.user.LoginType === "Client" && (
        <ClientMainPage
          ChildClientIDs={authState.user.ChildClientIDs}
          UserID={authState.user.UserID}
          authState={authState}
          labState={authState.user.LabState}
          onCreateOrderTest={handleCreateOrderTest}
        />
      )}
      {authState.user.LoginType === "PrivateLabel" && (
        <div className="private-label-container">
          <PrivateLabelMainPage
            onCreateOrderCOA={handleCreateOrderCOA}
            onDownloadPurchasedCOA={handleDownloadPurchasedCOA}
          />
        </div>
      )}
    </React.Fragment>
  );
};

DashboardPage.propTypes = {};

export default DashboardPage;
