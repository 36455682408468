import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useQuery } from "@apollo/client";

import { ALL_TEST_CATEGORIES_QUERY } from "../../graphql";

const TestCategorySelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
}) => {
  const { data: getTestCategoriesData } = useQuery(ALL_TEST_CATEGORIES_QUERY, {
    variables: { orderBy: { Name: "asc" } },
  });

  const testCategories = getTestCategoriesData?.findManyTestCategories;

  const indicateRequired = required && (value === "" || value === null);

  const handleChange = (e) => {
    onChange(
      e.target.name,
      setValue(e.target.value ? parseInt(e.target.value, 10) : "")
    );
  };

  return (
    <Control expanded>
      <Label>{label}</Label>
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(testCategories) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          required={required}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          <Select.Option value="" />
          {Array.isArray(testCategories) &&
            testCategories.map((testCategory) => (
              <Select.Option
                key={testCategory.TestCategoryID}
                value={testCategory.TestCategoryID}
              >
                {testCategory.Name}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

TestCategorySelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  required: PropTypes.bool,
};

TestCategorySelect.defaultProps = {
  value: "",
  name: "",
  label: "Test Category",
  size: "small",
  required: false,
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
};

export default TestCategorySelect;
