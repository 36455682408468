import React from "react";
import PropTypes from "prop-types";

import { Input, Field, Control, Label, Help } from "rbx";

import { StateSelect, FileUploader } from "../../../../../../components";

const PrivateLabelOrderPageForm = ({ inputs, onChange, disabled }) => (
  <React.Fragment>
    <Field className="grid-override is-three" kind="group">
      <Control expanded>
        <Label>Company</Label>
        <Input
          disabled={disabled}
          name="Company"
          placeholder="Company"
          type="text"
          value={inputs.Company}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Address</Label>
        <Input
          disabled={disabled}
          name="Address"
          placeholder="Address"
          type="text"
          value={inputs.Address}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Address 2</Label>
        <Input
          disabled={disabled}
          name="Address2"
          placeholder="Address 2"
          type="text"
          value={inputs.Address2}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field className="grid-override is-three" kind="group">
      <Control expanded>
        <Label>City</Label>
        <Input
          disabled={disabled}
          name="City"
          placeholder="City"
          type="text"
          value={inputs.City}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>State</Label>
        <StateSelect
          disabled={disabled}
          name="State"
          size="normal"
          value={inputs.State}
          onChange={onChange}
        />
      </Control>
      <Control expanded>
        <Label>Zip</Label>
        <Input
          disabled={disabled}
          name="Zip"
          placeholder="Zip"
          type="text"
          value={inputs.Zip}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>

    <Field>
      <Control expanded>
        <Label>Optional Logo</Label>
        <FileUploader
          disabled={disabled}
          name="ClientLogo"
          size="normal"
          value={inputs.ClientLogo}
          onChange={onChange}
        />
        <Help>
          Provided image must be 140x60 or the same ratio. Image size must be
          less than 1MB
        </Help>
      </Control>
    </Field>
  </React.Fragment>
);

PrivateLabelOrderPageForm.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
PrivateLabelOrderPageForm.defaultProps = {
  inputs: {},
  onChange: () => {},
  disabled: false,
};
export default PrivateLabelOrderPageForm;
