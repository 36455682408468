import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

import PaymentLedgerPage from "./PaymentLedgerPage";

const PaymentLedgerRouter = ({ routePermissions }) => (
  <Switch>
    <Route path="/accounting/payment-ledger">
      <PaymentLedgerPage routePermissions={routePermissions} />
    </Route>
  </Switch>
);

PaymentLedgerRouter.propTypes = {
  routePermissions: PropTypes.array,
};

PaymentLedgerRouter.defaultProps = {
  routePermissions: [],
};

export default PaymentLedgerRouter;
