/* eslint-disable react/prop-types */
import React from "react";
import { format } from "date-fns";
import { Tag } from "rbx";
import { STATUS_COLORS } from "../../../../constants";
import { TableCell } from "../../../../components/DataTable";

const DEFAULT_COLUMNS = [
  {
    Header: "User",
    id: "Username",
    accessor: "User.Username",
  },
  {
    Header: "Job ID",
    id: "JobID",
    type: "number",
    accessor: "JobID",
  },
  {
    Header: "Order ID",
    id: "JobOrderID",
    type: "number",
    accessor: "JobOrderID",
    Cell: ({ cell }) => (
      <TableCell.Link
        to={`/job-receipt/${cell.row.original.JobID}`}
        value={cell.value}
      />
    ),
  },
  {
    Header: "Order Name",
    id: "OrderName",
    accessor: "OrderName",
    Cell: ({ cell, row }) => (
      <TableCell.Wrapped
        value={row.original?.OverrideData?.OrderName || cell.value}
      />
    ),
  },
  {
    Header: "Batch Number",
    id: "BatchOrderNumber",
    accessor: "BatchOrderNumber",
    Cell: ({ cell, row }) =>
      row.original?.OverrideData?.BatchOrderNumber || cell.value,
  },
  {
    Header: "Create Date",
    id: "OrderCreateDate",
    accessor: "OrderCreateDate",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM-dd-yyyy") : "",
  },
  {
    Header: "Receive Date",
    id: "OrderReceiveDate",
    accessor: "OrderReceiveDate",
    disableSortBy: true,
    Cell: ({ cell }) =>
      cell?.value ? (
        format(new Date(cell.value), "MM-dd-yyyy")
      ) : (
        <Tag color="warning">Not Received Yet</Tag>
      ),
  },
  {
    Header: "Tests",
    id: "TestsCount",
    accessor: "TestsCount",
    disableSortBy: true,
  },
  {
    Header: "Shipping",
    id: "Shipping",
    accessor: "Job.ShippingSystemCode.CodeDescription",
    Cell: ({ cell, row }) =>
      row.original.LegacyUserID ? (
        <span />
      ) : (
        <Tag color={STATUS_COLORS.Shipping[cell.value]}>{cell.value}</Tag>
      ),
    disableSortBy: true,
  },
  {
    Header: "Payment",
    id: "PaymentStatus",
    accessor: "Job.Payment.CodeDescription",
    Cell: ({ cell, row }) =>
      row.original.LegacyUserID ? (
        <span />
      ) : (
        <Tag color={STATUS_COLORS.Payment[cell.value]}>{cell.value}</Tag>
      ),
    disableSortBy: true,
  },
  {
    Header: "Status",
    id: "JobOrderStatus",
    accessor: "JobOrderStatus.CodeDescription",
    Cell: ({ cell, row }) =>
      row.original.LegacyUserID ? (
        <Tag color={STATUS_COLORS.Status.Legacy}>Legacy Order</Tag>
      ) : (
        <Tag color={STATUS_COLORS.Status[cell.value]}>{cell.value}</Tag>
      ),
    disableSortBy: true,
  },
];

export default DEFAULT_COLUMNS;
