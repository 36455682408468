import React from "react";
import PropTypes from "prop-types";
import { Icon, Box, Column, Button, Field, Control, Generic } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { QCSampleTypeSelect, BooleanInput } from "../../../../../../components";
import { TestItem, BatchResultSampleColumn } from "../../components";
import BuildBatchResultTemplate from "../../BuildBatchResults";
import "../../BatchRecords.scss";

const Batch = ({
  editing,
  handleAddRow,
  handleDeleteTest,
  handleMoveTestDown,
  handleMoveTestUp,
  handleSelectTest,
  handleSessionBlur,
  handleSessionIdEnterKeyPress,
  handleTestsChange,
  inputs,
  references,
  selectedTestResult,
  batchResults,
  handleQCTypeChange,
  qcSampleTypeID,
  handleAddQCRow,
  BatchID,
  labState,
  handleInputsChange,
}) => {
  const singleResult = BuildBatchResultTemplate(
    batchResults,
    labState,
    selectedTestResult
  )[0];
  const {
    singleResultTemplate,
    includesFailedAnalyte,
    hasTargetValues,
    includesQualifierAlert,
    missingRequiredField,
    isMicro,
  } = singleResult;

  return (
    <Column.Group className="batch-container">
      <Column
        size={editing && isMicro ? 8 : 6}
        style={{ marginLeft: "auto", marginRight: "auto" }}
      >
        <Box className="tests-container">
          <Generic
            className={`test-item-container ${
              editing
                ? `${isMicro ? "title-grid-4" : "title-grid-3"}`
                : `${isMicro ? "title-grid-3" : "title-grid-2"}`
            }
      `}
          >
            <b className="center">Order Name</b>
            <b className="center">Session ID</b>
            {isMicro && <b className="center">Test Name</b>}
            {editing && <b className="center">Actions</b>}
          </Generic>
          {inputs?.Tests?.map((test, index) => (
            <TestItem
              // eslint-disable-next-line react/no-array-index-key
              key={`key-${index}`}
              checkIfSessionIdExists={handleSessionBlur}
              editing={editing}
              index={index}
              inputs={test}
              isMicro={isMicro}
              references={references}
              selectedTest={selectedTestResult[0]}
              showMS={!isMicro}
              showSD={
                (isMicro &&
                  !(
                    inputs?.TestCategory?.Code?.includes("MICRO BMX") &&
                    labState === "AZ"
                  )) ||
                !!inputs?.Tests?.find(
                  (sample) => sample.OrderName === "Sample Duplicate"
                )
              }
              onChange={handleTestsChange}
              onDelete={handleDeleteTest}
              onEnterKeyPress={handleSessionIdEnterKeyPress}
              onMoveRowDown={handleMoveTestDown}
              onMoveRowUp={handleMoveTestUp}
              onSelectTest={handleSelectTest}
            />
          ))}
          {editing && (
            <React.Fragment>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <Field kind="group">
                  <Button
                    color="primary"
                    size="small"
                    style={{ marginRight: "1rem" }}
                    onClick={handleAddRow}
                  >
                    <Icon>
                      <FontAwesomeIcon icon="plus" />
                    </Icon>
                    <span>Add Order Sample</span>
                  </Button>
                  {!isMicro && (
                    <Control id="reprep-btn-group" size="small">
                      <Button
                        color="primary"
                        size="small"
                        onClick={handleAddQCRow}
                      >
                        <Icon>
                          <FontAwesomeIcon icon="plus" />
                        </Icon>
                        <span>Add QC Sample</span>
                      </Button>
                      <QCSampleTypeSelect
                        name="QCSampleTypeID"
                        value={qcSampleTypeID}
                        onChange={handleQCTypeChange}
                      />
                    </Control>
                  )}
                  <Control>
                    <BooleanInput
                      label="Ready For Loading"
                      name="ReadyForLoading"
                      value={inputs.ReadyForLoading}
                      onChange={handleInputsChange}
                    />
                  </Control>
                </Field>
              </div>
              {isMicro && (
                <div className="qc-note">
                  * QC Samples will be added automatically
                </div>
              )}
            </React.Fragment>
          )}
        </Box>
      </Column>
      {(selectedTestResult[0]?.JobOrderTestSample?.SessionID ||
        selectedTestResult[0]?.QCSample?.SessionID) &&
        !editing && (
          <Column size={6}>
            <Box
              id="batch-results-container"
              style={{ height: "100%", overflow: "hidden" }}
            >
              <Column.Group
                className="result-columns"
                style={{
                  height: "100%",
                  paddingTop: "3rem",
                  paddingLeft: "4rem",
                  paddingRight: "4rem",
                  overflowX: "hidden",
                }}
              >
                <Column className="header-column" size={6}>
                  {singleResultTemplate.map((row) =>
                    !row.ref ? (
                      <div
                        key={row.label}
                        className={`result-item ${row.class || ""}`}
                      >
                        <b>{row.label}</b>
                        <span style={{ float: "right" }}>
                          {row.units || ""}
                        </span>
                      </div>
                    ) : (
                      <div
                        key={row.label}
                        className="result-item single-divider"
                      >
                        <b>{row.label}</b>
                        <span style={{ float: "right" }}>
                          {row.units || ""}
                        </span>
                      </div>
                    )
                  )}
                </Column>
                <Column className="results-column" size={6}>
                  <BatchResultSampleColumn
                    disabled
                    BatchID={BatchID}
                    batchResults={batchResults}
                    hasTargetValues={hasTargetValues}
                    includesFailedAnalyte={includesFailedAnalyte}
                    includesQualifierAlert={includesQualifierAlert}
                    isMicro={isMicro}
                    missingRequiredField={missingRequiredField}
                    sample={selectedTestResult[0]}
                    template={singleResultTemplate}
                  />
                </Column>
              </Column.Group>
            </Box>
          </Column>
        )}
    </Column.Group>
  );
};
Batch.propTypes = {
  editing: PropTypes.bool,
  handleAddRow: PropTypes.func,
  handleDeleteTest: PropTypes.func,
  handleMoveTestDown: PropTypes.func,
  handleMoveTestUp: PropTypes.func,
  handleSelectTest: PropTypes.func,
  handleSessionBlur: PropTypes.func,
  handleSessionIdEnterKeyPress: PropTypes.func,
  handleTestsChange: PropTypes.func,
  inputs: PropTypes.object,
  references: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  selectedTestResult: PropTypes.array,
  batchResults: PropTypes.array,
  handleQCTypeChange: PropTypes.func,
  qcSampleTypeID: PropTypes.string,
  handleAddQCRow: PropTypes.func,
  BatchID: PropTypes.number.isRequired,
  labState: PropTypes.string.isRequired,
  handleInputsChange: PropTypes.func,
};

Batch.defaultProps = {
  editing: true,
  handleAddRow: (x) => x,
  handleDeleteTest: (x) => x,
  handleMoveTestDown: (x) => x,
  handleMoveTestUp: (x) => x,
  handleSelectTest: (x) => x,
  handleSessionBlur: (x) => x,
  handleSessionIdEnterKeyPress: (x) => x,
  handleTestsChange: (x) => x,
  inputs: {},
  selectedTestResult: [],
  batchResults: [],
  handleQCTypeChange: (x) => x,
  qcSampleTypeID: "",
  handleAddQCRow: (x) => x,
  handleInputsChange: (x) => x,
};

export default Batch;
