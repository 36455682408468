import React from "react";
import { Switch, Route } from "react-router-dom";

import CartPage from "./CartPage";
import ConfirmationPage from "./ConfirmationPage";

const CheckoutRouter = (props) => (
  <Switch>
    <Route path="/cart/confirmation">
      <ConfirmationPage />
    </Route>
    <Route path="/cart">
      <CartPage />
    </Route>
  </Switch>
);

export default CheckoutRouter;
