import React, { useState, useEffect } from "react";
import { useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import { Title, Box } from "rbx";
import { ResponsiveBar } from "@nivo/bar";
import { startOfDay } from "date-fns";
import { useAuth } from "../../../../context";
import {
  PageHeader,
  DataTable,
  IconButton,
  Loader,
} from "../../../../components";
import { downloadCSV } from "../../../../utils";
import {
  ALL_JOB_ORDERS_QUERY,
  JOB_ORDERS_TAT_GROUP_QUERY,
  JOB_ORDERS_DASHBOARD_ANALYTICS_QUERY,
} from "../../../../graphql";
import {
  AnalyticsBox,
  DashboardFilters,
  DEFAULT_COLUMNS,
  OVERDUE_COLUMNS,
} from "./components";
import "./EmployeeMainPage.scss";

const INITIAL_WHERE = {
  JobOrderStatus: {
    is: {
      CodeDescription: {
        not: { in: ["Completed", "Cancelled"] },
      },
    },
  },
  OrderReceiveDate: {
    not: null,
  },
  OrderDueDate: {
    not: null,
  },
};
const EmployeeMainPage = () => {
  const client = useApolloClient();
  const { state: authState } = useAuth();
  const [labID, setLabID] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [where, setWhere] = useState(INITIAL_WHERE);
  const orderBy = [{ id: "OrderDueDate", asc: true }];

  const { data: JobOrderDashboardAnalyticsData } = useQuery(
    JOB_ORDERS_DASHBOARD_ANALYTICS_QUERY
  );
  const [getOrdersTatGraphData, { data: resultOrdersTatGraphData }] =
    useLazyQuery(JOB_ORDERS_TAT_GROUP_QUERY);

  // default lab filter based on user permissions
  useEffect(() => {
    setLabID(authState.user.LabID);
    if (authState.user.LabID !== "9999") {
      setWhere((prev) => ({
        ...prev,
        Job: {
          is: {
            LabID: {
              equals: parseInt(authState.user.LabID, 10),
            },
          },
        },
      }));
    }
  }, [authState]);

  // fetch data for bat graph
  useEffect(() => {
    getOrdersTatGraphData({
      variables: {
        where,
      },
      fetchPolicy: "network-only",
    });
  }, [where, getOrdersTatGraphData]);

  const handleFiltersReset = () => {
    if (labID.toString() !== "9999") {
      setWhere({
        ...INITIAL_WHERE,
        Job: {
          is: {
            LabID: {
              equals: parseInt(labID, 10),
            },
          },
        },
      });
    } else {
      setWhere(INITIAL_WHERE);
    }
  };

  const handleFilterChange = (name, value) => {
    setWhere((prev) => {
      if (value) {
        if (name === "UserID") {
          return {
            ...prev,
            UserID: {
              equals: value,
            },
          };
        }
        if (name === "LabID") {
          return {
            ...prev,
            Job: {
              is: { LabID: { equals: value } },
            },
          };
        }
        if (name === "ProductCategory") {
          return {
            ...prev,
            ProductCategory: {
              equals: value,
            },
          };
        }
      }

      return Object.keys(prev)
        .filter((x) => x !== name)
        .reduce((a, c) => ({ ...a, [c]: prev[c] }), {});
    });
  };

  if (!JobOrderDashboardAnalyticsData?.findManyJobOrdersDashboardAnalytics) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <div id="employee-main-page">
        <div className="dashboard-analytics">
          <AnalyticsBox
            title="Overdue"
            value={
              JobOrderDashboardAnalyticsData
                ?.findManyJobOrdersDashboardAnalytics?.Overdue || 0
            }
          />
          <AnalyticsBox
            title="New"
            value={
              JobOrderDashboardAnalyticsData
                ?.findManyJobOrdersDashboardAnalytics?.New || 0
            }
          />
          <AnalyticsBox
            title="In Progress"
            value={
              JobOrderDashboardAnalyticsData
                ?.findManyJobOrdersDashboardAnalytics?.InProcess || 0
            }
          />
          <AnalyticsBox
            title="Average TAT"
            units="hrs"
            value={
              JobOrderDashboardAnalyticsData
                ?.findManyJobOrdersDashboardAnalytics?.AverageTAT || 0
            }
          />
        </div>
        <div id="employee-dash-main-section">
          <PageHeader title="Dashboard">
            <IconButton
              icon="filter"
              onClick={(e) => {
                e.preventDefault();
                setShowFilters((prev) => !prev);
              }}
            />
          </PageHeader>
          <div id="employee-dash-graph-container">
            {showFilters && (
              <DashboardFilters
                canSearchByLab={labID.toString() === "9999"}
                inputs={where}
                onChange={handleFilterChange}
                onReset={handleFiltersReset}
              />
            )}
            <Box id="employee-dash-graph">
              <ResponsiveBar
                animate
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Days Old",
                  legendPosition: "middle",
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Orders",
                  legendPosition: "middle",
                  legendOffset: -40,
                  format: (e) => Math.floor(e) === e && e,
                }}
                axisRight={null}
                axisTop={null}
                colors="gradientA"
                data={
                  resultOrdersTatGraphData?.findManyJobOrdersGroupedByTAT || []
                }
                defs={[
                  {
                    id: "blueGradient",
                    type: "linearGradient",
                    colors: [
                      { offset: 0, color: "hsl(204, 86%, 75%)" },
                      { offset: 100, color: "hsl(204, 86%, 53%)" },
                    ],
                  },
                  {
                    id: "redGradient",
                    type: "linearGradient",
                    colors: [
                      { offset: 0, color: "hsl(354, 70%, 70%)" },
                      { offset: 100, color: "hsl(354, 70%, 50%)" },
                    ],
                  },
                ]}
                fill={[
                  { match: (d) => d.index >= 4, id: "redGradient" },
                  { match: "*", id: "blueGradient" },
                ]}
                indexBy="DaysOld"
                keys={["Orders"]}
                labelSkipHeight={12}
                labelSkipWidth={12}
                margin={{ top: 10, right: 10, bottom: 50, left: 50 }}
                motionDamping={15}
                motionStiffness={90}
                padding={0.2}
                theme={{
                  fontSize: 16,
                  axis: {
                    legend: {
                      text: {
                        fontWeight: "bold",
                        fontSize: 18,
                      },
                    },
                  },
                }}
                tooltip={({ indexValue, value }) => (
                  <div className="graph-tooltip">
                    <div>{`${indexValue} ${
                      indexValue === "1" ? "day old" : "days old"
                    }:`}</div>
                    <div className="graph-tooltip-total">{`${value} orders`}</div>
                  </div>
                )}
              />
            </Box>
          </div>
        </div>
      </div>
      <Title className="table-title">
        Overdue
        <IconButton
          icon="file-export"
          onClick={() =>
            downloadCSV(
              client,
              {
                query: ALL_JOB_ORDERS_QUERY,
                where: {
                  ...where,
                  OrderDueDate: {
                    lte: startOfDay(new Date()),
                  },
                },
                orderByMulti: orderBy,
              },
              "findManyJobOrders",
              OVERDUE_COLUMNS
            )
          }
        />
      </Title>
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateJobOrders"
        columns={OVERDUE_COLUMNS}
        name="findManyJobOrders"
        orderBy={orderBy}
        query={ALL_JOB_ORDERS_QUERY}
        where={{
          ...where,
          OrderDueDate: {
            lte: startOfDay(new Date()),
          },
        }}
      />
      <br />
      <Title className="table-title">
        Not Yet Overdue
        <IconButton
          icon="file-export"
          onClick={() =>
            downloadCSV(
              client,
              {
                query: ALL_JOB_ORDERS_QUERY,
                where: {
                  ...where,
                  OrderDueDate: {
                    gte: startOfDay(new Date()),
                  },
                },
                orderByMulti: orderBy,
              },
              "findManyJobOrders",
              DEFAULT_COLUMNS
            )
          }
        />
      </Title>
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateJobOrders"
        columns={DEFAULT_COLUMNS}
        name="findManyJobOrders"
        orderBy={orderBy}
        query={ALL_JOB_ORDERS_QUERY}
        where={{
          ...where,
          OrderDueDate: {
            gte: startOfDay(new Date()),
          },
        }}
      />
    </React.Fragment>
  );
};
EmployeeMainPage.propTypes = {};

export default EmployeeMainPage;
