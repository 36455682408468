import { Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { homogeneityProductCategoryCodeDescriptions } from "../../../constants";

const COLUMNS = [
  {
    Header: "Code Name",
    id: "CodeName",
    accessor: "CodeName",
  },
  {
    Header: "Type",
    id: "Type",
    accessor: "Type",
  },
  {
    Header: "Category",
    id: "Category",
    accessor: "Category",
  },
  {
    Header: "Code Description",
    id: "CodeDescription",
    accessor: "CodeDescription",
  },
  {
    Header: "Code Id",
    id: "CodeId",
    accessor: "CodeId",
  },
  {
    Header: "Homogeneity",
    id: "Homogeneity",
    accessor: "CodeDescription",
    Cell: (cell) =>
      homogeneityProductCategoryCodeDescriptions.includes(cell?.value) ? (
        <Icon>
          <FontAwesomeIcon icon="check" />
        </Icon>
      ) : (
        ""
      ),
    disableSortBy: true,
  },
  {
    Header: "Active",
    id: "Active",
    accessor: "Active",
    Cell: ({ cell }) => (cell?.value ? "Active" : "Inactive"),
  },
];

export default COLUMNS;
