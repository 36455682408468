import { gql } from "@apollo/client";

export const CREATE_EQUIPMENT_TEST_MUTATION = gql`
  mutation CREATE_EQUIPMENT_TEST_MUTATION(
    $data: EquipmentTestsCreateInput!
  ) {
    createEquipmentTests(data: $data) {
      EquipmentTestID
      EquipmentID
      TestID
    }
  }
`;

export const UPDATE_EQUIPMENT_TEST_MUTATION = gql`
  mutation UPDATE_EQUIPMENT_TEST_MUTATION(
    $data: EquipmentTestsCreateInput!
  ) {
    createEquipmentTests(data: $data) {
      EquipmentTestID
      EquipmentID
      TestID
    }
  }
`;

export const DELETE_EQUIPMENT_TEST_MUTATION = gql`
  mutation DELETE_EQUIPMENT_TEST_MUTATION(
    $where: EquipmentTestsWhereUniqueInput!
  ) {
    deleteEquipmentTests(where: $where) {
      EquipmentTestID
    }
  }
`;
