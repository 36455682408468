import jobRoutes from "./jobOrders";
import clientRoutes from "./clients";
import employeeRoutes from "./employees";
import labRoutes from "./labs";
import accountingRoutes from "./accounting";
import couponRoutes from "./coupons";
import creditLedgerRoutes from "./creditLedger";
import testRoutes from "./tests";
// import packageRoutes from "./packages";
import limsRoutes from "./lims";
import systemCodesRoutes from "./systemCodes";
import userGroupRoutes from "./userGroups";
import equipmentRoutes from "./equipment";
import sampleQueueRoutes from "./sampleQueues";
import legacyJobOrderRoutes from "./legacyJobOrders";
import dataExportRoutes from "./dataExport";

export const routesArr = [
  limsRoutes,
  jobRoutes,
  sampleQueueRoutes,
  clientRoutes,
  employeeRoutes,
  labRoutes,
  equipmentRoutes,
  accountingRoutes,
  creditLedgerRoutes,
  couponRoutes,
  testRoutes,
  // packageRoutes,
  userGroupRoutes,
  systemCodesRoutes,
  legacyJobOrderRoutes,
  dataExportRoutes,
];

export default routesArr;
