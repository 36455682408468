import {
  summedAnalytes,
  inhalableCategories,
  microCfuConversionConstants,
  cannSummedAnalyteRules,
  labelClaimThresholdProductCategoryCodeDescriptions,
} from "./batchConstants";
import { productCategoryMatrix } from "../constants";

import { lowercaseKeys } from "./index";

export const CalculateMatrixPercentRecovery = (
  result,
  resultToCompare,
  target
) => {
  if (!target) return NaN;
  const percRecovery = ((result - resultToCompare) * 100) / target;
  return parseFloat(percRecovery.toFixed(3));
};

export const CalculatePercentRecovery = (result, target) => {
  if (!target) return NaN;
  const percRecovery = (result / target) * 100;
  return parseFloat(percRecovery.toFixed(3));
};

export const CalculateRelativePercentDifference = (x, y) => {
  if (x === y) {
    return 0;
  }
  return parseFloat(((Math.abs(x - y) / ((x + y) / 2)) * 100).toFixed(3));
};

export const FindAnalyteRawResult = (
  sample,
  testCategoryCode,
  analyteName,
  labState
) => {
  if (testCategoryCode.includes("MICRO")) {
    return Number.isNaN(parseFloat(sample?.AnalyteResults?.[analyteName]))
      ? sample?.AnalyteResults?.[analyteName] || ""
      : parseFloat(sample?.AnalyteResults?.[analyteName])?.toFixed(5) || "";
  }

  const summedAnalyte = summedAnalytes.find(
    (obj) =>
      obj.testCategoryCode === testCategoryCode &&
      (!obj.state || obj.state === labState) &&
      obj.sumAnalyteName === analyteName
  );

  const rawResult = summedAnalyte
    ? summedAnalyte?.addendAnalyteNames?.reduce((acc, addendAnalyteName) => {
        if (
          sample?.AnalyteResults?.[addendAnalyteName] ||
          sample?.AnalyteResults?.[addendAnalyteName] === 0
        ) {
          return (
            parseFloat(acc || 0) +
            parseFloat(sample?.AnalyteResults?.[addendAnalyteName]?.toFixed(5))
          ).toFixed(5);
        }
        return acc;
      }, "")
    : sample?.AnalyteResults?.[analyteName]?.toFixed(5) || "";

  return rawResult;
};

export const CalculateAnalyteResult = (
  sample,
  testCategoryCode,
  analyteName,
  labState
) => {
  let calculatedResult = null;
  const volume = sample?.Volume;
  const dilution = sample?.Dilution;
  const weight = sample?.Weight;

  const rawResult = FindAnalyteRawResult(
    sample,
    testCategoryCode,
    analyteName,
    labState
  );
  // return raw result for all QCSamples other than sample duplicate
  if (sample?.QCSample && sample?.QCSampleType?.Name !== "Sample Duplicate") {
    calculatedResult = !Number.isNaN(parseFloat(rawResult))
      ? parseFloat(rawResult).toFixed(3)
      : rawResult || null;
  }

  //  MICRO MG ENUM checks analyte for matrix and converts from cq to cfu/g
  // all other MICRO uses raw result
  else if (testCategoryCode.includes("MICRO")) {
    if (testCategoryCode === "MICRO MG ENUM") {
      if (rawResult && !Number.isNaN(parseFloat(rawResult))) {
        const productCategory =
          sample?.JobOrder?.OverrideDataCodeDescriptions &&
          JSON.parse(sample?.JobOrder?.OverrideDataCodeDescriptions)
            ?.ProductCategory
            ? JSON.parse(sample?.JobOrder?.OverrideDataCodeDescriptions)
                ?.ProductCategory
            : sample?.JobOrder?.Category?.CodeDescription || null;
        const matrix = Object.keys(productCategoryMatrix).find((key) =>
          productCategoryMatrix[key].includes(productCategory)
        );

        const cfuConversionObject = microCfuConversionConstants.find(
          (obj) => obj.matrix === matrix && obj.analyte === analyteName
        );

        if (cfuConversionObject) {
          if (analyteName === "total yeast and mold") {
            calculatedResult = (
              10 **
                (cfuConversionObject.constant1 * parseFloat(rawResult) +
                  cfuConversionObject.constant2) *
              cfuConversionObject.constant3
            ).toFixed();
          } else {
            calculatedResult = (
              10 **
              ((cfuConversionObject.constant1 - parseFloat(rawResult)) /
                cfuConversionObject.constant2)
            ).toFixed();
          }
        } else {
          calculatedResult = rawResult;
        }
      } else {
        calculatedResult = rawResult;
      }
    } else {
      calculatedResult = rawResult;
    }
  }

  // else check test category and calculate result if the required values exist
  else if (testCategoryCode === "SOLV") {
    if (!!rawResult && !!dilution && !!weight) {
      calculatedResult = ((rawResult * dilution) / weight).toFixed(3);
    }
  } else if (testCategoryCode === "METAL") {
    if (!!rawResult && !!volume && !!dilution && !!weight) {
      if (labState === "AZ") {
        calculatedResult = (
          (rawResult * volume * dilution) /
          weight /
          1000
        ).toFixed(3);
      } else
        calculatedResult = ((rawResult * volume * dilution) / weight).toFixed(
          3
        );
    }
  } else if (testCategoryCode === "TERP") {
    if (!!rawResult && !!volume && !!dilution && !!weight) {
      calculatedResult = (
        (rawResult * volume * dilution) /
        weight /
        10000
      ).toFixed(3);
    }
  } else if (
    testCategoryCode === "CANN" ||
    testCategoryCode === "CE" ||
    testCategoryCode.includes("PEST")
  ) {
    if (!!rawResult && !!volume && !!dilution && !!weight) {
      if (testCategoryCode.includes("MYCO") && analyteName.includes("atoxin")) {
        calculatedResult = ((rawResult * volume * dilution) / weight).toFixed(
          3
        );
      } else {
        calculatedResult = (
          (rawResult * volume * dilution) /
          weight /
          1000
        ).toFixed(3);
      }
    }
  }
  return calculatedResult;
};

export const calculateTerpTotals = (calculatedAnalyteRows) => {
  let totalTerpenes = 0;
  const valByAnalyte = {};
  calculatedAnalyteRows.forEach((row) => {
    if (row.calculatedResult) {
      if (parseFloat(row.displayValue)) {
        totalTerpenes += parseFloat(row.calculatedResult);
      }
      valByAnalyte[row.label] = row.displayValue || 0;
    }
  });
  if (!totalTerpenes && calculatedAnalyteRows.length) {
    totalTerpenes = !calculatedAnalyteRows.find(
      (row) => row.displayValue === "<LOQ"
    )
      ? calculatedAnalyteRows.find((row) => row.displayValue === "ND")
          ?.displayValue || null
      : "<LOD";
    return totalTerpenes;
  }
  return parseFloat(totalTerpenes?.toFixed(3));
};

const calculateCannabinoidTotal = (
  primaryAnalyteValue,
  secondaryAnalyteValue,
  factor = 0.877
) => {
  // if values exist, calculate. otherwise check for <LOQ or ND
  if (parseFloat(primaryAnalyteValue) || parseFloat(secondaryAnalyteValue)) {
    return parseFloat(
      (
        (parseFloat(primaryAnalyteValue) || 0) +
        (parseFloat(secondaryAnalyteValue) || 0) * factor
      ).toFixed(3)
    );
  }
  if (primaryAnalyteValue === "<LOQ" || secondaryAnalyteValue === "<LOQ") {
    return "<LOQ";
  }
  if (
    (primaryAnalyteValue === "ND" &&
      (!secondaryAnalyteValue || secondaryAnalyteValue === "ND")) ||
    (!primaryAnalyteValue && secondaryAnalyteValue === "ND")
  ) {
    return "ND";
  }
  return null;
};

export const calculateCannTotals = (
  sample,
  labState,
  calculatedAnalyteRows
) => {
  const weight =
    sample?.JobOrder?.OverrideData?.Weight ||
    sample?.JobOrder?.DisplayUnit?.Weight;
  const density =
    sample?.JobOrder?.OverrideData?.Density ||
    sample?.JobOrder?.DisplayUnit?.Density ||
    1;
  const units =
    sample?.JobOrder?.OverrideData?.Units ||
    sample?.JobOrder?.DisplayUnit?.Units;
  const productCategory =
    sample?.JobOrder?.OverrideDataCodeDescriptions &&
    JSON.parse(sample?.JobOrder?.OverrideDataCodeDescriptions)?.ProductCategory
      ? JSON.parse(sample?.JobOrder?.OverrideDataCodeDescriptions)
          ?.ProductCategory
      : sample.JobOrder?.Category?.CodeDescription || "";
  const isInhalable =
    productCategory &&
    !!inhalableCategories.find((item) => item === productCategory);
  const orderLabelClaim =
    sample?.JobOrder?.ActualLabelClaims?.reduce(
      (a, lc) => ({
        ...a,
        [lc.Analyte.Name]: lc.LabelClaim,
      }),
      []
    ) || [];

  let totalCannabinoids = 0;
  let threshold = 10;
  let includesFailedLabelClaim = false;
  const valByCannabinoid = {};

  if (
    labelClaimThresholdProductCategoryCodeDescriptions.find(
      (item) => item === productCategory
    )
  ) {
    threshold = 15;
  }
  if (labState === "AZ") {
    threshold = 20;
  }

  // ** total cannabinoids ** //
  calculatedAnalyteRows.forEach((row) => {
    if (row.calculatedResult) {
      if (parseFloat(row.displayValue)) {
        totalCannabinoids += parseFloat(row.calculatedResult);
      }
      valByCannabinoid[row.label] = row.displayValue || 0;
    }
  });

  let totalCannabinoidsPerUnit =
    weight && totalCannabinoids
      ? (totalCannabinoids * weight * density).toFixed(3)
      : "";
  let totalCannabinoidsPerPackage =
    weight && units && totalCannabinoids
      ? (totalCannabinoids * weight * density * units).toFixed(3)
      : "";

  if (!totalCannabinoids && calculatedAnalyteRows.length) {
    totalCannabinoids = !calculatedAnalyteRows.find(
      (row) => row.displayValue === "<LOQ"
    )
      ? calculatedAnalyteRows.find((row) => row.displayValue === "ND")
          ?.displayValue || null
      : "<LOD";
    totalCannabinoidsPerUnit = totalCannabinoids;
    totalCannabinoidsPerPackage = totalCannabinoids;
  }

  // ** all label claims ** //
  const allLabelClaim = Object.entries(orderLabelClaim).map(
    ([name, actualLabelClaim]) => {
      let actualLabelClaimPercent = null;
      // sum analytes for total thc and cbd regardless of product category
      const shouldUseSummation = isInhalable || /THC|CBD/.test(name);

      const addendAnalyte = shouldUseSummation
        ? cannSummedAnalyteRules.find(({ sumAnalyte }) => sumAnalyte === name)
            ?.addendAnalyte
        : null;

      const resultLabelClaim = calculateCannabinoidTotal(
        valByCannabinoid[name] || 0,
        addendAnalyte ? valByCannabinoid[addendAnalyte] : null,
        cannSummedAnalyteRules.find(({ sumAnalyte }) => sumAnalyte === name)
          ?.factor
      );

      const totalPerUnit = parseFloat(resultLabelClaim)
        ? parseFloat((resultLabelClaim * weight * density).toFixed(1))
        : resultLabelClaim;
      const totalPerPackage = parseFloat(resultLabelClaim)
        ? parseFloat((resultLabelClaim * weight * density * units).toFixed(1))
        : resultLabelClaim;

      if (actualLabelClaim && parseFloat(totalPerPackage)) {
        actualLabelClaimPercent = Math.abs(
          ((actualLabelClaim - totalPerPackage) / actualLabelClaim) * 100
        );
      }
      let lcClass = "";
      if (
        (parseFloat(actualLabelClaimPercent) ||
          parseFloat(actualLabelClaimPercent) === 0) &&
        parseFloat(actualLabelClaimPercent) <= threshold
      ) {
        lcClass = " pass";
      } else if (actualLabelClaim && resultLabelClaim) {
        lcClass = " fail";
        includesFailedLabelClaim = true;
      }

      return {
        analyte: name,
        resultLabelClaim,
        actualLabelClaim,
        totalPerUnit,
        totalPerPackage,
        lcClass,
      };
    }
  );

  return {
    totalCannabinoidsPerUnit,
    totalCannabinoidsPerPackage,
    allLabelClaim,
    includesFailedLabelClaim,
  };
};

const calculatePercentRelativeStandardDeviation = (sampleTotals) => {
  const samplesCount = sampleTotals.length;
  const sum = sampleTotals.reduce((curr, a) => curr + a, 0);
  let variance = 0.0;

  // calculate the average of sample totals
  const average = sum / samplesCount;

  if (average === 0 || samplesCount === 1) {
    return 0;
  }

  // calculayte the sum of squares,
  // total difference between each number and average squared
  sampleTotals.forEach((sampleTotal) => {
    variance += Math.pow(sampleTotal - average, 2);
  });

  // calculate the standard deviation
  const standardDeviation = parseFloat(
    Math.sqrt(variance / (samplesCount - 1))
  );

  // calculate sd/average*100
  return parseFloat((Math.abs(standardDeviation / average) * 100).toFixed(1));
};

export const calculateHomogeneityTotals = (homogeneityTestsData) => {
  const totals = { "d9-THC": [], CBD: [] };

  // find constants for JobOrder to use for all samples
  const JobOrder = homogeneityTestsData[0]?.JobOrder;

  const weight =
    JobOrder?.OverrideData?.Weight || JobOrder?.DisplayUnit?.Weight;

  const units = JobOrder?.OverrideData?.Units || JobOrder?.DisplayUnit?.Units;

  const density =
    JobOrder?.OverrideData?.Density || JobOrder?.DisplayUnit?.Density || 1;

  const productCategory =
    JobOrder?.OverrideDataCodeDescriptions &&
    JSON.parse(JobOrder?.OverrideDataCodeDescriptions)?.ProductCategory
      ? JSON.parse(JobOrder?.OverrideDataCodeDescriptions)?.ProductCategory
      : JobOrder?.Category?.CodeDescription || "";

  const isInhalable =
    productCategory &&
    !!inhalableCategories.find((item) => item === productCategory);

  const orderLabelClaim = JobOrder?.ActualLabelClaims?.reduce(
    (a, lc) => ({
      ...a,
      [lc.Analyte.Name]: lc.LabelClaim,
    }),
    { "d9-THC": null, CBD: null }
  ) || { "d9-THC": null, CBD: null };

  // for each homogeneity JobOrderTest, find the associated results to use for calculations
  homogeneityTestsData.forEach((JobOrderTest) => {
    const batchJobOrderTestSample = JobOrderTest.Batch?.TestSamples?.find(
      (testSample) =>
        testSample?.JobOrderTestSample?.JobOrderTest?.JobOrderTestID ===
          JobOrderTest?.JobOrderTestID &&
        testSample?.JobOrderTestSample?.PrepNo === JobOrderTest?.ResultToDisplay
    );
    const sample = {
      ...batchJobOrderTestSample,
      ...batchJobOrderTestSample?.JobOrderTestSample,
      AnalyteResults: lowercaseKeys(
        batchJobOrderTestSample?.JobOrderTestSample?.Result
      ),
    };

    Object.keys(orderLabelClaim).forEach((name) => {
      const addendAnalyte = isInhalable
        ? cannSummedAnalyteRules.find(({ sumAnalyte }) => sumAnalyte === name)
            ?.addendAnalyte
        : null;

      const resultLabelClaim = calculateCannabinoidTotal(
        parseFloat(
          CalculateAnalyteResult(sample, "CANN", name.toLowerCase(), null)
        ) || 0,
        addendAnalyte
          ? parseFloat(
              CalculateAnalyteResult(
                sample,
                "CANN",
                addendAnalyte.toLowerCase(),
                null
              )
            )
          : null,
        cannSummedAnalyteRules.find(({ sumAnalyte }) => sumAnalyte === name)
          ?.factor
      );

      const totalPerPackage = parseFloat(resultLabelClaim)
        ? parseFloat((resultLabelClaim * weight * density * units).toFixed(1))
        : resultLabelClaim;

      totals[name] = totals[name]
        ? [...totals[name], totalPerPackage]
        : [totalPerPackage];
    });
  });

  return Object.entries(totals).reduce((acc, [analyte, values]) => {
    const percentRelativeStandardDeviation =
      calculatePercentRelativeStandardDeviation(values);
    // if sd is 0 or no label claim provided, result should be N/A
    // if sd is < 25 then it passes
    // if sd is >= 25 then it fails
    let passFail = "";
    if (percentRelativeStandardDeviation === 0 || !orderLabelClaim[analyte]) {
      passFail = "N/A";
    } else {
      passFail = percentRelativeStandardDeviation < 25 ? "Pass" : "Fail";
    }
    const dislayName = analyte === "d9-THC" ? "THC" : analyte;
    return {
      ...acc,
      [dislayName]: {
        "Label Claim": orderLabelClaim[analyte] || "N/A",
        "Action Limit": "<25%",
        Result: `${percentRelativeStandardDeviation}%`,
        "Pass/Fail": passFail,
      },
    };
  }, {});
};
