/* eslint-disable max-lines-per-function */
import React from "react";
import PropTypes from "prop-types";
import { Column, Field, Input, Label, Control, Box, Textarea } from "rbx";
import OrderTabs from "../OrderTabs";
import { formatCurrency } from "../../../../../../utils";

import "./IntakeForm.scss";

const IntakeForm = ({
  inputs,
  onChange,
  disabled,
  JobID,
  JobOrderID,
  handleOrderChange,
  history,
  uploadJobPath,
  uploadJobOrderPath,
  updatePerUnitCalculation,
  onAddTestToJobOrder,
  onJobOrderTestApprove,
  onOpenResultModal,
  disableIntakeStatus,
  hasMadeChanges,
  handleConfirmChanges,
  substanceIDs,
  JobData,
  onInternalNoteSubmit,
}) => (
  <Column.Group multiline>
    <Column className="read-only-display-wrapper">
      <Box>
        <Column
          className="read-only-display"
          desktop={{ size: 6 }}
          tablet={{ size: 12 }}
        >
          <Field kind="group">
            <Control expanded size="small">
              <Label>Client Username</Label>
              <Input
                disabled
                required
                autoComplete="off"
                maxLength={250}
                name="User.Username"
                placeholder="Username"
                size="small"
                value={inputs.User.Username}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded size="small">
              <Label>Client Company</Label>
              <Input
                disabled
                required
                autoComplete="off"
                maxLength={250}
                name="User.Company"
                placeholder="Company"
                size="small"
                value={inputs.User.Company}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded size="small">
              <Label>Client Fullname</Label>
              <Input
                disabled
                required
                autoComplete="off"
                maxLength={250}
                name="User.Fullname"
                placeholder="Company"
                size="small"
                value={`${inputs.User.FirstName} ${inputs.User.LastName}`}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded size="small">
              <Label>Client Notes</Label>
              <Textarea
                disabled
                required
                name="User.Notes.ClientNotes"
                size="small"
                value={inputs.User.Notes?.ClientNotes}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded size="small">
              <Label>Test Notes</Label>
              <Textarea
                disabled
                required
                name="User.Notes.TestNotes"
                size="small"
                value={inputs.User.Notes?.TestNotes}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
        </Column>
        <Column
          className="read-only-display"
          desktop={{ size: 6 }}
          tablet={{ size: 12 }}
        >
          <Field kind="group">
            <Control expanded size="small">
              <Label>JobID</Label>
              <Input
                disabled
                required
                autoComplete="off"
                maxLength={250}
                name="JobID"
                placeholder="JobID"
                size="small"
                value={inputs.JobID}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded size="small">
              <Label>Payment Type</Label>
              <Input
                disabled
                required
                autoComplete="off"
                maxLength={250}
                name="PaymentType"
                placeholder="Payment Type"
                size="small"
                value={inputs.PaymentType}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded size="small">
              <Label>Job Total</Label>
              <Input
                disabled
                required
                autoComplete="off"
                maxLength={250}
                name="Total"
                placeholder="Total"
                size="small"
                value={formatCurrency(inputs.Total)}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded size="small">
              <Label>Credit Used</Label>
              <Input
                disabled
                required
                autoComplete="off"
                maxLength={250}
                name="Credit"
                size="small"
                value={inputs.Credit}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded size="small">
              <Label>Coupon Type</Label>
              <Input
                disabled
                required
                autoComplete="off"
                maxLength={250}
                name="CouponID"
                placeholder="CouponID"
                size="small"
                type="text"
                value={inputs.Coupon?.Type}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded size="small">
              <Label>Coupon ID</Label>
              <Input
                disabled
                required
                autoComplete="off"
                maxLength={250}
                name="Coupon.CouponID"
                size="small"
                type="number"
                value={inputs.Coupon?.CouponID}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded size="small">
              <Label>Coupon Code</Label>
              <Input
                disabled
                required
                autoComplete="off"
                maxLength={250}
                name="Coupon.CouponCode"
                size="small"
                value={inputs.Coupon?.Code}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded size="small">
              <Label>Discount</Label>
              <Input
                disabled
                required
                autoComplete="off"
                maxLength={250}
                name="Coupon.Discount"
                size="small"
                value={
                  inputs.Coupon?.Type === "%"
                    ? `${inputs.Coupon.Percent}%`
                    : formatCurrency(inputs.Coupon?.Discount)
                }
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
        </Column>
      </Box>
    </Column>
    <Column className="order-info-column" size={12}>
      <OrderTabs
        JobData={JobData}
        JobID={JobID}
        JobOrderID={JobOrderID}
        disableIntakeStatus={disableIntakeStatus}
        disabled={disabled}
        handleConfirmChanges={handleConfirmChanges}
        hasMadeChanges={hasMadeChanges}
        history={history}
        inputs={inputs}
        substanceIDs={substanceIDs}
        updatePerUnitCalculation={updatePerUnitCalculation}
        uploadJobOrderPath={uploadJobOrderPath}
        onAddTestToJobOrder={onAddTestToJobOrder}
        onChange={handleOrderChange}
        onInternalNoteSubmit={onInternalNoteSubmit}
        onJobOrderTestApprove={onJobOrderTestApprove}
        onOpenResultModal={onOpenResultModal}
      />
    </Column>
  </Column.Group>
);

IntakeForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  handleOrderChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  JobID: PropTypes.string.isRequired,
  JobOrderID: PropTypes.string,
  history: PropTypes.object.isRequired,
  uploadJobPath: PropTypes.string.isRequired,
  uploadJobOrderPath: PropTypes.string.isRequired,
  updatePerUnitCalculation: PropTypes.func,
  onAddTestToJobOrder: PropTypes.func,
  onJobOrderTestApprove: PropTypes.func,
  onOpenResultModal: PropTypes.func,
  disableIntakeStatus: PropTypes.bool.isRequired,
  hasMadeChanges: PropTypes.bool.isRequired,
  handleConfirmChanges: PropTypes.func.isRequired,
  substanceIDs: PropTypes.object,
  JobData: PropTypes.object,
  onInternalNoteSubmit: PropTypes.func,
};

IntakeForm.defaultProps = {
  disabled: false,
  JobOrderID: null,
  updatePerUnitCalculation: () => null,
  onAddTestToJobOrder: () => null,
  onJobOrderTestApprove: () => null,
  onOpenResultModal: () => null,
  substanceIDs: {},
  JobData: {},
  onInternalNoteSubmit: () => null,
};

export default IntakeForm;
