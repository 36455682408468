import React from "react";

import { Switch, Route } from "react-router-dom";

import PrivateLabelCommissionsPage from "./PrivateLabelCommissionsPage";

const PrivateLabelCommissionsRouter = () => (
  <Switch>
    <Route path="/accounting/private-label-commissions">
      <PrivateLabelCommissionsPage />
    </Route>
  </Switch>
);

export default PrivateLabelCommissionsRouter;
