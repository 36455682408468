import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const ALL_USER_GROUP_PERMISSIONS_QUERY = gql`
  query ALL_USER_GROUP_PERMISSIONS_QUERY(
    $where: UserGroupPermissionsWhereInput
    $orderBy: [UserGroupPermissionsOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyUserGroupPermissions(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      UserGroupPermissionID
      GroupID
      PermissionID
      CreatedDateTime
      ModifiedDateTime
      CreatedBy
      ModifiedBy
      Update
      View
    }
  }
`;
