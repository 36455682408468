import React from "react";
import PropTypes from "prop-types";

import { Switch, Route } from "react-router-dom";

import InQueueSamplesListPage from "./InQueueSamplesList";
import InQueueReprepSamplesList from "./InQueueReprepSamplesList";
import RejectedSamples from "./RejectedSamples";

const SampleQueuesRouter = ({ routePermissions }) => (
  <Switch>
    <Route path="/sample-queues/in-queue-samples">
      <InQueueSamplesListPage />
    </Route>
    <Route path="/sample-queues/in-queue-reprep-samples">
      <InQueueReprepSamplesList />
    </Route>
    <Route path="/sample-queues/rejected-samples">
      <RejectedSamples routePermissions={routePermissions} />
    </Route>
  </Switch>
);

SampleQueuesRouter.propTypes = {
  routePermissions: PropTypes.array,
};

SampleQueuesRouter.defaultProps = {
  routePermissions: [],
};

export default SampleQueuesRouter;
