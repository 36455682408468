import React from "react";
import PropTypes from "prop-types";
import { Tab, Tag } from "rbx";

import OrderForm from "../OrderForm";
import { STATUS_COLORS } from "../../../../../JobOrders/JobOrdersListPage/columns";

const OrderTabs = ({
  JobID,
  JobOrderID,
  history,
  onChange,
  inputs,
  disabled,
  uploadJobOrderPath,
  disableIntakeStatus,
  hasMadeChanges,
  handleConfirmChanges,
  substanceIDs,
  JobData,
  onInternalNoteSubmit,
}) => {
  const JobOrderSelected =
    (JobOrderID &&
      inputs.JobOrders.find(
        (jobOrder) =>
          parseInt(jobOrder.JobOrderID, 10) === parseInt(JobOrderID, 10)
      )) ||
    null;

  const JobOrders =
    (inputs.JobOrders &&
      Array.isArray(inputs.JobOrders) &&
      inputs.JobOrders.length > 0 &&
      inputs.JobOrders) ||
    null;

  return (
    <div key="job-order-tabs">
      <Tab.Group className="intake-order-tabs" kind="boxed">
        {JobOrders &&
          JobOrders.map((jobOrder) => (
            <Tab
              key={jobOrder.JobOrderID}
              active={JobOrderID === jobOrder.JobOrderID}
              as={Tag}
              color={
                STATUS_COLORS.Status[jobOrder.JobOrderStatus.CodeDescription]
              }
              onClick={
                hasMadeChanges
                  ? () => {
                      handleConfirmChanges(
                        JobOrderSelected,
                        jobOrder.JobOrderID
                      );
                      history.push(
                        `/lims/intake/${JobID}/${jobOrder.JobOrderID}`
                      );
                    }
                  : () =>
                      history.push(
                        `/lims/intake/${JobID}/${jobOrder.JobOrderID}`
                      )
              }
            >
              {jobOrder.JobOrderID}
            </Tab>
          ))}
      </Tab.Group>
      {JobOrderSelected && (
        <OrderForm
          JobData={JobData}
          disableIntakeStatus={disableIntakeStatus}
          disabled={disabled}
          inputs={inputs.JobOrders.find(
            (jobOrder) =>
              parseInt(jobOrder.JobOrderID, 10) === parseInt(JobOrderID, 10)
          )}
          substanceIDs={substanceIDs}
          uploadJobOrderPath={uploadJobOrderPath}
          user={inputs.User}
          onChange={onChange}
          onInternalNoteSubmit={onInternalNoteSubmit}
        />
      )}
    </div>
  );
};

OrderTabs.propTypes = {
  JobID: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  inputs: PropTypes.object.isRequired,
  JobOrderID: PropTypes.string,
  uploadJobOrderPath: PropTypes.string.isRequired,
  disableIntakeStatus: PropTypes.bool.isRequired,
  hasMadeChanges: PropTypes.bool.isRequired,
  handleConfirmChanges: PropTypes.func.isRequired,
  substanceIDs: PropTypes.object,
  JobData: PropTypes.object,
  onInternalNoteSubmit: PropTypes.func,
};

OrderTabs.defaultProps = {
  JobOrderID: null,
  substanceIDs: {},
  JobData: {},
  onInternalNoteSubmit: () => null,
};

export default OrderTabs;
