import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Column, Control, Field, Input, Label, Button } from "rbx";
import { SystemCodeSelect } from "../../../../components";
import Table from "../../../../components/DataTable/Table";
import "../../../../components/DataTable/DataTable.scss";
import COLUMNS from "./columns";

const MMTCInfo = ({ inputs, data, onChange, onSave, onDelete, setInputs }) => {
  const [mmtcData, setMmtcData] = useState([]);

  useEffect(() => {
    if (data) {
      setMmtcData(
        Object.keys(data).map((key) => ({
          id: key,
          type:
            data[key]?.Item === "Cultivators" ? "Cultivar" : data[key]?.Item,
          name: data[key]?.Name,
        }))
      );
    }
  }, [data]);

  return (
    <React.Fragment>
      <Column size={12}>
        {inputs && (
          <Field kind="group">
            <Control expanded>
              <SystemCodeSelect
                label="Item to add"
                name="Item"
                size="small"
                value={inputs?.Item}
                where={{
                  Type: { equals: "User" },
                  Category: { equals: "MMTC" },
                  CodeName: { equals: "Item" },
                }}
                onChange={onChange}
              />
            </Control>
            <Control expanded>
              <Label>Name</Label>
              <Input
                name="Name"
                size="small"
                value={inputs?.Name}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control>
              <Label>&nbsp;</Label>
              <Button
                color="primary"
                disabled={!inputs?.Name || !inputs?.Item}
                size="small"
                type="button"
                onClick={onSave}
              >
                <span>Save</span>
              </Button>
            </Control>
          </Field>
        )}
      </Column>
      <div className="table-section">
        <Table
          columns={COLUMNS(onDelete)}
          data={mmtcData}
          onRowClick={(row) => {
            setInputs({
              id: row.id,
              Item: row.type,
              Name: row.name,
            });
          }}
        />
      </div>
    </React.Fragment>
  );
};

MMTCInfo.propTypes = {
  inputs: PropTypes.object,
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  setInputs: PropTypes.func,
};
MMTCInfo.defaultProps = {
  inputs: {},
  onChange: () => null,
  onSave: () => null,
  onDelete: () => null,
  setInputs: () => null,
};

export default MMTCInfo;
