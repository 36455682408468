const perUnitCategories = [
  "Baked Goods",
  "Bath Bomb",
  "Bath Salts",
  "Beverage",
  "Body Oil",
  "Capsule/Tablet",
  "Chocolate",
  "Edible/Food",
  "Gummy",
  "Hard Lozenge",
  "Ice Cream",
  "Ingestible - Other",
  "Lotion/Salve/Balm",
  "Orally-Dissolving Product",
  "Patch",
  "Pet/Animal Products",
  "Soft Chew",
  "Squeeze",
  "Suppository",
  "Syrup/Honey",
  "THC Drops",
  "Tincture",
  "Topical - Other",
  "Transdermal Patch",
];

// check to see if JobOrderTestSample has all required fields based on the test category
const checkRequiredFields = (sample, testCategoryCode, labState) => {
  if (
    !sample.SessionID ||
    (!sample.Volume &&
      sample.Volume !== 0 &&
      !testCategoryCode.includes("MICRO") &&
      testCategoryCode !== "SOLV") ||
    (!sample.Weight && sample.Weight !== 0) ||
    (!sample.Dilution &&
      sample.Dilution !== 0 &&
      (!testCategoryCode.includes("MICRO") ||
        testCategoryCode.includes("MICRO 3M")))
  ) {
    return true;
  }
  if (testCategoryCode === "CANN" || testCategoryCode === "CE") {
    // Cann requires order values. Always check for override data first
    const weight =
      sample.JobOrder?.OverrideData?.Weight ||
      sample.JobOrder?.DisplayUnit?.Weight;
    const units =
      sample.JobOrder?.OverrideData?.Units ||
      sample.JobOrder?.DisplayUnit?.Units;
    const productCategory =
      sample?.JobOrder?.OverrideDataCodeDescriptions &&
      JSON.parse(sample?.JobOrder?.OverrideDataCodeDescriptions)
        ?.ProductCategory
        ? JSON.parse(sample?.JobOrder?.OverrideDataCodeDescriptions)
            ?.ProductCategory
        : sample.JobOrder?.Category.CodeDescription;

    if (
      (!weight || !units) &&
      !!perUnitCategories.find((category) => category === productCategory) &&
      labState !== "AZ" // AZ never requires this sample weight and units
    ) {
      return true;
    }
    const density =
      sample.JobOrder?.OverrideData?.Density ||
      sample.JobOrder?.DisplayUnit?.Density;
    if (
      (productCategory === "Beverage" || productCategory === "Tincture") &&
      !density
    ) {
      return true;
    }
  }
  return false;
};

export default checkRequiredFields;
