import { format } from "date-fns";
import { formatCurrency } from "../../../utils";

const DEFAULT_COLUMNS = [
  {
    Header: "Date",
    id: "CreditDate",
    accessor: "CreditDate",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM-dd-yyyy") : "",
  },
  {
    Header: "Client",
    id: "Client",
    accessor: "User.Username",
    disableSortBy: true,
  },
  {
    Header: "Created By",
    id: "CreatedBy",
    accessor: "CreatedBy",
  },
  {
    Header: "Credit Remaining",
    id: "Credit",
    accessor: "Credit",
    Cell: ({ cell }) =>
      cell?.value ? formatCurrency(cell.value) : formatCurrency(0),
  },
  {
    Header: "Reason",
    id: "Reason",
    accessor: "Reason",
  },
  {
    Header: "Lab",
    id: "LabID",
    accessor: "User.Lab.Company",
  },
];

export default DEFAULT_COLUMNS;
