import { gql } from "@apollo/client";

export const ALL_EQUIPMENT_ATTACHMENTS_QUERY = gql`
  query ALL_EQUIPMENT_ATTACHMENTS_QUERY(
    $where: EquipmentAttachmentsWhereInput
    $orderBy: [EquipmentAttachmentsOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyEquipmentAttachments(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      EquipmentAttachmentID
      EquipmentID
      Description
      AttachmentURL
      CreatedBy
      ModifiedBy
      CreatedDateTime
      ModifiedDateTime
    }
  }
`;

export const SINGLE_EQUIPMENT_ATTACHMENT_QUERY = gql`
  query SINGLE_EQUIPMENT_ATTACHMENT_QUERY($where: EquipmentAttachmentsWhereUniqueInput!) {
    findUniqueEquipmentAttachments(where: $where) {
      EquipmentAttachmentID
      EquipmentID
      Description
      AttachmentURL
      CreatedBy
      ModifiedBy
      CreatedDateTime
      ModifiedDateTime
    }
  }
`;
