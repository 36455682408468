import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation, useLazyQuery } from "@apollo/client";
import { Title, Fieldset, Button } from "rbx";
import { customToast as toast } from "../../../../utils";
import SystemCodeForm from "../SystemCodeForm";
import Loader from "../../../../components/Loader";
import {
  CREATE_SYSTEM_CODE_MUTATION,
  LIST_SYSTEM_CODES_QUERY,
  ALL_SYSTEM_CODES_QUERY,
  SINGLE_SYSTEM_CODE_QUERY,
  UPDATE_SYSTEM_CODE_MUTATION,
} from "../../../../graphql";

const INITIAL_STATE = {
  CodeId: "",
  CodeDescription: "",
  CodeName: "",
  Category: "",
  Type: "",
  Active: 1,
};

const convertInputToVariables = (variables, keys, adding = true) =>
  keys.reduce((acc, curr) => {
    if (variables[curr]) {
      acc[curr] = variables[curr];
    } else {
      acc[curr] = null;
    }
    return acc;
  }, {});

const SystemCodeModal = ({ onComplete, RecId }) => {
  const [loading, setLoading] = useState(!!RecId);
  const [codeUnique] = useState(true);
  const [createSystemCode] = useMutation(CREATE_SYSTEM_CODE_MUTATION);
  const [updateSystemCode] = useMutation(UPDATE_SYSTEM_CODE_MUTATION);

  const [inputs, setInputs] = useState({
    ...INITIAL_STATE,
  });

  const [getSystemCodeData, resultSystemCodeData] = useLazyQuery(
    SINGLE_SYSTEM_CODE_QUERY
  );

  useEffect(() => {
    if (RecId) {
      getSystemCodeData({
        variables: {
          where: { RecId: parseInt(RecId, 10) },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [RecId, getSystemCodeData]);

  useEffect(() => {
    if (resultSystemCodeData?.data?.findUniqueSystemCodes) {
      const {
        data: { findUniqueSystemCodes },
      } = resultSystemCodeData;
      setInputs((prev) => ({
        ...prev,
        ...findUniqueSystemCodes,
      }));
      setLoading(false);
    }
  }, [resultSystemCodeData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (codeUnique) {
        if (RecId) {
          const obj = Object.keys(INITIAL_STATE).reduce((acc, curr) => {
            if (inputs[curr] !== null) {
              return {
                ...acc,
                [curr]: {
                  set: inputs[curr],
                },
              };
            }
            return acc;
          }, {});
          await updateSystemCode({
            variables: {
              data: obj,
              where: {
                RecId: parseInt(RecId, 10),
              },
            },
            refetchQueries: [
              {
                query: ALL_SYSTEM_CODES_QUERY,
                variables: {
                  where: {},
                },
                fetchPolicy: "network-only",
              },
              {
                query: LIST_SYSTEM_CODES_QUERY,
                variables: {
                  where: {},
                },
              },
              {
                query: SINGLE_SYSTEM_CODE_QUERY,
                variables: {
                  where: { RecId: parseInt(RecId, 10) },
                },
                fetchPolicy: "network-only",
              },
            ],
          });

          toast.success("System Code updated successfully.");
        } else {
          await createSystemCode({
            variables: {
              data: {
                ...convertInputToVariables(
                  inputs,
                  Object.keys(INITIAL_STATE),
                  true
                ),
              },
            },
            refetchQueries: [
              {
                query: LIST_SYSTEM_CODES_QUERY,
                variables: {
                  where: {},
                },
              },
              {
                query: ALL_SYSTEM_CODES_QUERY,
                variables: {
                  where: {},
                },
              },
            ],
          });
          toast.success("System Code created successfully.");
        }
        onComplete();
      }
    } catch (err) {
      toast.error(`Error ${RecId ? "updating" : "creating"} System Code.`);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader style={{ height: "330px" }} />;
  }
  return (
    <React.Fragment>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>{RecId ? "Update" : "Create"} System Code</Title>
        </div>
        <div className="page-head-end">
          <Button.Group>
            <Button
              color="primary"
              size="small"
              type="button"
              onClick={onComplete}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={!codeUnique}
              form="system-code-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Fieldset>
        <SystemCodeForm
          adding
          disabled={!!RecId}
          formId="system-code-form"
          inputs={inputs}
          onChange={handleChange}
          onSubmit={handleSave}
        />
      </Fieldset>
      <hr />
    </React.Fragment>
  );
};

SystemCodeModal.propTypes = {
  onComplete: PropTypes.func,
  RecId: PropTypes.string,
};

SystemCodeModal.defaultProps = {
  onComplete: (e) => e,
  RecId: null,
};

export default SystemCodeModal;
