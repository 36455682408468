/* eslint-disable react/prop-types */
import React from "react";
import { Tag, Icon } from "rbx";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { STATUS_COLORS } from "../../../../../constants";

const getColor = (value) => {
  const colorToReturn =
    STATUS_COLORS.BatchStatus[value] || STATUS_COLORS.BatchStatus.N;
  return colorToReturn;
};

const COLUMNS = [
  {
    Header: "Batch ID",
    id: "BatchID",
    type: "number",
    accessor: "BatchID",
    disableSortBy: true,
  },
  {
    Header: "Name",
    id: "Name",
    type: "number",
    accessor: "Name",
    disableSortBy: true,
  },
  {
    Header: "User",
    id: "User.Username",
    accessor: "User.Username",
    disableSortBy: true,
  },
  {
    Header: "Category",
    id: "TestCategoryID",
    accessor: "TestCategory.Code",
    disableSortBy: true,
  },
  {
    Header: "Tests",
    id: "Tests",
    accessor: "JobOrderTestSamples",
    disableSortBy: true,
    Cell: ({ cell }) => (cell?.value?.length ? cell.value.length : 0),
  },
  {
    Header: "Created Date",
    id: "CreateDate",
    accessor: "CreateDate",
    disableSortBy: true,
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM-dd-yyyy") : "",
  },
  {
    Header: "Status",
    id: "Status",
    accessor: "BatchStatus",
    disableSortBy: true,
    Cell: ({ cell }) =>
      cell.value ? (
        <Tag color={getColor(cell.value.CodeId)}>
          {cell.value.CodeDescription}
        </Tag>
      ) : (
        ""
      ),
  },
  {
    Header: "Lab",
    id: "Lab",
    accessor: "Lab.Company",
    disableSortBy: true,
  },
  {
    Header: "Ready For Loading",
    id: "ReadyForLoading",
    accessor: "ReadyForLoading",
    Cell: ({ cell }) =>
      cell.value ? (
        <Icon>
          <FontAwesomeIcon color="green" icon="check" />
        </Icon>
      ) : (
        <Icon>
          <FontAwesomeIcon color="red" icon="ban" />
        </Icon>
      ),
  },
];

export { COLUMNS, getColor };
