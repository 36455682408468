/* eslint-disable react/prop-types */
import React from "react";
import { format } from "date-fns";
import { Tag } from "rbx";
import { TableCell } from "../../../../../components/DataTable";
import { getBusinessDays } from "../../../../../utils";

export const DEFAULT_COLUMNS = [
  {
    Header: "Username",
    id: "Username",
    accessor: "User.Username",
    disableSortBy: true,
  },
  {
    Header: "Job ID",
    id: "JobID",
    type: "number",
    accessor: "Job.JobID",
    Cell: ({ cell }) => (
      <TableCell.Link
        to={`/lims/intake/${cell.value}/${cell.row.original.JobOrderID}`}
        value={cell.value}
      />
    ),
  },
  {
    Header: "Order ID",
    id: "JobOrderID",
    type: "number",
    accessor: "JobOrderID",
    Cell: ({ cell }) => (
      <TableCell.Link
        to={`/job-receipt/${cell.row.original.JobID}`}
        value={cell.value}
      />
    ),
  },
  {
    Header: "Order Name",
    id: "OrderName",
    accessor: "OrderName",
    Cell: ({ cell, row }) => (
      <TableCell.Wrapped
        value={row.original?.OverrideData?.OrderName || cell.value}
      />
    ),
  },
  {
    Header: "Product Category",
    id: "ProductCategory",
    accessor: "ProductCategorySystemCode.CodeDescription",
    disableSortBy: true,
    Cell: ({ cell }) => cell.value || "",
  },
  {
    Header: "Create Date",
    id: "OrderCreateDate",
    accessor: "OrderCreateDate",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM-dd-yyyy") : "",
  },
  {
    Header: "Receive Date",
    id: "OrderReceiveDate",
    accessor: "OrderReceiveDate",
    // disableSortBy: true,
    Cell: ({ cell }) =>
      cell?.value ? (
        format(new Date(cell.value), "MM-dd-yyyy")
      ) : (
        <Tag color="warning">Not Received Yet</Tag>
      ),
  },
  {
    Header: "Due Date",
    id: "OrderDueDate",
    accessor: "OrderDueDate",
    disableSortBy: true,
    Cell: ({ cell }) =>
      cell.value ? format(new Date(cell.value), "MM-dd-yyyy") : "",
  },
  {
    Header: "Remaining Days",
    id: "RemainingDays",
    accessor: "OrderDueDate",
    Cell: ({ cell }) =>
      cell?.value ? getBusinessDays(new Date(), new Date(cell.value)) - 1 : "",
  },
  {
    Header: "Days Old",
    id: "DaysOld",
    accessor: "OrderReceiveDate",
    Cell: ({ cell }) =>
      cell?.value ? getBusinessDays(new Date(cell.value), new Date()) : "",
  },
];

export const OVERDUE_COLUMNS = [
  {
    Header: "Username",
    id: "Username",
    accessor: "User.Username",
    disableSortBy: true,
  },
  {
    Header: "Job ID",
    id: "JobID",
    type: "number",
    accessor: "Job.JobID",
    Cell: ({ cell }) => (
      <TableCell.Link
        to={`/lims/intake/${cell.value}/${cell.row.original.JobOrderID}`}
        value={cell.value}
      />
    ),
  },
  {
    Header: "Order ID",
    id: "JobOrderID",
    type: "number",
    accessor: "JobOrderID",
    Cell: ({ cell }) => (
      <TableCell.Link
        to={`/job-receipt/${cell.row.original.JobID}`}
        value={cell.value}
      />
    ),
  },
  {
    Header: "Order Name",
    id: "OrderName",
    accessor: "OrderName",
    Cell: ({ cell, row }) => (
      <TableCell.Wrapped
        value={row.original?.OverrideData?.OrderName || cell.value}
      />
    ),
  },
  {
    Header: "Product Category",
    id: "ProductCategory",
    accessor: "ProductCategorySystemCode.CodeDescription",
    disableSortBy: true,
    Cell: ({ cell }) => cell.value || "",
  },
  {
    Header: "Create Date",
    id: "OrderCreateDate",
    accessor: "OrderCreateDate",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM-dd-yyyy") : "",
  },
  {
    Header: "Receive Date",
    id: "OrderReceiveDate",
    accessor: "OrderReceiveDate",
    // disableSortBy: true,
    Cell: ({ cell }) =>
      cell?.value ? (
        format(new Date(cell.value), "MM-dd-yyyy")
      ) : (
        <Tag color="warning">Not Received Yet</Tag>
      ),
  },
  {
    Header: "Due Date",
    id: "OrderDueDate",
    accessor: "OrderDueDate",
    disableSortBy: true,
    Cell: ({ cell }) =>
      cell.value ? format(new Date(cell.value), "MM-dd-yyyy") : "",
  },
  {
    Header: "Days Old",
    id: "DaysOld",
    accessor: "OrderReceiveDate",
    Cell: ({ cell }) =>
      cell?.value ? getBusinessDays(new Date(cell.value), new Date()) : "",
  },
];
