import React from "react";
import PropTypes from "prop-types";
import { Control, Input } from "rbx";

import { QualifierMultiSelect } from "../../../../../../components";

const AddQualifiersAnalytestTable = ({ data, onChange, state }) => (
  <div className="main-table-container">
    <hr />
    {data.map((element, index) => (
      <div
        key={element.name}
        className="row-container body"
        style={{
          marginRight: "2rem",
          marginLeft: "2rem",
          gridTemplateColumns: `1fr repeat(1, 1fr)`,
        }}
      >
        <Control>
          <Input readOnly size="small" value={element.name} />
        </Control>
        <QualifierMultiSelect
          name="Qualifiers"
          state={state}
          value={element.value}
          onChange={(name, value) => onChange(index, value)}
        />
      </div>
    ))}
  </div>
);

AddQualifiersAnalytestTable.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
  state: PropTypes.string.isRequired,
};

AddQualifiersAnalytestTable.defaultProps = {
  data: [],
  onChange: () => null,
};
export default AddQualifiersAnalytestTable;
