import React from "react";
import PropTypes from "prop-types";
import { Column, Label, Control, Textarea } from "rbx";

const ClientNotes = ({ inputs, onChange, handleSave, disabled }) => (
  <form id="edit-client-form" onSubmit={handleSave}>
    <Column.Group>
      <Column desktop={{ size: 6 }} tablet={{ size: 12 }}>
        <Control>
          <Label>Client Notes</Label>
          <Textarea
            disabled={disabled}
            name="ClientNotes"
            value={inputs?.Notes?.ClientNotes}
            onChange={(e) => onChange(e.target.name, e.target.value, "Notes")}
          />
        </Control>
      </Column>
      <Column desktop={{ size: 6 }} tablet={{ size: 12 }}>
        <Control>
          <Label>Test Notes</Label>
          <Textarea
            disabled={disabled}
            name="TestNotes"
            value={inputs?.Notes?.TestNotes}
            onChange={(e) => onChange(e.target.name, e.target.value, "Notes")}
          />
        </Control>
      </Column>
    </Column.Group>
  </form>
);

ClientNotes.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  handleSave: PropTypes.func,
  disabled: PropTypes.bool,
};

ClientNotes.defaultProps = {
  inputs: {},
  onChange: (e) => e,
  handleSave: (e) => e,
  disabled: false,
};

export default ClientNotes;
