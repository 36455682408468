import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Title, Fieldset, Button } from "rbx";

import { customToast as toast } from "../../../../utils";
import LabAccountDetailsForm from "../LabAccountDetailsForm";
import Loader from "../../../../components/Loader";
import { LIST_LABS_QUERY, CREATE_LAB_MUTATION } from "../../../../graphql";

const INITIAL_STATE = {
  Company: "",
  CorporateCustomerServiceEmail: "clientservices@greenscientificlabs.com",
  OnsiteCustomerServiceEmail: "",
  Address: "",
  City: "",
  State: "",
  Zip: "",
  Phone: "",
  LicenseNumber: "",
  ShowQualifiers: false,
  LabDirectorOneName: "",
  LabDirectorTwoTitle: "",
  LabDirectorTwoSignature: "",
  LabDirectorTwoName: "",
  LabDirectorOneTitle: "",
  LabDirectorOneSignature: "",
};

const AddLabModal = ({ onComplete }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [createLab] = useMutation(CREATE_LAB_MUTATION);

  const [inputs, setInputs] = useState(INITIAL_STATE);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const {
        data: { createLabs },
      } = await createLab({
        variables: {
          data: inputs,
        },
        refetchQueries: [
          {
            query: LIST_LABS_QUERY,
            variables: {
              where: {},
            },
          },
        ],
      });
      toast.success("Laboratory created successfully.");
      onComplete();
      history.push(`/labs/lab/${createLabs.LabID}/account-details`);
    } catch (err) {
      toast.error("Error creating Laboratory");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader style={{ height: "330px" }} />;
  }
  return (
    <React.Fragment>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Create Laboratory</Title>
        </div>
        <div className="page-head-end">
          <Button.Group>
            <Button
              color="primary"
              size="small"
              type="button"
              onClick={onComplete}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              form="lab-account-details"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Fieldset>
        <LabAccountDetailsForm
          adding
          formId="add-coupon-form"
          inputs={inputs}
          onChange={handleChange}
          onSubmit={handleSave}
        />
      </Fieldset>
      <hr />
    </React.Fragment>
  );
};

AddLabModal.propTypes = {
  onComplete: PropTypes.func,
};

AddLabModal.defaultProps = {
  onComplete: () => null,
};

export default AddLabModal;
