import React from "react";
import PropTypes from "prop-types";
import { Column, Input, Label, Control, Field } from "rbx";

import {
  LabLocationSelect,
  SystemCodeSelect,
  SwitchInput,
} from "../../../../components";
import { debounce } from "../../../../utils";

const EquipmentForm = ({
  inputs,
  onChange,
  disabled,
  onSubmit,
  formId,
  adding,
  isEdit,
  calibrationDueDate,
  maintenanceDueDate,
}) => (
  <form id={formId} onSubmit={onSubmit}>
    <Column.Group multiline>
      <Column size={12}>
        <div
          className={
            isEdit ? "equipment-edit-form-columns" : "equipment-columns"
          }
        >
          <Field>
            <Control expanded size="small">
              <Label>Name / EID</Label>
              <Input
                required
                disabled={disabled}
                maxLength={250}
                name="Name"
                placeholder="Name / EID"
                size="small"
                value={inputs.Name}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field>
            <Control expanded size="small">
              <Label>Manufacturer</Label>
              <Input
                required
                disabled={disabled}
                maxLength={250}
                name="Manufacturer"
                placeholder="Manufacturer"
                size="small"
                value={inputs.Manufacturer}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field>
            <Control expanded size="small">
              <Label>Serial Number</Label>
              <Input
                required
                disabled={disabled}
                maxLength={250}
                name="SerialNumber"
                placeholder="Serial Number"
                size="small"
                value={inputs.SerialNumber}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field>
            <Control expanded size="small">
              <Label>Model Number</Label>
              <Input
                required
                disabled={disabled}
                maxLength={250}
                name="ModelNumber"
                placeholder="Model Number"
                size="small"
                value={inputs.ModelNumber}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field>
            <Control expanded size="small">
              <LabLocationSelect
                required
                disabled={disabled}
                id="LabID"
                label="Lab to Use"
                name="LabID"
                showAllLocation={false}
                size="small"
                value={inputs.LabID}
                onChange={onChange}
              />
            </Control>
          </Field>
          <div className="switch-wrapper">
            <Field>
              <Control size="small">
                <Label>Active</Label>
                <SwitchInput
                  disabled={disabled}
                  name="Active"
                  value={inputs.Active}
                  onChange={(name, e) => onChange(name, e)}
                />
              </Control>
            </Field>
            <Field>
              <Control size="small">
                <Label>Used for Sample Prep</Label>
                <SwitchInput
                  disabled={disabled}
                  name="SamplePrep"
                  value={inputs.SamplePrep}
                  onChange={(name, e) => onChange(name, e)}
                />
              </Control>
            </Field>
          </div>
          <Field>
            <Control expanded size="small">
              <SystemCodeSelect
                required
                disabled={disabled}
                label="Calibration Cycle"
                name="CalibrationCycleID"
                size="small"
                value={inputs.CalibrationCycleID}
                where={{
                  Type: { equals: "User" },
                  Category: { equals: "Equipment" },
                  CodeName: { equals: "Cycle" },
                }}
                onChange={onChange}
              />
            </Control>
          </Field>
          <Field>
            <Control size="small">
              <Label>Last Calibration Date</Label>
              <Input
                defaultValue={inputs.LastCalibrationDate}
                disabled={disabled}
                maxLength={250}
                name="LastCalibrationDate"
                placeholder="Last Calibration Date"
                size="small"
                type="date"
                onChange={debounce(
                  (e) => onChange(e.target.name, e.target.value),
                  3000
                )}
              />
            </Control>
          </Field>

          {isEdit && (
            <Field>
              <Control size="small">
                <Label>Calibration Due Date</Label>
                <Input
                  disabled
                  maxLength={250}
                  name="CalibrationDueDate"
                  placeholder="Calibration Due Date"
                  size="small"
                  type="date"
                  value={calibrationDueDate}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                />
              </Control>
            </Field>
          )}

          <Field>
            <Control expanded size="small">
              <SystemCodeSelect
                required
                disabled={disabled}
                label="Maintenance Cycle"
                name="MaintenanceCycleID"
                size="small"
                value={inputs.MaintenanceCycleID}
                where={{
                  Type: { equals: "User" },
                  Category: { equals: "Equipment" },
                  CodeName: { equals: "Cycle" },
                }}
                onChange={onChange}
              />
            </Control>
          </Field>
          <Field>
            <Control size="small">
              <Label>Last Maintenance Date</Label>
              <Input
                defaultValue={inputs.LastMaintenanceDate}
                disabled={disabled}
                maxLength={250}
                name="LastMaintenanceDate"
                placeholder="Last Maintenance Date"
                size="small"
                type="date"
                onChange={debounce(
                  (e) => onChange(e.target.name, e.target.value),
                  3000
                )}
              />
            </Control>
          </Field>
          {isEdit && (
            <Field>
              <Control size="small">
                <Label>Maintenance Due Date</Label>
                <Input
                  disabled
                  maxLength={250}
                  name="MaintenanceDueDate"
                  placeholder="Maintenance Due Date"
                  size="small"
                  type="date"
                  value={maintenanceDueDate}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                />
              </Control>
            </Field>
          )}
        </div>
      </Column>
    </Column.Group>
  </form>
);

EquipmentForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  formId: PropTypes.string.isRequired,
  adding: PropTypes.bool,
  isEdit: PropTypes.bool,
  calibrationDueDate: PropTypes.string,
  maintenanceDueDate: PropTypes.string,
};

EquipmentForm.defaultProps = {
  onSubmit: () => null,
  disabled: false,
  adding: false,
  isEdit: false,
  calibrationDueDate: "",
  maintenanceDueDate: "",
};

export default EquipmentForm;
