/* eslint-disable max-lines-per-function */
/* eslint-disable react/no-array-index-key */
import React, { useState, useRef, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Field, Control, Input, Button, Icon, Tag, Label, Dropdown } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useApolloClient } from "@apollo/client";

import { ModalContext } from "../../../../../../context/ModalContext";
import { BooleanInput, IconButton } from "../../../../../../components";
import ResultsEditModal from "../ResultsEditModal";
import { customToast as toast } from "../../../../../../utils";
import { useAuth } from "../../../../../../context";
import {
  UPDATE_BATCH_MUTATION,
  CREATE_BATCH_LOG_MUTATION,
  SINGLE_BATCH_QUERY,
  UPDATE_JOB_ORDER_TEST_SAMPLE,
  UPDATE_QC_SAMPLE_MUTATION,
  UPDATE_SAMPLE_SYSTEM_QUALIFIERS_MUTATION,
  ALL_QUALIFIERS_QUERY,
} from "../../../../../../graphql";
import { STATUS_COLORS } from "../../../../../../constants";
import { AssignSystemQualifiers } from "../../BuildBatchResults/BatchFunctions";
import "../../BatchRecords.scss";

const BatchResultSampleColumn = ({
  sample,
  template,
  isFullView,
  tests,
  originalIndex,
  BatchID,
  onChange,
  showQualifiers,
  includesFailedAnalyte,
  includesQualifierAlert,
  missingRequiredField,
  hasTargetValues,
  disabled,
  isMicro,
  batchResults,
  labState,
}) => {
  const client = useApolloClient();
  const { setModalOpen } = useContext(ModalContext);
  const inputRefs = useRef([]);
  const [loading, setLoading] = useState(false);
  const [selectedInput, setSelectedInput] = useState("");
  const [editedValue, setEditedValue] = useState(null);
  const [selectedName, setSelectedName] = useState("");
  const [canRestoreSamples, setCanRestoreSamples] = useState(false);
  const [canEditResults, setCanEditResults] = useState(false);
  const { state: authState } = useAuth();
  const [updateBatch] = useMutation(UPDATE_BATCH_MUTATION);
  const [updateSystemQualifiers] = useMutation(
    UPDATE_SAMPLE_SYSTEM_QUALIFIERS_MUTATION
  );
  const [updateJobOrderTestSample] = useMutation(UPDATE_JOB_ORDER_TEST_SAMPLE);
  const [updateQCSample] = useMutation(UPDATE_QC_SAMPLE_MUTATION);
  const [createBatchLogs] = useMutation(CREATE_BATCH_LOG_MUTATION);

  useEffect(() => {
    if (Array.isArray(authState.permissions) && authState.permissions.length) {
      const foundRest = authState.permissions.find(
        (permission) => permission.Code === "REST_SAMPLES"
      );
      if (foundRest && foundRest.Update) {
        setCanRestoreSamples(true);
      } else {
        setCanRestoreSamples(false);
      }

      const foundEdit = authState.permissions.find(
        (permission) => permission.Code === "EDIT_BATCH_RSLTS"
      );
      if (foundEdit && foundEdit.Update) {
        setCanEditResults(true);
      } else {
        setCanEditResults(false);
      }
    } else {
      setCanRestoreSamples(false);
      setCanEditResults(false);
    }
  }, [authState]);

  const onClickEdit = (e, i, name) => {
    e.preventDefault();
    if (selectedInput && editedValue) {
      setModalOpen(
        true,
        <ResultsEditModal
          onCancel={() => {
            setModalOpen(false);
          }}
          onComplete={(reasonForChange) => {
            setModalOpen(false);
            handleSave(reasonForChange);
          }}
        />
      );
    } else {
      setSelectedName(name);
      inputRefs?.current[i]?.current?.focus();
      setSelectedInput(parseInt(inputRefs?.current[i]?.current?.name, 10));
    }
  };

  inputRefs.current = template.map(() => React.createRef());

  const onChangeInput = (val, i) => {
    setEditedValue(val);
  };

  const onClickInput = (val, i, row) => {
    if (selectedInput && selectedInput !== i && editedValue) {
      setModalOpen(
        true,
        <ResultsEditModal
          onCancel={() => {
            setModalOpen(false);
          }}
          onComplete={(reasonForChange) => {
            setModalOpen(false);
            handleSave(reasonForChange);
          }}
        />
      );
    }
  };

  const onCancelInput = () => {
    setSelectedInput("");
    setSelectedName("");
    setEditedValue(null);
  };

  const findInputValue = (row, i) => {
    if (selectedInput === i && editedValue !== null) {
      return editedValue;
    }
    if (row.func) {
      if (row.func(sample)) {
        return row.func(sample);
      }
      if (row.func(sample) === 0) {
        return 0;
      }
    }
    if (row.displayValue) {
      if (row.displayValue) {
        return row.displayValue;
      }
      if (row.displayValue === 0) {
        return 0;
      }
    }
    if (sample[row.field]) {
      return sample[row.field];
    }
    if (sample[row.field] === 0) {
      return 0;
    }
    return "";
  };

  const handleKeyDown = (e, i, name) => {
    if (e.key === "Enter") {
      if (selectedInput && editedValue) {
        setModalOpen(
          true,
          <ResultsEditModal
            onCancel={() => {
              setModalOpen(false);
            }}
            onComplete={(reasonForChange) => {
              setModalOpen(false);
              handleSave(reasonForChange);
            }}
          />
        );
      } else {
        setSelectedName(name);
        inputRefs?.current[i]?.current?.focus();
        setSelectedInput(parseInt(inputRefs?.current[i]?.current?.name, 10));
      }
    }
  };

  const handleSelectDropdownItem = (val) => {
    setEditedValue(val);
    setModalOpen(
      true,
      <ResultsEditModal
        onCancel={() => {
          setModalOpen(false);
        }}
        onComplete={(reasonForChange) => {
          setModalOpen(false);
          handleSave(reasonForChange, val);
        }}
      />
    );
  };

  const handleSave = async (reasonForChange, dropdownValue = null) => {
    setLoading(true);
    const query = {
      query: SINGLE_BATCH_QUERY,
      variables: {
        where: {
          BatchID: parseInt(BatchID, 10),
        },
      },
      fetchPolicy: "network-only",
    };

    const {
      data: { findUniqueBatches },
    } = await client.query(query);

    const testNames = ["Weight", "Volume", "Dilution"];

    if (testNames.includes(selectedName)) {
      const oldValue = tests[originalIndex][selectedName];
      const { SessionID } = tests[originalIndex];
      const changes = `${SessionID}: ${selectedName} updated from ${oldValue} to ${editedValue}`;

      const newTests = {
        ...tests[originalIndex],
        [selectedName]: editedValue,
        UserID: authState.user.UserID,
      };

      const newFullTests = findUniqueBatches.Tests.map((t) =>
        t.SessionID === SessionID ? newTests : t
      );

      try {
        await updateBatch({
          variables: {
            where: {
              BatchID: parseInt(BatchID, 10),
            },
            data: {
              Tests: newFullTests,
            },
          },
          refetchQueries: [query],
        });
        await createBatchLogs({
          variables: {
            data: {
              Change: changes,
              Batches: {
                connect: {
                  BatchID: parseInt(BatchID, 10),
                },
              },
              ReasonForChange: reasonForChange,
              CreatedBy: authState.user.Username,
            },
          },
        });

        saveSystemQualifiers("batch", newTests);

        setSelectedInput("");
        setSelectedName("");
        setEditedValue(null);
        toast.success("Batch updated successfully");
        setLoading(false);
      } catch (err) {
        toast.error("Unable to update Batch record");
        setLoading(false);
      }
    } else {
      const parsedResults =
        sample.JobOrderTestSample?.Result || sample.QCSample?.Result;
      const parsedSelectedName = selectedName.toLowerCase().replace(/\s/g, "");
      const newResults = {};
      let oldValue = "";
      const newValue = dropdownValue || editedValue;

      Object.keys(parsedResults).forEach((key) => {
        if (key.toLowerCase().replace(/\s/g, "") === parsedSelectedName) {
          oldValue = parsedResults[key];
          newResults[key] = Number.isNaN(Number(newValue))
            ? newValue
            : Number(newValue);
        } else {
          newResults[key] = parsedResults[key];
        }
      });
      const changes = `${sample.SessionID}: ${selectedName} updated from ${oldValue} to ${newValue}`;

      try {
        const date = new Date();
        if (sample.JobOrderTestSample) {
          await updateJobOrderTestSample({
            variables: {
              where: {
                JobOrderTestSampleID: parseInt(
                  sample.JobOrderTestSample.JobOrderTestSampleID,
                  10
                ),
              },
              data: {
                Result: newResults,
                ModifiedDateTime: {
                  set: date,
                },
                ModifiedBy: {
                  set: authState?.user?.Username,
                },
              },
            },
            refetchQueries: [query],
          });
        } else {
          await updateQCSample({
            variables: {
              where: {
                QCSampleID: parseInt(sample.QCSample.QCSampleID, 10),
              },
              data: {
                Result: newResults,
                ModifiedDateTime: {
                  set: date,
                },
                ModifiedBy: {
                  set: authState?.user?.Username,
                },
              },
            },
            refetchQueries: [query],
          });
        }
        await createBatchLogs({
          variables: {
            data: {
              Change: changes,
              Batches: {
                connect: {
                  BatchID: parseInt(BatchID, 10),
                },
              },
              ReasonForChange: reasonForChange,
              CreatedBy: authState.user.Username,
            },
          },
        });

        saveSystemQualifiers("sample", newResults);

        setSelectedInput("");
        setSelectedName("");
        setEditedValue(null);
        toast.success("Batch updated successfully");
        setLoading(false);
      } catch (err) {
        toast.error("Unable to update Batch record");
        setLoading(false);
      }
    }
  };

  const saveSystemQualifiers = async (type, newResults) => {
    const query = {
      query: SINGLE_BATCH_QUERY,
      variables: {
        where: {
          BatchID: parseInt(BatchID, 10),
        },
      },
      fetchPolicy: "network-only",
    };
    const {
      data: { findManyQualifiers },
    } = await client.query({
      query: ALL_QUALIFIERS_QUERY,
      variables: {},
    });
    const lowercaseKeys = (obj) => {
      if (!obj) {
        return {};
      }
      return Object.keys(obj).reduce((acc, key) => {
        acc[key.toLowerCase().trim()] = obj[key];
        return acc;
      }, {});
    };
    if (type === "sample") {
      const newAnalyteResults = lowercaseKeys(newResults);
      const newBatchResults = batchResults.map((s) => {
        if (s.SessionID === sample.SessionID) {
          return {
            ...sample,
            Result: newResults,
            AnalyteResults: newAnalyteResults,
          };
        }
        return s;
      });
      const systemQualifiers = AssignSystemQualifiers(
        newBatchResults,
        findManyQualifiers,
        labState
      );
      await updateSystemQualifiers({
        variables: {
          data: {
            SystemQualifiers: systemQualifiers,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [query],
      });
    } else {
      const newBatchResults = batchResults.map((s) => {
        if (s.SessionID === sample.SessionID) {
          return {
            ...sample,
            ...newResults,
          };
        }
        return s;
      });
      const systemQualifiers = AssignSystemQualifiers(
        newBatchResults,
        findManyQualifiers,
        labState
      );
      await updateSystemQualifiers({
        variables: {
          data: {
            SystemQualifiers: systemQualifiers,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [query],
      });
    }
  };

  return (
    <React.Fragment>
      {template.map((row, i) =>
        row.header ? (
          <div
            key={`batch-single-${i}`}
            className={`result-item ${row.class || ""} ${
              loading ? "blur" : ""
            }`}
          >
            <div className="header-buttons">
              {(sample.OriginalStatus === "Approved" ||
                sample.OriginalStatus === "Rejected") && (
                <React.Fragment>
                  <div className="result-status">
                    <Tag
                      color={STATUS_COLORS.ResultStatus[sample.OriginalStatus]}
                      size="medium"
                    >
                      {sample.OriginalStatus}
                    </Tag>
                    <span className="status-by">{` by: ${
                      sample.JobOrderTestSample
                        ? sample.JobOrderTestSample?.ReviewedBy
                        : sample.QCSample?.ReviewedBy || ""
                    }`}</span>
                  </div>
                  {!disabled && canRestoreSamples && (
                    <IconButton
                      color="warning"
                      icon="redo"
                      isSelected={
                        sample.Status === "Restored" && sample.needSave
                      }
                      size="small"
                      tooltipClass="has-tooltip-left has-tooltip-arrow has-tooltip-warning"
                      tooltipText="Restore Sample"
                      onClick={(value) =>
                        onChange(
                          "restore",
                          value,
                          sample,
                          includesFailedAnalyte
                        )
                      }
                    />
                  )}
                </React.Fragment>
              )}
              {(sample.OriginalStatus === "Imported" ||
                sample.OriginalStatus === "Restored") && (
                <React.Fragment>
                  {includesQualifierAlert && (
                    <Control size="small">
                      <Icon
                        className="has-tooltip-right has-tooltip-arrow has-tooltip-warning"
                        color="warning"
                        data-tooltip="Includes Qualifier Alert"
                        size="small"
                      >
                        <FontAwesomeIcon icon="exclamation-triangle" />
                      </Icon>
                    </Control>
                  )}
                  {includesFailedAnalyte && (
                    <Control size="small">
                      <Icon
                        className="has-tooltip-right has-tooltip-arrow has-tooltip-danger"
                        color="danger"
                        data-tooltip="Includes Failed Result"
                        size="small"
                      >
                        <FontAwesomeIcon icon="exclamation" />
                      </Icon>
                    </Control>
                  )}
                  <div className="result-status">
                    <Field kind="group">
                      <Control size="small">
                        <Label style={{ margin: "0px" }}>Approve</Label>
                        <BooleanInput
                          color="cyan"
                          disabled={
                            ((!sample?.JobOrderTestSample?.JobOrder ||
                              !sample?.JobOrderTestSample
                                ?.JobOrderTestSampleID) &&
                              !sample?.QCSample?.QCSampleID) ||
                            disabled ||
                            missingRequiredField
                          }
                          name="approve"
                          value={sample.Status === "Approved"}
                          onChange={(name, value) =>
                            onChange(name, value, sample, includesFailedAnalyte)
                          }
                        />
                      </Control>
                      <Control size="small">
                        <Label style={{ margin: "0px" }}>Reject</Label>
                        <BooleanInput
                          color="error"
                          disabled={
                            ((!sample?.JobOrderTestSample?.JobOrder ||
                              !sample?.JobOrderTestSample
                                ?.JobOrderTestSampleID) &&
                              !sample?.QCSample?.QCSampleID) ||
                            disabled ||
                            missingRequiredField
                          }
                          name="reject"
                          value={sample.Status === "Rejected"}
                          onChange={(name, value) =>
                            onChange(name, value, sample, includesFailedAnalyte)
                          }
                        />
                      </Control>
                    </Field>
                  </div>
                  {/* {hasTargetValues && (
                    <IconButton
                      color="primary"
                      icon="tasks"
                      size="small"
                      tooltipClass="has-tooltip-left has-tooltip-arrow has-tooltip-primary"
                      tooltipText="Edit Target Values"
                    />
                  )} */}
                </React.Fragment>
              )}
              {!sample.OriginalStatus && (
                <div className="result-status">
                  <Tag color={STATUS_COLORS.ResultStatus.New} size="medium">
                    New Sample
                  </Tag>
                </div>
              )}
            </div>
            <div className="header-name">{row.func(sample)[0] || "-"}</div>
            <div className="header-text">{row.func(sample)[1] || " "}</div>
            <div className="header-text">{row.func(sample)[2] || " "}</div>
            <div className="header-text">{row.func(sample)[3] || " "}</div>
            {isMicro && (
              <div className="header-text">{row.func(sample)[4] || " "}</div>
            )}
          </div>
        ) : (
          <Field
            key={`${row.field}-${i}`}
            className={`result-item ${row.class || ""} ${
              !row.displayEditDropdown && selectedInput === i ? "editing" : ""
            } ${loading ? "blur" : ""}`}
            kind="addons"
          >
            {selectedInput === i && row.displayEditDropdown ? (
              <React.Fragment>
                <Control expanded size="small">
                  <Dropdown managed active={selectedInput === i}>
                    <Dropdown.Menu>
                      <Dropdown.Content>
                        {sample.TestCategory?.Code === "MICRO BMX GENUP" ? (
                          <React.Fragment>
                            {findInputValue(row, i) === "Presumed Absence" ? (
                              <Dropdown.Item
                                onClick={() =>
                                  handleSelectDropdownItem("Presumed Presence")
                                }
                              >
                                Presumed Presence
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item
                                onClick={() =>
                                  handleSelectDropdownItem("Presumed Absence")
                                }
                              >
                                Presumed Absence
                              </Dropdown.Item>
                            )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <Dropdown.Item
                              autoFocus
                              as="input"
                              autoComplete="off"
                              disabled={
                                sample.OriginalStatus === "Approved" ||
                                sample.OriginalStatus === "Rejected" ||
                                disabled
                              }
                              maxLength={250}
                              name={i}
                              placeholder="Enter Cq Value"
                              readOnly={selectedInput !== i}
                              size="small"
                              state="focused"
                              type="number"
                              value={
                                findInputValue(row, i) === "No Cq"
                                  ? ""
                                  : findInputValue(row, i)
                              }
                              onChange={(e) => onChangeInput(e.target.value, i)}
                              onClick={(e) =>
                                onClickInput(e.target.value, i, row)
                              }
                              onKeyDown={(e) => handleKeyDown(e, i, row)}
                            />
                            <Dropdown.Divider />
                            <Dropdown.Item
                              onClick={() => handleSelectDropdownItem("No Cq")}
                            >
                              No Cq
                            </Dropdown.Item>
                          </React.Fragment>
                        )}
                        <Dropdown.Item
                          as="icon"
                          onClick={() => onCancelInput()}
                        >
                          <FontAwesomeIcon icon="times" />
                        </Dropdown.Item>
                      </Dropdown.Content>
                    </Dropdown.Menu>
                  </Dropdown>
                </Control>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Control size="small">
                  <Icon color="warning" size="small">
                    {(row.qualifierAlert ||
                      (row.isSummedAnalyte &&
                        template.find(
                          (r) =>
                            r.qualifierAlert &&
                            r.sumAnalyteName === row.isSummedAnalyte
                        ))) && (
                      <FontAwesomeIcon icon="exclamation-triangle" size="xs" />
                    )}
                  </Icon>
                </Control>
                <Control size="small">
                  <Icon
                    className={
                      row.failReason
                        ? "has-tooltip-left has-tooltip-arrow has-tooltip-danger"
                        : ""
                    }
                    color="danger"
                    data-tooltip={row.failReason || null}
                    size="small"
                  >
                    {row.class?.includes("fail") && (
                      <FontAwesomeIcon icon="exclamation" size="xs" />
                    )}
                  </Icon>
                </Control>
                <Control expanded size="small">
                  {row.icon && row.icon(sample) ? (
                    <Icon
                      align="right"
                      color="primary"
                      style={{ width: "100%" }}
                    >
                      <FontAwesomeIcon icon="check" />
                    </Icon>
                  ) : (
                    <Input
                      ref={inputRefs.current[i]}
                      disabled={
                        sample.OriginalStatus === "Approved" ||
                        sample.OriginalStatus === "Rejected" ||
                        disabled
                      }
                      maxLength={250}
                      name={i}
                      readOnly={selectedInput !== i}
                      size="small"
                      type={row.editable && !isMicro ? "number" : "text"}
                      value={findInputValue(row, i)}
                      onChange={(e) => onChangeInput(e.target.value, i)}
                      onClick={(e) => onClickInput(e.target.value, i, row)}
                      onKeyDown={(e) => handleKeyDown(e, i, row)}
                    />
                  )}
                </Control>
                {showQualifiers && row.showQualifiers && (
                  <React.Fragment>
                    {row.qualifiers?.length ? (
                      <Control>
                        <Input
                          readOnly
                          className="primary"
                          value={row.qualifiers || ""}
                        />
                      </Control>
                    ) : null}
                    {row.systemQualifiers?.length ? (
                      <Control>
                        <Input
                          readOnly
                          className="purple"
                          value={row.systemQualifiers || ""}
                        />
                      </Control>
                    ) : null}
                  </React.Fragment>
                )}
                {row.targetValue && (
                  <Control>
                    <Input
                      readOnly
                      className="grey"
                      value={`(${row.targetValue})${
                        row.isSampleSpecificTarget ? "*" : ""
                      }`}
                    />
                  </Control>
                )}
                {!disabled &&
                  (!sample.OriginalStatus ||
                    sample.OriginalStatus === "Imported" ||
                    sample.OriginalStatus === "Restored") && (
                    <Control>
                      {row.editable &&
                        isFullView &&
                        canEditResults &&
                        (selectedInput === i ? (
                          <Icon color="danger" onClick={() => onCancelInput()}>
                            <FontAwesomeIcon icon="times" />
                          </Icon>
                        ) : (
                          <Button
                            size="small"
                            onClick={(e) => onClickEdit(e, i, row.field)}
                          >
                            <Icon color="primary">
                              <FontAwesomeIcon icon="pen" />
                            </Icon>
                          </Button>
                        ))}
                    </Control>
                  )}
              </React.Fragment>
            )}
          </Field>
        )
      )}
    </React.Fragment>
  );
};

BatchResultSampleColumn.propTypes = {
  sample: PropTypes.object.isRequired,
  template: PropTypes.array.isRequired,
  isFullView: PropTypes.bool,
  onChange: PropTypes.func,
  tests: PropTypes.array.isRequired,
  originalIndex: PropTypes.number.isRequired,
  BatchID: PropTypes.number.isRequired,
  showQualifiers: PropTypes.bool.isRequired,
  includesFailedAnalyte: PropTypes.bool.isRequired,
  includesQualifierAlert: PropTypes.bool.isRequired,
  missingRequiredField: PropTypes.bool.isRequired,
  hasTargetValues: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  isMicro: PropTypes.bool.isRequired,
  batchResults: PropTypes.array.isRequired,
  labState: PropTypes.string,
};

BatchResultSampleColumn.defaultProps = {
  isFullView: false,
  onChange: () => null,
  labState: "",
};

export default BatchResultSampleColumn;
