import React from "react";
import { Generic, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const COLUMNS = (onDelete) => [
  {
    Header: "Type",
    id: "type",
    accessor: "type",
    disableSortBy: true,
  },
  {
    Header: "Name",
    id: "name",
    accessor: "name",
  },
  {
    Header: "",
    id: "delete",
    Cell: (cell) => (
      <Generic
        style={{ textAlign: "center" }}
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          onDelete(cell.row.original);
        }}
      >
        <Icon color="danger">
          <FontAwesomeIcon icon="times" />
        </Icon>
      </Generic>
    ),
  },
];

export default COLUMNS;
