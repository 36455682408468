/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Field, Control, Label, Button, Column, Checkbox } from "rbx";
import { endOfDay, startOfDay, isValid } from "date-fns";

import {
  OrderStatusSelect,
  FilterInput,
  DateInput,
  ProcessingOptionSelect,
  LabLocationSelect,
  TestSelect,
} from "../../../../components";
import "./JobOrderFilters.scss";

const JobOrderFilters = ({
  handleFilterChange,
  handleFiltersReset,
  inputs,
  canSearchByLab,
}) => {
  const [isReceiveDateChecked, setIsReceiveDateChecked] = useState(false);

  return (
    <Box id="job-filters">
      <Column.Group>
        <Column>
          <Field>
            <FilterInput
              getValue={(x) => x?.equals || ""}
              label="Username"
              name="User"
              placeholder="Username"
              setValue={(x) => (x ? { is: { Username: { equals: x } } } : "")}
              value={inputs?.User?.is?.Username || ""}
              onChange={handleFilterChange}
            />
          </Field>
        </Column>
        <Column>
          <Field>
            <FilterInput
              getValue={(x) => x?.equals || ""}
              label="Job ID"
              name="JobID"
              placeholder="Job ID"
              setValue={(x) => (x ? { equals: x } : "")}
              value={inputs.JobID || ""}
              onChange={handleFilterChange}
            />
          </Field>
        </Column>
        <Column>
          <Field>
            <FilterInput
              getValue={(x) => x?.equals || ""}
              label="Order ID"
              name="JobOrderID"
              placeholder="Order ID"
              setValue={(x) => (x ? { equals: x } : "")}
              value={inputs.JobOrderID || ""}
              onChange={handleFilterChange}
            />
          </Field>
        </Column>
        <Column>
          <Field kind="group">
            <OrderStatusSelect
              getValue={(x) => (typeof x?.equals === "number" ? x?.equals : "")}
              label="Order Status"
              name="OrderStatus"
              setValue={(x) => (typeof x === "number" ? { equals: x } : "")}
              value={inputs?.OrderStatus}
              onChange={handleFilterChange}
            />
          </Field>
        </Column>
        <Column>
          <Field kind="group">
            <ProcessingOptionSelect
              getValue={(x) => (typeof x?.equals === "number" ? x?.equals : "")}
              label="Shipping"
              name="Job"
              setValue={(x) =>
                typeof x === "number" ? { is: { Shipping: { equals: x } } } : ""
              }
              value={inputs?.Job?.is?.Shipping}
              onChange={handleFilterChange}
            />
          </Field>
        </Column>
      </Column.Group>
      <Column.Group>
        <Column>
          <Field>
            <DateInput
              getValue={(x) => {
                const date = x?.gte || x?.lte;
                return date ? date.substr(0, 10) : "";
              }}
              label="Order Create Date"
              name="OrderCreateDate"
              setValue={(x) => {
                if (
                  x &&
                  isValid(new Date(x.split("-").map((y) => parseInt(y, 10))))
                ) {
                  return {
                    gte: startOfDay(
                      new Date(x.split("-").map((y) => parseInt(y, 10)))
                    ).toISOString(),
                    lte: endOfDay(
                      new Date(x.split("-").map((y) => parseInt(y, 10)))
                    ).toISOString(),
                  };
                }
                return "";
              }}
              value={inputs.OrderCreateDate}
              onChange={handleFilterChange}
            />
          </Field>
        </Column>
        <Column>
          <Field>
            <DateInput
              disabled={isReceiveDateChecked}
              getValue={(x) => {
                const date = x?.gte || x?.lte;
                return date ? date.substr(0, 10) : "";
              }}
              label="Order Receive Date"
              name="OrderReceiveDate"
              setValue={(x) => {
                if (
                  x &&
                  isValid(new Date(x.split("-").map((y) => parseInt(y, 10))))
                ) {
                  return {
                    gte: startOfDay(
                      new Date(x.split("-").map((y) => parseInt(y, 10)))
                    ).toISOString(),
                    lte: endOfDay(
                      new Date(x.split("-").map((y) => parseInt(y, 10)))
                    ).toISOString(),
                  };
                }
                return "";
              }}
              value={inputs.OrderReceiveDate}
              onChange={handleFilterChange}
            />
          </Field>
        </Column>
        {canSearchByLab && (
          <Column>
            <Field>
              <LabLocationSelect
                showLabel
                getValue={(x) =>
                  typeof x?.is?.LabID?.equals === "number"
                    ? x?.is?.LabID?.equals
                    : ""
                }
                name="Job"
                setValue={(x) =>
                  typeof x === "number" ? { is: { LabID: { equals: x } } } : ""
                }
                size="small"
                value={inputs?.Job}
                onChange={handleFilterChange}
              />
            </Field>
          </Column>
        )}
        <Column>
          <Field>
            <Control expanded size="small">
              <TestSelect
                getValue={(x) => x?.some?.Test?.is?.TestID?.equals || ""}
                id="JobOrderTests"
                label="Test Name"
                name="JobOrderTests"
                setValue={(x) =>
                  x
                    ? {
                        some: {
                          Test: { is: { TestID: { equals: parseInt(x, 10) } } },
                        },
                      }
                    : ""
                }
                size="small"
                value={inputs?.JobOrderTests}
                onChange={handleFilterChange}
              />
            </Control>
          </Field>
        </Column>
        <Column>
          <Field className="filter-button-group" kind="group">
            <Control>
              <Label>&nbsp;</Label>
              <Label>
                <Checkbox
                  onChange={(e) => {
                    setIsReceiveDateChecked(e.target.checked);
                    e.target.checked
                      ? handleFilterChange("OrderReceiveDate", { equals: null })
                      : handleFilterChange("OrderReceiveDate");
                  }}
                />
                &nbsp;Not received
              </Label>
            </Control>
            <Control>
              <Label>&nbsp;</Label>
              <Button color="primary" size="small" onClick={handleFiltersReset}>
                Reset
              </Button>
            </Control>
          </Field>
        </Column>
      </Column.Group>
    </Box>
  );
};

JobOrderFilters.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  handleFiltersReset: PropTypes.func.isRequired,
  inputs: PropTypes.object,
  canSearchByLab: PropTypes.bool,
};

JobOrderFilters.defaultProps = {
  inputs: {},
  canSearchByLab: false,
};

export default JobOrderFilters;
