import { Switch, Route } from "react-router-dom";

import { PrivateLabelFindPage, PrivateLabelOrderPage } from "./routes";

const PrivateLabelRouter = () => (
  <Switch>
    <Route path="/pl/order">
      <PrivateLabelOrderPage />
    </Route>
    <Route path="/pl/find">
      <PrivateLabelFindPage />
    </Route>
  </Switch>
);

export default PrivateLabelRouter;
