/* eslint-disable no-console */
import { ApolloClient, from, InMemoryCache, ApolloLink } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";

import { onError } from "@apollo/client/link/error";

import Cookies from "js-cookie";

const { NODE_ENV, REACT_APP_API_KEY } = process.env;

const isProduction = NODE_ENV === "production";

// this will forward graphql + networking errors to the browser console
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path} 😞`
        )
      );
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError} 😞`);
    }
    return forward(operation);
  }
);

const uri = isProduction ? "/api" : "http://localhost:4000/graphql";

const authLink = new ApolloLink((operation, forward) => {
  const token = Cookies.get("lims_token");
  operation.setContext(({ headers }) => ({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
      "x-api-key": REACT_APP_API_KEY,
      ...headers,
    },
  }));

  return forward(operation);
});

const uploadLink = createUploadLink({
  uri,
});

export default new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          findManyUserGroupMemberships: {
            merge(existing = [], incoming) {
              return incoming;
            },
            findManyPackages: {
              merge(existing, incoming = []) {
                return [...incoming];
              },
            },
          },
        },
      },
      Coupons: {
        keyFields: ["CouponID"],
      },
      SampleTracking: {
        keyFields: ["SampleTrackingID"],
      },
      UserGroups: {
        keyFields: ["GroupID"],
      },
      UserProficiencies: {
        keyFields: ["RecId"],
      },
      UserGroupMemberships: {
        keyFields: ["UserGroupMembershipID"],
      },
      UserGroupPermissions: {
        keyFields: ["UserGroupPermissionID"],
      },
    },
  }),
  link: from([errorLink, authLink, uploadLink]),
});
