import React, { useEffect, useState } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

import Loader from "../components/Loader";
import Navigation from "../components/Navigation";

import { useAuth } from "../context";
import { ModalProvider } from "../context/ModalContext";

import { LandingPage } from "./Main";

import EmployeeRouter from "./Employee";
import ClientRouter from "./Client";
import AccountingRouter from "./Accounting";
import CouponRouter from "./Coupon";
import JobOrderRouter from "./JobOrders";
import LabListPage from "./Lab";
import TestRouter from "./Test";
import DashboardPage from "./Dashboard";
import SystemCodesRouter from "./SystemCode";
import LimsRouter from "./Lims";
import UserGroupRouter from "./UserGroup";
import NewTestRouter from "./NewTest";
import ProfileRouter from "./Profile";
import ClientOrderRouter from "./ClientOrder";
import CoasRouter from "./Coas";
import ContactRouter from "./Contact";
import CheckoutRouter from "./Checkout";
import EquipmentRouter from "./Equipment";
import CreditLedgerRouter from "./CreditLedger";
import ClientSettingsRouter from "./ClientSettings";
import SampleQueuesRouter from "./SampleQueues";
import { useLocalStorage } from "../hooks";
import ProtectedRoute from "../components/ProtectedRoute";
import PrivateLabelRouter from "./PrivateLabel";
import LegacyJobOrderRouter from "./LegacyJobOrders";
import JobReceiptRouter from "./JobReceipt";
import DataExportRouter from "./DataExport";

const Pages = () => {
  const { state: authState, handleLogout, handleCheckAuth } = useAuth();
  const [open, setOpen] = useLocalStorage(`MENU_OPEN`, false);
  const history = useHistory();

  const [showNav, setShowNav] = useState(true);

  useEffect(() => {
    handleCheckAuth();
  }, [handleCheckAuth]);

  useIdleTimer({
    timeout: 1000 * 60 * 60,
    debounce: 500,
    onIdle: handleLogout,
  });

  useEffect(() => {
    const routes = history.location.pathname.split("/");
    if (
      (routes.includes("labels") || routes.includes("batch-labels")) &&
      routes.length > 3
    ) {
      setShowNav(false);
    }
  }, [history]);

  if (authState.isLoginPending) return <Loader />;

  return authState.isLoggedIn ? (
    <div className="app-container">
      <ModalProvider>
        <main
          className={`container ${open ? "open" : ""}`}
          role="presentation"
          onClick={() => (window.innerWidth < 900 ? setOpen(false) : null)}
          onKeyDown={() => (window.innerWidth < 900 ? setOpen(false) : null)}
        >
          {showNav && <Navigation open={open} setOpen={setOpen} />}
          <div
            className={[
              "section-container",
              "main-page-container",
              open && "open",
            ]
              .filter(Boolean)
              .join(" ")}
          >
            <Switch>
              <ProtectedRoute
                component={ClientSettingsRouter}
                path="/client-settings"
              />

              <ProtectedRoute component={CoasRouter} path="/coas" />
              <Route component={JobReceiptRouter} path="/job-receipt/:JobID" />

              <ProtectedRoute component={ContactRouter} path="/contact" />

              <ProtectedRoute component={ClientOrderRouter} path="/orders" />

              <ProtectedRoute component={ProfileRouter} path="/profile" />

              <ProtectedRoute component={NewTestRouter} path="/new-test" />

              <ProtectedRoute component={TestRouter} path="/tests" />

              <ProtectedRoute component={AccountingRouter} path="/accounting" />

              <ProtectedRoute
                component={CreditLedgerRouter}
                path="/credit-ledger"
              />

              <ProtectedRoute component={CouponRouter} path="/coupons" />

              <ProtectedRoute
                component={SystemCodesRouter}
                path="/system-codes"
              />

              <ProtectedRoute component={EmployeeRouter} path="/employees" />

              <ProtectedRoute component={LabListPage} path="/labs" />

              <ProtectedRoute component={ClientRouter} path="/clients" />

              <ProtectedRoute component={JobOrderRouter} path="/job-orders" />

              <ProtectedRoute
                component={LegacyJobOrderRouter}
                path="/legacy-orders"
              />

              <ProtectedRoute component={LimsRouter} path="/lims" />

              <ProtectedRoute component={UserGroupRouter} path="/user-groups" />

              <ProtectedRoute component={CheckoutRouter} path="/cart" />

              <ProtectedRoute component={EquipmentRouter} path="/equipment" />

              <ProtectedRoute
                component={SampleQueuesRouter}
                path="/sample-queues"
              />
              <ProtectedRoute component={PrivateLabelRouter} path="/pl" />

              <ProtectedRoute
                component={DataExportRouter}
                path="/data-export"
              />

              <Route path="/">
                <DashboardPage />
              </Route>
            </Switch>
          </div>
        </main>
      </ModalProvider>
    </div>
  ) : (
    <LandingPage />
  );
};

Pages.propTypes = {};

export default Pages;
