import React, { useState } from "react";
import PropTypes from "prop-types";
import { Title, Button, Control, Input, Field } from "rbx";
import { useModal } from "../../../../../../context";
import { customToast as toast } from "../../../../../../utils";

const LabelPrintQuantity = ({ JobOrderID }) => {
  const [quantity, setQuantity] = useState("1");
  const { setModalOpen } = useModal();

  const handleChange = (e) => {
    setQuantity(e.target.value);
  };

  const print = (e) => {
    e.preventDefault();
    if (quantity >= 1 && quantity <= 100) {
      const win = window.open(
        `/lims/labels/ManyJobOrderID-${JobOrderID}/${quantity}`
      );
      setModalOpen(false);
      win.focus();
    } else {
      toast.error("Quantity must be greater than 1 and lower than 100");
    }
  };

  return (
    <React.Fragment>
      <Control style={{ marginBottom: 10 }}>
        <Title subtitle>Print Labels</Title>
        <Input
          max={100}
          placeholder="How many Order Labels would you like to print?"
          type="number"
          value={quantity}
          onChange={handleChange}
        />
      </Control>
      <Field style={{ justifyContent: "center", marginBottom: 30 }}>
        <Control>
          <Button color="primary" onClick={print}>
            Print
          </Button>
        </Control>
      </Field>
    </React.Fragment>
  );
};
LabelPrintQuantity.propTypes = {
  JobOrderID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
LabelPrintQuantity.defaultProps = {
  JobOrderID: "",
};
export default LabelPrintQuantity;
