import { gql } from "@apollo/client";

export const ALL_BATCH_LOGS_QUERY = gql`
  query ALL_BATCH_LOGS_QUERY(
    $where: BatchLogsWhereInput
    $orderBy: [BatchLogsOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyBatchLogs(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      BatchLogID
      Change
      ReasonForChange
      Batches {
        BatchID
      }
      CreatedBy
      CreatedDateTime
    } 
    aggregateBatchLogs(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const SINGLE_BATCH_LOG_QUERY = gql`
  query SINGLE_BATCH_LOG_QUERY($where: BatchLogsWhereUniqueInput!) {
    findUniqueBatchLogs(where: $where) {
      BatchLogID
      Change
      ReasonForChange
      Batches {
        BatchID
      }
      CreatedBy
      CreatedDateTime
    }
  }
`;

export const FIND_FIRST_BATCH_LOG_QUERY = gql`
  query FIND_FIRST_BATCH_LOG_QUERY($where: BatchLogsWhereInput!) {
    findFirstBatchLogs(where: $where) {
      BatchLogID
      Batches {
        BatchID
      }
    }
  }
`;
