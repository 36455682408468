import React from "react";
import PropTypes from "prop-types";
import { Column, Field, Input, Label, Control, Box, Title, Image } from "rbx";

import {
  BooleanInput,
  StateSelect,
  FileUploader,
  PhoneNumberInput,
} from "../../../../components";

const LabAccountDetailsForm = ({
  inputs,
  onChange,
  disabled,
  adding,
  onSubmit,
  uploadLabSignaturePath,
}) => (
  <form id="lab-account-details" onSubmit={onSubmit}>
    <Column.Group multiline>
      <Column size={12}>
        <Field kind="group">
          <Control expanded size="small">
            <Label>Location</Label>
            <Input
              required
              disabled={disabled}
              maxLength={250}
              name="Company"
              placeholder="Location"
              size="small"
              type="text"
              value={inputs.Company}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded size="small">
            <Label>Corporate Customer Service Email</Label>
            <Input
              required
              disabled={disabled}
              maxLength={250}
              name="CorporateCustomerServiceEmail"
              placeholder="Corporate Customer Service Email"
              size="small"
              type="email"
              value={inputs.CorporateCustomerServiceEmail}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded size="small">
            <Label>Onsite Customer Service Email</Label>
            <Input
              required
              disabled={disabled}
              maxLength={250}
              name="OnsiteCustomerServiceEmail"
              placeholder="Corporate Customer Service Email"
              size="small"
              type="email"
              value={inputs.OnsiteCustomerServiceEmail}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field kind="group">
          <Control expanded size="small">
            <Label>Address</Label>
            <Input
              required
              disabled={disabled}
              maxLength={250}
              name="Address"
              placeholder="Address"
              size="small"
              type="text"
              value={inputs.Address}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field kind="group">
          <Control expanded size="small" style={{ width: "33%" }}>
            <Label>City</Label>
            <Input
              required
              disabled={disabled}
              maxLength={250}
              name="City"
              placeholder="City"
              size="small"
              type="text"
              value={inputs.City}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded size="small" style={{ width: "33%" }}>
            <StateSelect
              required
              disabled={disabled}
              id="State"
              label="State"
              name="State"
              size="small"
              value={inputs.State}
              onChange={onChange}
            />
          </Control>
          <Control expanded size="small" style={{ width: "33%" }}>
            <Label>Zip</Label>
            <Input
              required
              disabled={disabled}
              maxLength={250}
              name="Zip"
              placeholder="Zip"
              size="small"
              type="text"
              value={inputs.Zip}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control>
            <Label>Show Qualifiers</Label>
            <BooleanInput
              disabled={disabled}
              name="ShowQualifiers"
              value={inputs.ShowQualifiers}
              onChange={(name, checked) => onChange(name, checked)}
            />
          </Control>
        </Field>
        <Field kind="group">
          <Control expanded size="small">
            <PhoneNumberInput
              required
              disabled={disabled}
              label="Phone"
              name="Phone"
              size="small"
              value={inputs.Phone}
              onChange={onChange}
            />
          </Control>
          <Control expanded size="small">
            <Label>License Number</Label>
            <Input
              required
              disabled={disabled}
              maxLength={250}
              name="LicenseNumber"
              placeholder="License Number"
              size="small"
              type="text"
              value={inputs.LicenseNumber}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
      </Column>
    </Column.Group>
    <Column.Group multiline>
      <Column size={6}>
        <Box>
          <Title subtitle>Main Lab Director</Title>
          <Field kind="group">
            <Control expanded size="small" style={{ width: "50%" }}>
              <Label>Full Name</Label>
              <Input
                required
                disabled={disabled}
                maxLength={250}
                name="LabDirectorOneName"
                placeholder="Full Name"
                size="small"
                type="text"
                value={inputs.LabDirectorOneName}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded size="small" style={{ width: "50%" }}>
              <Label>Title</Label>
              <Input
                required
                disabled={disabled}
                maxLength={250}
                name="LabDirectorOneTitle"
                placeholder="Title"
                size="small"
                type="text"
                value={inputs.LabDirectorOneTitle}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded style={{ width: "50%" }}>
              <FileUploader
                disabled={disabled}
                label="Signature"
                name="LabDirectorOneSignature"
                uploadPath={uploadLabSignaturePath}
                value={inputs?.LabDirectorOneSignature}
                onChange={(name, value) => onChange(name, value)}
              />
            </Control>
            <Control size="small">
              <Image.Container size={128}>
                <Image src={inputs?.LabDirectorOneSignature} />
              </Image.Container>
            </Control>
          </Field>
        </Box>
      </Column>
      <Column size={6}>
        <Box>
          <Title subtitle>Second Lab Director</Title>
          <Field kind="group">
            <Control expanded size="small" style={{ width: "50%" }}>
              <Label>Full Name</Label>
              <Input
                required
                disabled={disabled}
                maxLength={250}
                name="LabDirectorTwoName"
                placeholder="Full Name"
                size="small"
                type="text"
                value={inputs.LabDirectorTwoName}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded size="small" style={{ width: "50%" }}>
              <Label>Title</Label>
              <Input
                required
                disabled={disabled}
                maxLength={250}
                name="LabDirectorTwoTitle"
                placeholder="Title"
                size="small"
                type="text"
                value={inputs.LabDirectorTwoTitle}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded size="small" style={{ width: "50%" }}>
              <FileUploader
                disabled={disabled}
                label="Signature"
                name="LabDirectorTwoSignature"
                uploadPath={uploadLabSignaturePath}
                value={inputs?.LabDirectorTwoSignature}
                onChange={(name, value) => onChange(name, value)}
              />
            </Control>
            <Control size="small">
              <Image.Container size={128}>
                <Image src={inputs?.LabDirectorTwoSignature} />
              </Image.Container>
            </Control>
          </Field>
        </Box>
      </Column>
    </Column.Group>
  </form>
);

LabAccountDetailsForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  adding: PropTypes.bool,
  uploadLabSignaturePath: PropTypes.string.isRequired,
};

LabAccountDetailsForm.defaultProps = {
  disabled: false,
  adding: false,
};

export default LabAccountDetailsForm;
