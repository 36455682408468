import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Image } from "rbx";

import EquipmentTests from "../../components/EquipmentTests";
import EquipmentForm from "../../components/EquipmentForm";
import FileUploader from "../../../../components/FileUploader";
import "./Profile.scss";

const Profile = ({
  EquipmentID,
  inputs,
  onChange,
  disabled,
  onSubmit,
  formId,
  adding,
  isEdit,
  calibrationDueDate,
  maintenanceDueDate,
  uploadEquipmentPath,
}) => (
  <React.Fragment>
    <div className="equipment-edit-columns">
      <Field>
        <Control expanded>
          <FileUploader
            disabled={disabled}
            label="Equipment Image"
            name="ImageURL"
            uploadPath={uploadEquipmentPath}
            value={inputs?.ImageURL}
            onChange={(name, value) => onChange(name, value)}
          />
        </Control>
        <Control expanded style={{ width: "50%", margin: "auto", paddingTop: "10px" }}>
          <Image.Container size={50}>
            <Image alt="Equipment" src={inputs?.ImageURL || ""} />
          </Image.Container>
        </Control>
      </Field>
      <EquipmentForm
        isEdit
        calibrationDueDate={calibrationDueDate}
        disabled={disabled}
        formId="edit-equipment-form"
        inputs={inputs}
        maintenanceDueDate={maintenanceDueDate}
        onChange={onChange}
        onSubmit={onSubmit}
      />
    </div>
    <hr />
    <EquipmentTests EquipmentID={EquipmentID} disabled={disabled}/>
  </React.Fragment>
);

Profile.propTypes = {
  EquipmentID: PropTypes.string.isRequired,
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  formId: PropTypes.string.isRequired,
  adding: PropTypes.bool,
  isEdit: PropTypes.bool,
  calibrationDueDate: PropTypes.string,
  maintenanceDueDate: PropTypes.string,
  uploadEquipmentPath: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

Profile.defaultProps = {
  onSubmit: () => null,
  disabled: false,
  adding: false,
  isEdit: false,
  calibrationDueDate: "",
  maintenanceDueDate: "",
  uploadEquipmentPath: () => {},
};

export default Profile;
