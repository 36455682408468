import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useAuth } from "../context";
import { ALL_CREDIT_LEDGER_QUERY } from "../graphql";

const useCreditLedger = () => {
  const { state: authState } = useAuth();
  const [creditLedger, setCreditLedger] = useState(0);
  const [getCreditLedger, { data: creditLedgerData }] = useLazyQuery(
    ALL_CREDIT_LEDGER_QUERY
  );

  useEffect(() => {
    if (authState?.user?.UserID) {
      getCreditLedger({
        variables: {
          where: {
            UserID: { equals: parseInt(authState.user.UserID, 10) },
          },
        },
      });
    }
  }, [authState, getCreditLedger]);

  useEffect(() => {
    if (creditLedgerData?.creditLedgers?.length > 0) {
      const totalOfCredit = creditLedgerData.creditLedgers.reduce(
        (a, b) => a + b.Credit,
        0
      );
      setCreditLedger(totalOfCredit || 0);
    }
  }, [creditLedgerData]);

  return creditLedger;
};

export default useCreditLedger;