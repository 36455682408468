import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";

import { Box } from "rbx";

import { ALL_HOMOGENEITY_JOB_ORDER_TEST_RESULTS_QUERY } from "../../../../../../graphql";
import { calculateHomogeneityTotals } from "../../../../../../utils/batchCalculations";

import "./HomogeneityResultsSection.scss";

const HomogeneityResultsSection = ({ JobOrderID }) => {
  const [resultData, setResultData] = useState(null);
  const [getHomogeneityTestResults, { data: homogeneityTestsData }] =
    useLazyQuery(ALL_HOMOGENEITY_JOB_ORDER_TEST_RESULTS_QUERY);

  useEffect(() => {
    if (JobOrderID) {
      // get approved homogeneity test results only if all tests are approved or archived
      getHomogeneityTestResults({
        variables: {
          where: {
            JobOrderID: { equals: parseInt(JobOrderID, 10) },
          },
        },
      });
    }
  }, [getHomogeneityTestResults, JobOrderID]);

  useEffect(() => {
    if (homogeneityTestsData?.findManyHomogeneityJobOrderTestResults?.length) {
      // calculate results and pass/fail using all approved homogeneity tests
      setResultData(
        calculateHomogeneityTotals(
          homogeneityTestsData.findManyHomogeneityJobOrderTestResults
        )
      );
    }
  }, [homogeneityTestsData]);

  return resultData ? (
    <React.Fragment>
      <Box id="homogeneity-results-container">
        <div className="homogeneity-results-row">
          <div className="homogeneity-results-heading">
            <br />
          </div>
          {Object.keys(resultData.CBD).map((heading) => (
            <div key={heading} className="homogeneity-results-heading">
              <div>{heading}</div>
            </div>
          ))}
        </div>
        {Object.entries(resultData).map(([analyte, values]) => (
          <div key={analyte} className="homogeneity-results-row">
            <div className={values["Pass/Fail"]}>{analyte}:</div>
            {Object.entries(values).map(([heading, val]) => (
              <div key={heading} className={values["Pass/Fail"]}>
                {val}
              </div>
            ))}
          </div>
        ))}
      </Box>
      <br />
    </React.Fragment>
  ) : null;
};

HomogeneityResultsSection.propTypes = {
  JobOrderID: PropTypes.string.isRequired,
};

export default HomogeneityResultsSection;
