import React from "react";
import PropTypes from "prop-types";
import { Box, Field, Control, Label, Button, Column } from "rbx";
import { endOfDay, startOfDay, isValid } from "date-fns";

import {
  FilterInput,
  DateInput,
  LabLocationSelect,
} from "../../../../components";

const JobOrderFilters = ({
  handleFilterChange,
  handleFiltersReset,
  inputs,
  lab,
  onLabChange,
}) => (
  <Box id="job-filters">
    <Column.Group>
      <Column>
        <Field>
          <FilterInput
            getValue={(x) => x?.equals || ""}
            label="Username"
            name="User"
            placeholder="Username"
            setValue={(x) => (x ? { is: { Username: { equals: x } } } : "")}
            value={inputs?.User?.is?.Username || ""}
            onChange={handleFilterChange}
          />
        </Field>
      </Column>
      <Column>
        <Field>
          <FilterInput
            getValue={(x) => x?.equals || ""}
            label="Job ID"
            name="JobID"
            placeholder="Job ID"
            setValue={(x) => (x ? { equals: x } : "")}
            value={inputs.JobID || ""}
            onChange={handleFilterChange}
          />
        </Field>
      </Column>
      <Column>
        <Field>
          <FilterInput
            getValue={(x) => x?.equals || ""}
            label="Order ID"
            name="JobOrderID"
            placeholder="Order ID"
            setValue={(x) => (x ? { equals: x } : "")}
            value={inputs.JobOrderID || ""}
            onChange={handleFilterChange}
          />
        </Field>
      </Column>
      <Column>
        <Field>
          <DateInput
            getValue={(x) => {
              const date = x?.gte || x?.lte;
              return date ? date.substr(0, 10) : "";
            }}
            label="Order Create Date"
            name="OrderCreateDate"
            setValue={(x) => {
              if (
                x &&
                isValid(new Date(x.split("-").map((y) => parseInt(y, 10))))
              ) {
                return {
                  gte: startOfDay(
                    new Date(x.split("-").map((y) => parseInt(y, 10)))
                  ).toISOString(),
                  lte: endOfDay(
                    new Date(x.split("-").map((y) => parseInt(y, 10)))
                  ).toISOString(),
                };
              }
              return "";
            }}
            value={inputs.OrderCreateDate}
            onChange={handleFilterChange}
          />
        </Field>
      </Column>
      {lab?.Company === "All Location" && (
        <Column size={3.6}>
          <Field>
            <LabLocationSelect
              showLabel
              getValue={(x) => (typeof x?.equals === "number" ? x?.equals : "")}
              name="LabID"
              setValue={(x) => (typeof x === "number" ? { equals: x } : {})}
              size="small"
              value={inputs?.User?.is?.LabID || ""}
              onChange={onLabChange}
            />
          </Field>
        </Column>
      )}
      <Column size={1}>
        <Field>
          <Control>
            <Label>&nbsp;</Label>
            <Button
              align="right"
              color="primary"
              size="small"
              onClick={handleFiltersReset}
            >
              Reset
            </Button>
          </Control>
        </Field>
      </Column>
    </Column.Group>
  </Box>
);

JobOrderFilters.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  handleFiltersReset: PropTypes.func.isRequired,
  inputs: PropTypes.object,
  onLabChange: PropTypes.func.isRequired,
  lab: PropTypes.object.isRequired,
};

JobOrderFilters.defaultProps = {
  inputs: {},
};

export default JobOrderFilters;
