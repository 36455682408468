import React, { useState, useMemo, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Icon, Button } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  DataTable,
  PageHeader,
  ListSearchInput,
  IconButton,
  Confirmation,
} from "../../../components";
import {
  LIST_EQUIPMENT_QUERY,
  DELETE_EQUIPMENT_MUTATION,
} from "../../../graphql";
import { generateColumns } from "./columns";
import { useModal, useAuth } from "../../../context";
import { customToast as toast, debounce } from "../../../utils";
import AddEquipmentModal from "../components/AddEquipmentModal";
import EquipmentFilters from "../components/EquipmentFilters";

const EquipmentPage = ({ routePermissions }) => {
  const { state: authState } = useAuth();
  const history = useHistory();
  const { setModalOpen } = useModal();

  const [where, setWhere] = useState({});
  const [canUpdate, setCanUpdate] = useState(true);
  const [labID, setLabID] = useState("");
  const [showFilters, setShowFilters] = useState(false);

  const [deleteEquipment] = useMutation(DELETE_EQUIPMENT_MUTATION);

  const orderBy = [{ id: "EquipmentID", desc: false }];

  useEffect(() => {
    setLabID(authState.user.LabID);
    if (authState.user.LabID !== "9999") {
      setWhere((prev) => ({
        ...prev,
        LabID: {
          equals: parseInt(authState.user.LabID, 10),
        },
      }));
    }
  }, [authState]);

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  const COLUMNS = useMemo(() => {
    const handleDelete = (equipments) => {
      const performDelete = async () => {
        try {
          setModalOpen(false, "");
          await deleteEquipment({
            variables: {
              where: {
                EquipmentID: parseInt(equipments.EquipmentID, 10),
              },
            },
            refetchQueries: [
              {
                query: LIST_EQUIPMENT_QUERY,
                variables: {
                  where,
                },
              },
            ],
          });
          toast.success("Equipment deleted successfully.");
        } catch (err) {
          toast.error("Error deleting Equipment.");
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this Equipment?"
          onCancel={() => setModalOpen(false, "")}
          onConfirm={performDelete}
        />
      );
    };
    return generateColumns(handleDelete, canUpdate);
  }, [deleteEquipment, setModalOpen, canUpdate, where]);

  const toggleFilters = (e) => {
    e.preventDefault();
    setShowFilters((prev) => !prev);
  };

  const handleFilterChange = (name, value) => {
    setWhere((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setWhere(() => {
      if (labID.toString() !== "9999") {
        return {
          LabID: {
            equals: parseInt(labID, 10),
          },
        };
      }
      return {};
    });
  };

  const handleCreateEquipment = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push("/equipment");
    setModalOpen(
      true,
      <AddEquipmentModal
        onComplete={() => {
          history.push("/equipment");
          setModalOpen(false);
        }}
      />
    );
  };

  const handleRowClick = (equipmentItem) => {
    history.push(`/equipment/${equipmentItem.EquipmentID}/profile`);
  };

  const handleSearchChange = debounce((name, value) => {
    if (value) {
      setWhere((prev) => ({
        ...prev,
        OR: [
          { Name: { contains: value } },
          { Manufacturer: { contains: value } },
          { ModelNumber: { contains: value } },
          { SerialNumber: { contains: value } },
        ],
      }));
    } else if (labID.toString() !== "9999") {
      setWhere({
        LabID: {
          equals: parseInt(labID, 10),
        },
      });
    } else {
      setWhere({});
    }
  }, 500);

  return (
    <div>
      <PageHeader buttonText="Create Equipment" title="Equipment">
        <ListSearchInput onChange={handleSearchChange} />
        {canUpdate && (
          <IconButton icon="plus" onClick={handleCreateEquipment} />
        )}
        {labID.toString() === "9999" && (
          <Button color="primary" onClick={toggleFilters}>
            <Icon>
              <FontAwesomeIcon icon="filter" />
            </Icon>
          </Button>
        )}
      </PageHeader>
      {showFilters && (
        <EquipmentFilters
          handleFilterChange={handleFilterChange}
          handleFiltersReset={handleReset}
          inputs={where}
        />
      )}
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateEquipment"
        columns={COLUMNS}
        name="findManyEquipment"
        orderBy={orderBy}
        query={LIST_EQUIPMENT_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

EquipmentPage.propTypes = {
  routePermissions: PropTypes.array,
};

EquipmentPage.defaultProps = {
  routePermissions: [],
};

EquipmentPage.propTypes = {};

export default EquipmentPage;
