import { gql } from "@apollo/client";

export const CREATE_USER_GROUP_MUTATION = gql`
  mutation CREATE_USER_GROUP_MUTATION($data: UserGroupsCreateInput!) {
    createUserGroups(data: $data) {
      GroupID
    }
  }
`;

export const UPDATE_USER_GROUP_MUTATION = gql`
  mutation UPDATE_USER_GROUP_MUTATION(
    $data: UserGroupsUpdateInput!
    $where: UserGroupsWhereUniqueInput!
  ) {
    updateUserGroups(data: $data, where: $where) {
      GroupID
    }
  }
`;

export const DELETE_USER_GROUP_MUTATION = gql`
  mutation DELETE_USER_GROUP_MUTATION($where: UserGroupsWhereUniqueInput!) {
    deleteUserGroups(where: $where) {
      GroupID
    }
  }
`;
