/* eslint-disable react/prop-types */
import React from "react";
import { format } from "date-fns";
import { Tag } from "rbx";
import { TableCell } from "../../../components/DataTable";
import { STATUS_COLORS } from "../../../constants";

const DEFAULT_COLUMNS = [
  {
    Header: "Username",
    id: "Username",
    accessor: "User.Username",
    disableSortBy: true,
  },
  {
    Header: "Order ID",
    id: "JobOrderID",
    type: "number",
    accessor: "JobOrderID",
    Cell: ({ cell }) => (
      <TableCell.Link
        to={`/job-receipt/${cell.row.original.JobID}`}
        value={cell.value}
      />
    ),
  },
  {
    Header: "Order Name",
    id: "JobOrder:OrderName",
    accessor: "OrderName",
    Cell: ({ cell, row }) => (
      <TableCell.Wrapped
        value={row.original?.OverrideData?.OrderName || cell.value}
      />
    ),
  },
  {
    Header: "Completed Date",
    id: "JobOrder:OrderCompleteDate",
    accessor: "OrderCompleteDate",
    disableSortBy: true,
    Cell: ({ cell }) =>
      cell?.value ? (
        format(new Date(cell.value), "MM-dd-yyyy")
      ) : (
        <Tag color="warning">Not Received Yet</Tag>
      ),
  },
];

export { DEFAULT_COLUMNS, STATUS_COLORS };
