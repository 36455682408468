import React from "react";
import PropTypes from "prop-types";
import { Label, Select } from "rbx";
import { useQuery } from "@apollo/client";
import { orderBy } from "lodash";
import { ALL_QC_SAMPLE_TYPES_QUERY } from "../../graphql";

const QCSampleTypeSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  getValue,
  required,
}) => {
  const { data: getQCSampleTypeData } = useQuery(ALL_QC_SAMPLE_TYPES_QUERY, {
    variables: {
      orderBy: { QCSampleTypeID: "asc" },
    },
  });

  const sampleTypes = getQCSampleTypeData?.findManyQCSampleTypes
    ? orderBy(
      getQCSampleTypeData.findManyQCSampleTypes,
        [(obj) => obj.Name],
        ["asc"]
      )
    : [];

  const indicateRequired = required && (value === "" || value === null);

  const handleChange = (e) => {
    onChange(e.target.name, e.target.value);
  };

  return (
    <React.Fragment>
      {label && <Label>{label}</Label>}
      <Select.Container
        fullwidth={!!label}
        size={size}
        state={Array.isArray(sampleTypes) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          required={required}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          <Select.Option value="" />
          {Array.isArray(sampleTypes) &&
            sampleTypes.map((sampleType) => (
              <Select.Option
                key={sampleType.QCSampleTypeID}
                value={sampleType.QCSampleTypeID}
              >
                {sampleType.Name}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </React.Fragment>
  );
};

QCSampleTypeSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  required: PropTypes.bool,
};

QCSampleTypeSelect.defaultProps = {
  value: "",
  name: "",
  label: "",
  size: "small",
  required: false,
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
};

export default QCSampleTypeSelect;
