/* eslint-disable react/prop-types */
import React from "react";
import { format } from "date-fns";
import { Tag } from "rbx";
import { Link } from "react-router-dom";
import { TableCell } from "../../../components/DataTable";
import { STATUS_COLORS } from "../../../constants";

const DEFAULT_COLUMNS = [
  {
    Header: "Username",
    id: "Username",
    accessor: "User.Username",
    disableSortBy: true,
  },
  {
    Header: "Job ID",
    id: "JobID",
    type: "number",
    accessor: "Job.JobID",
    Cell: ({ cell }) => (
      <TableCell.Link
        to={`/lims/intake/${cell.value}/${cell.row.original.JobOrderID}`}
        value={cell.value}
      />
    ),
  },
  {
    Header: "Order ID",
    id: "JobOrderID",
    type: "number",
    accessor: "JobOrderID",
    Cell: ({ cell }) => (
      <TableCell.Link
        to={`/job-receipt/${cell.row.original.JobID}`}
        value={cell.value}
      />
    ),
  },
  {
    Header: "Order Name",
    id: "OrderName",
    accessor: "OrderName",
    Cell: ({ cell, row }) => (
      <TableCell.Wrapped
        value={row.original?.OverrideData?.OrderName || cell.value}
      />
    ),
  },
  {
    Header: "Create Date",
    id: "OrderCreateDate",
    accessor: "OrderCreateDate",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM-dd-yyyy") : "",
  },
  {
    Header: "Receive Date",
    id: "OrderReceiveDate",
    accessor: "OrderReceiveDate",
    disableSortBy: true,
    Cell: ({ cell }) =>
      cell?.value ? (
        format(new Date(cell.value), "MM-dd-yyyy")
      ) : (
        <Tag color="warning">Not Received Yet</Tag>
      ),
  },
  {
    Header: "Tests",
    id: "TestsCount",
    accessor: "TestsCount",
    disableSortBy: true,
    Cell: ({ cell }) => (
      <Link
        to={`/lims/test-status/${cell.row.original.JobID}/${cell.row.original.JobOrderID}`}
        value={cell.value}
      >
        {cell.row.original.ApprovedTestsCount} / {cell.value}
      </Link>
    ),
  },
  {
    Header: "Shipping",
    id: "Shipping",
    accessor: "Job.ShippingSystemCode.CodeDescription",
    Cell: ({ cell }) => (
      <Tag color={STATUS_COLORS.Shipping[cell.value]} value={cell.value}>
        {cell.value}
      </Tag>
    ),
    disableSortBy: true,
  },
  {
    Header: "Payment",
    id: "PaymentStatus",
    accessor: "Job.Payment.CodeDescription",
    Cell: ({ cell }) => (
      <Tag color={STATUS_COLORS.Payment[cell.value]} value={cell.value}>
        {cell.value}
      </Tag>
    ),
    disableSortBy: true,
  },
  {
    Header: "Status",
    id: "JobOrderStatus",
    accessor: "JobOrderStatus.CodeDescription",
    Cell: ({ cell }) => (
      <Tag color={STATUS_COLORS.Status[cell.value]} value={cell.value}>
        {cell.value}
      </Tag>
    ),
    disableSortBy: true,
  },
  {
    Header: "Completed Date",
    id: "OrderCompleteDate",
    accessor: "OrderCompleteDate",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM-dd-yyyy") : "",
  },
];

export { DEFAULT_COLUMNS, STATUS_COLORS };
