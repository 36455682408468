import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Control, Field, Input, Button, Title, Help, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  VALIDATE_PASSWORD_RESET_QUERY,
  RESET_PASSWORD_RECOVERY_MUTATION,
} from "../../graphql";
import { Loader } from "../../components";
import { customToast as toast } from "../../utils";

const ResetPassword = () => {
  const { id, token } = useParams();
  const history = useHistory();
  const [inputs, setInputs] = useState({
    Password: "",
    ConfirmPassword: "",
  });
  const [state, setState] = useState("loading");
  const [showPassword, setShowPassword] = useState(false);

  const [validatePasswordToken, { data: userInformationData, loading }] =
    useLazyQuery(VALIDATE_PASSWORD_RESET_QUERY);
  const [resetPassword] = useMutation(RESET_PASSWORD_RECOVERY_MUTATION);

  useEffect(() => {
    if (id && token) {
      setState("loading");
      validatePasswordToken({
        variables: {
          where: {
            UserID: { equals: parseInt(id, 10) },
            ForgotPasswordCode: { equals: token },
          },
        },
      });
    }
  }, [id, token, validatePasswordToken]);

  useEffect(() => {
    if (userInformationData?.validatePasswordResetToken) {
      setState("charged");
    } else {
      setState("notFound");
    }
  }, [userInformationData]);

  const handleChange = (e) => {
    setInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const toggleShowPassword = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPassword(!showPassword);
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    const { data: resetPasswordResult } = await resetPassword({
      variables: {
        where: {
          UserID: { equals: parseInt(id, 10) },
          ForgotPasswordCode: { equals: token },
        },
        data: {
          Password: { set: inputs.Password },
        },
      },
    });
    if (resetPasswordResult?.resetPasswordRecovery) {
      toast.success("Password updated successfully!");
      history.push("/");
    } else {
      toast.error("Error on updating the password. Please, try again");
    }
  };

  if (state === "loading" || loading) return <Loader />;

  if (state === "notFound")
    return <Title subtitle>The information is not correct</Title>;

  return (
    <div className="landing-page-inputs">
      <Field>
        <Control>
          <Input
            name="Password"
            placeholder="Your new password"
            size="large"
            type={showPassword ? "text" : "password"}
            value={inputs.Password}
            onChange={handleChange}
          />
          <Icon
            className="password-visibility"
            style={{ top: 15 }}
            onClick={toggleShowPassword}
          >
            <FontAwesomeIcon icon={showPassword ? "eye" : "eye-slash"} />
          </Icon>
        </Control>
      </Field>
      <Field>
        <Control>
          <Input
            name="ConfirmPassword"
            placeholder="Confirm password"
            size="large"
            type={showPassword ? "text" : "password"}
            value={inputs.ConfirmPassword}
            onChange={handleChange}
          />
          <Icon
            className="password-visibility"
            style={{ top: 15 }}
            onClick={toggleShowPassword}
          >
            <FontAwesomeIcon icon={showPassword ? "eye" : "eye-slash"} />
          </Icon>
          {inputs.Password !== "" &&
            inputs.ConfirmPassword !== "" &&
            inputs.Password !== inputs.ConfirmPassword && (
              <Help color="danger"> Passwords don&lsquo;t match</Help>
            )}
        </Control>
      </Field>
      <Button
        rounded
        color="primary"
        disabled={inputs.Password !== inputs.ConfirmPassword}
        size="large"
        type="button"
        onClick={handleUpdatePassword}
      >
        Confirm
      </Button>
    </div>
  );
};
export default ResetPassword;
