/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import { format } from "date-fns";
import { getDaysDiffBetweenDates } from "../../../../../utils";
import { TableCell } from "../../../../../components/DataTable/TableCell";

const DEFAULT_COLUMNS = [
  {
    Header: "Order Number",
    id: "JobOrderID",
    accessor: "JobOrderID",
  },
  {
    Header: "Order Name",
    id: "OrderName",
    accessor: "OrderName",
    Cell: ({ cell, row }) => (
      <TableCell.Wrapped
        value={row.original?.OverrideData?.OrderName || cell.value}
      />
    ),
  },
  {
    Header: "Date Moved to Location",
    id: "SampleLocationDate",
    accessor: "SampleLocationDate",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM/dd/yyyy hh:mm a") : "--",
  },
  {
    Header: "Days Old",
    id: "DaysOld",
    accessor: "SampleLocationDate",
    disableSortBy: true,
    Cell: (obj) => (
      <TableCell.Number
        value={
          obj.cell?.value
            ? getDaysDiffBetweenDates(new Date(obj.cell.value), new Date())
            : "--"
        }
      />
    ),
  },
];

export default DEFAULT_COLUMNS;
