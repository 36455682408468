import React from "react";
import PropTypes from "prop-types";

const WrappedCell = ({ value }) => (
  <p style={{ whiteSpace: "pre-wrap" }}>{value}</p>
);

WrappedCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default WrappedCell;
