/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import {
  Column,
  Input,
  Label,
  Control,
  Field,
  Button,
  Icon,
  Generic,
  Textarea,
} from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  UserSelect,
  TestCategorySelect,
  LabLocationSelect,
} from "../../../../../../components";
import { subtractLocalTimeZoneOffset } from "../../../../../../utils";

const BatchDetailsForm = ({
  inputs,
  onChange,
  onSubmit,
  formId,
  handleSelectTestCategory,
  testCategory,
  editing,
  handleFormCancel,
  disabled,
}) => {
  const userSelectWhere = {
    Type: { is: { CodeId: { equals: "1" } } },
  };

  const handleAddDiluent = (e) => {
    e.preventDefault();
    const newDiluents = inputs.Diluents;
    newDiluents.push({
      Diluent: "",
      Expiration: "",
    });
    onChange("Diluents", newDiluents);
  };

  const handleDiluentChange = (index, name, value) => {
    const currentDiluents = inputs.Diluents;
    const newValues = { ...inputs.Diluents[index] };
    newValues[name] = value;
    currentDiluents[index] = newValues;
    onChange("Diluents", currentDiluents);
  };

  const handleDiluentRemove = (index) => {
    const newDiluents = inputs.Diluents;
    newDiluents.splice(index, 1);
    onChange("Diluents", newDiluents);
  };

  const renderDiluents = () => {
    if (inputs.Diluents.length) {
      return inputs.Diluents.map(({ Diluent, Expiration }, index) => (
        <Field key={index} kind="group">
          <Control expanded size="small">
            <Label>Diluent</Label>
            <Input
              disabled={disabled}
              maxLength={250}
              name="Diluent"
              placeholder="Diluent"
              size="small"
              value={Diluent}
              onChange={({ target: { name, value } }) =>
                handleDiluentChange(index, name, value)
              }
            />
          </Control>
          <Control expanded size="small">
            <Label>Expiration</Label>
            <Input
              disabled={disabled}
              name="Expiration"
              placeholder="Expiration"
              size="small"
              type="date"
              value={
                Expiration
                  ? new Date(Expiration).toISOString().split("T")[0]
                  : ""
              }
              onChange={({ target: { name, value } }) =>
                handleDiluentChange(index, name, value)
              }
            />
          </Control>
          {!disabled && (
            <div className="diluent-delete-wrapper">
              <Generic
                disabled={disabled}
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDiluentRemove(index);
                }}
              >
                <Icon color="danger">
                  <FontAwesomeIcon icon="times" />
                </Icon>
              </Generic>
            </div>
          )}
          {index === 0 && (
            <div className="diluent-delete-wrapper">
              <Button
                color="primary"
                disabled={disabled}
                size="small"
                onClick={handleAddDiluent}
              >
                <span>Add Diluent</span>
              </Button>
            </div>
          )}
        </Field>
      ));
    }

    return (
      <Field>
        <Button
          color="primary"
          disabled={disabled}
          size="small"
          onClick={handleAddDiluent}
        >
          <span>Add Diluent</span>
        </Button>
      </Field>
    );
  };

  const renderEditingForm = () => (
    <Column size={12}>
      <Field kind="group">
        <Control expanded size="small">
          <TestCategorySelect
            required
            disabled={!!testCategory?.TestCategoryID || disabled}
            label="Test Category"
            name="TestCategoryID"
            size="small"
            value={testCategory.TestCategoryID}
            onChange={(name, value) => handleSelectTestCategory(value)}
          />
        </Control>
        <UserSelect
          disabled={disabled}
          label="Diluted By"
          name="DilutedBy"
          value={inputs.DilutedBy}
          where={userSelectWhere}
          onChange={onChange}
        />
        <UserSelect
          disabled={disabled}
          label="Loaded By"
          name="LoadedBy"
          value={inputs.LoadedBy}
          where={userSelectWhere}
          onChange={onChange}
        />
        <UserSelect
          disabled={disabled}
          label="Analyzed By"
          name="AnalyzedBy"
          value={inputs.AnalyzedBy}
          where={userSelectWhere}
          onChange={onChange}
        />
      </Field>
      {renderDiluents()}
      <Field kind="group">
        <Control expanded size="small" style={{ width: "50%" }}>
          <Label>Soy Lot Number</Label>
          <Input
            disabled={disabled}
            maxLength={100}
            name="SoyLotNumber"
            placeholder="Soy Lot Number"
            size="small"
            value={inputs.SoyLotNumber}
            onChange={({ target: { name, value } }) => onChange(name, value)}
          />
        </Control>
        <Control expanded size="small" style={{ width: "50%" }}>
          <Label>Soy Lot Expiration</Label>
          <Input
            disabled={disabled}
            name="SoyLotExpiration"
            placeholder="Soy Lot Expiration"
            size="small"
            type="date"
            value={
              inputs.SoyLotExpiration
                ? new Date(inputs.SoyLotExpiration).toISOString().split("T")[0]
                : ""
            }
            onChange={({ target: { name, value } }) => onChange(name, value)}
          />
        </Control>
      </Field>
      <Field kind="group">
        <Control expanded size="small" style={{ width: "50%" }}>
          <Label>Solution Lot Number</Label>
          <Input
            disabled={disabled}
            maxLength={100}
            name="SolutionLotNumber"
            placeholder="Solution Lot Number"
            size="small"
            value={inputs.SolutionLotNumber}
            onChange={({ target: { name, value } }) => onChange(name, value)}
          />
        </Control>
        <Control expanded size="small" style={{ width: "50%" }}>
          <Label>Solution Lot Expiration</Label>
          <Input
            disabled={disabled}
            maxLength={250}
            name="SolutionLotExpiration"
            placeholder="Solution Lot Expiration"
            size="small"
            type="date"
            value={
              inputs.SolutionLotExpiration
                ? new Date(inputs.SolutionLotExpiration)
                    .toISOString()
                    .split("T")[0]
                : ""
            }
            onChange={({ target: { name, value } }) => onChange(name, value)}
          />
        </Control>
      </Field>
      <Field kind="group">
        <UserSelect
          disabled={disabled}
          label="Incubation/Enrichment Started By"
          name="IncubationStartedBy"
          value={inputs.IncubationStartedBy}
          where={userSelectWhere}
          onChange={onChange}
        />
        <Control expanded size="small">
          <Label>Incubation/Enrichment Start Date</Label>
          <Input
            disabled={disabled}
            maxLength={250}
            name="IncubationStartDateTime"
            size="small"
            type="date"
            value={
              inputs.IncubationStartDateTime
                ? new Date(inputs.IncubationStartDateTime)
                    .toISOString()
                    .split("T")[0]
                : ""
            }
            onChange={({ target: { name, value } }) => onChange(name, value)}
          />
        </Control>
        <Control expanded size="small">
          <Label>Incubation/Enrichment Start Time</Label>
          <Input
            disabled={disabled || !inputs.IncubationStartDateTime}
            maxLength={250}
            name="IncubationStartTime"
            size="small"
            type="time"
            value={
              !inputs.IncubationStartTime && inputs.IncubationStartDateTime
                ? new Date(
                    subtractLocalTimeZoneOffset(inputs.IncubationStartDateTime)
                  )
                    .toISOString()
                    .split("T")[1]
                    .split("Z")[0]
                : inputs.IncubationStartTime
            }
            onChange={({ target: { name, value } }) => onChange(name, value)}
          />
        </Control>
      </Field>
      <Field kind="group">
        <UserSelect
          disabled={disabled}
          label="Incubation/Enrichment Ended By"
          name="IncubationEndedBy"
          value={inputs.IncubationEndedBy}
          where={userSelectWhere}
          onChange={onChange}
        />
        <Control expanded size="small">
          <Label>Incubation/Enrichment End Date</Label>
          <Input
            disabled={disabled}
            maxLength={250}
            name="IncubationEndDateTime"
            size="small"
            type="date"
            value={
              inputs.IncubationEndDateTime
                ? new Date(inputs.IncubationEndDateTime)
                    .toISOString()
                    .split("T")[0]
                : ""
            }
            onChange={({ target: { name, value } }) => onChange(name, value)}
          />
        </Control>
        <Control expanded size="small">
          <Label>Incubation/Enrichment End Time</Label>
          <Input
            disabled={disabled || !inputs.IncubationEndDateTime}
            maxLength={250}
            name="IncubationEndTime"
            size="small"
            type="time"
            value={
              !inputs.IncubationEndTime && inputs.IncubationEndDateTime
                ? new Date(
                    subtractLocalTimeZoneOffset(inputs.IncubationEndDateTime)
                  )
                    .toISOString()
                    .split("T")[1]
                    .split("Z")[0]
                : inputs.IncubationEndTime
            }
            onChange={({ target: { name, value } }) => onChange(name, value)}
          />
        </Control>
      </Field>
      <Field>
        <Control>
          <Label>Note</Label>
          <Textarea
            disabled={disabled}
            name="Note"
            size="small"
            value={inputs.Note}
            onChange={({ target: { name, value } }) => onChange(name, value)}
          />
        </Control>
      </Field>
    </Column>
  );

  const renderAddingForm = () => (
    <Column size={12}>
      <Field kind="group">
        <Control expanded size="small">
          <TestCategorySelect
            required
            disabled={!!testCategory?.TestCategoryID}
            label="Test Category"
            name="TestCategoryID"
            size="small"
            value={testCategory.TestCategoryID}
            onChange={(name, value) => handleSelectTestCategory(value)}
          />
        </Control>
        <Control expanded size="small">
          <LabLocationSelect
            required
            disabled={!!inputs.Lab}
            id="LabID"
            label="Lab"
            name="Lab"
            showAllLocation={false}
            size="small"
            value={inputs.Lab}
            onChange={onChange}
          />
        </Control>
      </Field>
    </Column>
  );

  return (
    <form id={formId} onSubmit={onSubmit}>
      <Column.Group multiline>
        {editing ? renderEditingForm() : renderAddingForm()}
      </Column.Group>
    </form>
  );
};

BatchDetailsForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  formId: PropTypes.string.isRequired,
  testCategory: PropTypes.object,
  handleSelectTestCategory: PropTypes.func,
  editing: PropTypes.bool,
  handleFormCancel: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
};

BatchDetailsForm.defaultProps = {
  onSubmit: () => null,
  testCategory: {},
  handleSelectTestCategory: () => null,
  editing: false,
  handleFormCancel: () => null,
};

export default BatchDetailsForm;
