import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { useAuth } from "../../../context";
import {
  DataTable,
  PageHeader,
  ListSearchInput,
  IconButton,
} from "../../../components";
import { ALL_JOB_ORDERS_QUERY } from "../../../graphql";
import { DEFAULT_COLUMNS } from "./columns";
import { JobOrderFilters } from "../components";
import { debounce, downloadCSV } from "../../../utils";

const JobOrdersListPage = () => {
  const client = useApolloClient();
  const [where, setWhere] = useState({});
  const [showFilters, setShowFilters] = useState(false);

  const { state: authState } = useAuth();
  const [labID, setLabID] = useState("");

  const orderBy = [{ id: "JobID", desc: true }];

  useEffect(() => {
    setLabID(authState.user.LabID);
    if (authState.user.LabID !== "9999") {
      setWhere({
        Job: {
          is: {
            LabID: {
              equals: parseInt(authState.user.LabID, 10),
            },
          },
        },
      });
    }
  }, [authState]);

  const handleFilterChange = (name, value) => {
    setWhere((prev) => {
      if (value) {
        if (name === "Job" && labID?.toString() !== "9999") {
          return {
            ...prev,
            Job: {
              is: {
                ...prev.Job.is,
                ...value.is,
              },
            },
          };
        }

        if (name === "Job" && labID?.toString() === "9999" && where?.Job) {
          return {
            ...prev,
            Job: {
              is: {
                ...prev.Job.is,
                ...value.is,
              },
            },
          };
        }

        return {
          ...prev,
          [name]: value,
        };
      }

      return Object.keys(prev)
        .filter((x) => x !== name)
        .reduce((a, c) => ({ ...a, [c]: prev[c] }), {});
    });
  };

  const handleFiltersReset = () => {
    if (labID.toString() !== "9999") {
      setWhere({
        Job: {
          is: {
            LabID: {
              equals: parseInt(labID, 10),
            },
          },
        },
      });
    } else {
      setWhere({});
    }
  };

  const toggleFilters = (e) => {
    e.preventDefault();
    setShowFilters((prev) => !prev);
  };

  return (
    <div className="job-list-page">
      <PageHeader buttonText="Create Job Order" title="Job Orders">
        <ListSearchInput
          placeholder="Job ID or Order ID..."
          onChange={debounce((name, value) => {
            if (value) {
              const newWhere = {
                ...where,
                OR: [],
              };
              if (/^[0-9]+$/.test(value)) {
                newWhere.OR.push({ JobID: { equals: parseInt(value, 10) } });
                newWhere.OR.push({ JobOrderID: { equals: parseInt(value, 10) } });
              } else {
                newWhere.OR.push({ OrderName: { contains: value } });
                newWhere.OR.push({ BatchOrderNumber: { contains: value } });
              }
              setWhere(newWhere);
            } else {
              setWhere({});
            }
          }, 500)}
        />
        <IconButton icon="filter" onClick={toggleFilters} />
        <IconButton
          icon="file-export"
          onClick={() =>
            downloadCSV(
              client,
              {
                query: ALL_JOB_ORDERS_QUERY,
                where,
                orderByMulti: orderBy,
              },
              "findManyJobOrders",
              DEFAULT_COLUMNS
            )
          }
        />
      </PageHeader>
      {showFilters && (
        <JobOrderFilters
          canSearchByLab={labID.toString() === "9999"}
          handleFilterChange={handleFilterChange}
          handleFiltersReset={handleFiltersReset}
          inputs={where}
        />
      )}
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateJobOrders"
        columns={DEFAULT_COLUMNS}
        name="findManyJobOrders"
        orderBy={orderBy}
        query={ALL_JOB_ORDERS_QUERY}
        where={where}
      />
    </div>
  );
};

JobOrdersListPage.propTypes = {};

export default JobOrdersListPage;
