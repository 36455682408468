import React from "react";
import PropTypes from "prop-types";

import { Control, Label, Field } from "rbx";
import DatePicker from "react-datepicker";
import { endOfDay, startOfDay } from "date-fns";
import { debounce } from "../../utils";
import "./DateRangeSelect.scss";
import "react-datepicker/dist/react-datepicker.css";

const DateRangeSelect = ({ start, end, handleChange }) => {
  const onChange = debounce((s, e) => {
    if (s) {
      if (e) {
        handleChange({
          gte: startOfDay(new Date(s)).toISOString(),
          lte: endOfDay(new Date(e)).toISOString(),
        });
      } else {
        handleChange({
          gte: new Date(s).toISOString(),
        });
      }
    } else if (e) {
      handleChange({
        lte: endOfDay(new Date(e)).toISOString(),
      });
    } else {
      handleChange({});
    }
  }, 500);

  return (
    <span id="date-range-select">
      <Field>
        <Control expanded>
          <Label>Start Date</Label>
          <DatePicker
            selectsStart
            endDate={end ? new Date(end) : ""}
            selected={start ? new Date(start) : ""}
            startDate={start ? new Date(start) : ""}
            onChange={(date) => onChange(date, end)}
          />
        </Control>
      </Field>
      <Field>
        <Control expanded>
          <Label>End Date</Label>
          <DatePicker
            selectsEnd
            endDate={end ? new Date(end) : ""}
            minDate={start ? new Date(start) : ""}
            selected={end ? new Date(end) : ""}
            startDate={start ? new Date(start) : ""}
            onChange={(date) => onChange(start, date)}
          />
        </Control>
      </Field>
    </span>
  );
};

DateRangeSelect.propTypes = {
  start: PropTypes.string,
  end: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

DateRangeSelect.defaultProps = {
  start: "",
  end: "",
};

export default DateRangeSelect;
