import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Image, Control, Column, Field } from "rbx";
import { FileUploader } from "../../../../components";

const ProfilePicture = ({
  inputs,
  onChange,
  disabled,
  formId,
  onSubmit,
  userID,
}) => {
  const uploadProfilePicPath = useMemo(() => {
    const date = new Date();
    return `/Images/Clients/${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${userID}`;
  }, [userID]);
  return (
    <Column.Group centered multiline>
      <Column desktop={{ size: 6 }} tablet={{ size: 12 }}>
        <Field>
          <Control size="small" style={{ display: "flex", justifyContent: "center" }}>
            <Image.Container size={128}>
              <Image
                src={inputs?.ProfilePic}
                style={{ height: "100%", width: "inherit" }}
              />
            </Image.Container>
          </Control>
        </Field>
        <Field>
          <Control expanded>
            <FileUploader
              label="Profile Picture"
              name="ProfilePic"
              uploadPath={uploadProfilePicPath}
              value={inputs?.ProfilePic}
              onChange={(name, value) => onChange(name, value)}
            />
          </Control>
        </Field>
      </Column>
    </Column.Group>
  );
};

ProfilePicture.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  formId: PropTypes.string.isRequired,
  userID: PropTypes.string.isRequired,
};

ProfilePicture.defaultProps = {
  onSubmit: () => null,
  disabled: false,
};

export default ProfilePicture;
