import React from "react";
import PropTypes from "prop-types";

import { Switch, Route } from "react-router-dom";

import SystemCodeListPage from "./SystemCodeListPage";

const SystemCodesRouter = (props) => {
  const { routePermissions } = props;
  return (
    <Switch>
      <Route path="/system-codes/:RecId?">
        <SystemCodeListPage routePermissions={routePermissions} />
      </Route>
    </Switch>
  );
};

SystemCodesRouter.propTypes = {
  routePermissions: PropTypes.array,
};

SystemCodesRouter.defaultProps = {
  routePermissions: [],
};

export default SystemCodesRouter;
