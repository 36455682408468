/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from "react";
import { format } from "date-fns";
import { Generic, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableCell } from "../../../../components/DataTable";
import { formatCurrency } from "../../../../utils";

export const SUMMARY_COLUMNS = [
  {
    Header: "Lab",
    id: "labName",
    accessor: "labName",
  },
  {
    Header: "Purchase",
    id: "purchase",
    accessor: "sumTotal",
    Cell: ({ cell }) => (cell?.value ? formatCurrency(cell.value) : 0),
  },
  {
    Header: "Discount",
    id: "discount",
    accessor: "sumDiscount",
    Cell: ({ cell }) => (cell?.value ? formatCurrency(cell.value) : 0),
  },
  {
    Header: "Processing",
    id: "processing",
    accessor: "sumShipping",
    Cell: ({ cell }) => (cell?.value ? formatCurrency(cell.value) : 0),
  },
  {
    Header: "Net",
    id: "net",
    accessor: "sumNet",
    Cell: ({ cell }) => (cell?.value ? formatCurrency(cell.value) : 0),
  },
];

export const FINANCE_EXPORT_COLUMNS = [
  {
    Header: "Lab",
    id: "Lab",
    accessor: "Job.Lab.Company",
    disableSortBy: true,
  },
  {
    Header: "Client Name",
    id: "User",
    accessor: "User.FirstName",
    Cell: ({ cell }) => `${cell.value} ${cell.row.original.User.LastName}`,
    disableSortBy: true,
  },
  {
    Header: "Client Username",
    id: "Username",
    accessor: "User.Username",
    disableSortBy: true,
  },
  {
    Header: "Company",
    id: "Company",
    accessor: "User.Company",
    disableSortBy: true,
  },
  {
    Header: "Terms",
    id: "Terms",
    accessor: "TermsDescription",
    disableSortBy: true,
  },
  {
    Header: "Job ID",
    id: "JobID",
    accessor: "Job.JobID",
    disableSortBy: true,
  },
  {
    Header: "Create Date",
    id: "Job.JobCreateDate",
    accessor: "Job.JobCreateDate",
    disableSortBy: true,
  },
  {
    Header: "JobOrder ID",
    id: "JobOrderID",
    accessor: "JobOrderID",
    disableSortBy: true,
  },
  {
    Header: "Test",
    id: "TestName",
    accessor: "TestName",
    disableSortBy: true,
  },
  {
    Header: "Package",
    id: "Package",
    accessor: "PackageName",
    disableSortBy: true,
  },
  {
    Header: "Order Total",
    id: "OrderTotal",
    accessor: "OrderTotal",
    Cell: (obj) => (
      <TableCell.Currency value={JSON.stringify(obj.cell.value)} />
    ),
    disableSortBy: true,
  },
  {
    Header: "Job Total",
    id: "Total",
    accessor: "Job.Total",
    Cell: (obj) => (
      <TableCell.Currency value={JSON.stringify(obj.cell.value)} />
    ),
    disableSortBy: true,
  },
  {
    Header: "Job Discount",
    id: "Discount",
    accessor: "Job.Discount",
    Cell: (obj) => (
      <TableCell.Currency value={JSON.stringify(obj.cell.value)} />
    ),
    disableSortBy: true,
  },
  {
    Header: "Job Coupon Code",
    id: "Job.Coupon.Code",
    accessor: "Job.Coupon.Code",
    disableSortBy: true,
  },
  {
    Header: "Job Coupon %",
    id: "Job.Coupon.Percent",
    accessor: "Job.Coupon.Percent",
    Cell: ({ cell }) => (cell?.value ? `${cell.value}%` : ""),
    disableSortBy: true,
  },
  {
    Header: "Job Coupon $",
    id: "Job.Coupon.Discount",
    accessor: "Job.Coupon.Discount",
    Cell: ({ cell }) => (cell?.value ? `$${cell.value}` : ""),
    disableSortBy: true,
  },
  {
    Header: "Job Credit",
    id: "Credit",
    accessor: "Job.Credit",
    Cell: (obj) => (
      <TableCell.Currency value={JSON.stringify(obj.cell.value)} />
    ),
    disableSortBy: true,
  },
  {
    Header: "Job Net",
    id: "Net",
    accessor: "Job.Net",
    Cell: (obj) => (
      <TableCell.Currency value={JSON.stringify(obj.cell.value)} />
    ),
    disableSortBy: true,
  },
];

export const DEFAULT_COLUMNS = [
  {
    Header: "Lab",
    id: "Lab",
    accessor: "Lab.Company",
    disableSortBy: true,
  },
  {
    Header: "JobID",
    id: "JobID",
    accessor: "JobID",
    Cell: ({ cell }) => (
      <TableCell.Link to={`/lims/intake/${cell.value}`} value={cell.value} />
    ),
  },
  {
    Header: "User",
    id: "User",
    accessor: "User.Username",
    disableSortBy: true,
  },
  {
    Header: "Created",
    id: "JobCreateDate",
    accessor: "JobCreateDate",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM-dd-yyyy") : "",
  },
  {
    Header: "Balance",
    id: "PaymentReceived",
    accessor: "PaymentReceived",
    Cell: ({ cell }) =>
      formatCurrency(
        (cell.row.original.PaymentAmount || 0) - (cell.value || 0)
      ),
  },
  {
    Header: "Total",
    id: "Total",
    accessor: "Total",
    Cell: (obj) => (
      <TableCell.Currency value={JSON.stringify(obj.cell.value)} />
    ),
  },
  {
    Header: "Credit",
    id: "Credit",
    accessor: "Credit",
    Cell: (obj) => (
      <TableCell.Currency value={JSON.stringify(obj.cell.value)} />
    ),
  },
  {
    Header: "Discount",
    id: "Discount",
    accessor: "Discount",
    Cell: (obj) => (
      <TableCell.Currency value={JSON.stringify(obj.cell.value)} />
    ),
  },
  {
    Header: "Shipping Price",
    id: "Shipping",
    accessor: "ShippingPrice",
    Cell: (obj) => (
      <TableCell.Currency value={JSON.stringify(obj.cell.value)} />
    ),
  },
  {
    Header: "Net",
    id: "Net",
    accessor: "Net",
    Cell: (obj) => (
      <TableCell.Currency value={JSON.stringify(obj.cell.value)} />
    ),
  },
  {
    Header: "Payment Amount",
    id: "Payment Amount",
    accessor: "PaymentAmount",
    Cell: (obj) => (
      <TableCell.Currency value={JSON.stringify(obj.cell.value)} />
    ),
  },
  {
    Header: "Status",
    id: "Status",
    accessor: "Payment.CodeDescription",
    disableSortBy: true,
  },
];

export const generateColumns = (handleMarkAsPaid, canUpdate) => [
  ...DEFAULT_COLUMNS,
  {
    Header: "",
    id: "markAsPaid",
    Cell: (cell) =>
      canUpdate && (
        <Generic
          style={{ textAlign: "center" }}
          type="button"
          onClick={
            cell.row.original.Payment.CodeDescription === "Pending"
              ? (e) => {
                  e.stopPropagation();
                  handleMarkAsPaid(cell.row.original);
                }
              : null
          }
        >
          <Icon
            color={
              cell.row.original.Payment.CodeDescription === "Pending"
                ? "danger"
                : "primary"
            }
          >
            <FontAwesomeIcon icon="money-bill-wave" />
          </Icon>
        </Generic>
      ),
  },
];
