/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Icon, Generic } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const getBackgroundColor = (
  isDragging,
  isGroupedOver,
  authorColors = { soft: "#FFFFFF" }
) => {
  if (isDragging) {
    return authorColors.soft;
  }

  if (isGroupedOver) {
    return "#EBECF0";
  }

  return "#FFFFFF";
};

const getBorderColor = (isDragging, authorColors = { hard: "#059e42" }) =>
  isDragging ? authorColors.hard : "transparent";

const Container = styled.a`
  border-radius: 20px 0px 20px 20px;
  border: 2px solid transparent;
  border-color: ${(props) => getBorderColor(props.isDragging, props.colors)};
  background-color: ${(props) =>
    getBackgroundColor(props.isDragging, props.isGroupedOver, props.colors)};
  box-shadow: ${({ isDragging }) =>
    isDragging ? `2px 2px 1px #A5ADBA` : "none"};
  padding: 8px;
  min-height: 40px;
  user-select: none;

  /* anchor overrides */
  color: #091e42;

  &:hover,
  &:active {
    color: #091e42;
    text-decoration: none;
  }

  &:focus {
    outline: none;
    border-color: ${(props) => props?.colors?.hard || "#2684FF"};
    box-shadow: none;
  }

  /* flexbox */
  display: flex;
  height: 100%;
`;

const TestInfoItem = ({
  item,
  isDragging,
  isGroupedOver,
  provided,
  name,
  removePackageTest,
  disabled,
}) => (
  <Container
    ref={provided.innerRef}
    colors={item?.author?.colors}
    href={item?.author?.url}
    isDragging={isDragging}
    isGroupedOver={isGroupedOver}
    name={name}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
  >
    {name === "UserTest" ? (
      <div className="test-info-item">
        <p>{item.content?.Test?.Name}</p>
        <span />
        <p className="money">${parseFloat(item.content.Price, 10)}</p>
      </div>
    ) : (
      <div className="test-info-item">
        <p>{item.content?.Test?.Name}</p>
        <p className="money">${parseFloat(item.content.Price, 10)}</p>
        {!disabled &&
          <Generic
            className="delete-package-button"
            onClick={() => removePackageTest(name, item)}
          >
            <Icon color="primary">
              <FontAwesomeIcon icon="trash-alt" />
            </Icon>
          </Generic>
        }
      </div>
    )}
  </Container>
);

TestInfoItem.propTypes = {
  item: PropTypes.object,
  isDragging: PropTypes.bool,
  isGroupedOver: PropTypes.bool,
  provided: PropTypes.object,
  name: PropTypes.string.isRequired,
  removePackageTest: PropTypes.func,
  disabled: PropTypes.func,
};

TestInfoItem.defaultProps = {
  item: {},
  isDragging: false,
  isGroupedOver: false,
  provided: {},
  removePackageTest: () => null,
  disabled: false,
};

export default TestInfoItem;
