import React from "react";
import PropTypes from "prop-types";
import { Box, Field, Control, Label, Button, Column } from "rbx";
import { DateRangeSelect, LabLocationSelect } from "../../../../components";

const PrivateLabelCommissionsFilters = ({
  handleFilterChange,
  handleFiltersReset,
  inputs,
  onLabChange,
  lab,
}) => (
  <Box id="job-filters">
    <Column.Group multiline>
      <Column size={7}>
        <Field>
          <DateRangeSelect
            end={inputs?.PurchaseDate?.lte}
            handleChange={(value) =>
              handleFilterChange("PurchaseDate", { ...value })
            }
            start={inputs?.PurchaseDate?.gte}
          />
        </Field>
      </Column>
      {lab?.Company === "All Location" && (
        <Column size={4}>
          <Field>
            <LabLocationSelect
              showLabel
              getValue={(x) => (typeof x?.equals === "number" ? x?.equals : "")}
              name="LabID"
              setValue={(x) => (typeof x === "number" ? { equals: x } : {})}
              size="small"
              value={inputs?.LabID || ""}
              onChange={onLabChange}
            />
          </Field>
        </Column>
      )}
      <Column size={1}>
        <Field style={{ display: "flex", justifyContent: "flex-end" }}>
          <Control>
            <Label>&nbsp;</Label>
            <Button color="primary" size="small" onClick={handleFiltersReset}>
              Reset
            </Button>
          </Control>
        </Field>
      </Column>
    </Column.Group>
  </Box>
);

PrivateLabelCommissionsFilters.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  handleFiltersReset: PropTypes.func.isRequired,
  inputs: PropTypes.object,
  onLabChange: PropTypes.func.isRequired,
  lab: PropTypes.object.isRequired,
};

PrivateLabelCommissionsFilters.defaultProps = {
  inputs: {},
};

export default PrivateLabelCommissionsFilters;
