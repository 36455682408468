import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Control, Label, File } from "rbx";
import { useMutation } from "@apollo/client";
import { UPLOAD_FILE_MUTATION } from "../../graphql";

const FileUploader = ({
  name,
  value,
  onChange,
  dirPath,
  label,
  disabled,
  showPreview,
  fullwidth,
  size,
  allowOnlyImages,
}) => {
  const [uploadFile] = useMutation(UPLOAD_FILE_MUTATION);

  const handleChange = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (validity.valid && file) {
      const { data: UploadFileData } = await uploadFile({
        variables: { file },
      });
      const filepath = UploadFileData?.uploadFile?.filepath;

      if (filepath) {
        onChange(name, filepath);
      }
    }
  };

  return (
    <Control>
      {label && <Label>{label}</Label>}
      <File
        hasName
        color={disabled ? "" : "primary"}
        fullwidth={fullwidth}
        size={size}
      >
        <File.Label
          style={{
            cursor: disabled ? "not-allowed" : "pointer",
          }}
        >
          {allowOnlyImages ? (
            <File.Input
              accept="image/*"
              capture="camera"
              disabled={disabled}
              name="file"
              onBlur={handleChange}
              onChange={handleChange}
            />
          ) : (
            <File.Input
              disabled={disabled}
              name="file"
              onChange={handleChange}
            />
          )}

          <File.CTA>
            <File.Icon>
              <FontAwesomeIcon icon="upload" />
            </File.Icon>
          </File.CTA>
          <File.Name>{value || "Choose a file"}</File.Name>
        </File.Label>
      </File>
    </Control>
  );
};
FileUploader.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  dirPath: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  showPreview: PropTypes.bool,
  fullwidth: PropTypes.bool,
  size: PropTypes.string,
  allowOnlyImages: PropTypes.bool,
};

FileUploader.defaultProps = {
  name: "",
  value: "",
  dirPath: "",
  label: "",
  disabled: false,
  showPreview: false,
  onChange: () => null,
  fullwidth: true,
  size: "small",
  allowOnlyImages: false,
};

export default FileUploader;
