/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { Button, Field, Control, Input, Title, Tab, Generic } from "rbx";
import { useMutation } from "@apollo/client";
import { Link, Switch, useLocation, useHistory, Route } from "react-router-dom";

import { useAuth } from "../../context";
import {
  EMAIL_NOTIFICATION_ACCOUNT_INQUIRY_MUTATION,
  FORGOT_PASSWORD_RECOVERY_MUTATION,
} from "../../graphql";
import { customToast as toast } from "../../utils";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import { PhoneNumberInput } from "../../components";
import { usePublicIP } from "../../hooks";

const LandingPage = () => {
  const history = useHistory();
  const { handleLogin } = useAuth();
  const location = useLocation();
  const IP = usePublicIP();

  const [sendEmailNotificationAccount] = useMutation(
    EMAIL_NOTIFICATION_ACCOUNT_INQUIRY_MUTATION
  );
  const [forgotPassword] = useMutation(FORGOT_PASSWORD_RECOVERY_MUTATION);
  const [state, setState] = useState({ Username: "", Password: "" });
  const [newUser, setNewUser] = useState({
    Username: "",
    Company: "",
    Phone: "",
    Email: "",
    Password: "",
  });
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");

  // client, label, employee login types
  const [accountType, setAccountType] = useState("Client");

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (location.pathname.includes("signup")) {
      const created = await sendEmailNotificationAccount({
        variables: {
          data: {
            Username: newUser.Username,
            Company: newUser.Company,
            Phone: newUser.Phone,
            Email: newUser.Email,
            Password: "",
          },
        },
      });
      if (created) {
        history.push("/confirmation");
      } else {
        toast.error(
          "An error ocurred while trying to create the account. Please try again later."
        );
      }
    } else if (
      location.pathname.includes("forgot-password") ||
      location.pathname.includes("first-time-password")
    ) {
      try {
        setLoading(true);
        const { data: forgotPasswordResult } = await forgotPassword({
          variables: {
            data: {
              email: forgotPasswordEmail,
            },
          },
        });
        if (forgotPasswordResult?.forgotPasswordRecovery) {
          history.push("/password-confirmation");
        }
      } catch (err) {
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    } else {
      handleLogin(
        state.Username,
        state.Password,
        accountType,
        IP,
        window.navigator.userAgent
      );
    }
  };

  const handleNewUserChange = (name, value) => {
    setNewUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const showLoginOptions =
    !location.pathname.includes("signup") &&
    !location.pathname.includes("confirmation") &&
    !location.pathname.includes("forgot-password") &&
    !location.pathname.includes("first-time-password");

  return (
    <div className="landing-page">
      <form className="landing-page-container" onSubmit={handleSubmit}>
        <div>
          <Title size={1} textColor="white">
            {showLoginOptions
              ? "Would You Like To Create An Account?"
              : "Already Have An Account?"}
          </Title>
          <p style={{ color: "white" }}>
            Click the button below to {showLoginOptions ? "sign up" : "login"}.
          </p>
          <Button
            outlined
            rounded
            as={Link}
            color="white"
            size="large"
            to={showLoginOptions ? "/signup" : "/"}
            type="button"
          >
            &nbsp;&nbsp;&nbsp;&nbsp;
            {showLoginOptions ? "SIGN UP" : "LOGIN"}
            &nbsp;&nbsp;&nbsp;&nbsp;
          </Button>
        </div>
        <Switch>
          <Route path="/reset-password/:id/:token">
            <div>
              <Title id="forgot-password-header" size={1}>
                Password Reset
              </Title>
              <hr />
              <ResetPassword />
            </div>
          </Route>
          <Route path="/forgot-password">
            <div>
              <Title id="forgot-password-header" size={1}>
                Forgot your password?
              </Title>
              <hr />
              <ForgotPassword
                loading={loading}
                value={forgotPasswordEmail}
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
              />
            </div>
          </Route>
          <Route path="/first-time-password">
            <div>
              <Title size={1}>Welcome!</Title>
              <br />
              <Title subtitle>
                Enter your email to create your new password
              </Title>
              <hr />
              <ForgotPassword
                loading={loading}
                value={forgotPasswordEmail}
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
              />
            </div>
          </Route>
          <Route path="/signup">
            <div>
              <Title size={1}>Create Account</Title>
              <p>
                Fill out the short form below to have one of our customer
                service reps contact you with your username and password as soon
                as possible.
              </p>
              <div className="landing-page-inputs">
                <Field>
                  <Control expanded>
                    <Input
                      name="Company"
                      placeholder="Company Name"
                      size="large"
                      value={newUser.Company}
                      onChange={(e) =>
                        handleNewUserChange(e.target.name, e.target.value)
                      }
                    />
                  </Control>
                </Field>
                <Field>
                  <Control>
                    <Input
                      name="Username"
                      placeholder="Contact Name"
                      size="large"
                      value={newUser.Username}
                      onChange={(e) =>
                        handleNewUserChange(e.target.name, e.target.value)
                      }
                    />
                  </Control>
                </Field>
                <Field>
                  <Control>
                    <Input
                      name="Email"
                      placeholder="Email"
                      size="large"
                      value={newUser.Email}
                      onChange={(e) =>
                        handleNewUserChange(e.target.name, e.target.value)
                      }
                    />
                  </Control>
                </Field>
                <Field>
                  <Control>
                    <PhoneNumberInput
                      label=""
                      name="Phone"
                      placeholder="Phone Number"
                      size="large"
                      value={newUser.Phone}
                      onChange={handleNewUserChange}
                    />
                  </Control>
                </Field>
                <Button rounded color="primary" size="large" type="submit">
                  &nbsp;&nbsp;&nbsp;&nbsp;SIGN UP&nbsp;&nbsp;&nbsp;&nbsp;
                </Button>
              </div>
            </div>
          </Route>
          <Route path="/password-confirmation">
            <div>
              <Title>Thank You!</Title>
              <p>We sent an email to your account!</p>
            </div>
          </Route>
          <Route path="/confirmation">
            <div>
              <Title>Thank You!</Title>
              <p>
                One of our customer service reps will contact you with your
                username and password as soon as possible.
              </p>
            </div>
          </Route>
          <Route path="/">
            <div>
              <Title id="login-header" size={1}>
                Login
              </Title>
              <Tab.Group>
                <Tab
                  active={accountType === "Client"}
                  onClick={() => setAccountType("Client")}
                >
                  Client
                </Tab>
                <Tab
                  active={accountType === "Employee"}
                  onClick={() => setAccountType("Employee")}
                >
                  GSL
                </Tab>
              </Tab.Group>
              <div className="landing-page-inputs">
                <Field>
                  <Control>
                    <Input
                      name="Username"
                      placeholder="Username"
                      size="large"
                      type="text"
                      onChange={handleChange}
                    />
                  </Control>
                </Field>

                <Field>
                  <Control>
                    <Input
                      name="Password"
                      placeholder="Password"
                      size="large"
                      type="password"
                      onChange={handleChange}
                    />
                  </Control>
                </Field>
                <Generic className="forgot-password">
                  <Link to="/forgot-password">Forgot your password?</Link>
                </Generic>
                <Button rounded color="primary" size="large" type="submit">
                  &nbsp;&nbsp;&nbsp;&nbsp;LOGIN &nbsp;&nbsp;&nbsp;&nbsp;
                </Button>
              </div>
            </div>
          </Route>
        </Switch>
      </form>
    </div>
  );
};

LandingPage.propTypes = {};

export default LandingPage;
