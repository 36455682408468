import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Field, Control, Button, Title } from "rbx";

import "./NotificationModal.scss";

const NotificationModal = ({ onConfirm, isPastTransitionDate }) => {
  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");
  const [message3, setMessage3] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (isPastTransitionDate) {
      setMessage(
        "As of Monday, October 2nd, 2023, new orders will be placed through the Smithers CTS Arizona Confident Cannabis account, which can be accessed "
      );
      setMessage2(
        ". Ordering through the Green Scientific Labs AZ LIMS is no longer offered. Past results and samples will be available in the GSL AZ LIMS until November 10th, 2023"
      );
      setMessage3("For more information about recent updates, please click ");
      setTitle("Notice of Recent Updates in Arizona");
    } else {
      setMessage(
        "Beginning Monday, October 2nd, 2023, we will be moving to Confident Cannabis and all new sample orders will take place there. We will also be officially rebranding to Smithers CTS Arizona. Find out more about our move to Confident Cannabis and name rebranding and how that will impact your operation "
      );
      setMessage2("");
      setMessage3("");
      setTitle("Changes are coming in Arizona!");
    }
  }, [isPastTransitionDate]);

  return (
    <div id="notification-modal">
      <Title>{title}</Title>
      <div className="notification-container">
        <p>
          {message}
          <a
            href={
              isPastTransitionDate
                ? "https://accounts.confidentcannabis.com/login?lab=smithersaz"
                : "https://www.smithers.com/industries/cannabis-testing/arizona-testing/move-to-confident-cannabis"
            }
            rel="noreferrer"
            target="_blank"
          >
            here
          </a>
          {message2 || null}.
        </p>
        {message3 ? (
          <React.Fragment>
            <br />
            <p>
              {message3}
              <a
                href="https://www.smithers.com/industries/cannabis-testing/arizona-testing/move-to-confident-cannabis"
                rel="noreferrer"
                target="_blank"
              >
                here
              </a>
              .
            </p>
          </React.Fragment>
        ) : null}
        <br />
        <Field align="centered" kind="group">
          <Control>
            <Button color="primary" onClick={onConfirm}>
              Continue
            </Button>
          </Control>
        </Field>
      </div>
    </div>
  );
};

NotificationModal.propTypes = {
  onConfirm: PropTypes.func,
  isPastTransitionDate: PropTypes.bool,
};
NotificationModal.defaultProps = {
  onConfirm: () => null,
  isPastTransitionDate: false,
};

export default NotificationModal;
