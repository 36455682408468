import { gql } from "@apollo/client";

export const CREATE_QC_TARGET_MUTATION = gql`
  mutation CREATE_QC_TARGET_MUTATION($data: QCTargetsCreateInput!) {
    createQCTargets(data: $data) {
      QCTargetID
    }
  }
`;

export const UPDATE_QC_TARGET_MUTATION = gql`
  mutation UPDATE_QC_TARGET_MUTATION(
    $data: QCTargetsUpdateInput!
    $where: QCTargetsWhereUniqueInput!
  ) {
    updateQCTargets(data: $data, where: $where) {
      QCTargetID
    }
  }
`;
