import React from "react";
import { Switch, Route } from "react-router-dom";

import NewTestPage from "./NewTestPage";

const ClientRouter = (props) => (
  <Switch>
    <Route path="/new-test/:tab?/:CartID?">
      <NewTestPage />
    </Route>
  </Switch>
);

export default ClientRouter;
