const GSLHolidays = [
  "1-Jan-22",
  "30-May-22",
  "4-Jul-22",
  "5-Sep-22",
  "24-Nov-22",
  "25-Dec-22",
  "1-Jan-23",
  "29-May-23",
  "4-Jul-23",
  "4-Sep-23",
  "23-Nov-23",
  "25-Dec-23",
  "1-Jan-24",
  "27-May-24",
  "4-Jul-24",
  "2-Sep-24",
  "28-Nov-24",
  "25-Dec-24",
  "1-Jan-25",
  "26-May-25",
  "4-Jul-25",
  "1-Sep-25",
  "27-Nov-25",
  "25-Dec-25",
  "1-Jan-26",
  "25-May-26",
  "4-Jul-26",
  "7-Sep-26",
  "26-Nov-26",
  "25-Dec-26",
  "1-Jan-27",
  "31-May-27",
  "4-Jul-27",
  "6-Sep-27",
  "25-Nov-27",
  "25-Dec-27",
  "1-Jan-28",
  "29-May-28",
  "4-Jul-28",
  "4-Sep-28",
  "23-Nov-28",
  "25-Dec-28",
  "1-Jan-29",
  "28-May-29",
  "4-Jul-29",
  "3-Sep-29",
  "22-Nov-29",
  "25-Dec-29",
  "1-Jan-30",
  "27-May-30",
  "4-Jul-30",
  "2-Sep-30",
  "28-Nov-30",
  "25-Dec-30",
  "1-Jan-31",
  "26-May-31",
  "4-Jul-31",
  "1-Sep-31",
  "27-Nov-31",
  "25-Dec-31",
  "1-Jan-32",
  "31-May-32",
  "4-Jul-32",
  "6-Sep-32",
  "25-Nov-32",
  "25-Dec-32",
];

export default GSLHolidays;
