const labs = {
  name: "labs",
  label: "Labs",
  icon: "flask",
  permissions: ["LAB"],
  active: (location) => {
    const [, p] = location.pathname.split("/");
    return /labs/.test(p);
  },
  to: "/labs",
};

export default labs;
