import { gql } from "@apollo/client";

export const DELETE_BATCH_ATTACHMENT_MUTATION = gql`
  mutation DELETE_BATCH_ATTACHMENT_MUTATION($where: BatchAttachmentsWhereUniqueInput!) {
    deleteBatchAttachments(where: $where) {
      BatchAttachmentID
    }
  }
`;

export const CREATE_BATCH_ATTACHMENT_MUTATION = gql`
  mutation CREATE_BATCH_ATTACHMENT_MUTATION($data: BatchAttachmentsCreateInput!) {
    createBatchAttachments(data: $data) {
      BatchAttachmentID
    }
  }
`;

export const UPDATE_BATCH_ATTACHMENT_MUTATION = gql`
  mutation UPDATE_BATCH_ATTACHMENT_MUTATION(
    $data:  BatchAttachmentsUpdateInput!
    $where: BatchAttachmentsWhereUniqueInput!
  ) {
    updateBatchAttachments(data: $data, where: $where) {
      BatchAttachmentID
    }
  }
`;