import React, { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Button, Box, Control, Title, Field } from "rbx";

import { useModal, useAuth } from "../../../../../context";
import {
  PageHeader,
  ListSearchInput,
  Loader,
  QrReaderModal,
} from "../../../../../components";
import { SINGLE_JOB_ORDER_QUERY } from "../../../../../graphql";
import { customToast as toast } from "../../../../../utils";

const PerUnitCalculatorSearchPage = () => {
  const history = useHistory();
  const { state: authState } = useAuth();

  const { setModalOpen } = useModal();

  const client = useApolloClient();

  const [loading, setLoading] = useState(false);
  const [where, setWhere] = useState({});

  const searchJobOrder = async (e) => {
    if (e.key === "Enter" && where.JobOrderID) {
      setLoading(true);
      const { data: jobOrdersData } = await client.query({
        query: SINGLE_JOB_ORDER_QUERY,
        variables: {
          where,
        },
      });
      setLoading(false);
      if (
        authState?.user?.LabID ===
        jobOrdersData?.findUniqueJobOrders?.Job?.LabID
      ) {
        if (jobOrdersData?.findUniqueJobOrders?.JobOrderID) {
          history.push(
            `/lims/per-unit-calculator/${jobOrdersData.findUniqueJobOrders.JobOrderID}`
          );
        } else {
          toast.error("Error. Please try a different Job Order ID");
        }
      } else {
        toast.error("This order belongs to a different lab");
      }
    }
  };

  const scanCode = (e) => {
    e.preventDefault();
    setModalOpen(
      true,
      <QrReaderModal
        onClose={({ result }) => setWhere({ JobOrderID: parseInt(result, 10) })}
      />
    );
  };

  if (loading) return <Loader />;

  return (
    <div className="per-unit-calculator-page">
      <PageHeader title="Per Unit Calculator" />
      <div style={{ justifyContent: "center", display: "flex" }}>
        <Box style={{ width: "50%", textAlign: "center" }}>
          <Control style={{ marginBottom: 10 }}>
            <Title subtitle>Search by Job Order ID</Title>
            <ListSearchInput
              placeholder="Enter Job Order ID..."
              onChange={(name, value) => {
                setWhere({
                  JobOrderID: parseInt(value, 10),
                });
              }}
              onKeyPress={searchJobOrder}
            />
          </Control>
          <Field kind="group" style={{ justifyContent: "center" }}>
            <Control>
              <Button
                color="primary"
                onClick={() => searchJobOrder({ key: "Enter" })}
              >
                Search
              </Button>
            </Control>
            <Control>
              <Button onClick={scanCode}>Scan QR Code</Button>
            </Control>
          </Field>
        </Box>
      </div>
    </div>
  );
};

PerUnitCalculatorSearchPage.propTypes = {};

export default PerUnitCalculatorSearchPage;
