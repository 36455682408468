import { gql } from "@apollo/client";

export const DELETE_ACTION_LIMIT_TEMPLATE_ANALYTE_MUTATION = gql`
  mutation DELETE_ACTION_LIMIT_TEMPLATE_ANALYTE_MUTATION($where: ActionLimitTemplateAnalytesWhereUniqueInput!) {
    deleteActionLimitTemplateAnalytes(where: $where) {
      ActionLimitTemplateAnalyteID
      TemplateID
      AnalyteID
    }
  }
`;

export const DELETE_MANY_ACTION_LIMIT_TEMPLATE_ANALYTES = gql`
  mutation DELETE_MANY_ACTION_LIMIT_TEMPLATE_ANALYTES($where: ActionLimitTemplateAnalytesWhereInput!) {
    deleteManyActionLimitTemplateAnalytes(where: $where) {
      count
    }
  }
`;

export const CREATE_ACTION_LIMIT_TEMPLATE_ANALYTE_MUTATION = gql`
  mutation CREATE_ACTION_LIMIT_TEMPLATE_ANALYTE_MUTATION($data:  ActionLimitTemplateAnalytesCreateInput!) {
    createAnalytes(data: $data) {
      ActionLimitTemplateAnalyteID
      TemplateID
      AnalyteID
    }
  }
`;

export const UPDATE_ACTION_LIMIT_TEMPLATE_ANALYTE_MUTATION = gql`
  mutation UPDATE_ACTION_LIMIT_TEMPLATE_ANALYTE_MUTATION(
    $data: ActionLimitTemplateAnalytesUpdateInput!
    $where: ActionLimitTemplateAnalytesWhereUniqueInput!
  ) {
    updateActionLimitTemplateAnalytes(data: $data, where: $where) {
      ActionLimitTemplateAnalyteID
      TemplateID
      AnalyteID
    }
  }
`;

export const UPDATE_DIFFERENT_ACTION_LIMIT_TEMPLATE_ANALYTE_MUTATION = gql`
  mutation UPDATE_DIFFERENT_ACTION_LIMIT_TEMPLATE_ANALYTE_MUTATION(
    $data: [UpdateActionLimitTemplateAnalyteInput!]!
  ) {
    updateDifferentActionLimitTemplateAnalytes(data: $data)
  }
`;

export const NEW_ACTION_LIMIT_TEMPLATE_ANALYTES = gql`
  mutation NEW_ACTION_LIMIT_TEMPLATE_ANALYTES(
    $data: [CreateActionLimitTemplateAnalyteInput!]!
  ) {
    newActionLimitTemplateAnalytes(data: $data)
  }
`;
