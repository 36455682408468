import React, { useState, useRef, useEffect, useMemo } from "react";

import SampleQueueFilters from "../components/SampleQueueFilters";
import {
  PageHeader,
  DataTable,
  ListSearchInput,
  IconButton,
} from "../../../components";
import { ALL_JOB_ORDER_TEST_SAMPLES_QUERY } from "../../../graphql";
import { generateColumns } from "./columns";
import { debounce } from "../../../utils";
import { useAuth } from "../../../context";

const INITIAL_STATE = {
  AND: [
    {
      Result: {
        equals: null,
      },
    },
    {
      PrepNo: {
        not: {
          equals: 1,
        },
      },
    },
  ],
};

const InQueueReprepTestsListPage = () => {
  const searchInputRef = useRef();
  const { state: authState } = useAuth();

  const [where, setWhere] = useState(INITIAL_STATE);
  const [selectedTest, setSelectedTest] = useState();
  const [showFilters, setShowFilters] = useState(true);
  const [labID, setLabID] = useState("");

  const orderBy = [{ id: "JobOrderID", asc: true }];

  useEffect(() => {
    setLabID(authState.user.LabID);
    if (authState.user.LabID !== "9999") {
      setWhere((prev) => ({
        ...prev,
        Job: {
          is: {
            LabID: {
              equals: parseInt(authState.user.LabID, 10),
            },
          },
        },
      }));
    }
  }, [authState]);

  const COLUMNS = useMemo(() => {
    const handlePrintLabel = (row) => {
      const win = window.open(
        `/lims/labels/JobOrderTestSampleID-${row.JobOrderTestSampleID}`
      );
      win.focus();
    };
    return generateColumns(handlePrintLabel);
  }, []);

  const onChangeTest = (name, value) => {
    setSelectedTest(value);
    setWhere((prev) => {
      if (value) {
        return {
          ...prev,
          AND: [
            {
              JobOrderTest: { is: { TestID: { equals: parseInt(value, 10) } } },
            },
            { Result: { equals: null } },
            { PrepNo: { not: { equals: 1 } } },
          ],
        };
      }

      if (labID?.toString() !== "9999") {
        return {
          ...INITIAL_STATE,
          Job: {
            is: {
              LabID: {
                equals: parseInt(authState.user.LabID, 10),
              },
            },
          },
        };
      }

      return INITIAL_STATE;
    });
  };

  const handleWhereChange = (value) => {
    if (value) {
      setWhere((prev) => ({
        ...prev,
        JobOrder: {
          is: {
            OR: [
              {
                OrderName: {
                  startsWith: value,
                },
              },
              {
                BatchOrderNumber: {
                  startsWith: value,
                },
              },
            ],
          },
        },
      }));
    } else {
      setWhere((prev) => {
        const prevValue = { ...prev };
        delete prevValue.JobOrder;
        return prevValue;
      });
    }
  };

  const onLabChange = (name, value) => {
    setWhere((prev) => ({
      ...prev,
      Job: {
        is: {
          LabID: {
            ...value,
          },
        },
      },
    }));
  };

  const onFiltersReset = () => {
    setWhere(INITIAL_STATE);
    setSelectedTest(undefined);
  };

  return (
    <div className="test-list-page">
      <PageHeader title="In Queue Reprep Samples">
        <ListSearchInput
          ref={searchInputRef}
          placeholder="Search By Job ID..."
          onChange={debounce((name, value) => handleWhereChange(value), 500)}
        />
        <IconButton
          icon="filter"
          onClick={(e) => {
            e.preventDefault();
            setShowFilters((prev) => !prev);
          }}
        />
      </PageHeader>
      {showFilters && (
        <SampleQueueFilters
          labID={labID}
          selectedTest={selectedTest}
          testQueueSelectCountWhere="RePrep"
          where={where}
          onChangeTest={onChangeTest}
          onFiltersReset={onFiltersReset}
          onLabChange={onLabChange}
        />
      )}
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateJobOrderTestSamples"
        columns={COLUMNS}
        name="findManyJobOrderTestSamples"
        orderBy={orderBy}
        query={ALL_JOB_ORDER_TEST_SAMPLES_QUERY}
        where={where}
      />
    </div>
  );
};

InQueueReprepTestsListPage.propTypes = {};

export default InQueueReprepTestsListPage;
