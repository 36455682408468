import React from "react";
import PropTypes from "prop-types";
import { Box, Title } from "rbx";
import { BooleanInput } from "../../../../../components";

const TestAnalytes = ({ analytes, onChange, onSelectAll, canUpdate }) => {
  const allChecked = analytes.filter((analyte) => analyte.checked);
  return (
    <Box>
      <div className="page-head">
        <div className="page-head-start">
          <Title subtitle>Analytes</Title>
        </div>
        <div className="page-head-end">
          <BooleanInput
            disabled={!canUpdate}
            label="Select All"
            value={allChecked.length === analytes.length}
            onChange={(_, checked) => onSelectAll(checked)}
          />
        </div>
      </div>
      <div className="test-checkboxes-container">
        {analytes.map((analyte) => (
          <BooleanInput
            key={analyte.AnalyteID}
            disabled={!canUpdate}
            label={analyte.Name}
            name={analyte.AnalyteID}
            value={analyte.checked}
            onChange={onChange}
          />
        ))}
      </div>
    </Box>
  );
};

TestAnalytes.propTypes = {
  analytes: PropTypes.array,
  onChange: PropTypes.func,
  onSelectAll: PropTypes.func,
  canUpdate: PropTypes.bool.isRequired,
};
TestAnalytes.defaultProps = {
  analytes: [],
  onChange: () => null,
  onSelectAll: () => null,
};
export default TestAnalytes;
