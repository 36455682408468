import React, { createContext, useState, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

import { useApolloClient, useMutation } from "@apollo/client";

import { toast } from "react-toastify";

import {
  LOGIN_MUTATION,
  CHECK_AUTH_QUERY,
  CHECK_PRIVATE_LABEL_AUTH_QUERY,
  LOGIN_PRIVATE_LABEL_MUTATION,
  LOGIN_AS_CLIENT_MUTATION,
  // FORGOT_PASSWORD_MUTATION,
  // COMPLETE_PASSWORD_RESET_MUTATION,
} from "../graphql";

import {
  getPermissionsFromUserGroupMemberships,
  getIsPostTransitionDate,
} from "../utils";

export const AuthContext = createContext(null);

const initialState = {
  isLoggedIn: false,
  isLoginPending: false,
  loginError: null,
  loginType: null,
  user: { UserID: null },
};

export const AuthProvider = ({ children }) => {
  const client = useApolloClient();

  const [state, setState] = useState(initialState);

  const [loginPrivateLabel] = useMutation(LOGIN_PRIVATE_LABEL_MUTATION);
  const [login] = useMutation(LOGIN_MUTATION);
  const [loginAsClient] = useMutation(LOGIN_AS_CLIENT_MUTATION);
  const history = useHistory();
  // const [requestPasswordReset] = useMutation(FORGOT_PASSWORD_MUTATION);
  // const [completePasswordReset] = useMutation(COMPLETE_PASSWORD_RESET_MUTATION);

  const handleLogin = async (
    Username,
    Password,
    loginType = "Client",
    IP,
    UserAgent
  ) => {
    try {
      setState((prev) => ({ ...prev, isLoginPending: true }));

      if (loginType === "PrivateLabel") {
        const {
          data: { loginPrivateLabel: loginData = { Token: "" } },
        } = await loginPrivateLabel({
          variables: { input: { Username, Password, IP, UserAgent } },
        });
        if (loginData.Token) {
          Cookies.set("lims_token", loginData.Token, { sameSite: true });

          setState((prev) => ({
            ...prev,
            user: { ...loginData.User, LoginType: "PrivateLabel" },
            isLoggedIn: true,
            loginType: "PrivateLabel",
          }));
        }
        history.push(`/first-time-password`);
      } else {
        const {
          data: { login: loginData = { Token: "" } },
        } = await login({
          variables: { input: { Username, Password, IP, UserAgent } },
        });

        if (loginData.User.LoginType !== loginType) {
          throw new Error("Incorrect User Type");
        }

        const isPastArizonaAccessTransitionDate = getIsPostTransitionDate(
          loginData?.User?.Lab?.State,
          "12/02/2023"
        );
        if (
          isPastArizonaAccessTransitionDate &&
          loginData?.User?.Username !== "KrisM"
        ) {
          throw new Error(
            "Access Denied. Place new orders with Smithers CTS Arizona through Confident Cannabis."
          );
        }

        if (loginData.Token) {
          Cookies.set("lims_token", loginData.Token, { sameSite: true });

          const isPastArizonaOrderTransitionDate = getIsPostTransitionDate(
            loginData?.User?.Lab?.State,
            "10/1/2023"
          );

          setState((prev) => ({
            ...prev,
            user: {
              ...loginData.User,
              LabName: loginData?.User?.Lab?.Company,
              LabState: loginData?.User?.Lab?.State,
              Lab: loginData?.User?.Lab,
              isPastArizonaOrderTransitionDate,
            },
            permissions: getPermissionsFromUserGroupMemberships(
              loginData?.User?.UserGroupMemberships
            ),
            isLoggedIn: true,
            loginType: loginData?.User?.LoginType,
            childClientIDs: loginData?.User?.ChildClientIDs || [],
          }));
        }
        history.push(`/first-time-password`);
      }
    } catch (err) {
      toast.error(err.message);
      Cookies.remove("lims_token");
      setState((prev) => ({ ...prev, loginError: err.message }));
    } finally {
      setState((prev) => ({ ...prev, isLoginPending: false }));
    }
  };

  // const handleForgotPassword = async (email) => {
  //   try {
  //     setState((prev) => ({ ...prev, isLoginPending: true }));

  //     await requestPasswordReset({
  //       variables: { input: { email, origin: window.origin } },
  //     });

  //     toast.success(`Password reset request sent. Please check your email.`);
  //   } catch (err) {
  //     toast.error(err.message);
  //     setState((prev) => ({ ...prev, loginError: err.message }));
  //   } finally {
  //     setState((prev) => ({ ...prev, isLoginPending: false }));
  //   }
  // };

  // const handleCompletePasswordReset = async (
  //   password,
  //   confirmPassword,
  //   resetPasswordToken
  // ) => {
  //   try {
  //     setState((prev) => ({ ...prev, isLoginPending: true }));
  //     if (password === confirmPassword) {
  //       await completePasswordReset({
  //         variables: { input: { password, resetPasswordToken } },
  //       });

  //       toast.success("Password reset completed. Please login.");
  //       window.location.href = window.origin;
  //     } else {
  //       throw new Error("Password and Confirm Password must be the same");
  //     }
  //   } catch (err) {
  //     toast.error(err.message);
  //     setState((prev) => ({ ...prev, loginError: err.message }));
  //   } finally {
  //     setState((prev) => ({ ...prev, isLoginPending: false }));
  //   }
  // };

  const handleLogout = useCallback(() => {
    Cookies.remove("lims_token");
    history.push("/");
    setState((prev) => ({ ...prev, ...initialState }));
  }, [history]);

  const handleCheckAuth = useCallback(async () => {
    try {
      setState((prev) => ({ ...prev, isLoginPending: true }));

      const {
        data: { checkAuth = false },
      } = await client.query({
        query: CHECK_AUTH_QUERY,
        fetchPolicy: "network-only",
      });

      const isPastArizonaOrderTransitionDate = getIsPostTransitionDate(
        checkAuth.Lab?.State,
        "10/1/2023"
      );

      if (!!checkAuth.UserID !== state.isLoggedIn) {
        setState((prev) => ({
          ...prev,
          isLoggedIn: !!checkAuth,
          loginType: checkAuth.LoginType,
          user: {
            UserID: checkAuth.UserID,
            LastName: checkAuth.LastName,
            FirstName: checkAuth.FirstName,
            Email: checkAuth.Email,
            Username: checkAuth.Username,
            MMTCInfo: checkAuth.MMTCInfo,
            Company: checkAuth.Company,
            Address: checkAuth.Address,
            City: checkAuth.City,
            State: checkAuth.State,
            Zip: checkAuth.Zip,
            Terms: checkAuth.Terms,
            BillingAddress: checkAuth.BillingAddress,
            LoginType: checkAuth.LoginType,
            ShippingMultiplier: checkAuth.ShippingMultiplier,
            SMSNotifications: checkAuth.SMSNotifications,
            EmailNotifications: checkAuth.EmailNotifications,
            ShowOnVerifyHemp: checkAuth.ShowOnVerifyHemp,
            VerifyHempCategories: checkAuth.VerifyHempCategories,
            LabID: checkAuth.LabID,
            LabName: checkAuth.Lab?.Company,
            LabState: checkAuth.Lab?.State,
            Lab: checkAuth.Lab,
            ChildClientIDs: checkAuth?.ChildClientIDs,
            AllowDupeBatchOrderNumber: checkAuth.AllowDupeBatchOrderNumber,
            isPastArizonaOrderTransitionDate,
          },
          permissions: getPermissionsFromUserGroupMemberships(
            checkAuth?.UserGroupMemberships
          ),
        }));
      }
    } catch (err) {
      if (state.isLoggedIn) {
        const {
          data: { checkPrivateLabelAuth = false },
        } = await client.query({
          query: CHECK_PRIVATE_LABEL_AUTH_QUERY,
          fetchPolicy: "network-only",
        });
        if (!checkPrivateLabelAuth) {
          handleLogout();
          toast.error("Not authorized.");
        } else {
          setState((prev) => ({
            ...prev,
            isLoggedIn: !!checkPrivateLabelAuth,
            loginType: "PrivateLabel",
            isLoginPending: false,
            user: {
              PrivateLabelUserID: checkPrivateLabelAuth.PrivateLabelUserID,
              OwnerUserID: checkPrivateLabelAuth.OwnerUserID,
              Username: checkPrivateLabelAuth.Username,
              RevShare: checkPrivateLabelAuth.RevShare,
              Status: checkPrivateLabelAuth.Status,
              CreateDate: checkPrivateLabelAuth.CreateDate,
              LoginType: "PrivateLabel",
            },
            permissions: getPermissionsFromUserGroupMemberships(
              checkPrivateLabelAuth?.UserGroupMemberships
            ),
          }));
        }
      }
    } finally {
      setState((prev) => ({ ...prev, isLoginPending: false }));
    }
  }, [client, handleLogout, state.isLoggedIn]);

  const handleLoginAsClient = async (Username) => {
    try {
      setState((prev) => ({ ...prev, isLoginPending: true }));
      const {
        data: { loginAsClient: loginData = { Token: "" } },
      } = await loginAsClient({
        variables: { input: { equals: Username } },
      });

      if (loginData.Token) {
        Cookies.set("lims_token", loginData.Token, { sameSite: true });

        setState((prev) => ({
          ...prev,
          user: {
            ...loginData.User,
            LabName: loginData?.User?.Lab?.Company,
            LabState: loginData?.User?.Lab?.State,
            Lab: loginData?.User?.Lab,
          },
          permissions: getPermissionsFromUserGroupMemberships(
            loginData?.User?.UserGroupMemberships
          ),
          isLoggedIn: true,
          loginType: loginData?.User?.LoginType,
          childClientIDs: loginData?.User?.ChildClientIDs || [],
        }));
      }
    } catch (err) {
      toast.error(err.message);
      Cookies.remove("lims_token");
      setState((prev) => ({ ...prev, loginError: err.message }));
    } finally {
      setState((prev) => ({ ...prev, isLoginPending: false }));
    }
  };

  return (
    <AuthContext.Provider
      value={{
        state,
        handleLogin,
        handleLogout,
        handleCheckAuth,
        handleLoginAsClient,
        // handleForgotPassword,
        // handleCompletePasswordReset,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
