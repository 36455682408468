import { gql } from "@apollo/client";

export const ALL_ACTION_LIMIT_TEMPLATES_QUERY = gql`
  query ALL_ACTION_LIMIT_TEMPLATES_QUERY(
    $where: ActionLimitTemplatesWhereInput
    $orderBy: [ActionLimitTemplatesOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyActionLimitTemplates(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      TemplateID
      TestCategory
      TemplateType
      TemplateState
      TemplateName
      IsPrimary
    }
  }
`;

export const SINGLE_ACTION_LIMIT_TEMPLATE_QUERY = gql`
  query SINGLE_ACTION_LIMIT_TEMPLATE_QUERY(
    $where: ActionLimitTemplatesWhereUniqueInput!
  ) {
    findUniqueActionLimitTemplates(where: $where) {
      TemplateID
      TestCategory
      TemplateType
      TemplateState
      TemplateName
      IsPrimary
      SubstanceType
      MedicalType
      MachineType
      ProductCategories {
        Category {
          RecId
          CodeDescription
        }
      }
    }
  }
`;
