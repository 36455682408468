import React from "react";
import PropTypes from "prop-types";
import { Tab, Tag } from "rbx";

import OrderForm from "../OrderForm";
import { STATUS_COLORS } from "../../../../../JobOrders/JobOrdersListPage/columns";

const OrderTabs = ({
  JobID,
  JobOrderID,
  history,
  onChange,
  inputs,
  disabled,
  updatePerUnitCalculation,
  onAddTestToJobOrder,
  onOpenResultModal,
  JobData,
  allTestApproved,
  canExportCsv,
}) => {
  const JobOrderSelected =
    (JobOrderID &&
      inputs.JobOrders.find(
        (jobOrder) =>
          parseInt(jobOrder.JobOrderID, 10) === parseInt(JobOrderID, 10)
      )) ||
    null;

  const JobOrders =
    (inputs.JobOrders &&
      Array.isArray(inputs.JobOrders) &&
      inputs.JobOrders.length > 0 &&
      inputs.JobOrders) ||
    null;

  return (
    <div key="job-order-tabs">
      <Tab.Group kind="boxed">
        {JobOrders &&
          JobOrders.map((jobOrder) => (
            <Tab
              key={jobOrder.JobOrderID}
              active={JobOrderID === jobOrder.JobOrderID}
              as={Tag}
              color={
                STATUS_COLORS.Status[jobOrder.JobOrderStatus.CodeDescription]
              }
              onClick={() =>
                history.push(
                  `/lims/test-status/${JobID}/${jobOrder.JobOrderID}`
                )
              }
            >
              {jobOrder.JobOrderID}
            </Tab>
          ))}
      </Tab.Group>
      {JobOrderSelected && (
        <OrderForm
          JobData={JobData}
          allTestApproved={allTestApproved}
          canExportCsv={canExportCsv}
          disabled={disabled}
          inputs={inputs.JobOrders.find(
            (jobOrder) =>
              parseInt(jobOrder.JobOrderID, 10) === parseInt(JobOrderID, 10)
          )}
          updatePerUnitCalculation={() =>
            updatePerUnitCalculation(JobOrderSelected)
          }
          user={inputs.User}
          onAddTestToJobOrder={onAddTestToJobOrder}
          onChange={onChange}
          onOpenResultModal={onOpenResultModal}
        />
      )}
    </div>
  );
};

OrderTabs.propTypes = {
  JobID: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  inputs: PropTypes.object.isRequired,
  JobOrderID: PropTypes.string,
  updatePerUnitCalculation: PropTypes.func,
  onAddTestToJobOrder: PropTypes.func,
  onOpenResultModal: PropTypes.func,
  JobData: PropTypes.object,
  allTestApproved: PropTypes.bool,
  canExportCsv: PropTypes.bool,
};

OrderTabs.defaultProps = {
  JobOrderID: null,
  updatePerUnitCalculation: () => null,
  onAddTestToJobOrder: () => null,
  onOpenResultModal: () => null,
  JobData: {},
  allTestApproved: false,
  canExportCsv: false,
};

export default OrderTabs;
