import React, { useState, useEffect, useMemo } from "react";
import { useMutation, useApolloClient } from "@apollo/client";
import PropTypes from "prop-types";

import { useAuth, useModal } from "../../../../../context";
import {
  DataTable,
  PageHeader,
  ListSearchInput,
  Confirmation,
  ReasonModal,
} from "../../../../../components";
import {
  ALL_JOB_ORDERS_QUERY,
  UPDATE_JOB_ORDER_MUTATION,
  FIRST_SYSTEM_CODE_QUERY,
  CREATE_JOB_LOG_MUTATION,
  SEND_ORDER_COMPLETE_EMAIL_MUTATION,
} from "../../../../../graphql";
import { generateColumns } from "./columns";
import { customToast as toast, debounce } from "../../../../../utils";

const INITIAL_STATE = {
  JobOrderStatus: {
    is: {
      CodeDescription: {
        equals: "In Review",
      },
    },
  },
};

const FinalReviewListPage = ({ routePermissions }) => {
  const { state: authState } = useAuth();
  const orderBy = [{ id: "JobID", desc: true }];
  const { setModalOpen } = useModal();
  const client = useApolloClient();

  const [where, setWhere] = useState(INITIAL_STATE);
  const [canUpdate, setCanUpdate] = useState(true);

  const [updateJobOrder] = useMutation(UPDATE_JOB_ORDER_MUTATION);
  const [createJobLog] = useMutation(CREATE_JOB_LOG_MUTATION);
  const [sendJobOrderCompleteEmail] = useMutation(
    SEND_ORDER_COMPLETE_EMAIL_MUTATION
  );

  useEffect(() => {
    if (authState.user.LabID !== "9999") {
      setWhere((prev) => ({
        ...prev,
        Job: {
          is: {
            LabID: {
              equals: parseInt(authState.user.LabID, 10),
            },
          },
        },
      }));
    }
  }, [authState]);

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  const COLUMNS = useMemo(() => {
    const handleReleaseCOA = ({ JobOrderID }) => {
      const performReleaseCOA = async () => {
        try {
          const { data: sysCodeData } = await client.query({
            query: FIRST_SYSTEM_CODE_QUERY,
            variables: {
              where: {
                AND: [
                  {
                    Category: {
                      equals: "Status",
                    },
                  },
                  {
                    CodeDescription: {
                      equals: "Completed",
                    },
                  },
                ],
              },
            },
          });
          await updateJobOrder({
            variables: {
              where: {
                JobOrderID: parseInt(JobOrderID, 10),
              },
              data: {
                JobOrderStatus: {
                  connect: {
                    RecId: parseInt(sysCodeData.findFirstSystemCodes.RecId, 10),
                  },
                },
                OrderCompleteDate: { set: new Date() },
                FinalReviewBy: {
                  set: parseInt(authState?.user?.UserID, 10),
                },
              },
            },
            refetchQueries: [
              {
                query: ALL_JOB_ORDERS_QUERY,
                variables: {
                  where,
                },
                fetchPolicy: "network-only",
              },
            ],
          });
          setModalOpen(false);
          await sendJobOrderCompleteEmail({
            variables: {
              where: { JobOrderID },
            },
          });

          toast.success("COA released.");
        } catch (err) {
          toast.error("Error releasing COA.");
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to release COA on this job order"
          onCancel={() => setModalOpen(false, "")}
          onConfirm={performReleaseCOA}
        />
      );
    };

    const handleReviewFlagToggle = ({ JobOrderID, ReviewFlag, JobID }) => {
      const performReviewFlagToggle = async (reason) => {
        try {
          const { data: sysCodeData } = await client.query({
            query: FIRST_SYSTEM_CODE_QUERY,
            variables: {
              where: {
                AND: [
                  {
                    Category: {
                      equals: "Status",
                    },
                  },
                  {
                    CodeDescription: {
                      equals: "In Progress",
                    },
                  },
                ],
              },
            },
          });
          await updateJobOrder({
            variables: {
              where: {
                JobOrderID: parseInt(JobOrderID, 10),
              },
              data: {
                ReviewFlag: {
                  set: !ReviewFlag,
                },
                JobOrderStatus: {
                  connect: {
                    RecId: parseInt(sysCodeData.findFirstSystemCodes.RecId, 10),
                  },
                },
              },
            },
            refetchQueries: [
              {
                query: ALL_JOB_ORDERS_QUERY,
                variables: {
                  where,
                },
                fetchPolicy: "network-only",
              },
            ],
          });
          await createJobLog({
            variables: {
              data: {
                JobID: parseInt(JobID, 10),
                JobOrderID: parseInt(JobOrderID, 10),
                Change: `Set Order ${JobOrderID} review flag to ${!ReviewFlag}`,
                ReasonForChange: reason,
                CreatedBy: authState.user.Username,
                ModifiedBy: authState.user.Username,
              },
            },
          });

          setModalOpen(false);
          toast.success("Job order review flag updated.");
        } catch (err) {
          toast.error("Error updating job order review flag.");
        }
      };
      setModalOpen(
        true,
        <ReasonModal
          message="Are you sure you want to update this job order review flag?"
          onCancel={() => setModalOpen(false)}
          onConfirm={(reason) => performReviewFlagToggle(reason)}
        />
      );
    };

    return generateColumns(handleReleaseCOA, handleReviewFlagToggle, canUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setModalOpen, canUpdate]);

  return (
    <div className="job-list-page">
      <PageHeader title="Final Review">
        <ListSearchInput
          placeholder="Job ID or Order ID..."
          onChange={debounce((name, value) => {
            if (value) {
              setWhere((prev) => ({
                ...prev,
                OR: [
                  { JobID: { equals: value } },
                  { JobOrderID: { equals: value } },
                ],
              }));
            } else {
              setWhere(INITIAL_STATE);
            }
          }, 500)}
        />
      </PageHeader>
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateJobOrders"
        columns={COLUMNS}
        name="findManyJobOrders"
        orderBy={orderBy}
        query={ALL_JOB_ORDERS_QUERY}
        where={where}
      />
    </div>
  );
};

FinalReviewListPage.propTypes = {
  routePermissions: PropTypes.array,
};

FinalReviewListPage.defaultProps = {
  routePermissions: [],
};

export default FinalReviewListPage;
