import React from "react";
import { Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";

import {
  SampleTrackingListPage,
  SampleTrackingPage,
  IntakeEditPage,
  SampleLocationListPage,
  MoistureWaterFilthSearchPage,
  IntakeSearchPage,
  PackageTemplatePage,
  PerUnitCalculatorSearchPage,
  PerUnitCalculatorEditPage,
  InternalNotesSearchPage,
  InternalNotesEditPage,
  BatchRecordsListPage,
  SamplePrepPage,
  EditBatchPage,
  LabelCreatePage,
  LabelDisplayPage,
  ImportResultsPage,
  TestStatusEditPage,
  AddBatchPage,
  BatchLabelDisplayPage,
  ComplianceReviewListPage,
  FinalReviewListPage,
  QCTargetsListPage,
  QualifierListPage,
} from "./Pages";

const LimsRouter = ({ routePermissions }) => (
  <Switch>
    <Route path="/lims/test-status/:JobID/:JobOrderID?">
      <TestStatusEditPage routePermissions={routePermissions} />
    </Route>
    <Route path="/lims/intake/:JobID/:JobOrderID?">
      <IntakeEditPage routePermissions={routePermissions} />
    </Route>
    <Route path="/lims/intake">
      <IntakeSearchPage routePermissions={routePermissions} />
    </Route>
    <Route path="/lims/moisture-water-filth/:JobOrderID?">
      <MoistureWaterFilthSearchPage routePermissions={routePermissions} />
    </Route>
    <Route path="/lims/sample-tracking/:SampleTrackingID">
      <SampleTrackingPage routePermissions={routePermissions} />
    </Route>
    <Route path="/lims/sample-tracking">
      <SampleTrackingListPage routePermissions={routePermissions} />
    </Route>
    <Route path="/lims/template-packages">
      <PackageTemplatePage routePermissions={routePermissions} />
    </Route>
    <Route path="/lims/sample-locations/:SampleTrackingID?">
      <SampleLocationListPage routePermissions={routePermissions} />
    </Route>
    <Route path="/lims/labels/:id/:repeat?">
      <LabelDisplayPage routePermissions={routePermissions} />
    </Route>
    <Route path="/lims/labels">
      <LabelCreatePage routePermissions={routePermissions} />
    </Route>
    <Route path="/lims/per-unit-calculator/:JobOrderID">
      <PerUnitCalculatorEditPage routePermissions={routePermissions} />
    </Route>
    <Route path="/lims/per-unit-calculator">
      <PerUnitCalculatorSearchPage routePermissions={routePermissions} />
    </Route>
    <Route path="/lims/internal-notes/:JobOrderID">
      <InternalNotesEditPage routePermissions={routePermissions} />
    </Route>
    <Route path="/lims/internal-notes">
      <InternalNotesSearchPage routePermissions={routePermissions} />
    </Route>
    <Route path="/lims/batch-records/add">
      <AddBatchPage routePermissions={routePermissions} />
    </Route>
    <Route path="/lims/batch-records/:BatchID">
      <EditBatchPage routePermissions={routePermissions} />
    </Route>
    <Route path="/lims/batch-records">
      <BatchRecordsListPage routePermissions={routePermissions} />
    </Route>
    <Route path="/lims/import-results">
      <ImportResultsPage routePermissions={routePermissions} />
    </Route>
    <Route path="/lims/sample-prep">
      <SamplePrepPage routePermissions={routePermissions} />
    </Route>
    <Route path="/lims/batch-labels/:entity">
      <BatchLabelDisplayPage routePermissions={routePermissions} />
    </Route>
    <Route path="/lims/compliance-review">
      <ComplianceReviewListPage routePermissions={routePermissions} />
    </Route>
    <Route path="/lims/final-review">
      <FinalReviewListPage routePermissions={routePermissions} />
    </Route>
    <Route path="/lims/qc-targets/:QCTargetID?">
      <QCTargetsListPage routePermissions={routePermissions} />
    </Route>
    <Route path="/lims/qualifiers/:QualifierID?">
      <QualifierListPage routePermissions={routePermissions} />
    </Route>
  </Switch>
);

LimsRouter.propTypes = {
  routePermissions: PropTypes.array,
};

LimsRouter.defaultProps = {
  routePermissions: [],
};

export default LimsRouter;
