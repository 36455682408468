/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { Input, Icon, Generic, Control, Field } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import TestInfoItem from "./TestInfoItem";
import {
  SystemCodeSelect,
  BooleanInput,
  StateSelect,
} from "../../../../components";
import { useAuth } from "../../../../context";

const InnerListContainer = styled.div`
  margin: 8px;
  display: flex;
  flex-direction: column;
  height: ${({ name }) => (name === "UserTest" ? "80vh" : "40rem")};
  width: 100%;
  padding: 0.5rem;
  flex: 1 0 auto;
`;

const ColumContainer = styled.div`
  margin: 8px;
  width: 100%;
  max-width: ${({ name }) => (name === "UserTest" ? "360px" : "300px")};
  position: ${({ name }) => (name === "UserTest" ? "sticky" : "static")};
  top: 0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: ${({ isDragging }) => (isDragging ? "#E3FCEF" : "#f1f1f1")};
  transition: background-color 0.2s ease;
  border-bottom: ${({ title }) =>
    title === "UserTest" ? "solid 1px #e0dede" : ""};
  border-radius: 0px 20px 0px 0px;
  padding: 0.75rem 0.5rem 0.5rem 0.5rem;
`;

const Title = styled.h4`
  padding: 8px;
  transition: background-color ease 0.2s;
  flex-grow: 1;
  user-select: none;
  position: relative;
  &:focus {
    outline: 2px solid #998dd9;
    outline-offset: 2px;
  }
`;

const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
  if (isDraggingOver) {
    return "#d6d6d6";
  }
  if (isDraggingFrom) {
    return "#d6d6d6";
  }
  return "#f1f1f1";
};

const Wrapper = styled.div`
  background-color: ${(props) =>
    getBackgroundColor(props.isDraggingOver, props.isDraggingFrom)};
  display: flex;
  flex-direction: column;
  opacity: ${({ isDropDisabled }) => (isDropDisabled ? 0.5 : "inherit")};
  border: 8px;
  transition: background-color 0.2s ease, opacity 0.1s ease;
  user-select: none;
  width: 100%;
  max-height: ${({ name }) => (name === "UserTest" ? "" : "32rem")};
  padding-bottom: 10px;
`;

const scrollContainerHeight = 250;

const DropZone = styled.div`
  min-height: ${scrollContainerHeight}px;
  padding-bottom: 8px;
  padding-right: 1rem;
  height: 100%;
`;

const ScrollContainer = styled.div`
  overflow-x: hidden;
  height: 100%;
`;

const ItemContainer = styled.div`
  height: fit-content;
  margin-bottom: 1em;
  border-radius: 20px 0px 20px 20px;
  border: solid 0.5px #059e42;
`;

// /////////////////////////////
// INNER ITEM LIST COMPONENT //
// ///////////////////////////
const InnerItemList = ({ items, name, removePackageTest, disabled }) =>
  items.map((item, index) => (
    <ItemContainer>
      <Draggable
        key={item.id}
        draggableId={item.id}
        index={index}
        shouldRespectForceTouch={false}
      >
        {(dragProvided, dragSnapshot) => (
          <TestInfoItem
            key={item.id}
            disabled={disabled}
            isDragging={dragSnapshot.isDragging}
            isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
            item={item}
            name={name}
            provided={dragProvided}
            removePackageTest={removePackageTest}
          />
        )}
      </Draggable>
    </ItemContainer>
  ));

InnerItemList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string.isRequired,
  removePackageTest: PropTypes.func,
  disabled: PropTypes.bool,
};

InnerItemList.defaultProps = {
  items: [],
  removePackageTest: () => null,
  disabled: false,
};

// ///////////////////////
// INNER LIST COMPONENT //
// //////////////////////
const InnerList = ({
  items,
  dropProvided,
  title,
  name,
  removePackageTest,
  disabled,
}) => (
  <InnerListContainer name={name}>
    {title}
    <DropZone ref={dropProvided.innerRef}>
      <InnerItemList
        disabled={disabled}
        items={Array.isArray(items) ? items : []}
        name={name}
        removePackageTest={removePackageTest}
      />
      {dropProvided.placeholder}
    </DropZone>
  </InnerListContainer>
);
InnerList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  dropProvided: PropTypes.object,
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  removePackageTest: PropTypes.func,
  disabled: PropTypes.bool,
};
InnerList.defaultProps = {
  items: [],
  dropProvided: {},
  title: "",
  removePackageTest: () => null,
  disabled: false,
};

// ///////////////////////
// ITEM LIST COMPONENT //
// /////////////////////
const ItemList = ({
  ignoreContainerClipping,
  internalScroll,
  scrollContainerStyle,
  isDropDisabled,
  isCombineEnabled,
  listId,
  listType,
  style,
  items,
  title,
  name,
  removePackageTest,
  disabled,
}) => (
  <Droppable
    droppableId={listId}
    ignoreContainerClipping={ignoreContainerClipping}
    isCombineEnabled={isCombineEnabled}
    isDropDisabled={isDropDisabled}
    type={listType}
  >
    {(dropProvided, dropSnapshot) => (
      <Wrapper
        isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
        isDraggingOver={dropSnapshot.isDraggingOver}
        isDropDisabled={isDropDisabled}
        name={name}
        style={style}
        {...dropProvided.droppableProps}
      >
        {internalScroll ? (
          <ScrollContainer style={scrollContainerStyle}>
            <InnerList
              disabled={disabled}
              dropProvided={dropProvided}
              items={items}
              name={name}
              removePackageTest={removePackageTest}
              title={title}
            />
          </ScrollContainer>
        ) : (
          <InnerList
            disabled={disabled}
            dropProvided={dropProvided}
            items={items}
            name={name}
            removePackageTest={removePackageTest}
            title={title}
          />
        )}
      </Wrapper>
    )}
  </Droppable>
);

ItemList.propTypes = {
  ignoreContainerClipping: PropTypes.bool,
  internalScroll: PropTypes.bool,
  scrollContainerStyle: PropTypes.object,
  isDropDisabled: PropTypes.bool,
  isCombineEnabled: PropTypes.bool,
  listId: PropTypes.string,
  listType: PropTypes.string,
  style: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  removePackageTest: PropTypes.func,
  disabled: PropTypes.bool,
};

ItemList.defaultProps = {
  ignoreContainerClipping: false,
  internalScroll: false,
  scrollContainerStyle: {},
  isDropDisabled: false,
  isCombineEnabled: false,
  listId: "",
  listType: "",
  style: {},
  items: [],
  title: "",
  removePackageTest: () => null,
  disabled: false,
};

// ////////////////////
// COLUMN COMPONENT //
// ///////////////////
const Column = ({
  title,
  items,
  index,
  isScrollable,
  isCombineEnabled,
  name,
  onChange,
  handleHeaderChange,
  removePackage,
  removePackageTest,
  mainItems,
  packagePrice,
  disabled,
  medicalType,
  handleMedicalChange,
  displayAsTest,
  onDisplayAsTestChange,
  packageState,
  onPackageStateChange,
}) => {
  const { state: authState } = useAuth();
  const [totalValues, setTotalValues] = useState({
    mainSum: "$0.00",
    savedMoney: "$0.00",
  });
  const canUpdateState = useMemo(
    () => authState?.user?.Lab?.Company === "All Location",
    [authState]
  );

  useEffect(() => {
    const allUserTestsIds = items.map((item) =>
      parseInt(item.content.Test.TestID, 10)
    );
    const filteredItems = mainItems.filter((item) =>
      allUserTestsIds.includes(parseInt(item.content.Test.TestID, 10))
    );

    const mainSum = filteredItems.reduce(
      (prev, curr) => (prev += parseFloat(curr.content?.Price || 0, 10)),
      0
    );
    setTotalValues({
      mainSum: `$${mainSum}`,
      savedMoney: `$${mainSum - parseFloat(packagePrice, 10)}`,
    });
  }, [items, mainItems, packagePrice]);

  return (
    <Draggable draggableId={name} index={index} isDragDisabled={disabled}>
      {(provided, snapshot) => (
        <ColumContainer
          ref={provided.innerRef}
          name={name}
          {...provided.draggableProps}
        >
          <Header isDragging={snapshot.isDragging} title={title}>
            {title === "UserTest" ? (
              <Title
                isDragging={snapshot.isDragging}
                {...provided.dragHandleProps}
              >
                Tests
              </Title>
            ) : (
              <React.Fragment>
                <Field kind="group">
                  <Input
                    required
                    defaultValue={title}
                    disabled={disabled}
                    id={title}
                    name={title}
                    size="small"
                    {...provided.dragHandleProps}
                    onBlur={(e) =>
                      handleHeaderChange(e.target.name, e.target.value)
                    }
                  />
                  {title !== "UserTest" && (
                    <Control
                      expanded
                      iconLeft
                      style={{
                        margin: "0 0.1rem",
                      }}
                    >
                      <Input
                        defaultValue={packagePrice}
                        disabled={disabled}
                        name={name}
                        size="small"
                        type="number"
                        onBlur={(e) => onChange(e.target.name, e.target.value)}
                      />
                      <Icon align="left">
                        <FontAwesomeIcon icon="dollar-sign" />
                      </Icon>
                    </Control>
                  )}
                </Field>
                {!disabled && (
                  <div style={{ width: "10%", textAlign: "center" }}>
                    <Generic
                      className="delete-package-button"
                      onClick={() => removePackage(name)}
                    >
                      <Icon color="primary">
                        <FontAwesomeIcon icon="trash-alt" />
                      </Icon>
                    </Generic>
                  </div>
                )}
              </React.Fragment>
            )}
          </Header>
          {title !== "UserTest" && (
            <React.Fragment>
              <div
                style={{
                  backgroundColor: "#f1f1f1",
                  padding: "0 0.5rem 0.5rem 0.5rem",
                }}
              >
                <Field kind="group">
                  <Control expanded size="small">
                    <SystemCodeSelect
                      disabled={disabled}
                      name="MedicalType"
                      size="small"
                      value={medicalType}
                      where={{
                        Type: { equals: "Medical" },
                        Category: { equals: "Type" },
                        CodeName: { equals: "MedicalType" },
                      }}
                      onChange={(n, v) => handleMedicalChange(name, v)}
                    />
                  </Control>
                  <Control>
                    <BooleanInput
                      disabled={disabled}
                      label="Display As Test"
                      name=""
                      value={displayAsTest}
                      onChange={(_, value) =>
                        onDisplayAsTestChange(name, value)
                      }
                    />
                  </Control>
                </Field>
              </div>
            </React.Fragment>
          )}
          {title !== "UserTest" && (
            <Control
              expanded
              size="small"
              style={{
                width: "100%",
                backgroundColor: "#f1f1f1",
                padding: "0 0.5rem 0.5rem 0.5rem",
              }}
            >
              <StateSelect
                disabled={!canUpdateState}
                id="State"
                name="State"
                size="small"
                value={packageState}
                onChange={(_, value) => onPackageStateChange(name, value)}
              />
            </Control>
          )}
          {title !== "UserTest" && (
            <div
              style={{
                fontSize: 12,
                padding: "0 1rem 0.5rem 1rem",
                backgroundColor: "#f1f1f1",
                display: "flex",
                justifyContent: "space-evenly",
                borderBottom: "solid 1px #e0dede",
              }}
            >
              <div>
                Total: <span>{totalValues.mainSum}</span>
              </div>
              <div>
                Savings: <span>{totalValues.savedMoney}</span>
              </div>
            </div>
          )}
          <ItemList
            disabled={disabled}
            internalScroll={isScrollable}
            isCombineEnabled={Boolean(isCombineEnabled)}
            items={items}
            listId={name}
            listType="ITEM"
            name={name}
            removePackageTest={removePackageTest}
            style={{
              backgroundColor: snapshot.isDragging ? "#E3FCEF" : null,
              borderRadius: "0px 0px 20px 20px",
            }}
          />
        </ColumContainer>
      )}
    </Draggable>
  );
};

Column.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  index: PropTypes.number,
  isScrollable: PropTypes.bool,
  isCombineEnabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  handleHeaderChange: PropTypes.func,
  removePackage: PropTypes.func,
  removePackageTest: PropTypes.func,
  mainItems: PropTypes.arrayOf(PropTypes.object),
  packagePrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  medicalType: PropTypes.string,
  handleMedicalChange: PropTypes.func,
  displayAsTest: PropTypes.bool,
  onDisplayAsTestChange: PropTypes.func,
  packageState: PropTypes.string,
  onPackageStateChange: PropTypes.func,
};

Column.defaultProps = {
  title: "",
  items: [],
  index: null,
  isScrollable: false,
  isCombineEnabled: false,
  onChange: () => null,
  handleHeaderChange: () => null,
  removePackage: () => null,
  removePackageTest: () => null,
  mainItems: [],
  packagePrice: 0,
  disabled: false,
  medicalType: "",
  handleMedicalChange: () => null,
  onDisplayAsTestChange: () => null,
  displayAsTest: false,
  packageState: null,
  onPackageStateChange: () => null,
};

export default Column;
