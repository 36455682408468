import React, { createContext, useState, useContext } from "react";
import PropTypes from "prop-types";

export const ShowNavigationContext = createContext(null);

export const ShowNavigationProvider = ({ children }) => {
  const [showNavigation, setShowNavigation] = useState(true);

  const toggleShowNavigation = () => {
    setShowNavigation((prevState) => !prevState);
  };

  return (
    <ShowNavigationContext.Provider
      value={{ showNavigation, toggleShowNavigation }}
    >
      {children}
    </ShowNavigationContext.Provider>
  );
};

ShowNavigationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useShowNavigation = () => useContext(ShowNavigationContext);

export default ShowNavigationProvider;
