import React from "react";
import PropTypes from "prop-types";
import { Column, Field, Box, Input, Control, Label } from "rbx";
import StateSelect from "../../../../components/StateSelect";

const BillingAddress = ({ inputs, onChange, handleSave }) => (
  <form id="edit-client-form" onSubmit={handleSave}>
    <Column.Group>
      <Column>
        <Box>
          <Field kind="group">
            <Control expanded>
              <Label>First name</Label>
              <Input
                name="BillingFirstName"
                size="small"
                value={inputs?.BillingAddress?.BillingFirstName}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, "BillingAddress")
                }
              />
            </Control>
            <Control expanded>
              <Label>Last name</Label>
              <Input
                name="BillingLastName"
                size="small"
                value={inputs?.BillingAddress?.BillingLastName}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, "BillingAddress")
                }
              />
            </Control>
          </Field>
          <Field>
            <Control>
              <Label>Company</Label>
              <Input
                name="BillingCompany"
                size="small"
                value={inputs?.BillingAddress?.BillingCompany}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, "BillingAddress")
                }
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded>
              <Label>Address</Label>
              <Input
                name="BillingAddress"
                size="small"
                value={inputs?.BillingAddress?.BillingAddress}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, "BillingAddress")
                }
              />
            </Control>
            <Control expanded>
              <Label>Address 2</Label>
              <Input
                name="BillingAddress2"
                size="small"
                value={inputs?.BillingAddress?.BillingAddress2}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, "BillingAddress")
                }
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded>
              <Label>City</Label>
              <Input
                name="BillingCity"
                size="small"
                value={inputs?.BillingAddress?.BillingCity}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, "BillingAddress")
                }
              />
            </Control>
            <StateSelect
              id="BillingState"
              label="State/Province"
              name="BillingState"
              size="small"
              value={inputs?.BillingAddress?.BillingState}
              onChange={(name, value) =>
                onChange(name, value, "BillingAddress")
              }
            />
            <Control expanded>
              <Label>Zip/Postal</Label>
              <Input
                name="BillingZip"
                size="small"
                value={inputs?.BillingAddress?.BillingZip}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, "BillingAddress")
                }
              />
            </Control>
          </Field>
        </Box>
      </Column>
    </Column.Group>
  </form>
);

export default BillingAddress;

BillingAddress.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  handleSave: PropTypes.func,
};

BillingAddress.defaultProps = {
  inputs: {},
  onChange: (e) => e,
  handleSave: (e) => e,
};
