/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import { useAuth } from "../../../context";
import { ALL_PACKAGES_QUERY } from "../../../graphql";
import Loader from "../../../components/Loader";
import PackageBoard from "../components/PackageBoard";

const PackageTests = ({ onChange, selectedPackage }) => {
  const { state: authState } = useAuth();
  const [userTestPackages, setUserTestPackages] = useState([]);
  const [getPackages, packagesData] = useLazyQuery(ALL_PACKAGES_QUERY);

  useEffect(() => {
    if (authState?.user) {
      getPackages({
        variables: {
          where: {
            UserID: { equals: parseInt(authState.user.UserID, 10) },
            Type: { is: { CodeDescription: { equals: "User" } } },
            DisplayAsTest: { equals: false },
          },
          orderBy: [{ CreatedDateTime: "asc" }],
        },
      });
    }
  }, [getPackages, authState]);

  useEffect(() => {
    if (Array.isArray(packagesData?.data?.findManyPackages)) {
      const packages = packagesData.data.findManyPackages.filter(
        (pkg) => pkg.PackageTests.length > 0
      );
      setUserTestPackages(
        packages.map((pkg) => {
          const mainSum = pkg.PackageTests.reduce(
            (prev, curr) => (prev += parseFloat(curr.Price || 0, 10)),
            0
          );
          return {
            ...pkg,
            mainSum,
            savings: `${(
              100 -
              (parseFloat(pkg.Price, 10) * 100) / mainSum
            ).toFixed(0)}%`,
          };
        })
      );
    }
  }, [packagesData]);

  if (packagesData?.loading) return <Loader />;

  return (
    <PackageBoard
      packages={userTestPackages}
      selectedPackage={selectedPackage}
      onPackageChange={onChange}
    />
  );
};

PackageTests.propTypes = {
  onChange: PropTypes.func,
  selectedPackage: PropTypes.object,
};

PackageTests.defaultProps = {
  onChange: () => null,
  selectedPackage: null,
};

export default PackageTests;
