/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation, useLazyQuery } from "@apollo/client";
import { Title, Fieldset, Button, Control } from "rbx";

import { customToast as toast } from "../../../../../utils";
import TestForm from "../TestForm";
import TestAnalytes from "../TestAnalytes";
import Loader from "../../../../../components/Loader";
import BooleanInput from "../../../../../components/BooleanInput";
import {
  CREATE_TEST_MUTATION,
  LIST_TESTS_QUERY,
  SINGLE_TEST_QUERY,
  UPDATE_TEST_MUTATION,
  CREATE_DIFFERENT_USER_TESTS,
  UPDATE_MANY_USER_TESTS_MUTATION,
  ALL_ANALYTES_QUERY,
  CREATE_MANY_TEST_ANALYTE_MUTATION,
  DELETE_MANY_TEST_ANALYTES_MUTATION,
  CREATE_MANY_TEST_TEST_CATEGORIES_MUTATION,
  DELETE_MANY_TEST_TEST_CATEGORIES_MUTATION,
} from "../../../../../graphql";
import { useAuth } from "../../../../../context";

const INITIAL_STATE = {
  Name: "",
  Code: "",
  Description: "",
  CategoryPrice: "",
  TestCategoryID: 1,
  TestCategories: [],
  Active: true,
};

const convertInputToVariables = (variables, keys, adding = true) =>
  keys.reduce((acc, curr) => {
    if (variables[curr] || variables[curr] === false) {
      acc[curr] = variables[curr];
    } else {
      acc[curr] = null;
    }
    return acc;
  }, {});

const TestModal = ({ onComplete, TestID, canUpdate }) => {
  const { state: authState } = useAuth();
  const [loading, setLoading] = useState(!!TestID);
  const [analytes, setAnalytes] = useState([]);
  const [updatePriceForClients, setUpdatePriceForClients] = useState(false);
  const [originalTestCategories, setOriginalTestCategories] = useState([]);
  const [originalTestAnalytes, setOriginalTestAnalytes] = useState([]);
  const [isActiveDisabled, setIsActiveDisabled] = useState(false);

  const [createTest] = useMutation(CREATE_TEST_MUTATION);
  const [updateTest] = useMutation(UPDATE_TEST_MUTATION);
  const [createDifferentUserTests] = useMutation(CREATE_DIFFERENT_USER_TESTS);
  const [updateManyUserTests] = useMutation(UPDATE_MANY_USER_TESTS_MUTATION);
  const [createManyTestTestCategories] = useMutation(
    CREATE_MANY_TEST_TEST_CATEGORIES_MUTATION
  );
  const [deleteManyTestTestCategories] = useMutation(
    DELETE_MANY_TEST_TEST_CATEGORIES_MUTATION
  );
  const [createManyTestAnalytes] = useMutation(
    CREATE_MANY_TEST_ANALYTE_MUTATION
  );
  const [deleteManyTestAnalytes] = useMutation(
    DELETE_MANY_TEST_ANALYTES_MUTATION
  );

  const [inputs, setInputs] = useState({
    ...INITIAL_STATE,
  });

  const [getTestData, resultTestData] = useLazyQuery(SINGLE_TEST_QUERY);
  const [getAnalytesData, resultAnalytesData] = useLazyQuery(
    ALL_ANALYTES_QUERY,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (TestID) {
      getTestData({
        variables: {
          where: { TestID: parseInt(TestID, 10) },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [TestID, getTestData]);

  useEffect(() => {
    if (resultTestData?.data?.findUniqueTests) {
      const {
        data: { findUniqueTests },
      } = resultTestData;

      // eslint-disable-next-line no-extra-boolean-cast
      !!findUniqueTests?.PackageTests?.length
        ? setIsActiveDisabled(true)
        : setIsActiveDisabled(false);

      const TestTestCategories = findUniqueTests.TestTestCategories.map(
        ({ TestCategory }) => ({
          label: TestCategory.Name,
          value: TestCategory.TestCategoryID,
        })
      );

      setOriginalTestAnalytes(
        findUniqueTests.TestAnalytes.map((TestAnalyte) =>
          parseInt(TestAnalyte.AnalyteID, 10)
        )
      );

      setOriginalTestCategories(
        TestTestCategories.map((TestCategory) =>
          parseInt(TestCategory.value, 10)
        )
      );

      setInputs((prev) => ({
        ...prev,
        ...findUniqueTests,
        TestCategories: TestTestCategories,
      }));
      setLoading(false);
    }
  }, [resultTestData]);

  useEffect(() => {
    if (inputs.TestCategories.length) {
      const testCategoriesIds = inputs.TestCategories.map(({ value }) => ({
        TestCategoryID: {
          equals: parseInt(value, 10),
        },
      }));

      getAnalytesData({
        variables: {
          where: {
            OR: testCategoriesIds,
            Active: {
              equals: true,
            },
          },
          orderBy: { Name: "asc" },
        },
      });
    } else {
      setAnalytes([]);
    }
  }, [getAnalytesData, inputs.TestCategories]);

  useEffect(() => {
    if (
      resultAnalytesData?.data?.findManyAnalytes &&
      resultTestData?.data?.findUniqueTests
    ) {
      setAnalytes(
        resultAnalytesData.data.findManyAnalytes.map((analyte) => ({
          ...analyte,
          checked: !!resultTestData?.data?.findUniqueTests?.TestAnalytes.find(
            (testAnalyte) => testAnalyte.AnalyteID === analyte.AnalyteID
          ),
        }))
      );
    } else if (resultAnalytesData?.data?.findManyAnalytes && !TestID) {
      setAnalytes(
        resultAnalytesData.data.findManyAnalytes.map((analyte) => ({
          ...analyte,
          checked: false,
        }))
      );
    }
  }, [TestID, resultAnalytesData, resultTestData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (TestID) {
        const testCategoriesToCreate = [];
        const testCategoriesToDelete = [];

        if (originalTestCategories.length) {
          // all were deleted
          if (!inputs.TestCategories.length) {
            originalTestCategories.forEach((testCategory) => {
              testCategoriesToDelete.push({
                TestCategoryID: {
                  equals: parseInt(testCategory, 10),
                },
                TestID: {
                  equals: parseInt(TestID, 10),
                },
              });
            });
          } else {
            //  to create
            inputs.TestCategories.forEach((testCategory) => {
              if (
                !originalTestCategories.includes(
                  parseInt(testCategory.value, 10)
                )
              ) {
                testCategoriesToCreate.push({
                  TestID: parseInt(TestID, 10),
                  TestCategoryID: parseInt(testCategory.value, 10),
                  CreatedBy: authState.user.Username,
                  ModifiedBy: authState.user.Username,
                });
              }
            });

            // to delete
            const currentTestCategoriesIds = inputs.TestCategories.map(
              (testCategory) => parseInt(testCategory.value, 10)
            );
            originalTestCategories.forEach((originalTestCategory) => {
              if (
                !currentTestCategoriesIds.includes(
                  parseInt(originalTestCategory, 10)
                )
              ) {
                testCategoriesToDelete.push({
                  TestCategoryID: {
                    equals: parseInt(originalTestCategory, 10),
                  },
                  TestID: {
                    equals: parseInt(TestID, 10),
                  },
                });
              }
            });
          }
        } else if (inputs.TestCategories.length) {
          // all were created
          inputs.TestCategories.forEach((testCategory) => {
            testCategoriesToCreate.push({
              TestID: parseInt(TestID, 10),
              TestCategoryID: parseInt(testCategory.value, 10),
              CreatedBy: authState.user.Username,
              ModifiedBy: authState.user.Username,
            });
          });
        }

        const testAnalytesToCreate = [];
        const testAnalytesToDelete = [];
        const testAnalytesChecked = analytes.filter(
          (analyte) => analyte.checked
        );
        const testAnalytesUnchecked = analytes.filter(
          (analyte) => !analyte.checked
        );

        // create test analytes if no original analytes found
        if (!originalTestAnalytes.length && testAnalytesChecked.length) {
          testAnalytesChecked.forEach((analyte) => {
            testAnalytesToCreate.push({
              AnalyteID: parseInt(analyte.AnalyteID, 10),
              CreatedBy: authState.user.Username,
              ModifiedBy: authState.user.Username,
              TestID: parseInt(TestID, 10),
            });
          });
          // delete test analytes if there are no current analytes
        } else if (originalTestAnalytes.length && !testAnalytesChecked.length) {
          originalTestAnalytes.forEach((analyteID) => {
            testAnalytesToDelete.push({
              AnalyteID: {
                equals: parseInt(analyteID, 10),
              },
              TestID: {
                equals: parseInt(TestID, 10),
              },
            });
          });
          // create new ones
        } else {
          if (originalTestAnalytes.length && testAnalytesChecked.length) {
            testAnalytesChecked.forEach(({ AnalyteID }) => {
              if (!originalTestAnalytes.includes(parseInt(AnalyteID, 10))) {
                testAnalytesToCreate.push({
                  AnalyteID: parseInt(AnalyteID, 10),
                  CreatedBy: authState.user.Username,
                  ModifiedBy: authState.user.Username,
                  TestID: parseInt(TestID, 10),
                });
              }
            });
          }
          if (originalTestAnalytes.length && testAnalytesUnchecked.length) {
            testAnalytesUnchecked.forEach(({ AnalyteID }) => {
              if (originalTestAnalytes.includes(parseInt(AnalyteID, 10))) {
                testAnalytesToDelete.push({
                  AnalyteID: {
                    equals: parseInt(AnalyteID, 10),
                  },
                  TestID: {
                    equals: parseInt(TestID, 10),
                  },
                });
              }
            });
          }
        }

        const obj = Object.keys(INITIAL_STATE).reduce((acc, curr) => {
          if (inputs[curr] !== null) {
            return {
              ...acc,
              [curr]: {
                set: inputs[curr],
              },
            };
          }
          return acc;
        }, {});

        delete obj.TestCategories;

        const data = {
          ...obj,
          CategoryPrice: { set: parseFloat(inputs.CategoryPrice) },
        };
        delete data.TestCategoryID;

        await updateTest({
          variables: {
            data,
            where: {
              TestID: parseInt(TestID, 10),
            },
          },
        });

        if (updatePriceForClients || !inputs.Active) {
          const updatePriceForClientsData = {};

          if (updatePriceForClients) {
            updatePriceForClientsData.UserPrice = {
              set: parseFloat(inputs.CategoryPrice),
            };
          }
          if (!inputs.Active) {
            updatePriceForClientsData.Active = {
              set: 0,
            };
          }
          await updateManyUserTests({
            variables: {
              where: {
                TestID: {
                  equals: parseInt(TestID, 10),
                },
              },
              data: updatePriceForClientsData,
            },
          });
        }

        testCategoriesToDelete.forEach(async (testCategoryToDelete) => {
          await deleteManyTestTestCategories({
            variables: {
              where: {
                AND: [testCategoryToDelete],
              },
            },
          });
        });

        if (testCategoriesToCreate.length) {
          await createManyTestTestCategories({
            variables: {
              data: testCategoriesToCreate,
            },
          });
        }

        testAnalytesToDelete.forEach(async (testAnalyteToDelete) => {
          await deleteManyTestAnalytes({
            variables: {
              where: {
                AND: [testAnalyteToDelete],
              },
            },
          });
        });

        await createManyTestAnalytes({
          variables: {
            data: testAnalytesToCreate,
          },
          refetchQueries: [
            {
              query: LIST_TESTS_QUERY,
              variables: {
                where: {},
              },
              fetchPolicy: "network-only",
            },
            {
              query: SINGLE_TEST_QUERY,
              variables: {
                where: { TestID: parseInt(TestID, 10) },
              },
              fetchPolicy: "network-only",
            },
          ],
        });

        toast.success("Test updated successfully.");
      } else {
        const data = {
          ...convertInputToVariables(inputs, Object.keys(INITIAL_STATE), true),
          CategoryPrice: parseFloat(inputs.CategoryPrice),
        };
        delete data.TestCategoryID;
        delete data.TestCategories;

        const newTest = await createTest({
          variables: {
            data,
          },
          refetchQueries: [
            {
              query: LIST_TESTS_QUERY,
              variables: {
                where: {},
              },
            },
          ],
        });

        const createUserTestData = {
          TestID: parseInt(newTest.data.createTests.TestID, 10),
          UserPrice: inputs.CategoryPrice === "" ? 0 : inputs.CategoryPrice,
        };

        await createDifferentUserTests({
          variables: {
            data: createUserTestData,
          },
        });

        const testAnalytesData = analytes
          .map((analyte) =>
            analyte.checked
              ? {
                  AnalyteID: parseInt(analyte.AnalyteID, 10),
                  CreatedBy: authState.user.Username,
                  ModifiedBy: authState.user.Username,
                  TestID: parseInt(newTest.data.createTests.TestID, 10),
                }
              : null
          )
          .filter((a) => a);

        await createManyTestAnalytes({
          variables: {
            data: testAnalytesData,
          },
        });

        const testTestCategoriesData = inputs.TestCategories.map(
          (testTestCategory) => ({
            TestID: parseInt(newTest.data.createTests.TestID, 10),
            TestCategoryID: parseInt(testTestCategory.value, 10),
            CreatedBy: authState.user.Username,
            ModifiedBy: authState.user.Username,
          })
        );

        await createManyTestTestCategories({
          variables: {
            data: testTestCategoriesData,
          },
        });

        toast.success("Test created successfully.");
      }
      onComplete();
    } catch (err) {
      toast.error(`Error ${TestID ? "updating" : "creating"} Test.`);
    } finally {
      setLoading(false);
    }
  };

  const handleTestAnalytesChangeOnEditing = async (AnalyteID, checked) => {
    setAnalytes((prev) =>
      prev.map((analyte) =>
        analyte.AnalyteID === AnalyteID ? { ...analyte, checked } : analyte
      )
    );
  };

  const handleTestAnalytesChangeOnAdding = (AnalyteID, checked) => {
    setAnalytes((prev) =>
      prev.map((analyte) =>
        analyte.AnalyteID === AnalyteID ? { ...analyte, checked } : analyte
      )
    );
  };

  const handleSelectAllOnAdding = (checked) => {
    setAnalytes((prev) => prev.map((analyte) => ({ ...analyte, checked })));
  };

  const handleSelectAllOnEditing = async (checked) => {
    setAnalytes((prev) => prev.map((analyte) => ({ ...analyte, checked })));
  };

  if (loading) {
    return <Loader style={{ height: "330px" }} />;
  }
  return (
    <React.Fragment>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>{TestID ? "Update" : "Create"} Test</Title>
        </div>
        <div className="page-head-end">
          {TestID && canUpdate && (
            <Control style={{ margin: "8px 12px 0 0" }}>
              <BooleanInput
                label="Change Price For All Clients"
                name="Test"
                value={updatePriceForClients}
                onChange={(name, checked) => {
                  setUpdatePriceForClients(checked);
                }}
              />
            </Control>
          )}
          <Button.Group>
            <Button
              rounded
              color="primary"
              size="small"
              type="button"
              onClick={onComplete}
            >
              <span>Cancel</span>
            </Button>
            {canUpdate && (
              <Button
                rounded
                color="primary"
                form="add-test-form"
                size="small"
                type="submit"
              >
                <span>Submit</span>
              </Button>
            )}
          </Button.Group>
        </div>
      </header>
      <hr />
      <Fieldset>
        <TestForm
          disabled={!canUpdate}
          formId="add-test-form"
          inputs={inputs}
          isActiveDisabled={isActiveDisabled}
          onChange={handleChange}
          onSubmit={handleSave}
        />
      </Fieldset>
      <hr />
      {analytes.length ? (
        <TestAnalytes
          analytes={analytes}
          canUpdate={canUpdate}
          onChange={
            TestID
              ? handleTestAnalytesChangeOnEditing
              : handleTestAnalytesChangeOnAdding
          }
          onSelectAll={
            TestID ? handleSelectAllOnEditing : handleSelectAllOnAdding
          }
        />
      ) : null}
    </React.Fragment>
  );
};

TestModal.propTypes = {
  onComplete: PropTypes.func,
  TestID: PropTypes.string,
  canUpdate: PropTypes.bool.isRequired,
};

TestModal.defaultProps = {
  onComplete: (e) => e,
  TestID: null,
};

export default TestModal;
