import React from "react";
import { Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";

import ClientListPage from "./ClientListPage";
import ClientEditPage from "./ClientEditPage";

const ClientRouter = ({ routePermissions }) => (
  <Switch>
    <Route path="/clients/:UserID">
      <ClientEditPage routePermissions={routePermissions} />
    </Route>
    <Route path="/clients">
      <ClientListPage routePermissions={routePermissions} />
    </Route>
  </Switch>
);

ClientRouter.propTypes = {
  routePermissions: PropTypes.array,
};

ClientRouter.defaultProps = {
  routePermissions: [],
};

export default ClientRouter;
