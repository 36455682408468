import React from "react";
import { Column, Title, Button } from "rbx";
import { useHistory } from "react-router-dom";
import "../Checkout.scss";

const ConfirmationPage = () => {
  const history = useHistory();
  return (
    <Column.Group centered>
      <Column size={10}>
        <div className="confirmation-container">
          <Title style={{ textAlign: "center" }}>
            Thank you! Your order has been placed
          </Title>
          <div className="leftText medText">
            An order confirmation has been emailed
          </div>
          <div className="button-container">
            <Button
              color="primary"
              onClick={() => {
                history.push("/");
              }}
            >
              Return to Homepage
            </Button>
          </div>
        </div>
      </Column>
    </Column.Group>
  );
};
ConfirmationPage.propTypes = {};
export default ConfirmationPage;
