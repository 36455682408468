import React, { useState } from "react";
import PropTypes from "prop-types";
import { Title, Field, Control, Label, Input, Button } from "rbx";
import { SystemCodeSelect } from "../../../../../../components";

const INITIAL_STATE = {
  structuralRigidity: "",
  weight: "",
  units: "",
  displayName: "",
  density: "",
};

const PerUnitCalculator = ({
  orderID,
  category,
  type,
  onComplete,
  inputs: initialInputs,
}) => {
  const [inputs, setInputs] = useState({ ...INITIAL_STATE, ...initialInputs });

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <form
      id="calculator-form"
      onSubmit={(e) => {
        e.preventDefault();
        onComplete(inputs);
      }}
    >
      <Title subtitle>Order ID: {orderID}</Title>
      <Title subtitle>Product Category: {category}</Title>
      <Title subtitle>Substance Type: {type}</Title>
      <Field kind="group">
        <SystemCodeSelect
          label="Structural Rigidity"
          name="structuralRigidity"
          size="small"
          value={inputs.structuralRigidity}
          where={{
            Type: { equals: "Product" },
            Category: { equals: "Rigidity" },
            CodeName: { equals: "ProductRigidity" },
          }}
          onChange={handleChange}
        />
        <Control expanded>
          <Label>Serving Weight/Volume</Label>
          <Input
            name="weight"
            size="small"
            type="text"
            value={inputs.weight}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>Number of Servings per Package</Label>
          <Input
            name="units"
            size="small"
            type="text"
            value={inputs.units}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
      <Field kind="group">
        <Control expanded>
          <Label>Display Name</Label>
          <Input
            name="displayName"
            size="small"
            type="text"
            value={inputs.displayName}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>Density</Label>
          <Input
            name="density"
            size="small"
            type="text"
            value={inputs.density}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
      <Field>
        <Button color="primary" form="calculator-form" type="submit">
          Save Calculation
        </Button>
      </Field>
    </form>
  );
};

PerUnitCalculator.propTypes = {
  orderID: PropTypes.string,
  category: PropTypes.string,
  type: PropTypes.string,
  onComplete: PropTypes.func,
  inputs: PropTypes.object,
};

PerUnitCalculator.defaultProps = {
  orderID: "",
  category: "",
  type: "",
  onComplete: () => null,
  inputs: {},
};

export default PerUnitCalculator;
