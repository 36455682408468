/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import { format } from "date-fns";
import { TableCell } from "../../../../../components/DataTable";

export const COLUMNS = [
  {
    Header: "Job ID",
    id: "JobID",
    accessor: "JobID",
    Cell: ({ cell }) => (
      <TableCell.Link to={`/lims/intake/${cell.value}`} value={cell.value} />
    ),
  },
  {
    Header: "Job Order",
    id: "JobOrderID",
    accessor: "JobOrderID",
  },
  {
    Header: "Change",
    id: "Change",
    accessor: "Change",
  },
  {
    Header: "Reason For Change",
    id: "ReasonForChange",
    accessor: "ReasonForChange",
  },
  {
    Header: "Created By",
    id: "CreatedBy",
    accessor: "CreatedBy",
  },
  {
    Header: "Created At",
    id: "CreatedDateTime",
    accessor: "CreatedDateTime",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM-dd-yyyy") : "",
  },
];
