import React, { useState, useEffect } from "react";
import ReactTagInput from "@pathofdev/react-tag-input";
import PropTypes from "prop-types";

import "./TagsInput.scss";

const TagsInput = ({
  placeholder,
  onChange,
  validatorFunc,
  values,
  disabled,
}) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (values.length) {
      setTags(values.replace(/\s+/g, "").split(/[,;]+/));
    }
  }, [values]);

  const handleChange = (newTags) => {
    if (validatorFunc) {
      let areTagsValid = true;
      newTags.forEach((tag) => {
        if (!validatorFunc(tag)) {
          areTagsValid = false;
        }
      });
      if (areTagsValid) {
        setTags(newTags);
        onChange(newTags);
      }
    } else {
      setTags(newTags);
      onChange(newTags);
    }
  };

  return (
    <ReactTagInput
      placeholder={placeholder}
      readOnly={disabled}
      tags={tags}
      onChange={handleChange}
    />
  );
};

TagsInput.propTypes = {
  onChange: PropTypes.func,
  validatorFunc: PropTypes.func,
  placeholder: PropTypes.string,
  values: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

TagsInput.defaultProps = {
  placeholder: "Type and press enter",
  onChange: () => null,
  validatorFunc: () => null,
  disabled: false,
};

export default TagsInput;
