/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { generateColumns } from "./columns";
import AddEditEquipmentAttachmentModal from "../../components/AddEditEquipmentAttachmentModal";
import { useModal } from "../../../../context/ModalContext";

import { DataTable, PageHeader, IconButton } from "../../../../components";
import { ALL_EQUIPMENT_ATTACHMENTS_QUERY } from "../../../../graphql";

const Attachments = ({ EquipmentID, disabled }) => {
  const where = {
    EquipmentID: {
      equals: parseInt(EquipmentID, 10),
    },
  };

  const orderBy = [{ id: "EquipmentID", desc: false }];
  const { setModalOpen } = useModal();

  const onRowClick = (attachmentItem) => {
    if (!disabled) {
      setModalOpen(
        true,
        <AddEditEquipmentAttachmentModal
          Attachment={attachmentItem}
          onComplete={() => {
            setModalOpen(false);
          }}
        />
      );
    }
  };

  const handleCreateAttachment = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModalOpen(
      true,
      <AddEditEquipmentAttachmentModal
        EquipmentID={EquipmentID}
        onComplete={() => {
          setModalOpen(false);
        }}
      />
    );
  };

  const COLUMNS = useMemo(() => generateColumns(), [setModalOpen]);

  return (
    <div>
      <PageHeader buttonText="Create Attachment" title="Attachments">
        {!disabled && (
          <IconButton icon="plus" onClick={handleCreateAttachment} />
        )}
      </PageHeader>
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateEquipmentAttachments"
        columns={COLUMNS}
        name="findManyEquipmentAttachments"
        orderBy={orderBy}
        query={ALL_EQUIPMENT_ATTACHMENTS_QUERY}
        where={where}
        onRowClick={onRowClick}
      />
    </div>
  );
};

Attachments.propTypes = {
  EquipmentID: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Attachments;
