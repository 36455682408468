import React from "react";
import { Tab } from "rbx";
import { useLocation, Link, Switch } from "react-router-dom";
// import OrderTestsListPage from "./OrderTestsListPage";
import JobOrdersExportTab from "./JobOrdersExportTab";

const DataExportPage = () => {
  const location = useLocation();

  return (
    <React.Fragment>
      <Tab.Group kind="boxed">
        {/* <Tab
          active={location.pathname.includes("order-tests")}
          as={Link}
          to="/data-export/order-tests"
        >
          Order Tests
        </Tab> */}
        <Tab
          active={location.pathname.includes("job-orders")}
          as={Link}
          to="/data-export/job-orders"
        >
          Job Orders
        </Tab>
      </Tab.Group>
      <Switch>
        {/* <Switch path="/data-export/order-tests">
          <OrderTestsListPage />
        </Switch> */}
        <Switch path="/data-export/job-orders">
          <JobOrdersExportTab />
        </Switch>
      </Switch>
    </React.Fragment>
  );
};
export default DataExportPage;
