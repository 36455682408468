/* eslint-disable no-param-reassign */
import React from "react";
import PropTypes from "prop-types";
import { Control, Input, Generic, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BooleanInput } from "../../../../../../components";

const TestItem = ({
  index,
  inputs,
  onChange,
  checkIfSessionIdExists,
  editing,
  onMoveRowUp,
  onMoveRowDown,
  onDelete,
  onSelectTest,
  selectedTest,
  onEnterKeyPress,
  showMS,
  showSD,
  references,
  isMicro,
}) => (
  <Generic
    className={`${
      !editing && index === selectedTest?.index ? "highlighted-row" : ""
    } test-item-container ${
      editing
        ? `${isMicro ? "grid-6" : "grid-5"}`
        : `${isMicro ? "grid-4" : "grid-2"}`
    }
      `}
    style={!editing ? { cursor: "pointer" } : null}
    onClick={() => (!editing && inputs.saved ? onSelectTest(index) : null)}
  >
    {editing && (
      <Generic
        style={{
          display: "flex",
          flexDirection: "column",
          height: "1.5rem",
          justifyContent: "center",
          overflow: "hidden",
          paddingBottom: "0.25rem",
        }}
      >
        <Generic
          style={{
            textAlign: "center",
            height: "40%",
            fontSize: "0.75em",
          }}
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            onMoveRowUp(index);
          }}
        >
          <Icon color="primary" size="small">
            <FontAwesomeIcon icon="chevron-up" />
          </Icon>
        </Generic>
        <Generic
          style={{
            textAlign: "center",
            height: "40%",
            fontSize: "0.75em",
          }}
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            onMoveRowDown(index);
          }}
        >
          <Icon color="primary" size="small">
            <FontAwesomeIcon icon="chevron-down" />
          </Icon>
        </Generic>
      </Generic>
    )}
    <Generic
      style={{
        display: "flex",
        flexDirection: "column",
        height: "1.5rem",
        justifyContent: "center",
        overflow: "hidden",
        padding: "0.25rem 0.5rem 0 0.25rem",
      }}
    >
      {String(index + 1).padStart(2, "0")}.
    </Generic>
    <Control expanded>
      <Input
        readOnly
        name="OrderName"
        placeholder="Order Name"
        size="small"
        style={!editing ? { cursor: "pointer" } : null}
        value={inputs.OrderName}
      />
    </Control>
    <Control expanded>
      <Input
        ref={(x) => {
          references.current[index] = x;
        }}
        autoComplete="off"
        disabled={
          inputs.saved ||
          inputs.SessionID?.startsWith("99_99_") ||
          /^[a-z]/i.test(inputs.SessionID) ||
          !editing
        }
        id={`S-ID ${index}`}
        name="SessionID"
        placeholder={`S-ID ${index + 1}`}
        readOnly={
          inputs.SessionID?.startsWith("99_99_") ||
          /^[a-z]/i.test(inputs.SessionID) ||
          !editing
        }
        size="small"
        style={!editing ? { cursor: "pointer" } : null}
        value={inputs.SessionID}
        onBlur={() => checkIfSessionIdExists(index)}
        onChange={(e) => onChange(e.target.name, e.target.value, index)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onEnterKeyPress(index);
          }
        }}
      />
    </Control>
    {isMicro && (
      <Control expanded>
        <Input
          readOnly
          name="TestName"
          placeholder="Test Name"
          size="small"
          style={!editing ? { cursor: "pointer" } : null}
          value={inputs.testName || ""}
        />
      </Control>
    )}
    {editing && (
      <React.Fragment>
        <Generic
          style={{
            minWidth: "140px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Control
            style={{
              fontSize: "0.25em",
            }}
          >
            {!inputs.SessionID?.includes("99_99_") &&
              inputs.showMSSDFields &&
              !/^[a-z]/i.test(inputs.SessionID) &&
              showMS && (
                <BooleanInput
                  label="MS"
                  name="matrixSpike"
                  value={inputs.matrixSpike}
                  onChange={(name, checked) =>
                    onChange("matrixSpike", checked, index)
                  }
                />
              )}
          </Control>
          <Control
            style={{
              fontSize: "0.25em",
            }}
          >
            {!inputs.SessionID?.includes("99_99_") &&
              inputs.showMSSDFields &&
              !/^[a-z]/i.test(inputs.SessionID) &&
              showSD && (
                <BooleanInput
                  label="SD"
                  name="sampleDuplicate"
                  value={inputs.sampleDuplicate}
                  onChange={(name, checked) =>
                    onChange("sampleDuplicate", checked, index)
                  }
                />
              )}
          </Control>
          <Generic
            style={{ textAlign: "center" }}
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(index);
            }}
          >
            <Icon color="danger">
              <FontAwesomeIcon icon="times" />
            </Icon>
          </Generic>
        </Generic>
      </React.Fragment>
    )}
  </Generic>
);

TestItem.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  index: PropTypes.number.isRequired,
  checkIfSessionIdExists: PropTypes.func,
  editing: PropTypes.bool,
  onMoveRowUp: PropTypes.func,
  onMoveRowDown: PropTypes.func,
  onDelete: PropTypes.func,
  onSelectTest: PropTypes.func,
  selectedTest: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onEnterKeyPress: PropTypes.func,
  showMS: PropTypes.bool,
  showSD: PropTypes.bool,
  references: PropTypes.object,
  isMicro: PropTypes.bool,
};

TestItem.defaultProps = {
  onChange: () => null,
  checkIfSessionIdExists: () => null,
  editing: false,
  onMoveRowUp: () => null,
  onMoveRowDown: () => null,
  onDelete: () => null,
  onSelectTest: () => null,
  selectedTest: null,
  onEnterKeyPress: () => null,
  showMS: true,
  showSD: true,
  references: {},
  isMicro: false,
};

export default TestItem;
