import React, { useState, useEffect } from "react";
import {
  DataTable,
  PageHeader,
  ListSearchInput,
  IconButton,
} from "../../../components";
import { ALL_LEGACY_JOB_ORDERS_QUERY } from "../../../graphql";
import { DEFAULT_COLUMNS } from "./columns";
import { LegacyJobOrderFilters } from "../components";
import { debounce } from "../../../utils";
import { useAuth } from "../../../context";

const LegacyJobOrdersListPage = () => {
  const { state: authState } = useAuth();
  const [where, setWhere] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const [lab, setLab] = useState("");

  const orderBy = [{ id: "OrderCreateDate", desc: true }];

  useEffect(() => {
    setLab(authState.user.Lab);
    if (authState.user.Lab?.Company !== "All Location") {
      setWhere((prev) => ({
        ...prev,
        User: {
          is: {
            LabID: {
              equals: parseInt(authState.user.LabID, 10),
            },
          },
        },
      }));
    }
  }, [authState]);

  const handleFilterChange = (name, value) => {
    setWhere((prev) => {
      if (value) {
        if (name === "Job" && where?.Job) {
          return {
            ...prev,
            Job: {
              is: {
                ...prev.Job.is,
                ...value.is,
              },
            },
          };
        }

        return {
          ...prev,
          [name]: value,
        };
      }

      return Object.keys(prev)
        .filter((x) => x !== name)
        .reduce((a, c) => ({ ...a, [c]: prev[c] }), {});
    });
  };

  const onLabChange = (name, value) => {
    setWhere((prev) => ({
      ...prev,
      User: {
        is: {
          LabID: {
            ...value,
          },
        },
      },
    }));
  };

  const handleFiltersReset = () => {
    if (lab?.Company !== "All Location") {
      setWhere({
        User: {
          is: {
            LabID: {
              equals: parseInt(authState.user.LabID, 10),
            },
          },
        },
      });
    } else {
      setWhere({});
    }
  };

  const toggleFilters = (e) => {
    e.preventDefault();
    setShowFilters((prev) => !prev);
  };

  return (
    <div className="job-list-page">
      <PageHeader title="Legacy Job Orders">
        <ListSearchInput
          placeholder="Job ID or Order ID..."
          onChange={debounce((name, value) => {
            if (value) {
              setWhere((prev) => ({
                ...prev,
                OR: [
                  { JobID: { equals: value } },
                  { JobOrderID: { equals: value } },
                ],
              }));
            } else {
              setWhere({});
            }
          }, 500)}
        />
        <IconButton icon="filter" onClick={toggleFilters} />
      </PageHeader>
      {showFilters && (
        <LegacyJobOrderFilters
          handleFilterChange={handleFilterChange}
          handleFiltersReset={handleFiltersReset}
          inputs={where}
          lab={lab}
          onLabChange={onLabChange}
        />
      )}
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateLegacyJobOrders"
        columns={DEFAULT_COLUMNS}
        name="findManyLegacyJobOrders"
        orderBy={orderBy}
        query={ALL_LEGACY_JOB_ORDERS_QUERY}
        where={where}
      />
    </div>
  );
};

LegacyJobOrdersListPage.propTypes = {};

export default LegacyJobOrdersListPage;
