import { gql } from "@apollo/client";

export const DELETE_EQUIPMENT_MUTATION = gql`
  mutation DELETE_EQUIPMENT_MUTATION($where: EquipmentWhereUniqueInput!) {
    deleteEquipment(where: $where) {
      EquipmentID
    }
  }
`;

export const CREATE_EQUIPMENT_MUTATION = gql`
  mutation CREATE_EQUIPMENT_MUTATION($data: EquipmentCreateInput!) {
    createEquipment(data: $data) {
      EquipmentID
    }
  }
`;

export const UPDATE_EQUIPMENT_MUTATION = gql`
  mutation UPDATE_EQUIPMENT_MUTATION(
    $data: EquipmentUpdateInput!
    $where: EquipmentWhereUniqueInput!
  ) {
    updateEquipment(data: $data, where: $where) {
      EquipmentID
    }
  }
`;