import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { CUSTOM_LIST_BATCHES_QUERY } from "../../../../../graphql";
import { debounce } from "../../../../../utils";
import {
  DataTable,
  ListSearchInput,
  PageHeader,
  IconButton,
} from "../../../../../components";
import { COLUMNS } from "./columns";
import { BatchFilters } from "../components";
import { useAuth } from "../../../../../context";

const INITIAL_WHERE = {
  criteria: "",
  clause: {},
};

const BatchRecordsListPage = ({ routePermissions }) => {
  const history = useHistory();
  const { state: authState } = useAuth();

  const [canUpdate, setCanUpdate] = useState(true);
  const [where, setWhere] = useState(INITIAL_WHERE);
  const [showFilters, setShowFilters] = useState(false);
  const [labID, setLabID] = useState("");

  const orderBy = [{ id: "CreateDate", desc: true }];

  useEffect(() => {
    setLabID(authState.user.LabID);
    if (authState.user.LabID !== "9999") {
      setWhere((prev) => ({
        ...prev,
        clause: {
          LabID: {
            equals: parseInt(authState.user.LabID, 10),
          },
        },
      }));
    } 
  }, [authState]);

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  const handleRowClick = (batch) => {
    history.push(`/lims/batch-records/${batch.BatchID}/batch-info`);
  };

  const onFilterChange = (name, value) => {
    setWhere((prev) => ({
      ...prev,
      clause: {
        ...prev.clause,
        [name]: value,
      },
    }));
  };

  const handleReset = () => {
    setWhere(() => {
      if (labID?.toString() !== "9999") {
        return {
          ...INITIAL_WHERE,
          clause: {
            LabID: {
              equals: parseInt(labID, 10),
            },
          },
        };
      }     
      return INITIAL_WHERE;
    });
  };

  return (
    <div className="batch-records-list-page">
      <PageHeader title="Batch Records">
        <ListSearchInput
          onChange={debounce((name, value) => {
            if (value) {
              setWhere((prev) => ({
                ...INITIAL_WHERE,
                ...prev,
                criteria: value,
              }));
            } else {
              handleReset();
            }
          }, 500)}
        />
        <IconButton
          icon="filter"
          onClick={(e) => {
            e.preventDefault();
            setShowFilters((prev) => !prev);
          }}
        />
        {canUpdate && (
          <IconButton
            icon="plus"
            onClick={() => history.push("/lims/batch-records/add")}
          />
        )}
      </PageHeader>
      {showFilters && (
        <BatchFilters
          inputs={where.clause}
          onChange={onFilterChange}
          onReset={handleReset}
        />
      )}
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateBatchesExtended"
        columns={COLUMNS}
        name="findManyBatchesExtended"
        orderBy={orderBy}
        query={CUSTOM_LIST_BATCHES_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

BatchRecordsListPage.propTypes = {
  routePermissions: PropTypes.array,
};

BatchRecordsListPage.defaultProps = {
  routePermissions: [],
};

BatchRecordsListPage.propTypes = {};

export default BatchRecordsListPage;
