import { gql } from "@apollo/client";

export const CREATE_QUALIFIER_MUTATION = gql`
  mutation CREATE_QUALIFIER_MUTATION($data: QualifiersCreateInput!) {
    createQualifiers(data: $data) {
      QualifierID
    }
  }
`;

export const UPDATE_QUALIFIER_MUTATION = gql`
  mutation UPDATE_QUALIFIER_MUTATION(
    $data: QualifiersUpdateInput!
    $where: QualifiersWhereUniqueInput!
  ) {
    updateQualifiers(data: $data, where: $where) {
      QualifierID
    }
  }
`;
