import React from "react";
import PropTypes from "prop-types";

const PercentCell = ({ value }) => (
  <div style={{ width: "100%", textAlign: "right" }}>{`${value}%`}</div>
);

PercentCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default PercentCell;
