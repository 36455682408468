import React from "react";
import PropTypes from "prop-types";
import {
  Field,
  Input,
  Control,
  Label,
  Box,
  Title,
  Column,
  Button,
  Image,
  Select,
} from "rbx";

import {
  PhoneNumberInput,
  StateSelect,
  FileUploader,
} from "../../../../components";

const COAInfo = ({
  inputs,
  onChange,
  handleSave,
  copyCOAInfoAddress,
  uploadCOALogoPath,
  disabled,
}) => (
  <form id="edit-client-form" onSubmit={handleSave}>
    <Column.Group multiline>
      <Column desktop={{ size: 6 }} tablet={{ size: 12 }}>
        <Box>
          <Title subtitle>CoA Standard One Pager</Title>
          <Control expanded>
            <Select.Container fullwidth>
              <Select
                name="COA_SOP"
                value={inputs?.COASettings?.COA_SOP || "0"}
                onChange={(e) => {
                  onChange(e.target.name, e.target.value, "COASettings");
                }}
              >
                <Select.Option value="0">None</Select.Option>
                <Select.Option value="opc">Cover page only</Select.Option>
                <Select.Option value="opcc">Cover page with CANN</Select.Option>
                <Select.Option value="opct">
                  Cover page with CANN and TERP
                </Select.Option>
                <Select.Option value="op">Hemp Full</Select.Option>
              </Select>
            </Select.Container>
          </Control>
        </Box>
        <Field kind="group">
          <Control expanded style={{ width: "50%" }}>
            <FileUploader
              disabled={disabled}
              label="COA Logo"
              name="COALogo"
              uploadPath={uploadCOALogoPath}
              value={inputs?.COAAddress?.COALogo}
              onChange={(name, value) => onChange(name, value, "COAAddress")}
            />
          </Control>
          <Control size="small">
            <Image.Container size={128}>
              {inputs?.COAAddress?.COALogo && (
                <Image
                  src={inputs?.COAAddress?.COALogo}
                  style={{ height: "100%", width: "inherit" }}
                />
              )}
            </Image.Container>
            {inputs?.COAAddress?.COALogo && (
              <Button
                id="coa-logo-btn"
                size="small"
                onClick={() => onChange("COALogo", null, "COAAddress")}
              >
                Remove Logo
              </Button>
            )}
          </Control>
        </Field>
      </Column>
      <Column desktop={{ size: 6 }} tablet={{ size: 12 }}>
        <div className="page-head">
          <div className="page-head-start" />
          <div className="page-head-start">
            <Button
              color="primary"
              disabled={disabled}
              size="small"
              type="button"
              onClick={copyCOAInfoAddress}
            >
              Copy from Account Details
            </Button>
          </div>
        </div>
        <Field kind="group">
          <Control expanded>
            <Label>Name</Label>
            <Input
              disabled={disabled}
              name="COAName"
              size="small"
              value={inputs?.COAAddress?.COAName}
              onChange={(e) =>
                onChange(e.target.name, e.target.value, "COAAddress")
              }
            />
          </Control>
          <PhoneNumberInput
            disabled={disabled}
            label="Phone"
            name="COAPhone"
            size="small"
            value={inputs?.COAAddress?.COAPhone}
            onChange={(name, value) => onChange(name, value, "COAAddress")}
          />
        </Field>
        <Field kind="group">
          <Control expanded>
            <Label>Address</Label>
            <Input
              disabled={disabled}
              name="COAAddress"
              size="small"
              value={inputs?.COAAddress?.COAAddress}
              onChange={(e) =>
                onChange(e.target.name, e.target.value, "COAAddress")
              }
            />
          </Control>
          <Control expanded>
            <Label>Address 2</Label>
            <Input
              disabled={disabled}
              name="COAAddress2"
              size="small"
              value={inputs?.COAAddress?.COAAddress2}
              onChange={(e) =>
                onChange(e.target.name, e.target.value, "COAAddress")
              }
            />
          </Control>
        </Field>
        <Field kind="group">
          <Control expanded>
            <Label>City</Label>
            <Input
              disabled={disabled}
              name="COACity"
              size="small"
              value={inputs?.COAAddress?.COACity}
              onChange={(e) =>
                onChange(e.target.name, e.target.value, "COAAddress")
              }
            />
          </Control>
          <StateSelect
            disabled={disabled}
            id="COAState"
            label="State/Province"
            name="COAState"
            size="small"
            value={inputs?.COAAddress?.COAState}
            onChange={(name, value) => onChange(name, value, "COAAddress")}
          />
          <Control>
            <Label>Zip/Postal</Label>
            <Input
              disabled={disabled}
              name="COAZip"
              size="small"
              value={inputs?.COAAddress?.COAZip}
              onChange={(e) =>
                onChange(e.target.name, e.target.value, "COAAddress")
              }
            />
          </Control>
        </Field>
        <Field kind="group">
          <Control expanded>
            <Label>License Number</Label>
            <Input
              disabled={disabled}
              name="LicenseNumber"
              placeholder="License Number"
              size="small"
              value={inputs?.LicenseNumber}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
      </Column>
    </Column.Group>
  </form>
);

COAInfo.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  handleSave: PropTypes.func,
  copyCOAInfoAddress: PropTypes.func,
  uploadCOALogoPath: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

COAInfo.defaultProps = {
  inputs: {},
  onChange: (e) => e,
  handleSave: (e) => e,
  copyCOAInfoAddress: (e) => e,
  disabled: false,
};

export default COAInfo;
