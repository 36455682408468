import { gql, useLazyQuery } from "@apollo/client";

import { useState, useEffect } from "react";

import { useAuth } from "../context";
import { getPermissionsFromUserGroupMemberships } from "../utils";

const FIND_MANY_USER_GROUP_MEMBERSHIPS_QUERY = gql`
  query FIND_MANY_USER_GROUP_MEMBERSHIPS_QUERY(
    $where: UserGroupMembershipsWhereInput
  ) {
    findManyUserGroupMemberships(where: $where) {
      UserGroupMembershipID
      UserGroup {
        GroupID
        UserGroupPermissions {
          UserGroupPermissionID
          Permission {
            PermissionID
            Code
          }
        }
      }
    }
  }
`;

export default function usePermissions() {
  const { state: authState } = useAuth();
  const [permissions, setPermissions] = useState([]);

  const [
    getUserGroupMemberships,
    { data: FindManyUserGroupMembershipsData },
  ] = useLazyQuery(FIND_MANY_USER_GROUP_MEMBERSHIPS_QUERY);

  useEffect(() => {
    const UserID = authState?.user?.UserID;
    if (UserID) {
      getUserGroupMemberships({
        variables: { where: { UserID: { equals: parseInt(UserID, 10) } } },
      });
    }
  }, [authState, getUserGroupMemberships]);

  useEffect(() => {
    const userGroups =
      FindManyUserGroupMembershipsData?.findManyUserGroupMemberships;
    if (Array.isArray(userGroups)) {
      const result = getPermissionsFromUserGroupMemberships(userGroups);
      setPermissions(result);
    }
  }, [FindManyUserGroupMembershipsData]);

  return permissions;
}
