import { gql } from "@apollo/client";

export const ALL_PERMISSIONS_QUERY = gql`
  query ALL_PERMISSIONS_QUERY(
    $where: PermissionsWhereInput
    $orderBy: [PermissionsOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyPermissions(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      PermissionID
      Name
      Description
      CreatedDateTime
      ModifiedDateTime
      CreatedBy
      ModifiedBy
    }
  }
`;

export const SINGLE_PERMISSION_QUERY = gql`
  query SINGLE_PERMISSION_QUERY($where: PermissionsWhereUniqueInput!) {
    findUniquePermissions(where: $where) {
      PermissionID
      Name
      Description
      CreatedDateTime
      ModifiedDateTime
      CreatedBy
      ModifiedBy
    }
  }
`;
