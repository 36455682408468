/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Control, Title, Section } from "rbx";
import { useLazyQuery, useQuery } from "@apollo/client";
import Collapse from "rc-collapse";
import { sortBy } from "lodash";
import "rc-collapse/assets/index.css";

import Checkbox from "../../../../../components/BooleanInput/Checkbox";
import Loader from "../../../../../components/Loader/Loader";
import {
  ALL_ANALYTES_QUERY,
  LIST_TEST_CATEGORIES_QUERY,
  FIRST_TEST_CATEGORY_QUERY,
} from "../../../../../graphql";
import { MICRO_COLUMNS, NON_MICRO_COLUMNS } from "../columns";
import "./NewActionLimitTemplateTable.scss";
import ActionLimitTemplateTable from "../ActionLimitTemplateTable";

const NewActionLimitTemplateTable = ({
  aggregateName,
  aggregateKey,
  orderBy,
  where,
  onRowClick,
  setNewActionLimitTemplateAnalytesData,
  canUpdate,
  inputs,
}) => {
  const { data: getTestCategoriesData } = useQuery(LIST_TEST_CATEGORIES_QUERY);

  const [currentPage, setCurrentPage] = useState(0);
  const [orderByMulti] = useState(orderBy);
  const [testCategoriesData, setTestCategoriesData] = useState({});
  const [selectAllByCategory, setSelectAllByCategory] = useState({});
  const { data: getFirstTestCategory } = useQuery(FIRST_TEST_CATEGORY_QUERY, {
    variables: {
      where: {
        TestCategoryID: { equals: parseInt(inputs.TestCategory, 10) },
      },
    },
  });
  const selectedTestType = useMemo(() => {
    if (getFirstTestCategory?.findFirstTestCategories) {
      return getFirstTestCategory?.findFirstTestCategories.Name;
    }
    return null;
  }, [getFirstTestCategory]);

  const [getData, { data }] = useLazyQuery(ALL_ANALYTES_QUERY, {
    fetchPolicy: "network-only",
  });

  const initializeState = () => {
    if (Array.isArray(getTestCategoriesData?.findManyTestCategories)) {
      let dataObj = {};
      let selectObj = {};
      getTestCategoriesData.findManyTestCategories.forEach((cat) => {
        dataObj = {
          ...dataObj,
          [cat.Name]: [],
        };

        selectObj = {
          ...selectObj,
          [cat.Name]: true,
        };
      });
      setTestCategoriesData(dataObj);
      setSelectAllByCategory(selectObj);
    }
  };

  const assignActionLimitTemplateAnalyteToArray = (obj) => {
    setTestCategoriesData((prev) => {
      const newObj = {
        ...prev,
        [obj.Analyte.TestCategory.Name]: [obj].concat(
          prev[obj.Analyte.TestCategory.Name]
        ),
      };

      let sortedObj = { ...newObj };

      const notSortedArr = sortedObj[obj.Analyte.TestCategory.Name];
      const sortedArr = sortBy(notSortedArr, (item) =>
        item.Analyte.Name.toUpperCase()
      );
      sortedObj = {
        ...sortedObj,
        [obj.Analyte.TestCategory.Name]: sortedArr,
      };

      return sortedObj;
    });
  };

  const handleInputChange = (index, fieldName, value, item) => {
    setTestCategoriesData((prev) => {
      const parentObj = prev[item.Analyte.TestCategory.Name];
      const childObj = parentObj[index];
      const element = {
        ...childObj,
        [fieldName]: value,
      };
      parentObj[index] = element;

      return {
        ...prev,
        [item.Analyte.TestCategory.Name]: parentObj,
      };
    });
  };

  useEffect(() => {
    getData({
      variables: {
        where: {
          Active: {
            equals: true,
          },
        },
        orderBy: orderByMulti.map((x) => {
          if (x.id.includes(":")) {
            const sorts = x.id.split(":");
            return { [sorts[0]]: { [sorts[1]]: x.desc ? "desc" : "asc" } };
          }
          return { [x.id]: x.desc ? "desc" : "asc" };
        }),
      },
    });
    setCurrentPage(0);
  }, [currentPage, getData, orderByMulti]);

  useEffect(() => {
    initializeState();
    const fetchedData = [];

    if (data?.findManyAnalytes) {
      data.findManyAnalytes.forEach((item) => {
        const obj = {
          ActionLevel: "0.000",
          Analyte: item,
          LOQ: "0.000",
          LOD: "0.000",
          InSampleLOQ: "0.000",
          InSampleLOD: "0.000",
          AllowableCriteria: "",
          UpperLimit: "0.000",
          Active: true,
        };
        fetchedData.push(obj);
        assignActionLimitTemplateAnalyteToArray(obj);
      });
    }
    setNewActionLimitTemplateAnalytesData(fetchedData);
  }, [data]);

  useEffect(() => {
    const updatedArr = [];
    Object.keys(testCategoriesData).forEach((key) => {
      testCategoriesData[key].forEach((item) => {
        updatedArr.push(item);
      });
    });
    setNewActionLimitTemplateAnalytesData(updatedArr);
  }, [testCategoriesData]);

  const handleSelectAllByType = (e, analyteName) => {
    setSelectAllByCategory((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));

    setTestCategoriesData((prev) => {
      let arr = prev[e.target.name];
      arr = arr.map((item) => ({
        ...item,
        Active: e.target.checked,
      }));
      return {
        ...prev,
        [e.target.name]: arr,
      };
    });
  };

  const renderTables = () => (
    <div key={selectedTestType}>
      <Collapse accordion={false}>
        <div className="my-header-class">
          <Control className="table-group-title">
            <Title size={5}>{selectedTestType}</Title>
            <Checkbox
              checked={selectAllByCategory[selectedTestType]}
              label={`Select all ${selectedTestType}`}
              name={selectedTestType}
              onChange={(e) => handleSelectAllByType(e)}
            />
          </Control>
          <ActionLimitTemplateTable
            canUpdate={canUpdate}
            columns={
              /MICRO/.test(selectedTestType.toUpperCase())
                ? MICRO_COLUMNS
                : NON_MICRO_COLUMNS
            }
            data={testCategoriesData[selectedTestType]}
            isMicro={/MICRO/.test(selectedTestType.toUpperCase())}
            onChange={handleInputChange}
          />
        </div>
      </Collapse>
    </div>
  );

  if (!Array.isArray(data?.findManyAnalytes)) {
    return <Loader />;
  }

  if (
    Array.isArray(data?.findManyAnalytes) &&
    data?.findManyAnalytes?.length === 0
  ) {
    return (
      <Title subtitle style={{ color: "gray" }}>
        Nothing here...
      </Title>
    );
  }

  return (
    <Section className="table-section" id="action-limit-template-table">
      {selectedTestType && (
        <React.Fragment>
          <Control className="table-group-title">
            <Title size={4}>Analytes</Title>
          </Control>
          <hr />
          <div>{renderTables()}</div>
        </React.Fragment>
      )}
    </Section>
  );
};

export default NewActionLimitTemplateTable;

NewActionLimitTemplateTable.propTypes = {
  aggregateName: PropTypes.string.isRequired,
  aggregateKey: PropTypes.string,
  orderBy: PropTypes.array,
  where: PropTypes.object,
  onRowClick: PropTypes.func,
  setNewActionLimitTemplateAnalytesData: PropTypes.func.isRequired,
  canUpdate: PropTypes.bool.isRequired,
  inputs: PropTypes.object.isRequired,
};

NewActionLimitTemplateTable.defaultProps = {
  orderBy: [],
  aggregateKey: "_all",
  where: {},
  onRowClick: () => null,
};
