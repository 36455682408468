import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { useAuth, useModal } from "../../../context";
import { debounce } from "../../../utils";
import { LIST_USERS_QUERY } from "../../../graphql";
import {
  PageHeader,
  IconButton,
  ListSearchInput,
  DataTable,
} from "../../../components";
import AddClientModal from "../components/AddClientModal";
import ClientFilters from "../components/ClientFilters";
import COLUMNS from "./columns";

const INITIAL_STATE = {
  Type: { is: { CodeId: { in: ["0", "6"] } } },
};

const ClientListPage = ({ routePermissions }) => {
  const { setModalOpen } = useModal();
  const history = useHistory();
  const { state: authState } = useAuth();

  const [canUpdate, setCanUpdate] = useState(true);
  const [where, setWhere] = useState(INITIAL_STATE);
  const [showFilters, setShowFilters] = useState(false);
  const [labID, setLabID] = useState("");

  const orderBy = [{ id: "Username", desc: false }];

  useEffect(() => {
    setLabID(authState.user.LabID);
    if (authState.user.LabID !== "9999") {
      setWhere((prev) => ({
        ...prev,
        LabID: {
          equals: parseInt(authState.user.LabID, 10),
        },
      }));
    }
  }, [authState]);

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  const handleCreateClient = (e) => {
    e.preventDefault();
    setModalOpen(
      true,
      <AddClientModal onComplete={() => setModalOpen(false)} />
    );
  };

  const handleFilterChange = (name, value) => {
    setWhere((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setWhere(() => {
      if (labID.toString() !== "9999") {
        return {
          ...INITIAL_STATE,
          LabID: {
            equals: parseInt(labID, 10),
          },
        };
      }
      return INITIAL_STATE;
    });
  };

  const parseFilters = (whereFilter) => {
    let filters = { ...whereFilter };
    if (filters?.Status === "") {
      filters = INITIAL_STATE;
    }
    return filters;
  };

  return (
    <div className="employee-list-page">
      <PageHeader title="Clients">
        <ListSearchInput
          onChange={debounce((name, value) => {
            if (value) {
              setWhere({
                ...INITIAL_STATE,
                ...where,
                OR: [
                  { Username: { contains: value } },
                  { FirstName: { contains: value } },
                  { LastName: { contains: value } },
                  { Email: { contains: value } },
                ],
              });
            } else {
              handleReset();
            }
          }, 500)}
        />
        <IconButton
          icon="filter"
          onClick={(e) => {
            e.preventDefault();
            setShowFilters((prev) => !prev);
          }}
        />
        {canUpdate && <IconButton icon="plus" onClick={handleCreateClient} />}
      </PageHeader>
      {showFilters && (
        <ClientFilters
          canSearchByLab={labID.toString() === "9999"}
          inputs={where}
          onChange={handleFilterChange}
          onReset={handleReset}
        />
      )}
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateUsers"
        columns={COLUMNS}
        name="findManyUsers"
        orderBy={orderBy}
        query={LIST_USERS_QUERY}
        where={parseFilters(where)}
        onRowClick={(client) =>
          history.push(`/clients/${client.UserID}/account-details`)
        }
      />
    </div>
  );
};

ClientListPage.propTypes = {
  routePermissions: PropTypes.array,
};

ClientListPage.defaultProps = {
  routePermissions: [],
};

ClientListPage.propTypes = {};

export default ClientListPage;
