import clientRoutes from "./clientRoutes";
import employeeRoutes from "./employeeRoutes";
import privateLabelRoutes from "./privateLabelRoutes";

const ROUTES = {
  Client: clientRoutes,
  Employee: employeeRoutes,
  PrivateLabel: privateLabelRoutes,
};

export const getRoutes = (loginType) => ROUTES[loginType] || [];

export default ROUTES;
