import { sortBy, groupBy } from "lodash";

import {
  FindBatchResultHeaderRows,
  CheckRequiredFields,
  BuildCalculatedFieldString,
  AssignQualifierAlerts,
  DetermineAnalytePassFail,
  CANN_TOTAL_ROWS,
  TERP_TOTAL_ROWS,
} from "./BatchFunctions";

import {
  CalculateAnalyteResult,
  FindAnalyteRawResult,
  calculateCannTotals,
  calculateTerpTotals,
} from "../../../../../utils/batchCalculations";

import {
  summedAnalytes,
  analyteNameDisplayLabelMapping,
} from "../../../../../utils/batchConstants";

const rawAnalyteDividerRow = {
  field: "analyteResults",
  label: "Raw Results",
  func: () => " ",
  class: "divider-row ",
  ref: "divider1",
};
const calculatedAnalyteDividerRow = {
  field: "calculatedResults",
  label: "Calculated Results",
  func: () => " ",
  class: "divider-row ",
  ref: "divider2",
};
const summationAnalyteDividerRow = {
  field: "summationResults",
  label: "Summation Results",
  func: () => " ",
  class: "divider-row summed",
  ref: "divider3",
};

const getBatchResultUnitsByCategory = (testCategoryCode, state) => {
  const batchResultUnitsByCategory = {
    CANN: { rawUnits: "ug/ml", calculatedUnits: "mg/g" },
    SOLV: { rawUnits: "ug", calculatedUnits: "ppm" },
    METAL: {
      rawUnits: "ng/ml",
      calculatedUnits: state === "AZ" ? "ppm" : "ppb",
    },
    "SSI LC PEST MYCO": {
      rawUnits: "ng/ml",
      calculatedUnits: "ppm",
      toxinCalculatedUnits: "ppb",
    },
    "AGILENT LC PEST MYCO": {
      rawUnits: "ng/ml",
      calculatedUnits: "ppm",
      toxinCalculatedUnits: "ppb",
    },
    "GC PEST": { rawUnits: "ng/ml", calculatedUnits: "ppm" },
    TERP: { rawUnits: "ug/ml", calculatedUnits: "%" },
    "MICRO MG INC": { rawUnits: "cq", calculatedUnits: "" },
    "MICRO MG ENUM": { rawUnits: "cq", calculatedUnits: "cfu/g" },
    "MICRO 3M ENUM": { rawUnits: "counts", calculatedUnits: "cfu/g" },
    "MICRO BMX GENUP": { rawUnits: "", calculatedUnits: "" },
    "MICRO BMX TEMPO": { rawUnits: "cfu/g", calculatedUnits: "cfu/g" },
  };
  return batchResultUnitsByCategory[testCategoryCode];
};

const BuildBatchResultTemplate = (
  batchResults,
  labState,
  selectedTestResult = null
) => {
  // find constants for this batch to use for all sample columns
  const testCategoryCode = batchResults.find((item) => item?.TestCategory?.Code)
    ?.TestCategory?.Code;

  const isMicro = testCategoryCode?.includes("MICRO");

  const displayEditDropdown = isMicro && testCategoryCode !== "MICRO BMX TEMPO";

  const batchResultUnits = getBatchResultUnitsByCategory(
    testCategoryCode,
    labState
  );

  const rawUnits = batchResultUnits?.rawUnits || "";

  const globalHeaderRows = FindBatchResultHeaderRows(
    testCategoryCode,
    !!selectedTestResult
  );

  const allTestCategoryAnalytes =
    batchResults.find((item) => item?.TestCategory?.Analytes?.length)
      ?.TestCategory?.Analytes || [];

  // finter analytes and sort alphabetically by display name
  const finalTestCategoryAnalytes = sortBy(
    allTestCategoryAnalytes
      .filter((item) => item.Active)
      .map((item) => ({
        ...item,
        displayName:
          analyteNameDisplayLabelMapping.find(
            (obj) =>
              obj.originalAnalyteName === item.Name.toLowerCase().trim() &&
              obj.state === labState &&
              obj.testCategoryCode === testCategoryCode
          )?.displayName || item.Name,
        isSummedAnalyte: !!summedAnalytes.find(
          (obj) =>
            obj.testCategoryCode === testCategoryCode &&
            (!obj.state || obj.state === labState) &&
            obj.sumAnalyteName === item.Name.toLowerCase().trim()
        ),
      })),
    [(item) => item.isSummedAnalyte, (item) => item.displayName.toLowerCase()],
    ["asc", "desc"]
  );

  // **************
  // build column for each sample in batch
  // **************
  const samplesToMap = selectedTestResult || batchResults;

  const labelClaimAnalytes = samplesToMap
    .reduce((acc, sample) => {
      const t =
        sample?.JobOrder?.ActualLabelClaims?.reduce((a, lc) => {
          if (
            !acc.find((x) => x === lc?.Analyte?.Name) &&
            !a.find((x) => x === lc?.Analyte?.Name)
          ) {
            return [...a, lc?.Analyte?.Name];
          }
          return a;
        }, []) || [];
      return [...acc, ...t];
    }, [])
    .sort((a, b) => {
      if (a === "d9-THC") {
        return -1;
      }
      if (b === "d9-THC") {
        return 1;
      }
      if (a === "CBD") {
        return -1;
      }
      if (b === "CBD") {
        return 1;
      }
      return a.localeCompare(b);
    });

  const sampleColumns = samplesToMap.map((sample, originalIndex) => {
    // initialize rows for this sample column
    const rawAnalyteRows = [
      {
        ...rawAnalyteDividerRow,
        class: rawAnalyteDividerRow.class.concat(isMicro ? "raw-micro" : "raw"),
      },
    ];
    const calculatedAnalyteRows = [];
    const totalRows = [];
    const headerRows = [...globalHeaderRows];

    // find constants for this sample column to use for all analyte rows
    const sampleType = sample?.QCSample ? "QCSample" : "JobOrderTestSample";

    const actionLimitTemplateAnalytes =
      sample?.ActionLimitTemplate?.ActionLimitTemplateAnalytes;

    const missingRequiredField =
      sampleType === "JobOrderTestSample"
        ? CheckRequiredFields(sample, testCategoryCode, labState)
        : false;

    // variables for sample column, the analyte fields can update these if criteria is met
    let includesFailedAnalyte = false;
    let includesQualifierAlert = false;

    // **************
    // build field cell for each analyte row for this sample column
    // **************
    finalTestCategoryAnalytes?.forEach((analyte, index) => {
      // find constants for this analyte
      const analyteNameFormatted = analyte.Name.toLowerCase().trim();

      const actionLimitTemplateAnalyte = actionLimitTemplateAnalytes?.find(
        (a) => a.AnalyteID.toString() === analyte.AnalyteID
      );

      const testAnalyte = sample.JobOrderTest?.Test?.TestAnalytes?.find(
        (a) => a.AnalyteID === analyte.AnalyteID
      );

      // black out analytes not on test or action limit template
      const blackOutAnalyteField =
        (sample.JobOrderTest && !testAnalyte) || !actionLimitTemplateAnalyte;

      const summedAnalyte = summedAnalytes.find(
        (obj) =>
          obj.testCategoryCode === testCategoryCode &&
          (!obj.state || obj.state === labState) &&
          obj.sumAnalyteName === analyteNameFormatted
      );

      const isAddendAnalyte = summedAnalytes.find(
        (obj) =>
          obj.testCategoryCode === testCategoryCode &&
          (!obj.state || obj.state === labState) &&
          obj.addendAnalyteNames.includes(analyteNameFormatted)
      );

      const rawResult = FindAnalyteRawResult(
        sample,
        testCategoryCode,
        analyteNameFormatted,
        labState
      );

      const calculatedResult = summedAnalyte
        ? calculatedAnalyteRows
            .filter((row) => row.sumAnalyteName === analyteNameFormatted)
            .reduce((sum, row) => {
              if (row.calculatedResult && parseFloat(row.displayValue)) {
                return sum + parseFloat(row.calculatedResult);
              }
              return sum;
            }, 0)
            .toFixed(3)
        : CalculateAnalyteResult(
            sample,
            testCategoryCode,
            analyteNameFormatted,
            labState
          );

      const targetValue = blackOutAnalyteField
        ? null
        : parseFloat(sample?.TargetValues?.[analyteNameFormatted]) ||
          parseFloat(sample?.QCTarget?.TargetValues?.[analyteNameFormatted]) ||
          null;
      const [analyteResultPassFail, failReason] =
        blackOutAnalyteField ||
        (!rawResult && rawResult !== 0) ||
        !calculatedResult ||
        (sampleType === "JobOrderTestSample" && isAddendAnalyte)
          ? []
          : DetermineAnalytePassFail(
              sampleType,
              sample,
              actionLimitTemplateAnalyte,
              labState,
              calculatedResult,
              targetValue,
              testCategoryCode,
              analyteNameFormatted,
              batchResults,
              analyte.AnalyteID
            );

      const qualifierAlert =
        labState !== "AZ" ||
        blackOutAnalyteField ||
        !calculatedResult ||
        sampleType === "QCSample" ||
        summedAnalyte
          ? false
          : AssignQualifierAlerts(batchResults, sample, labState, analyte);

      let fieldClass = blackOutAnalyteField ? " black-out" : "";

      if (!summedAnalyte) {
        // add this analyte to the raw analyte rows
        rawAnalyteRows.push({
          field: analyte.Name,
          label: analyte.displayName,
          units: rawUnits,
          editable: !blackOutAnalyteField && rawResult,
          displayValue: rawResult || "",
          // (sample?.AnalyteResults &&
          // Object.keys(sample?.AnalyteResults)?.length
          //   ? "NT"
          //   : ""),
          class: fieldClass,
          displayEditDropdown:
            displayEditDropdown && !blackOutAnalyteField && rawResult,
          group: "raw",
        });
      }

      if (isAddendAnalyte) {
        fieldClass = fieldClass.concat(" italics");
      }
      if (analyteResultPassFail === true) {
        fieldClass = fieldClass.concat(" pass");
      }
      if (analyteResultPassFail === false) {
        fieldClass = fieldClass.concat(" fail");
      }

      const calculatedFieldString = BuildCalculatedFieldString(
        sample,
        rawResult,
        calculatedResult,
        actionLimitTemplateAnalyte,
        testCategoryCode,
        analyteNameFormatted,
        batchResultUnits
      );

      if (!summedAnalyte) {
        // add this analyte to the calculated analyte rows
        calculatedAnalyteRows.push({
          field: `${analyte.Name}-calc`,
          label: analyte.displayName,
          displayValue: blackOutAnalyteField ? "" : calculatedFieldString,
          class: fieldClass,
          targetValue,
          isSampleSpecificTarget:
            !!sample?.TargetValues?.[analyteNameFormatted],
          showQualifiers: true,
          qualifiers: sample?.Qualifiers?.[analyteNameFormatted] || [],
          systemQualifiers:
            sample?.SystemQualifiers?.[analyteNameFormatted] || [],
          qualifierAlert,
          sumAnalyteName: isAddendAnalyte?.sumAnalyteName,
          calculatedResult,
          failReason,
          group: "calculated",
        });
      } else {
        totalRows.push({
          field: `${analyte.Name}-calc`,
          label: analyte.displayName,
          displayValue: calculatedFieldString,
          class: fieldClass,
          targetValue,
          isSampleSpecificTarget:
            !!sample?.TargetValues?.[analyteNameFormatted],
          showQualifiers: true,
          qualifiers: sample?.Qualifiers?.[analyteNameFormatted] || [],
          systemQualifiers:
            sample?.SystemQualifiers?.[analyteNameFormatted] || [],
          qualifierAlert,
          isSummedAnalyte: summedAnalyte?.sumAnalyteName,
          group: "total",
        });
      }

      // apply flags to the entire sample if this analyte fits criteria
      if (analyteResultPassFail === false) {
        includesFailedAnalyte = true;
      }
      if (qualifierAlert) {
        includesQualifierAlert = true;
      }
    }); // **** end map through analytes

    if (testCategoryCode === "CANN" || testCategoryCode === "CE") {
      const {
        totalCannabinoidsPerUnit,
        totalCannabinoidsPerPackage,
        allLabelClaim,
        includesFailedLabelClaim,
      } = calculateCannTotals(sample, labState, calculatedAnalyteRows);

      totalRows.push(
        ...CANN_TOTAL_ROWS(
          totalCannabinoidsPerUnit,
          totalCannabinoidsPerPackage,
          allLabelClaim,
          labelClaimAnalytes,
          sampleType
        )
      );

      if (includesFailedLabelClaim) {
        includesFailedAnalyte = true;
      }

      labelClaimAnalytes.forEach((lcAnalyteName) => {
        const labelClaimObj = sample.JobOrder?.ActualLabelClaims?.find(
          (lc) => lc.Analyte.Name === lcAnalyteName
        );
        const dislayName = lcAnalyteName === "d9-THC" ? "THC" : lcAnalyteName;
        headerRows.push({
          field: `Label Claim - Total ${lcAnalyteName}`,
          label: `Label Claim - Total ${dislayName}`,
          func: () => (labelClaimObj ? `${labelClaimObj?.LabelClaim} mg` : ""),
          class: labelClaimObj ? "" : "black-out",
        });
      });
    }

    if (testCategoryCode === "TERP") {
      const totalTerpenes = calculateTerpTotals(calculatedAnalyteRows);

      totalRows.push(...TERP_TOTAL_ROWS(totalTerpenes));
    }

    if (totalRows.length > 0) {
      calculatedAnalyteRows.unshift({
        ...calculatedAnalyteDividerRow,
        class: calculatedAnalyteDividerRow.class.concat("calculated-summed"),
      });
      totalRows.unshift({ ...summationAnalyteDividerRow });
      totalRows[1] = {
        ...totalRows[1],
        class: totalRows[1]?.class?.concat(" first") || " first",
      };
      totalRows[totalRows.length - 1] = {
        ...totalRows[totalRows.length - 1],
        class:
          totalRows[totalRows.length - 1]?.class?.concat(" last") || " last",
      };
    } else {
      calculatedAnalyteRows.unshift({
        ...calculatedAnalyteDividerRow,
        class: calculatedAnalyteDividerRow.class.concat(
          isMicro ? "calculated-micro" : "calculated"
        ),
      });
    }

    headerRows[headerRows.length - 1] = {
      ...headerRows[headerRows.length - 1],
      class: headerRows[headerRows.length - 1]?.class?.concat(" last"),
    };
    rawAnalyteRows[1] = {
      ...rawAnalyteRows[1],
      class: rawAnalyteRows[1]?.class?.concat(" first"),
    };
    rawAnalyteRows[rawAnalyteRows.length - 1] = {
      ...rawAnalyteRows[rawAnalyteRows.length - 1],
      class: rawAnalyteRows[rawAnalyteRows.length - 1]?.class?.concat(" last"),
    };
    calculatedAnalyteRows[1] = {
      ...calculatedAnalyteRows[1],
      class: calculatedAnalyteRows[1]?.class?.concat(" first"),
    };
    calculatedAnalyteRows[calculatedAnalyteRows.length - 1] = {
      ...calculatedAnalyteRows[calculatedAnalyteRows.length - 1],
      class:
        calculatedAnalyteRows[calculatedAnalyteRows.length - 1]?.class?.concat(
          " last"
        ),
    };

    // FOR TESTING, REMOVE BEFORE LAUNCH
    totalRows.push({
      field: "ACTION LIMIT TEMPLATE",
      label: "ACTION LIMIT TEMPLATE",
      func: () => sample?.ActionLimitTemplate?.TemplateName,
    });

    return {
      singleResultTemplate: [
        ...headerRows,
        ...rawAnalyteRows,
        ...calculatedAnalyteRows,
        ...totalRows,
      ],
      missingRequiredField,
      includesFailedAnalyte,
      includesQualifierAlert,
      hasTargetValues: !!sample?.TargetValues,
      isMicro,
      sample,
      originalIndex,
    };
  }); // **** end map through samples

  if (!isMicro || !!selectedTestResult) {
    return sampleColumns;
  }

  // for micro batches
  // reorder columns to group by JobOrderID
  // add classes to style groupings
  const finalSampleColumnsToReturn = [];

  const groupedColumns = groupBy(sampleColumns, (x) =>
    x.sample?.JobOrderTestSample?.JobOrder?.JobOrderID?.toString()
  );
  const groupedQC = groupBy(groupedColumns.undefined, "sample.OrderName");
  delete groupedColumns.undefined;

  Object.entries({ ...groupedQC, ...groupedColumns })
    .sort((a, b) =>
      isNaN(a[0]) === isNaN(b[0])
        ? !b[0].localeCompare(a[0])
        : b[0].localeCompare(a[0])
    )
    .forEach(([key, value], groupIndex) => {
      const columnClass = groupIndex % 2 === 0 ? "" : "alternate-background";
      const samples = value?.map((sample, columnIndex) => {
        let classToAdd = columnClass;
        if (columnIndex === 0) {
          classToAdd = classToAdd.concat(" border-left");
        }
        if (columnIndex === value?.length - 1) {
          classToAdd = classToAdd.concat(" border-right");
        }
        return {
          ...sample,
          columnClass: classToAdd,
        };
      });
      finalSampleColumnsToReturn.push(...samples);
    });
  return finalSampleColumnsToReturn;
};

export default BuildBatchResultTemplate;
