import React, { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { useParams, useHistory } from "react-router-dom";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  PageHeader,
  IconButton,
  DataTable,
  Confirmation,
} from "../../../../../components";
import {
  ALL_SAMPLE_TRACKINGS_QUERY,
  DELETE_SAMPLE_TRACKING_MUTATION,
} from "../../../../../graphql";
import { generateColumns } from "./columns";
import { useModal, useAuth } from "../../../../../context";
import { customToast as toast } from "../../../../../utils";
import SampleLocationModal from "../components/SampleLocationModal";
import SampleLocationFilters from "../components/SampleLocationFilters";

const SampleLocationListPage = ({ routePermissions }) => {
  const { SampleTrackingID } = useParams();
  const history = useHistory();
  const { state: authState } = useAuth();

  const [where, setWhere] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const [canUpdate, setCanUpdate] = useState(true);
  const [modalOpened, setModalOpened] = useState(false);
  const [labID, setLabID] = useState("");

  const orderBy = [{ id: "Name", desc: false }];

  const { setModalOpen } = useModal();

  const [deleteSampleTracking] = useMutation(DELETE_SAMPLE_TRACKING_MUTATION);

  useEffect(() => {
    setLabID(authState.user.LabID);
    if (authState.user.LabID !== "9999") {
      setWhere((prev) => ({
        ...prev,
        LabID: {
          equals: parseInt(authState.user.LabID, 10),
        },
      }));
    }  
  }, [authState]);

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  useEffect(() => {
    if (SampleTrackingID && !modalOpened) {
      setModalOpened(true);
      setModalOpen(
        true,
        <SampleLocationModal
          SampleTrackingID={SampleTrackingID}
          onComplete={() => {
            history.push("/lims/sample-locations");
            setModalOpen(false);
          }}
        />
      );
    }
  }, [SampleTrackingID, history, modalOpened, setModalOpen]);

  const COLUMNS = useMemo(() => {
    const handleDelete = (sampleTracking) => {
      const performDelete = async () => {
        try {
          setModalOpen(false, "");
          await deleteSampleTracking({
            variables: {
              where: {
                SampleTrackingID: parseInt(sampleTracking.SampleTrackingID, 10),
              },
            },
            refetchQueries: [
              {
                query: ALL_SAMPLE_TRACKINGS_QUERY,
                variables: {
                  where: {},
                },
              },
            ],
          });
          toast.success("Sample Location deleted successfully.");
        } catch (err) {
          toast.error(`Error deleting Sample Location.`);
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this Sample Location?"
          onCancel={() => setModalOpen(false, "")}
          onConfirm={performDelete}
        />
      );
    };
    return generateColumns(handleDelete, canUpdate);
  }, [deleteSampleTracking, setModalOpen, canUpdate]);

  const handleRowClick = (sampleTrackingItem) => {
    if (canUpdate) {
      history.push(
        `/lims/sample-locations/${sampleTrackingItem.SampleTrackingID}`
      );
      setModalOpened(false);
    }
  };

  const toggleFilters = () => {
    setShowFilters((prevState) => !prevState);
  };

  const handleFilterChange = (name, value) => {
    setWhere((prev) => {
      if (name === "LabID" && value?.equals?.toString() === "9999" && where?.LabID) {
        const newObj = {...prev};
        delete newObj.LabID;
        return newObj;
      }

      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleFiltersReset = () => {
    setWhere(() => {
      if (labID.toString() !== "9999") {
        return {
          LabID: {
            equals: parseInt(labID, 10),
          },
        };
      }     
      return {};
    });
  };

  const handleCreateSampleLocation = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push("/lims/sample-locations");
    setModalOpen(
      true,
      <SampleLocationModal
        onComplete={() => {
          history.push("/lims/sample-locations");
          setModalOpen(false);
        }}
      />
    );
  };

  return (
    <div className="sample-location-list-page">
      <PageHeader title="Sample Locations">
        {labID?.toString() === "9999" && (
          <Button color="primary" onClick={toggleFilters}>
            <Icon>
              <FontAwesomeIcon icon="filter" />
            </Icon>
          </Button>
        )}
        <IconButton disabled={!canUpdate} icon="plus" onClick={handleCreateSampleLocation}/>
      </PageHeader>
      {showFilters && (
        <SampleLocationFilters
          handleFilterChange={handleFilterChange}
          handleFiltersReset={handleFiltersReset}
          inputs={where}
        />
      )}
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateSampleTracking"
        columns={COLUMNS}
        name="sampleTrackings"
        orderBy={orderBy}
        query={ALL_SAMPLE_TRACKINGS_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

SampleLocationListPage.propTypes = {
  routePermissions: PropTypes.array,
};

SampleLocationListPage.defaultProps = {
  routePermissions: [],
};
export default SampleLocationListPage;
