import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation, useLazyQuery } from "@apollo/client";
import { Title, Fieldset, Button } from "rbx";
import { customToast as toast } from "../../../../utils";
import LabTestForm from "../LabTestForm";
import Loader from "../../../../components/Loader";
import {
  CREATE_LAB_TEST_MUTATION,
  LIST_LABS_TESTS_QUERY,
  SINGLE_LAB_TEST_QUERY,
  UPDATE_LAB_TEST_MUTATION,
  SINGLE_TEST_QUERY,
} from "../../../../graphql";

const INITIAL_STATE = {
  Plants: 0,
  Extracts: 0,
  Ingestibles: 0,
  Topicals: 0,
  AnimalProducts: 0,
  Other: 0,
};

const LabTestModal = ({ onComplete, LabTestID, LabID }) => {
  const [loading, setLoading] = useState(!!LabTestID);
  const [createLabTest] = useMutation(CREATE_LAB_TEST_MUTATION);
  const [updateLabTest] = useMutation(UPDATE_LAB_TEST_MUTATION);
  const [getTest, { data: GetTestData }] = useLazyQuery(SINGLE_TEST_QUERY);

  const [inputs, setInputs] = useState({
    ...INITIAL_STATE,
  });

  const [getLabTestData, resultLabTestData] = useLazyQuery(
    SINGLE_LAB_TEST_QUERY
  );

  useEffect(() => {
    if (LabTestID) {
      getLabTestData({
        variables: {
          where: { LabTestID: parseInt(LabTestID, 10) },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [LabTestID, getLabTestData]);

  useEffect(() => {
    if (resultLabTestData?.data?.findUniqueLabTests) {
      const {
        data: { findUniqueLabTests },
      } = resultLabTestData;
      setInputs((prev) => ({
        ...prev,
        ...findUniqueLabTests,
        StartDate: findUniqueLabTests.StartDate
          ? new Date(findUniqueLabTests.StartDate).toISOString().slice(0, 10)
          : "",
        ExpireDate: findUniqueLabTests.ExpireDate
          ? new Date(findUniqueLabTests.ExpireDate).toISOString().slice(0, 10)
          : "",
      }));
      setLoading(false);
    }
  }, [resultLabTestData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (inputs.TestID) {
      getTest({
        variables: { where: { TestID: parseInt(inputs.TestID, 10) } },
      });
    }
  }, [getTest, inputs.TestID]);

  useEffect(() => {
    const test = GetTestData?.findUniqueTests;
    if (test) {
      setInputs((prev) => ({
        ...prev,
        Plants: test.CategoryPrice.toFixed(2),
        Extracts: test.CategoryPrice.toFixed(2),
        Ingestibles: test.CategoryPrice.toFixed(2),
        Topicals: test.CategoryPrice.toFixed(2),
        AnimalProducts: test.CategoryPrice.toFixed(2),
        Other: test.CategoryPrice.toFixed(2),
      }));
    }
  }, [GetTestData]);

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (LabTestID) {
        await updateLabTest({
          variables: {
            data: {
              Plants: { set: parseFloat(inputs.Plants) },
              Extracts: { set: parseFloat(inputs.Extracts) },
              Ingestibles: { set: parseFloat(inputs.Ingestibles) },
              Topicals: { set: parseFloat(inputs.Topicals) },
              AnimalProducts: { set: parseFloat(inputs.AnimalProducts) },
              Other: { set: parseFloat(inputs.Other) },
              Test: { connect: { TestID: parseInt(inputs.TestID, 10) } },
            },
            where: {
              LabTestID: parseInt(LabTestID, 10),
            },
          },
          refetchQueries: [
            {
              query: LIST_LABS_TESTS_QUERY,
              variables: {
                where: {},
              },
              fetchPolicy: "network-only",
            },
            {
              query: SINGLE_LAB_TEST_QUERY,
              variables: {
                where: { LabTestID: parseInt(LabTestID, 10) },
              },
              fetchPolicy: "network-only",
            },
          ],
        });

        toast.success("Laboratory Test updated successfully.");
      } else {
        await createLabTest({
          variables: {
            data: {
              Plants: parseFloat(inputs.Plants),
              Extracts: parseFloat(inputs.Extracts),
              Ingestibles: parseFloat(inputs.Ingestibles),
              Topicals: parseFloat(inputs.Topicals),
              AnimalProducts: parseFloat(inputs.AnimalProducts),
              Other: parseFloat(inputs.Other),
              Lab: { connect: { LabID } },
              Test: { connect: { TestID: parseInt(inputs.TestID, 10) } },
            },
          },
          refetchQueries: [
            {
              query: LIST_LABS_TESTS_QUERY,
              variables: {
                where: {},
              },
            },
          ],
        });
        toast.success("Laboratory Test created successfully.");
      }

      onComplete();
    } catch (err) {
      toast.error(
        `Error ${LabTestID ? "updating" : "creating"} Laboratory Test.`
      );
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader style={{ height: "330px" }} />;
  }
  return (
    <React.Fragment>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>{LabTestID ? "Update" : "Create"} Lab Test</Title>
        </div>
        <form
          className="page-head-end"
          id="lab-test-form"
          onSubmit={handleSave}
        >
          <Button.Group>
            <Button
              color="primary"
              size="small"
              type="button"
              onClick={onComplete}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              form="lab-test-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </form>
      </header>
      <hr />
      <Fieldset>
        <LabTestForm
          adding
          formId="lab-test-form"
          inputs={inputs}
          onChange={handleChange}
          onSubmit={handleSave}
        />
      </Fieldset>
      <hr />
    </React.Fragment>
  );
};

LabTestModal.propTypes = {
  onComplete: PropTypes.func,
  LabTestID: PropTypes.string,
  LabID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

LabTestModal.defaultProps = {
  onComplete: (e) => e,
  LabTestID: null,
};

export default LabTestModal;
