import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const ALL_PROFICIENCIES_QUERY = gql`
  query ALL_PROFICIENCIES_QUERY(
    $where: ProficienciesWhereInput
    $orderBy: [ProficienciesOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyProficiencies(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      ProficiencyID
      Proficiency
      Tests
    }
  }
`;
