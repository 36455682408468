import React, { useState } from "react";
import PropTypes from "prop-types";
import { Column, Field, Input, Label, Icon, Control, Box, Title } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  PhoneNumberInput,
  StatusSelect,
  SystemCodeSelect,
  StateSelect,
  LabLocationSelect,
  TagsInput,
  UserSelect,
  BooleanInput,
} from "../../../../components";
import ShippingMultiplierForm from "../ShippingMultiplierForm";
import "./ClientForm.scss";

const ClientForm = ({
  inputs,
  onChange,
  disabled,
  onSubmit,
  formId,
  adding,
  uploadProfilePicPath,
  userID,
  parentClientAccountType,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleEmailsChange = (emails) => {
    const newEmails = emails.join(", ");
    onChange("Email", newEmails);
  };

  // eslint-disable-next-line no-useless-escape
  const isEmailValid = (email) =>
    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);

  const toggleShowPassword = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPassword(!showPassword);
  };

  return (
    <form id={formId} onSubmit={onSubmit}>
      <Column.Group multiline>
        <Column desktop={{ size: 6 }} tablet={{ size: 12 }}>
          <Field kind="group">
            <Control expanded size="small" style={{ width: "50%" }}>
              <Label>Client</Label>
              <Input
                required
                autoComplete="off"
                disabled={disabled || !adding}
                maxLength={250}
                name="Username"
                placeholder="Username"
                size="small"
                value={inputs.Username}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            {!adding && (
              <Control expanded size="small">
                <Label>UserID</Label>
                <Input disabled size="small" value={inputs.UserID} />
              </Control>
            )}
            {!adding && (
              <StatusSelect
                disabled={disabled}
                displayEmptyOption={false}
                id="status"
                label="Status"
                name="Status"
                size="small"
                value={inputs.Status}
                onChange={onChange}
              />
            )}
          </Field>
          <Field kind="group">
            <Control expanded size="small">
              <Label>Last Name</Label>
              <Input
                required
                disabled={disabled}
                maxLength={250}
                name="LastName"
                placeholder="LastName"
                size="small"
                value={inputs.LastName}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded size="small">
              <Label>First Name</Label>
              <Input
                disabled={disabled}
                maxLength={250}
                name="FirstName"
                placeholder="Firstname"
                size="small"
                value={inputs.FirstName}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded className="email-control" size="small">
              <Label>Emails</Label>
              <TagsInput
                disabled={disabled}
                placeholder="Enter an email and press enter"
                validatorFunc={isEmailValid}
                values={inputs.Email}
                onChange={handleEmailsChange}
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded style={{ width: "33%" }}>
              <PhoneNumberInput
                required
                disabled={disabled}
                label="Phone"
                name="Phone"
                size="small"
                value={inputs.Phone}
                onChange={onChange}
              />
            </Control>
            <Control expanded style={{ width: "33%" }}>
              <SystemCodeSelect
                disabled={disabled}
                label="Time Zone"
                name="TimeZone"
                size="small"
                value={inputs.TimeZone}
                where={{
                  Type: { equals: "System" },
                  Category: { equals: "System" },
                  CodeName: { equals: "Timezone" },
                }}
                onChange={onChange}
              />
            </Control>
            <Control expanded style={{ width: "33%" }}>
              <SystemCodeSelect
                disabled={disabled}
                label="Terms"
                name="Terms"
                size="small"
                value={inputs.Terms}
                where={{
                  Type: { equals: "User" },
                  Category: { equals: "Terms" },
                  CodeName: { equals: "PaymentTerms" },
                }}
                onChange={onChange}
              />
            </Control>
          </Field>
          {!adding && (
            <React.Fragment>
              <Field kind="group">
                <Control expanded style={{ width: "33%" }}>
                  <Label>Expected Turn Around Days</Label>
                  <Input
                    disabled={disabled}
                    maxLength={50}
                    name="TurnAroundDays"
                    placeholder="Expected Turn Around"
                    size="small"
                    type="number"
                    value={inputs.TurnAroundDays || ""}
                    onChange={(e) =>
                      onChange(e.target.name, Number(e.target.value))
                    }
                  />
                </Control>
                <Control expanded style={{ width: "33%" }}>
                  <SystemCodeSelect
                    disabled={disabled}
                    label="Account Type"
                    name="AccountType"
                    size="small"
                    value={inputs.AccountType || ""}
                    where={{
                      Type: { equals: "User" },
                      Category: { equals: "User" },
                      CodeName: { equals: "AccountType" },
                      CodeDescription: { in: ["Client", "Parent Client"] },
                    }}
                    onChange={onChange}
                  />
                </Control>
                {inputs.AccountType?.toString() !==
                  parentClientAccountType?.RecId && (
                  <Control expanded style={{ width: "33%" }}>
                    <UserSelect
                      disabled={disabled}
                      label="Parent Client"
                      name="ParentClientID"
                      value={inputs.ParentClientID}
                      where={{ Type: { is: { CodeId: { equals: "6" } } } }}
                      onChange={onChange}
                    />
                  </Control>
                )}
              </Field>
              <Field kind="group">
                <Control>
                  <Label>Allow Duplicate Batch Order Numbers</Label>
                  <BooleanInput
                    disabled={disabled}
                    name="AllowDupeBatchOrderNumber"
                    value={!!inputs.AllowDupeBatchOrderNumber}
                    onChange={(name, value) => onChange(name, value)}
                  />
                </Control>
              </Field>
            </React.Fragment>
          )}
        </Column>
        <Column desktop={{ size: 6 }} tablet={{ size: 12 }}>
          <Field kind="group">
            <Control expanded size="small">
              <Label>Company</Label>
              <Input
                disabled={disabled}
                maxLength={250}
                name="Company"
                placeholder="Company"
                size="small"
                value={inputs.Company}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded>
              <LabLocationSelect
                required
                disabled={disabled}
                id="LabID"
                label="Lab to Use"
                name="LabID"
                showAllLocation={false}
                size="small"
                value={inputs.LabID}
                onChange={onChange}
              />
            </Control>
          </Field>
          <Field>
            <Control expanded size="small">
              <Label>Address</Label>
              <Input
                disabled={disabled}
                maxLength={250}
                name="Address"
                placeholder="Address"
                size="small"
                value={inputs.Address}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field>
            <Control expanded size="small">
              <Label>Address 2</Label>
              <Input
                disabled={disabled}
                maxLength={250}
                name="Address2"
                placeholder="Address2"
                size="small"
                value={inputs.Address2}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded size="small" style={{ width: "33%" }}>
              <Label>City</Label>
              <Input
                disabled={disabled}
                maxLength={250}
                name="City"
                placeholder="City"
                size="small"
                value={inputs.City}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded size="small" style={{ width: "33%" }}>
              <StateSelect
                disabled={disabled}
                id="State"
                label="State"
                name="State"
                size="small"
                value={inputs.State}
                onChange={onChange}
              />
            </Control>
            <Control expanded size="small" style={{ width: "33%" }}>
              <Label>Zip</Label>
              <Input
                disabled={disabled}
                maxLength={50}
                name="Zip"
                placeholder="Zip"
                size="small"
                value={inputs.Zip}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          {!adding && (
            <Box>
              <Title subtitle>Processing options</Title>
              <ShippingMultiplierForm
                disabled={disabled}
                inputs={inputs.ShippingMultiplier}
                onChange={(name, value) =>
                  onChange(name, value, "ShippingMultiplier")
                }
              />
            </Box>
          )}
        </Column>
        {adding && (
          <Column size={12}>
            <Field kind="group">
              <Control expanded size="small" style={{ width: "33%" }}>
                <Label>Password</Label>
                <Input
                  disabled={disabled}
                  maxLength={250}
                  name="Password"
                  placeholder="Password"
                  size="small"
                  type={showPassword ? "text" : "password"}
                  value={inputs.Password}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                />
                <Icon
                  className="password-visibility"
                  onClick={toggleShowPassword}
                >
                  <FontAwesomeIcon icon={showPassword ? "eye" : "eye-slash"} />
                </Icon>
              </Control>
              <Control expanded size="small" style={{ width: "33%" }}>
                <Label>Confirm Password</Label>
                <Input
                  disabled={disabled}
                  maxLength={250}
                  name="ConfirmPassword"
                  placeholder="Confirm Password"
                  size="small"
                  type={showPassword ? "text" : "password"}
                  value={inputs.ConfirmPassword}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                />
                <Icon
                  className="password-visibility"
                  onClick={toggleShowPassword}
                >
                  <FontAwesomeIcon icon={showPassword ? "eye" : "eye-slash"} />
                </Icon>
                {inputs.Password !== "" &&
                  inputs.ConfirmPassword !== "" &&
                  inputs.Password !== inputs.ConfirmPassword && (
                    <div className="text-danger">
                      Passwords don&lsquo;t match
                    </div>
                  )}
              </Control>
            </Field>
          </Column>
        )}
      </Column.Group>
    </form>
  );
};

ClientForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  formId: PropTypes.string.isRequired,
  adding: PropTypes.bool,
  uploadProfilePicPath: PropTypes.string.isRequired,
  userID: PropTypes.string,
  parentClientAccountType: PropTypes.object,
};

ClientForm.defaultProps = {
  onSubmit: () => null,
  disabled: false,
  adding: false,
  userID: "",
  parentClientAccountType: {},
};

export default ClientForm;
