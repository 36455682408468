import React, { useState, useImperativeHandle, forwardRef } from "react";
import PropTypes from "prop-types";
import { Control, Input, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ListSearchInput.scss";

const ListSearchInput = forwardRef(
  ({ onChange, placeholder, onKeyPress }, refProp) => {
    const [value, setValue] = useState("");

    const handleChange = (e) => {
      onChange(e.target.name, e.target.value);
      setValue(e.target.value);
    };

    useImperativeHandle(refProp, () => ({
      setSearchValue(newValue) {
        setValue(newValue);
      },
    }));

    return (
      <Control iconLeft>
        <Input
          id="search-input"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onKeyPress={onKeyPress}
        />
        <Icon align="left" color="primary">
          <FontAwesomeIcon icon="search" />
        </Icon>
      </Control>
    );
  }
);

ListSearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  onKeyPress: PropTypes.func,
};

ListSearchInput.defaultProps = {
  placeholder: "Search...",
  onKeyPress: () => null,
};

export default ListSearchInput;
