/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const FIND_FIRST_SNAPSHOT_QUERY = gql`
  query FIND_FIRST_SNAPSHOT_QUERY(
    $where: SnapshotsWhereInput
    $orderBy: [SnapshotsOrderByInput!]
  ) {
    findFirstSnapshots(where: $where, orderBy: $orderBy) {
      SnapshotID
      JobOrderID
      Version
      Data
    }
  }
`;

export const ALL_SNAPSHOTS_QUERY = gql`
  query ALL_SNAPSHOTS_QUERY(
    $where: SnapshotsWhereInput
    $orderBy: [SnapshotsOrderByInput!]
    $skip: Int
    $limit: Int
  ) {
    findManySnapshots(
      where: $where
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      SnapshotID
      JobOrderID
      Version
    }
  }
`;
