import { useState, useEffect } from "react";

export default function usePublicIP() {
  const [ipAddress, setIpAddress] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        fetch("https://icanhazip.com/")
          .then((res) => res.text())
          .then((data) => {
            setIpAddress(data.replace(/[^0-9|.]/gi, ""));
          }).catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err.message);
          });
        // setIpAddress(ip);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err.message);
      }
    };
    if (!ipAddress) {
      fetchData();
    }
  }, [ipAddress]);

  return ipAddress;
}
