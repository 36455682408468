import { gql } from "@apollo/client";

export const ALL_JOB_ORDER_DISPLAY_UNITS_QUERY = gql`
  query ALL_JOB_ORDER_DISPLAY_UNITS_QUERY(
    $where: JobOrderDisplayUnitsWhereInput
    $orderBy: [JobOrderDisplayUnitsOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyJobOrderDisplayUnits(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      JobOrderID
      JobOrders {
        ProductCategory
        SubstanceType
      }
      JobOrderDisplayUnitID
      StructuralRigidity
      Weight
      Units
      Density
      DisplayName
    }
    aggregateJobOrderDisplayUnits(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const SINGLE_JOB_ORDER_DISPLAY_UNIT_QUERY = gql`
  query SINGLE_JOB_ORDER_DISPLAY_UNIT_QUERY(
    $where: JobOrderDisplayUnitsWhereUniqueInput!
  ) {
    findUniqueJobOrderDisplayUnits(where: $where) {
      JobOrderID
      JobOrders {
        ProductCategory
        SubstanceType
      }
      JobOrderDisplayUnitID
      StructuralRigidity
      Weight
      Units
      Density
      DisplayName
    }
  }
`;

export const FIRST_JOB_ORDER_DISPLAY_UNIT_QUERY = gql`
  query FIRST_JOB_ORDER_DISPLAY_UNIT_QUERY(
    $where: JobOrderDisplayUnitsWhereInput!
  ) {
    findFirstJobOrderDisplayUnits(where: $where) {
      JobOrderID
      JobOrders {
        ProductCategory
        SubstanceType
      }
      JobOrderDisplayUnitID
      StructuralRigidity
      Weight
      Units
      Density
      DisplayName
    }
  }
`;