import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "rbx";
import { useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";

import EmployeeForm from "../components/EmployeeForm";
import { useModal } from "../../../context/ModalContext";
import { UpdatePasswordModal, Loader, PageHeader } from "../../../components";

import { customToast as toast } from "../../../utils";

import {
  LIST_USERS_QUERY,
  UPDATE_USER_MUTATION,
  SINGLE_USER_QUERY,
  UPDATE_PASSWORD_MUTATION,
} from "../../../graphql";

const INITIAL_STATE = {
  UserID: "",
  Username: "",
  FirstName: "",
  LastName: "",
  AccountType: "",
  Email: "",
  Status: "",
  Address: "",
  Address2: "",
  Phone: "",
  City: "",
  State: "",
  Zip: "",
  ProfilePic: "",
  LabID: "",
  isPrivateLabel: "",
};

const EmployeeEditPage = ({ routePermissions }) => {
  const { UserID } = useParams();
  const { setModalOpen } = useModal();

  const [canUpdate, setCanUpdate] = useState(true);
  const [editMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [inputs, setInputs] = useState(INITIAL_STATE);

  const [updateUser] = useMutation(UPDATE_USER_MUTATION);
  const [updatePassword] = useMutation(UPDATE_PASSWORD_MUTATION);
  const [getEmployeeData, resultEmployeeData] = useLazyQuery(SINGLE_USER_QUERY);

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  useEffect(() => {
    if (UserID) {
      getEmployeeData({
        variables: {
          where: { UserID: parseInt(UserID, 10) },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [UserID, getEmployeeData]);

  useEffect(() => {
    if (resultEmployeeData?.data) {
      const {
        data: { findUniqueUsers },
      } = resultEmployeeData;

      const cleanObject = Object.fromEntries(
        Object.entries(findUniqueUsers).filter(([_, v]) => v !== null)
      );
      setInputs((prev) => ({
        ...prev,
        ...cleanObject,
      }));
      setLoading(false);
    }
  }, [resultEmployeeData]);

  const handleChange = async (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setLoading(true);
      const obj = Object.keys(INITIAL_STATE).reduce((acc, curr) => {
        if (inputs[curr] !== null) {
          return {
            ...acc,
            [curr]: {
              set: inputs[curr],
            },
          };
        }
        return acc;
      }, {});
      delete obj.AccountType;
      delete obj.LabID;
      await updateUser({
        variables: {
          data: {
            ...obj,
            Lab: {
              connect: {
                LabID: inputs.LabID || 9999,
              },
            },
            Type: {
              connect: { RecId: parseInt(inputs.AccountType, 10) },
            },
            Status: { set: inputs.Status ? 1 : 0 },
          },
          where: {
            UserID: parseInt(UserID, 10),
          },
        },
        refetchQueries: [
          {
            query: LIST_USERS_QUERY,
            variables: {
              where: {
                Type: { is: { CodeId: { in: ["1", "2", "3"] } } },
              },
            },
            fetchPolicy: "network-only",
          },
          {
            query: SINGLE_USER_QUERY,
            variables: {
              where: { UserID: parseInt(UserID, 10) },
            },
            fetchPolicy: "network-only",
          },
        ],
      });
      toast.success("Updated successfully");
    } catch (err) {
      toast.error("Error updating employee");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePassword = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModalOpen(
      true,
      <UpdatePasswordModal
        onCancel={() => setModalOpen(false)}
        onConfirm={async (Password, ConfirmPassword) => {
          try {
            setLoading(true);
            await updatePassword({
              variables: {
                data: {
                  Password,
                  ConfirmPassword,
                },
                where: {
                  UserID: parseInt(UserID, 10),
                },
              },
              refetchQueries: [
                {
                  query: LIST_USERS_QUERY,
                  variables: {
                    where: {
                      Type: { is: { CodeId: { in: ["1", "2", "3"] } } },
                    },
                  },
                  fetchPolicy: "network-only",
                },
                {
                  query: SINGLE_USER_QUERY,
                  variables: {
                    where: { UserID: parseInt(UserID, 10) },
                  },
                  fetchPolicy: "network-only",
                },
              ],
            });
            setModalOpen(false);
            toast.success("Password updated successfully");
          } catch (err) {
            toast.error("Error updating password");
          } finally {
            setLoading(false);
          }
        }}
      />
    );
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="employee-list-page">
      <PageHeader title={`Employee ${inputs.FirstName}`}>
        {canUpdate && (
          <React.Fragment>
            <Button
              color="primary"
              disabled={editMode}
              size="small"
              onClick={handleUpdatePassword}
            >
              Update Password
            </Button>
            <Button
              color="primary"
              disabled={editMode}
              form="edit-employee-form"
              size="small"
              type="submit"
            >
              Save
            </Button>
          </React.Fragment>
        )}
      </PageHeader>

      <EmployeeForm
        UserID={UserID}
        disabled={editMode || !canUpdate}
        formId="edit-employee-form"
        inputs={inputs}
        onChange={handleChange}
        onSubmit={handleSave}
      />
    </div>
  );
};

EmployeeEditPage.propTypes = {
  routePermissions: PropTypes.array,
};

EmployeeEditPage.defaultProps = {
  routePermissions: [],
};

EmployeeEditPage.propTypes = {};

export default EmployeeEditPage;
