/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { VALIDATE_JOB_ORDER_ID_QUERY } from "../graphql";
import useDebounce from "./useDebounce";

export default function useValidatePrivateLabelJobOrderId() {
  const client = useApolloClient();
  const [jobOrderId, setJobOrderId] = useState("");

  const [valid, setValid] = useState("");
  const [orderTotal, setOrderTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const searchableJobOrderId = useDebounce(jobOrderId, 500);

  const validate = useCallback(
    async (id) => {
      try {
        setLoading(true);
        const {
          data: { validateJobOrderID },
        } = await client.query({
          query: VALIDATE_JOB_ORDER_ID_QUERY,
          variables: {
            JobOrderID: parseInt(id, 10),
          },
        });
        setValid(!!validateJobOrderID);
        setOrderTotal(validateJobOrderID?.OrderTotal);
      } catch (err) {
        toast.error(
          "Whoops! There was a problem looking up this Job Order. Please contact support."
        );
      } finally {
        setLoading(false);
      }
    },
    [client]
  );

  useEffect(() => {
    if (searchableJobOrderId) {
      validate(searchableJobOrderId);
    }
  }, [client, validate, searchableJobOrderId]);

  const _setJobOrderId = (val) => {
    setValid(false);
    setLoading(!!val.length);
    setJobOrderId(val);
  };

  return {
    jobOrderId,
    orderTotal,
    loading,
    valid,
    setJobOrderId: _setJobOrderId,
    setValid,
    validate,
  };
}
