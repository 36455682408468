const DEFAULT_COLUMNS = [
  {
    Header: "Location",
    id: "Company",
    accessor: "Company",
  },
  {
    Header: "Address",
    id: "Address",
    accessor: "Address",
  },
  {
    Header: "City",
    id: "City",
    accessor: "City",
  },
  {
    Header: "State",
    id: "State",
    accessor: "State",
  },
  {
    Header: "Corporate Customer Service",
    id: "CorporateCustomerServiceEmail",
    accessor: "CorporateCustomerServiceEmail",
  },
  {
    Header: "Onsite Customer Service",
    id: "OnsiteCustomerServiceEmail",
    accessor: "OnsiteCustomerServiceEmail",
  },
];

export default DEFAULT_COLUMNS;
