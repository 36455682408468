import React, { useState, useEffect, useMemo, useContext } from "react";
import { Button, Tab } from "rbx";
import {
  useParams,
  useHistory,
  useLocation,
  Link,
  Switch,
} from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import PropTypes from "prop-types";

import { useAuth } from "../../../context/AuthContext";
import { Loader, PageHeader } from "../../../components";
import { customToast as toast } from "../../../utils";
import { ModalContext } from "../../../context/ModalContext";
import {
  ALL_EQUIPMENT_QUERY,
  UPDATE_EQUIPMENT_MUTATION,
  SINGLE_EQUIPMENT_QUERY,
  CREATE_EQUIPMENT_LOG_MUTATION,
  ALL_EQUIPMENT_LOGS_QUERY,
  ALL_SYSTEM_CODES_QUERY,
} from "../../../graphql";
import { Profile, Attachments, Logs } from "../routes";
import EquipmentLogsModal from "../components/EquipmentLogsModal";

const INITIAL_STATE = {
  LabID: 0,
  Name: "",
  Manufacturer: "",
  SerialNumber: "",
  ModelNumber: "",
  CalibrationCycleID: "",
  LastCalibrationDate: new Date().toISOString(),
  MaintenanceCycleID: "",
  LastMaintenanceDate: new Date().toISOString(),
  ImageURL: "",
  Active: false,
  SamplePrep: false,
};

const convertInputToVariables = (variables, keys) =>
  keys.reduce((acc, curr) => {
    if (variables[curr]) {
      acc[curr] = variables[curr];
    } else {
      acc[curr] = null;
    }
    return acc;
  }, {});

const EquipmentEditPage = ({ routePermissions }) => {
  const { EquipmentID } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { state: authState } = useAuth();

  const { setModalOpen } = useContext(ModalContext);

  const [canUpdate, setCanUpdate] = useState(true);
  const [editMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [maintenanceDueDate, setMaintenanceDueDate] = useState("");
  const [calibrationDueDate, setCalibrationDueDate] = useState("");
  const [datesLogs, setDatesLogs] = useState({});

  const [updateEquipment] = useMutation(UPDATE_EQUIPMENT_MUTATION);
  const [createEquipmentLog] = useMutation(CREATE_EQUIPMENT_LOG_MUTATION);

  const [getEquipmentData, resultEquipmentData] = useLazyQuery(
    SINGLE_EQUIPMENT_QUERY
  );

  const { data: getSystemCodeData } = useQuery(ALL_SYSTEM_CODES_QUERY, {
    variables: {
      where: {
        Category: { equals: "Equipment" },
        CodeName: { equals: "LogType" },
      },
      orderBy: [{ CodeDescription: "asc" }],
    },
  });

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  useEffect(() => {
    if (EquipmentID) {
      getEquipmentData({
        variables: {
          where: { EquipmentID: parseInt(EquipmentID, 10) },
        },
        fetchPolicy: "network-only",
      });
    }
    return () => {};
  }, [EquipmentID, getEquipmentData]);

  useEffect(() => {
    if (resultEquipmentData?.data) {
      const {
        data: { findUniqueEquipment },
      } = resultEquipmentData;

      setCalibrationDueDate(
        new Date(findUniqueEquipment.CalibrationDueDate)
          .toISOString()
          .split("T")[0]
      );
      setMaintenanceDueDate(
        new Date(findUniqueEquipment.MaintenanceDueDate)
          .toISOString()
          .split("T")[0]
      );

      const cleanObject = convertInputToVariables(
        findUniqueEquipment,
        Object.keys(INITIAL_STATE)
      );

      setInputs((prev) => ({
        ...prev,
        ...cleanObject,
        LastCalibrationDate: new Date(cleanObject.LastCalibrationDate)
          .toISOString()
          .split("T")[0],
        LastMaintenanceDate: new Date(cleanObject.LastMaintenanceDate)
          .toISOString()
          .split("T")[0],
      }));
      setLoading(false);
    }
  }, [resultEquipmentData]);

  const uploadEquipmentPath = useMemo(() => {
    const date = new Date();
    return `/Images/Equipment/${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${EquipmentID}`;
  }, [EquipmentID]);

  const uploadAttachmentPath = useMemo(() => {
    const date = new Date();
    return `/Files/EquipmentLogs/Attachments/${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${EquipmentID}`;
  }, [EquipmentID]);

  const handleDatesChange = (ReasonForChange, AttachmentURL, logType) => {
    setDatesLogs({
      ...datesLogs,
      [logType]: {
        ReasonForChange,
        AttachmentURL,
      },
    });
  };

  const handleChange = async (name, value) => {
    if (
      (name === "LastCalibrationDate" || name === "LastMaintenanceDate") &&
      Object.prototype.toString.call(new Date(value)) === "[object Date]"
    ) {
      setModalOpen(
        true,
        <EquipmentLogsModal
          uploadAttachmentPath={uploadAttachmentPath}
          onCancel={() => {
            setModalOpen(false);
            toast.error("A reason for change must be provided.");
          }}
          onComplete={(reasonForChange, attachmentURL) => {
            setModalOpen(false);
            setInputs((prev) => ({
              ...prev,
              [name]: value,
            }));

            const logType = getSystemCodeData?.findManySystemCodes?.find(
              (code) =>
                code.CodeId ===
                (name === "LastCalibrationDate" ? "CALIBRATION" : "MAINTENANCE")
            );

            handleDatesChange(reasonForChange, attachmentURL, logType?.RecId);
          }}
        />
      );
    } else {
      setInputs((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (name === "ImageURL") {
        try {
          setLoading(true);
          await updateEquipment({
            variables: {
              where: {
                EquipmentID: parseInt(EquipmentID, 10),
              },
              data: {
                ImageURL: { set: value },
              },
            },
            refetchQueries: [
              {
                query: SINGLE_EQUIPMENT_QUERY,
                variables: {
                  where: { EquipmentID: parseInt(EquipmentID, 10) },
                },
                fetchPolicy: "network-only",
              },
            ],
          });
          toast.success("Image uploaded successfully");
        } catch (err) {
          toast.error("Error updating image");
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setLoading(true);
      if (Object.keys(datesLogs).length) {
        Object.keys(datesLogs).map(async (key) => {
          await createEquipmentLog({
            variables: {
              data: {
                Equipment: {
                  connect: {
                    EquipmentID: parseInt(EquipmentID, 10),
                  },
                },
                Type: { connect: { RecId: key } },
                ReasonForChange: datesLogs[key].ReasonForChange,
                AttachmentURL: datesLogs[key].AttachmentURL.length
                  ? datesLogs[key].AttachmentURL
                  : null,
                CreatedBy: authState.user.Username,
              },
            },
            refetchQueries: [
              {
                query: ALL_EQUIPMENT_LOGS_QUERY,
                where: {
                  EquipmentID: {
                    equals: parseInt(EquipmentID, 10),
                  },
                },
              },
            ],
          });
        });
      }

      const obj = Object.keys(INITIAL_STATE)
        .filter((prop) => prop !== "LabID")
        .reduce((acc, curr) => {
          if (inputs[curr] !== null) {
            return {
              ...acc,
              [curr]: {
                set: inputs[curr],
              },
            };
          }
          return acc;
        }, {});
      const updateData = {
        ...obj,
        Lab: {
          connect: {
            LabID: parseInt(inputs.LabID, 10),
          },
        },
        MaintenanceCycleSystemCode: {
          connect: {
            RecId: parseInt(inputs.MaintenanceCycleID, 10),
          },
        },
        CalibrationCycleSystemCode: {
          connect: {
            RecId: parseInt(inputs.CalibrationCycleID, 10),
          },
        },
      };
      delete updateData.MaintenanceCycleID;
      delete updateData.CalibrationCycleID;
      await updateEquipment({
        variables: {
          data: updateData,
          where: {
            EquipmentID: parseInt(EquipmentID, 10),
          },
        },
        refetchQueries: [
          {
            query: ALL_EQUIPMENT_QUERY,
            variables: {
              orderBy: { EquipmentID: "asc" },
            },
            fetchPolicy: "network-only",
          },
          {
            query: SINGLE_EQUIPMENT_QUERY,
            variables: {
              where: { EquipmentID: parseInt(EquipmentID, 10) },
            },
            fetchPolicy: "network-only",
          },
        ],
      });
      toast.success("Equipment Updated successfully");
      history.push("/equipment");
    } catch (err) {
      toast.error("Error Updating Equipment");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push("/equipment");
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <PageHeader title={inputs.Name}>
        {canUpdate && (
          <React.Fragment>
            <Button disabled={editMode} size="small" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={editMode}
              form="edit-equipment-form"
              size="small"
              type="submit"
            >
              Save
            </Button>
          </React.Fragment>
        )}
      </PageHeader>
      <Tab.Group kind="boxed">
        <Tab
          active={location.pathname.includes("profile")}
          as={Link}
          to={`/equipment/${EquipmentID}/profile`}
        >
          Profile
        </Tab>
        <Tab
          active={location.pathname.includes("attachments")}
          as={Link}
          to={`/equipment/${EquipmentID}/attachments`}
        >
          Attachments
        </Tab>
        <Tab
          active={location.pathname.includes("logs")}
          as={Link}
          to={`/equipment/${EquipmentID}/logs`}
        >
          Logs
        </Tab>
      </Tab.Group>
      <Switch>
        <Switch path="/equipment/:EquipmentID/profile">
          <Profile
            isEdit
            EquipmentID={EquipmentID}
            calibrationDueDate={calibrationDueDate}
            disabled={editMode || !canUpdate}
            formId="edit-equipment-form"
            handleChange={handleChange}
            handleSave={handleSave}
            inputs={inputs}
            maintenanceDueDate={maintenanceDueDate}
            uploadEquipmentPath={uploadEquipmentPath}
            onChange={handleChange}
            onSubmit={handleSave}
          />
        </Switch>
        <Switch path="/equipment/:EquipmentID/attachments">
          <Attachments
            EquipmentID={EquipmentID}
            disabled={editMode || !canUpdate}
          />
        </Switch>
        <Switch path="/equipment/:EquipmentID/logs">
          <Logs EquipmentID={EquipmentID} />
        </Switch>
      </Switch>
    </div>
  );
};

EquipmentEditPage.propTypes = {
  routePermissions: PropTypes.array,
};

EquipmentEditPage.defaultProps = {
  routePermissions: [],
};

EquipmentEditPage.propTypes = {};

export default EquipmentEditPage;
