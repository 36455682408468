/* eslint-disable max-lines-per-function */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-extend-native */
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  useParams,
  useLocation,
  Switch,
  Link,
  useHistory,
} from "react-router-dom";
import { Button, Tab, Tag, Icon, Dropdown, Field, Control } from "rbx";
import { getTime, addMilliseconds, isValid, format } from "date-fns";
import {
  useLazyQuery,
  useMutation,
  useApolloClient,
  useQuery,
} from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { groupBy } from "lodash";

import { useAuth } from "../../../../../context/AuthContext";
import {
  BatchLogsModal,
  BatchDetailsForm,
  RejectReasonModal,
} from "../components";
import { PageHeader, Loader, Confirmation } from "../../../../../components";
import { microSpeciesMapping } from "../../../../../utils/batchConstants";
import { ModalContext } from "../../../../../context/ModalContext";
import { Batch, Logs, Results, Attachments } from "../routes";
import {
  customToast as toast,
  generateCSVFunction,
} from "../../../../../utils";
import BuildBatchResultTemplate from "../BuildBatchResults";
import {
  SINGLE_BATCH_QUERY,
  UPDATE_BATCH_MUTATION,
  CHECK_SESSION_ID_QUERY,
  CREATE_BATCH_LOG_MUTATION,
  UPDATE_MANY_JOB_ORDER_TEST_SAMPLES_STATUS_MUTATION,
  ALL_SYSTEM_CODES_QUERY,
  FIRST_SYSTEM_CODE_QUERY,
  DELETE_MANY_QC_SAMPLES,
  ALL_QC_SAMPLE_TYPES_QUERY,
  UPDATE_JOB_ORDER_TEST_SAMPLES_BATCH,
  GENERATE_BATCH_SUMMARY_PDF_QUERY,
} from "../../../../../graphql";
import { getColor } from "../BatchRecordsListPage/columns";
import AddQualifiersModal from "../../TestStatus/components/AddQualifiersModal";

Array.prototype.move = function move(from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
  return this;
};

const INITIAL_SAMPLE_ITEM = {
  SessionID: "",
  OrderName: "",
  Weight: "",
  Volume: "",
  Dilution: "",
  PrepUserID: "",
  sampleDuplicate: false,
  matrixSpike: false,
};

const INITIAL_STATE = {
  TestCategoryID: "",
  Tests: Array.from({ length: 20 }, () => INITIAL_SAMPLE_ITEM),
  Diluents: [
    {
      Diluent: "",
      Expiration: "",
    },
  ],
  DilutedBy: "",
  LoadedBy: "",
  AnalyzedBy: "",
  SoyLotNumber: "",
  SoyLotExpiration: "",
  SolutionLotNumber: "",
  SolutionLotExpiration: "",
  Note: "",
  TestCategory: {},
  LabID: "",
  ReadyForLoading: false,
};

const EditBatchPage = ({ routePermissions }) => {
  const { BatchID } = useParams();
  const location = useLocation();
  const { state: authState } = useAuth();
  const { setModalOpen } = useContext(ModalContext);
  const references = useRef([]);
  const client = useApolloClient();

  const [canUpdate, setCanUpdate] = useState(true);
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [labState, setLabState] = useState("");
  const [editing, setEditing] = useState(false);
  const [selectedTestResult, setSelectedTestResult] = useState({});
  const [changes, setChanges] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileDownloading, setFileDownloading] = useState(false);
  const [results, setResults] = useState([]);
  const [batchResultsTemplate, setBatchResultsTemplate] = useState([]);
  const [originalDetailsInputs, setOriginalDetailsInputs] = useState({});
  const [isDetailsTabActive, setIsDetailsTabActive] = useState(false);
  const [changesOnResults, setChangesOnResults] = useState(false);
  const [showQualifiers, setShowQualifiers] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [deletedSessionIds, setDeletedSessionIds] = useState([]);
  const [qcSampleTypeID, setQCSampleTypeID] = useState("1");
  const [isMicro, setIsMicro] = useState(false);

  const [getBatch, { data: batchData }] = useLazyQuery(SINGLE_BATCH_QUERY, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });
  const [updateBatch] = useMutation(UPDATE_BATCH_MUTATION);
  const [createBatchLogs] = useMutation(CREATE_BATCH_LOG_MUTATION);
  const [updateManyJobOrderTestSamplesStatus] = useMutation(
    UPDATE_MANY_JOB_ORDER_TEST_SAMPLES_STATUS_MUTATION
  );
  const [updateJobOrderTestSamplesBatch] = useMutation(
    UPDATE_JOB_ORDER_TEST_SAMPLES_BATCH
  );
  const [deleteManyQCSamples] = useMutation(DELETE_MANY_QC_SAMPLES);
  const { data: getQCSampleTypeData } = useQuery(ALL_QC_SAMPLE_TYPES_QUERY, {});
  const { data: getStatusData } = useQuery(ALL_SYSTEM_CODES_QUERY, {
    variables: {
      where: {
        OR: [
          {
            AND: [
              { Category: { equals: "JobOrderSample" } },
              { CodeName: { equals: "ResultStatus" } },
            ],
          },
          {
            AND: [
              { Category: { equals: "Batch" } },
              { CodeName: { equals: "Status" } },
            ],
          },
        ],
      },
      orderBy: {
        CodeId: "asc",
      },
    },
  });

  const history = useHistory();

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  useEffect(() => {
    setIsDetailsTabActive(location.pathname.includes("details"));
  }, [location]);

  useEffect(() => {
    if (BatchID) {
      getBatch({
        variables: {
          where: {
            BatchID: parseInt(BatchID, 10),
          },
        },
      });
    }
  }, [BatchID, getBatch]);

  // :-( in the future we should avoid this. we can just use a date time input

  const getDateStrAndTimeStrFromDate = (d) => {
    const x = new Date(d);
    if (d && isValid(x)) {
      return [format(x, "yyyy-MM-dd"), format(x, "HH:mm")];
    }
    return [];
  };
  useEffect(() => {
    if (batchData?.findUniqueBatches) {
      const foundBatch = batchData.findUniqueBatches;

      const [incStartDate, incStartTime] = getDateStrAndTimeStrFromDate(
        foundBatch.IncubationStartDateTime
      );
      const [incEndDate, incEndTime] = getDateStrAndTimeStrFromDate(
        foundBatch.IncubationEndDateTime
      );

      setInputs((prev) => ({
        ...prev,
        TestCategoryID: foundBatch.TestCategoryID,
        Tests: foundBatch.Tests.map((test) => {
          if (
            test.SessionID &&
            !test.JobOrderTestSampleID &&
            !test.QCSampleID
          ) {
            const qcSample = foundBatch.TestSamples?.find(
              (sample) => sample.QCSample?.SessionID === test.SessionID
            );
            if (qcSample) {
              return {
                ...test,
                QCSampleID: qcSample.QCSample?.QCSampleID,
                jobOrderID: qcSample.JobOrder?.JobOrderID,
                testName: qcSample?.JobOrderTest?.Test?.Name,
                saved: true,
                showMSSDFields: true,
              };
            }
          }

          if (foundBatch.TestCategory?.Code?.includes("MICRO")) {
            if (test.SessionID) {
              const existingSample = foundBatch.TestSamples?.find(
                (sample) =>
                  sample.JobOrderTestSample?.SessionID === test.SessionID ||
                  sample.QCSample?.SessionID === test.SessionID
              );

              if (existingSample) {
                const testSpecies = [];
                existingSample.JobOrderTestSample?.JobOrderTest?.Test?.TestAnalytes?.forEach(
                  (obj) => {
                    if (
                      obj.Analyte?.Active &&
                      obj?.Analyte?.TestCategoryID === foundBatch.TestCategoryID
                    ) {
                      const speciesObj = microSpeciesMapping.find((mapObj) =>
                        mapObj?.analytes.includes(obj.Analyte?.Name)
                      );
                      if (
                        speciesObj &&
                        !testSpecies.includes(speciesObj?.species)
                      ) {
                        testSpecies.push(speciesObj.species);
                      }
                    }
                  }
                );

                return {
                  ...test,
                  testSpecies,
                  jobOrderID: existingSample.JobOrder?.JobOrderID,
                  saved: true,
                  showMSSDFields:
                    existingSample.JobOrderTestSample?.JobOrderTest?.Test
                      ?.Code !== "TOX",
                  testName:
                    existingSample?.JobOrderTestSample?.JobOrderTest?.Test
                      ?.Name || existingSample?.JobOrderTest?.Test?.Name,
                };
              }
            }
          }
          if (test.SessionID && test.JobOrderTestSampleID) {
            const foundJobOrderTestSample =
              batchData.findUniqueBatches?.JobOrderTestSamples.find(
                (jobOrderTestSample) =>
                  jobOrderTestSample.JobOrderTestSampleID ===
                  test.JobOrderTestSampleID
              );
            return {
              ...test,
              saved: true,
              showMSSDFields:
                foundJobOrderTestSample?.JobOrderTest?.Test?.Code !== "TOX",
            };
          }
          return test.SessionID
            ? { ...test, saved: true, showMSSDFields: true }
            : { ...test, showMSSDFields: true };
        }),
        Diluents: foundBatch?.Diluents?.length
          ? foundBatch.Diluents.map((diluent) => diluent)
          : [
              {
                Diluent: "",
                Expiration: "",
              },
            ],
        DilutedBy: foundBatch.DilutedBy,
        LoadedBy: foundBatch.LoadedBy,
        AnalyzedBy: foundBatch.AnalyzedBy,
        SoyLotNumber: foundBatch.SoyLotNumber,
        SoyLotExpiration: foundBatch.SoyLotExpiration,
        SolutionLotNumber: foundBatch.SolutionLotNumber,
        SolutionLotExpiration: foundBatch.SolutionLotExpiration,
        IncubationStartDateTime: incStartDate,
        IncubationStartTime: incStartTime,
        IncubationEndDateTime: incEndDate,
        IncubationEndTime: incEndTime,
        IncubationStartedBy: foundBatch.IncubationStartedBy,
        IncubationEndedBy: foundBatch.IncubationEndedBy,
        TestCategory: foundBatch.TestCategory,
        Note: foundBatch.Note,
        LabID: foundBatch.LabID,
        ReadyForLoading: foundBatch.ReadyForLoading,
      }));

      setLabState(foundBatch?.Lab?.State);
      setIsMicro(!!foundBatch.TestCategory?.Code?.includes("MICRO"));
      setOriginalDetailsInputs({
        Diluents: foundBatch?.Diluents?.length
          ? foundBatch.Diluents.map((diluent) => diluent)
          : [
              {
                Diluent: "",
                Expiration: "",
              },
            ],
        DilutedBy: foundBatch.DilutedBy,
        LoadedBy: foundBatch.LoadedBy,
        AnalyzedBy: foundBatch.AnalyzedBy,
        SoyLotNumber: foundBatch.SoyLotNumber,
        SoyLotExpiration: foundBatch.SoyLotExpiration,
        SolutionLotNumber: foundBatch.SolutionLotNumber,
        SolutionLotExpiration: foundBatch.SolutionLotExpiration,
        TestCategory: foundBatch.TestCategory,
        Note: foundBatch.Note,
      });

      const parsedResults = foundBatch?.TestSamples?.map((res) => {
        const lowercaseKeys = (obj) => {
          if (!obj) {
            return {};
          }
          return Object.keys(obj).reduce((acc, key) => {
            acc[key.toLowerCase().trim()] = obj[key];
            return acc;
          }, {});
        };
        return {
          ...res,
          ...res.JobOrderTestSample,
          ...res.QCSample,
          AnalyteResults: res?.JobOrderTestSample?.Result
            ? lowercaseKeys(res?.JobOrderTestSample?.Result)
            : lowercaseKeys(res?.QCSample?.Result),
          Qualifiers: res?.JobOrderTestSample?.Qualifiers
            ? lowercaseKeys(res?.JobOrderTestSample?.Qualifiers)
            : lowercaseKeys(res?.QCSample?.Qualifiers),
          SystemQualifiers: res?.JobOrderTestSample?.SystemQualifiers
            ? lowercaseKeys(res?.JobOrderTestSample?.SystemQualifiers)
            : lowercaseKeys(res?.QCSample?.SystemQualifiers),
          TargetValues: res?.QCSample?.TargetValues
            ? lowercaseKeys(res?.QCSample?.TargetValues)
            : null,
          QCTarget: {
            TargetValues: res?.QCTarget?.TargetValues
              ? lowercaseKeys(res?.QCTarget?.TargetValues)
              : null,
          },
          Status:
            res.JobOrderTestSample?.ResultStatus?.CodeDescription ||
            res.QCSample?.ResultStatus?.CodeDescription,
          OriginalStatus:
            res.JobOrderTestSample?.ResultStatus?.CodeDescription ||
            res.QCSample?.ResultStatus?.CodeDescription,
          needSave: false,
        };
      });
      setResults(parsedResults);
    }
  }, [batchData]);

  useEffect(() => {
    if (results?.length) {
      setBatchResultsTemplate(BuildBatchResultTemplate(results, labState));
    }
  }, [results, labState]);

  useEffect(() => {
    if (labState === "AZ") {
      setShowQualifiers(true);
    }
  }, [labState]);

  const handleInputsChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleBatchLogsChange = (change) => {
    setChanges((prev) => (prev.length ? `${prev}, ${change}` : change));
  };

  const handleTestsChange = (name, value, index) => {
    if (name === "sampleDuplicate" || name === "matrixSpike") {
      const currentTest = inputs.Tests.find((test) => test[name]);
      const newTest = inputs.Tests[index];

      const isNotNewName = batchData.findUniqueBatches.Tests.some(
        (test) => !!test[name]
      );

      if (isNotNewName) {
        const nameFormatted =
          name === "matrixSpike" ? "Matrix Spike" : "Sample Duplicate";
        const change = `${nameFormatted} updated from ${currentTest?.SessionID} to ${newTest?.SessionID}`;
        handleBatchLogsChange(change);
      }

      const newTests = inputs.Tests.map((item, i) => {
        if (i === index) {
          return { ...item, [name]: true };
        }
        if (item[name]) {
          return { ...item, [name]: false };
        }
        return item;
      });

      setInputs((prev) => ({
        ...prev,
        Tests: newTests,
      }));
    } else {
      const newTests = inputs.Tests.map((item, i) => {
        if (name === "SessionID" && value) {
          return i === index ? { ...item, [name]: value } : item;
        }
        if (name === "SessionID" && !value) {
          return i === index ? { ...item, [name]: value, OrderName: "" } : item;
        }
        if (i === index) {
          return { ...item, [name]: true };
        }
        if (item[name]) {
          return { ...item, [name]: false };
        }
        return item;
      });
      setInputs((prev) => ({
        ...prev,
        Tests: newTests,
      }));
    }
  };

  const handleFocusById = (index) => {
    references.current[index]?.focus();
  };

  const handleSessionBlur = async (index) => {
    try {
      const foundTest = inputs.Tests.find((item, i) => i === index);
      const sessionIdAlreadyExists = inputs.Tests.find(
        (item, i) =>
          item.SessionID &&
          foundTest.SessionID &&
          item.SessionID === foundTest.SessionID &&
          i !== index
      );
      const [JobID, JobOrderID, JobOrderTestID] =
        foundTest.SessionID.split("_");
      if (!sessionIdAlreadyExists) {
        if (JobID && JobOrderID && JobOrderTestID) {
          const { data: foundJobOrderTestSample } = await client.query({
            query: CHECK_SESSION_ID_QUERY,
            variables: {
              where: {
                JobID: { equals: parseInt(JobID, 10) },
                JobOrderID: { equals: parseInt(JobOrderID, 10) },
                JobOrderTestID: { equals: parseInt(JobOrderTestID, 10) },
                SessionID: { equals: foundTest.SessionID },
              },
              TestCategory: {
                TestCategoryID: parseInt(inputs.TestCategoryID, 10),
              },
              Lab: {
                LabID: parseInt(inputs.LabID, 10),
              },
            },
          });
          if (!foundJobOrderTestSample?.checkIfSessionIdExists) {
            toast.error(`Session ID ${foundTest.SessionID} is not valid`);
            handleFocusById(index);
          } else {
            const tests = Object.assign([], [...inputs.Tests]);
            const newTestObj = {
              ...tests[index],
              SessionID: tests[index].SessionID.toLowerCase(),
              JobOrderTestSampleID:
                foundJobOrderTestSample.checkIfSessionIdExists
                  .JobOrderTestSampleID,
              OrderName:
                foundJobOrderTestSample.checkIfSessionIdExists.JobOrder
                  .OrderName,
              showMSSDFields:
                foundJobOrderTestSample.checkIfSessionIdExists.JobOrderTest
                  ?.Test?.Code !== "TOX",
            };
            if (isMicro) {
              const testSpecies = [];
              foundJobOrderTestSample.checkIfSessionIdExists.JobOrderTest?.Test?.TestAnalytes?.forEach(
                (obj) => {
                  if (
                    obj.Analyte?.Active &&
                    obj?.Analyte?.TestCategoryID === inputs.TestCategoryID
                  ) {
                    const speciesObj = microSpeciesMapping.find((mapObj) =>
                      mapObj?.analytes.includes(obj.Analyte?.Name)
                    );
                    if (
                      speciesObj &&
                      !testSpecies.includes(speciesObj?.species)
                    ) {
                      testSpecies.push(speciesObj.species);
                    }
                  }
                }
              );

              newTestObj.testSpecies = testSpecies;
              newTestObj.jobOrderID =
                foundJobOrderTestSample.checkIfSessionIdExists.JobOrderID;
              newTestObj.testName =
                foundJobOrderTestSample.checkIfSessionIdExists.JobOrderTest?.Test?.Name;
            }

            const newTests = tests.map((item, i) =>
              i === index ? { ...newTestObj } : item
            );

            handleBatchLogsChange(`Added ${foundTest.SessionID}`);

            setInputs((prev) => ({
              ...prev,
              Tests: newTests,
            }));
            handleFocusById(index + 1);
          }
        } else if (foundTest.SessionID) {
          handleFocusById(index);
        }
      } else {
        handleFocusById(index);
        toast.error("This Session ID already exists on this batch");
      }
    } catch (err) {
      handleFocusById(index);
      toast.error(err.message);
    }
  };

  // for micro batches, need to add multiple QCSamples based on sessionIDs entered before creating a new batch
  const updateSamplesBeforeCreatingMicroBatch = () => {
    const initialTimestamp = getTime(new Date());
    let increment = 0;

    const primarySampleDupeSample = inputs.Tests.find((t) => t.sampleDuplicate);

    const PositiveControlQCSampleType =
      getQCSampleTypeData?.findManyQCSampleTypes?.find(
        (type) => type.Code === "PC"
      );
    const NegativeControlQCSampleType =
      getQCSampleTypeData?.findManyQCSampleTypes?.find(
        (type) => type.Code === "NC"
      );
    const SampleDupeQCSampleType =
      getQCSampleTypeData?.findManyQCSampleTypes?.find(
        (type) => type.Code === "SD"
      );

    const positiveControlSamplesToAdd = [];
    const negativeControlSamplesToAdd = [];
    const sampleDupeSamplesToAdd = [];

    const enteredSamples = inputs.Tests.map((test) => {
      // create a PC and NC QCSample for every species represented in the JobOrderTestSamples
      if (test.JobOrderTestSampleID && test?.testSpecies?.length) {
        test?.testSpecies?.forEach((speciesName) => {
          const existingSpecies =
            positiveControlSamplesToAdd.find(
              (controlSample) => controlSample.Species === speciesName
            ) || inputs.Tests.find((sample) => sample.Species === speciesName);
          if (!existingSpecies) {
            const posTimestamp = getTime(
              addMilliseconds(initialTimestamp, increment)
            ).toString();
            const negTimestamp = getTime(
              addMilliseconds(initialTimestamp, increment + 1)
            ).toString();
            increment += 2;

            positiveControlSamplesToAdd.push({
              ...INITIAL_SAMPLE_ITEM,
              OrderName: PositiveControlQCSampleType?.Name,
              SessionID: `${
                PositiveControlQCSampleType?.Code
              }_${posTimestamp.substring(0, 8)}_${posTimestamp.substring(8)}`,
              PrimarySampleSessionID: test.SessionID,
              Species: speciesName,
            });
            negativeControlSamplesToAdd.push({
              ...INITIAL_SAMPLE_ITEM,
              OrderName: NegativeControlQCSampleType?.Name,
              SessionID: `${
                NegativeControlQCSampleType?.Code
              }_${negTimestamp.substring(0, 8)}_${negTimestamp.substring(8)}`,
              PrimarySampleSessionID: test.SessionID,
              Species: speciesName,
            });
          }
        });
      }
      // create an SD QCSample for every JobOrderTestSample on the same JobOrder as the JobOrderTestSample checked as the SD
      if (
        test.JobOrderTestSampleID &&
        primarySampleDupeSample?.jobOrderID === test.jobOrderID &&
        !inputs.Tests.find(
          (sample) =>
            sample.OrderName === "Sample Duplicate" &&
            sample.PrimarySampleSessionID === test.SessionID
        ) &&
        !(inputs.TestCategory?.Code?.includes("MICRO BMX") && labState === "AZ")
      ) {
        const timestamp = getTime(
          addMilliseconds(initialTimestamp, increment)
        ).toString();
        increment += 1;
        sampleDupeSamplesToAdd.push({
          ...INITIAL_SAMPLE_ITEM,
          OrderName: SampleDupeQCSampleType?.Name,
          SessionID: `${SampleDupeQCSampleType?.Code}_${timestamp.substring(
            0,
            8
          )}_${timestamp.substring(8)}`,
          PrimarySampleSessionID: test.SessionID,
        });
      }
      if (
        test.OrderName === "Sample Duplicate" &&
        test.jobOrderID &&
        primarySampleDupeSample?.jobOrderID !== test.jobOrderID
      ) {
        setDeletedSessionIds((prev) => [...prev, test.SessionID]);
        return null;
      }

      const result = { ...test };
      delete result.testSpecies;
      delete result.jobOrderID;
      delete result.testName;
      return result;
    }).filter((test) => test);

    return [
      ...negativeControlSamplesToAdd,
      ...positiveControlSamplesToAdd,
      ...sampleDupeSamplesToAdd,
      ...enteredSamples,
    ];
  };

  // Make validations before returning the batch records to save
  const getBatchPayload = () => {
    let payload = [];
    // if MICRO Batch, add corresponding QCSamples
    payload = isMicro ? updateSamplesBeforeCreatingMicroBatch() : inputs?.Tests;

    // if ReadyForLoading is true, load just the records with SessionID
    payload = inputs?.ReadyForLoading
      ? payload.filter((test) => test.SessionID)
      : payload;

    return payload;
  };

  const handleSave = async () => {
    const batchPayload = getBatchPayload();
    if (isDetailsTabActive) {
      setLoading(true);
      try {
        const inputsWithValues = Object.keys(inputs).reduce((acc, curr) => {
          if (
            inputs[curr] !== "" &&
            curr !== "TestCategoryID" &&
            curr !== "TestCategory" &&
            curr !== "LabID" &&
            curr !== "jobOrderID" &&
            curr !== "testName" &&
            curr !== "IncubationStartTime" &&
            curr !== "IncubationEndTime" &&
            curr !== "IncubationStartDateTime" &&
            curr !== "IncubationEndDateTime"
          ) {
            acc[curr] = {
              set: inputs[curr],
            };
          }

          return acc;
        }, {});

        const dataToSave = {
          ...inputsWithValues,
          Diluents: inputs?.Diluents,
          Tests: batchPayload,
          Lab: {
            connect: {
              LabID: parseInt(inputs.LabID, 10),
            },
          },
        };

        const startDateTime = new Date(
          [
            inputs.IncubationStartDateTime,
            inputs.IncubationStartTime || "13:00",
          ]
            .filter(Boolean)
            .join(" ")
        );
        const endDateTime = new Date(
          [inputs.IncubationEndDateTime, inputs.IncubationEndTime || "13:00"]
            .filter(Boolean)
            .join(" ")
        );

        if (endDateTime.getTime() < startDateTime.getTime()) {
          toast.error(
            "The Incubation/Enrichment End Time must be after the Start Time"
          );
          return;
        }

        dataToSave.IncubationStartDateTime = {
          set: startDateTime || null,
        };

        dataToSave.IncubationEndDateTime = {
          set: endDateTime || null,
        };

        await updateBatch({
          variables: {
            where: {
              BatchID: parseInt(BatchID, 10),
            },
            data: dataToSave,
          },
          refetchQueries: [
            {
              query: SINGLE_BATCH_QUERY,
              variables: {
                where: {
                  BatchID: parseInt(BatchID, 10),
                },
              },
            },
          ],
        });
        setEditing(false);
        toast.success("Batch updated successfully");
      } catch (err) {
        toast.error("Unable to update Batch record");
      } finally {
        setLoading(false);
      }
    } else {
      setModalOpen(
        true,
        <BatchLogsModal
          onCancel={() => {
            setModalOpen(false);
            toast.error("A reason for change must be provided.");
          }}
          onComplete={async (reasonForChange) => {
            setModalOpen(false);
            setLoading(true);
            try {
              const inputsWithValues = Object.keys(inputs).reduce(
                (acc, curr) => {
                  if (
                    inputs[curr] !== "" &&
                    curr !== "TestCategoryID" &&
                    curr !== "TestCategory" &&
                    curr !== "LabID" &&
                    curr !== "jobOrderID" &&
                    curr !== "testName" &&
                    curr !== "IncubationStartTime" &&
                    curr !== "IncubationEndTime" &&
                    curr !== "IncubationStartDateTime" &&
                    curr !== "IncubationEndDateTime"
                  ) {
                    acc[curr] = {
                      set: inputs[curr],
                    };
                  }

                  return acc;
                },
                {}
              );

              const dataToSave = {
                ...inputsWithValues,
                Diluents: inputs?.Diluents,
                Tests: batchPayload,
                Lab: {
                  connect: {
                    LabID: parseInt(inputs.LabID, 10),
                  },
                },
              };

              const startDateTime = new Date(
                [
                  inputs.IncubationStartDateTime,
                  inputs.IncubationStartTime || "13:00",
                ]
                  .filter(Boolean)
                  .join(" ")
              );
              const endDateTime = new Date(
                [
                  inputs.IncubationEndDateTime,
                  inputs.IncubationEndTime || "13:00",
                ]
                  .filter(Boolean)
                  .join(" ")
              );

              if (endDateTime.getTime() < startDateTime.getTime()) {
                toast.error(
                  "The Incubation/Enrichment End Time must be after the Start Time"
                );
                return;
              }

              dataToSave.IncubationStartDateTime = {
                set: startDateTime || null,
              };

              dataToSave.IncubationEndDateTime = {
                set: endDateTime || null,
              };

              await updateBatch({
                variables: {
                  where: {
                    BatchID: parseInt(BatchID, 10),
                  },
                  data: dataToSave,
                },
                refetchQueries: [
                  {
                    query: SINGLE_BATCH_QUERY,
                    variables: {
                      where: {
                        BatchID: parseInt(BatchID, 10),
                      },
                    },
                  },
                ],
              });

              if (deletedSessionIds.length) {
                const OR = deletedSessionIds.reduce((acc, curr) => {
                  if (!inputs.Tests.some((test) => test.SessionID === curr)) {
                    acc.push({
                      SessionID: {
                        equals: curr,
                      },
                    });
                  }
                  return acc;
                }, []);

                if (OR.length) {
                  await updateJobOrderTestSamplesBatch({
                    variables: {
                      where: {
                        OR,
                      },
                    },
                  });

                  await deleteManyQCSamples({
                    variables: {
                      where: {
                        OR,
                      },
                    },
                  });
                }
              }

              if (changes.length) {
                createBatchLogs({
                  variables: {
                    data: {
                      Change: changes,
                      Batches: {
                        connect: {
                          BatchID: parseInt(BatchID, 10),
                        },
                      },
                      ReasonForChange: reasonForChange,
                      CreatedBy: authState.user.Username,
                    },
                  },
                });
              }
              setEditing(false);
              toast.success("Batch updated successfully");
            } catch (err) {
              toast.error("Unable to update Batch record");
            } finally {
              setLoading(false);
            }
          }}
        />
      );
    }
  };

  const handleAddRow = (e) => {
    e.preventDefault();
    setInputs((prev) => ({
      ...prev,
      Tests: [...prev.Tests, INITIAL_SAMPLE_ITEM],
    }));
  };

  const handleAddQCRow = (e) => {
    e.preventDefault();
    try {
      const qcSampleType = getQCSampleTypeData?.findManyQCSampleTypes?.find(
        (type) => type.QCSampleTypeID === qcSampleTypeID
      );
      if (qcSampleType) {
        if (!qcSampleType.AllowMultiple) {
          const matchingQCSample = inputs.Tests.find(
            (test) => test.OrderName === qcSampleType.Name
          );
          if (matchingQCSample) {
            toast.error(
              `A ${qcSampleType.Name} sample already exists on this batch`
            );
            return false;
          }
        }
        const timestamp = getTime(new Date()).toString();
        setInputs((prev) => ({
          ...prev,
          Tests: [
            ...prev.Tests,
            {
              ...INITIAL_SAMPLE_ITEM,
              OrderName: qcSampleType?.Name,
              SessionID: `${qcSampleType?.Code}_${timestamp.substring(
                0,
                8
              )}_${timestamp.substring(8)}`,
            },
          ],
        }));
      } else {
        toast.error("Unable to add QC sample");
      }
    } catch {
      toast.error("Unable to add QC sample");
    }
    return false;
  };

  const handleMoveTestUp = (index) => {
    const groupedColumns = groupBy(inputs.Tests, "SessionID");
    const repeated = Object.keys(groupedColumns).filter(
      (x) => x !== "" && groupedColumns[x].length > 1
    );

    if (repeated.length) {
      return;
    }
    if (index > 0) {
      const tests = Object.assign([], [...inputs.Tests]);
      tests.move(index, index - 1);
      setInputs((prev) => ({
        ...prev,
        Tests: tests,
      }));
    }
  };

  const handleMoveTestDown = (index) => {
    const groupedColumns = groupBy(inputs.Tests, "SessionID");
    const repeated = Object.keys(groupedColumns).filter(
      (x) => x !== "" && groupedColumns[x].length > 1
    );

    if (repeated.length) {
      return;
      // toast.error("This Session ID already exists on this batch");
    }
    if (index <= inputs.Tests.length) {
      const tests = Object.assign([], [...inputs.Tests]);
      tests.move(index, index + 1);
      setInputs((prev) => ({
        ...prev,
        Tests: tests,
      }));
    }
  };

  const handleDeleteTest = (index) => {
    const test = inputs.Tests[index];
    const associatedQCSamples = isMicro
      ? inputs.Tests.filter((t) => t.PrimarySampleSessionID === test.SessionID)
      : [];
    handleBatchLogsChange(`Removed ${test.SessionID}`);
    setDeletedSessionIds((prev) => [
      ...prev,
      test.SessionID,
      ...associatedQCSamples?.map((s) => s.SessionID),
    ]);
    const tests = inputs.Tests.filter(
      (item, i) =>
        i !== index &&
        !associatedQCSamples?.find((t) => t.SessionID === item.SessionID)
    );
    setInputs((prev) => ({
      ...prev,
      Tests: tests,
    }));
    if (tests.length < 20) {
      setInputs((prev) => ({
        ...prev,
        Tests: [...prev.Tests, INITIAL_SAMPLE_ITEM],
      }));
    }
  };

  const handleSelectTest = async (index) => {
    const foundTest = inputs.Tests.find((test, i) => i === index);
    const foundResult = results.find(
      (item) => item.SessionID === foundTest.SessionID
    );
    setSelectedTestResult({
      ...foundResult,
      index,
    });
  };

  const handleDetailsFormCancel = () => {
    setInputs((prev) => ({
      TestCategoryID: prev.TestCategoryID,
      Diluents: prev.Diluents,
      Tests: prev.Tests.map((test) =>
        test.SessionID ? { ...test, saved: true } : test
      ),
      ...originalDetailsInputs,
    }));

    history.push(`/lims/batch-records/${BatchID}/batch-info`);
  };

  const handleSessionIdEnterKeyPress = (index) => {
    handleSessionBlur(index);
  };

  const handleExportCSV = () => {
    generateCSVFunction(new Date().getTime(), inputs.Tests, [
      {
        Header: "Name",
        id: "OrderName",
        accessor: "OrderName",
      },
      {
        Header: "Session ID",
        id: "SessionID",
        accessor: "SessionID",
      },
    ]);
  };

  const handleChangeResults = (name, value, item, includesFailedAnalyte) => {
    let Status = null;
    if (value) {
      switch (name) {
        case "approve":
          Status = "Approved";
          break;
        case "reject":
          Status = "Rejected";
          break;
        case "restore":
          Status = "Restored";
          break;
        default:
          break;
      }
    }
    if (item.QCSample) {
      setResults((prev) =>
        prev.map((result) =>
          result.QCSample?.SessionID === item.QCSample?.SessionID
            ? {
                ...result,
                Status,
                IncludesFailedResult: includesFailedAnalyte,
                needSave: true,
              }
            : result
        )
      );
    } else {
      setResults((prev) =>
        prev.map((result) =>
          result.JobOrderTestSample?.SessionID ===
          item.JobOrderTestSample?.SessionID
            ? {
                ...result,
                Status,
                IncludesFailedResult: includesFailedAnalyte,
                needSave: true,
              }
            : result
        )
      );
    }
    setChangesOnResults(true);
  };

  const handleChangeStatusOfAll = (Status, SessionIDs) => {
    setResults((prev) =>
      prev.map((result) => ({
        ...result,
        Status: SessionIDs.includes(result.SessionID) ? Status : result.Status,
        needSave: !!SessionIDs.includes(result.SessionID),
      }))
    );
    setChangesOnResults(true);
  };

  const handleCompleteSaveResultStatus = async (result, batchStatus = null) => {
    try {
      const [ApprovedResult, RejectedResult, RestoredResult] =
        getStatusData.findManySystemCodes.filter(
          (code) => code.Category === "JobOrderSample" && code.CodeId !== "I"
        );
      const [CompletedBatchStatus, InProcessBatchStatus] =
        getStatusData.findManySystemCodes.filter(
          (code) =>
            code.Category === "Batch" &&
            (code.CodeId === "C" || code.CodeId === "IP")
        );

      const resultsToSave = result
        .map((res) => {
          const whereType = res.JobOrderTestSample
            ? "whereJobOrderTestSamples"
            : "whereQCSamples";
          const where = res.JobOrderTestSample
            ? {
                JobOrderTestSampleID: parseInt(
                  res.JobOrderTestSample.JobOrderTestSampleID,
                  10
                ),
              }
            : {
                QCSampleID: parseInt(res.QCSample?.QCSampleID, 10),
              };
          return res.needSave
            ? {
                data: {
                  RejectNote: {
                    set: res.rejectNote || "",
                  },
                  ResultStatus:
                    res.Status && res.Status !== "Restored"
                      ? {
                          connect: {
                            RecId:
                              res.Status === "Approved"
                                ? parseInt(ApprovedResult.RecId, 10)
                                : parseInt(RejectedResult.RecId, 10),
                          },
                        }
                      : {
                          connect: {
                            RecId: parseInt(RestoredResult.RecId, 10),
                          },
                        },
                  IncludesFailedResult: {
                    set: !!res.IncludesFailedResult,
                  },
                  ReviewedBy: {
                    set: authState.user.Username,
                  },
                  CreatedBy: {
                    set: authState.user.Username,
                  },
                  CreatedDateTime: {
                    set: new Date(),
                  },
                  SessionID: {
                    set: res.SessionID,
                  },
                },
                Approval: res.Status === "Approved",
                [whereType]: where,
                qcSampleSpecificData: {
                  TargetValues: {
                    ...res.QCTarget?.TargetValues,
                    ...res.TargetValues,
                  },
                },
              }
            : null;
        })
        .filter((res) => res);
      if (resultsToSave.length) {
        await updateManyJobOrderTestSamplesStatus({
          variables: { data: { arrayInfo: resultsToSave } },
        });
        if (batchStatus) {
          await updateBatch({
            variables: {
              data: {
                BatchStatus: {
                  connect: {
                    RecId:
                      batchStatus === "Complete"
                        ? parseInt(CompletedBatchStatus.RecId, 10)
                        : parseInt(InProcessBatchStatus.RecId, 10),
                  },
                },
              },
              where: { BatchID: parseInt(BatchID, 10) },
            },
            refetchQueries: [
              {
                query: SINGLE_BATCH_QUERY,
                variables: {
                  where: {
                    BatchID: parseInt(BatchID, 10),
                  },
                },
              },
            ],
          });
          // Batch log creation
          let Change = "";
          let ReasonForChange = "";
          const rejectedResults = result.filter(
            (res) => res.Status === "Rejected" && res.needSave
          );
          const approvedResults = result.filter(
            (res) => res.Status === "Approved" && res.needSave
          );
          const restoredResults = result.filter(
            (res) => res.Status === "Restored" && res.needSave
          );
          if (rejectedResults.length) {
            Change += `Rejected sample${
              rejectedResults.length > 1 ? "s" : ""
            }: `;
            rejectedResults.forEach((res, index) => {
              Change += `${res.SessionID}${
                index < rejectedResults.length - 1 ? ", " : ". "
              }`;
            });
            rejectedResults.forEach((res, index) => {
              ReasonForChange += `${res.rejectNote}${
                index < rejectedResults.length - 1 ? ", " : "."
              }`;
            });
          }
          if (approvedResults.length) {
            Change += `Approved sample${
              approvedResults.length > 1 ? "s" : ""
            }: `;
            approvedResults.forEach((res, index) => {
              Change += `${res.SessionID}${
                index < approvedResults.length - 1 ? ", " : ". "
              }`;
            });
          }
          if (restoredResults.length) {
            Change += `Restored sample${
              restoredResults.length > 1 ? "s" : ""
            }: `;
            restoredResults.forEach((res, index) => {
              Change += `${res.SessionID}${
                index < restoredResults.length - 1 ? ", " : "."
              }`;
            });
            restoredResults.forEach((res, index) => {
              ReasonForChange += `${res.rejectNote}${
                index < rejectedResults.length - 1 ? ", " : "."
              }`;
            });
          }
          await createBatchLogs({
            variables: {
              data: {
                Change,
                Batches: {
                  connect: {
                    BatchID: parseInt(BatchID, 10),
                  },
                },
                ReasonForChange,
                CreatedBy: authState.user.Username,
              },
            },
          });
        }
      }
      setChangesOnResults(false);
      setModalOpen(false);
    } catch (e) {
      toast.error("Error on saving");
    }
  };

  const handleSaveResults = () => {
    const rejectedResultsToSave = results.filter(
      (result) => result.Status === "Rejected" && result.needSave
    );
    const approvedResultsToSave = results.filter(
      (result) => result.Status === "Approved" && result.needSave
    );
    const restoredResultsToSave = results.filter(
      (result) => result.Status === "Restored" && result.needSave
    );
    const blankResultsToSave = results.filter(
      (result) => !result.Status && result.needSave
    );
    const allRejectedResults = results.filter(
      (result) => result.Status === "Rejected"
    );
    const allApprovedResults = results.filter(
      (result) => result.Status === "Approved"
    );
    if (rejectedResultsToSave.length || restoredResultsToSave.length) {
      setModalOpen(
        true,
        <RejectReasonModal
          displayMany={
            [...rejectedResultsToSave, ...restoredResultsToSave].length > 1
              ? [...rejectedResultsToSave, ...restoredResultsToSave]
              : null
          }
          results={results}
          onCancel={() => setModalOpen(false)}
          onComplete={(result) =>
            handleCompleteSaveResultStatus(
              result,
              allRejectedResults.length + allApprovedResults.length ===
                results.length
                ? "Complete"
                : "Partial"
            )
          }
        />
      );
    } else if (
      allRejectedResults.length + allApprovedResults.length ===
      results.length
    ) {
      handleCompleteSaveResultStatus(
        [...rejectedResultsToSave, ...approvedResultsToSave].filter(
          (res) => res.needSave
        ),
        "Complete"
      );
    } else {
      handleCompleteSaveResultStatus(
        [
          ...rejectedResultsToSave,
          ...approvedResultsToSave,
          ...blankResultsToSave,
        ].filter((res) => res.needSave),
        "Partial"
      );
    }
  };

  const handleClickQualifiers = () => {
    setShowQualifiers(!showQualifiers);
  };

  const handleQCTypeChange = (name, val) => {
    setQCSampleTypeID(val);
  };

  if (loading || !batchData) {
    return <Loader />;
  }

  const getTitle = (batch) => (
    <div className="batch-header">
      <h1>{`Batch ${BatchID} - ${batch?.TestCategory?.Code || ""}`}</h1>
      {batch?.BatchStatus ? (
        <Tag color={getColor(batch?.BatchStatus?.CodeId)}>
          {batch?.BatchStatus?.CodeDescription}
        </Tag>
      ) : null}
    </div>
  );

  const handleArchiveBatch = async (e) => {
    const performArchive = async () => {
      try {
        const { data: archivedStatus } = await client.query({
          query: FIRST_SYSTEM_CODE_QUERY,
          variables: {
            where: {
              Category: { equals: "Batch" },
              CodeName: { equals: "Status" },
              CodeId: { equals: "A" },
            },
          },
        });
        await updateBatch({
          variables: {
            where: {
              BatchID: parseInt(BatchID, 10),
            },
            data: {
              BatchStatus: {
                connect: {
                  RecId: parseInt(
                    archivedStatus?.findFirstSystemCodes?.RecId,
                    10
                  ),
                },
              },
            },
          },
          refetchQueries: [
            {
              query: SINGLE_BATCH_QUERY,
              variables: {
                where: {
                  BatchID: parseInt(BatchID, 10),
                },
              },
            },
          ],
        });
        const Change = "Status updated from Complete to Archived";
        await createBatchLogs({
          variables: {
            data: {
              Change,
              Batches: {
                connect: {
                  BatchID: parseInt(BatchID, 10),
                },
              },
              ReasonForChange: "Batch archived",
              CreatedBy: authState.user.Username,
            },
          },
        });
        setModalOpen(false, "");
      } catch (err) {
        toast.error("Error on trying to Archive Batch");
      }
    };
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to archive this Batch?"
        onCancel={() => setModalOpen(false, "")}
        onConfirm={performArchive}
      />
    );
  };

  const handleLabelClick = (selectedValue) => {
    setSelectedLabel(selectedValue);
    if (selectedValue === "QC Samples") {
      const filteredSamples = inputs.Tests.filter(
        (test) =>
          test.SessionID &&
          (test.SessionID?.startsWith("99_99_") ||
            /^[a-z]/i.test(test.SessionID))
      );

      if (filteredSamples?.length) {
        const win = window.open(`/lims/batch-labels/BatchQCSamples-${BatchID}`);
        win.focus();
      }
    } else if (selectedValue === "Unknown Samples") {
      const filteredSamples = inputs.Tests.filter(
        (test) =>
          test.SessionID &&
          !test.SessionID?.startsWith("99_99_") &&
          !/^[a-z]/i.test(test.SessionID)
      );
      if (filteredSamples?.length) {
        const win = window.open(
          `/lims/batch-labels/BatchUnknownSamples-${BatchID}`
        );
        win.focus();
      }
    } else if (selectedValue === "All Samples") {
      const win = window.open(`/lims/batch-labels/All-${BatchID}`);
      win.focus();
    }
  };

  const downloadSummaryPDF = (filename) => {
    const el = document.createElement("a");
    el.setAttribute("href", filename);
    el.setAttribute("download", ``);
    el.style.display = "none";
    document.body.appendChild(el);
    el.click();
    document.body.removeChild(el);
    setFileDownloading(false);
  };

  const buildBatchSummaryData = () => {
    const samples = batchResultsTemplate.map((column) => {
      const rawData = column.singleResultTemplate
        ?.filter(
          (row) => row.group === "raw" && !row.class.includes("black-out")
        )
        .map((row) => ({
          analyte: row.label,
          result: row.displayValue
            ? `${row.displayValue} ${row.units || ""}`
            : "",
        }));
      const calculatedData = column.singleResultTemplate
        ?.filter(
          (row) =>
            row.group === "calculated" && !row.class.includes("black-out")
        )
        .map((row) => ({
          analyte: row.label,
          result: row.displayValue,
        }));
      const totalsData = column.singleResultTemplate
        ?.filter(
          (row) => row.group === "total" && !row.class.includes("black-out")
        )
        .map((row) => ({
          analyte: row.label,
          result: row.displayValue,
        }));
      const jobOrderID = parseInt(column.sample?.JobOrder?.JobOrderID, 10);
      const sessionID = column.sample?.SessionID;
      return { sessionID, jobOrderID, rawData, calculatedData, totalsData };
    });
    return samples;
  };

  const handleGenerateBatchSummaryPDF = async (e) => {
    e.preventDefault();
    setFileDownloading(true);
    const data = buildBatchSummaryData();
    const { data: pdfResult } = await client.query({
      query: GENERATE_BATCH_SUMMARY_PDF_QUERY,
      variables: {
        where: {
          BatchID: parseInt(BatchID, 10),
        },
        data: {
          sampleResults: JSON.stringify(data),
        },
      },
    });
    downloadSummaryPDF(pdfResult.generateBatchSummaryPDF);
  };

  const handleAddQualifiersModal = (e) => {
    e.preventDefault();
    if (
      Array(batchData?.findUniqueBatches?.JobOrderTestSamples) &&
      batchData?.findUniqueBatches?.JobOrderTestSamples?.length
    ) {
      setModalOpen(
        true,
        <AddQualifiersModal
          allBatchesSamples
          BatchID={BatchID}
          JobOrderTestSamples={
            batchData?.findUniqueBatches?.JobOrderTestSamples
          }
          onComplete={() => setModalOpen(false)}
        />
      );
    } else {
      toast.error("Please add samples to batch before adding qualifiers");
    }
  };

  return (
    <React.Fragment>
      <PageHeader title={getTitle(batchData?.findUniqueBatches)}>
        {location.pathname.includes("batch-info") && (
          <React.Fragment>
            {!editing ? (
              <React.Fragment>
                {canUpdate && (
                  <Button
                    color="primary"
                    size="small"
                    onClick={(prev) => setEditing(true)}
                  >
                    <span>Edit Batch</span>
                  </Button>
                )}
                <Button
                  color="primary"
                  size="small"
                  type="button"
                  onClick={handleExportCSV}
                >
                  <span>.csv Download</span>
                </Button>
                {batchData?.findUniqueBatches?.BatchStatus?.CodeDescription ===
                  "Complete" && canUpdate ? (
                  <Button
                    color="default"
                    size="small"
                    onClick={handleArchiveBatch}
                  >
                    <span>Archive</span>
                  </Button>
                ) : null}
                <Dropdown>
                  <Dropdown.Trigger>
                    <Button color="primary" size="small">
                      <span>{selectedLabel || "Labels"}</span>
                      <Icon size="small">
                        <FontAwesomeIcon icon="angle-down" />
                      </Icon>
                    </Button>
                  </Dropdown.Trigger>
                  <Dropdown.Menu>
                    <Dropdown.Content>
                      <Dropdown.Item
                        active={selectedLabel === "All Samples"}
                        onClick={() => handleLabelClick("All Samples")}
                      >
                        All Samples
                      </Dropdown.Item>
                      <Dropdown.Item
                        active={selectedLabel === "QC Samples"}
                        onClick={() => handleLabelClick("QC Samples")}
                      >
                        QC Samples
                      </Dropdown.Item>
                      <Dropdown.Item
                        active={selectedLabel === "Unknown Samples"}
                        onClick={() => handleLabelClick("Unknown Samples")}
                      >
                        Unknown Samples
                      </Dropdown.Item>
                    </Dropdown.Content>
                  </Dropdown.Menu>
                </Dropdown>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Button
                  color="default"
                  disabled={!canUpdate}
                  size="small"
                  onClick={() => setEditing(false)}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  disabled={!canUpdate}
                  size="small"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {location.pathname.includes("results") && (
          <React.Fragment>
            {changesOnResults ? (
              <React.Fragment>
                <Button
                  color="default"
                  disabled={!canUpdate}
                  size="small"
                  onClick={() => {
                    setEditing(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  size="small"
                  onClick={handleSaveResults}
                >
                  Save
                </Button>
              </React.Fragment>
            ) : (
              <Field kind="group">
                {labState === "AZ" && (
                  <Control>
                    <Button
                      color="default"
                      disabled={fileDownloading}
                      size="small"
                      onClick={handleAddQualifiersModal}
                    >
                      <span>Add Qualifiers</span>
                    </Button>
                  </Control>
                )}
                <Control>
                  <Button
                    color="primary"
                    disabled={fileDownloading}
                    size="small"
                    onClick={handleGenerateBatchSummaryPDF}
                  >
                    <span>Download Summary PDF</span>
                  </Button>
                </Control>
              </Field>
            )}
          </React.Fragment>
        )}
        {isDetailsTabActive && (
          <React.Fragment>
            <Button
              color="default"
              disabled={!canUpdate}
              size="small"
              onClick={() => {
                setEditing(false);
                handleDetailsFormCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={!canUpdate}
              size="small"
              onClick={handleSave}
            >
              Save
            </Button>
          </React.Fragment>
        )}
      </PageHeader>
      <Tab.Group kind="boxed">
        <Tab
          active={location.pathname.includes("batch-info")}
          as={Link}
          to={`/lims/batch-records/${BatchID}/batch-info`}
        >
          Batch
        </Tab>
        <Tab
          active={location.pathname.includes("details")}
          as={Link}
          to={`/lims/batch-records/${BatchID}/details`}
        >
          Details
        </Tab>
        <Tab
          active={location.pathname.includes("results")}
          as={Link}
          to={`/lims/batch-records/${BatchID}/results`}
        >
          Results
        </Tab>
        <Tab
          active={location.pathname.includes("logs")}
          as={Link}
          to={`/lims/batch-records/${BatchID}/logs`}
        >
          Logs
        </Tab>
        <Tab
          active={location.pathname.includes("attachments")}
          as={Link}
          to={`/lims/batch-records/${BatchID}/attachments`}
        >
          Attachments
        </Tab>
      </Tab.Group>
      <Switch>
        <Switch path="/lims/batch-records/:BatchID/batch-info">
          <Batch
            BatchID={parseInt(BatchID, 10)}
            batchResults={results}
            editing={editing}
            handleAddQCRow={handleAddQCRow}
            handleAddRow={handleAddRow}
            handleDeleteTest={handleDeleteTest}
            handleInputsChange={handleInputsChange}
            handleMoveTestDown={handleMoveTestDown}
            handleMoveTestUp={handleMoveTestUp}
            handleQCTypeChange={handleQCTypeChange}
            handleSelectTest={handleSelectTest}
            handleSessionBlur={handleSessionBlur}
            handleSessionIdEnterKeyPress={handleSessionIdEnterKeyPress}
            handleTestsChange={handleTestsChange}
            inputs={inputs}
            labState={labState}
            qcSampleTypeID={qcSampleTypeID}
            references={references}
            selectedTestResult={[{ ...selectedTestResult }]}
          />
        </Switch>
        <Switch path="/lims/batch-records/:BatchID/logs">
          <Logs />
        </Switch>
        <Switch path="/lims/batch-records/:BatchID/results">
          <Results
            BatchID={parseInt(BatchID, 10)}
            batchResults={results}
            batchResultsTemplate={batchResultsTemplate}
            disabled={!canUpdate}
            labState={labState}
            showQualifiers={showQualifiers}
            tests={inputs.Tests}
            onChange={handleChangeResults}
            onChangeStatusOfAll={handleChangeStatusOfAll}
            onClickQualifiers={handleClickQualifiers}
          />
        </Switch>
        <Switch path="/lims/batch-records/:BatchID/details">
          <BatchDetailsForm
            editing
            disabled={!canUpdate}
            formId="batch-details-form"
            inputs={inputs}
            testCategory={inputs.TestCategory}
            onChange={handleInputsChange}
          />
        </Switch>
        <Switch path="/lims/batch-records/:BatchID/attachments">
          <Attachments BatchID={BatchID} disabled={!canUpdate} />
        </Switch>
      </Switch>
    </React.Fragment>
  );
};

EditBatchPage.propTypes = {
  routePermissions: PropTypes.array,
};

EditBatchPage.defaultProps = {
  routePermissions: [],
};

export default EditBatchPage;
