/* eslint-disable max-lines-per-function */
import React, { useEffect, useState, useMemo } from "react";
import { Button, Tab } from "rbx";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  useParams,
  Link,
  useLocation,
  Switch,
  useHistory,
} from "react-router-dom";
import PropTypes from "prop-types";

import { useModal } from "../../../context/ModalContext";
import { useAuth } from "../../../context";
import {
  SINGLE_USER_QUERY,
  UPDATE_USER_MUTATION,
  LIST_USERS_QUERY,
  UPDATE_USER_TEST_MUTATION,
  LIST_USER_TESTS_QUERY,
  UPDATE_PASSWORD_MUTATION,
  CREATE_MANY_USER_TESTS_MUTATION,
  CREATE_USER_PROCESSING_OPTIONS_MUTATION,
  DELETE_USER_PROCESSING_OPTION_MUTATION,
  UPDATE_PRIVATE_LABEL_USER_WITH_PASSWORD_HASHED_MUTATION,
  CREATE_PRIVATE_LABEL_USER_WITH_PASSWORD_HASHED_MUTATION,
  FIRST_SYSTEM_CODE_QUERY,
  REMOVE_FILE_MUTATION,
} from "../../../graphql";
import {
  AccountDetails,
  BillingDetails,
  COAInfo,
  TestsInfo,
  ClientNotes,
  PrivateLabel,
  TestSettings,
  PackageSettings,
  ReferrerInfo,
} from "../routes";
import {
  UpdatePasswordModal,
  Loader,
  PageHeader,
  Confirmation,
} from "../../../components";
import { customToast as toast } from "../../../utils";
import { usePermissions } from "../../../hooks";

const INITIAL_STATE = {
  UserID: "",
  Username: "",
  FirstName: "",
  LastName: "",
  Email: "",
  Status: "",
  Address: "",
  Address2: "",
  Phone: "",
  City: "",
  State: "",
  Zip: "",
  ProfilePic: "",
  LabID: "",
  isPrivateLabel: "",
  Terms: "",
  TimeZone: "",
  Company: "",
  ProcessingOption: "",
  UserProcessingOptions: [],
  ReferrerID: "",
  Commission: "",
  Referrer2ID: "",
  Commission2: "",
  LicenseNumber: "",
  TurnAroundDays: null,
  AccountType: null,
  ParentClientID: "",
  AllowDupeBatchOrderNumber: false,
  ShippingMultiplier: { Expedited: 1, Urgent: 2 },
  BillingAddress: {
    BillingAddress: "",
    BillingAddress2: "",
    BillingCity: "",
    BillingCompany: "",
    BillingFirstName: "",
    BillingLastName: "",
    BillingState: "",
    BillingZip: "",
  },
  ShippingAddress: {
    ShippingAddress: "",
    ShippingAddress2: "",
    ShippingCity: "",
    ShippingCompany: "",
    ShippingFirstName: "",
    ShippingLastName: "",
    ShippingState: "",
    ShippingZip: "",
  },
  COAAddress: {
    COAAddress: "",
    COAAddress2: "",
    COACity: "",
    COAEmail: "",
    COALogo: "",
    COAName: "",
    COAPhone: "",
    COAState: "",
    COAZip: "",
  },
  COASettings: {
    COA_SOP: "0",
    ShowSummary: "0",
  },
  Notes: {
    ClientNotes: "",
    TestNotes: "",
  },
  TestSettings: {
    CANN: "",
    TOX: "",
    MICRO: "",
    METAL: "",
    MOIST: "",
    WATER: "",
    PEST: "",
    SOLV: "",
    TERP: "",
    _HideGraphs: "",
  },
};

const INITIAL_PRIVATE_LABEL_STATE = {
  Username: "",
  Password: "",
  OwnerUserID: "",
  CreateDate: "",
  PrivateLabelUserID: "",
  RevShare: 1,
  Status: "",
  adding: true,
};

const ClientEditPage = ({ routePermissions }) => {
  const history = useHistory();
  const { UserID } = useParams();
  const location = useLocation();
  const { setModalOpen } = useModal();
  const permissions = usePermissions();

  const [canUpdate, setCanUpdate] = useState(true);
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [privateLabelInputs, setPrivateLabelInputs] = useState(
    INITIAL_PRIVATE_LABEL_STATE
  );
  const [loading, setLoading] = useState(false);
  const [userTests, setUserTests] = useState([]);
  const [originalProcessingOptions, setOriginalProcessingOptions] = useState(
    []
  );

  const { state: authState, handleLoginAsClient: loginAsClient } = useAuth();

  const [getClientData, resultClientData] = useLazyQuery(SINGLE_USER_QUERY);
  const { data: parentClientAccountType } = useQuery(FIRST_SYSTEM_CODE_QUERY, {
    variables: {
      where: {
        Type: { equals: "User" },
        Category: { equals: "User" },
        CodeName: { equals: "AccountType" },
        CodeId: { equals: "6" },
      },
    },
  });
  const [updateClient] = useMutation(UPDATE_USER_MUTATION);
  const [updatePassword] = useMutation(UPDATE_PASSWORD_MUTATION);
  const [updateUserTest] = useMutation(UPDATE_USER_TEST_MUTATION);
  const [createManyUserTests] = useMutation(CREATE_MANY_USER_TESTS_MUTATION);
  const [updatePrivateLabelUser] = useMutation(
    UPDATE_PRIVATE_LABEL_USER_WITH_PASSWORD_HASHED_MUTATION
  );
  const [createPrivateLabelUser] = useMutation(
    CREATE_PRIVATE_LABEL_USER_WITH_PASSWORD_HASHED_MUTATION
  );

  const [createProcessingOptions] = useMutation(
    CREATE_USER_PROCESSING_OPTIONS_MUTATION
  );
  const [deleteProcessingOption] = useMutation(
    DELETE_USER_PROCESSING_OPTION_MUTATION
  );
  const [removeLogo] = useMutation(REMOVE_FILE_MUTATION);

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  const uploadProfilePicPath = useMemo(() => {
    const date = new Date();
    return `/Images/Clients/${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${UserID}`;
  }, [UserID]);

  const uploadCOALogoPath = useMemo(() => {
    const date = new Date();
    return `/Images/Clients/COA/${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${UserID}`;
  }, [UserID]);

  const canLoginAsClient = useMemo(
    () => !!permissions.find((p) => p.Code === "CLIENT_LOGIN"),
    [permissions]
  );

  useEffect(() => {
    if (UserID) {
      getClientData({
        variables: {
          where: { UserID: parseInt(UserID, 10) },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [UserID, getClientData]);

  useEffect(() => {
    if (resultClientData?.data) {
      const {
        data: { findUniqueUsers },
      } = resultClientData;

      const cleanObject = Object.fromEntries(
        Object.entries(findUniqueUsers).filter(([_, v]) => v !== null)
      );

      if (cleanObject.UserProcessingOptions.length) {
        const options = cleanObject.UserProcessingOptions.map((item) => ({
          label: item.ProcessingOption.CodeDescription,
          value: item.SystemCodeID,
        }));

        cleanObject.UserProcessingOptions = options;
        setOriginalProcessingOptions(options);
      }

      setInputs((prev) => ({
        ...prev,
        ...cleanObject,
      }));

      if (findUniqueUsers?.PrivateLabelUsers?.length > 0) {
        setPrivateLabelInputs((prev) => {
          const obj = {
            ...prev,
            ...findUniqueUsers.PrivateLabelUsers[0],
            adding: false,
          };
          delete obj.Password;
          return obj;
        });
      }

      setLoading(false);
    }
  }, [resultClientData]);

  const updatePicture = async (data) => {
    try {
      setLoading(true);
      if (!data.COAAddress.COALogo) {
        const [, logoId] = inputs.COAAddress.COALogo.split("com/");
        await removeLogo({
          variables: {
            file: logoId,
          },
        });
      }
      await updateClient({
        variables: {
          where: { UserID: parseInt(UserID, 10) },
          data,
        },
        refetchQueries: [
          {
            query: SINGLE_USER_QUERY,
            variables: {
              where: { UserID: parseInt(UserID, 10) },
            },
            fetchPolicy: "network-only",
          },
        ],
      });
      toast.success("Profile Picture updated successfully");
    } catch (err) {
      toast.error("Error updating Profile Picture");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = async (name, value, entity = null) => {
    if (entity) {
      setInputs((prev) => ({
        ...prev,
        [entity]: {
          ...prev[entity],
          [name]: value,
        },
      }));
      if (name === "COALogo") {
        updatePicture({ COAAddress: { ...inputs.COAAddress, COALogo: value } });
      }
    } else if (name === "ProfilePic") {
      setInputs((prev) => ({
        ...prev,
        [name]: value,
      }));
      updatePicture({ ProfilePic: { set: value } });
    } else if (name === "AccountType") {
      setInputs((prev) => ({
        ...prev,
        [name]: value,
        ParentClientID: "",
      }));
    } else {
      setInputs((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleUpdatePrivateLabelPassword = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModalOpen(
      true,
      <UpdatePasswordModal
        onCancel={() => setModalOpen(false)}
        onConfirm={async (Password, ConfirmPassword) => {
          try {
            setModalOpen(false);
            setLoading(true);
            await updatePrivateLabelUser({
              variables: {
                where: {
                  PrivateLabelUserID: parseInt(
                    privateLabelInputs.PrivateLabelUserID,
                    10
                  ),
                },
                data: {
                  Password: { set: ConfirmPassword },
                },
              },
              refetchQueries: [
                {
                  query: SINGLE_USER_QUERY,
                  variables: {
                    where: { UserID: parseInt(UserID, 10) },
                  },
                  fetchPolicy: "network-only",
                },
              ],
            });
            toast.success("Private label password updated successfully");
          } catch (err) {
            toast.error("Error updating private label password");
          } finally {
            setLoading(false);
          }
        }}
      />
    );
  };

  const handleUpdatePassword = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModalOpen(
      true,
      <UpdatePasswordModal
        onCancel={() => setModalOpen(false)}
        onConfirm={async (Password, ConfirmPassword) => {
          try {
            setModalOpen(false);
            setLoading(true);
            await updatePassword({
              variables: {
                data: {
                  Password,
                  ConfirmPassword,
                },
                where: {
                  UserID: parseInt(UserID, 10),
                },
              },
              refetchQueries: [
                {
                  query: LIST_USERS_QUERY,
                  variables: {
                    where: {
                      Type: { is: { CodeId: { in: ["0"] } } },
                    },
                  },
                  fetchPolicy: "network-only",
                },
                {
                  query: SINGLE_USER_QUERY,
                  variables: {
                    where: { UserID: parseInt(UserID, 10) },
                  },
                  fetchPolicy: "network-only",
                },
              ],
            });
            toast.success("Password updated successfully");
          } catch (err) {
            toast.error("Error updating password");
          } finally {
            setLoading(false);
          }
        }}
      />
    );
  };

  const handleSave = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setLoading(true);
      const obj = Object.keys(INITIAL_STATE).reduce((acc, curr) => {
        if (
          inputs[curr] !== null &&
          curr !== "BillingAddress" &&
          curr !== "ShippingAddress" &&
          curr !== "COAAddress" &&
          curr !== "COASettings" &&
          curr !== "ShippingMultiplier" &&
          curr !== "TimeZone"
        ) {
          return {
            ...acc,
            [curr]: {
              set: inputs[curr],
            },
          };
        }
        return acc;
      }, {});
      if (obj.Terms?.set) {
        obj.Terms = { set: parseInt(obj.Terms.set, 10) };
      } else {
        delete obj.Terms;
      }
      if (obj.ProcessingOption?.set) {
        obj.ProcessingOption = { set: parseInt(obj.ProcessingOption.set, 10) };
      } else {
        delete obj.ProcessingOption;
      }
      if (obj.AccountType?.set) {
        obj.Type = { connect: { RecId: parseInt(obj.AccountType.set, 10) } };
        delete obj.AccountType;
      } else {
        delete obj.AccountType;
      }
      if (obj.ParentClientID?.set) {
        obj.ParentClient = {
          connect: { UserID: parseInt(obj.ParentClientID?.set, 10) },
        };
        delete obj.ParentClientID;
      } else {
        obj.ParentClient = { disconnect: true };
        delete obj.ParentClientID;
      }

      delete obj.UserProcessingOptions;
      delete obj.LabID;

      await handleCreateProcessingOptions(inputs.UserProcessingOptions);
      await handleDeleteProcessingOptions(inputs.UserProcessingOptions);

      await updateClient({
        variables: {
          data: {
            ...obj,
            BillingAddress: inputs.BillingAddress,
            ShippingAddress: inputs.ShippingAddress,
            COAAddress: inputs.COAAddress,
            COASettings: inputs.COASettings,
            Notes: inputs.Notes,
            TestSettings: inputs.TestSettings,
            ShippingMultiplier: inputs.ShippingMultiplier,
            Lab: {
              connect: {
                LabID: parseInt(inputs.LabID, 10),
              },
            },
          },
          where: {
            UserID: parseInt(UserID, 10),
          },
        },
        refetchQueries: [
          {
            query: LIST_USERS_QUERY,
            variables: {
              where: {
                Type: { is: { CodeId: { in: ["4"] } } },
              },
              orderBy: { Username: "asc" },
            },
            fetchPolicy: "network-only",
          },
          {
            query: SINGLE_USER_QUERY,
            variables: {
              where: { UserID: parseInt(UserID, 10) },
            },
            fetchPolicy: "network-only",
          },
        ],
      });
      toast.success("Updated successfully");
    } catch (err) {
      toast.error("Error updating Client");
    } finally {
      setLoading(false);
    }
  };

  const handleCreateProcessingOptions = async (newValues) => {
    newValues.map(async (item) => {
      if (!originalProcessingOptions.find((v) => v.value === item.value)) {
        await createProcessingOptions({
          variables: {
            data: {
              CreatedBy: authState.user.Username,
              CreatedDateTime: new Date(),
              User: {
                connect: {
                  UserID: parseInt(UserID, 10),
                },
              },
              ProcessingOption: {
                connect: {
                  RecId: parseInt(item.value, 10),
                },
              },
            },
          },
        });
      }
    });
  };

  const handleDeleteProcessingOptions = async (newValues) => {
    originalProcessingOptions.map(async (item) => {
      if (!newValues.find((v) => v.value === item.value)) {
        await deleteProcessingOption({
          variables: {
            where: {
              UserProcessingOptions_UserID_SystemCodeID_uindex: {
                UserID: parseInt(UserID, 10),
                SystemCodeID: parseInt(item.value, 10),
              },
            },
          },
        });
      }
    });
  };

  const handleSaveTestsInfo = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const refetchQueries = [
        {
          query: LIST_USER_TESTS_QUERY,
          variables: {
            where: { UserID: { equals: parseInt(UserID, 10) } },
            orderBy: [{ ShowOrder: "asc" }],
          },
        },
        {
          query: SINGLE_USER_QUERY,
          variables: {
            where: { UserID: parseInt(UserID, 10) },
          },
          fetchPolicy: "network-only",
        },
      ];

      const informationToUpdate = userTests.filter((test) => test.edited);

      const userTestsToCreate = [];
      userTests.forEach((test) => {
        if (test.newID && test.Active) {
          const newObj = {
            TestID: parseInt(test.Test.TestID, 10),
            UserPrice: test.UserPrice,
            Active: 1,
            ShowOrder: test.ShowOrder,
            UserID: parseInt(UserID, 10),
          };
          userTestsToCreate.push(newObj);
        }
      });

      await Promise.all(
        informationToUpdate.map((test) =>
          updateUserTest({
            variables: {
              where: { UserTestID: test.UserTestID },
              data: {
                Active: { set: test.Active },
                UserPrice: { set: test.UserPrice },
                ShowOrder: { set: test.ShowOrder },
              },
            },
            refetchQueries,
          })
        )
      );

      await createManyUserTests({
        variables: {
          data: userTestsToCreate,
        },
        refetchQueries,
      });

      toast.success("Updated successfully");
    } catch (err) {
      toast.error("Error updating Client");
    } finally {
      setLoading(false);
    }
  };

  const handleSaveReferrerInfo = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await updateClient({
        variables: {
          data: {
            ReferrerID: { set: parseInt(inputs.ReferrerID, 10) },
            Referrer2ID: { set: parseInt(inputs.Referrer2ID, 10) },
            Commission: { set: parseInt(inputs.Commission, 10) },
            Commission2: { set: parseInt(inputs.Commission2, 10) },
          },
          where: {
            UserID: parseInt(UserID, 10),
          },
        },
      });
      toast.success("Updated successfully");
    } catch (err) {
      toast.error("Error updating Client");
    } finally {
      setLoading(false);
    }
  };

  const copyBillingAddressFromAccounting = (e) => {
    e.preventDefault();
    setInputs((prev) => ({
      ...prev,
      BillingAddress: {
        BillingFirstName: inputs.FirstName,
        BillingLastName: inputs.LastName,
        BillingCompany: inputs.Company,
        BillingAddress: inputs.Address,
        BillingAddress2: inputs.Address2,
        BillingCity: inputs.City,
        BillingState: inputs.State,
        BillingZip: inputs.Zip,
      },
    }));
  };

  const copyShippingAddressFromAccounting = (e) => {
    e.preventDefault();
    setInputs((prev) => ({
      ...prev,
      ShippingAddress: {
        ShippingFirstName: inputs.FirstName,
        ShippingLastName: inputs.LastName,
        ShippingCompany: inputs.Company,
        ShippingAddress: inputs.Address,
        ShippingAddress2: inputs.Address2,
        ShippingCity: inputs.City,
        ShippingState: inputs.State,
        ShippingZip: inputs.Zip,
      },
    }));
  };

  const copyCOAAddressFromAccounting = (e) => {
    e.preventDefault();
    setInputs((prev) => ({
      ...prev,
      COAAddress: {
        COAName: `${inputs.FirstName} ${inputs.LastName}`,
        COAAddress: inputs.Address,
        COAAddress2: inputs.Address2,
        COAPhone: inputs.Phone,
        COACity: inputs.City,
        COAState: inputs.State,
        COAZip: inputs.Zip,
      },
    }));
  };

  const handlePrivateLabelSave = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (!privateLabelInputs.adding) {
        await updatePrivateLabelUser({
          variables: {
            where: {
              PrivateLabelUserID: parseInt(
                privateLabelInputs.PrivateLabelUserID,
                10
              ),
            },
            data: {
              Username: { set: privateLabelInputs.Username },
              RevShare: { set: parseInt(privateLabelInputs.RevShare, 10) },
              Status: { set: privateLabelInputs.Status },
            },
          },
          refetchQueries: [
            {
              query: SINGLE_USER_QUERY,
              variables: {
                where: { UserID: parseInt(UserID, 10) },
              },
              fetchPolicy: "network-only",
            },
          ],
        });
      } else {
        await createPrivateLabelUser({
          variables: {
            data: {
              Username: privateLabelInputs.Username,
              Password: privateLabelInputs.Password,
              RevShare: parseInt(privateLabelInputs.RevShare, 10),
              Status: privateLabelInputs.Status,
              CreateDate: new Date(),
              User: {
                connect: {
                  UserID: parseInt(UserID, 10),
                },
              },
            },
          },
          refetchQueries: [
            {
              query: SINGLE_USER_QUERY,
              variables: {
                where: { UserID: parseInt(UserID, 10) },
              },
              fetchPolicy: "network-only",
            },
          ],
        });
      }
      toast.success("Updated successfully");
    } catch (err) {
      toast.error("Error updating Client");
    } finally {
      setLoading(false);
    }
  };

  const handlePrivateLabelChange = (name, value) => {
    setPrivateLabelInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push("/clients");
  };

  const handleLoginAsClient = async (e) => {
    e?.preventDefault();
    const performLoginAsClient = async () => {
      const {
        data: { findUniqueUsers },
      } = resultClientData;
      await loginAsClient(findUniqueUsers?.Username);
    };
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to login as this client?"
        onCancel={() => setModalOpen(false, "")}
        onConfirm={performLoginAsClient}
      />
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="client-page">
      <PageHeader title={`${inputs.FirstName} ${inputs.LastName}`}>
        {canUpdate && (
          <React.Fragment>
            <Button size="small" onClick={handleCancel}>
              Cancel
            </Button>
            {canLoginAsClient && (
              <Button
                color="primary"
                size="small"
                onClick={handleLoginAsClient}
              >
                Login with this account
              </Button>
            )}
            <Button color="primary" size="small" onClick={handleUpdatePassword}>
              Update Password
            </Button>
            <Button
              color="primary"
              form="edit-client-form"
              size="small"
              type="submit"
            >
              Save
            </Button>
          </React.Fragment>
        )}
      </PageHeader>
      <Tab.Group kind="boxed">
        <Tab
          active={location.pathname.includes("account-details")}
          as={Link}
          to={`/clients/${UserID}/account-details`}
        >
          Account Details
        </Tab>
        <Tab
          active={location.pathname.includes("billing-details")}
          as={Link}
          to={`/clients/${UserID}/billing-details`}
        >
          Billing Details
        </Tab>
        <Tab
          active={location.pathname.includes("coa-info")}
          as={Link}
          to={`/clients/${UserID}/coa-info`}
        >
          COA Info
        </Tab>
        <Tab
          active={location.pathname.includes("tests-info")}
          as={Link}
          to={`/clients/${UserID}/tests-info`}
        >
          Tests Info
        </Tab>
        <Tab
          active={location.pathname.includes("client-notes")}
          as={Link}
          to={`/clients/${UserID}/client-notes`}
        >
          Client Notes
        </Tab>
        <Tab
          active={location.pathname.includes("private-label")}
          as={Link}
          to={`/clients/${UserID}/private-label`}
        >
          Private Label
        </Tab>
        <Tab
          active={location.pathname.includes("test-settings")}
          as={Link}
          to={`/clients/${UserID}/test-settings`}
        >
          Test Settings
        </Tab>
        <Tab
          active={location.pathname.includes("package-settings")}
          as={Link}
          to={`/clients/${UserID}/package-settings`}
        >
          Package Settings
        </Tab>
      </Tab.Group>
      <Switch>
        <Switch path="/clients/:UserID/account-details">
          <AccountDetails
            disabled={!canUpdate}
            handleChange={handleChange}
            handleSave={handleSave}
            inputs={inputs}
            parentClientAccountType={
              parentClientAccountType?.findFirstSystemCodes
            }
            uploadProfilePicPath={uploadProfilePicPath}
            userID={UserID}
          />
        </Switch>
        <Switch path="/clients/:UserID/billing-details">
          <BillingDetails
            copyBillingInfo={copyBillingAddressFromAccounting}
            copyShippingInfo={copyShippingAddressFromAccounting}
            disabled={!canUpdate}
            handleSave={handleSave}
            inputs={inputs}
            onChange={handleChange}
          />
        </Switch>
        <Switch path="/clients/:UserID/coa-info">
          <COAInfo
            copyCOAInfoAddress={copyCOAAddressFromAccounting}
            disabled={!canUpdate}
            handleSave={handleSave}
            inputs={inputs}
            uploadCOALogoPath={uploadCOALogoPath}
            onChange={handleChange}
          />
        </Switch>
        <Switch path="/clients/:UserID/tests-info">
          <TestsInfo
            disabled={!canUpdate}
            handleSave={handleSaveTestsInfo}
            inputs={inputs}
            setUserTests={setUserTests}
            userID={UserID}
            userTests={userTests}
            onChange={handleChange}
          />
        </Switch>
        <Switch path="/clients/:UserID/referrer-info">
          <ReferrerInfo
            disabled={!canUpdate}
            handleSave={handleSaveReferrerInfo}
            inputs={inputs}
            onChange={handleChange}
          />
        </Switch>
        <Switch path="/clients/:UserID/client-notes">
          <ClientNotes
            disabled={!canUpdate}
            handleSave={handleSave}
            inputs={inputs}
            onChange={handleChange}
          />
        </Switch>
        <Switch path="/clients/:UserID/private-label">
          <PrivateLabel
            disabled={!canUpdate}
            handleSave={handlePrivateLabelSave}
            handleUpdatePrivateLabelPassword={handleUpdatePrivateLabelPassword}
            inputs={privateLabelInputs}
            onChange={handlePrivateLabelChange}
          />
        </Switch>
        <Switch path="/clients/:UserID/test-settings">
          <TestSettings
            disabled={!canUpdate}
            handleSave={handleSave}
            inputs={inputs}
            onChange={handleChange}
          />
        </Switch>
        <Switch path="/clients/:UserID/package-settings">
          <PackageSettings
            disabled={!canUpdate}
            setLoading={setLoading}
            userID={UserID}
          />
        </Switch>
      </Switch>
    </div>
  );
};

ClientEditPage.propTypes = {
  routePermissions: PropTypes.array,
};

ClientEditPage.defaultProps = {
  routePermissions: [],
};

export default ClientEditPage;
