import React from "react";
import PropTypes from "prop-types";
import { Button } from "rbx";
import "./PackageBoard.scss";

const PackageBoard = ({ packages, onPackageChange, selectedPackage }) => (
  <div className="pkg-container">
    {packages.map((pkg, index) => (
      <div key={pkg.PackageID} className="column">
        <div
          className={`header ${
            selectedPackage?.PackageID === pkg.PackageID ? "is-selected" : ""
          }`}
        >
          <h4 className="title">{pkg.Name}</h4>
          <h5 className="money" style={{ fontSize: 13 }}>
            Retail Cost: ${pkg.mainSum}
            <br />
            Package Cost: ${pkg.Price}
            <br />
            Savings: {pkg.savings}
          </h5>
          <Button
            color={
              selectedPackage?.PackageID === pkg.PackageID ? "primary" : ""
            }
            type="button"
            onClick={() => onPackageChange(pkg)}
          >
            {selectedPackage?.PackageID === pkg.PackageID
              ? "Selected"
              : "Order"}
          </Button>
        </div>
        <div
          className={`col-container ${
            selectedPackage?.PackageID === pkg.PackageID ? "is-selected" : ""
          }`}
        >
          {pkg.PackageTests.map((test) => (
            <div key={test.PackageTestID} className="test-info-item">
              <p>{test?.Test?.Name}</p>
              <span />
              <p className="money">${parseFloat(test.Price, 10)}</p>
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
);
PackageBoard.propTypes = {
  packages: PropTypes.array.isRequired,
  onPackageChange: PropTypes.func.isRequired,
  selectedPackage: PropTypes.object,
};
PackageBoard.defaultProps = {
  selectedPackage: {},
};

export default PackageBoard;
