const statusColors = {
  Status: {
    Completed: "cyan",
    "Sample Not Received": "error",
    "New Order": "pink",
    "In Progress": "purple",
    "In Review": "warning",
    Legacy: "grey",
    Cancelled: "grey",
  },
  Payment: {
    Partial: "warning",
    Pending: "error",
    Paid: "cyan",
    Cancelled: "grey",
  },
  Shipping: {
    Urgent: "error",
    Expedited: "warning",
    Standard: "cyan",
  },
  ResultStatus: {
    Rejected: "error",
    Imported: "purple",
    Approved: "cyan",
    Restored: "warning",
    New: "pink",
  },
  BatchStatus: {
    N: "pink",
    IP: "purple",
    P: "warning",
    C: "cyan",
    A: "grey",
  },
};

export default statusColors;
