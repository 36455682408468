// eslint-disable-next-line import/prefer-default-export
export const COLUMNS = [
  {
    Header: "Name",
    id: "Name",
    accessor: "Name",
  },
  {
    Header: "QC Sample Type",
    id: "QCSampleType",
    accessor: "QCSampleType.Name",
  },
  {
    Header: "Test Category",
    id: "TestCategory",
    accessor: "TestCategory.Name",
  },
  {
    Header: "States",
    id: "States",
    accessor: "States",
  },
];
