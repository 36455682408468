import { gql } from "@apollo/client";

export const DELETE_EQUIPMENT_LOG_MUTATION = gql`
  mutation DELETE_EQUIPMENT_LOG_MUTATION($where: EquipmentLogsWhereUniqueInput!) {
    deleteEquipmentLogs(where: $where) {
      EquipmentLogID
    }
  }
`;

export const CREATE_EQUIPMENT_LOG_MUTATION = gql`
  mutation CREATE_EQUIPMENT_LOG_MUTATION($data: EquipmentLogsCreateInput!) {
    createEquipmentLogs(data: $data) {
      EquipmentLogID
    }
  }
`;

export const UPDATE_EQUIPMENT_LOG_MUTATION = gql`
  mutation UPDATE_EQUIPMENT_LOG_MUTATION(
    $data:  EquipmentLogsUpdateInput!
    $where: EquipmentLogsWhereUniqueInput!
  ) {
    updateEquipmentLogs(data: $data, where: $where) {
      EquipmentLogID
    }
  }
`;