import { gql } from "@apollo/client";

export const CREATE_MANY_USER_PROCESSING_OPTIONS_MUTATION = gql`
  mutation CREATE_MANY_USER_PROCESSING_OPTIONS_MUTATION(
    $data: [UserProcessingOptionsCreateManyInput!]!
  ) {
    createManyUserProcessingOptions(data: $data) {
      count
    }
  }
`;

export const CREATE_USER_PROCESSING_OPTIONS_MUTATION = gql`
  mutation CREATE_USER_PROCESSING_OPTIONS_MUTATION(
    $data: UserProcessingOptionsCreateInput!
  ) {
    createUserProcessingOptions(data: $data) {
      UserProcessingOptionID
    }
  }
`;

export const DELETE_USER_PROCESSING_OPTION_MUTATION = gql`
  mutation DELETE_USER_PROCESSING_OPTION_MUTATION(
    $where: UserProcessingOptionsWhereUniqueInput!
  ) {
    deleteUserProcessingOptions(where: $where) {
      UserID
      SystemCodeID
    }
  }
`;
