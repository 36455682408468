import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Control } from "rbx";
import ReactSelectAsync from "react-select/async";
import { orderBy } from "lodash";
import { STATES } from "../../utils";

const StateMultiSelect = ({
  value,
  name,
  size,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
  placeHolder,
  maxSelected,
}) => {
  const states = orderBy(
    Object.keys(STATES).map((key) => ({
      label: key,
      value: key,
    })),
    [(obj) => obj.label],
    ["asc"]
  );

  const loadingOptions = useCallback(
    async (inputValue = "") =>
      new Promise((resolve, reject) => {
        try {
          if (states && Array.isArray(states) && states.length > 0) {
            const arrayFiltered = states.filter((i) =>
              i.label.toLowerCase().includes(inputValue.toLowerCase())
            );
            resolve(arrayFiltered);
          }
        } catch (error) {
          reject(error);
        }
      }),
    [states]
  );

  return (
    <Control>
      <ReactSelectAsync
        cacheOptions
        isMulti
        className="async-select"
        defaultOptions={states}
        isDisabled={disabled}
        loadOptions={loadingOptions}
        name={name}
        noOptionsMessage={
          ({ inputValue }) => (inputValue.length ? "No options" : placeHolder)
          // eslint-disable-next-line react/jsx-curly-newline
        }
        required={required}
        styles={{
          indicatorsContainer: (provided) => ({
            ...provided,
            height: "inherit",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          container: (provided) => ({
            fontSize: 13,
          }),
          singleValue: (provided) => ({
            ...provided,
            top: "38%",
          }),
          control: (provided) => ({
            ...provided,
            width: "100%",
            minWidth: 180,
            minHeight: "2.20em",
            textTransform: "capitalize",
            height:
              Array.isArray(getValue(value)) && value.length > 0
                ? "auto"
                : "2.20em",
            border:
              required && Array.isArray(getValue(value)) && value.length === 0
                ? "1px #e63362 solid"
                : "",
            borderRadius: "4px",
            boxShadow:
              required && Array.isArray(getValue(value)) && value.length === 0
                ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
                : "",
            "&:hover": {
              borderColor: "hsl(0, 0%, 90%)",
              borderRadius: 4,
              cursor: disabled ? "not-allowed" : "pointer",
            },
          }),
          placeholder: (provided) => ({ ...provided, top: "38%" }),
          menu: (provided) => ({
            ...provided,
            zIndex: 999,
            textTransform: "capitalize",
          }),
        }}
        value={
          Array.isArray(states) &&
          Array.isArray(value) &&
          states.filter((x) => getValue(value).find((i) => i === x.value))
        }
        onChange={(e) => {
          if (e.length <= maxSelected) {
            onChange(
              name,
              e.map((user) => user.value)
            );
          } else if (!maxSelected) {
            onChange(
              name,
              e.map((user) => user.value)
            );
          }
        }}
      />
    </Control>
  );
};

StateMultiSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  name: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  required: PropTypes.bool,
  placeHolder: PropTypes.string,
  maxSelected: PropTypes.number,
};

StateMultiSelect.defaultProps = {
  value: "",
  name: "",
  placeHolder: "Start typing...",
  size: "small",
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  required: false,
  maxSelected: null,
};

export default StateMultiSelect;
