import React, { useState } from "react";
import PropTypes from "prop-types";
import { Title, Button, Column, Control, Label, Input } from "rbx";

const ResultsEditModal = ({ onComplete, onCancel }) => {
  const [reasonForChange, setReasonForChange] = useState("");
  return (
    <div>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Reason For Change</Title>
        </div>
        <div className="page-head-end">
          <Button.Group>
            <Button
              color="primary"
              size="small"
              type="button"
              onClick={() => onCancel()}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={!reasonForChange.length}
              form="add-referrer-form"
              size="small"
              type="submit"
              onClick={() => onComplete(reasonForChange)}
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <br />
      <Column.Group multiline>
        <Column desktop={{ size: 12 }} tablet={{ size: 12 }}>
          <Control expanded size="small">
            <Label>Reason For Change</Label>
            <Input
              autoFocus
              required
              maxLength={250}
              name="ReasonForChange"
              placeholder="Reason For Change"
              size="small"
              value={reasonForChange}
              onChange={(e) => setReasonForChange(e.target.value)}
            />
          </Control>
        </Column>
      </Column.Group>
    </div>
  );
};

ResultsEditModal.propTypes = {
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
};

ResultsEditModal.defaultProps = {
  onComplete: (e) => e,
  onCancel: (e) => e,
};

export default ResultsEditModal;
