import { gql } from "@apollo/client";

export const LIST_PACKAGES_QUERY = gql`
  query LIST_PACKAGES_QUERY(
    $where: PackagesWhereInput
    $orderBy: [PackagesOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyPackages(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      PackageID
      UserID
      Name
      CreatedBy
      ModifiedBy
      CreatedDateTime
      ModifiedDateTime
      DisplayAsTest
    }
    aggregatePackages(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const ALL_PACKAGES_QUERY = gql`
  query ALL_PACKAGES_QUERY(
    $where: PackagesWhereInput
    $orderBy: [PackagesOrderByInput!]
  ) {
    findManyPackages(where: $where, orderBy: $orderBy) {
      PackageID
      UserID
      Name
      CreatedBy
      ModifiedBy
      CreatedDateTime
      ModifiedDateTime
      Price
      MedicalType
      DisplayAsTest
      State
      Type {
        RecId
        CodeDescription
      }
      PackageTests {
        PackageTestID
        PackageID
        TestID
        Price
        Order
        CreatedBy
        ModifiedBy
        CreatedDateTime
        ModifiedDateTime
        Test {
          TestID
          Name
          Code
          Description
          Image
        }
      }
    }
  }
`;

export const SINGLE_PACKAGE_QUERY = gql`
  query SINGLE_PACKAGE_QUERY($where: PackagesWhereUniqueInput!) {
    findUniquePackages(where: $where) {
      PackageID
      UserID
      Name
      CreatedBy
      ModifiedBy
      CreatedDateTime
      ModifiedDateTime
      DisplayAsTest
      PackageTests {
        PackageTestID
        PackageID
        TestID
        Price
        CreatedBy
        ModifiedBy
        CreatedDateTime
        ModifiedDateTime
        Test {
          TestID
          Name
          Code
          Description
          Image
        }
      }
    }
  }
`;
