/* eslint-disable react/prop-types */
/* eslint-disable max-lines-per-function */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import {
  Column,
  Field,
  Title,
  Box,
  Input,
  Control,
  Label,
  Button,
  Textarea,
} from "rbx";
import Collapse, { Panel } from "rc-collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import SystemCodeSelectWithMemory from "../../../Intake/components/SystemCodeSelectWithMemory";
import InputWithMemory from "../../../Intake/components/InputWithMemory";
import RadioWithMemory from "../../../Intake/components/RadioWithMemory";
import { TestSelect, BooleanInput } from "../../../../../../components";
import MMTCInfoSelectWithMemory from "../../../Intake/components/MMTCInfoSelectWithMemory";
import CultivatorsMultiSelectWithMemory from "../../../Intake/components/CultivatorsMultiSelectWithMemory";
import InputWithSelectAndMemory from "../../../Intake/components/UnitSelectWithMemory";
import SamplesList from "../SamplesList";
import HomogeneityResultsSection from "../HomogeneityResultsSection";
import LabelClaimForm from "../LabelClaimForm";

import "./OrderForm.scss";
import {
  FIND_FIRST_SNAPSHOT_QUERY,
  EXPORT_DATA_QUERY,
} from "../../../../../../graphql";
import { download } from "../../../../../../utils";

const { REACT_APP_COA_URL } = process.env;

class CustomSnapshotError extends Error {}

const OrderForm = ({
  inputs,
  onChange,
  disabled,
  updatePerUnitCalculation,
  onAddTestToJobOrder,
  onOpenResultModal,
  user,
  JobData,
  allTestApproved,
  canExportCsv,
}) => {
  const [selectedTest, setSelectedTest] = useState("0");
  const [exportLoading, setExportLoading] = useState(false);

  const handleTestChange = (value) => {
    setSelectedTest(value);
  };

  const skullIcon = <FontAwesomeIcon icon="skull" />;

  const client = useApolloClient();
  const [loading, setLoading] = useState(false);

  const handleClick = async (pdf = false, onepager = false) => {
    try {
      setLoading(true);
      const { data: FindFirstSnapshotData } = await client.query({
        query: FIND_FIRST_SNAPSHOT_QUERY,
        variables: {
          where: {
            JobOrderID: { equals: parseInt(inputs.JobOrderID, 10) },
            PrivateLabelCOAID: { equals: null },
          },
          orderBy: [{ Version: "desc" }],
        },
        fetchPolicy: "network-only",
      });
      const snapshot = FindFirstSnapshotData?.findFirstSnapshots;
      if (!snapshot) {
        throw new Error();
      }

      window.open(
        `${REACT_APP_COA_URL}/${
          onepager ? user?.COASettings?.COA_SOP || "coa" : "coa"
        }/${pdf ? "pdf" : "html"}/${inputs.JobOrderID}/${snapshot.Version}`,
        "_blank"
      );
    } catch (err) {
      toast.error(
        "Whoops! We encountered an error opening this COA. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleExport = async () => {
    try {
      setExportLoading(true);
      const { data: ExportData } = await client.query({
        query: EXPORT_DATA_QUERY,
        variables: {
          jobOrderIds: [parseInt(inputs.JobOrderID, 10)],
        },
        fetchPolicy: "no-cache",
      });

      if (ExportData) {
        download(`${inputs.JobOrderID}.csv`, "csv", ExportData.exportData);
      }
    } catch (err) {
      const message =
        err instanceof CustomSnapshotError
          ? err.message
          : "Whoops! We encountered an error performing this action. Please contact support.";

      toast.error(message);
    } finally {
      setExportLoading(false);
    }
  };
  return (
    <Column.Group multiline>
      <Column className="accordion-column" size={12}>
        <Collapse accordion>
          <Panel
            header={
              <Control>
                <Title size={5}>
                  Order Info{" "}
                  {inputs?.OverrideData?.RetailBatchRetest && skullIcon}
                </Title>
              </Control>
            }
          >
            <div className="panel-columns-wrapper">
              <Column desktop={{ size: 6 }} mobile={{ size: 12 }}>
                <Field kind="group">
                  <InputWithMemory
                    disabled={disabled}
                    inputs={inputs}
                    label="Order Name"
                    name="OrderName"
                    placeholder="Order Name"
                    onChange={onChange}
                  />
                  <Control expanded>
                    <Label>Order Created</Label>
                    <Input
                      disabled
                      name="OrderCreateDate"
                      placeholder="Order Created"
                      size="small"
                      type="text"
                      value={format(
                        new Date(inputs.OrderCreateDate),
                        "MM/dd/yyyy hh:mm a"
                      )}
                      onChange={({ target: { name, value } }) =>
                        onChange(name, value, true)
                      }
                    />
                  </Control>
                </Field>
                <Field kind="group">
                  <InputWithMemory
                    disabled={disabled}
                    inputs={inputs}
                    label="Batch Number"
                    name="BatchOrderNumber"
                    placeholder="Order Number"
                    onChange={onChange}
                  />
                  <SystemCodeSelectWithMemory
                    expanded
                    disabled={disabled}
                    inputs={inputs}
                    label="Test Type"
                    name="TestType"
                    where={{
                      Type: { equals: "Test" },
                      Category: { equals: "Type" },
                      CodeName: { equals: "TestType" },
                    }}
                    onChange={onChange}
                  />
                  <SystemCodeSelectWithMemory
                    expanded
                    disabled={disabled}
                    inputs={inputs}
                    label="Serving Size"
                    name="Serving Size"
                    where={{
                      Type: { equals: "Serving" },
                      Category: { equals: "Size" },
                      CodeName: { equals: "ServingSize" },
                    }}
                    onChange={onChange}
                  />
                </Field>
              </Column>
              <Column desktop={{ size: 6 }} mobile={{ size: 12 }}>
                <Field kind="group">
                  <SystemCodeSelectWithMemory
                    expanded
                    disabled={disabled || JobData?.Lab?.State !== "FL"}
                    inputs={inputs}
                    label="Substance"
                    name="SubstanceType"
                    placeholder="Substance"
                    where={{
                      Type: { equals: "Substance" },
                      Category: { equals: "Type" },
                      CodeName: { equals: "SubstanceType" },
                    }}
                    onChange={onChange}
                  />
                  <SystemCodeSelectWithMemory
                    expanded
                    disabled={disabled}
                    inputs={inputs}
                    label="Product Category"
                    name="ProductCategory"
                    where={{
                      Type: { equals: "Product" },
                      Category: { equals: "Category" },
                      CodeName: { equals: "ProductCategory" },
                    }}
                    onChange={onChange}
                  />
                </Field>
                <Field kind="group">
                  <InputWithMemory
                    disabled={disabled}
                    inputs={inputs}
                    label="Harvest/Lot ID"
                    name="CustomerSampleID"
                    placeholder="Harvest/Lot ID"
                    onChange={onChange}
                  />
                  <InputWithMemory
                    disabled={disabled}
                    inputs={inputs}
                    label="Seed-to-Sale #"
                    name="LotID"
                    placeholder="Seed-to-Sale #"
                    onChange={onChange}
                  />
                  <Control>
                    <Label>Hide Per Package on COA</Label>
                    <BooleanInput
                      disabled={disabled}
                      name="HidePerPackage"
                      value={inputs.HidePerPackage}
                      onChange={(name, value) => onChange(name, value, true)}
                    />
                  </Control>
                </Field>
              </Column>
            </div>
            <Column size={12}>
              <Field kind="group">
                <Control expanded>
                  <Label>Client Notes</Label>
                  <Textarea
                    disabled
                    name="ClientNotes"
                    placeholder="Client Notes"
                    size="small"
                    value={inputs.ClientNotes || ""}
                    onChange={({ target: { name, value } }) =>
                      onChange(name, value, true)
                    }
                  />
                </Control>
              </Field>
            </Column>
          </Panel>
        </Collapse>
        <Collapse accordion>
          <Panel
            header={
              <Control>
                <Title size={5}>Client Info</Title>
              </Control>
            }
          >
            <Column.Group multiline>
              <Column
                className="mmtc-column-1"
                desktop={{ size: 12 }}
                mobile={{ size: 12 }}
              >
                <Field kind="group">
                  <MMTCInfoSelectWithMemory
                    expanded
                    disabled={disabled}
                    inputs={inputs}
                    label="Cultivation Facility"
                    name="CultivationFacility"
                    user={user}
                    onChange={onChange}
                  />
                  <MMTCInfoSelectWithMemory
                    expanded
                    disabled={disabled}
                    inputs={inputs}
                    label="Processing Facility"
                    name="ProcessingFacility"
                    user={user}
                    onChange={onChange}
                  />
                  <CultivatorsMultiSelectWithMemory
                    expanded
                    disabled={disabled}
                    inputs={inputs}
                    label="Cultivars"
                    name="Cultivators"
                    user={user}
                    onChange={onChange}
                  />
                </Field>
              </Column>
            </Column.Group>
          </Panel>
        </Collapse>
        <Collapse accordion>
          <Panel
            header={
              <Control>
                <Title size={5}>Label Claim</Title>
              </Control>
            }
          >
            <Column.Group multiline>
              <Column desktop={{ size: 12 }} mobile={{ size: 12 }}>
                <Field kind="group">
                  <InputWithMemory
                    expanded
                    disabled={disabled}
                    inputs={inputs}
                    label="Stated Label Claim THC"
                    name="LabelClaimTHC"
                    placeholder="Stated Label Claim THC"
                    onChange={onChange}
                  />
                  <SystemCodeSelectWithMemory
                    expanded
                    disabled={disabled}
                    inputs={inputs}
                    name="LabelClaimUnitTHC"
                    where={{
                      Type: { equals: "Product" },
                      Category: { equals: "Unit" },
                      CodeName: { equals: "ProductUnit" },
                    }}
                    onChange={onChange}
                  />
                  <InputWithMemory
                    expanded
                    disabled={disabled}
                    inputs={inputs}
                    label="Stated Label Claim CBD"
                    name="LabelClaimCBD"
                    placeholder="Stated Label Claim CBD"
                    onChange={onChange}
                  />
                  <SystemCodeSelectWithMemory
                    expanded
                    disabled={disabled}
                    inputs={inputs}
                    name="LabelClaimUnitCBD"
                    where={{
                      Type: { equals: "Product" },
                      Category: { equals: "Unit" },
                      CodeName: { equals: "ProductUnit" },
                    }}
                    onChange={onChange}
                  />
                </Field>
              </Column>
              <LabelClaimForm
                inputs={inputs}
                onChange={(name, value) => onChange(name, value, true)}
              />
            </Column.Group>
          </Panel>
        </Collapse>
        <Collapse accordion>
          <Panel
            header={
              <Control>
                <Title size={5}>Batch Information</Title>
              </Control>
            }
          >
            <Column>
              <Field kind="group">
                <InputWithMemory
                  expanded
                  disabled={disabled}
                  inputs={inputs}
                  label="ID"
                  name="ID"
                  placeholder="ID"
                  onChange={onChange}
                />
                <InputWithMemory
                  expanded
                  disabled={disabled}
                  inputs={inputs}
                  label="Date"
                  name="Date"
                  placeholder="Date"
                  type="date"
                  onChange={onChange}
                />
                <InputWithMemory
                  expanded
                  disabled={disabled}
                  inputs={inputs}
                  label="Expiration Date"
                  name="ExpDate"
                  placeholder="Expiration Date"
                  type="date"
                  onChange={onChange}
                />
              </Field>
              <Field className="grouped-field-on-tablet">
                <InputWithMemory
                  expanded
                  disabled={disabled}
                  inputs={inputs}
                  label="Size"
                  name="Size"
                  placeholder="Size"
                  type="number"
                  onChange={onChange}
                />
                <InputWithSelectAndMemory
                  expanded
                  disabled={disabled}
                  inputs={inputs}
                  label="Size Unit"
                  name="BatchSizeUnit"
                  onChange={onChange}
                />
                <InputWithMemory
                  expanded
                  disabled={disabled}
                  inputs={inputs}
                  label="Quantity"
                  name="Qty"
                  placeholder="Quantity"
                  type="number"
                  onChange={onChange}
                />
              </Field>
            </Column>
          </Panel>
        </Collapse>
        <Collapse accordion>
          <Panel
            header={
              <Control>
                <Title size={5}>Ethanol</Title>
              </Control>
            }
          >
            <Column size={12}>
              <RadioWithMemory
                disabled={disabled}
                inputs={inputs}
                label="Hide Ethanol on Solvents"
                name="HideEthanolOnSolventsOverride"
                options={[
                  {
                    value: "true",
                    label: "true",
                  },
                  {
                    value: "false",
                    label: "false",
                  },
                ]}
                onChange={onChange}
              >
                <Input
                  className="ethanol-input"
                  disabled={disabled}
                  name="EthanolSolventsNoteOverride"
                  placeholder="Enter Override Note here"
                  size="small"
                  type="text"
                  value={
                    inputs?.EthanolSolventsNoteOverride ||
                    inputs.OverrideData?.EthanolSolventsNoteOverride
                  }
                  onChange={({ target: { name, value } }) =>
                    onChange(name, value)
                  }
                />
              </RadioWithMemory>
            </Column>
          </Panel>
        </Collapse>
        <Collapse accordion>
          <Panel
            header={
              <Control>
                <Title size={5}>Per Unit Calculator Info</Title>
              </Control>
            }
          >
            <Column className="unit-calc-column" size={12}>
              <Field className="grouped-field-on-tablet">
                <Control expanded>
                  <Label>Structural Rigidity</Label>
                  <Input
                    disabled={disabled}
                    name="StructuralRigidity"
                    value={
                      (inputs.StructuralRigidity
                        ? inputs.StructuralRigidity
                        : inputs.OverrideData?.StructuralRigidity ||
                          inputs.DisplayUnit?.StructuralRigidity) || ""
                    }
                    onChange={({ target: { name, value } }) =>
                      onChange(name, value)
                    }
                  />
                </Control>
                <Control expanded>
                  <Label>Display Name</Label>
                  <Input
                    disabled={disabled}
                    name="DisplayName"
                    value={
                      (inputs.DisplayName
                        ? inputs.DisplayName
                        : inputs.OverrideData?.DisplayName ||
                          inputs.DisplayUnit?.DisplayName) || ""
                    }
                    onChange={({ target: { name, value } }) =>
                      onChange(name, value)
                    }
                  />
                </Control>
              </Field>
              <Field className="grouped-field-on-tablet">
                <Control expanded>
                  <Label>Number of Servings per Package</Label>
                  <Input
                    disabled={disabled}
                    name="Units"
                    value={
                      (inputs.Units
                        ? inputs.Units
                        : inputs.OverrideData?.Units ||
                          inputs.DisplayUnit?.Units) || ""
                    }
                    onChange={({ target: { name, value } }) =>
                      onChange(name, value)
                    }
                  />
                </Control>
                <Control expanded>
                  <Label>Serving Weight</Label>
                  <Input
                    disabled={disabled}
                    name="Weight"
                    value={
                      (inputs.Weight
                        ? inputs.Weight
                        : inputs.OverrideData?.Weight ||
                          inputs.DisplayUnit?.Weight) || ""
                    }
                    onChange={({ target: { name, value } }) =>
                      onChange(name, value)
                    }
                  />
                </Control>
                <Control expanded>
                  <Label>Density</Label>
                  <Input
                    disabled={disabled}
                    name="Density"
                    value={
                      (inputs.Density
                        ? inputs.Density
                        : inputs.OverrideData?.Density ||
                          inputs.DisplayUnit?.Density) || ""
                    }
                    onChange={({ target: { name, value } }) =>
                      onChange(name, value)
                    }
                  />
                </Control>
              </Field>
            </Column>
          </Panel>
        </Collapse>
      </Column>
      <Column size={12}>
        <Box>
          <Field multiline kind="group">
            <Control>
              <Button
                as="a"
                color="primary"
                disabled={
                  !allTestApproved ||
                  !user?.COASettings?.COA_SOP ||
                  user?.COASettings?.COA_SOP === "0"
                }
                state={loading ? "loading" : ""}
                target="_blank"
                type="button"
                onClick={() => handleClick(true, true)}
              >
                View Latest One Pager
              </Button>
            </Control>
            <Control>
              <Button
                as="a"
                color="info"
                disabled={
                  !allTestApproved ||
                  !user?.COASettings?.COA_SOP ||
                  user?.COASettings?.COA_SOP === "0"
                }
                href={`${REACT_APP_COA_URL}/${user?.COASettings?.COA_SOP}/pdf/${inputs.JobOrderID}/0?cachebuster=1`}
                state={loading ? "loading" : ""}
                target="_blank"
                type="button"
              >
                Generate New One Pager
              </Button>
            </Control>
            <Control>
              <Button
                as="a"
                color="primary"
                state={loading ? "loading" : ""}
                target="_blank"
                type="button"
                onClick={() => handleClick()}
              >
                View Latest HTML Report
              </Button>
            </Control>
            <Control>
              <Button
                as="a"
                color="info"
                disabled={!allTestApproved}
                href={`${REACT_APP_COA_URL}/coa/html/${inputs.JobOrderID}/0`}
                state={loading ? "loading" : ""}
                target="_blank"
                type="button"
              >
                Generate New HTML Report
              </Button>
            </Control>
            <Control>
              <Button
                as="a"
                color="primary"
                state={loading ? "loading" : ""}
                target="_blank"
                type="button"
                onClick={() => handleClick(true)}
              >
                Download Latest PDF Results
              </Button>
            </Control>
            <Control>
              <Button
                as="a"
                color="info"
                disabled={!allTestApproved}
                href={`${REACT_APP_COA_URL}/coa/pdf/${inputs.JobOrderID}/0?cachebuster=1`}
                state={loading ? "loading" : ""}
                target="_blank"
                type="button"
              >
                Generate New PDF Results
              </Button>
            </Control>
            <Control>
              <Button
                as="a"
                color="primary"
                disabled={!canExportCsv}
                state={exportLoading ? "loading" : ""}
                target="_blank"
                type="button"
                onClick={() => handleExport()}
              >
                Download Latest CSV Results
              </Button>
            </Control>
          </Field>
        </Box>
      </Column>
      <Column size={12}>
        <div className="page-head">
          <div className="page-head-start">
            <Title>Samples</Title>
          </div>
          {!disabled &&
            inputs?.JobOrderStatus?.CodeDescription !== "Completed" && (
              <div className="page-head-end">
                <Control expanded size="small">
                  <TestSelect
                    id="TestID"
                    label="Test Name"
                    name="TestID"
                    size="small"
                    value={selectedTest}
                    onChange={(name, value) => handleTestChange(value)}
                  />
                </Control>
                <Control expanded size="small">
                  <Label>&nbsp;</Label>
                  <Button
                    color="primary"
                    size="small"
                    type="button"
                    onClick={() => onAddTestToJobOrder(selectedTest)}
                  >
                    Add Test
                  </Button>
                </Control>
              </div>
            )}
        </div>
        <br />
        <SamplesList
          disabled={disabled}
          isHomo={false}
          jobOrderID={inputs.JobOrderID}
        />
      </Column>
      <Column size={12}>
        <div className="page-head">
          <div className="page-head-start">
            <Title>Homogeneity Samples</Title>
          </div>
        </div>
        <br />
        <HomogeneityResultsSection JobOrderID={inputs.JobOrderID} />
        <SamplesList
          isHomo
          disabled={disabled}
          jobOrderID={inputs.JobOrderID}
        />
      </Column>
    </Column.Group>
  );
};
OrderForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  updatePerUnitCalculation: PropTypes.func,
  onAddTestToJobOrder: PropTypes.func,
  onOpenResultModal: PropTypes.func,
  user: PropTypes.object.isRequired,
  JobData: PropTypes.object,
  allTestApproved: PropTypes.bool,
  canExportCsv: PropTypes.bool,
};

OrderForm.defaultProps = {
  disabled: false,
  updatePerUnitCalculation: () => null,
  onAddTestToJobOrder: () => null,
  onOpenResultModal: () => null,
  JobData: {},
  allTestApproved: false,
  canExportCsv: false,
};

export default OrderForm;
