import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button, Fieldset } from "rbx";
import { useLazyQuery, useMutation } from "@apollo/client";
import TestCategoryForm from "../TestCategoryForm";
import {
  SINGLE_TEST_CATEGORY_QUERY,
  UPDATE_TEST_CATEGORY_MUTATION,
  CREATE_TEST_CATEGORY_MUTATION,
  LIST_TEST_CATEGORIES_QUERY,
} from "../../../../../graphql";
import { Loader } from "../../../../../components";
import { customToast as toast } from "../../../../../utils";
import { useAuth } from "../../../../../context";

const INITIAL_STATE = {
  Name: "",
  Code: "",
  Description: "",
};

const TestCategoryModal = ({ TestCategoryID, onComplete }) => {
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [loading, setLoading] = useState(!!TestCategoryID);

  const [getTestCategoryData, resultTestCategoryData] = useLazyQuery(
    SINGLE_TEST_CATEGORY_QUERY
  );
  const [updateTestCategory] = useMutation(UPDATE_TEST_CATEGORY_MUTATION);
  const [createTestCategory] = useMutation(CREATE_TEST_CATEGORY_MUTATION);

  useEffect(() => {
    if (TestCategoryID) {
      getTestCategoryData({
        variables: {
          where: { TestCategoryID: parseInt(TestCategoryID, 10) },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [TestCategoryID, getTestCategoryData]);

  useEffect(() => {
    if (resultTestCategoryData?.data?.findUniqueTestCategories) {
      const {
        data: { findUniqueTestCategories },
      } = resultTestCategoryData;

      setInputs((prev) => ({
        ...prev,
        ...findUniqueTestCategories,
      }));
      setLoading(false);
    }
  }, [resultTestCategoryData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const date = new Date();
      if (TestCategoryID) {
        await updateTestCategory({
          variables: {
            data: {
              Name: { set: inputs.Name },
              Code: { set: inputs.Code },
              Description: { set: inputs.Description },
              ModifiedDateTime: { set: date },
              ModifiedBy: { set: authState?.user?.Username },
            },
            where: { TestCategoryID: parseInt(TestCategoryID, 10) },
          },
          refetchQueries: [
            {
              query: LIST_TEST_CATEGORIES_QUERY,
            },
            {
              query: SINGLE_TEST_CATEGORY_QUERY,
              variables: {
                where: { TestCategoryID: parseInt(TestCategoryID, 10) },
              },
            },
          ],
        });
      } else {
        await createTestCategory({
          variables: {
            data: {
              Name: inputs.Name,
              Code: inputs.Code,
              Description: inputs.Description,
              CreatedDateTime: date,
              ModifiedDateTime: date,
              CreatedBy: authState?.user?.Username,
              ModifiedBy: authState?.user?.Username,
            },
          },
          refetchQueries: [
            {
              query: LIST_TEST_CATEGORIES_QUERY,
            },
          ],
        });
      }
      toast.success("Test Category saved successfully");
      onComplete();
    } catch (err) {
      toast.error("Error saving Test Category");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader style={{ height: "330px" }} />;
  }

  return (
    <React.Fragment>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {TestCategoryID ? "Update" : "Create"} Test Category
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group>
            <Button
              rounded
              color="primary"
              size="small"
              type="button"
              onClick={onComplete}
            >
              <span>Cancel</span>
            </Button>
            <Button
              rounded
              color="primary"
              form="test-category-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Fieldset>
        <TestCategoryForm
          inputs={inputs}
          onChange={handleChange}
          onSubmit={handleSave}
        />
      </Fieldset>
      <hr />
    </React.Fragment>
  );
};
TestCategoryModal.propTypes = {
  TestCategoryID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onComplete: PropTypes.func.isRequired,
};
TestCategoryModal.defaultProps = {
  TestCategoryID: "",
};
export default TestCategoryModal;
