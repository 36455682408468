import { gql } from "@apollo/client";

export const DELETE_ANALYTE_MUTATION = gql`
  mutation DELETE_ANALYTE_MUTATION($where: AnalytesWhereUniqueInput!) {
    deleteAnalytes(where: $where) {
      AnalyteID
      TestCategoryID
    }
  }
`;

export const CREATE_ANALYTE_MUTATION = gql`
  mutation CREATE_ANALYTE_MUTATION($data: AnalytesCreateInput!) {
    createAnalytes(data: $data) {
      AnalyteID
      TestCategoryID
    }
  }
`;

export const UPDATE_ANALYTE_MUTATION = gql`
  mutation UPDATE_ANALYTE_MUTATION(
    $data: AnalytesUpdateInput!
    $where: AnalytesWhereUniqueInput!
  ) {
    updateAnalytes(data: $data, where: $where) {
      AnalyteID
      TestCategoryID
    }
  }
`;

export const DELETE_ANALYTE_ON_CASCADE_MUTATION = gql`
  mutation DELETE_ANALYTE_ON_CASCADE_MUTATION($where: AnalytesWhereUniqueInput!) {
    deleteAnalyteOnCascade(where: $where) {
      AnalyteID
      TestCategoryID
    }
  }
`;
