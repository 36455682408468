import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";

import { PageHeader } from "../../../../components";
import { customToast as toast } from "../../../../utils";
import {
  ALL_ANALYTES_QUERY,
  CREATE_ANALYTE_MUTATION,
} from "../../../../graphql";
import CreateAnalyteForm from "../components/CreateAnalyteForm";
import DisplayAnalytesTables from "../components/DisplayAnalytesTables";
import Loader from "../../../../components/Loader";
import "./AnalytesListPage.scss";

const INITIAL_STATE = {
  Name: "",
  TestCategoryID: "",
  Active: true,
};

const convertInputToVariables = (variables, keys, adding = true) =>
  keys.reduce((acc, curr) => {
    if (variables[curr] !== null && variables[curr] !== undefined) {
      acc[curr] = variables[curr];
    } else {
      acc[curr] = null;
    }
    return acc;
  }, {});

const AnalyteListPage = ({ routePermissions }) => {
  const [loading, setLoading] = useState(false);

  const [createAnalyte] = useMutation(CREATE_ANALYTE_MUTATION);

  const [canUpdate, setCanUpdate] = useState(true);

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  const handleAnalyteCreation = async (inputs) => {
    setLoading(true);
    try {
      const data = {
        ...convertInputToVariables(inputs, Object.keys(INITIAL_STATE), true),
        TestCategory: {
          connect: {
            TestCategoryID: parseInt(inputs.TestCategoryID, 10),
          },
        },
      };
      delete data.TestCategoryID;

      await createAnalyte({
        variables: {
          data,
        },
        refetchQueries: [
          {
            query: ALL_ANALYTES_QUERY,
            variables: {
              where: {},
            },
          },
        ],
      });
      toast.success("Analyte created successfully.");
    } catch (err) {
      toast.error("Error creating Analyte");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader style={{ height: "330px" }} />;
  }

  return (
    <div className="analyte-list-page">
      <PageHeader title="Analytes By Test Category" />
      {canUpdate && (
        <CreateAnalyteForm
          formId="analyte-creation-form"
          onSubmit={handleAnalyteCreation}
        />
      )}
      <DisplayAnalytesTables canUpdate={canUpdate} />
    </div>
  );
};

AnalyteListPage.propTypes = {
  routePermissions: PropTypes.array,
};

AnalyteListPage.defaultProps = {
  routePermissions: [],
};

export default AnalyteListPage;
