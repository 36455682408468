export const analyteNameDisplayLabelMapping = [
  {
    originalAnalyteName: "isopropyl alcohol",
    displayName: "2-Propanol",
    state: "AZ",
    testCategoryCode: "SOLV",
  },
  {
    originalAnalyteName: "pathogenic e. coli",
    displayName: "E. coli",
    state: "AZ",
    testCategoryCode: "MICRO MG INC",
  },
  {
    originalAnalyteName: "pathogenic e. coli",
    displayName: "E. coli",
    state: "AZ",
    testCategoryCode: "MICRO BMX TEMPO",
  },
  {
    originalAnalyteName: "pathogenic e. coli",
    displayName: "E. coli",
    state: "AZ",
    testCategoryCode: "MICRO 3M ENUM",
  },
  {
    originalAnalyteName: "butane",
    displayName: "Total butanes",
    state: "AZ",
    testCategoryCode: "SOLV",
  },
  {
    originalAnalyteName: "methylene chloride",
    displayName: "Dichloromethane",
    state: "AZ",
    testCategoryCode: "SOLV",
  },
  {
    originalAnalyteName: "hexanes",
    displayName: "Total hexanes",
    state: "AZ",
    testCategoryCode: "SOLV",
  },
  {
    originalAnalyteName: "pentane",
    displayName: "Total pentanes",
    state: "AZ",
    testCategoryCode: "SOLV",
  },
  {
    originalAnalyteName: "xylenes",
    displayName: "Total xylenes",
    state: "AZ",
    testCategoryCode: "SOLV",
  },
  {
    originalAnalyteName: "xylenes",
    displayName: "Total xylenes",
    state: "FL",
    testCategoryCode: "SOLV",
  },
  {
    originalAnalyteName: "abamectin b1a",
    displayName: "Abamectin",
    state: "AZ",
    testCategoryCode: "SSI LC PEST MYCO",
  },
  {
    originalAnalyteName: "abamectin b1a",
    displayName: "Abamectin",
    state: "AZ",
    testCategoryCode: "AGILENT LC PEST MYCO",
  },
];

export const summedAnalytes = [
  {
    testCategoryCode: "SSI LC PEST MYCO",
    sumAnalyteName: "total aflatoxins",
    addendAnalyteNames: [
      "aflatoxin b1",
      "aflatoxin b2",
      "aflatoxin g1",
      "aflatoxin g2",
    ],
  },
  {
    testCategoryCode: "AGILENT LC PEST MYCO",
    sumAnalyteName: "total aflatoxins",
    addendAnalyteNames: [
      "aflatoxin b1",
      "aflatoxin b2",
      "aflatoxin g1",
      "aflatoxin g2",
    ],
  },
  {
    testCategoryCode: "SOLV",
    sumAnalyteName: "butane",
    addendAnalyteNames: ["n-butane", "iso-butane"],
    state: "AZ",
  },
  {
    testCategoryCode: "SOLV",
    sumAnalyteName: "hexanes",
    addendAnalyteNames: [
      "n-hexane",
      "2,2-dimethylbutane",
      "2,3-dimethylbutane",
      "2-methylpentane",
      "3-methylpentane",
    ],
    state: "AZ",
  },
  {
    testCategoryCode: "SOLV",
    sumAnalyteName: "pentane",
    addendAnalyteNames: ["n-pentane", "iso-pentane", "neo-pentane"],
    state: "AZ",
  },
  {
    testCategoryCode: "SOLV",
    sumAnalyteName: "xylenes",
    addendAnalyteNames: ["xylene (o)", "xylenes (m,p)", "ethyl benzene"],
    state: "AZ",
  },
  {
    testCategoryCode: "SOLV",
    sumAnalyteName: "xylenes",
    addendAnalyteNames: ["xylene (o)", "xylenes (m,p)"],
    state: "FL",
  },
];

export const inhalableCategories = [
  "Biomass",
  "Cannagars/Cannarillos",
  "Clone",
  "Flower",
  "Flower - Cured",
  "Flower - Uncured",
  "Flower - Wet",
  "Flower/Trim - Mixed",
  "Fresh Frozen",
  "Plant",
  "Popcorn Buds",
  "Pre-Roll",
  "Root",
  "Seed",
  "Sprouts",
  "Stem/Stalk",
  "Sugar Leaf",
  "Trim",
  "Air",
  "All",
  "Batter/Badder",
  "Budder",
  "Caviar",
  "Caviar Preroll",
  "Concentrate/Extract - Liquid",
  "Concentrate/Extract - Solid",
  "Concentrates/Extracts",
  "Crude",
  "Crumble",
  "Crystalline Solid",
  "Diamonds",
  "Distillate",
  "Enhanced/Infused Preroll",
  "Hash - Hard",
  "Hash - Ice Water",
  "Hash - Oil",
  "Honeycomb",
  "Isolate",
  "Kief",
  "Leaf - Fresh",
  "Live Resin",
  "Live Rosin",
  "Live Budder",
  "Moon Rock",
  "Press",
  "Rosin",
  "Sauce",
  "Shatter",
  "Shisha",
  "Sugar Wax",
  "Tarantula",
  "Vape - Oil",
  "Vape Hardware",
  "Vape Oil",
];

export const microResultImportMapping = {
  "Aspergillus flavus": "CY5",
  "Aspergillus niger": "ROX",
  "Aspergillus fumigatus": "FAM",
  "Aspergillus terreus": "ATTO425",
  "STEC e. coli": "FAM",
  Salmonella: "ROX",
  "Pathogenic e. coli": "FAM",
  "Pseudomonas aeruginosa": "FAM",
  "Staphylococcus aureus": "FAM",
  "Listeria monocytogenes": "FAM",
  "Total aerobic count": "FAM",
  "Total coliform": "FAM",
  "Total coliforms": "FAM",
  "Total enterobacteriaceae": "FAM",
  "Total yeast and mold": "FAM",
  BTGN: "FAM",
};

export const microSpeciesMapping = [
  {
    species: "aspergillus",
    analytes: [
      "Aspergillus flavus",
      "Aspergillus niger",
      "Aspergillus fumigatus",
      "Aspergillus terreus",
    ],
  },
  {
    species: "stecSalmonella",
    analytes: ["STEC e. coli", "Salmonella"],
  },
  {
    species: "eColi",
    analytes: ["Pathogenic e. coli"],
  },
  {
    species: "pAeruginosa",
    analytes: ["Pseudomonas aeruginosa"],
  },
  {
    species: "sAureus",
    analytes: ["Staphylococcus aureus"],
  },
  {
    species: "lMonocytogenes",
    analytes: ["Listeria monocytogenes"],
  },
  {
    species: "aerobic",
    analytes: ["Total aerobic count"],
  },
  {
    species: "coliform",
    analytes: ["Total coliform", "Total coliforms"],
  },
  {
    species: "enterobacteriaceae",
    analytes: ["Total enterobacteriaceae"],
  },
  {
    species: "yeastMold",
    analytes: ["Total yeast and mold"],
  },
  {
    species: "btgn",
    analytes: ["BTGN"],
  },
];

export const microCfuConversionConstants = [
  {
    matrix: "flower",
    analyte: "total yeast and mold",
    constant1: -0.1896,
    constant2: 8.5798,
    constant3: 20,
  },
  {
    matrix: "flower",
    analyte: "total aerobic count",
    constant1: 35.111,
    constant2: 2.8883,
    negativeThreshold: 30,
  },
  {
    matrix: "flower",
    analyte: "total coliform",
    constant1: 40.073,
    constant2: 3.3417,
  },
  {
    matrix: "flower",
    analyte: "total coliforms",
    constant1: 40.073,
    constant2: 3.3417,
  },
  {
    matrix: "flower",
    analyte: "btgn",
    constant1: 38.665,
    constant2: 3.2417,
  },
  {
    matrix: "flower",
    analyte: "total enterobacteriaceae",
    constant1: 41.218,
    constant2: 4.3708,
  },
  {
    matrix: "mip",
    analyte: "total yeast and mold",
    constant1: -0.096,
    constant2: 7.1104,
    constant3: 8,
  },
  {
    matrix: "mip",
    analyte: "total aerobic count",
    constant1: 38.076,
    constant2: 3.2249,
    negativeThreshold: 35,
  },
  {
    matrix: "mip",
    analyte: "total coliform",
    constant1: 41.935,
    constant2: 3.6274,
  },
  {
    matrix: "mip",
    analyte: "total coliforms",
    constant1: 41.935,
    constant2: 3.6274,
  },
  {
    matrix: "mip",
    analyte: "btgn",
    constant1: 40.58,
    constant2: 3.3403,
  },
  {
    matrix: "mip",
    analyte: "total enterobacteriaceae",
    constant1: 38.407,
    constant2: 3.3041,
  },
  {
    matrix: "gummy",
    analyte: "total yeast and mold",
    constant1: -0.096,
    constant2: 7.1104,
    constant3: 8,
  },
  {
    matrix: "gummy",
    analyte: "total aerobic count",
    constant1: 37.235,
    constant2: 2.356,
    negativeThreshold: 35,
  },
  {
    matrix: "gummy",
    analyte: "total coliform",
    constant1: 52.888,
    constant2: 5.9643,
  },
  {
    matrix: "gummy",
    analyte: "total coliforms",
    constant1: 52.888,
    constant2: 5.9643,
  },
  {
    matrix: "gummy",
    analyte: "btgn",
    constant1: 40.458,
    constant2: 3.4035,
  },
  {
    matrix: "gummy",
    analyte: "total enterobacteriaceae",
    constant1: 44.81,
    constant2: 4.9665,
  },
];

export const cannSummedAnalyteRules = [
  {
    sumAnalyte: "d9-THC",
    addendAnalyte: "THCA",
    factor: 0.877,
  },
  {
    sumAnalyte: "CBD",
    addendAnalyte: "CBDA",
    factor: 0.877,
  },
  {
    sumAnalyte: "CBG",
    addendAnalyte: "CBGA",
    factor: 0.878,
  },
  {
    sumAnalyte: "CBN",
    addendAnalyte: "CBNA",
    factor: 0.876,
  },
  {
    sumAnalyte: "THCV",
    addendAnalyte: "THCVA",
    factor: 0.867,
  },
  {
    sumAnalyte: "CBDV",
    addendAnalyte: "CBDVA",
    factor: 0.867,
  },
];

export const labelClaimThresholdProductCategoryCodeDescriptions = [
  "Edible/Food",
  "Soft Chew",
  "Gummy",
  "Chocolate",
  "Orally-Dissolving Product",
  "Hard Lozenge",
  "Baked Goods",
  "Capsule/Tablet",
  "Beverage",
];
