import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Input, Button } from "rbx";

const ForgotPassword = ({ value, onChange, loading }) => (
  <div className="landing-page-inputs">
    <Field>
      <Control>
        <Input
          name="Email"
          placeholder="Your email"
          size="large"
          type="email"
          value={value}
          onChange={onChange}
        />
      </Control>
    </Field>
    <Button rounded color="primary" disabled={loading} size="large">
      Next
    </Button>
  </div>
);
ForgotPassword.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
ForgotPassword.defaultProps = {
  loading: false,
};
export default ForgotPassword;
