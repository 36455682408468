import React from "react";
import PropTypes from "prop-types";
import { Box, Field, Control, Label, Button, Column } from "rbx";
import {
  LabLocationSelect,
  UserSelect,
  SystemCodeSelect,
} from "../../../../../components";

const DashboardFilters = ({ onChange, inputs, onReset, canSearchByLab }) => (
  <Box>
    <Column.Group>
      <Column size={canSearchByLab ? 3.5 : 5.5}>
        <Field>
          <UserSelect
            label="Client"
            name="UserID"
            value={inputs?.UserID?.equals}
            where={{ Type: { is: { CodeId: { equals: "0" } } } }}
            onChange={onChange}
          />
        </Field>
      </Column>
      {canSearchByLab && (
        <Column size={3.5}>
          <Field>
            <LabLocationSelect
              showLabel
              name="LabID"
              showAllLocation={false}
              size="small"
              value={inputs?.Job?.is?.LabID?.equals}
              onChange={onChange}
            />
          </Field>
        </Column>
      )}
      <Column size={canSearchByLab ? 3.5 : 5.5}>
        <Field>
          <SystemCodeSelect
            label="Product Category"
            name="ProductCategory"
            size="small"
            value={inputs?.ProductCategory?.equals}
            where={{
              Type: { equals: "Product" },
              Category: { equals: "Category" },
              CodeName: { equals: "ProductCategory" },
            }}
            onChange={onChange}
          />
        </Field>
      </Column>
      <Column size={1}>
        <Field>
          <Control>
            <Label>&nbsp;</Label>
            <Button color="primary" size="small" onClick={onReset}>
              Reset
            </Button>
          </Control>
        </Field>
      </Column>
    </Column.Group>
  </Box>
);

DashboardFilters.propTypes = {
  onChange: PropTypes.func,
  inputs: PropTypes.object,
  onReset: PropTypes.func,
  canSearchByLab: PropTypes.bool,
};

DashboardFilters.defaultProps = {
  onChange: (e) => e,
  inputs: {},
  onReset: (e) => e,
  canSearchByLab: false,
};

export default DashboardFilters;
