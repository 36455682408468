const lims = {
  name: "lims",
  label: "LIMS",
  icon: "desktop",
  permissions: ["LIMS"],
  active: (location) => {
    const [, p] = location.pathname.split("/");
    return /lims/.test(p);
  },
  links: [
    {
      name: "Orders",
      links: [
        {
          name: "Intake",
          to: "/lims/intake",
        },
        {
          name: "Moisture, Water & Filth",
          to: "/lims/moisture-water-filth",
        },
        {
          name: "Import Results",
          to: "/lims/import-results",
        },
      ],
    },

    // {
    //   name: "Tracking",
    //   links: [
    //     {
    //       name: "Sample Locations",
    //       to: "/lims/sample-locations",
    //     },
    //     {
    //       name: "Sample Tracking",
    //       to: "/lims/sample-tracking",
    //     },
    //   ],
    // },
    {
      name: "Sample Preparation",
      links: [
        {
          name: "Batch Records",
          to: "/lims/batch-records",
        },
        {
          name: "Sample Prep",
          to: "/lims/sample-prep",
        },
        {
          name: "Per Unit Calculator",
          to: "/lims/per-unit-calculator",
        },
        {
          name: "Labels",
          to: "/lims/labels",
        },
      ],
    },
    {
      name: "Reporting",
      links: [
        {
          name: "Compliance Review",
          to: "/lims/compliance-review",
        },
        {
          name: "Final Review",
          to: "/lims/final-review",
        },
      ],
    },
    {
      name: "Miscellaneous",
      links: [
        {
          name: "Internal Notes",
          to: "/lims/internal-notes",
        },
        {
          name: "Template Packages",
          to: "/lims/template-packages",
        },
        {
          name: "QC Target Values",
          to: "/lims/qc-targets",
        },
        {
          name: "Qualifiers (AZ only)",
          to: "/lims/qualifiers",
        },
      ],
    },
  ],
  root: "/lims",
};

export default lims;
