import { gql } from "@apollo/client";

export const ALL_QUALIFIERS_QUERY = gql`
  query ALL_QUALIFIERS_QUERY(
    $where: QualifiersWhereInput
    $orderBy: [QualifiersOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyQualifiers(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      QualifierID
      Name
      Active
      States
      CreatedBy
      CreatedDateTime
      ModifiedBy
      ModifiedDateTime
    }
    aggregateQualifiers(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const SINGLE_QUALIFIER_QUERY = gql`
  query SINGLE_QUALIFIER_QUERY($where: QualifiersWhereUniqueInput!) {
    findUniqueQualifiers(where: $where) {
      QualifierID
      Name
      Active
      States
      CreatedBy
      CreatedDateTime
      ModifiedBy
      ModifiedDateTime
    }
  }
`;
