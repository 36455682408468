import React from "react";
import PropTypes from "prop-types";
import { CreditCard, PaymentForm } from "rswp-sdk";
import { Control, Button, Title } from "rbx";
import { customToast as toast, formatCurrency } from "../../../../../../utils";

const { REACT_APP_APLLICATION_ID, REACT_APP_LOCATION_ID } = process.env;

const PrivateLabelPaymentForm = ({
  onSubmit,
  paymentAmount,
  loading,
  onClose,
}) => (
  <React.Fragment>
    <header className="page-head">
      <div className="page-head-start">
        <Title size={5}>Complete Purchase</Title>
      </div>
    </header>
    <hr />
    <Control expanded id="card-input-container">
      <Title subtitle className="page-head-end" color="primary">
        Payment Total: {formatCurrency(paymentAmount)}
      </Title>
      <br />
      {paymentAmount ? (
        <PaymentForm
          applicationId={REACT_APP_APLLICATION_ID}
          cardTokenizeResponseReceived={async (response) => {
            if (response.status === "OK" && !!response.token) {
              await onSubmit(response.token);
            } else {
              toast.error("Error processing card");
            }
          }}
          locationId={REACT_APP_LOCATION_ID}
        >
          <CreditCard
            buttonProps={{
              isLoading: loading,
              css: {
                borderRadius: "20px 0px 20px 20px",
                background: "linear-gradient(to right, #3bb78e, #059e42)",
                maxWidth: "fit-content",
                padding: "5px 20px",
                margin: "auto",
              },
            }}
          >
            Submit Payment
          </CreditCard>
        </PaymentForm>
      ) : (
        <Button
          color="primary"
          onClick={async () => {
            await onSubmit("");
          }}
        >
          Submit Order
        </Button>
      )}
    </Control>
    <Control>
      <Button size="small" type="button" onClick={onClose}>
        Cancel
      </Button>
    </Control>
  </React.Fragment>
);
PrivateLabelPaymentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  paymentAmount: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};
PrivateLabelPaymentForm.defaultProps = {
  loading: false,
  paymentAmount: 0,
};

export default PrivateLabelPaymentForm;
