/* eslint-disable max-lines-per-function */
import React from "react";
import PropTypes from "prop-types";
import { Column } from "rbx";
import OrderTabs from "../OrderTabs";

const TestStatusForm = ({
  inputs,
  onChange,
  disabled,
  formId,
  JobID,
  JobOrderID,
  handleOrderChange,
  history,
  updatePerUnitCalculation,
  onAddTestToJobOrder,
  onOpenResultModal,
  JobData,
  allTestApproved,
  canExportCsv,
}) => (
  <form id={formId}>
    <Column.Group multiline>
      <Column size={12}>
        <OrderTabs
          JobData={JobData}
          JobID={JobID}
          JobOrderID={JobOrderID}
          allTestApproved={allTestApproved}
          canExportCsv={canExportCsv}
          disabled={disabled}
          history={history}
          inputs={inputs}
          updatePerUnitCalculation={updatePerUnitCalculation}
          onAddTestToJobOrder={onAddTestToJobOrder}
          onChange={handleOrderChange}
          onOpenResultModal={onOpenResultModal}
        />
      </Column>
    </Column.Group>
  </form>
);

TestStatusForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  handleOrderChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  formId: PropTypes.string.isRequired,
  JobID: PropTypes.string.isRequired,
  JobOrderID: PropTypes.string,
  history: PropTypes.object.isRequired,
  updatePerUnitCalculation: PropTypes.func,
  onAddTestToJobOrder: PropTypes.func,
  onOpenResultModal: PropTypes.func,
  JobData: PropTypes.object,
  allTestApproved: PropTypes.bool,
  canExportCsv: PropTypes.bool,
};

TestStatusForm.defaultProps = {
  disabled: false,
  JobOrderID: null,
  updatePerUnitCalculation: () => null,
  onAddTestToJobOrder: () => null,
  onOpenResultModal: () => null,
  JobData: {},
  allTestApproved: false,
  canExportCsv: false,
};

export default TestStatusForm;
