import React, { useState, useMemo, useRef, useEffect } from "react";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";

import SampleQueueFilters from "../components/SampleQueueFilters";
import {
  PageHeader,
  DataTable,
  ListSearchInput,
  IconButton,
} from "../../../components";
import { customToast as toast, debounce } from "../../../utils";
import { generateColumns } from "./columns";
import {
  CREATE_JOB_ORDER_TEST_SAMPLES,
  ALL_JOB_ORDER_TEST_SAMPLES_QUERY,
  UPDATE_JOB_ORDER_TEST_SAMPLE,
} from "../../../graphql";
import { useAuth } from "../../../context";

import "./RejectedSamples.scss";

const INITIAL_STATE = {
  AND: [
    { ResultStatus: { is: { CodeId: { equals: "R" } } } },
    { Replicated: { not: { equals: true } } },
    {
      JobOrder: {
        is: {
          JobOrderStatus: {
            isNot: { CodeDescription: { equals: "Completed" } },
          },
        },
      },
    },
  ],
};
const RejectedSamplesListPage = (props) => {
  const { state: authState } = useAuth();
  const searchInputRef = useRef();

  const [showFilters, setShowFilters] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const [where, setWhere] = useState(INITIAL_STATE);
  const [selectedTest, setSelectedTest] = useState();
  const [labID, setLabID] = useState("");

  const orderBy = [{ id: "CreatedDateTime", desc: true }];

  const [createJobOrderTestSamples] = useMutation(
    CREATE_JOB_ORDER_TEST_SAMPLES
  );
  const [updateJobOrderTestSample] = useMutation(UPDATE_JOB_ORDER_TEST_SAMPLE);

  const { routePermissions } = props;

  useEffect(() => {
    setLabID(authState.user.LabID);
    if (authState.user.LabID !== "9999") {
      setWhere((prev) => ({
        ...prev,
        Job: {
          is: {
            LabID: {
              equals: parseInt(authState.user.LabID, 10),
            },
          },
        },
      }));
    }
  }, [authState]);

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  const onChangeTest = (name, value) => {
    setSelectedTest(value);
    setWhere((prev) => {
      if (value) {
        return {
          ...prev,
          AND: [
            {
              JobOrderTest: { is: { TestID: { equals: parseInt(value, 10) } } },
            },
            { ResultStatus: { is: { CodeId: { equals: "R" } } } },
            { Replicated: { not: { equals: true } } },
            {
              JobOrder: {
                is: {
                  JobOrderStatus: {
                    isNot: { CodeDescription: { equals: "Completed" } },
                  },
                },
              },
            },
          ],
        };
      }

      if (labID?.toString() !== "9999") {
        return {
          ...INITIAL_STATE,
          Job: {
            is: {
              LabID: {
                equals: parseInt(authState.user.LabID, 10),
              },
            },
          },
        };
      }

      return INITIAL_STATE;
    });
  };

  const columns = useMemo(() => {
    const handleClickReplicate = async (jobOrderTestSample, replicateQty) => {
      try {
        await updateJobOrderTestSample({
          variables: {
            where: {
              JobOrderTestSampleID: parseInt(
                jobOrderTestSample.JobOrderTestSampleID,
                10
              ),
            },
            data: {
              Replicated: { set: true },
            },
          },
          refetchQueries: [
            {
              query: ALL_JOB_ORDER_TEST_SAMPLES_QUERY,
              variables: {
                where,
              },
            },
          ],
        });

        [...Array(replicateQty).keys()].forEach(async (x) => {
          const prepNo = x + 1 + jobOrderTestSample.PrepNo;
          await createJobOrderTestSamples({
            variables: {
              data: {
                SessionID: `${jobOrderTestSample.JobID}_${jobOrderTestSample.JobOrderID}_${jobOrderTestSample.JobOrderTestID}`,
                JobOrderTest: {
                  connect: {
                    JobOrderTestID: parseInt(
                      jobOrderTestSample.JobOrderTestID,
                      10
                    ),
                  },
                },
                JobOrder: {
                  connect: {
                    JobOrderID: parseInt(jobOrderTestSample.JobOrderID, 10),
                  },
                },
                Job: {
                  connect: {
                    JobID: parseInt(jobOrderTestSample.JobID, 10),
                  },
                },
                PrepNo: prepNo,
                Important: jobOrderTestSample.Important,
                CreatedBy: authState?.user?.Username,
                ModifiedBy: authState?.user?.Username,
              },
            },
          });
        });
      } catch (err) {
        toast.error("Error saving changes");
      }
    };

    const handlePrintLabel = (row) => {
      const win = window.open(
        `/lims/labels/JobOrderTestSampleID-${row.JobOrderTestSampleID}`
      );
      win.focus();
    };

    return generateColumns(handleClickReplicate, canUpdate, handlePrintLabel);
  }, [
    authState.user.Username,
    createJobOrderTestSamples,
    where,
    updateJobOrderTestSample,
    canUpdate,
  ]);

  const handleWhereChange = (value) => {
    if (value) {
      setWhere((prev) => ({
        ...prev,
        JobOrder: {
          is: {
            OR: [
              {
                OrderName: {
                  startsWith: value,
                },
              },
              {
                BatchOrderNumber: {
                  startsWith: value,
                },
              },
            ],
          },
        },
      }));
    } else {
      setWhere((prev) => {
        const prevValue = { ...prev };
        delete prevValue.JobOrder;
        return prevValue;
      });
    }
  };

  const onLabChange = (name, value) => {
    setWhere((prev) => ({
      ...prev,
      Job: {
        is: {
          LabID: {
            ...value,
          },
        },
      },
    }));
  };

  const onFiltersReset = () => {
    setWhere(INITIAL_STATE);
    setSelectedTest(undefined);
  };

  return (
    <div className="test-list-page">
      <PageHeader title="Rejected Samples">
        <ListSearchInput
          ref={searchInputRef}
          placeholder="Search By Job ID..."
          onChange={debounce((name, value) => handleWhereChange(value), 500)}
        />
        <IconButton
          icon="filter"
          onClick={(e) => {
            e.preventDefault();
            setShowFilters((prev) => !prev);
          }}
        />
      </PageHeader>
      {showFilters && (
        <SampleQueueFilters
          labID={labID}
          selectedTest={selectedTest}
          testQueueSelectCountWhere="Rejected"
          where={where}
          onChangeTest={onChangeTest}
          onFiltersReset={onFiltersReset}
          onLabChange={onLabChange}
        />
      )}
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateJobOrderTestSamples"
        columns={columns}
        name="findManyJobOrderTestSamples"
        orderBy={orderBy}
        query={ALL_JOB_ORDER_TEST_SAMPLES_QUERY}
        where={where}
      />
    </div>
  );
};

RejectedSamplesListPage.propTypes = {
  routePermissions: PropTypes.array,
};

RejectedSamplesListPage.defaultProps = {
  routePermissions: [],
};

export default RejectedSamplesListPage;
