import React from "react";
import PropTypes from "prop-types";
import LabAccountDetailsForm from "../../components/LabAccountDetailsForm";

const AccountDetails = ({
  inputs,
  onChange,
  disabled,
  adding,
  onSubmit,
  uploadLabSignaturePath,
}) => (
  <div>
    <LabAccountDetailsForm
      adding={adding}
      disabled={disabled}
      inputs={inputs}
      uploadLabSignaturePath={uploadLabSignaturePath}
      onChange={onChange}
      onSubmit={onSubmit}
    />
  </div>
);

AccountDetails.propTypes = {
  inputs: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  adding: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  uploadLabSignaturePath: PropTypes.string.isRequired,
};
AccountDetails.defaultProps = {
  inputs: {},
  disabled: false,
  onChange: () => null,
};
export default AccountDetails;
