import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery, useApolloClient } from "@apollo/client";
import { SINGLE_BATCH_QUERY } from "../../../../../graphql";
import { labelBuilder } from "../../../../../utils";
import "../LabelDisplayPage/LabelDisplayPage.scss";

const BatchLabelDisplayPage = () => {
  const { entity } = useParams();
  const client = useApolloClient();
  const [getBatchInfo, { data: batchData }] = useLazyQuery(SINGLE_BATCH_QUERY);
  const [data, setData] = useState([]);
  const [fieldName, id] = entity.split("-");

  useEffect(() => {
    if (entity && fieldName) {
      getBatchInfo({
        variables: {
          where: {
            BatchID: parseInt(id, 10),
          },
        },
      });
    }
  }, [entity, fieldName, getBatchInfo, id]);

  useEffect(() => {
    const fetchData = async () => {
      if (batchData?.findUniqueBatches) {
        const { findUniqueBatches: batch } = batchData;
        if (fieldName === "BatchQCSamples") {
          const filteredSamples = batch.TestSamples.filter(
            (testSample) => testSample.QCSample && testSample.QCSample.SessionID
          ).map((testSample) => {
            const ids = testSample.QCSample.SessionID.split("_");
            return {
              SessionID: testSample.QCSample.SessionID,
              information: [testSample.OrderName, ids[1], ids[2]],
            };
          });
          setData(filteredSamples);
        } else if (fieldName === "All") {
          const filteredSamples = batch.TestSamples.filter(
            (testSample) =>
              testSample.JobOrderTestSample?.SessionID ||
              testSample.QCSample?.SessionID
          ).map((testSample) => {
            if (testSample.JobOrderTestSample?.SessionID) {
              const ids = testSample.JobOrderTestSample.SessionID.split("_");
              const jobOrderID = ids[1];
              const jobOrderTestIdWithPrepNo = ids[3]
                ? `${ids[2]}_${ids[3]}`
                : ids[2];
              const testCode =
                testSample.JobOrderTestSample.JobOrderTest?.Test?.Code;
              return {
                SessionID: testSample.JobOrderTestSample.SessionID,
                information: [jobOrderID, jobOrderTestIdWithPrepNo, testCode],
              };
            }
            const ids = testSample.QCSample.SessionID.split("_");
            return {
              SessionID: testSample.QCSample.SessionID,
              information: [testSample.OrderName, ids[1], ids[2]],
            };
          });
          setData([...filteredSamples]);
        } else if (fieldName === "BatchUnknownSamples") {
          const filteredSamples = batch.TestSamples.filter(
            (testSample) =>
              testSample.JobOrderTestSample &&
              testSample.JobOrderTestSample.SessionID
          ).map((testSample) => {
            const ids = testSample.JobOrderTestSample.SessionID.split("_");
            const jobOrderID = ids[1];
            const jobOrderTestIdWithPrepNo = ids[3]
              ? `${ids[2]}_${ids[3]}`
              : ids[2];
            const testCode =
              testSample.JobOrderTestSample.JobOrderTest?.Test?.Code;
            return {
              SessionID: testSample.JobOrderTestSample.SessionID,
              information: [jobOrderID, jobOrderTestIdWithPrepNo, testCode],
            };
          });
          setData(filteredSamples);
        }
      }
    };
    fetchData();
  }, [batchData, client, fieldName]);

  return (
    <div className="labels-wrapper">
      <div className="label-repeat-grid vertical">
        {data.map(({ SessionID, information }) =>
          labelBuilder(SessionID, information)
        )}
      </div>
    </div>
  );
};

export default BatchLabelDisplayPage;
