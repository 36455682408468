/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Icon, Button } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { startOfMonth, endOfMonth } from "date-fns";
import { useApolloClient } from "@apollo/client";

import { DataTable, ListSearchInput } from "../../../../components";
import {
  LIST_PRIVATE_LABEL_COMMISSIONS_QUERY,
  GENERATE_COA_QUERY,
} from "../../../../graphql";
import { COLUMNS, generateSubColumns } from "./columns";
import PageHeader from "../../../../components/PageHeader";
import Table from "../../../../components/DataTable/Table";
import PrivateLabelCommissionsFilters from "../components/PrivateLabelCommissionsFilters";
import { debounce } from "../../../../utils";
import { useAuth } from "../../../../context";

const { REACT_APP_COA_URL } = process.env;

const INITIAL_STATE = {
  PurchaseDate: {
    gte: startOfMonth(new Date()),
    lte: endOfMonth(new Date()),
  },
};

const PrivateLabelCommissionsPage = (props) => {
  const [where, setWhere] = useState(INITIAL_STATE);
  const [showFilters, setShowFilters] = useState(false);
  const [lab, setLab] = useState("");

  const orderBy = [{ id: "Username", desc: false }];
  const client = useApolloClient();
  const { state: authState } = useAuth();

  useEffect(() => {
    setLab(authState.user.Lab);
    if (authState.user.Lab?.Company !== "All Location") {
      setWhere((prev) => ({
        ...prev,
        LabID: {
          equals: parseInt(authState.user.LabID, 10),
        },
      }));
    }
  }, [authState]);

  const columns = React.useMemo(
    () => [
      ...COLUMNS,
      {
        id: "expander",
        Cell: ({ row, rows, toggleRowExpanded }) =>
          row.canExpand && row.original.JobOrdersCommissions.length ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 2}rem`,
                },
                onClick: async () => {
                  const expandedRow = await rows.find(
                    (currentRow) =>
                      !!currentRow.isExpanded && currentRow.id !== row.id
                  );

                  if (expandedRow) {
                    toggleRowExpanded(expandedRow.id, false);
                  }
                  row.toggleRowExpanded();
                },
              })}
            >
              <Icon>
                <FontAwesomeIcon
                  icon={row.isExpanded ? "chevron-up" : "chevron-down"}
                />
              </Icon>
            </span>
          ) : null,
      },
    ],
    []
  );

  const handleFilterChange = (name, value) => {
    setWhere((prev) => {
      if (value) {
        return {
          ...prev,
          [name]: value,
        };
      }
      return Object.keys(prev)
        .filter((x) => x !== name)
        .reduce((a, c) => ({ ...a, [c]: prev[c] }), {});
    });
  };

  const onLabChange = (name, value) => {
    setWhere((prev) => ({
      ...prev,
      LabID: {
        ...value,
      },
    }));
  };

  const handleFiltersReset = () => {
    if (authState.user.Lab?.Company !== "All Location") {
      setWhere({
        ...INITIAL_STATE,
        LabID: {
          equals: parseInt(authState.user.LabID, 10),
        },
      });
    } else {
      setWhere(INITIAL_STATE);
    }
  };

  const toggleFilters = (e) => {
    e.preventDefault();
    setShowFilters((prev) => !prev);
  };

  const handleSearchInputChange = (value) => {
    if (value) {
      setWhere((prev) => ({
        ...prev,
        COAID: {
          equals: parseInt(value, 10) || undefined,
        },
        JobOrderID: {
          equals: parseInt(value, 10) || undefined,
        },
      }));
    } else {
      setWhere((prev) => INITIAL_STATE);
    }
  };
  const handleDownloadCOA = async (jobOrderID, COAID) => {
    const { data: GenerateCOAData } = await client.query({
      query: GENERATE_COA_QUERY,
      variables: {
        jobOrderId: parseInt(jobOrderID, 10),
        coaId: parseInt(COAID, 10),
      },
      fetchPolicy: "network-only",
    });

    const coa = GenerateCOAData?.generateCertificateOfAnalysis;

    if (coa?.version) {
      window.open(
        `${REACT_APP_COA_URL}/plcoa/pdf/${jobOrderID}/${coa.version}?cachebuster=1?coaId=${COAID}`,
        "_blank"
      );
    }
  };

  const SUB_COLUMNS = generateSubColumns(handleDownloadCOA);

  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <Table
        isSubRow
        columns={SUB_COLUMNS}
        data={row.original.JobOrdersCommissions}
      />
    ),
    [SUB_COLUMNS]
  );

  return (
    <div className="private-label-commissions-list-page">
      <PageHeader title="Private Label Commissions">
        <ListSearchInput
          onChange={debounce((name, value) => {
            handleSearchInputChange(value);
          }, 500)}
        />
        <Button color="primary" onClick={toggleFilters}>
          <Icon>
            <FontAwesomeIcon icon="filter" />
          </Icon>
        </Button>
      </PageHeader>
      {showFilters && (
        <PrivateLabelCommissionsFilters
          handleFilterChange={handleFilterChange}
          handleFiltersReset={handleFiltersReset}
          inputs={where}
          lab={lab}
          onLabChange={onLabChange}
        />
      )}
      <DataTable
        useSubRows
        columns={columns}
        name="PrivateLabelCommissions"
        orderBy={orderBy}
        query={LIST_PRIVATE_LABEL_COMMISSIONS_QUERY}
        renderRowSubComponent={renderRowSubComponent}
        where={where}
      />
    </div>
  );
};

PrivateLabelCommissionsPage.propTypes = {};

export default PrivateLabelCommissionsPage;
