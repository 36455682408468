/* eslint-disable react/destructuring-assignment */
import React from "react";
import { TableCell } from "../../../../../components/DataTable/TableCell";

const DEFAULT_COLUMNS = [
  {
    Header: "Name",
    id: "Name",
    accessor: "Name",
  },
  {
    Header: "Value",
    id: "Value",
    accessor: "Value",
  },
  {
    Header: "Quantity of Samples",
    id: "JobOrders",
    type: "number",
    accessor: "JobOrders",
    disableSortBy: true,
    Cell: (obj) => <TableCell.Number value={obj.cell.value.length} />,
  },
];

export const generateColumns = (handleDelete) => [...DEFAULT_COLUMNS];

export default DEFAULT_COLUMNS;
