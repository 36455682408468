import React from "react";
import PropTypes from "prop-types";

import { Control, Input, Label } from "rbx";

import { debounce } from "../../utils";

const FilterInput = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  getValue,
  setValue,
}) => {
  const handleChange = debounce((e) => {
    onChange(e.target.name, setValue(e.target.value));
  }, 500);

  return (
    <Control expanded>
      <Label>{label}</Label>
      <Input
        key={getValue(value)}
        defaultValue={getValue(value)}
        disabled={disabled}
        name={name}
        size={size}
        type="text"
        onChange={handleChange}
      />
    </Control>
  );
};

FilterInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
};

FilterInput.defaultProps = {
  value: "",
  name: "",
  label: "",
  size: "small",
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
};

export default FilterInput;
