import React from "react";
import PropTypes from "prop-types";
import { Box, Column, Input, Label, Control, Field, Radio } from "rbx";

import { SystemCodeSelect } from "../../../../../../components";

const PerUnitCalculatorForm = ({
  inputs,
  onChange,
  disabled,
  onSubmit,
  formId,
  adding,
  jobOrderInfo,
  JobOrderID,
}) => {
  const handleChange = (e) => {
    onChange(e.target.name, e.target.value);
  };

  const handleRadioChange = (e) => {
    onChange(e.target.name, e.target.labels[0].innerText.trim());
  };

  return (
    <form id={formId} onSubmit={onSubmit}>
      <Box>
        <Column.Group multiline>
          <Column size={12}>
            <Field kind="group">
              <Control expanded size="small" style={{ width: "33%" }}>
                <Label>Job Order ID</Label>
                <Input
                  disabled
                  placeholder="Job Order ID"
                  size="small"
                  value={JobOrderID}
                />
              </Control>
              <Control expanded size="small" style={{ width: "33%" }}>
                <SystemCodeSelect
                  disabled
                  label="Product Category"
                  name="ProductCategory"
                  size="small"
                  value={jobOrderInfo.ProductCategory}
                  where={{
                    Type: { equals: "Product" },
                    Category: { equals: "Category" },
                    CodeName: { equals: "ProductCategory" },
                  }}
                />
              </Control>
              <Control expanded size="small" style={{ width: "33%" }}>
                <SystemCodeSelect
                  disabled
                  label="Substance Type"
                  name="SubstanceType"
                  size="small"
                  value={jobOrderInfo.SubstanceType}
                  where={{
                    Type: { equals: "Substance" },
                    Category: { equals: "Type" },
                    CodeName: { equals: "SubstanceType" },
                  }}
                />
              </Control>
            </Field>
            <Field kind="group">
              <Control expanded size="small" style={{ width: "33%" }}>
                <Label>Structural Rigidity</Label>
                <Label>
                  <Radio
                    checked={inputs.StructuralRigidity === "Solid"}
                    disabled={disabled}
                    name="StructuralRigidity"
                    value={inputs.StructuralRigidity}
                    onChange={handleRadioChange}
                  />{" "}
                  Solid
                </Label>
                <Label>
                  <Radio
                    checked={inputs.StructuralRigidity === "Liquid"}
                    disabled={disabled}
                    name="StructuralRigidity"
                    value={inputs.StructuralRigidity}
                    onChange={handleRadioChange}
                  />{" "}
                  Liquid
                </Label>
              </Control>
              <Control expanded size="small" style={{ width: "33%" }}>
                <Label>Serving Weight/Volume</Label>
                <Input
                  required
                  disabled={disabled}
                  name="Weight"
                  placeholder="Weight"
                  size="small"
                  type="number"
                  value={inputs.Weight}
                  onChange={handleChange}
                />
              </Control>
              <Control expanded size="small" style={{ width: "33%" }}>
                <Label>Number of Servings per Package</Label>
                <Input
                  required
                  disabled={disabled}
                  name="Units"
                  placeholder="Servings"
                  size="small"
                  type="number"
                  value={inputs.Units}
                  onChange={handleChange}
                />
              </Control>
            </Field>
            <Field kind="group">
              <Control expanded size="small" style={{ width: "50%" }}>
                <Label>
                  Display Name <small>(Ex: unit, package, pill,...)</small>
                </Label>
                <Input
                  required
                  disabled={disabled}
                  name="DisplayName"
                  placeholder="Display Name"
                  size="small"
                  value={inputs.DisplayName}
                  onChange={handleChange}
                />
              </Control>
              <Control expanded size="small" style={{ width: "33%" }}>
                <Label>Density</Label>
                <Input
                  required
                  disabled={disabled}
                  name="Density"
                  placeholder="Density"
                  size="small"
                  type="number"
                  value={inputs.Density}
                  onChange={handleChange}
                />
              </Control>
            </Field>
          </Column>
        </Column.Group>
      </Box>
    </form>
  );
};

PerUnitCalculatorForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  formId: PropTypes.string.isRequired,
  adding: PropTypes.bool,
  jobOrderInfo: PropTypes.object.isRequired,
  JobOrderID: PropTypes.string.isRequired,
};

PerUnitCalculatorForm.defaultProps = {
  onSubmit: () => null,
  disabled: false,
  adding: false,
};

export default PerUnitCalculatorForm;
