import { gql } from "@apollo/client";

export const CREATE_USER_PROFICIENCY_MUTATION = gql`
  mutation CREATE_USER_PROFICIENCY_MUTATION(
    $data: UserProficienciesCreateInput!
  ) {
    createUserProficiencies(data: $data) {
      RecId
      UserID
      ProficiencyID
    }
  }
`;
export const UPDATE_USER_PROFICIENCY_MUTATION = gql`
  mutation UPDATE_USER_PROFICIENCY_MUTATION(
    $data: UserProficienciesCreateInput!
  ) {
    createUserProficiencies(data: $data) {
      RecId
      UserID
      ProficiencyID
    }
  }
`;

export const DELETE_USER_PROFICIENCY_MUTATION = gql`
  mutation DELETE_USER_PROFICIENCY_MUTATION(
    $where: UserProficienciesWhereUniqueInput!
  ) {
    deleteUserProficiencies(where: $where) {
      RecId
    }
  }
`;
