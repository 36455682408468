import { gql } from "@apollo/client";

export const UPDATE_PACKAGE_MUTATION = gql`
  mutation UPDATE_PACKAGE_MUTATION(
    $data: PackagesUpdateInput!
    $where: PackagesWhereUniqueInput!
  ) {
    updatePackages(data: $data, where: $where) {
      PackageID
    }
  }
`;

export const CREATE_PACKAGE_MUTATION = gql`
  mutation CREATE_PACKAGE_MUTATION($data: PackagesCreateInput!) {
    createPackages(data: $data) {
      PackageID
    }
  }
`;

export const DELETE_PACKAGE_MUTATION = gql`
  mutation DELETE_PACKAGE_MUTATION($where: PackagesWhereUniqueInput!) {
    deletePackages(where: $where) {
      PackageID
    }
  }
`;

export const DELETE_PACKAGE_WITH_TESTS_MUTATION = gql`
  mutation DELETE_PACKAGE_WITH_TESTS_MUTATION(
    $where: PackagesWhereUniqueInput!
  ) {
    deletePackageWithPackageTests(where: $where) {
      PackageID
    }
  }
`;

export const UPDATE_PACKAGE_TEST_MUTATION = gql`
  mutation UPDATE_PACKAGE_TEST_MUTATION(
    $data: PackageTestsUpdateInput!
    $where: PackageTestsWhereUniqueInput!
  ) {
    updatePackageTests(data: $data, where: $where) {
      PackageTestID
    }
  }
`;

export const CREATE_PACKAGE_TEST_MUTATION = gql`
  mutation CREATE_PACKAGE_TEST_MUTATION($data: PackageTestsCreateInput!) {
    createPackageTests(data: $data) {
      PackageTestID
    }
  }
`;

export const DELETE_PACKAGE_TEST_MUTATION = gql`
  mutation DELETE_PACKAGE_TEST_MUTATION($where: PackageTestsWhereUniqueInput!) {
    deletePackageTests(where: $where) {
      PackageTestID
    }
  }
`;

export const CLONE_PACKAGE_FROM_TEMPLATE_MUTATION = gql`
  mutation CLONE_PACKAGE_FROM_TEMPLATE_MUTATION($data: PackagesCreateInput!) {
    clonePackageFromTemplate(data: $data) {
      PackageID
    }
  }
`;
