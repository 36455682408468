import { gql } from "@apollo/client";

export const CREATE_PRIVATE_LABEL_COA_MUTATION = gql`
  mutation CREATE_PRIVATE_LABEL_COA_MUTATION(
    $data: PrivateLabelCOAsCreateInput!
  ) {
    createPrivateLabelCOAs(data: $data) {
      COAID
    }
  }
`;

export const ORDER_PRIVATE_LABEL_COA_MUTATION = gql`
  mutation ORDER_PRIVATE_LABEL_COA_MUTATION(
    $data: PrivateLabelCOAsCreateInput!
  ) {
    orderPrivateLabelCOA(data: $data) {
      COAID
    }
  }
`;
