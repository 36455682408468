import React from "react";
import PropTypes from "prop-types";
import { Control, Input, Label } from "rbx";

import { formatCurrency, unmaskCurrency } from "../../utils";

const CurrencyInput = ({
  label,
  name,
  value,
  size,
  onChange,
  disabled,
  required,
}) => {
  const handleChange = (e) => {
    onChange(e.target.name, unmaskCurrency(e.target.value));
  };

  return (
    <Control>
      <Label>{label}</Label>
      <Input
        autoComplete="new"
        disabled={disabled}
        name={name}
        required={required}
        size={size}
        type="text"
        value={formatCurrency(value)}
        onChange={handleChange}
      />
    </Control>
  );
};

CurrencyInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  name: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

CurrencyInput.defaultProps = {
  value: "",
  label: "",
  name: "",
  size: "small",
  disabled: false,
  required: false,
  onChange: () => null,
};
export default CurrencyInput;
