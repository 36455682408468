/* eslint-disable max-lines-per-function */
/* eslint-disable no-param-reassign */
/* eslint-disable no-extra-boolean-cast */
import React, { useState, useEffect } from "react";
import { Button } from "rbx";
import { useMutation, useApolloClient } from "@apollo/client";
import PropTypes from "prop-types";
import { omit, groupBy } from "lodash";

import { customToast as toast, deepFlatten } from "../../../../../utils";
import { microResultImportMapping } from "../../../../../utils/batchConstants";
import { PageHeader } from "../../../../../components";
import Loader from "../../../../../components/Loader";
import { ImportResultsDropZone, ImportResultsFileList } from "../components";
import {
  UPDATE_DIFFERENT_JOB_ORDER_TEST_SAMPLES,
  SINGLE_BATCH_QUERY,
  ALL_QUALIFIERS_QUERY,
  UPDATE_SAMPLE_SYSTEM_QUALIFIERS_MUTATION,
  ALL_JOB_ORDER_TEST_SAMPLES_QUERY,
  ALL_QC_SAMPLES_QUERY,
} from "../../../../../graphql";
import { useAuth } from "../../../../../context";
import { AssignSystemQualifiers } from "../../BatchRecords/BuildBatchResults/BatchFunctions";

const ImportResultsPage = ({ routePermissions }) => {
  const client = useApolloClient();
  const { state: authState } = useAuth();

  const [canUpdate, setCanUpdate] = useState(true);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [approvedSamples, setApprovedSamples] = useState({
    testSamples: [],
    qCSamples: [],
  });

  const [updateDifferentJobOrderTestSamples] = useMutation(
    UPDATE_DIFFERENT_JOB_ORDER_TEST_SAMPLES
  );

  const [updateSystemQualifiers] = useMutation(
    UPDATE_SAMPLE_SYSTEM_QUALIFIERS_MUTATION
  );

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  const handleChange = async (name, value) => {
    if (Array.isArray(value)) {
      let isPest = false;
      let isSolv = false;
      let isMicroMg = false;
      try {
        const flat = deepFlatten(value);
        const allSessionIds = flat.map((element) => element.sessionId);

        const { data: allJobOrderTestSamplesData } = await client.query({
          query: ALL_JOB_ORDER_TEST_SAMPLES_QUERY,
          variables: {
            where: {
              SessionID: { in: allSessionIds },
            },
          },
        });
        const { data: allQCSamplesData } = await client.query({
          query: ALL_QC_SAMPLES_QUERY,
          variables: {
            where: {
              SessionID: { in: allSessionIds },
            },
          },
        });

        const sampleWithDifferentLab = allQCSamplesData.findManyQCSamples.find(
          (qcSample) => qcSample.Batches?.LabID !== authState?.user?.LabID
        );

        const jobOrderWithDifferentLab =
          allJobOrderTestSamplesData.findManyJobOrderTestSamples.find(
            (jobOrder) => jobOrder.Batches?.LabID !== authState?.user?.LabID
          );
        if (sampleWithDifferentLab || jobOrderWithDifferentLab) {
          toast.error("Some of uploaded files belongs to a different lab");
          return;
        }

        const approvedTestSamples =
          allJobOrderTestSamplesData.findManyJobOrderTestSamples
            .filter(
              (sample) => sample?.ResultStatus?.CodeDescription === "Approved"
            )
            .map((item) => {
              const element = flat.find(
                (flatElement) => item.SessionID === flatElement.sessionId
              );
              return {
                ...item,
                type: element.type || "",
              };
            });

        const approvedQCSamples = allQCSamplesData.findManyQCSamples
          .filter(
            (sample) => sample?.ResultStatus?.CodeDescription === "Approved"
          )
          .map((item) => {
            const element = flat.find(
              (flatElement) => item.SessionID === flatElement.sessionId
            );
            return {
              ...item,
              type: element.type || "",
            };
          });

        setApprovedSamples({
          testSamples: approvedTestSamples,
          qCSamples: approvedQCSamples,
        });

        // add id to identify file in case of duplicate
        const flatArrWithTypeDetermined = await Promise.all(
          flat.map(async (element) => {
            const foundElement =
              element.sessionId.includes("99_99_") ||
              /^[a-z]/i.test(element.sessionId)
                ? allQCSamplesData?.findManyQCSamples?.find(
                    (qcSample) => qcSample.SessionID === element.sessionId
                  )
                : allJobOrderTestSamplesData?.findManyJobOrderTestSamples?.find(
                    (testSample) => testSample.SessionID === element.sessionId
                  );

            if (!foundElement) {
              throw new Error(`Session ID ${element.sessionId} not found.`);
            }
            if (!foundElement.Batches) {
              throw new Error(
                `Please add ${element.sessionId} to a batch before importing results.`
              );
            }
            if (foundElement?.ResultStatus?.CodeDescription !== "Approved") {
              const newObj = { ...element };
              if (element.type === "To determine") {
                newObj.type = foundElement.Batches?.TestCategory?.Code;
              }

              if (/.*PEST/.test(newObj.type.toUpperCase())) {
                isPest = true;
                newObj.labState = foundElement?.Batches?.Lab?.State;
              } else if (/.*SOLV/.test(newObj.type.toUpperCase())) {
                isSolv = true;
                newObj.labState = foundElement?.Batches?.Lab?.State;
              } else if (/.*MICRO MG/.test(newObj.type.toUpperCase())) {
                isMicroMg = true;
                if (
                  foundElement.QCSampleID &&
                  foundElement.PrimarySampleSessionID
                ) {
                  const { data: primarySampleData } = await client.query({
                    query: ALL_JOB_ORDER_TEST_SAMPLES_QUERY,
                    variables: {
                      where: {
                        SessionID: {
                          equals: foundElement.PrimarySampleSessionID,
                        },
                      },
                    },
                  });
                  const primarySample =
                    primarySampleData.findManyJobOrderTestSamples.find(
                      (testSample) =>
                        testSample.SessionID ===
                        foundElement.PrimarySampleSessionID
                    );

                  newObj.testAnalytes =
                    primarySample?.JobOrderTest?.Test?.TestAnalytes?.filter(
                      (item) =>
                        item?.Analyte?.Active &&
                        item?.Analyte?.TestCategoryID ===
                          primarySample?.Batches?.TestCategory?.TestCategoryID
                    ).map((item) => item?.Analyte?.Name);
                } else {
                  newObj.testAnalytes =
                    foundElement?.JobOrderTest?.Test?.TestAnalytes?.filter(
                      (item) =>
                        item?.Analyte?.Active &&
                        item?.Analyte?.TestCategoryID ===
                          foundElement?.Batches?.TestCategory?.TestCategoryID
                    ).map((item) => item?.Analyte?.Name);
                }
              } else if (/.*MICRO 3M/.test(newObj.type.toUpperCase())) {
                if (
                  foundElement.QCSampleID &&
                  foundElement.PrimarySampleSessionID
                ) {
                  const { data: primarySampleData } = await client.query({
                    query: ALL_JOB_ORDER_TEST_SAMPLES_QUERY,
                    variables: {
                      where: {
                        SessionID: {
                          equals: foundElement.PrimarySampleSessionID,
                        },
                      },
                    },
                  });
                  const primarySample =
                    primarySampleData.findManyJobOrderTestSamples.find(
                      (testSample) =>
                        testSample.SessionID ===
                        foundElement.PrimarySampleSessionID
                    );

                  const testAnalyteName =
                    primarySample?.JobOrderTest?.Test?.TestAnalytes?.find(
                      (item) =>
                        item?.Analyte?.Active &&
                        item?.Analyte?.TestCategoryID ===
                          primarySample?.Batches?.TestCategory?.TestCategoryID
                    )?.Analyte?.Name;
                  newObj.result = { [testAnalyteName]: element.result };
                } else {
                  const testAnalyteName =
                    foundElement?.JobOrderTest?.Test?.TestAnalytes?.find(
                      (item) =>
                        item?.Analyte?.Active &&
                        item?.Analyte?.TestCategoryID ===
                          foundElement?.Batches?.TestCategory?.TestCategoryID
                    )?.Analyte?.Name;
                  newObj.result = { [testAnalyteName]: element.result };
                }
              }
              return newObj;
            }
            return null;
          })
        );

        const filteredItems = flatArrWithTypeDetermined.filter((item) => item);

        // Micro Bmx Genup: sessionIDs can exist within several files
        // group these Micro Bmx Genup results by sessionID and format results
        const microBmxGenupFilesGroupedBySample = groupBy(
          filteredItems?.filter((sample) => sample.type === "MICRO BMX GENEUP"),
          "sessionId"
        );
        const microBmxGenupSamples = [];
        Object.entries(microBmxGenupFilesGroupedBySample).forEach(
          ([key, valArr]) => {
            const result = valArr.reduce(
              (prev, curr) => ({ ...prev, ...curr.result }),
              {}
            );

            microBmxGenupSamples.push({
              sessionId: key,
              type: valArr[0].type,
              result,
            });
          }
        );

        // results other than Micro Bmx results do not need to be grouped
        const samplesExcludingBmxGenup = filteredItems?.filter(
          (sample) => sample.type !== "MICRO BMX GENEUP"
        );

        // Micro Bmx samples formatted along with all other results, then apply any rules
        let arrWithRulesApplied = [
          ...samplesExcludingBmxGenup,
          ...microBmxGenupSamples,
        ];

        if (isPest || isSolv || isMicroMg) {
          const pestRegexDeletionArr = [
            /-ISTD$/,
            /-5$/,
            /-D3$/,
            /-D4$/,
            /-D5$/,
            /-D6$/,
            /-D7$/,
            /-D10$/,
            /\SD9$/,
            /\S13C4$/,
          ];

          const solvRegexDeletionArr = [
            /4-BROMOFLUOROBENZENE ISTD$/,
            /BENZENE D6 ISTD$/,
            /CHLOROBENZENE ISTD$/,
          ];

          arrWithRulesApplied = filteredItems?.map((obj) => {
            // need to check type again since we can have diff types in files already
            if (/.*PEST/.test(obj.type.toUpperCase())) {
              // pest rules
              const resultsObj = { ...obj.result };
              const propsToDelete = [];

              Object.keys(resultsObj).forEach((key) => {
                let summedName = "";
                let changedName = "";

                pestRegexDeletionArr.forEach((item) => {
                  if (item.test(key.toUpperCase())) {
                    propsToDelete.push(key);
                  }
                });

                if (
                  /CYFLUTHRIN-/.test(key.toUpperCase()) &&
                  key.toLocaleUpperCase().startsWith("CYFLUTHRIN-")
                ) {
                  summedName = "Cyfluthrin";
                } else if (
                  /CYPERMETHRIN-/.test(key.toUpperCase()) &&
                  key.toLocaleUpperCase().startsWith("CYPERMETHRIN-")
                ) {
                  summedName = "Cypermethrin";
                } else if (/.*CHLORDANE.*/.test(key.toUpperCase())) {
                  summedName = "Chlordane";
                } else if (/.*SPINOSAD.*/.test(key.toUpperCase())) {
                  summedName = "Spinosad";
                }

                if (key.toUpperCase().trim() === "PYRETHRINS (PYRETHRIN I)") {
                  changedName = "Pyrethrins";
                } else if (key.toUpperCase().trim() === "DIAZANON") {
                  changedName = "Diazinon";
                } else if (key.toUpperCase().trim() === "PARATHION-METHYL") {
                  changedName = "Methyl parathion";
                } else if (key.toUpperCase().trim() === "CYPERMETHRINS") {
                  changedName = "Cypermethrin";
                } else if (key.toUpperCase().trim() === "PERMETHRIN") {
                  changedName = "Permethrins";
                }

                if (!!changedName.length) {
                  resultsObj[changedName] = resultsObj[key];
                  propsToDelete.push(key);
                } else if (!!summedName.length) {
                  resultsObj[summedName] =
                    !!resultsObj[summedName] && key !== summedName
                      ? resultsObj[summedName] + resultsObj[key]
                      : resultsObj[key];
                  if (key !== summedName) {
                    propsToDelete.push(key);
                  }
                }
              });

              const newResultsObj = omit(resultsObj, propsToDelete);
              delete obj.labState;
              return {
                ...obj,
                result: newResultsObj,
              };
            }

            if (/.*SOLV/.test(obj.type.toUpperCase())) {
              // solv rules
              const resultsObj = { ...obj.result };
              const propsToDelete = [];

              Object.keys(resultsObj).forEach((key) => {
                let changedName = "";

                solvRegexDeletionArr.forEach((item) => {
                  if (item.test(key.toUpperCase())) {
                    propsToDelete.push(key);
                  }
                });

                // CHANGE!
                if (key.toUpperCase().trim() === "1,2- DICHLOROETHANE") {
                  changedName = "1,2-Dichloroethane";
                }
                if (key.toUpperCase().trim() === "N-HEPTANE") {
                  changedName = "Heptane";
                }
                if (
                  key.toUpperCase().trim() === "METHYL CHLORIDE" ||
                  key.toUpperCase().trim() === "DICHLOROMETHANE"
                ) {
                  changedName = "Methylene Chloride";
                }
                if (key.toUpperCase().trim() === "TRICHLOROETHENE") {
                  changedName = "Trichloroethylene";
                }
                if (key.toUpperCase().trim() === "ISOBUTANE") {
                  changedName = "iso-Butane";
                }
                if (key.toUpperCase().trim() === "ISOPENTANE") {
                  changedName = "iso-Pentane";
                }
                if (key.toUpperCase().trim() === "NEOPENTANE") {
                  changedName = "neo-Pentane";
                }

                if (
                  obj.labState !== "AZ" &&
                  key.toUpperCase().trim() === "N-HEXANE"
                ) {
                  changedName = "Hexanes";
                }

                if (!!changedName.length) {
                  resultsObj[changedName] = resultsObj[key];
                  propsToDelete.push(key);
                }
              });

              const newResultsObj = omit(resultsObj, propsToDelete);
              delete obj.labState;
              return {
                ...obj,
                result: newResultsObj,
              };
            }

            if (/.*MICRO MG/.test(obj.type.toUpperCase())) {
              const resultObj = {};
              const mappedNames = obj.testAnalytes.map((analyteName) => ({
                channel: microResultImportMapping[analyteName],
                analyteName,
              }));

              mappedNames.forEach(({ channel, analyteName }) => {
                resultObj[analyteName] = obj.result[channel];
              });

              return {
                type: obj.type,
                sessionId: obj.sessionId,
                result: { ...resultObj },
              };
            }
            return obj;
          });
        }

        const flatWithId = arrWithRulesApplied.map((item, i) => ({
          ...item,
          id: i,
        }));
        setFiles(flatWithId);
      } catch (err) {
        toast.error(err.message);
      }
    }
  };

  // save qualifiers to db per batch
  const saveSystemQualifiers = async (batchID) => {
    const query = {
      query: SINGLE_BATCH_QUERY,
      variables: {
        where: {
          BatchID: parseInt(batchID, 10),
        },
      },
      fetchPolicy: "network-only",
    };
    const {
      data: { findUniqueBatches },
    } = await client.query(query);
    const {
      data: { findManyQualifiers },
    } = await client.query({
      query: ALL_QUALIFIERS_QUERY,
      variables: {},
    });

    if (findUniqueBatches) {
      const parsedResults = findUniqueBatches?.TestSamples?.map((res) => {
        const lowercaseKeys = (obj) => {
          if (!obj) {
            return {};
          }
          return Object.keys(obj).reduce((acc, key) => {
            acc[key.toLowerCase().trim()] = obj[key];
            return acc;
          }, {});
        };
        return {
          ...res,
          ...res.JobOrderTestSample,
          ...res.QCSample,
          AnalyteResults: res?.JobOrderTestSample?.Result
            ? lowercaseKeys(res?.JobOrderTestSample?.Result)
            : lowercaseKeys(res?.QCSample?.Result),
          TargetValues: res?.QCSample?.TargetValues
            ? lowercaseKeys(res?.QCSample?.TargetValues)
            : null,
          QCTarget: {
            TargetValues: res?.QCTarget?.TargetValues
              ? lowercaseKeys(res?.QCTarget?.TargetValues)
              : null,
          },
        };
      });
      const systemQualifiers = AssignSystemQualifiers(
        parsedResults,
        findManyQualifiers,
        findUniqueBatches?.Lab?.State
      );

      await updateSystemQualifiers({
        variables: {
          data: {
            SystemQualifiers: systemQualifiers,
          },
          refetchQueries: [query],
        },
      });
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const filesWithoutId = files.map(({ id, ...keepAttrs }) => keepAttrs);

      const data = filesWithoutId.map(({ result, sessionId, type }) => ({
        Result: JSON.stringify(result),
        SessionID: sessionId,
        ModifiedBy: authState.user.Username,
        ImportedBy: authState.user.Username,
      }));

      const { data: returnData } = await updateDifferentJobOrderTestSamples({
        variables: {
          data,
        },
      });

      // update qualifiers for each associated batch
      if (returnData.updateDifferentJobOrderTestSamples?.length) {
        await returnData.updateDifferentJobOrderTestSamples.forEach(
          async (batchID) => {
            if (batchID) {
              await saveSystemQualifiers(batchID);
            }
          }
        );
      }
      toast.success("Results saved successfully");
      setFiles([]);
    } catch (err) {
      toast.error("Error saving results");
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setFiles([]);
    setApprovedSamples({
      testSamples: [],
      qCSamples: [],
    });
  };

  const handleRemoveFile = (item) => {
    setFiles((prev) => prev.filter((file) => file.id !== item.id));
  };

  const handleRemoveApprovedFile = (name, sessionId) => {
    setApprovedSamples((prev) => ({
      ...prev,
      [name]: prev?.[name]?.filter((item) => item.SessionID !== sessionId),
    }));
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="import-results-page">
      <PageHeader title="Import Results">
        <Button
          color="primary"
          disabled={!canUpdate || !files.length}
          onClick={handleSave}
        >
          Save
        </Button>
        <Button color="primary" disabled={!canUpdate} onClick={handleReset}>
          Reset
        </Button>
      </PageHeader>
      <div className="import-results-container">
        <ImportResultsDropZone files={files} onChange={handleChange} />
      </div>
      <ImportResultsFileList
        approvedSamples={approvedSamples}
        files={files}
        removeApprovedFile={handleRemoveApprovedFile}
        removeFile={handleRemoveFile}
      />
    </div>
  );
};

ImportResultsPage.propTypes = {
  routePermissions: PropTypes.array,
};

ImportResultsPage.defaultProps = {
  routePermissions: [],
};

ImportResultsPage.propTypes = {};

export default ImportResultsPage;
