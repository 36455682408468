import React, { useState } from "react";
import PropTypes from "prop-types";
import { Content, Button, Control, Field, Label, Input, Icon, Help } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useModal } from "../../context";

const UpdatePasswordModal = ({ onConfirm, onCancel }) => {
  const { setModalOpen } = useModal();

  const [inputs, setInputs] = useState({
    Password: "",
    ConfirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleCancel = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setModalOpen(false, "");
    if (onCancel && typeof onCancel === "function") {
      onCancel();
    }
  };

  const onChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onConfirm(inputs.Password, inputs.ConfirmPassword);
  };

  const toggleShowPassword = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPassword(!showPassword);
  };

  return (
    <form onSubmit={handleSave}>
      <Content size="medium" textAlign="centered">
        <Field>
          <Control expanded size="small">
            <Label>Password</Label>
            <Input
              required
              maxLength={250}
              name="Password"
              placeholder="Password"
              size="small"
              type={showPassword ? "text" : "password"}
              value={inputs.Password}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
            <Icon className="password-visibility" onClick={toggleShowPassword}>
              <FontAwesomeIcon icon={showPassword ? "eye" : "eye-slash"} />
            </Icon>
          </Control>
        </Field>

        <Field>
          <Control expanded size="small">
            <Label>Confirm Password</Label>
            <Input
              required
              maxLength={250}
              name="ConfirmPassword"
              placeholder="ConfirmPassword"
              size="small"
              type={showPassword ? "text" : "password"}
              value={inputs.ConfirmPassword}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
            <Icon className="password-visibility" onClick={toggleShowPassword}>
              <FontAwesomeIcon icon={showPassword ? "eye" : "eye-slash"} />
            </Icon>
            {inputs.Password !== "" &&
              inputs.ConfirmPassword !== "" &&
              inputs.Password !== inputs.ConfirmPassword && (
                <Help color="danger">Passwords don&lsquo;t match</Help>
              )}
          </Control>
        </Field>
      </Content>

      <Field align="centered" kind="group">
        <Control>
          <Button onClick={handleCancel}>Cancel</Button>
        </Control>
        <Control>
          <Button
            color="primary"
            disabled={inputs.Password !== inputs.ConfirmPassword}
            type="submit"
          >
            Save
          </Button>
        </Control>
      </Field>
    </form>
  );
};

UpdatePasswordModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};
UpdatePasswordModal.defaultProps = {
  onCancel: () => null,
};
export default UpdatePasswordModal;
