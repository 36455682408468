import React from "react";

import { Switch, Route } from "react-router-dom";

import ContactFormPage from "./ContactFormPage";

const ContactRouter = (props) => (
  <Switch>
    <Route path="/contact/:JobID?/:JobOrderID?">
      <ContactFormPage />
    </Route>
  </Switch>
);

export default ContactRouter;
