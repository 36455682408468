import { gql } from "@apollo/client";

export const ALL_CART_QUERY = gql`
  query ALL_CART_QUERY(
    $where: CartWhereInput
    $orderBy: [CartOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    carts(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      CartID
      OrderName
      BatchOrderNumber
      SubstanceType
      ProductCategory
      Tests
      OrderTotal
      StateRetest
      OriginalCOA
      Species
      MMTCInfo
      LotID
      CustomerSampleID
      BatchInfo
      TestItems {
        TestID
        Name
        Code
        Description
        CategoryPrice
        TestTestCategories {
          TestTestCategoryID
          TestCategory {
            TestCategoryID
            Code
          }
        }
      }
      Package {
        PackageID
        UserID
        Name
        CreatedBy
        ModifiedBy
        CreatedDateTime
        ModifiedDateTime
        Price
        DisplayAsTest
        Type {
          RecId
          CodeDescription
        }
        PackageTests {
          PackageTestID
          PackageID
          TestID
          Price
          Order
          CreatedBy
          ModifiedBy
          CreatedDateTime
          ModifiedDateTime
          Test {
            TestID
            Name
            Code
            Description
            Image
            TestTestCategories {
              TestTestCategoryID
              TestCategory {
                TestCategoryID
                Code
              }
            }
          }
        }
      }
    }
  }
`;

export const SINGLE_CART_QUERY = gql`
  query SINGLE_CART_QUERY($where: CartWhereUniqueInput!) {
    cart(where: $where) {
      CartID
      OrderName
      BatchOrderNumber
      SubstanceType
      ProductCategory
      Tests
      ClientNotes
      TestType
      CustomerSampleID
      LotID
      LabelClaim
      MMTCInfo
      BatchInfo
      PackageID
      StateRetest
      OriginalCOA
      Species
      Package {
        PackageID
        UserID
        Name
        Price
        CreatedBy
        ModifiedBy
        CreatedDateTime
        ModifiedDateTime
        DisplayAsTest
        PackageTests {
          PackageTestID
          PackageID
          TestID
          Price
          CreatedBy
          ModifiedBy
          CreatedDateTime
          ModifiedDateTime
          Test {
            TestID
            Name
            Code
            Description
            Image
          }
        }
      }
    }
  }
`;

export const CART_COUNT_QUERY = gql`
  query CART_COUNT_QUERY($where: UsersWhereUniqueInput!) {
    cartCount(where: $where)
  }
`;

export const GET_CART_SUMMARY_QUERY = gql`
  query GET_FIRST_CART_SUMMARY_QUERY(
    $where: CartWhereInput
    $orderBy: [CartOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    carts(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      CartID
      OrderName
      BatchOrderNumber
      SubstanceType
    }
  }
`;
