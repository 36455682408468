import Currency from "./Currency";
import Number from "./Number";
import Percent from "./Percent";
import Link from "./Link";
import Image from "./Image";
import Wrapped from "./Wrapped";

export const TableCell = { Currency, Number, Percent, Link, Image, Wrapped };

export default TableCell;
