const clients = {
  name: "clients",
  label: "Clients",
  icon: "user",
  permissions: ["CL"],
  active: (location) => {
    const [, p] = location.pathname.split("/");
    return /clients/.test(p);
  },
  to: "/clients",
};

export default clients;
