import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const ALL_CREDIT_LEDGER_QUERY = gql`
  query ALL_CREDIT_LEDGER_QUERY(
    $where: CreditLedgerWhereInput
    $orderBy: [CreditLedgerOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    creditLedgers(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      CreditID
      UserID
      JobID
      JobOrderID
      Credit
      Reason
      CreditDate
      CreatedBy
      User {
        UserID
        Username
        LabID
        Lab {
          LabID
          Company
        }
      }
    }
  }
`;
