/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const CREATE_JOB_LOG_MUTATION = gql`
  mutation CREATE_JOB_LOG_MUTATION($data: JobLogsCreateInput!) {
    createJobLogs(data: $data) {
      JobLogID
    }
  }
`;

export const CREATE_JOB_LOGS_MUTATION = gql`
  mutation CREATE_JOB_LOGS_MUTATION($data: [JobLogsCreateManyInput!]!) {
    createManyJobLogs(data: $data) {
      count
    }
  }
`;
