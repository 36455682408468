import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Box, Title } from "rbx";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import BooleanInput from "../../../../components/BooleanInput";

import {
  ALL_USER_GROUPS_QUERY,
  ALL_USER_GROUP_MEMBERSHIPS_QUERY,
  CREATE_USER_GROUP_MEMBERSHIP_MUTATION,
  DELETE_USER_GROUP_MEMBERSHIP_MUTATION,
} from "../../../../graphql";

// import { throttle } from "../../../../utils";
import { useAuth } from "../../../../context";

const EmployeeGroups = ({ UserID, disabled }) => {
  const { state: authState } = useAuth();

  const [createUserGroupMembership] = useMutation(
    CREATE_USER_GROUP_MEMBERSHIP_MUTATION
  );
  const [deleteUserGroupMembership] = useMutation(
    DELETE_USER_GROUP_MEMBERSHIP_MUTATION
  );

  const { data: FindManyUserGroupsData } = useQuery(ALL_USER_GROUPS_QUERY);
  const { data: FindManyUserGroupMembershipsData } = useQuery(
    ALL_USER_GROUP_MEMBERSHIPS_QUERY,
    {
      variables: { where: { UserID: { equals: parseInt(UserID, 10) } } },
    }
  );

  const [userGroups, setUserGroups] = useState([]);

  const [inputs, setInputs] = useState({});

  useEffect(() => {
    const groups = FindManyUserGroupsData?.findManyUserGroups;
    if (Array.isArray(groups)) {
      setUserGroups(groups);
    }
  }, [FindManyUserGroupsData]);

  useEffect(() => {
    const userGroupMemberships =
      FindManyUserGroupMembershipsData?.findManyUserGroupMemberships;
    if (Array.isArray(userGroupMemberships)) {
      setInputs((prev) => ({
        ...prev,
        ...userGroupMemberships.reduce(
          (a, c) => ({ ...a, [c.GroupID]: true }),
          {}
        ),
      }));
    }
  }, [FindManyUserGroupMembershipsData]);

  const handleChangeUserGroup = async (GroupID, value) => {
    setInputs((prev) => ({ ...prev, [GroupID]: value }));

    const date = new Date();

    const refetchQueries = [
      {
        query: ALL_USER_GROUP_MEMBERSHIPS_QUERY,
        variables: {
          where: { UserID: { equals: parseInt(UserID, 10) } },
        },
      },
    ];

    if (value) {
      await createUserGroupMembership({
        variables: {
          data: {
            UserGroup: {
              connect: {
                GroupID: parseInt(GroupID, 10),
              },
            },
            User: {
              connect: {
                UserID: parseInt(UserID, 10),
              },
            },
            CreatedDateTime: date,
            ModifiedDateTime: date,
            CreatedBy: authState?.user?.Username,
            ModifiedBy: authState?.user?.Username,
          },
        },
        refetchQueries,
      });
    } else {
      await deleteUserGroupMembership({
        variables: {
          where: {
            GroupID_UserID: {
              UserID: parseInt(UserID, 10),
              GroupID: parseInt(GroupID, 10),
            },
          },
        },
        refetchQueries,
      });
    }
    toast.success(`User groups updated successfully.`);
  };

  return (
    <Box>
      <Title subtitle>Permissions</Title>
      <div className="employee-proficiencies-container">
        {userGroups.map((userGroup) => (
          <BooleanInput
            key={userGroup.GroupID}
            disabled={disabled}
            label={userGroup.Description}
            name={String(userGroup.GroupID)}
            value={inputs[userGroup.GroupID]}
            onChange={handleChangeUserGroup}
          />
        ))}
      </div>
      {/* <pre>
        <code>{JSON.stringify(proficiencies, null, 2)}</code>
      </pre> */}
    </Box>
  );
};

EmployeeGroups.propTypes = {
  UserID: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

EmployeeGroups.defaultProps = {
  disabled: false,
};

export default EmployeeGroups;
