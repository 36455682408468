/* eslint-disable max-lines-per-function */
/* eslint-disable no-extend-native */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Button } from "rbx";
import { useModal } from "../../../../context/ModalContext";
import { useAuth } from "../../../../context/AuthContext";
import {
  LIST_TESTS_QUERY,
  ALL_PACKAGES_QUERY,
  DELETE_PACKAGE_TEST_MUTATION,
  DELETE_PACKAGE_WITH_TESTS_MUTATION,
  CREATE_PACKAGE_MUTATION,
  UPDATE_PACKAGE_MUTATION,
  CREATE_PACKAGE_TEST_MUTATION,
  UPDATE_PACKAGE_TEST_MUTATION,
} from "../../../../graphql";
import PackagesBoard from "../../components/PackagesBoard";
import Loader from "../../../../components/Loader";
import { customToast as toast } from "../../../../utils";
import Confirmation from "../../../../components/Confirmation";
import PackageTemplateModal from "../../components/PackageTemplateModal";

Array.prototype.move = function move(from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
  return this;
};

const PackageSettings = ({ userID, setLoading, disabled }) => {
  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();
  // This state is to control dashboard on Test Packages Tab. Info:
  // columns: this is where we charge the information to display.
  // ordered: this is the order of columns on dashboard.
  // packages: here we save the IDs of packages to save info correctly.
  // packagePrices: here we store the sum of packages test prices.
  // medicalTypes: here we store the Package.MedicalType for each package.
  const [userTestPackages, setUserTestPackages] = useState({
    columns: {},
    ordered: [],
    packages: [],
    packagePrices: {},
    medicalTypes: {},
    names: {},
  });
  const [getTests, testsData] = useLazyQuery(LIST_TESTS_QUERY);
  const [getPackages, packagesData] = useLazyQuery(ALL_PACKAGES_QUERY);
  const [deletePackage] = useMutation(DELETE_PACKAGE_WITH_TESTS_MUTATION);
  const [deletePackageTest] = useMutation(DELETE_PACKAGE_TEST_MUTATION);
  const [createPackage] = useMutation(CREATE_PACKAGE_MUTATION);
  const [updatePackage] = useMutation(UPDATE_PACKAGE_MUTATION);
  const [createPackageTest] = useMutation(CREATE_PACKAGE_TEST_MUTATION);
  const [updatePackageTest] = useMutation(UPDATE_PACKAGE_TEST_MUTATION);

  useEffect(() => {
    if (userID) {
      getTests({
        variables: {
          where: { Active: { equals: true } },
          orderBy: [{ Name: "asc" }],
        },
      });
      getPackages({
        variables: {
          where: {
            UserID: { equals: parseInt(userID, 10) },
            Type: { is: { CodeDescription: { equals: "User" } } },
          },
          orderBy: [{ CreatedDateTime: "desc" }],
        },
      });
    }
  }, [userID, getTests, getPackages]);

  useEffect(() => {
    if (
      Array.isArray(testsData?.data?.findManyTests) &&
      Array.isArray(packagesData?.data?.findManyPackages)
    ) {
      const packages = packagesData.data.findManyPackages;
      const previousPackages = packages.reduce(
        (previous, current) => ({
          ...previous,
          [current.PackageID]: current.PackageTests.map((pt) => ({
            id: `${current.Name}-${pt.TestID}`,
            content: pt,
          })),
        }),
        {}
      );
      const previousPrices = packages.reduce(
        (previous, current) => ({
          ...previous,
          [current.PackageID]: current.Price || 0,
        }),
        {}
      );
      const previousMedicalTypes = packages.reduce(
        (previous, current) => ({
          ...previous,
          [current.PackageID]: current.MedicalType || null,
        }),
        {}
      );
      const previousDisplayAsTests = packages.reduce(
        (previous, current) => ({
          ...previous,
          [current.PackageID]: current.DisplayAsTest || false,
        }),
        {}
      );
      const previousNames = packages.reduce(
        (previous, current) => ({
          ...previous,
          [current.PackageID]: current.Name,
        }),
        {}
      );
      const previousStates = packages.reduce(
        (previous, current) => ({
          ...previous,
          [current.PackageID]: current.State || null,
        }),
        {}
      );

      setUserTestPackages((prev) => ({
        ...prev,
        columns: {
          ...previousPackages,
          UserTest: testsData?.data?.findManyTests.map((test) => ({
            id: `main-${test.TestID}`,
            content: {
              Price: test.CategoryPrice,
              Test: test,
            },
          })),
        },
        ordered: ["UserTest", ...Object.keys(previousPackages)],
        packages: packages.map(({ PackageID, Name }) => ({
          PackageID,
          Name,
        })),
        packagePrices: previousPrices,
        medicalTypes: previousMedicalTypes,
        displayAsTests: previousDisplayAsTests,
        names: { ...previousNames, UserTest: "UserTest" },
        packageStates: previousStates,
      }));
    }
  }, [packagesData, testsData]);

  const handlePriceChange = async (PackageID, value) => {
    try {
      await updatePackage({
        variables: {
          data: {
            Price: { set: parseFloat(value, 10) },
            ModifiedBy: { set: authState.user.Username },
          },
          where: {
            PackageID: parseInt(PackageID, 10),
          },
        },
        refetchQueries: [
          {
            query: ALL_PACKAGES_QUERY,
            variables: {
              where: {
                UserID: { equals: parseInt(userID, 10) },
                Type: { is: { CodeDescription: { equals: "User" } } },
              },
              orderBy: [{ CreatedDateTime: "desc" }],
            },
          },
        ],
      });
      toast.success("Package updated successfully");
    } catch (err) {
      toast.error("Error updating Package");
    }
  };

  const handleDisplayAsTestChange = async (PackageID, value) => {
    try {
      await updatePackage({
        variables: {
          data: {
            DisplayAsTest: { set: value },
            ModifiedBy: { set: authState.user.Username },
          },
          where: {
            PackageID: parseInt(PackageID, 10),
          },
        },
        refetchQueries: [
          {
            query: ALL_PACKAGES_QUERY,
            variables: {
              where: {
                UserID: { equals: parseInt(userID, 10) },
                Type: { is: { CodeDescription: { equals: "User" } } },
              },
              orderBy: [{ CreatedDateTime: "desc" }],
            },
          },
        ],
      });

      toast.success("Package updated successfully");
    } catch (err) {
      toast.error("Error updating Package");
    }
  };

  const handleHeaderChange = async (oldName, newName) => {
    try {
      const { names } = userTestPackages;
      if (
        oldName !== newName &&
        Object.keys(names).find((key) => names[key] === newName)
      ) {
        if (document.getElementById(oldName)) {
          document.getElementById(oldName).value = oldName;
        }
        toast.error("That name already exists");
        return;
      }

      const packageToUpdate = userTestPackages.packages.find(
        ({ Name }) => Name === oldName
      );
      await updatePackage({
        variables: {
          data: {
            Name: { set: newName },
            ModifiedBy: { set: authState.user.Username },
          },
          where: {
            PackageID: parseInt(packageToUpdate.PackageID, 10),
          },
        },
        refetchQueries: [
          {
            query: ALL_PACKAGES_QUERY,
            variables: {
              where: {
                UserID: { equals: parseInt(userID, 10) },
                Type: { is: { CodeDescription: { equals: "User" } } },
              },
              orderBy: [{ CreatedDateTime: "desc" }],
            },
          },
        ],
      });
      toast.success("Package updated successfully");
    } catch (err) {
      toast.error("Error updating Package");
    }
  };

  const handleMedicalChange = async (PackageID, medicalValue) => {
    try {
      await updatePackage({
        variables: {
          data: {
            MedicalType: { set: medicalValue },
            ModifiedBy: { set: authState.user.Username },
          },
          where: {
            PackageID: parseInt(PackageID, 10),
          },
        },
        refetchQueries: [
          {
            query: ALL_PACKAGES_QUERY,
            variables: {
              where: {
                UserID: { equals: parseInt(userID, 10) },
                Type: { is: { CodeDescription: { equals: "User" } } },
              },
              orderBy: [{ CreatedDateTime: "desc" }],
            },
          },
        ],
      });

      toast.success("Package updated successfully");
    } catch (err) {
      toast.error("Error updating Package");
    }
  };

  const handleAddPackage = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const countOfColumns = Object.keys(userTestPackages.columns).length;
      const packageName = `Package-${countOfColumns}${
        Object.keys(userTestPackages.columns).find(
          (key) => key === `Package-${countOfColumns}`
        )
          ? "(2)"
          : ""
      }`;
      await createPackage({
        variables: {
          data: {
            UserID: parseInt(userID, 10),
            Name: packageName,
            CreatedBy: authState.user.Username,
            ModifiedBy: authState.user.Username,
            Price: parseFloat(userTestPackages.packagePrices[packageName], 10),
            MedicalType: userTestPackages.medicalTypes[packageName],
            Type: {
              connect: { RecId: 106 },
            },
            State:
              authState.user.Lab.Company !== "All Location"
                ? authState.user.Lab.State
                : null,
          },
        },
        refetchQueries: [
          {
            query: ALL_PACKAGES_QUERY,
            variables: {
              where: {
                UserID: { equals: parseInt(userID, 10) },
                Type: { is: { CodeDescription: { equals: "User" } } },
              },
              orderBy: [{ CreatedDateTime: "desc" }],
            },
          },
        ],
      });
      toast.success("Package created successfully");
    } catch (err) {
      toast.error("Error creating Package");
    } finally {
      setLoading(false);
    }
  };

  const removePackage = (PackageID) => {
    const performDelete = async () => {
      try {
        await deletePackage({
          variables: {
            where: {
              PackageID: parseInt(PackageID, 10),
            },
          },
          refetchQueries: [
            {
              query: ALL_PACKAGES_QUERY,
              variables: {
                where: {
                  UserID: { equals: parseInt(userID, 10) },
                  Type: { is: { CodeDescription: { equals: "User" } } },
                },
                orderBy: [{ CreatedDateTime: "desc" }],
              },
              fetchPolicy: "network-only",
            },
          ],
        });
        setModalOpen(false);
        toast.success("Package deleted successfully!");
      } catch (e) {
        toast.error("Error deleting Package Test");
      }
    };
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to remove this and its contents?"
        onCancel={() => setModalOpen(false, "")}
        onConfirm={performDelete}
      />
    );
  };

  const removePackageTest = (source, itemToDelete) => {
    const performDelete = async () => {
      try {
        await deletePackageTest({
          variables: {
            where: {
              PackageTestID: parseInt(itemToDelete.content.PackageTestID, 10),
            },
          },
          refetchQueries: [
            {
              query: ALL_PACKAGES_QUERY,
              variables: {
                where: {
                  UserID: { equals: parseInt(userID, 10) },
                  Type: { is: { CodeDescription: { equals: "User" } } },
                },
                orderBy: [{ CreatedDateTime: "desc" }],
              },
            },
          ],
        });
        setModalOpen(false);
        toast.success("Package Test deleted successfully!");
      } catch (e) {
        toast.error("Error deleting Package Test");
      }
    };
    if (itemToDelete.content?.PackageTestID) {
      setModalOpen(
        true,
        <Confirmation
          message={`Are you sure you want to remove this item from ${source}?`}
          onCancel={() => setModalOpen(false, "")}
          onConfirm={performDelete}
        />
      );
    }
  };

  const handleCreatePackageTest = async (packageToInsert, PackageID, Order) => {
    try {
      if (packageToInsert) {
        await createPackageTest({
          variables: {
            data: {
              Price: parseFloat(packageToInsert.content.Price, 10),
              CreatedBy: authState.user.Username,
              ModifiedBy: authState.user.Username,
              Test: {
                connect: {
                  TestID: parseInt(packageToInsert.content.Test.TestID, 10),
                },
              },
              PackageID: parseInt(PackageID, 10),
              Order,
            },
          },
          refetchQueries: [
            {
              query: ALL_PACKAGES_QUERY,
              variables: {
                where: {
                  UserID: { equals: parseInt(userID, 10) },
                  Type: {
                    is: { CodeDescription: { equals: "User" } },
                  },
                },
                orderBy: [{ CreatedDateTime: "desc" }],
              },
              fetchPolicy: "network-only",
            },
          ],
        });
      }
      toast.success("Package updated successfully");
    } catch (err) {
      toast.error("Error creating Package Test");
    }
  };

  const handleReorderPackageTests = async (source, destination) => {
    const packages = userTestPackages.columns[destination.droppableId];
    packages.move(source.index, destination.index);
    await Promise.all(
      packages.map(async (pkg, index) => {
        await updatePackageTest({
          variables: {
            where: {
              PackageTestID: parseInt(pkg.content.PackageTestID, 10),
            },
            data: {
              Order: { set: index },
            },
          },
          refetchQueries: [
            {
              query: ALL_PACKAGES_QUERY,
              variables: {
                where: {
                  UserID: { equals: parseInt(userID, 10) },
                  Type: {
                    is: { CodeDescription: { equals: "User" } },
                  },
                },
                orderBy: [{ CreatedDateTime: "desc" }],
              },
              fetchPolicy: "network-only",
            },
          ],
        });
      })
    );
    toast.success("Package updated successfully");
  };

  const handleAddPackageFromTemplate = () => {
    const countOfColumns = Object.keys(userTestPackages.columns).length;
    const packageName = `Package-${countOfColumns}${
      Object.keys(userTestPackages.columns).find(
        (key) => key === `Package-${countOfColumns}`
      )
        ? "(2)"
        : ""
    }`;

    setModalOpen(
      true,
      <PackageTemplateModal
        UserID={userID}
        packageName={packageName}
        onComplete={() => setModalOpen(false)}
      />
    );
  };

  const handlePackageStateChange = async (PackageID, stateValue) => {
    try {
      await updatePackage({
        variables: {
          data: {
            State: { set: stateValue },
            ModifiedBy: { set: authState.user.Username },
          },
          where: {
            PackageID: parseInt(PackageID, 10),
          },
        },
        refetchQueries: [
          {
            query: ALL_PACKAGES_QUERY,
            variables: {
              where: {
                UserID: { equals: parseInt(userID, 10) },
                Type: { is: { CodeDescription: { equals: "User" } } },
              },
              orderBy: [{ CreatedDateTime: "desc" }],
            },
          },
        ],
      });

      toast.success("Package updated successfully");
    } catch (err) {
      toast.error("Error updating Package");
    }
  };

  if (packagesData?.loading || testsData?.loading) return <Loader />;

  return (
    <form style={{ width: "100%", paddingLeft: 10 }}>
      <div className="page-head">
        <div className="page-head-start" />
        <div className="page-head-start">
          {!disabled && (
            <React.Fragment>
              <Button
                color="secondary"
                size="small"
                style={{ borderRadius: "20px 0 20px 20px" }}
                type="button"
                onClick={handleAddPackageFromTemplate}
              >
                New from Template
              </Button>
              <Button
                color="primary"
                size="small"
                style={{ borderRadius: "20px 0 20px 20px" }}
                type="button"
                onClick={handleAddPackage}
              >
                New Package
              </Button>
            </React.Fragment>
          )}
        </div>
      </div>
      {testsData?.data?.findManyTests?.length > 0 ? (
        <PackagesBoard
          isCombineEnabled
          withScrollableColumns
          createPackageTest={handleCreatePackageTest}
          disabled={disabled}
          handleHeaderChange={handleHeaderChange}
          handleMedicalChange={handleMedicalChange}
          removePackage={removePackage}
          removePackageTest={removePackageTest}
          reorderPackageTests={handleReorderPackageTests}
          setState={setUserTestPackages}
          state={userTestPackages}
          onChange={handlePriceChange}
          onDisplayAsTestChange={handleDisplayAsTestChange}
          onPackageStateChange={handlePackageStateChange}
        />
      ) : (
        <h1>No data found</h1>
      )}
    </form>
  );
};

PackageSettings.propTypes = {
  userID: PropTypes.string,
  setLoading: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

PackageSettings.defaultProps = {
  userID: "",
  disabled: false,
};

export default PackageSettings;
