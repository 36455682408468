/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import { useMutation, useApolloClient, useQuery } from "@apollo/client";
import {
  Title,
  Fieldset,
  Button,
  Column,
  Field,
  Control,
  Input,
  Label,
} from "rbx";
import { Loader, EquipmentSelect } from "../../../../../components";
import {
  UPDATE_BATCH_MUTATION,
  // LIST_BATCHES_QUERY,
  UPDATE_SAMPLE_SYSTEM_QUALIFIERS_MUTATION,
  ALL_QUALIFIERS_QUERY,
  SINGLE_BATCH_QUERY,
  CREATE_BATCH_LOG_MUTATION,
  FIND_FIRST_JOB_ORDER_TEST_SAMPLE_QUERY,
  CREATE_JOB_LOG_MUTATION,
} from "../../../../../graphql";
import { customToast as toast } from "../../../../../utils";
import { useAuth } from "../../../../../context";
import { AssignSystemQualifiers } from "../../BatchRecords/BuildBatchResults/BatchFunctions";

const INITIAL_STATE = {
  OrderName: "",
  Weight: "",
  Volume: "",
  Dilution: "",
  SessionID: "",
  EquipmentID: "",
  EquipmentCalibrationDueDate: "",
};

const INITIAL_CHANGES = {
  Weight: "",
  Volume: "",
  Dilution: "",
  EquipmentID: "",
};

const SamplePrepModal = ({
  onComplete,
  sessionId,
  batchesData,
  userID: UserID,
  disabled,
}) => {
  const { state: authState } = useAuth();
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [selectedTest, setSelectedTest] = useState({
    ...INITIAL_STATE,
  });
  const [matrixSpike, setMatrixSpike] = useState({
    ...INITIAL_STATE,
  });
  const [matrixSpikeDuplicate, setMatrixSpikeDuplicate] = useState({
    ...INITIAL_STATE,
  });
  const [sampleDuplicate, setSampleDuplicate] = useState({
    ...INITIAL_STATE,
  });
  const [originalSelectedTest, setOriginalSelectedTest] = useState({
    ...INITIAL_STATE,
  });
  const [originalMatrixSpike, setOriginalMatrixSpike] = useState({
    ...INITIAL_STATE,
  });
  const [originalMatrixSpikeDuplicate, setOriginalMatrixSpikeDuplicate] =
    useState({
      ...INITIAL_STATE,
    });
  const [originalSampleDuplicate, setOriginalSampleDuplicate] = useState({
    ...INITIAL_STATE,
  });
  const [workingBatchIndex, setWorkingBatchIndex] = useState(-1);
  const [hasSpike, setHasSpike] = useState(false);
  const [hasSpikeDuplicate, setHasSpikeDuplicate] = useState(false);
  const [hasSampleDuplicate, setHasSampleDuplicate] = useState(false);
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [workingBatchID, setWorkingBatchID] = useState(-1);
  const [labID, setLabID] = useState(null);
  const [testName, setTestName] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [associatedMicroQCSamples, setAssociatedMicroQCSamples] = useState([]);
  const [
    originalAssociatedMicroQCSamples,
    setOriginalAssociatedMicroQCSamples,
  ] = useState([]);
  const [showReasonForChangeInput, setShowReasonForChangeInput] =
    useState(false);
  const [reasonForChange, setReasonForChange] = useState("");

  const [createJobLog] = useMutation(CREATE_JOB_LOG_MUTATION);
  const [updateBatches] = useMutation(UPDATE_BATCH_MUTATION);
  const [updateSystemQualifiers] = useMutation(
    UPDATE_SAMPLE_SYSTEM_QUALIFIERS_MUTATION
  );
  const [createBatchLogs] = useMutation(CREATE_BATCH_LOG_MUTATION);
  const [changes, setChanges] = useState({
    test: {
      ...INITIAL_CHANGES,
    },
    matrixSpike: {
      ...INITIAL_CHANGES,
    },
    matrixSpikeDuplicate: {
      ...INITIAL_CHANGES,
    },
    sampleDuplicate: {
      ...INITIAL_CHANGES,
    },
  });

  const { data: getJobOrderTestSampleData } = useQuery(
    FIND_FIRST_JOB_ORDER_TEST_SAMPLE_QUERY,
    {
      variables: {
        where: {
          SessionID: {
            equals: sessionId,
          },
        },
      },
    }
  );

  const equipmentWhere = useMemo(() => {
    const finalWhere = {
      SamplePrep: { equals: true },
      Active: { equals: true },
    };
    if (labID) {
      finalWhere.LabID = { equals: parseInt(labID, 10) };
    }
    return finalWhere;
  }, [labID]);

  useEffect(() => {
    let foundTest;
    let workingBatch;
    let isMicro = false;

    for (const [index, batch] of batchesData.entries()) {
      try {
        const tests = JSON.parse(batch.Tests);
        if (Array.isArray(tests)) {
          for (const test of tests) {
            if (test.SessionID === sessionId) {
              foundTest = test;
              workingBatch = index;
              setWorkingBatchID(batch.BatchID);
              setLabID(batch.LabID);
              if (test.JobOrderTestSampleID) {
                const jobOrderTestSample = batch.JobOrderTestSamples?.find(
                  (sample) => sample.SessionID === sessionId
                );
                setTestName(jobOrderTestSample?.JobOrderTest?.Test?.Name);
                setProductCategory(
                  jobOrderTestSample?.JobOrder?.ProductCategorySystemCode
                    ?.CodeDescription
                );
                if (batch.TestCategory?.Code?.includes("MICRO")) {
                  isMicro = true;
                  setAssociatedMicroQCSamples(
                    tests.filter((t) => t.PrimarySampleSessionID === sessionId)
                  );
                  setOriginalAssociatedMicroQCSamples(
                    tests.filter((t) => t.PrimarySampleSessionID === sessionId)
                  );
                }
              } else if (test.PrimarySampleSessionID) {
                const jobOrderTestSample = batch.JobOrderTestSamples?.find(
                  (sample) => sample.SessionID === test.PrimarySampleSessionID
                );
                setTestName(jobOrderTestSample?.JobOrderTest?.Test?.Name);
                setProductCategory(
                  jobOrderTestSample?.JobOrder?.ProductCategorySystemCode
                    ?.CodeDescription
                );
              }
            }
          }
        }
      } catch (e) {
        toast.error("Error when trying to open the Batch");
      }
    }
    setSelectedTest(foundTest);
    setOriginalSelectedTest(foundTest);
    setWorkingBatchIndex(workingBatch);

    if (!isMicro) {
      if (batchesData?.[workingBatch]?.Tests) {
        const getTests = (x) => {
          if (Array.isArray(x)) return x;
          if (typeof x === "string") {
            return JSON.parse(x);
          }
          return undefined;
        };
        const tests = getTests(batchesData[workingBatch].Tests);

        if (tests) {
          if (foundTest.matrixSpike) {
            const foundSpike = tests.find(
              (test) => test.OrderName === "Matrix Spike"
            );
            if (foundSpike) {
              setMatrixSpike(foundSpike);
              setOriginalMatrixSpike(foundSpike);
              setHasSpike(true);
            }

            const foundSpikeDuplicate = tests.find(
              (test) => test.OrderName === "Matrix Spike Duplicate"
            );
            if (foundSpikeDuplicate) {
              setMatrixSpikeDuplicate(foundSpikeDuplicate);
              setOriginalMatrixSpikeDuplicate(foundSpikeDuplicate);
              setHasSpikeDuplicate(true);
            }
          }

          if (foundTest.sampleDuplicate) {
            const foundSampleDuplicate = tests.find(
              (test) => test.OrderName === "Sample Duplicate"
            );
            if (foundSampleDuplicate) {
              setSampleDuplicate(foundSampleDuplicate);
              setOriginalSampleDuplicate(foundSampleDuplicate);
              setHasSampleDuplicate(true);
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchesData, sessionId]);

  // assign qualifers to the associated batch
  const saveSystemQualifiers = async (batchID) => {
    const query = {
      query: SINGLE_BATCH_QUERY,
      variables: {
        where: {
          BatchID: parseInt(batchID, 10),
        },
      },
      fetchPolicy: "network-only",
    };
    const {
      data: { findUniqueBatches },
    } = await client.query(query);
    const {
      data: { findManyQualifiers },
    } = await client.query({
      query: ALL_QUALIFIERS_QUERY,
      variables: {},
    });

    if (findUniqueBatches) {
      const parsedResults = findUniqueBatches?.TestSamples?.map((res) => {
        const lowercaseKeys = (obj) => {
          if (!obj) {
            return {};
          }
          return Object.keys(obj).reduce((acc, key) => {
            acc[key.toLowerCase().trim()] = obj[key];
            return acc;
          }, {});
        };
        return {
          ...res,
          ...res.JobOrderTestSample,
          ...res.QCSample,
          AnalyteResults: res?.JobOrderTestSample?.Result
            ? lowercaseKeys(res?.JobOrderTestSample?.Result)
            : lowercaseKeys(res?.QCSample?.Result),
          TargetValues: res?.QCSample?.TargetValues
            ? lowercaseKeys(res?.QCSample?.TargetValues)
            : null,
          QCTarget: {
            TargetValues: res?.QCTarget?.TargetValues
              ? lowercaseKeys(res?.QCTarget?.TargetValues)
              : null,
          },
        };
      });
      const systemQualifiers = AssignSystemQualifiers(
        parsedResults,
        findManyQualifiers,
        findUniqueBatches?.Lab?.State
      );

      await updateSystemQualifiers({
        variables: {
          data: {
            SystemQualifiers: systemQualifiers,
          },
          refetchQueries: [query],
        },
      });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let reasonRequired = false;
    Object.keys(changes).forEach((key) => {
      Object.keys(changes[key]).forEach((changeKey) => {
        if (changes[key][changeKey].length) {
          reasonRequired = true;
        }
      });
    });
    if (reasonRequired) {
      setShowReasonForChangeInput(reasonRequired);
    } else {
      onReasonForChangeProvided();
    }
  };

  const onReasonForChangeProvided = async () => {
    setLoading(true);
    try {
      const query = {
        query: SINGLE_BATCH_QUERY,
        variables: {
          where: {
            BatchID: parseInt(batchesData[workingBatchIndex].BatchID, 10),
          },
        },
        fetchPolicy: "network-only",
      };

      const {
        data: { findUniqueBatches },
      } = await client.query(query);

      const arrToUpdate = findUniqueBatches.Tests.map((test) => {
        if (sessionId === test.SessionID) {
          return selectedTest;
        }

        if (hasSpike) {
          if (matrixSpike.SessionID === test.SessionID) {
            return matrixSpike;
          }
        }

        if (hasSpikeDuplicate) {
          if (matrixSpikeDuplicate.SessionID === test.SessionID) {
            return matrixSpikeDuplicate;
          }
        }

        if (hasSampleDuplicate) {
          if (sampleDuplicate.SessionID === test.SessionID) {
            return sampleDuplicate;
          }
        }

        if (associatedMicroQCSamples.length) {
          const associatedQC = associatedMicroQCSamples.find(
            (sample) => sample.SessionID === test.SessionID
          );
          if (associatedQC) {
            return associatedQC;
          }
        }
        return test;
      });

      await updateBatches({
        variables: {
          where: {
            BatchID: parseInt(workingBatchID, 10),
          },
          data: {
            Tests: arrToUpdate,
          },
        },
      });

      let Change = "";

      Object.keys(changes).forEach((key) => {
        Object.keys(changes[key]).forEach((changeKey) => {
          if (changes[key][changeKey].length) {
            Change += `${Change.length ? ", " : ""}${changes[key][changeKey]}`;
          }
        });
      });

      if (Change?.length) {
        await createBatchLogs({
          variables: {
            data: {
              Change,
              Batches: {
                connect: {
                  BatchID: parseInt(batchesData[workingBatchIndex].BatchID, 10),
                },
              },
              ReasonForChange: reasonForChange,
              CreatedBy: authState.user.Username,
            },
          },
        });
      }

      if (
        getJobOrderTestSampleData?.findFirstJobOrderTestSamples &&
        Change?.length
      ) {
        await createJobLog({
          variables: {
            data: {
              JobID: parseInt(
                getJobOrderTestSampleData.findFirstJobOrderTestSamples.JobID,
                10
              ),
              JobOrderID: parseInt(
                getJobOrderTestSampleData.findFirstJobOrderTestSamples
                  .JobOrderID,
                10
              ),
              Change,
              ReasonForChange: reasonForChange,
              CreatedBy: authState.user.Username,
              ModifiedBy: authState.user.Username,
            },
          },
        });
      }
      await saveSystemQualifiers(workingBatchID);

      toast.success("Batch updated successfully");
      onComplete();
    } catch (err) {
      toast.error("An error has ocurred.");
    } finally {
      setLoading(false);
    }
  };

  const onChange = async (name, value, type, microQCIndex) => {
    let EquipmentCalibrationDueDate = "";
    let valueToUpdate = value;
    if (name === "EquipmentID") {
      EquipmentCalibrationDueDate = value.CalibrationDueDate;
      valueToUpdate = value.EquipmentID;
    }

    const handleSetChange = (oldValue, sessionIdChanged) => {
      const Change = `Changed ${sessionIdChanged} ${name} from ${oldValue} to ${valueToUpdate}`;
      setChanges((prev) => ({
        ...prev,
        [type]: {
          ...prev[type],
          [name]: Change,
        },
      }));
    };

    switch (type) {
      case "test": {
        setSelectedTest((prevValues) => ({
          ...prevValues,
          [name]: valueToUpdate,
          UserID,
          EquipmentCalibrationDueDate,
          SamplePrepDate: new Date(),
        }));

        if (originalSelectedTest[name]?.length) {
          handleSetChange(originalSelectedTest[name], sessionId);
        }
        break;
      }
      case "matrixSpike": {
        setMatrixSpike((prevValues) => ({
          ...prevValues,
          [name]: valueToUpdate,
          UserID,
          EquipmentCalibrationDueDate,
          SamplePrepDate: new Date(),
        }));

        if (originalMatrixSpike[name]?.length) {
          handleSetChange(
            originalMatrixSpike[name],
            originalMatrixSpike.SessionID
          );
        }
        break;
      }
      case "matrixSpikeDuplicate": {
        setMatrixSpikeDuplicate((prevValues) => ({
          ...prevValues,
          [name]: valueToUpdate,
          UserID,
          EquipmentCalibrationDueDate,
          SamplePrepDate: new Date(),
        }));

        if (originalMatrixSpikeDuplicate[name]?.length) {
          handleSetChange(
            originalMatrixSpikeDuplicate[name],
            originalMatrixSpikeDuplicate.SessionID
          );
        }
        break;
      }
      case "sampleDuplicate": {
        setSampleDuplicate((prevValues) => ({
          ...prevValues,
          [name]: valueToUpdate,
          UserID,
          EquipmentCalibrationDueDate,
          SamplePrepDate: new Date(),
        }));

        if (originalSampleDuplicate[name]?.length) {
          handleSetChange(
            originalSampleDuplicate[name],
            originalSampleDuplicate.SessionID
          );
        }
        break;
      }
      case "microQC": {
        setAssociatedMicroQCSamples((prevArr) => {
          const newArr = [...prevArr];
          newArr[microQCIndex] = {
            ...newArr[microQCIndex],
            [name]: valueToUpdate,
            UserID,
            EquipmentCalibrationDueDate,
            SamplePrepDate: new Date(),
          };
          return newArr;
        });

        if (originalAssociatedMicroQCSamples[microQCIndex][name]?.length) {
          const Change = `Changed ${originalAssociatedMicroQCSamples[microQCIndex].SessionID} ${name} from ${originalAssociatedMicroQCSamples[microQCIndex][name]} to ${valueToUpdate}`;
          setChanges((prev) => ({
            ...prev,
            [originalAssociatedMicroQCSamples[microQCIndex].SessionID]: {
              ...prev[originalAssociatedMicroQCSamples[microQCIndex].SessionID],
              [name]: Change,
            },
          }));
        }
        break;
      }
      default:
        break;
    }
    setFormHasChanged(true);
  };

  const isDisabled =
    Object.keys(INITIAL_STATE).every((key) => !selectedTest[key].length) ||
    !formHasChanged ||
    disabled;

  const renderTestsForm = () => (
    <React.Fragment>
      <Column.Group multiline>
        <Column size={12}>
          <Field kind="group">
            <Control expanded size="small">
              <Label>Weight</Label>
              <Input
                disabled={disabled}
                maxLength={250}
                name="Weight"
                placeholder="Weight"
                size="small"
                type="text"
                value={selectedTest.Weight}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, "test")
                }
              />
            </Control>
            <Control expanded size="small">
              <Label>Volume</Label>
              <Input
                disabled={disabled}
                name="Volume"
                placeholder="Volume"
                size="small"
                type="number"
                value={selectedTest.Volume}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, "test")
                }
              />
            </Control>
            <Control expanded size="small">
              <Label>Dilution</Label>
              <Input
                disabled={disabled}
                name="Dilution"
                placeholder="Dilution"
                size="small"
                type="number"
                value={selectedTest.Dilution}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, "test")
                }
              />
            </Control>
            <EquipmentSelect
              fullWidth
              returnFullValue
              disabled={disabled}
              label="Balance EID#"
              name="EquipmentID"
              size="small"
              value={selectedTest.EquipmentID}
              where={equipmentWhere}
              onChange={(name, value) => onChange(name, value, "test")}
            />
          </Field>
        </Column>
      </Column.Group>
      {hasSpike && (
        <Fragment>
          <Title size={5}>Matrix Spike</Title>
          <Column.Group multiline>
            <Column size={12}>
              <Field kind="group">
                <Control expanded size="small">
                  <Label>Weight</Label>
                  <Input
                    disabled={disabled}
                    maxLength={250}
                    name="Weight"
                    placeholder="Weight"
                    size="small"
                    type="text"
                    value={matrixSpike.Weight}
                    onChange={(e) =>
                      onChange(e.target.name, e.target.value, "matrixSpike")
                    }
                  />
                </Control>
                <Control expanded size="small">
                  <Label>Volume</Label>
                  <Input
                    disabled={disabled}
                    name="Volume"
                    placeholder="Volume"
                    size="small"
                    type="number"
                    value={matrixSpike.Volume}
                    onChange={(e) =>
                      onChange(e.target.name, e.target.value, "matrixSpike")
                    }
                  />
                </Control>
                <Control expanded size="small">
                  <Label>Dilution</Label>
                  <Input
                    disabled={disabled}
                    name="Dilution"
                    placeholder="Dilution"
                    size="small"
                    type="number"
                    value={matrixSpike.Dilution}
                    onChange={(e) =>
                      onChange(e.target.name, e.target.value, "matrixSpike")
                    }
                  />
                </Control>
                <EquipmentSelect
                  fullWidth
                  returnFullValue
                  disabled={disabled}
                  label="Balance EID#"
                  name="EquipmentID"
                  size="small"
                  value={matrixSpike.EquipmentID}
                  where={equipmentWhere}
                  onChange={(name, value) =>
                    onChange(name, value, "matrixSpike")
                  }
                />
              </Field>
            </Column>
          </Column.Group>
        </Fragment>
      )}
      {hasSpikeDuplicate && (
        <React.Fragment>
          <Title size={5}>Matrix Spike Duplicate</Title>
          <Column.Group multiline>
            <Column size={12}>
              <Field kind="group">
                <Control expanded size="small">
                  <Label>Weight</Label>
                  <Input
                    disabled={disabled}
                    maxLength={250}
                    name="Weight"
                    placeholder="Weight"
                    size="small"
                    type="text"
                    value={matrixSpikeDuplicate.Weight}
                    onChange={(e) =>
                      onChange(
                        e.target.name,
                        e.target.value,
                        "matrixSpikeDuplicate"
                      )
                    }
                  />
                </Control>
                <Control expanded size="small">
                  <Label>Volume</Label>
                  <Input
                    disabled={disabled}
                    name="Volume"
                    placeholder="Volume"
                    size="small"
                    type="number"
                    value={matrixSpikeDuplicate.Volume}
                    onChange={(e) =>
                      onChange(
                        e.target.name,
                        e.target.value,
                        "matrixSpikeDuplicate"
                      )
                    }
                  />
                </Control>
                <Control expanded size="small">
                  <Label>Dilution</Label>
                  <Input
                    disabled={disabled}
                    name="Dilution"
                    placeholder="Dilution"
                    size="small"
                    type="number"
                    value={matrixSpikeDuplicate.Dilution}
                    onChange={(e) =>
                      onChange(
                        e.target.name,
                        e.target.value,
                        "matrixSpikeDuplicate"
                      )
                    }
                  />
                </Control>
                <EquipmentSelect
                  fullWidth
                  returnFullValue
                  disabled={disabled}
                  label="Balance EID#"
                  name="EquipmentID"
                  size="small"
                  value={matrixSpikeDuplicate.EquipmentID}
                  where={equipmentWhere}
                  onChange={(name, value) =>
                    onChange(name, value, "matrixSpikeDuplicate")
                  }
                />
              </Field>
            </Column>
          </Column.Group>
        </React.Fragment>
      )}
      {hasSampleDuplicate && (
        <Fragment>
          <Title size={5}>Sample Duplicate</Title>
          <Column.Group multiline>
            <Column size={12}>
              <Field kind="group">
                <Control expanded size="small">
                  <Label>Weight</Label>
                  <Input
                    disabled={disabled}
                    maxLength={250}
                    name="Weight"
                    placeholder="Weight"
                    size="small"
                    type="text"
                    value={sampleDuplicate.Weight}
                    onChange={(e) =>
                      onChange(e.target.name, e.target.value, "sampleDuplicate")
                    }
                  />
                </Control>
                <Control expanded size="small">
                  <Label>Volume</Label>
                  <Input
                    disabled={disabled}
                    name="Volume"
                    placeholder="Volume"
                    size="small"
                    type="number"
                    value={sampleDuplicate.Volume}
                    onChange={(e) =>
                      onChange(e.target.name, e.target.value, "sampleDuplicate")
                    }
                  />
                </Control>
                <Control expanded size="small">
                  <Label>Dilution</Label>
                  <Input
                    disabled={disabled}
                    name="Dilution"
                    placeholder="Dilution"
                    size="small"
                    type="number"
                    value={sampleDuplicate.Dilution}
                    onChange={(e) =>
                      onChange(e.target.name, e.target.value, "sampleDuplicate")
                    }
                  />
                </Control>
                <EquipmentSelect
                  fullWidth
                  returnFullValue
                  disabled={disabled}
                  label="Balance EID#"
                  name="EquipmentID"
                  size="small"
                  value={sampleDuplicate.EquipmentID}
                  where={equipmentWhere}
                  onChange={(name, value) =>
                    onChange(name, value, "sampleDuplicate")
                  }
                />
              </Field>
            </Column>
          </Column.Group>
        </Fragment>
      )}
      {associatedMicroQCSamples.length
        ? associatedMicroQCSamples.map((_test, index) => (
            <React.Fragment>
              <Title size={5}>
                {_test?.OrderName}: {_test?.SessionID}
              </Title>
              <Column.Group multiline>
                <Column size={12}>
                  <Field kind="group">
                    <Control expanded size="small">
                      <Label>Weight</Label>
                      <Input
                        disabled={disabled}
                        maxLength={250}
                        name="Weight"
                        placeholder="Weight"
                        size="small"
                        type="text"
                        value={_test?.Weight}
                        onChange={(e) =>
                          onChange(
                            e.target.name,
                            e.target.value,
                            "microQC",
                            index
                          )
                        }
                      />
                    </Control>
                    <Control expanded size="small">
                      <Label>Volume</Label>
                      <Input
                        disabled={disabled}
                        name="Volume"
                        placeholder="Volume"
                        size="small"
                        type="number"
                        value={_test?.Volume}
                        onChange={(e) =>
                          onChange(
                            e.target.name,
                            e.target.value,
                            "microQC",
                            index
                          )
                        }
                      />
                    </Control>
                    <Control expanded size="small">
                      <Label>Dilution</Label>
                      <Input
                        disabled={disabled}
                        name="Dilution"
                        placeholder="Dilution"
                        size="small"
                        type="number"
                        value={_test?.Dilution}
                        onChange={(e) =>
                          onChange(
                            e.target.name,
                            e.target.value,
                            "microQC",
                            index
                          )
                        }
                      />
                    </Control>
                    <EquipmentSelect
                      fullWidth
                      returnFullValue
                      disabled={disabled}
                      label="Balance EID#"
                      name="EquipmentID"
                      size="small"
                      value={_test?.EquipmentID}
                      where={equipmentWhere}
                      onChange={(name, value) =>
                        onChange(name, value, "microQC", index)
                      }
                    />
                  </Field>
                </Column>
              </Column.Group>
            </React.Fragment>
          ))
        : null}
    </React.Fragment>
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {`Sample ${sessionId} - ${batchesData[workingBatchIndex]?.TestCategory?.Description}`}
          </Title>
        </div>
        <div className="page-head-end">
          {showReasonForChangeInput ? (
            <Button.Group
              style={{
                marginTop: "10px",
              }}
            >
              <Button
                size="small"
                type="button"
                onClick={() => {
                  setReasonForChange("");
                  setShowReasonForChangeInput(false);
                }}
              >
                <span>Cancel</span>
              </Button>
              <Button
                color="primary"
                disabled={!reasonForChange.length}
                size="small"
                type="button"
                onClick={onReasonForChangeProvided}
              >
                <span>Submit</span>
              </Button>
            </Button.Group>
          ) : (
            <Button.Group>
              <Button size="small" type="button" onClick={onComplete}>
                <span>Cancel</span>
              </Button>
              <Button
                color="primary"
                disabled={isDisabled}
                form="sample-prep-form"
                size="small"
                type="submit"
              >
                <span>Submit</span>
              </Button>
            </Button.Group>
          )}
        </div>
      </header>
      <div style={{ marginTop: "0.5rem" }}>
        {testName && (
          <span style={{ marginRight: "1rem" }}>
            <b>Test: </b>
            {testName || ""}
          </span>
        )}
        {productCategory && (
          <span>
            <b>Category: </b>
            {productCategory || ""}
          </span>
        )}
      </div>
      <hr />
      <Fieldset>
        <form id="sample-prep-form" onSubmit={onSubmit}>
          {showReasonForChangeInput ? (
            <React.Fragment>
              <Control expanded size="small">
                <Label>Reason For Change</Label>
                <Input
                  name="ReasonForChange"
                  placeholder="Reason For Change"
                  size="small"
                  type="text"
                  value={reasonForChange}
                  onChange={(e) => setReasonForChange(e.target.value)}
                />
              </Control>
            </React.Fragment>
          ) : (
            renderTestsForm()
          )}
        </form>
      </Fieldset>
      <hr />
    </Fragment>
  );
};

SamplePrepModal.propTypes = {
  onComplete: PropTypes.func,
  sessionId: PropTypes.string.isRequired,
  batchesData: PropTypes.array.isRequired,
  userID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
};

SamplePrepModal.defaultProps = {
  onComplete: (e) => e,
  disabled: false,
};

export default SamplePrepModal;
