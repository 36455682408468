import { gql } from "@apollo/client";

export const ALL_QC_SAMPLE_TYPES_QUERY = gql`
  query ALL_QC_SAMPLE_TYPES_QUERY(
    $where: QCSampleTypesWhereInput
    $orderBy: [QCSampleTypesOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyQCSampleTypes(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      QCSampleTypeID
      Name
      Code
      AllowMultiple
    }
    aggregateQCSampleTypes(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const SINGLE_QC_SAMPLE_TYPE_QUERY = gql`
  query SINGLE_QC_TARGET_QUERY($where: QCTargetsWhereUniqueInput!) {
    findUniqueQCTargets(where: $where) {
      QCSampleTypeID
      Name
      Code
    }
  }
`;
