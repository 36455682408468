import React from "react";
import PropTypes from "prop-types";

import { Title, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SwitchInput from "../../../components/SwitchInput";

const IndividualTest = ({ onChange, tests }) => {
  const first = tests.slice(0, tests.length / 2);
  const second = tests.slice(tests.length / 2);

  const testItem = (test) => (
    <div
      key={test.TestID}
      className={`test-item ${test.showDescription ? "test-item-opened" : ""}`}
    >
      <Title
        size={6}
        onClick={() =>
          onChange("showDescription", !test.showDescription, test.TestID)
        }
      >
        {test.Name} ${test.UserPrice}
      </Title>
      <div style={{ textAlign: "center" }}>
        <Icon
          role="button"
          style={{ cursor: "pointer" }}
          onClick={() =>
            onChange("showDescription", !test.showDescription, test.TestID)
          }
        >
          <FontAwesomeIcon
            icon={test.showDescription ? "chevron-up" : "chevron-down"}
          />
        </Icon>
      </div>
      <SwitchInput
        key={test.TestID}
        name={test.TestID}
        size="small"
        value={test.added}
        onChange={() => onChange("added", !test.added, test.TestID)}
      />

      <p className={test.showDescription ? "show" : ""}>{test.Description}</p>
    </div>
  );

  return (
    <div className="test-container">
      <div>{first.map((test) => testItem(test))}</div>
      <div>{second.map((test) => testItem(test))}</div>
    </div>
  );
};

IndividualTest.propTypes = {
  onChange: PropTypes.func.isRequired,
  tests: PropTypes.array,
};

IndividualTest.defaultProps = {
  tests: [],
};

export default IndividualTest;
