const sampleQueues = {
  name: "sample queues",
  label: "Sample Queues",
  icon: "clipboard-list",
  permissions: ["SMPL_QS"],
  active: (location) => {
    const [, p] = location.pathname.split("/");
    return /sample-queues/.test(p);
  },
  links: [
    {
      name: "Rejected Samples",
      to: "/sample-queues/rejected-samples",
    },
    {
      name: "In Queue Samples",
      to: "/sample-queues/in-queue-samples",
    },
    {
      name: "In Queue Reprep Samples",
      to: "/sample-queues/in-queue-reprep-samples",
    },
  ],
  root: "/sample-queues",
};

export default sampleQueues;
