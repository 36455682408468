import { gql } from "@apollo/client";

export const ALL_EQUIPMENT_LOGS_QUERY = gql`
  query ALL_EQUIPMENT_LOGS_QUERY(
    $where: EquipmentLogsWhereInput
    $orderBy: [EquipmentLogsOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyEquipmentLogs(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      EquipmentLogID
      EquipmentID
      LogType
      ReasonForChange
      AttachmentURL
      CreatedBy
      CreatedDateTime
      Type {
        RecId
        CodeId
        CodeDescription
      }
    } 
    aggregateEquipmentLogs(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const SINGLE_EQUIPMENT_LOG_QUERY = gql`
  query SINGLE_EQUIPMENT_LOG_QUERY($where: EquipmentLogsWhereUniqueInput!) {
    findUniqueEquipmentLogs(where: $where) {
      EquipmentLogID
      EquipmentID
      LogType
      ReasonForChange
      AttachmentURL
      CreatedBy
      CreatedDateTime
    }
  }
`;
