/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import { Title, Box } from "rbx";

import { LIST_USER_TESTS_QUERY, LIST_TESTS_QUERY } from "../../../../graphql";
import Loader from "../../../../components/Loader";
import TestInfoList from "./TestInfoList";
import "../../Client.scss";

const TestsInfo = ({
  userID,
  handleSave,
  userTests,
  setUserTests,
  inputs,
  onChange,
  disabled,
}) => {
  const [getUserTests, userTestsData] = useLazyQuery(LIST_USER_TESTS_QUERY, {
    fetchPolicy: "network-only",
  });
  const [getTests, testsData] = useLazyQuery(LIST_TESTS_QUERY);

  useEffect(() => {
    if (userID) {
      getUserTests({
        variables: {
          where: {
            UserID: {
              equals: parseInt(userID, 10),
            },
            Active: {
              equals: 1,
            },
          },
          orderBy: [{ ShowOrder: "asc" }],
        },
      });

      getTests({
        variables: {
          where: {
            Active: {
              equals: true,
            },
          },
        },
      });
    }
  }, [userID, getUserTests, getTests]);

  useEffect(() => {
    if (
      userTestsData?.data?.findManyUserTests &&
      testsData?.data?.findManyTests
    ) {
      const userTestsToCompare = userTestsData.data.findManyUserTests.map(
        ({ Test }) => Test.TestID
      );

      const testsToMerge = [];
      testsData.data.findManyTests.forEach((test) => {
        if (!userTestsToCompare.includes(test.TestID)) {
          const newObj = {
            Active: 0,
            ShowOrder: 100,
            Test: test,
            UserPrice: test.CategoryPrice,
            // since it doesn't have an usertest id, will reference it with it's own id
            newID: test.TestID * 3,
          };
          testsToMerge.push(newObj);
        }
      });

      setUserTests([...userTestsData.data.findManyUserTests, ...testsToMerge]);
    }
  }, [userTestsData, setUserTests, testsData]);

  const handleChange = (Id, name, value) => {
    setUserTests((prev) =>
      prev.map((test) => {
        if (test.UserTestID) {
          if (test.UserTestID === Id) {
            return { ...test, [name]: value, edited: true };
          }
          return test;
        }
        if (test.newID) {
          if (test.newID === Id) {
            return { ...test, [name]: value };
          }
          return test;
        }
      })
    );
  };

  const handleBlur = (UserTestID, name, value) => {
    const {
      data: { findManyUserTests },
    } = userTestsData;
    const foundItem = findManyUserTests.find(
      (item) => item.UserTestID === UserTestID
    );

    const newObject = Object.keys(foundItem.UserCategoryPrice).reduce(
      (acc, curr) => ({
        ...acc,
        [parseFloat(curr) + 1]: foundItem.UserCategoryPrice[curr],
      }),
      {}
    );

    setUserTests((prev) =>
      prev.map((test) =>
        test.UserTestID === UserTestID
          ? {
              ...test,
              UserCategoryPrice: { 1: value, ...newObject },
              edited: true,
            }
          : test
      )
    );
  };

  if (userTestsData?.loading) {
    return <Loader />;
  }

  return (
    <Box>
      <div className="info-items-header">
        <Title subtitle>Test Name</Title>
        <Title subtitle>Status</Title>
        <Title subtitle>Price</Title>
      </div>
      {userTests?.length > 0 ? (
        <form
          id="edit-client-form"
          style={{ width: "100%" }}
          onSubmit={handleSave}
        >
          <TestInfoList
            disabled={disabled}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setUserTests={setUserTests}
            userTests={userTests}
          />
        </form>
      ) : (
        <h1>No data found</h1>
      )}
    </Box>
  );
};

TestsInfo.propTypes = {
  userID: PropTypes.string,
  inputs: PropTypes.object,
  handleSave: PropTypes.func,
  userTests: PropTypes.array,
  setUserTests: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

TestsInfo.defaultProps = {
  userID: "",
  inputs: {},
  handleSave: (e) => e,
  userTests: [],
  setUserTests: () => null,
  onChange: (e) => e,
  disabled: false,
};

export default TestsInfo;
