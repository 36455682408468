import { gql } from "@apollo/client";

export const ALL_VERIFY_HEMP_CATEGORIES_QUERY = gql`
  query ALL_VERIFY_HEMP_CATEGORIES_QUERY(
    $where: VerifyHempCategoriesWhereInput
    $orderBy: [VerifyHempCategoriesOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyVerifyHempCategories(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      CategoryID
      UserID
      Name
      ImageURL
    }
    aggregateVerifyHempCategories(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const SINGLE_VERIFY_HEMP_CATEGORIES_QUERY = gql`
  query SINGLE_VERIFY_HEMP_CATEGORIES_QUERY($where: VerifyHempCategoriesWhereUniqueInput!) {
    findUniqueVerifyHempCategories(where: $where) {
      CategoryID
      UserID
      Name
      ImageURL
    }
  }
`;
