const PRIVATE_LABEL_ROUTES = [
  {
    name: "new-coa",
    label: "Order New COA",
    icon: "vial",
    active: (location) => {
      const [, p] = location.pathname.split("/");
      return /new-coa/.test(p);
    },
    to: "/pl/order",
  },
  {
    name: "purchased-coa",
    label: "Purchased COA",
    icon: "vials",
    active: (location) => {
      const [, p] = location.pathname.split("/");
      return /purchased-coa/.test(p);
    },
    to: "/pl/find",
  },
];

export default PRIVATE_LABEL_ROUTES;
