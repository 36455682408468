const coupons = {
  name: "coupons",
  label: "Coupons",
  icon: "ticket-alt",
  permissions: ["CPNS"],
  active: (location) => {
    const [, p] = location.pathname.split("/");
    return /coupons/.test(p);
  },
  to: "/coupons",
};

export default coupons;
