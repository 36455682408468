import React from "react";
import { useParams } from "react-router-dom";

import { COLUMNS } from "./columns";
import {DataTable} from "../../../../../../components";
import { ALL_BATCH_LOGS_QUERY } from "../../../../../../graphql";

const BatchLogsListPage = () => {  
  const { BatchID } = useParams();
  
  const where = {
    BatchID: {
      equals: parseInt(BatchID, 10),
    },
  };

  const orderBy = [{ id: "CreatedDateTime", desc: true }];

  return (
    <div>
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateBatchLogs"
        columns={COLUMNS}
        fetchPolicy="network-only"
        name="findManyBatchLogs"
        orderBy={orderBy}
        query={ALL_BATCH_LOGS_QUERY}
        where={where}
      />
    </div>
  );
};

export default BatchLogsListPage;