import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const UPLOAD_FILE_MUTATION = gql`
  mutation UPLOAD_FILE_MUTATION($file: Upload!) {
    uploadFile(file: $file) {
      filename
      mimetype
      encoding
      filepath
    }
  }
`;
