import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Input, Label } from "rbx";

const ShippingMultiplierForm = ({ inputs, onChange, disabled }) => (
  <Field kind="group">
    <Control expanded>
      <Label>Expedited</Label>
      <Input
        disabled={disabled}
        name="Expedited"
        size="small"
        type="number"
        value={inputs.Expedited}
        onChange={(e) => onChange(e.target.name, Number(e.target.value))}
    
    />
    </Control>
    <Control expanded>
      <Label>Urgent</Label>
      <Input
        disabled={disabled}
        name="Urgent"
        size="small"
        type="number"
        value={inputs.Urgent}
        onChange={(e) => onChange(e.target.name, Number(e.target.value))}
      />
    </Control>
  </Field>
);

ShippingMultiplierForm.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

ShippingMultiplierForm.defaultProps = {
  inputs: { Expedited: 1, Urgent: 2 },
  onChange: () => null,
  disabled: false,
};

export default ShippingMultiplierForm;
