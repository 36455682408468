import { gql } from "@apollo/client";

export const DELETE_COUPON_MUTATION = gql`
  mutation DELETE_COUPON_MUTATION($where: CouponsWhereUniqueInput!) {
    deleteCoupons(where: $where) {
      CouponID
      UserID
    }
  }
`;

export const CREATE_COUPON_MUTATION = gql`
  mutation CREATE_COUPON_MUTATION($data: CouponsCreateInput!) {
    createCoupons(data: $data) {
      CouponID
      UserID
    }
  }
`;

export const UPDATE_COUPON_MUTATION = gql`
  mutation UPDATE_COUPON_MUTATION(
    $data: CouponsUpdateInput!
    $where: CouponsWhereUniqueInput!
  ) {
    updateCoupons(data: $data, where: $where) {
      CouponID
      UserID
    }
  }
`;
