import React, { useState } from "react";
import {
  Box,
  Button,
  Field,
  Input,
  Label,
  Icon,
  Control,
  Heading,
  Checkbox,
} from "rbx";

import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { PhoneNumberInput, PageHeader, Loader } from "../../../../components";
import { useAuth } from "../../../../context";
import { useModal } from "../../../../context/ModalContext";

import {
  PrivateLabelOrderPageForm,
  PrivateLabelApplyCoupon,
  PrivateLabelPaymentForm,
} from "./components";

import { ORDER_PRIVATE_LABEL_COA_MUTATION } from "../../../../graphql";

import {
  usePublicIP,
  useValidatePrivateLabelJobOrderId,
} from "../../../../hooks";

const INITIAL_STATE = {
  Company: "",
  Address: "",
  Address2: "",
  City: "",
  State: "",
  Zip: "",
  Phone: "",
  Email: "",
  ClientLogo: "",
  CouponID: "",
  CouponDiscount: "",
  PaymentType: "",
  PaymentAmount: "",
  PaymentID: "",
  IP: "",
  UA: "",
  PurchaseDate: new Date(),
};

const PrivateLabelOrderPage = () => {
  const { state: authState } = useAuth();
  const { setModalOpen } = useModal();
  const history = useHistory();
  const IP = usePublicIP();

  const [inputs, setInputs] = useState({ ...INITIAL_STATE });

  const [orderPrivateLabelCOA, { loading: CreatePrivateLabelCOALoading }] =
    useMutation(ORDER_PRIVATE_LABEL_COA_MUTATION);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const finishTransaction = async (token) => {
      setModalOpen(false);
      try {
        await orderPrivateLabelCOA({
          variables: {
            data: {
              JobOrderID: parseInt(jobOrderId, 10),
              OwnerUserID: parseInt(authState?.user?.OwnerUserID, 10),
              PrivateLabelUserID: parseInt(
                authState?.user?.PrivateLabelUserID,
                10
              ),
              Company: inputs.Company || "",
              Address: inputs.Address || "",
              Address2: inputs.Address2 || "",
              City: inputs.City || "",
              State: inputs.State || "",
              Zip: inputs.Zip || "",
              Phone: inputs.Phone || "",
              Email: inputs.Email || "",
              ClientLogo: inputs.ClientLogo || "",
              CouponID: inputs.CouponID || "",
              CouponDiscount: inputs.CouponDiscount
                ? parseFloat(inputs.CouponDiscount)
                : 0,
              PaymentType: "COD",
              PaymentAmount:
                parseFloat(orderTotal) -
                (parseFloat(inputs.CouponDiscount) || 0),
              PaymentID: token,
              IP,
              UA: window.navigator.userAgent,
              PurchaseDate: new Date(),
            },
          },
        });
        toast.success("Order placed successfully.");
        setInputs({ ...INITIAL_STATE });
        history.push("/");
      } catch (err) {
        toast.error(err.message);
      }
    };
    setModalOpen(
      true,
      <PrivateLabelPaymentForm
        loading={CreatePrivateLabelCOALoading}
        paymentAmount={
          parseFloat(orderTotal) - (parseFloat(inputs.CouponDiscount) || 0)
        }
        onClose={() => setModalOpen(false)}
        onSubmit={finishTransaction}
      />
    );
  };

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const loading = CreatePrivateLabelCOALoading;

  const {
    jobOrderId,
    orderTotal,
    loading: validateLoading,
    valid: jobOrderIdValid,
    setJobOrderId,
  } = useValidatePrivateLabelJobOrderId();

  const isDisabled = loading || !jobOrderIdValid;

  if (loading || CreatePrivateLabelCOALoading) return <Loader />;

  return (
    <div className="private-label-order-page">
      <PageHeader title="Private Label Order COA Page" />
      <Box>
        <Field className="grid-override" kind="group">
          <Field>
            <Heading>Job Order Identifier</Heading>
            <Field>
              <Label>Job Order ID</Label>
              <Control iconRight loading={validateLoading}>
                <Input
                  disabled={loading || CreatePrivateLabelCOALoading}
                  name="JobOrderID"
                  placeholder="JobOrderID"
                  type="text"
                  value={jobOrderId}
                  onChange={(e) => setJobOrderId(e.target.value)}
                />
                {jobOrderIdValid && (
                  <Icon align="right" color="primary">
                    <FontAwesomeIcon icon="check" />
                  </Icon>
                )}
              </Control>
            </Field>
          </Field>
          <Field>
            <Heading>Contact Information</Heading>
            <Field className="grid-override" kind="group">
              <Control expanded>
                <Label>Phone</Label>
                <PhoneNumberInput
                  disabled={isDisabled}
                  name="Phone"
                  placeholder="Phone"
                  size="normal"
                  value={inputs.Phone}
                  onChange={handleChange}
                />
              </Control>
              <Control expanded>
                <Label>Email</Label>
                <Input
                  required
                  disabled={isDisabled}
                  name="Email"
                  placeholder="Email"
                  type="email"
                  value={inputs.Email}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </Control>
            </Field>
          </Field>
        </Field>
        <hr />
        <form id="find-coa" onSubmit={handleSubmit}>
          <Heading>COA Information</Heading>
          <PrivateLabelOrderPageForm
            disabled={isDisabled}
            inputs={inputs}
            onChange={handleChange}
          />
          <hr />
          <div style={{ textAlign: "center", marginBottom: "1rem" }}>
            <PrivateLabelApplyCoupon
              orderTotal={orderTotal}
              onChange={handleChange}
            />
          </div>
          <hr />
          <div style={{ textAlign: "center", marginBottom: "1rem" }}>
            <Label>
              <Checkbox
                checked={inputs.terms}
                name="terms"
                onChange={(e) => handleChange(e.target.name, e.target.checked)}
              />
              &nbsp; I agree to the&nbsp;
              <a
                className="green"
                href="https://actlab.com/terms-and-conditions/"
                rel="noreferrer"
                target="_blank"
              >
                terms and conditions
              </a>
            </Label>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              color="primary"
              disabled={isDisabled || !inputs.terms || !inputs.Email}
              size="medium"
              state={loading || CreatePrivateLabelCOALoading ? "loading" : ""}
              type="submit"
            >
              Checkout
            </Button>
          </div>
        </form>
      </Box>
    </div>
  );
};

export default PrivateLabelOrderPage;
