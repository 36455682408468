import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { Column, Field, Input, Label, Control, Title, Button } from "rbx";

import { ALL_ANALYTES_QUERY } from "../../../../../../graphql";
import "./LabelClaimForm.scss";

const LabelClaimForm = ({ inputs, onChange }) => {
  // const [labelClaim, setLabelClaim] = useState(INITIAL_STATE);
  const { data: cannAnalytes } = useQuery(ALL_ANALYTES_QUERY, {
    variables: {
      where: {
        Active: {
          equals: true,
        },
        TestCategory: {
          is: { Code: { equals: "CANN" } },
        },
      },
      orderBy: {
        Name: "asc",
      },
    },
  });

  const handleChange = (analyteID, value) => {
    const existingLabelClaim = inputs.ActualLabelClaims.find(
      ({ AnalyteID }) => AnalyteID === analyteID
    );
    if (existingLabelClaim) {
      const result = inputs.ActualLabelClaims.map((actualLabelClaim) =>
        actualLabelClaim.AnalyteID === analyteID
          ? { ...actualLabelClaim, LabelClaim: value, needSave: true }
          : actualLabelClaim
      );
      onChange("ActualLabelClaims", result);
    } else {
      const result = [
        ...inputs.ActualLabelClaims,
        { AnalyteID: analyteID, LabelClaim: value, needSave: true },
      ];
      onChange("ActualLabelClaims", result);
    }
  };

  return (
    <React.Fragment>
      <Column size={12}>
        <Title subtitle size={6}>
          Actual Label Claim Per Package
        </Title>
        <Column size={12}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
            }}
          >
            {cannAnalytes?.findManyAnalytes?.map((analyte) => (
              <Field key={analyte.AnalyteID} horizontal>
                <Field.Label size="small">
                  <Label style={{ width: "100px" }}>{analyte.Name}</Label>
                </Field.Label>
                <Field.Body>
                  <Field kind="addons">
                    <Control expanded size="small">
                      <Input
                        disabled={!analyte.AnalyteID}
                        name="LabelClaim"
                        placeholder="Label Claim"
                        size="small"
                        type="number"
                        value={
                          inputs.ActualLabelClaims.find(
                            ({ AnalyteID }) => AnalyteID === analyte.AnalyteID
                          )?.LabelClaim || ""
                        }
                        onChange={({ target: { value } }) =>
                          handleChange(analyte.AnalyteID, parseFloat(value))
                        }
                      />
                    </Control>
                    <Control>
                      <Button static size="small">
                        mg
                      </Button>
                    </Control>
                  </Field>
                </Field.Body>
              </Field>
            ))}
          </div>
        </Column>
      </Column>
    </React.Fragment>
  );
};

LabelClaimForm.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

LabelClaimForm.defaultProps = {
  inputs: {},
};

export default LabelClaimForm;
