/* eslint-disable no-extra-boolean-cast */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Column, Input, Label, Control, Help, Field, Checkbox } from "rbx";

import { LabLocationSelect, CouponTypeSelect } from "../../../../components";
import { useAuth } from "../../../../context";

const CouponForm = ({
  inputs,
  onChange,
  disabled,
  onSubmit,
  formId,
  adding,
  codeUnique,
  onBlurCode,
  couponTypes,
}) => {
  const { state: authState } = useAuth();
  const percentageType = couponTypes.find((type) => type.CodeId === "%");
  const moneyType = couponTypes.find((type) => type.CodeId === "$");

  const labEnabled = useMemo(
    () => authState?.user?.Lab?.Company === "All Location",
    [authState]
  );

  return (
    <form id={formId} onSubmit={onSubmit}>
      <Column.Group multiline>
        <Column size={12}>
          <Field kind="group">
            <Control expanded size="small" style={{ width: "33%" }}>
              <Label>Code</Label>
              <Input
                required
                maxLength={250}
                name="Code"
                placeholder="Code"
                size="small"
                value={inputs.Code}
                onBlur={onBlurCode}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
              {!codeUnique && (
                <Help color="danger">This Code is already taken</Help>
              )}
            </Control>
            <Control expanded size="small" style={{ width: "33%" }}>
              <CouponTypeSelect
                required
                id="Type"
                label="Type"
                name="Type"
                size="small"
                value={inputs.Type}
                onChange={onChange}
              />
            </Control>
            {Number(inputs.Type) === Number(percentageType?.RecId) && (
              <Control expanded size="small" style={{ width: "33%" }}>
                <Label>Percentage</Label>
                <Input
                  required
                  color={
                    inputs.Percent &&
                    (parseInt(inputs.Percent, 10) < 1 ||
                      parseInt(inputs.Percent, 10) > 100)
                      ? "danger"
                      : ""
                  }
                  maxLength={250}
                  maximum={100}
                  name="Percent"
                  placeholder="Percent"
                  size="small"
                  type="number"
                  value={inputs.Percent}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                />
              </Control>
            )}
            {Number(inputs.Type) === Number(moneyType?.RecId) && (
              <Control expanded size="small" style={{ width: "33%" }}>
                <Label>Discount</Label>
                <Input
                  required
                  maxLength={250}
                  name="Discount"
                  placeholder="Discount"
                  size="small"
                  type="number"
                  value={inputs.Discount}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                />
              </Control>
            )}
            <LabLocationSelect
              required
              showLabel
              disabled={!labEnabled}
              name="LabID"
              size="small"
              value={inputs?.LabID}
              onChange={onChange}
            />
          </Field>
        </Column>
        <Column size={6}>
          <Field kind="group">
            <Control expanded size="small">
              <Label>Uses</Label>
              <Input
                required
                color={
                  inputs.Uses &&
                  parseInt(inputs.Uses, 10) < 0 &&
                  parseInt(inputs.Uses, 10) !== -1
                    ? "danger"
                    : ""
                }
                disabled={disabled || parseInt(inputs.Uses, 10) === -1}
                maxLength={250}
                name="Uses"
                placeholder="Uses"
                size="small"
                type="number"
                value={parseInt(inputs.Uses, 10) === -1 ? "" : inputs.Uses}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control>
              <Label>&nbsp;</Label>
              <Label>
                <Checkbox
                  checked={parseInt(inputs.Uses, 10) === -1}
                  onChange={(e) => {
                    onChange(
                      "Uses",
                      parseInt(inputs.Uses, 10) === -1 ? "0" : "-1"
                    );
                  }}
                />
                &nbsp;Unlimited
              </Label>
            </Control>
            {!adding && (
              <Control expanded size="small">
                <Label>Used</Label>
                <Input
                  disabled
                  maxLength={250}
                  name="Used"
                  placeholder="Used"
                  size="small"
                  type="number"
                  value={inputs.Used}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                />
              </Control>
            )}
          </Field>
        </Column>
        <Column size={6}>
          <Field kind="group">
            <Control expanded size="small">
              <Label>Start Date</Label>
              <Input
                required
                maxLength={250}
                name="StartDate"
                placeholder="StartDate"
                size="small"
                type="date"
                value={new Date(inputs.StartDate).toISOString().split("T")[0]}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded size="small">
              <Label>Expire Date</Label>
              <Input
                required
                disabled={disabled || parseInt(inputs.Uses, 10) === -1}
                maxLength={250}
                name="ExpireDate"
                placeholder="ExpireDate"
                size="small"
                type="date"
                value={
                  !!inputs.ExpireDate
                    ? new Date(inputs.ExpireDate).toISOString().split("T")[0]
                    : ""
                }
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
        </Column>
      </Column.Group>
    </form>
  );
};

CouponForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  formId: PropTypes.string.isRequired,
  adding: PropTypes.bool,
  codeUnique: PropTypes.bool.isRequired,
  onBlurCode: PropTypes.func.isRequired,
  couponTypes: PropTypes.array.isRequired,
};

CouponForm.defaultProps = {
  onSubmit: () => null,
  disabled: false,
  adding: false,
};

export default CouponForm;
