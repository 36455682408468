/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Control, Title } from "rbx";
import { useQuery } from "@apollo/client";
import "rc-collapse/assets/index.css";
import { omit } from "lodash";

import BooleanInput from "../../../../components/BooleanInput";
import "./UserGroupPermissions.scss";

import {
  ALL_PERMISSIONS_QUERY,
  ALL_USER_GROUP_PERMISSIONS_QUERY,
} from "../../../../graphql";

const UserGroupPermissions = ({
  GroupID,
  onChange,
  setOriginalPermissions,
  disabled,
}) => {
  const where = GroupID ? { GroupID: { equals: parseInt(GroupID, 10) } } : {};
  const singleCheckboxPermissions = [
    "Restore Samples",
    "Delete Samples",
    "Add Sample Qualifier",
    "Release COA",
    "Edit Batch Results",
    "Archive Tests",
    "Client Login",
    "Cancel Jobs/Orders",
    "Data Export",
  ];

  const { data: FindManyPermissionsData } = useQuery(ALL_PERMISSIONS_QUERY);
  const { data: FindManyUserGroupPermissionsData } = useQuery(
    ALL_USER_GROUP_PERMISSIONS_QUERY,
    {
      variables: {
        where,
      },
    }
  );

  const [permissions, setPermissions] = useState([]);
  const [inputs, setInputs] = useState({});

  useEffect(() => {
    const perms = FindManyPermissionsData?.findManyPermissions;
    if (Array.isArray(perms)) {
      setPermissions(perms);
    }
  }, [FindManyPermissionsData]);

  useEffect(() => {
    if (GroupID) {
      const userGroupPerms =
        FindManyUserGroupPermissionsData?.findManyUserGroupPermissions;
      if (Array.isArray(userGroupPerms)) {
        setOriginalPermissions(
          userGroupPerms.map((perm) => String(perm.PermissionID))
        );

        setInputs((prev) => ({
          ...prev,
          ...userGroupPerms.reduce(
            (a, c) => ({
              ...a,
              [c.PermissionID]: {
                Update: c.Update,
                View: c.View,
              },
            }),
            {}
          ),
        }));
      }
    }
  }, [FindManyUserGroupPermissionsData]);

  const handleChangeAdditionalPermissions = async (value, PermissionID) => {
    setInputs((prev) => ({
      ...prev,
      [PermissionID]: {
        View: true,
        Update: value,
      },
    }));
  };

  const handleChangePermissionChMod = async (name, value, PermissionID) => {
    setInputs((prevState) => {
      if (Object.keys(prevState).includes(String(PermissionID))) {
        const obj = inputs[PermissionID];

        let newState = {};

        if (name === "Update" && value) {
          newState = {
            ...prevState,
            [PermissionID]: {
              Update: true,
              View: true,
            },
          };
        } else {
          newState = {
            ...prevState,
            [PermissionID]: {
              ...obj,
              [name]: value,
            },
          };
        }
        return newState;
      }

      return prevState;
    });
  };

  useEffect(() => {
    onChange(inputs);
  }, [inputs]);

  const handleChangePermission = async (PermissionID, value) => {
    setInputs((prevState) => {
      if (value) {
        const obj = {
          Update: true,
          View: true,
        };
        return {
          ...prevState,
          [PermissionID]: {
            ...obj,
          },
        };
      }
      const newState = omit(prevState, PermissionID);

      return newState;
    });
  };

  return (
    <Control>
      <br />
      <Title size={5}>Section Permissions</Title>
      <div className="employee-proficiencies-container">
        {permissions.map(
          (perm) =>
            !singleCheckboxPermissions.includes(perm.Name) && (
              <div>
                <Title size={6} style={{ marginBottom: 0, marginTop: "1rem" }}>
                  {perm.Name}
                </Title>
                <div className="chModInput">
                  <BooleanInput
                    disabled={disabled}
                    label="View"
                    name={String(perm.PermissionID)}
                    value={inputs[perm.PermissionID] || false}
                    onChange={handleChangePermission}
                  />
                  <BooleanInput
                    disabled={disabled}
                    label="Update"
                    name="Update"
                    value={
                      inputs[perm.PermissionID]
                        ? inputs[perm.PermissionID].Update
                        : false
                    }
                    onChange={(name, value) =>
                      handleChangePermissionChMod(
                        name,
                        value,
                        perm.PermissionID
                      )
                    }
                  />
                </div>
              </div>
            )
        )}
      </div>
      <br />
      <br />
      <Title size={5}>Additional Permissions</Title>
      <div className="employee-proficiencies-container">
        {permissions.map(
          (perm) =>
            singleCheckboxPermissions.includes(perm.Name) && (
              <div>
                <Title size={6} style={{ marginBottom: 0, marginTop: "1rem" }}>
                  {perm.Name}
                </Title>
                <div className="chModInput">
                  <BooleanInput
                    disabled={disabled}
                    label="Allow"
                    name="Update"
                    value={
                      inputs[perm.PermissionID]
                        ? inputs[perm.PermissionID].Update
                        : false
                    }
                    onChange={(name, value) =>
                      handleChangeAdditionalPermissions(
                        value,
                        perm.PermissionID
                      )
                    }
                  />
                </div>
              </div>
            )
        )}
      </div>
    </Control>
  );
};

UserGroupPermissions.propTypes = {
  GroupID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  setOriginalPermissions: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default UserGroupPermissions;
