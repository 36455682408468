/* eslint-disable no-extra-boolean-cast */
/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Button, Tag, Dropdown, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams, useHistory } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import IntakeForm from "../components/IntakeForm";
import {
  Loader,
  PageHeader,
  Confirmation,
  DataTable,
  ReasonModal,
} from "../../../../../components";

import {
  customToast as toast,
  calculateOrderDueDate,
  getDateEST,
} from "../../../../../utils";
import { useModal, useAuth } from "../../../../../context";
import {
  UPDATE_JOB_MUTATION,
  SINGLE_JOB_QUERY,
  ALL_JOB_ORDER_TESTS_QUERY,
  UPDATE_JOB_ORDER_MUTATION,
  FIRST_SYSTEM_CODE_QUERY,
  CREATE_JOB_LOG_MUTATION,
  ALL_JOB_LOGS_QUERY,
  UPDATE_MANY_JOB_ORDER_TEST_SAMPLES_MUTATION,
  ALL_SYSTEM_CODES_QUERY,
  CANCEL_JOB_MUTATION,
} from "../../../../../graphql";
import { COLUMNS } from "./jobLogColumns";
import "./IntakeEditPage.scss";

const INITIAL_STATE = {
  OrderName: "",
  LabelClaimTHC: "",
  LabelClaimUnitTHC: "",
  LabelClaimCBD: "",
  LabelClaimUnitCBD: "",
  ActualLabelClaimTHC: "",
  ActualLabelClaimUnitTHC: "",
  ActualLabelClaimCBD: "",
  ActualLabelClaimUnitCBD: "",
  CultivationFacility: "",
  ProcessingFacility: "",
  CultivatorsNum: "",
  Cultivators: "",
  CouponID: "",
  ReceiveStatus: "",
  JobID: "",
  Coupon: {
    CouponID: "",
    Code: "",
    Percent: "",
    Discount: "",
    Type: "",
  },
  User: {
    UserID: "",
    Username: "",
    FirstName: "",
    LastName: "",
    Company: "",
    Notes: {
      ClientNotes: "",
      TestNotes: "",
    },
  },
};

const IntakeEditPage = ({ routePermissions }) => {
  const history = useHistory();
  const { JobID, JobOrderID } = useParams();
  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();

  const [canUpdate, setCanUpdate] = useState(true);
  const [disableIntakeStatus, setDisableIntakeStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hasMadeChanges, setHasMadeChanges] = useState(false);
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [allTestApproved, setAllTestApproved] = useState(false);
  const [originalInputs, setOriginalInputs] = useState(INITIAL_STATE);
  const [where, setWhere] = useState({});
  const [hempID, setHempID] = useState(0);
  const [mjID, setMjID] = useState(0);

  const orderBy = [{ id: "CreatedDateTime", desc: true }];

  const [getJobData, resultJobData] = useLazyQuery(SINGLE_JOB_QUERY);
  const [updateJob] = useMutation(UPDATE_JOB_MUTATION);
  const [updateJobOrder] = useMutation(UPDATE_JOB_ORDER_MUTATION);
  const [createJobLog] = useMutation(CREATE_JOB_LOG_MUTATION);
  const [updateJobOrderTestSamples] = useMutation(
    UPDATE_MANY_JOB_ORDER_TEST_SAMPLES_MUTATION
  );
  const [cancelJob] = useMutation(CANCEL_JOB_MUTATION);
  const [getJobOrderTests, { data: jobOrderTestsData }] = useLazyQuery(
    ALL_JOB_ORDER_TESTS_QUERY
  );
  const { data: allGoodIntakeStatusData } = useQuery(FIRST_SYSTEM_CODE_QUERY, {
    variables: {
      where: {
        CodeName: { equals: "IntakeStatus" },
        CodeId: { equals: "All Good" },
      },
    },
  });
  const { data: notReceivedIntakeStatusData } = useQuery(
    FIRST_SYSTEM_CODE_QUERY,
    {
      variables: {
        where: {
          CodeName: { equals: "IntakeStatus" },
          CodeId: { equals: "Sample Not Received" },
        },
      },
    }
  );
  const { data: InProcessOrderStatusData } = useQuery(FIRST_SYSTEM_CODE_QUERY, {
    variables: {
      where: {
        CodeName: { equals: "OrderStatus" },
        CodeId: { equals: "1" },
      },
    },
  });

  const { data: NotReceivedOrderStatusData } = useQuery(
    FIRST_SYSTEM_CODE_QUERY,
    {
      variables: {
        where: {
          CodeName: { equals: "OrderStatus" },
          CodeId: { equals: "-1" },
        },
      },
    }
  );

  const { data: systemCodesData } = useQuery(ALL_SYSTEM_CODES_QUERY, {
    variables: {
      where: {
        CodeName: {
          equals: "SubstanceType",
        },
        OR: [
          {
            CodeId: {
              equals: "Hemp",
            },
          },
          {
            CodeId: {
              equals: "MJ",
            },
          },
        ],
      },
    },
  });

  const canCancelJob = useMemo(() => {
    const job = resultJobData?.data?.findUniqueJobs;
    const currentOrder = job?.JobOrders?.find(
      (jobOrder) => jobOrder.JobOrderID === JobOrderID
    );
    return !!authState.permissions?.find(
      (p) =>
        p.Code === "CANCEL_JOB" &&
        p.Update &&
        currentOrder?.JobOrderStatus?.CodeDescription !== "Cancelled"
    );
  }, [authState.permissions, resultJobData, JobOrderID]);

  const isOrderCancelled = useMemo(() => {
    const job = resultJobData?.data?.findUniqueJobs;
    const orderStatus = job?.JobOrders[0]?.JobOrderStatus?.CodeDescription;
    return orderStatus === "Cancelled";
  }, [resultJobData]);

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  useEffect(() => {
    const job = resultJobData?.data?.findUniqueJobs;
    const currentOrder = job?.JobOrders?.find(
      (jobOrder) => jobOrder.JobOrderID === JobOrderID
    );
    if (currentOrder?.JobOrderStatus?.CodeDescription === "Cancelled") {
      setCanUpdate(false);
    }
  }, [resultJobData, JobOrderID]);

  useEffect(() => {
    setWhere({
      JobID: {
        equals: parseInt(JobID, 10),
      },
    });
  }, [JobID]);

  useEffect(() => {
    if (systemCodesData?.findManySystemCodes) {
      systemCodesData?.findManySystemCodes.forEach((systemCode) => {
        if (systemCode.CodeDescription === "Hemp") {
          setHempID(parseInt(systemCode.RecId, 10));
        } else if (systemCode.CodeDescription === "MJ") {
          setMjID(parseInt(systemCode.RecId, 10));
        }
      });
    }
  }, [systemCodesData]);

  useEffect(() => {
    if (JobOrderID) {
      getJobOrderTests({
        variables: {
          where: {
            JobOrderID: { equals: parseInt(JobOrderID, 10) },
          },
        },
      });
      setHasMadeChanges(false);
    }
  }, [getJobOrderTests, JobOrderID]);

  useEffect(() => {
    if (jobOrderTestsData?.findManyJobOrderTests) {
      const { findManyJobOrderTests } = jobOrderTestsData;
      const approvedOrArchived = findManyJobOrderTests.filter(
        (jobTest) => jobTest.Approved || jobTest.Archived
      );
      setAllTestApproved(
        findManyJobOrderTests.length === approvedOrArchived.length
      );
    }
  }, [jobOrderTestsData]);

  useEffect(() => {
    const findUniqueJobs = resultJobData?.data?.findUniqueJobs;
    const allGoodState = allGoodIntakeStatusData?.findFirstSystemCodes;
    if (findUniqueJobs && allGoodState) {
      if (!JobOrderID && findUniqueJobs.JobOrders.length) {
        history.push(
          `/lims/intake/${JobID}/${findUniqueJobs.JobOrders[0].JobOrderID}`
        );
      }
    }
  }, [resultJobData, allGoodIntakeStatusData, JobOrderID, history, JobID]);

  useEffect(() => {
    const findUniqueJobs = resultJobData?.data?.findUniqueJobs;
    const allGoodState = allGoodIntakeStatusData?.findFirstSystemCodes;
    if (findUniqueJobs && allGoodState) {
      const selectedOrder = findUniqueJobs.JobOrders.find(
        (jobOrder) => Number(jobOrder.JobOrderID) === Number(JobOrderID)
      );
      if (Number(selectedOrder?.Status) === Number(allGoodState.RecId)) {
        setDisableIntakeStatus(true);
      } else {
        setDisableIntakeStatus(false);
      }
    }
  }, [JobOrderID, allGoodIntakeStatusData, resultJobData]);

  const uploadJobOrderPath = useMemo(() => {
    const date = new Date();
    return `/Images/JobOrders/${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${JobOrderID}`;
  }, [JobOrderID]);

  useEffect(() => {
    if (JobID) {
      getJobData({
        variables: {
          where: { JobID: parseInt(JobID, 10) },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [JobID, getJobData]);

  useEffect(() => {
    let findUniqueJobs = resultJobData?.data?.findUniqueJobs;
    if (findUniqueJobs) {
      const JobOrders = [...resultJobData?.data?.findUniqueJobs?.JobOrders];
      const JobOrdersUpdated = [];
      JobOrders &&
        Array.isArray(JobOrders) &&
        JobOrders.forEach((JobOrder) => {
          const { OverrideData } = JobOrder;
          let fieldsChanged = {};
          OverrideData &&
            Object.keys(OverrideData).reduce((acc, curr) => {
              fieldsChanged = {
                ...fieldsChanged,
                [`${curr}Changed`]: true,
              };
              return acc;
            }, {});
          JobOrdersUpdated.push({
            ...JobOrder,
            ...fieldsChanged,
            ...JobOrder.LabelClaim,
            ...JobOrder.MMTCInfo,
            ...JobOrder.BatchInfo,
          });
        });

      findUniqueJobs = { ...findUniqueJobs, JobOrders: JobOrdersUpdated };
      setInputs((prev) => {
        const newInputs = {
          ...prev,
          ...findUniqueJobs,
        };
        setOriginalInputs(newInputs);
        return newInputs;
      });

      setLoading(false);
    }
  }, [resultJobData?.data?.findUniqueJobs]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleOrderChange = (name, value, notOverrideData = false) => {
    setHasMadeChanges(true);
    if (name.includes("Changed")) {
      const nameChanged = name.replace("Changed", "");
      const orderToEdit = inputs.JobOrders.find(
        (jobOrder) => jobOrder.JobOrderID === JobOrderID
      );
      const OverrideData = { ...orderToEdit.OverrideData };
      if (!value) {
        delete OverrideData[nameChanged];
      } else {
        OverrideData[nameChanged] = orderToEdit[nameChanged];
      }
      setInputs((prev) => ({
        ...prev,
        JobOrders: prev.JobOrders.map((jobOrder) =>
          jobOrder.JobOrderID === JobOrderID
            ? { ...orderToEdit, OverrideData, [name]: value }
            : jobOrder
        ),
      }));
    } else if (!notOverrideData) {
      setInputs((prev) => ({
        ...prev,
        JobOrders: prev.JobOrders.map((jobOrder) =>
          jobOrder.JobOrderID === JobOrderID
            ? {
                ...jobOrder,
                OverrideData: { ...jobOrder.OverrideData, [name]: value },
              }
            : jobOrder
        ),
      }));
    } else if (notOverrideData) {
      setInputs((prev) => ({
        ...prev,
        JobOrders: prev.JobOrders.map((jobOrder) =>
          jobOrder.JobOrderID === JobOrderID
            ? {
                ...jobOrder,
                [name]: value,
              }
            : jobOrder
        ),
      }));
    }
  };

  const handleCancelLabelPrint = () => {
    setModalOpen(false, "");
    history.push("/lims/intake");
  };

  const handleConfirmLabelPrint = (jobOrderID) => {
    setModalOpen(false, "");
    const win = window.open(`/lims/labels/JobOrderID-${jobOrderID}`);
    win.focus();
  };

  const handleSaveJobOrder = async (jobOrder) => {
    const isPictureRequired = () => {
      if (!!jobOrder?.OverrideData?.SubstanceType) {
        return (
          jobOrder?.OverrideData?.SubstanceType === hempID ||
          jobOrder?.OverrideData?.SubstanceType === mjID
        );
      }
      if (
        jobOrder.SubstanceType === "Hemp" ||
        jobOrder.SubstanceType === "MJ"
      ) {
        return true;
      }
      return false;
    };

    if (isPictureRequired && !jobOrder.Picture.length) {
      toast.error("A picture is required.");
      return;
    }
    const originalWorkingJobOrder = originalInputs.JobOrders.find(
      (element) => element.JobOrderID === JobOrderID
    );

    // checking changes on the form below (not tabs)
    const jobOrderKeys = [
      "Status",
      "Picture",
      "NetWeight",
      "NetWeightUnit",
      "PickupWeight",
      "InternalNotes",
      "COANotes",
      "Important",
    ];

    const modifications = [];
    jobOrderKeys.forEach((key) => {
      if (
        jobOrder[key] !== originalWorkingJobOrder[key] &&
        !!originalWorkingJobOrder[key]?.length
      ) {
        modifications.push(
          `updated ${key} from ${originalWorkingJobOrder[key]} to ${jobOrder[key]}`
        );
      }
    });

    // checking changes on override data
    const overrideKeys = jobOrder.OverrideData
      ? Object.keys(jobOrder.OverrideData)
      : [];

    if (
      jobOrder?.OverrideData &&
      !!overrideKeys.length &&
      originalWorkingJobOrder?.OverrideData
    ) {
      overrideKeys.forEach((key) => {
        if (
          originalWorkingJobOrder.OverrideData[key] &&
          originalWorkingJobOrder.OverrideData[key] !==
            jobOrder?.OverrideData[key]
        ) {
          modifications.push(
            `updated ${key} from ${originalWorkingJobOrder.OverrideData[key]} to ${jobOrder?.OverrideData[key]}`
          );
        }

        // checks if changed value was not checked but it still exists
        if (
          originalWorkingJobOrder[key] !== undefined &&
          // eslint-disable-next-line no-prototype-builtins
          !originalWorkingJobOrder.OverrideData.hasOwnProperty(key) &&
          originalWorkingJobOrder[key] !== jobOrder?.OverrideData[key]
        ) {
          modifications.push(
            `updated ${key} from ${originalWorkingJobOrder[key]} to ${jobOrder?.OverrideData[key]}`
          );
        }
      });
    }

    // apply JobOrderStatus based on IntakeStatus
    const allGoodStatus = allGoodIntakeStatusData?.findFirstSystemCodes;
    const notReceivedIntakeStatus =
      notReceivedIntakeStatusData?.findFirstSystemCodes;
    const inProcessStatus = InProcessOrderStatusData?.findFirstSystemCodes;
    const notReceivedOrderStatus =
      NotReceivedOrderStatusData?.findFirstSystemCodes;

    let jobOrderStatus = "";
    if (Number(jobOrder?.Status) === Number(allGoodStatus?.RecId)) {
      jobOrderStatus = inProcessStatus.RecId;
    } else if (
      Number(jobOrder?.Status) === Number(notReceivedIntakeStatus?.RecId)
    ) {
      jobOrderStatus = notReceivedOrderStatus.RecId;
    }

    let data = {
      OverrideData:
        jobOrder.OverrideData && Object.keys(jobOrder.OverrideData).length
          ? {
              Override: true,
              ...jobOrder.OverrideData,
            }
          : {},
      IntakeStatus: jobOrder.Status
        ? { connect: { RecId: parseInt(jobOrder.Status, 10) } }
        : undefined,
      JobOrderStatus: jobOrderStatus
        ? { connect: { RecId: parseInt(jobOrderStatus, 10) } }
        : {
            connect: {
              RecId: parseInt(jobOrder.JobOrderStatus.RecId, 10) || undefined,
            },
          },
      Picture: { set: jobOrder.Picture },
      NetWeight: { set: parseFloat(jobOrder.NetWeight) },
      NetWeightUnit: { set: jobOrder.NetWeightUnit },
      PickupWeight: { set: jobOrder.PickupWeight },
      InternalNotes: { set: jobOrder.InternalNotes },
      COANotes: { set: jobOrder.COANotes },
      Important: { set: jobOrder.Important },
      IntakeProcessor: { set: authState.user.UserID },
      ClientNotes: { set: jobOrder.ClientNotes },
    };

    // If intake status changed to All Good, set OrderReceiveDate and OrderDueDate
    if (
      Number(jobOrder?.Status) === Number(allGoodStatus?.RecId) &&
      Number(originalWorkingJobOrder?.Status) !== Number(allGoodStatus?.RecId)
    ) {
      const userTurnAroundDays =
        resultJobData?.data?.findUniqueJobs?.User?.TurnAroundDays;
      const labState = resultJobData?.data?.findUniqueJobs?.Lab?.State;
      const shippingType =
        resultJobData?.data?.findUniqueJobs?.ShippingSystemCode
          ?.CodeDescription;
      const substanceType = jobOrder?.SubstanceTypeSystemCode?.CodeDescription;

      const dueDate = calculateOrderDueDate(
        labState,
        substanceType,
        shippingType,
        userTurnAroundDays
      );

      data = {
        ...data,
        OrderReceiveDate: {
          set: new Date(),
        },
        OrderDueDate: {
          set: new Date(dueDate),
        },
      };
    }

    try {
      setLoading(true);
      await updateJobOrder({
        variables: {
          where: {
            JobOrderID: parseInt(jobOrder.JobOrderID, 10),
          },
          data,
        },
      });

      if (
        inputs.JobOrders.every(
          (order) => Number(order?.Status) === Number(allGoodStatus?.RecId)
        )
      ) {
        await updateJob({
          variables: {
            data: {
              ReceiveStatus: {
                set: 1,
              },
              JobReceiveDate: { set: new Date() },
            },
            where: {
              JobID: parseInt(JobID, 10),
            },
          },
        });
      }

      if (modifications.length) {
        await createJobLog({
          variables: {
            data: {
              JobID: parseInt(JobID, 10),
              JobOrderID: parseInt(JobOrderID, 10),
              Change: modifications.join(", "),
              CreatedBy: authState.user.Username,
              ModifiedBy: authState.user.Username,
            },
          },
        });
      }
      await updateJobOrderTestSamples({
        variables: {
          where: {
            JobOrderID: {
              equals: parseInt(JobOrderID, 10),
            },
          },
          data: {
            Important: {
              set: jobOrder.Important,
            },
          },
        },
        refetchQueries: [
          {
            query: SINGLE_JOB_QUERY,
            variables: {
              where: { JobID: parseInt(JobID, 10) },
            },
            fetchPolicy: "network-only",
          },
        ],
      });

      if (Number(jobOrder.Status) === Number(allGoodStatus.RecId)) {
        setModalOpen(
          true,
          <Confirmation
            cancelText="Intake Another Job"
            confirmText="Print Order Labels"
            message="Would you like to print labels for this Order?"
            onCancel={handleCancelLabelPrint}
            onConfirm={() => handleConfirmLabelPrint(jobOrder.JobOrderID)}
          />
        );
      }
    } catch (e) {
      toast.error("Error updating Job Order");
    } finally {
      setLoading(false);
      setHasMadeChanges(false);
    }
  };
  const handleConfirmChanges = (jobOrder) => {
    setModalOpen(
      true,
      <Confirmation
        cancelText="Continue without saving"
        confirmText="Save Changes"
        message={`There are unsaved changes for order ${JobOrderID}. Would you like to save the changes?`}
        onCancel={() => setModalOpen(false, "")}
        onConfirm={() => {
          handleSaveJobOrder(jobOrder);
          setModalOpen(false, "");
        }}
      />
    );
  };

  const handleCancelJob = (e) => {
    e.preventDefault();
    const performCancelJob = async (reason) => {
      const { data: cancelResultData } = await cancelJob({
        variables: {
          data: {
            JobOrderID: parseInt(JobOrderID, 10),
            JobID: parseInt(JobID, 10),
            Change: reason,
            CreatedBy: authState.user.Username,
          },
        },
        refetchQueries: [
          {
            query: SINGLE_JOB_QUERY,
            variables: {
              where: { JobID: parseInt(JobID, 10) },
            },
            fetchPolicy: "network-only",
          },
        ],
      });
      if (cancelResultData?.cancelJob) {
        toast.success("Job cancelled successfully!");
        setModalOpen(false);
      } else {
        toast.error("Error when trying to cancel Job");
      }
    };
    setModalOpen(
      true,
      <ReasonModal
        message="Are you sure you would like to cancel this Job and all Job Orders?"
        onCancel={() => setModalOpen(false, "")}
        onConfirm={(reason) => performCancelJob(reason)}
      />
    );
  };

  const handleCancelJobOrder = (e) => {
    e.preventDefault();
    const performCancelJob = async (reason) => {
      const { data: cancelResultData } = await cancelJob({
        variables: {
          data: {
            JobOrderID: parseInt(JobOrderID, 10),
            JobID: parseInt(JobID, 10),
            Change: reason,
            CreatedBy: authState.user.Username,
          },
          where: {
            JobOrderID: { equals: parseInt(JobOrderID, 10) },
          },
        },
        refetchQueries: [
          {
            query: SINGLE_JOB_QUERY,
            variables: {
              where: { JobID: parseInt(JobID, 10) },
            },
            fetchPolicy: "network-only",
          },
        ],
      });
      if (cancelResultData?.cancelJob) {
        toast.success(`Order ${JobOrderID} was cancelled successfully!`);
        setModalOpen(false);
      } else {
        toast.error("Error when trying to cancel Order");
      }
    };
    setModalOpen(
      true,
      <ReasonModal
        message={`Are you sure you would like to cancel Job Order ${JobOrderID}?`}
        onCancel={() => setModalOpen(false, "")}
        onConfirm={(reason) => performCancelJob(reason)}
      />
    );
  };

  const handleInternalNoteSubmit = async (note, currentNotes) => {
    setLoading(true);
    try {
      const notes = [];
      const dateAdded = getDateEST({
        timeZone: "America/New_York",
        hour12: true,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });

      try {
        const currNotes = JSON.parse(currentNotes);
        notes.push(...currNotes);
      } catch (e) {
        if (currentNotes) {
          const newNote = {
            note: currentNotes,
            userIDAdded: authState.user.Username,
            dateAdded,
          };
          notes.push(newNote);
        }
      }
      const newNote = {
        note,
        userIDAdded: authState.user.Username,
        dateAdded,
      };
      notes.push(newNote);

      const notesToSend = JSON.stringify(notes);

      await updateJobOrder({
        variables: {
          data: {
            InternalNotes: {
              set: notesToSend,
            },
          },
          where: {
            JobOrderID: parseInt(JobOrderID, 10),
          },
        },
        refetchQueries: [
          {
            query: SINGLE_JOB_QUERY,
            variables: {
              where: { JobID: parseInt(JobID, 10) },
            },
            fetchPolicy: "network-only",
          },
        ],
      });
      return toast.success("Internal Note added successfully.");
    } catch (err) {
      return toast.error("Error adding Note.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="job-order-list-page">
      <PageHeader
        style={{
          paddingLeft: 0,
          position: "fixed",
          top: "3rem",
          width: "85%",
          zIndex: 998,
          paddingTop: 16,
          paddingRight: "1rem",
          background: "#f7fafd",
        }}
        title={`${JobID} - ${JobOrderID}`}
      >
        {!isOrderCancelled && (
          <Tag color={allTestApproved ? "cyan" : "warning"}>
            {allTestApproved
              ? "All Tests Approved"
              : "Waiting for Test Approval"}
          </Tag>
        )}
        {canCancelJob && (
          <Dropdown>
            <Dropdown.Trigger>
              <Button color="danger" size="small">
                <span>Cancel</span>
                <Icon size="small">
                  <FontAwesomeIcon icon="angle-down" />
                </Icon>
              </Button>
            </Dropdown.Trigger>
            <Dropdown.Menu>
              <Dropdown.Content>
                <Dropdown.Item onClick={handleCancelJobOrder}>
                  Cancel Order {JobOrderID}
                </Dropdown.Item>
                <Dropdown.Item onClick={handleCancelJob}>
                  Cancel Job and all Orders
                </Dropdown.Item>
              </Dropdown.Content>
            </Dropdown.Menu>
          </Dropdown>
        )}
        <Button
          color="primary"
          disabled={!canUpdate}
          form="edit-job-order-form"
          size="small"
          type="submit"
        >
          Save Order
        </Button>
      </PageHeader>
      <div>
        <form
          id="edit-job-order-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSaveJobOrder(
              inputs.JobOrders.find(
                (jobOrder) =>
                  parseInt(jobOrder.JobOrderID, 10) === parseInt(JobOrderID, 10)
              )
            );
          }}
        >
          <IntakeForm
            JobData={resultJobData?.data?.findUniqueJobs}
            JobID={JobID}
            JobOrderID={JobOrderID}
            disableIntakeStatus={disableIntakeStatus}
            disabled={!canUpdate}
            handleConfirmChanges={handleConfirmChanges}
            handleOrderChange={handleOrderChange}
            hasMadeChanges={hasMadeChanges}
            history={history}
            inputs={inputs}
            substanceIDs={{ mjID, hempID }}
            uploadJobOrderPath={uploadJobOrderPath}
            onChange={handleChange}
            onInternalNoteSubmit={handleInternalNoteSubmit}
          />
        </form>
      </div>
      <br />
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateJobLogs"
        columns={COLUMNS}
        fetchPolicy="network-only"
        name="findManyJobLogs"
        orderBy={orderBy}
        query={ALL_JOB_LOGS_QUERY}
        title="Job Logs"
        where={where}
      />
    </div>
  );
};

IntakeEditPage.propTypes = {
  routePermissions: PropTypes.array,
};

IntakeEditPage.defaultProps = {
  routePermissions: [],
};

IntakeEditPage.propTypes = {};

export default IntakeEditPage;
