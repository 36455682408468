import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Icon, Generic } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CART_COUNT_QUERY } from "../../graphql";
import { useAuth } from "../../context/AuthContext";
import "../Navigation/Sidebar.scss";

const BadgeCart = () => {
  const history = useHistory();
  const [cartCount, setCartCount] = useState(0);
  const { state: authState } = useAuth();
  const [getCartCount, { data: cartCountData }] =
    useLazyQuery(CART_COUNT_QUERY);

  useEffect(() => {
    if (authState?.user) {
      getCartCount({
        variables: {
          where: {
            UserID: parseInt(authState.user.UserID, 10),
          },
        },
      });
    }
  }, [getCartCount, authState]);

  useEffect(() => {
    setCartCount(cartCountData?.cartCount || 0);
  }, [cartCountData]);

  return (
    <Generic
      style={{ cursor: "pointer", paddingTop: "1rem" }}
      onClick={() => history.push("/cart")}
    >
      <Icon style={{ cursor: "pointer" }} textColor="white">
        <FontAwesomeIcon icon="shopping-cart" />
      </Icon>
      <p className="badge badge-warning">{cartCount}</p>
    </Generic>
  );
};
BadgeCart.propTypes = {};

export default BadgeCart;
