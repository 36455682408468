import { gql } from "@apollo/client";

export const ALL_TEST__TEST_CATEGORIES_QUERY = gql`
  query ALL_TEST__TEST_CATEGORIES_QUERY(
    $where: TestTestCategoriesWhereInput
    $orderBy: [TestTestCategoriesOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyTestTestCategories(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      TestTestCategoryID
      TestID
      TestCategoryID
      CreatedBy
      ModifiedBy
      CreatedDateTime
      ModifiedDateTime
    }
    aggregateTestTestCategories(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const SINGLE_TEST_TEST_CATEGORY_QUERY = gql`
  query SINGLE_TEST_TEST_CATEGORY_QUERY($where: TestTestCategoriesWhereUniqueInput!) {
    findUniqueTestTestCategories(where: $where) {
      TestTestCategoryID
      TestID
      TestCategoryID
      CreatedBy
      ModifiedBy
      CreatedDateTime
      ModifiedDateTime
    }
  }
`;
