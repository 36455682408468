/* eslint-disable react/prop-types */
import { format } from "date-fns";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { formatCurrency } from "../../../../utils";
import { TableCell } from "../../../../components/DataTable";

export const COLUMNS = [
  {
    Header: "Username",
    id: "Username",
    accessor: "plUser.Username",
    disableSortBy: true,
  },
  {
    Header: "Total Commissions",
    id: "total",
    accessor: "total",
    Cell: ({ cell }) =>
      cell?.value ? formatCurrency(cell.value) : formatCurrency(0),
  },
];

export const SUB_COLUMNS = [
  {
    Header: "COA ID",
    id: "PrivateLabelCOA.COAID",
    accessor: "PrivateLabelCOA.COAID",
  },
  {
    Header: "Order ID",
    id: "PrivateLabelCOA.JobOrderID",
    accessor: "PrivateLabelCOA.JobOrderID",
  },
  {
    Header: "COA Purchase Date",
    id: "PrivateLabelCOA.PurchaseDate",
    accessor: "PrivateLabelCOA.PurchaseDate",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM-dd-yyyy") : "",
  },
  {
    Header: "Coupon Discount",
    id: "PrivateLabelCOA.CouponDiscount",
    accessor: "PrivateLabelCOA.CouponDiscount",
    Cell: TableCell.Currency,
  },
  {
    Header: "Payment Amount",
    id: "PrivateLabelCOA.PaymentAmount",
    accessor: "PrivateLabelCOA.PaymentAmount",
    Cell: TableCell.Currency,
  },
  {
    Header: "Percentage",
    id: "Percentage",
    accessor: "Percentage",
    Cell: ({ cell }) => `${cell.value}%`,
  },
  {
    Header: "Commission",
    id: "Total",
    accessor: "Total",
    Cell: TableCell.Currency,
  },
];

export const generateSubColumns = (handleClickCOA) => [
  ...SUB_COLUMNS,
  {
    Header: "COA ID",
    id: "COAID",
    accessor: "PrivateLabelCOA.COAID",
    Cell: ({ cell }) => (
      <Button
        color="primary"
        style={{ borderRadius: "20px 0 20px 20px" }}
        onClick={() =>
          handleClickCOA(
            cell.row.original.PrivateLabelCOA?.JobOrderID,
            cell.value
          )
        }
      >
        <Icon>
          <FontAwesomeIcon icon="download" />
        </Icon>
      </Button>
    ),
  },
];
