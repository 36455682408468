import React from "react";
import PropTypes from "prop-types";
import { Box, Field, Control, Label, Button, Column } from "rbx";
import { StatusSelect, LabLocationSelect } from "../../../../components";

const EmployeeFilters = ({ onChange, inputs, onReset, canSearchByLab }) => (
  <Box>
    <Column.Group>
      <Column size={canSearchByLab ? 5.5 : 11}>
        <Field>
          <StatusSelect
            getValue={(x) => x?.equals?.toString() || ""}
            id="status"
            label="Status"
            name="Status"
            setValue={(x) => (typeof x === "number" ? { equals: x } : "")}
            size="small"
            value={inputs.Status}
            onChange={onChange}
          />
        </Field>
      </Column>
      {canSearchByLab && (
        <Column size={5.5}>
          <Field>
            <LabLocationSelect
              showLabel
              getValue={(x) => (typeof x?.equals === "number" ? x?.equals : "")}
              name="LabID"
              setValue={(x) => (typeof x === "number" ? { equals: x } : {})}
              size="small"
              value={inputs?.LabID}
              onChange={onChange}
            />
          </Field>
        </Column>
      )}
      <Column size={1}>
        <Field>
          <Control>
            <Label>&nbsp;</Label>
            <Button color="primary" size="small" onClick={onReset}>
              Reset
            </Button>
          </Control>
        </Field>
      </Column>
    </Column.Group>
  </Box>
);

EmployeeFilters.propTypes = {
  onChange: PropTypes.func,
  inputs: PropTypes.object,
  onReset: PropTypes.func,
  canSearchByLab: PropTypes.bool,
};

EmployeeFilters.defaultProps = {
  onChange: (e) => e,
  inputs: {},
  onReset: (e) => e,
  canSearchByLab: false,
};

export default EmployeeFilters;
