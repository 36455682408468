import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const ALL_USER_GROUP_MEMBERSHIPS_QUERY = gql`
  query ALL_USER_GROUP_MEMBERSHIPS_QUERY(
    $where: UserGroupMembershipsWhereInput
    $orderBy: [UserGroupMembershipsOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyUserGroupMemberships(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      UserGroupMembershipID
      GroupID
      UserID
      CreatedDateTime
      CreatedBy
      ModifiedDateTime
      ModifiedBy
    }
  }
`;
