import React from "react";
import PropTypes from "prop-types";
import { Column, Field } from "rbx";

import BooleanInput from "../../../../components/BooleanInput";

const LabPaymentOptionsForm = ({
  inputs,
  onChange,
  disabled,
  adding,
  onSubmit,
}) => (
  <form id="lab-account-details" onSubmit={onSubmit}>
    <Column.Group multiline>
      <Column size={12}>
        <Field kind="group">
          <BooleanInput
            disabled={disabled}
            label="Allow Terms"
            name="PaymentOptions.Terms"
            value={inputs.PaymentOptions.Terms}
            onChange={onChange}
          />
        </Field>
        <Field kind="group">
          <BooleanInput
            disabled={disabled}
            label="Allow Credit Card Payments"
            name="PaymentOptions.CC"
            value={inputs.PaymentOptions.CC}
            onChange={onChange}
          />
        </Field>
      </Column>
    </Column.Group>
  </form>
);

LabPaymentOptionsForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  adding: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

LabPaymentOptionsForm.defaultProps = {
  disabled: false,
  adding: false,
};

export default LabPaymentOptionsForm;
