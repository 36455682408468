import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { Button } from "rbx";
import { DataTable, PageHeader, ListSearchInput } from "../../../components";
import { ALL_JOB_ORDERS_QUERY, EXPORT_DATA_QUERY } from "../../../graphql";
import { DEFAULT_COLUMNS } from "./columns";
import { JobOrdersFilters } from "../components";
import { debounce, download } from "../../../utils";
import { useAuth } from "../../../context";

class CustomSnapshotError extends Error {}

const INITIAL_STATE = {
  OrderCompleteDate: {
    not: {
      equals: null,
    },
  },
};

const JobOrdersExportTab = () => {
  const client = useApolloClient();
  const { state: authState } = useAuth();
  const [where, setWhere] = useState(INITIAL_STATE);
  const [exportLoading, setExportLoading] = useState(false);
  const [lab, setLab] = useState("");

  const orderBy = [{ id: "JobID", desc: true }];

  useEffect(() => {
    setLab(authState.user.Lab);
    if (authState.user.Lab?.Company !== "All Location") {
      setWhere({
        ...INITIAL_STATE,
        Job: {
          is: {
            LabID: {
              equals: parseInt(authState.user.LabID, 10),
            },
          },
        },
      });
    }
  }, [authState]);

  const handleFilterChange = (name, value) => {
    setWhere((prev) => {
      if (value) {
        if (name === "JobOrder") {
          return {
            ...prev,
            JobOrder: {
              is: {
                ...prev?.JobOrder?.is,
                ...value.JobOrder.is,
              },
            },
          };
        }

        return {
          ...prev,
          [name]: value,
        };
      }

      return Object.keys(prev)
        .filter((x) => x !== name)
        .reduce((a, c) => ({ ...a, [c]: prev[c] }), {});
    });
  };

  const handleFiltersReset = () => {
    if (lab?.Company !== "All Location") {
      setWhere({
        ...INITIAL_STATE,
        Job: {
          is: {
            LabID: {
              equals: parseInt(authState.user.LabID, 10),
            },
          },
        },
      });
    } else {
      setWhere(INITIAL_STATE);
    }
  };

  const handleExport = async () => {
    try {
      setExportLoading(true);
      const { data: JobOrdersData } = await client.query({
        query: ALL_JOB_ORDERS_QUERY,
        variables: {
          where,
        },
        fetchPolicy: "network-only",
      });
      if (Array.isArray(JobOrdersData.findManyJobOrders)) {
        const jobOrderIds = JobOrdersData.findManyJobOrders.map((order) =>
          parseInt(order.JobOrderID, 10)
        );
        const { data: ExportData } = await client.query({
          query: EXPORT_DATA_QUERY,
          variables: {
            jobOrderIds: [...jobOrderIds],
          },
          fetchPolicy: "no-cache",
        });

        if (ExportData) {
          download(`JobOrders.csv`, "csv", ExportData.exportData);
        }
      }
    } catch (err) {
      const message =
        err instanceof CustomSnapshotError
          ? err.message
          : "Whoops! We encountered an error performing this action. Please contact support.";

      toast.error(message);
    } finally {
      setExportLoading(false);
    }
  };

  return (
    <div className="job-list-page">
      <PageHeader title="Job Orders">
        <ListSearchInput
          placeholder="Job ID or Order ID..."
          onChange={debounce((name, value) => {
            if (value) {
              setWhere((prev) => ({
                ...prev,
                OR: [
                  { JobID: { equals: value } },
                  { JobOrderID: { equals: value } },
                ],
              }));
            } else {
              setWhere({});
            }
          }, 500)}
        />
        <Button
          as="a"
          color="primary"
          state={exportLoading ? "loading" : ""}
          target="_blank"
          type="button"
          onClick={() => handleExport()}
        >
          Download Latest CSV Results
        </Button>
      </PageHeader>
      <JobOrdersFilters
        lab={lab}
        where={where}
        onChange={handleFilterChange}
        onReset={handleFiltersReset}
      />
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateJobOrders"
        columns={DEFAULT_COLUMNS}
        name="findManyJobOrders"
        orderBy={orderBy}
        query={ALL_JOB_ORDERS_QUERY}
        where={where}
      />
    </div>
  );
};

JobOrdersExportTab.propTypes = {};

export default JobOrdersExportTab;
