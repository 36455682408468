import { gql } from "@apollo/client";

export const DELETE_TEST_TEST_CATEGORY_MUTATION = gql`
  mutation DELETE_TEST_TEST_CATEGORY_MUTATION($where: TestTestCategoriesWhereUniqueInput!) {
    deleteTestTestCategories(where: $where) {
      TestTestCategoryID
    }
  }
`;

export const CREATE_TEST_TEST_CATEGORY_MUTATION = gql`
  mutation CREATE_TEST_TEST_CATEGORY_MUTATION($data: TestTestCategoriesCreateInput!) {
    createTestTestCategories(data: $data) {
      TestTestCategoryID
    }
  }
`;

export const CREATE_MANY_TEST_TEST_CATEGORIES_MUTATION = gql`
  mutation CREATE_MANY_TEST_TEST_CATEGORIES_MUTATION(
    $data: [TestTestCategoriesCreateManyInput!]!
  ) {
    createManyTestTestCategories(data: $data) {
      count
    }
  }
`;


export const DELETE_MANY_TEST_TEST_CATEGORIES_MUTATION = gql`
  mutation DELETE_MANY_TEST_TEST_CATEGORIES_MUTATION($where: TestTestCategoriesWhereInput) {
    deleteManyTestTestCategories(where: $where) {
      count
    }
  }
`;

