/* eslint-disable consistent-return */
import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useQuery } from "@apollo/client";

import { ALL_LABS_QUERY } from "../../graphql";

const LabLocationSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
  showLabel,
  showAllLocation,
}) => {
  const { data: getLabData } = useQuery(ALL_LABS_QUERY, {
    variables: { orderBy: { Company: "asc" } },
    fetchPolicy: "network-only",
  });

  const labs = getLabData?.findManyLabs;

  const indicateRequired = required && (value === "" || value === null);

  const handleChange = (e) => {
    onChange(
      e.target.name,
      setValue(e.target.value ? parseInt(e.target.value, 10) : "")
    );
  };

  return (
    <Control>
      {showLabel && <Label>{label}</Label>}
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(labs) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          required={required}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          <Select.Option value="" />
          {Array.isArray(labs) &&
            // eslint-disable-next-line array-callback-return
            labs.map((lab) => {
              if (
                showAllLocation ||
                (!showAllLocation && lab.Company !== "All Location")
              ) {
                return (
                  <Select.Option key={lab.LabID} value={lab.LabID}>
                    {lab.Company}
                  </Select.Option>
                );
              }
            })}
        </Select>
      </Select.Container>
    </Control>
  );
};

LabLocationSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  required: PropTypes.bool,
  showLabel: PropTypes.bool,
  showAllLocation: PropTypes.bool,
};

LabLocationSelect.defaultProps = {
  value: "",
  name: "",
  label: "Lab Location",
  size: "small",
  required: false,
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  showLabel: true,
  showAllLocation: true,
};

export default LabLocationSelect;
