import React, { useState } from "react";
import PropTypes from "prop-types";

import { Input, Control, Label } from "rbx";
import { formatPhoneNumber } from "../../utils";

const PhoneNumberInput = ({
  name,
  value,
  onChange,
  disabled,
  maxLength,
  required,
  placeholder,
  size,
  label,
}) => {
  const [valid, setValid] = useState(true);

  const handleChange = (e) => {
    const [isValid, phoneNumber] = formatPhoneNumber(
      e.target.value.replace(/[^\d()\s-]/gi, "")
    );
    setValid(phoneNumber.length ? isValid : true);
    onChange(name, phoneNumber);
  };

  return (
    <Control expanded size={size}>
      {!!label.length && <Label>{label}</Label>}
      <Input
        autoComplete="new"
        disabled={disabled}
        maxLength={maxLength}
        name={name}
        placeholder={placeholder}
        required={required}
        size={size}
        state={valid ? "" : "warning"}
        type="tel"
        value={value}
        onChange={handleChange}
      />
    </Control>
  );
};

PhoneNumberInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string,
};

PhoneNumberInput.defaultProps = {
  name: "",
  placeholder: "Phone",
  value: "",
  maxLength: 14,
  disabled: false,
  required: false,
  onChange: () => null,
  size: "small",
  label: "",
};

export default PhoneNumberInput;
