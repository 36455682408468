import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Control, Button, Label, Textarea } from "rbx";
import { orderBy } from "lodash";
import PropTypes from "prop-types";

import { useAuth } from "../../../../context";
import { Loader, PageHeader } from "../../../../components";
import {
  SINGLE_JOB_ORDER_QUERY,
  UPDATE_JOB_ORDER_MUTATION,
} from "../../../../graphql";
import { customToast as toast, getDateEST } from "../../../../utils";
import "./InternalNotesEditPage.scss";

const InternalNotesEditPage = ({ routePermissions }) => {
  const { JobOrderID } = useParams();

  const { state: authState } = useAuth();

  const [canUpdate, setCanUpdate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [internalNotes, setInternalNotes] = useState([]);
  const [value, setValue] = useState("");

  const [updateJobOrder] = useMutation(UPDATE_JOB_ORDER_MUTATION);
  const [getJobOrderData, resultJobOrderData] = useLazyQuery(
    SINGLE_JOB_ORDER_QUERY,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  useEffect(() => {
    if (JobOrderID) {
      setLoading(true);
      try {
        getJobOrderData({
          variables: {
            where: {
              JobOrderID: parseInt(JobOrderID, 10),
            },
          },
        });
      } catch (err) {
        toast.error("Error Fetching Job Order.");
      }
    }
    return () => {};
  }, [JobOrderID, getJobOrderData]);

  useEffect(() => {
    if (resultJobOrderData?.data) {
      const {
        data: { findUniqueJobOrders },
      } = resultJobOrderData;

      if (findUniqueJobOrders.InternalNotes) {
        try {
          setInternalNotes(JSON.parse(findUniqueJobOrders.InternalNotes));
        } catch (e) {
          const dateAdded = getDateEST({
            timeZone: "America/New_York",
            hour12: true,
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          });
          const newNote = {
            note: findUniqueJobOrders.InternalNotes,
            userIDAdded: authState.user.Username,
            dateAdded,
          };
          setInternalNotes([newNote]);
        }
      }

      setLoading(false);
    }
  }, [authState, resultJobOrderData, value]);

  const onSubmit = async () => {
    setLoading(true);
    try {
      const dateAdded = getDateEST({
        timeZone: "America/New_York",
        hour12: true,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });

      const newNote = {
        note: value,
        userIDAdded: authState.user.Username,
        dateAdded,
      };
      const notesToSend = JSON.stringify([...internalNotes, newNote]);

      await updateJobOrder({
        variables: {
          data: {
            InternalNotes: {
              set: notesToSend,
            },
          },
          where: {
            JobOrderID: parseInt(JobOrderID, 10),
          },
        },
        refetchQueries: [
          {
            query: SINGLE_JOB_ORDER_QUERY,
            variables: {
              where: {
                JobOrderID: parseInt(JobOrderID, 10),
              },
            },
            fetchPolicy: "network-only",
          },
        ],
      });
      toast.success("Internal Note added successfully.");
      setValue("");
    } catch (err) {
      toast.error("Error adding Note.");
    } finally {
      setLoading(false);
    }
  };

  const renderForm = () => (
    <form id="add-internal-note-form" onSubmit={onSubmit}>
      <Control expanded size="small">
        <Label>Add Note</Label>
        <Textarea
          required
          disabled={!canUpdate}
          placeholder="Note"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </Control>
    </form>
  );

  const renderNotes = () => {
    const orderedNotes = orderBy(
      internalNotes,
      [(obj) => new Date(obj.dateAdded)],
      ["desc"]
    );

    return (
      <div className="notes-wrapper">
        {orderedNotes.map((note) => (
          <div key={`${note.dateAdded}+${note.note}`}>
            <p className="note-metadata">
              {note.userIDAdded} {note.dateAdded}
            </p>
            <p>{note.note}</p>
            <hr />
          </div>
        ))}
      </div>
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <PageHeader title={`Internal Notes for Job Order ${JobOrderID}`}>
        {!!value.length && (
          <Button
            color="primary"
            form="add-internal-note-form"
            onClick={onSubmit}
          >
            Save
          </Button>
        )}
      </PageHeader>
      <Box>
        {renderForm()}
        {renderNotes()}
      </Box>
    </div>
  );
};

InternalNotesEditPage.propTypes = {
  routePermissions: PropTypes.array,
};

InternalNotesEditPage.defaultProps = {
  routePermissions: [],
};

export default InternalNotesEditPage;
