// eslint-disable-next-line import/prefer-default-export
export const COLUMNS = [
  {
    Header: "Name",
    id: "Name",
    accessor: "Name",
  },
  {
    Header: "States",
    id: "States",
    accessor: "States",
  },
  {
    Header: "Active",
    id: "Active",
    accessor: "Active",
    Cell: ({ cell }) => (cell?.value ? "Active" : "Inactive"),
  },
];
