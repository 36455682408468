import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../DashboardPage/DashboardPage.scss";

const PrivateLabelMainPage = ({ onCreateOrderCOA, onDownloadPurchasedCOA }) => (
  <React.Fragment>
    <div className="home-page-options">
      <Button
        rounded
        color="primary"
        style={{ textAlign: "center" }}
        onClick={onCreateOrderCOA}
      >
        <Icon>
          <FontAwesomeIcon icon="vial" />
        </Icon>
        <span>Order New COA</span>
      </Button>
    </div>
    <div className="home-page-options">
      <Button
        rounded
        color="secondary"
        style={{ textAlign: "center" }}
        onClick={onDownloadPurchasedCOA}
      >
        <Icon>
          <FontAwesomeIcon icon="vial" />
        </Icon>
        <span>Download Purchased COA</span>
      </Button>
    </div>
  </React.Fragment>
);
PrivateLabelMainPage.propTypes = {
  onCreateOrderCOA: PropTypes.func.isRequired,
  onDownloadPurchasedCOA: PropTypes.func.isRequired,
};

export default PrivateLabelMainPage;
