import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/client";
import { Title, Fieldset, Button } from "rbx";
import { customToast as toast } from "../../../../utils";
import ReferrerForm from "../ReferrerForm";
import {
  CREATE_USER_MUTATION,
  LIST_USERS_QUERY,
  FIRST_SYSTEM_CODE_QUERY,
} from "../../../../graphql";

const DEFAULT_STATE = {
  Username: "",
  FirstName: "",
  LastName: "",
  Company: "",
  Email: "",
  Phone: "",
  Status: 1,
  Address: "",
  City: "",
  State: "",
  Zip: "",
  Password: "",
  TimeZone: "",
};

const convertInputToVariables = (variables, keys, adding = true) =>
  keys.reduce((acc, curr) => {
    if (variables[curr]) {
      acc[curr] = variables[curr];
    } else {
      acc[curr] = null;
    }
    return acc;
  }, {});

const AddReferrerModal = ({ onComplete }) => {
  const [createReferrer] = useMutation(CREATE_USER_MUTATION);
  const { data: referrerAccountType } = useQuery(FIRST_SYSTEM_CODE_QUERY, {
    variables: {
      where: {
        Category: { equals: "User" },
        CodeName: { equals: "AccountType" },
        CodeId: { equals: "4" },
      },
    },
  });

  const [inputs, setInputs] = useState({
    ...DEFAULT_STATE,
  });

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const { findFirstSystemCodes } = referrerAccountType;
      const { data: createReferrerData } = await createReferrer({
        variables: {
          data: {
            ...convertInputToVariables(
              inputs,
              Object.keys(DEFAULT_STATE),
              true
            ),
            Type: {
              connect: {
                RecId: findFirstSystemCodes.RecId,
              },
            },
            isPrivateLabel: 0,
          },
        },
        refetchQueries: [
          {
            query: LIST_USERS_QUERY,
            variables: {
              where: { Type: { is: { CodeId: { in: ["4"] } } } },
              orderBy: { Username: "asc" },
            },
          },
        ],
      });
      if (createReferrerData?.createUsers?.UserID) {
        toast.success("Referrer created successfully.");
        onComplete(createReferrerData.createUsers.UserID);
      }
    } catch (err) {
      toast.error("Error creating Referrer.");
    }
  };

  return (
    <React.Fragment>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Create Referrer</Title>
        </div>
        <div className="page-head-end">
          <Button.Group>
            <Button
              color="primary"
              size="small"
              type="button"
              onClick={() => onComplete()}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={inputs.Password !== inputs.ConfirmPassword}
              form="add-referrer-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Fieldset>
        <ReferrerForm
          adding
          formId="add-referrer-form"
          inputs={inputs}
          onChange={handleChange}
          onSubmit={handleSave}
        />
      </Fieldset>
      <hr />
    </React.Fragment>
  );
};

AddReferrerModal.propTypes = {
  onComplete: PropTypes.func,
};

AddReferrerModal.defaultProps = {
  onComplete: (e) => e,
};

export default AddReferrerModal;
