import React, { useState, useMemo, useEffect } from "react";
import { useMutation, useApolloClient } from "@apollo/client";
import { Icon, Button } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { endOfMonth, startOfMonth } from "date-fns";
import PropTypes from "prop-types";

import { DataTable, ListSearchInput, Loader } from "../../../../components";
import {
  ALL_JOBS_QUERY,
  ALL_JOBS_BY_LAB_QUERY,
  UPDATE_JOB_MUTATION,
  ALL_SYSTEM_CODES_QUERY,
  JOB_ORDERS_FINANCE_EXPORT_QUERY,
} from "../../../../graphql";
import { useModal, useAuth } from "../../../../context";
import {
  generateColumns,
  SUMMARY_COLUMNS,
  FINANCE_EXPORT_COLUMNS,
} from "./columns";
import PageHeader from "../../../../components/PageHeader";
import PaymentLedgerFilters from "../components/PaymentLedgerFilters";
import PaymentLedgerModal from "../components/PaymentLedgerModal";
import {
  customToast as toast,
  debounce,
  formatCurrency,
  downloadCSV,
} from "../../../../utils";

const INITIAL_STATE = {
  JobCreateDate: {
    gte: startOfMonth(new Date()),
    lte: endOfMonth(new Date()),
  },
};

const PaymentLedgerPage = ({ routePermissions }) => {
  const [loading, setLoading] = useState(false);
  const [where, setWhere] = useState(INITIAL_STATE);
  const [showFilters, setShowFilters] = useState(false);
  const [canUpdate, setCanUpdate] = useState(true);
  const [labID, setLabID] = useState("");

  const { state: authState } = useAuth();
  const { setModalOpen } = useModal();
  const client = useApolloClient();
  const orderBy = [{ id: "JobID", desc: true }];
  const [updateJob] = useMutation(UPDATE_JOB_MUTATION);

  useEffect(() => {
    setLabID(authState.user.LabID);
    if (authState.user.LabID !== "9999") {
      setWhere((prev) => ({
        ...prev,
        LabID: {
          equals: parseInt(authState.user.LabID, 10),
        },
      }));
    }
  }, [authState]);

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  const COLUMNS = useMemo(() => {
    const handleMarkAsPaid = (job) => {
      const performUpdate = async (amountPaid) => {
        setLoading(true);
        try {
          setModalOpen(false, "");
          const { data: systemCodesData } = await client.query({
            query: ALL_SYSTEM_CODES_QUERY,
            variables: {
              where: {
                CodeName: {
                  equals: "PaymentType",
                },
              },
            },
          });

          let partialCode;
          let paidCode;
          systemCodesData.findManySystemCodes.forEach((systemCode) => {
            if (systemCode.CodeDescription === "Partial") {
              partialCode = systemCode.RecId;
            }
            if (systemCode.CodeDescription === "Paid") {
              paidCode = systemCode.RecId;
            }
          });

          await updateJob({
            variables: {
              data: {
                Payment: {
                  connect: {
                    RecId:
                      amountPaid + job.PaymentReceived >= job.PaymentAmount
                        ? paidCode
                        : partialCode,
                  },
                },
                PaymentReceived: { set: amountPaid + job.PaymentReceived },
              },
              where: {
                JobID: parseInt(job.JobID, 10),
              },
            },
            refetchQueries: [
              {
                query: ALL_JOBS_QUERY,
                variables: {
                  where,
                },
                fetchPolicy: "network-only",
              },
            ],
          });
          toast.success("Job payment status updated successfully.");
        } catch (err) {
          toast.error(`Error deleting Coupon.`);
        } finally {
          setLoading(false);
        }
      };
      setModalOpen(
        true,
        <PaymentLedgerModal
          message={`Amount due: ${formatCurrency(
            job.PaymentAmount - job.PaymentReceived
          )}`}
          title={`Enter payment amount for Job ${job.JobID}`}
          onCancel={() => setModalOpen(false, "")}
          onComplete={performUpdate}
        />
      );
    };
    return generateColumns(handleMarkAsPaid, canUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setModalOpen, updateJob, where, canUpdate]);

  const handleFilterChange = (name, value) => {
    setWhere((prev) => {
      if (
        name === "LabID" &&
        value?.equals?.toString() === "9999" &&
        where?.LabID
      ) {
        const newObj = { ...prev };
        delete newObj.LabID;
        return newObj;
      }

      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleReset = () => {
    setWhere(() => {
      if (labID.toString() !== "9999") {
        return {
          ...INITIAL_STATE,
          LabID: {
            equals: parseInt(labID, 10),
          },
        };
      }
      return INITIAL_STATE;
    });
  };

  const toggleFilters = (e) => {
    e.preventDefault();
    setShowFilters((prev) => !prev);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="job-list-page">
      <PageHeader title="Payment Ledger">
        <ListSearchInput
          onChange={debounce((name, value) => {
            if (value) {
              setWhere((prev) => ({
                ...prev,
                OR: [
                  { JobID: { equals: parseInt(value, 10) || undefined } },
                  { User: { is: { Username: { equals: value } } } },
                ],
              }));
            } else {
              handleReset();
            }
          }, 500)}
        />
        <Button color="primary" onClick={toggleFilters}>
          <Icon>
            <FontAwesomeIcon icon="filter" />
          </Icon>
        </Button>
        {authState.user?.LabState !== "AZ" ? (
          <Button
            color="primary"
            icon="file-export"
            onClick={() =>
              downloadCSV(
                client,
                {
                  query: JOB_ORDERS_FINANCE_EXPORT_QUERY,
                  where: { Job: { is: where } },
                },
                "jobOrderFinanceExport",
                FINANCE_EXPORT_COLUMNS
              )
            }
          >
            Download Finance Export
          </Button>
        ) : null}
      </PageHeader>
      {showFilters && (
        <PaymentLedgerFilters
          canSearchByLab={labID.toString() === "9999"}
          handleFilterChange={handleFilterChange}
          handleFiltersReset={handleReset}
          inputs={where}
        />
      )}
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateJobs"
        columns={SUMMARY_COLUMNS}
        name="SumByLabs"
        query={ALL_JOBS_BY_LAB_QUERY}
        where={where}
      />
      <br />
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateJobs"
        columns={COLUMNS}
        name="findManyJobs"
        orderBy={orderBy}
        query={ALL_JOBS_QUERY}
        where={where}
      />
    </div>
  );
};

PaymentLedgerPage.propTypes = {
  routePermissions: PropTypes.array,
};

PaymentLedgerPage.defaultProps = {
  routePermissions: [],
};

PaymentLedgerPage.propTypes = {};

export default PaymentLedgerPage;
