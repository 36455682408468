import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

import DataExportPage from "./DataExportPage";

const DataExportRouter = ({ routePermissions }) => (
  <Switch>
    <Route path="/data-export">
      <DataExportPage routePermissions={routePermissions} />
    </Route>
  </Switch>
);

DataExportRouter.propTypes = {
  routePermissions: PropTypes.array,
};

DataExportRouter.defaultProps = {
  routePermissions: [],
};

export default DataExportRouter;
