import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";

import Loader from "../../../../../components/Loader";
import DataTable from "../../../../../components/DataTable";
import DEFAULT_COLUMNS from "./columns";
import { PageHeader } from "../../../../../components";

import {
  ALL_JOB_ORDERS_QUERY,
  SINGLE_SAMPLE_TRACKING_QUERY,
} from "../../../../../graphql";

const INITIAL_STATE = {
  Name: "",
  Value: "",
};

const SampleTrackingPage = (props) => {
  const { SampleTrackingID } = useParams();
  const where = {
    SampleTracking: {
      is: { SampleTrackingID: { equals: parseInt(SampleTrackingID, 10) } },
    },
  };
  const [loading, setLoading] = useState(true);
  const orderBy = [{ id: "JobOrderID", desc: true }];
  const [inputs, setInputs] = useState(INITIAL_STATE);

  const [getSampleTrackingData, resultSampleTrackingData] = useLazyQuery(
    SINGLE_SAMPLE_TRACKING_QUERY
  );

  useEffect(() => {
    if (SampleTrackingID) {
      getSampleTrackingData({
        variables: {
          where: { SampleTrackingID: parseInt(SampleTrackingID, 10) },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [SampleTrackingID, getSampleTrackingData]);

  useEffect(() => {
    if (resultSampleTrackingData?.data) {
      const {
        data: { sampleTracking },
      } = resultSampleTrackingData;

      const cleanObject = Object.fromEntries(
        Object.entries(sampleTracking).filter(([_, v]) => v !== null)
      );
      setInputs((prev) => ({
        ...prev,
        ...cleanObject,
      }));
      setLoading(false);
    }
  }, [resultSampleTrackingData]);

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="employee-list-page">
      <PageHeader title={`Sample Tracking ${inputs.Name}`} />
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateJobOrders"
        columns={DEFAULT_COLUMNS}
        name="findManyJobOrders"
        orderBy={orderBy}
        query={ALL_JOB_ORDERS_QUERY}
        where={where}
      />
    </div>
  );
};

SampleTrackingPage.propTypes = {};

export default SampleTrackingPage;
