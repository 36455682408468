/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from "react";
import { Tag, Button, Icon, Generic } from "rbx";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { STATUS_COLORS } from "../../../constants";

const DEFAULT_COLUMNS = [
  {
    Header: "Session ID",
    id: "SessionID",
    accessor: "SessionID",
    Cell: ({ cell, row }) => {
      if (!row.original?.JobOrder?.OverrideData?.RetailBatchRetest) {
        return (
          <Link
            to={`/lims/test-status/${row.original.JobID}/${row.original.JobOrderID}`}
          >
            {cell.value}
            {row.original?.JobOrder?.OverrideData?.RetailBatchRetest && (
              <FontAwesomeIcon icon="skull" />
            )}
          </Link>
        );
      }
      return (
        <Link
          to={`/lims/test-status/${row.original.JobID}/${row.original.JobOrderID}`}
        >
          {cell.value}
        </Link>
      );
    },
  },
  {
    Header: "Username",
    id: "Name",
    accessor: "JobOrder.User.Username",
  },
  {
    Header: "Batch ID",
    id: "BatchID",
    accessor: "BatchID",
    Cell: ({ cell }) => (
      <Link to={`/lims/batch-records/${cell.value}/batch-info`}>
        {cell.value}
      </Link>
    ),
  },
  {
    Header: "Prep No.",
    id: "PrepNo",
    accessor: "PrepNo",
  },
  {
    Header: "Receive Date",
    id: "JobOrderReceiveDate",
    accessor: "JobOrder.OrderReceiveDate",
    disableSortBy: true,
    Cell: ({ cell }) =>
      cell?.value ? (
        format(new Date(cell.value), "MM-dd-yyyy")
      ) : (
        <Tag color="warning">Not Received Yet</Tag>
      ),
  },
  {
    Header: "Priority",
    id: "Priority",
    accessor: "JobOrder.Job.ShippingSystemCode.CodeDescription",
    Cell: ({ cell }) => (
      <Tag color={STATUS_COLORS.Shipping[cell.value]}>{cell.value}</Tag>
    ),
    disableSortBy: true,
  },
  {
    Header: "Important",
    id: "Important",
    accessor: "Important",
    Cell: ({ cell }) => (
      <Button static color="white">
        <Icon color={cell.value ? "primary" : "grey"}>
          <FontAwesomeIcon icon="flag" />
        </Icon>
      </Button>
    ),
  },
  {
    Header: "Due Date",
    id: "OrderDueDate",
    accessor: "JobOrder.OrderDueDate",
    disableSortBy: true,
    Cell: ({ cell }) =>
      cell.value ? format(new Date(cell.value), "MM-dd-yyyy") : "",
  },
];

export const generateColumns = (handlePrintLabel) => [
  ...DEFAULT_COLUMNS,
  {
    Header: "Label",
    id: "Label",
    Cell: (cell) => (
      <Generic
        style={{ textAlign: "center" }}
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          handlePrintLabel(cell.row.original);
        }}
      >
        <Icon color="danger">
          <FontAwesomeIcon icon="print" />
        </Icon>
      </Generic>
    ),
  },
];
