import React from "react";
import PropTypes from "prop-types";
import { Column, Field, Input, Label, Control } from "rbx";

import { BooleanInput } from "../../../../components";

const SystemCodeForm = ({
  inputs,
  onChange,
  disabled,
  onSubmit,
  formId,
  adding,
}) => (
  <form id={formId} onSubmit={onSubmit}>
    <Column.Group multiline>
      <Column size={12}>
        <Field kind="group">
          <Control expanded size="small">
            <Label>Code Name</Label>
            <Input
              required
              disabled={disabled}
              maxLength={250}
              name="CodeName"
              placeholder="Code Name"
              size="small"
              type="text"
              value={inputs.CodeName}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded size="small">
            <Label>Type</Label>
            <Input
              required
              disabled={disabled}
              maxLength={250}
              name="Type"
              placeholder="Type"
              size="small"
              value={inputs.Type}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>

          <Control expanded size="small">
            <Label>Category</Label>
            <Input
              required
              disabled={disabled}
              maxLength={250}
              name="Category"
              placeholder="Category"
              size="small"
              type="text"
              value={inputs.Category}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>

          <Control expanded size="small">
            <Label>Code Id</Label>
            <Input
              required
              disabled={disabled}
              maxLength={250}
              name="CodeId"
              placeholder="Code Id"
              size="small"
              type="text"
              value={inputs.CodeId}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>

        <Field kind="group">
          <Control expanded size="small" style={{ width: "76%" }}>
            <Label>Code Description</Label>
            <Input
              required
              disabled={disabled}
              maxLength={250}
              name="CodeDescription"
              placeholder="Code Description"
              size="small"
              value={inputs.CodeDescription}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded size="small" style={{ width: "24%" }}>
            <Label>Active</Label>
            <BooleanInput
              required
              name="Active"
              value={!!inputs.Active}
              onChange={(name, checked) =>
                onChange(name, checked ? 1 : 0)
              }
            />
          </Control>
        </Field>
      </Column>
    </Column.Group>
  </form>
);

SystemCodeForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  formId: PropTypes.string.isRequired,
  adding: PropTypes.bool,
};

SystemCodeForm.defaultProps = {
  onSubmit: () => null,
  disabled: false,
  adding: false,
};

export default SystemCodeForm;
