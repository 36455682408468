import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Title, Table, Control, Tab, Field } from "rbx";
import { useLazyQuery } from "@apollo/client";
import PaymentForm from "../PaymentForm";
import { customToast as toast, formatCurrency } from "../../../../utils";
import { ALL_CREDIT_LEDGER_QUERY } from "../../../../graphql";
import { Loader } from "../../../../components";
import "./PaymentForm.scss";

const INITIAL_STATE = {
  billToFirstName: "",
  billToLastName: "",
  billToCompany: "",
  billToAddress: "",
  billToCity: "",
  billToState: "",
  billToZip: "",
  billToCountry: "US",
};

const Payment = ({
  billDetail,
  checkoutInformation,
  totals: initialTotal,
  onComplete,
  client,
  CouponCode,
  onClose,
}) => {
  const [inputs, setInputs] = useState({
    ...INITIAL_STATE,
    billToFirstName: checkoutInformation.BillingFirstname || "",
    billToLastName: checkoutInformation.BillingLastname || "",
    billToCompany: checkoutInformation.BillingCompany || "",
    billToAddress: checkoutInformation.BillingAddress || "",
    billToAddress2: checkoutInformation.BillingAddress2 || "",
    billToCity: checkoutInformation.BillingCity || "",
    billToState: checkoutInformation.BillingState || "",
    billToZip: checkoutInformation.BillingZip || "",
  });
  // const { REACT_APP_API_LOGIN_ID, REACT_APP_TRANSACTION_KEY } = process.env;
  const [validExpiration, setValidExpiration] = useState(false);
  const [validCreditCard, setValidCreditCart] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totals, setTotals] = useState(initialTotal);
  const [paymentType, setPaymentType] = useState(
    checkoutInformation?.TermsCode?.CodeDescription ? "terms" : "card"
  );
  const [getCreditLedgerData, resultCreditLedgerData] = useLazyQuery(
    ALL_CREDIT_LEDGER_QUERY
  );

  useEffect(() => {
    if (client?.UserID) {
      getCreditLedgerData({
        variables: {
          where: {
            UserID: { equals: parseInt(client.UserID, 10) },
          },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [client.UserID, getCreditLedgerData]);

  useEffect(() => {
    if (resultCreditLedgerData?.data?.creditLedgers) {
      const totalOfCredit = resultCreditLedgerData.data.creditLedgers.reduce(
        (a, b) => a + b.Credit,
        0
      );
      setTotals((prev) => ({ ...prev, creditLedgers: totalOfCredit }));
    }
  }, [resultCreditLedgerData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const applyCredit = () => {
    if (totals.usedCredits === 0) {
      const { total, creditLedgers } = totals;
      if (total <= creditLedgers) {
        setTotals((prev) => ({ ...prev, usedCredits: total }));
      } else if (total > creditLedgers) {
        setTotals((prev) => ({ ...prev, usedCredits: creditLedgers }));
      }
    } else {
      setTotals((prev) => ({ ...prev, usedCredits: 0 }));
    }
  };

  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      if (totals.usedCredits) {
        if (totals.creditLedgers - totals.total >= 0) {
          onComplete("Credit", inputs, totals);
        } else {
          onComplete("Terms/Credit", inputs, totals);
        }
      } else {
        onComplete("Terms", inputs, totals);
      }
    } catch (err) {
      toast.error("Error on payment");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitCard = (data) => {
    try {
      setLoading(true);
      if (totals.usedCredits) {
        if (totals.creditLedgers - totals.total >= 0) {
          onComplete("Credit", { ...inputs, ...data }, totals);
        } else {
          onComplete("COD/Credit", { ...inputs, ...data }, totals);
        }
      } else {
        onComplete("COD", { ...inputs, ...data }, totals);
      }
    } catch (err) {
      toast.error("Error on payment");
    } finally {
      setLoading(false);
    }
  };

  if (resultCreditLedgerData?.loading) return <Loader />;
  return (
    <div className="payment-form-container">
      <div className="page-head" style={{ gridTemplateColumns: "1fr 1fr" }}>
        <div className="page-head-start">
          <Title subtitle>Select Payment</Title>
        </div>
        <div className="page-head-end">
          <Table style={{ width: "100%", fontSize: 13 }}>
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan="3" style={{ padding: "0.5em 0em" }}>
                  Subtotal
                </Table.Cell>
                <Table.Cell style={{ textAlign: "end", padding: "0.5em 0em" }}>
                  {formatCurrency(totals.subtotal)}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell colSpan="3" style={{ padding: "0.5em 0em" }}>
                  Processing
                </Table.Cell>
                <Table.Cell style={{ textAlign: "end", padding: "0.5em 0em" }}>
                  {formatCurrency(totals.processing)}
                </Table.Cell>
              </Table.Row>
              {CouponCode && (
                <Table.Row>
                  <Table.Cell colSpan="3" style={{ padding: "0.5em 0em" }}>
                    Discount
                  </Table.Cell>
                  <Table.Cell
                    style={{ textAlign: "end", padding: "0.5em 0em" }}
                  >
                    -{formatCurrency(totals.discount)}
                  </Table.Cell>
                </Table.Row>
              )}
              {totals.usedCredits ? (
                <Table.Row>
                  <Table.Cell colSpan="3" style={{ padding: "0.5em 0em" }}>
                    Used Credits
                  </Table.Cell>
                  <Table.Cell
                    style={{
                      textAlign: "end",
                      padding: "0.5em 0em",
                      width: "15%",
                      color: "#079e42",
                    }}
                  >
                    -{formatCurrency(totals.usedCredits)}
                  </Table.Cell>
                </Table.Row>
              ) : null}
              <Table.Row style={{ fontWeight: "bold", fontSize: 15 }}>
                <Table.Cell colSpan="3" style={{ padding: "0.5em 0em" }}>
                  Payment Total
                </Table.Cell>
                <Table.Cell
                  style={{
                    textAlign: "end",
                    padding: "0.5em 0em",
                    color: "#079e42",
                  }}
                >
                  {formatCurrency(totals.total - totals.usedCredits)}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      </div>
      <Tab.Group kind="boxed">
        {checkoutInformation?.TermsCode?.CodeDescription &&
          client?.Lab?.PaymentOptions?.Terms && (
            <Tab
              as={Button}
              className={paymentType === "terms" ? "active" : ""}
              onClick={() => {
                applyCredit();
                setPaymentType("terms");
              }}
            >
              Terms
            </Tab>
          )}
        {client?.Lab?.PaymentOptions?.CC && (
          <Tab
            as={Button}
            className={paymentType === "card" ? "active" : ""}
            onClick={() => {
              setTotals((prev) => ({
                ...prev,
                usedCredits: 0,
              }));
              setPaymentType("card");
            }}
          >
            Credit Card
          </Tab>
        )}
      </Tab.Group>
      <React.Fragment>
        {checkoutInformation?.TermsCode?.CodeDescription &&
        paymentType === "terms" ? (
          <React.Fragment>
            <div className="credit-container">
              <h2>
                Currently you are on&nbsp;
                <span style={{ color: "#079e42" }}>
                  {checkoutInformation?.TermsCode?.CodeDescription}
                </span>
              </h2>
            </div>

            <Field
              style={{
                justifyContent: "center",
                marginTop: "3rem",
                display: "flex",
              }}
            >
              <Control>
                <Button
                  color="primary"
                  state={loading ? "loading" : ""}
                  onClick={handleSubmit}
                >
                  Submit Payment
                </Button>
              </Control>
            </Field>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {paymentType === "card" && (
              <PaymentForm
                disabled={totals.total - totals.usedCredits === 0}
                inputs={inputs}
                loading={loading}
                paymentAmount={totals.total - totals.usedCredits}
                setValidCreditCart={setValidCreditCart}
                setValidExpiration={setValidExpiration}
                validCreditCard={validCreditCard}
                validExpiration={validExpiration}
                onChange={handleChange}
                onClose={onClose}
                onSubmit={handleSubmitCard}
              />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
      <Control>
        <Button size="small" type="button" onClick={onClose}>
          Return to Cart
        </Button>
      </Control>
    </div>
  );
};

Payment.propTypes = {
  billDetail: PropTypes.array.isRequired,
  client: PropTypes.object.isRequired,
  checkoutInformation: PropTypes.object.isRequired,
  totals: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
  CouponCode: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

Payment.defaultProps = {
  CouponCode: null,
};

export default Payment;
