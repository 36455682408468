import React from "react";
import PropTypes from "prop-types";
import { Column, Field, Input, Label, Control } from "rbx";

import TestSelect from "../../../../components/TestSelect";

const LabTestForm = ({ inputs, onChange, disabled, adding }) => (
  <div>
    <Column.Group multiline>
      <Column size={12}>
        <Field kind="group">
          <Control expanded size="small">
            <TestSelect
              required
              disabled={disabled}
              id="TestID"
              label="Test Name"
              name="TestID"
              size="small"
              value={inputs.TestID}
              onChange={onChange}
            />
          </Control>
          <Control expanded size="small">
            <Label>Plants</Label>
            <Input
              required
              disabled={disabled}
              maxLength={250}
              name="Plants"
              placeholder="Plants"
              size="small"
              type="text"
              value={inputs.Plants}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded size="small">
            <Label>Extracts</Label>
            <Input
              required
              disabled={disabled}
              maxLength={250}
              name="Extracts"
              placeholder="Extracts"
              size="small"
              type="text"
              value={inputs.Extracts}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field kind="group">
          <Control expanded size="small">
            <Label>Ingestibles</Label>
            <Input
              required
              disabled={disabled}
              maxLength={250}
              name="Ingestibles"
              placeholder="Ingestibles"
              size="small"
              type="text"
              value={inputs.Ingestibles}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded size="small">
            <Label>Topicals</Label>
            <Input
              required
              disabled={disabled}
              maxLength={250}
              name="Topicals"
              placeholder="Topicals"
              size="small"
              type="text"
              value={inputs.Topicals}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded size="small">
            <Label>Animal Products</Label>
            <Input
              required
              disabled={disabled}
              maxLength={250}
              name="AnimalProducts"
              placeholder="AnimalProducts"
              size="small"
              type="number"
              value={inputs.AnimalProducts}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded size="small">
            <Label>Other</Label>
            <Input
              required
              disabled={disabled}
              maxLength={250}
              name="Other"
              placeholder="Other"
              size="small"
              type="number"
              value={inputs.Other}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
      </Column>
    </Column.Group>
  </div>
);

LabTestForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  adding: PropTypes.bool,
};

LabTestForm.defaultProps = {
  disabled: false,
  adding: false,
};

export default LabTestForm;
