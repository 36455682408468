import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useLazyQuery } from "@apollo/client";
import { ALL_USERS_QUERY } from "../../graphql";

const UserSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
  where,
}) => {
  const [users, setUsers] = useState([]);
  const [getUsers, { data: usersData }] = useLazyQuery(ALL_USERS_QUERY);

  useEffect(() => {
    getUsers({
      variables: { where, orderBy: { Username: "asc" } },
    });
  }, [where, getUsers]);

  useEffect(() => {
    if (Array.isArray(usersData?.findManyUsers)) {
      setUsers(usersData.findManyUsers);
    }
  }, [usersData]);

  const indicateRequired = required && (value === "" || value === null);

  const handleChange = (e) => {
    onChange(e.target.name, e.target.value);
  };

  return (
    <Control expanded>
      {label && (
        <Label>{label}</Label>
      )}
      <Select.Container
        fullwidth
        size={size}
        state={!usersData?.loading ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          required={required}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          <Select.Option value="" />
          {Array.isArray(users) &&
            users.map((user) => (
              <Select.Option key={user.UserID} value={user.UserID}>
                {user.Username}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

UserSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  required: PropTypes.bool,
  where: PropTypes.object,
};

UserSelect.defaultProps = {
  value: "",
  name: "",
  label: "",
  size: "small",
  required: false,
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  where: {},
};

export default UserSelect;
