import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Fieldset, Field, Control, Title, Column } from "rbx";
import { useQuery, useMutation } from "@apollo/client";
import {
  SINGLE_JOB_ORDERS_QUERY,
  UPDATE_JOB_ORDER_MUTATION,
  FIRST_SYSTEM_CODE_QUERY,
  UPDATE_MANY_JOB_ORDER_TEST_SAMPLES_STATUS_MUTATION,
} from "../../../../../../graphql";
import { useAuth } from "../../../../../../context";
import { customToast as toast } from "../../../../../../utils";
import { productCategoryMatrix } from "../../../../../../constants";
import FormSectionGroup from "./FormSectionGroup";

const MoistureWaterFilthModal = ({ types, onComplete, JobOrderID }) => {
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState(types);
  const [loading, setLoading] = useState(false);
  const { data: jobOrderData, loading: jobOrderLoading } = useQuery(
    SINGLE_JOB_ORDERS_QUERY,
    {
      variables: {
        where: {
          JobOrderID: parseInt(JobOrderID, 10),
        },
      },
      fetchPolicy: "network-only",
    }
  );
  const { data: approvedSystemCodeData } = useQuery(FIRST_SYSTEM_CODE_QUERY, {
    variables: {
      where: {
        Category: { equals: "JobOrderSample" },
        CodeName: { equals: "ResultStatus" },
        CodeId: { equals: "A" },
      },
    },
  });
  const [updateJobOrder] = useMutation(UPDATE_JOB_ORDER_MUTATION);
  const [updateManyJobOrderTestSamplesStatus] = useMutation(
    UPDATE_MANY_JOB_ORDER_TEST_SAMPLES_STATUS_MUTATION
  );

  const handleChangeRejectNote = (type, JobOrderTestSampleID, RejectNote) => {
    setInputs((prev) => {
      const res = prev[type].map((sample) => {
        if (sample.JobOrderTestSampleID === JobOrderTestSampleID) {
          return { ...sample, RejectNote, updated: true };
        }
        return sample;
      });
      return {
        ...prev,
        [type]: res,
      };
    });
  };

  const handleChangeResult = (type, JobOrderTestSampleID, name, value) => {
    setInputs((prev) => {
      const res = prev[type].map((sample) => {
        if (sample.JobOrderTestSampleID === JobOrderTestSampleID) {
          if (value) {
            if (type === "Water") {
              const jobOrder = jobOrderData.findUniqueJobOrders;
              const productCategory =
                jobOrder?.ProductCategorySystemCode?.CodeDescription;
              const orderMatrix = Object.keys(productCategoryMatrix).find(
                (key) => productCategoryMatrix[key].includes(productCategory)
              );
              let result = sample.Result?.Result;
              if (name === "Percent") {
                if (
                  jobOrder?.SubstanceTypeSystemCode?.CodeDescription === "MJ"
                ) {
                  result =
                    parseFloat(value) <
                    (orderMatrix && orderMatrix === "flower" ? 0.65 : 0.85)
                      ? "Pass"
                      : "Fail";
                } else {
                  result = "Pass";
                }
              }
              return {
                ...sample,
                Result: {
                  ...sample.Result,
                  [name]: value,
                  Result: result,
                },
                IncludesFailedResult: {
                  set: result === "Fail",
                },
                updated: true,
              };
            }
            if (type === "Moisture") {
              const jobOrder = jobOrderData.findUniqueJobOrders;
              let result = sample.Result?.Result;
              if (name === "Percent") {
                if (
                  jobOrder?.Job?.Lab?.State === "FL" &&
                  jobOrder?.SubstanceTypeSystemCode?.CodeDescription === "MJ"
                ) {
                  result = parseFloat(value) < 15 ? "Pass" : "Fail";
                } else {
                  result = "Pass";
                }
              }
              return {
                ...sample,
                Result: {
                  ...sample.Result,
                  [name]: value,
                  Result: result,
                },
                IncludesFailedResult: {
                  set: result === "Fail",
                },
                updated: true,
              };
            }
            return {
              ...sample,
              Result: { ...sample.Result, [name]: value },
              IncludesFailedResult: {
                set: !!(value === "Fail"),
              },
              updated: true,
            };
          }
          return {
            ...sample,
            Result: { ...sample.Result, [name]: "" },
            Status: null,
            updated: true,
          };
        }
        return sample;
      });
      return {
        ...prev,
        [type]: res,
      };
    });
  };
  const handleChangeStatus = (type, JobOrderTestSampleID, status, value) => {
    setInputs((prev) => {
      const res = prev[type].map((sample) => {
        if (sample.JobOrderTestSampleID === JobOrderTestSampleID) {
          return { ...sample, Status: value ? status : null, updated: true };
        }
        return sample;
      });
      return {
        ...prev,
        [type]: res,
      };
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      // update JobOrder.Moisture
      if (inputs.Moisture?.length) {
        const approvedMoistureSamples = inputs.Moisture.filter(
          (sample) =>
            sample.Status === approvedSystemCodeData?.findFirstSystemCodes.RecId
        ).sort(
          (a, b) =>
            a.JobOrderTestSampleID.localeCompare(b.JobOrderTestSampleID) &&
            a.JobOrderTestID.localeCompare(b.JobOrderTestID)
        );

        const orderData = {
          Moisture: { set: approvedMoistureSamples[0]?.Result.Percent || null },
        };

        await updateJobOrder({
          variables: {
            where: {
              JobOrderID,
            },
            data: orderData,
          },
        });
      }
      // update all edited JobOrderTestSamples
      const testSamples = [];
      const allSamples = [];
      if (inputs.Moisture?.length) {
        allSamples.push(...inputs.Moisture);
      }
      if (inputs.Water?.length) {
        allSamples.push(...inputs.Water);
      }
      if (inputs.Filth?.length) {
        allSamples.push(...inputs.Filth);
      }
      if (allSamples?.length) {
        allSamples.forEach((sample) => {
          if (sample.updated && sample.Status) {
            const sampleData = {
              IncludesFailedResult: sample.IncludesFailedResult,
              CreatedBy: {
                set: authState.user.Username,
              },
              SessionID: {
                set: sample.SessionID,
              },
            };
            sampleData.ResultStatus = {
              connect: {
                RecId: parseInt(sample.Status, 10),
              },
            };
            sampleData.ReviewedBy = { set: authState.user.Username };
            if (sample.RejectNote) {
              sampleData.RejectNote = {
                set: sample.RejectNote,
              };
            }
            if (sample.Result) {
              sampleData.Result = sample.Result;
            }

            testSamples.push({
              Approval: !!(
                sample.Status ===
                approvedSystemCodeData?.findFirstSystemCodes.RecId
              ),
              data: sampleData,
              whereJobOrderTestSamples: {
                JobOrderTestSampleID: parseInt(sample.JobOrderTestSampleID, 10),
              },
            });
          }
        });
      }

      const { data: updatedResult } = await updateManyJobOrderTestSamplesStatus(
        {
          variables: { data: { arrayInfo: testSamples } },
          refetchQueries: [
            {
              query: SINGLE_JOB_ORDERS_QUERY,
              variables: {
                where: {
                  JobOrderID,
                },
              },
              fetchPolicy: "network-only",
            },
          ],
        }
      );
      if (updatedResult?.updateManyJobOrderTestSampleStatus) {
        toast.success("Job Order updated successfully");
        onComplete();
      } else {
        throw new Error("Error");
      }
    } catch (err) {
      toast.error("Error trying to save");
    } finally {
      setLoading(false);
    }
  };

  const validateSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const allSamples = [];
    if (inputs.Moisture?.length) {
      allSamples.push(...inputs.Moisture);
    }
    if (inputs.Water?.length) {
      allSamples.push(...inputs.Water);
    }
    if (inputs.Filth?.length) {
      allSamples.push(...inputs.Filth);
    }
    const sampleWithoutStatus = allSamples.find(
      (sample) => sample.Result?.Result && !sample.Status
    );
    if (sampleWithoutStatus) {
      toast.error("Please Approve or Reject before saving");
      setLoading(false);
    } else {
      handleSubmit();
    }
  };

  return (
    <React.Fragment>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {`Update ${Object.keys(inputs).map((key, index) =>
              index !== 0 && index === Object.keys(inputs).length - 1
                ? ` and ${key}`
                : ` ${key}`
            )}`}
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group>
            <Button
              color="primary"
              size="small"
              type="button"
              onClick={onComplete}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={loading || jobOrderLoading}
              form="moisture-and-weight-form"
              size="small"
              state={loading || jobOrderLoading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Fieldset>
        <form id="moisture-and-weight-form" onSubmit={validateSubmit}>
          <Column.Group multiline>
            {inputs.Moisture && (
              <Column size={12} style={{ marginBottom: "1rem" }}>
                <Title size={5} style={{ textAlign: "center" }}>
                  Moisture Analysis
                </Title>
                <Field multiline kind="group">
                  <Control expanded>
                    {inputs.Moisture.map((sample) => (
                      <FormSectionGroup
                        key={sample.SessionID}
                        disabled={loading || jobOrderLoading}
                        handleChangeRejectNote={handleChangeRejectNote}
                        handleChangeResult={handleChangeResult}
                        handleChangeStatus={handleChangeStatus}
                        sample={sample}
                        testType="Moisture"
                      />
                    ))}
                  </Control>
                </Field>
              </Column>
            )}
            {inputs.Water && (
              <Column size={12} style={{ marginBottom: "1rem" }}>
                <Title size={5} style={{ textAlign: "center" }}>
                  Water Activity
                </Title>
                <Field multiline kind="group">
                  <Control expanded>
                    {inputs.Water.map((sample) => (
                      <FormSectionGroup
                        key={sample.SessionID}
                        disabled={loading || jobOrderLoading}
                        handleChangeRejectNote={handleChangeRejectNote}
                        handleChangeResult={handleChangeResult}
                        handleChangeStatus={handleChangeStatus}
                        sample={sample}
                        testType="Water"
                      />
                    ))}
                  </Control>
                </Field>
              </Column>
            )}
            {inputs.Filth && (
              <Column size={12} style={{ marginBottom: "1rem" }}>
                <Title size={5} style={{ textAlign: "center" }}>
                  Filth & Foreign Material
                </Title>
                <Field multiline kind="group">
                  <Control expanded>
                    {inputs.Filth.map((sample) => (
                      <FormSectionGroup
                        key={sample.SessionID}
                        disabled={loading || jobOrderLoading}
                        handleChangeRejectNote={handleChangeRejectNote}
                        handleChangeResult={handleChangeResult}
                        handleChangeStatus={handleChangeStatus}
                        sample={sample}
                        testType="Filth"
                      />
                    ))}
                  </Control>
                </Field>
              </Column>
            )}
          </Column.Group>
        </form>
      </Fieldset>
    </React.Fragment>
  );
};

MoistureWaterFilthModal.propTypes = {
  types: PropTypes.object,
  onComplete: PropTypes.func,
  JobOrderID: PropTypes.number.isRequired,
};

MoistureWaterFilthModal.defaultProps = {
  types: {},
  onComplete: () => null,
};
export default MoistureWaterFilthModal;
