import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field, Box, Control, Button, Label, Input, Column, Title } from "rbx";

import TestCategorySelect from "../../../../../components/TestCategorySelect";
import BooleanInput from "../../../../../components/BooleanInput";

const CreateAnalyteForm = ({ onSubmit, formId }) => {
  const [inputs, setInputs] = useState({
    Active: true,
    Name: "",
    TestCategoryID: "",
  });

  const handleChange = (name, value) => {
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const handleReset = () => {
    setInputs({
      Active: true,
      Name: "",
      Test: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputs.Name.length && Number.isInteger(inputs.TestCategoryID)) {
      onSubmit(inputs);
    }
  };

  return (
    <form id={formId} onSubmit={handleSubmit}>
      <Box>
        <Title size={5}>Create Analyte</Title>
        <Column.Group>
          <Column size={12}>
            <Field kind="group">
              <Control expanded size="small">
                <Label>Name</Label>
                <Input
                  required
                  maxLength={250}
                  name="Name"
                  placeholder="Name"
                  size="small"
                  type="text"
                  value={inputs.Name}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </Control>
              <Control expanded size="small">
                <TestCategorySelect
                  required
                  label="Test Category"
                  name="TestCategoryID"
                  size="small"
                  value={inputs.TestCategoryID}
                  onChange={handleChange}
                />
              </Control>
              <Control expanded size="small">
                <Label>Active</Label>
                <BooleanInput
                  name="Active"
                  value={inputs.Active}
                  onChange={handleChange}
                />
              </Control>
            </Field>
            <Field kind="group">
              <Control>
                <Label>&nbsp;</Label>
                <Button color="primary" size="small" onClick={handleSubmit}>
                  Save
                </Button>
              </Control>
              <Control>
                <Label>&nbsp;</Label>
                <Button color="primary" size="small" onClick={handleReset}>
                  Reset
                </Button>
              </Control>
            </Field>
          </Column>
        </Column.Group>
      </Box>
    </form>
  );
};

CreateAnalyteForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formId: PropTypes.string.isRequired,
};

CreateAnalyteForm.defaultProps = {};

export default CreateAnalyteForm;
