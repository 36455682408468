import React from "react";
import { PropTypes } from "prop-types";
import { Column, Field, Control, Label, Button, Box } from "rbx";
import { endOfDay, startOfDay, isValid } from "date-fns";
import {
  DateInput,
  LabLocationSelect,
  FilterInput,
} from "../../../../components";

const OrderTestsFilters = ({ where, onChange, onReset }) => (
  <Box>
    <Column.Group>
      <Column>
        <Field>
          <FilterInput
            getValue={(x) => x?.JobOrder?.is?.User?.is?.Username?.equals || ""}
            label="Client"
            name="JobOrder"
            placeholder="Client"
            setValue={(x) =>
              x
                ? {
                    JobOrder: {
                      is: { User: { is: { Username: { equals: x } } } },
                    },
                  }
                : ""
            }
            value={where?.JobOrder || ""}
            onChange={onChange}
          />
        </Field>
      </Column>
      <Column>
        <Field>
          <DateInput
            getValue={(x) => {
              const date =
                x?.is?.OrderCompleteDate?.gte || x?.is?.OrderCompleteDate?.lte;
              return date ? date.substr(0, 10) : "";
            }}
            label="Completed Date"
            name="JobOrder"
            setValue={(x) => {
              if (
                x &&
                isValid(new Date(x.split("-").map((y) => parseInt(y, 10))))
              ) {
                return {
                  JobOrder: {
                    is: {
                      OrderCompleteDate: {
                        gte: startOfDay(
                          new Date(x.split("-").map((y) => parseInt(y, 10)))
                        ).toISOString(),
                        lte: endOfDay(
                          new Date(x.split("-").map((y) => parseInt(y, 10)))
                        ).toISOString(),
                      },
                    },
                  },
                };
              }
              return "";
            }}
            value={where.JobOrder}
            onChange={onChange}
          />
        </Field>
      </Column>
      <Column>
        <Field>
          <LabLocationSelect
            showLabel
            getValue={(x) =>
              typeof x?.is?.Job?.is?.LabID?.equals === "number"
                ? x?.is?.Job?.is?.LabID?.equals
                : ""
            }
            name="JobOrder"
            setValue={(x) =>
              typeof x === "number"
                ? {
                    JobOrder: { is: { Job: { is: { LabID: { equals: x } } } } },
                  }
                : ""
            }
            size="small"
            value={where?.JobOrder}
            onChange={onChange}
          />
        </Field>
      </Column>
      <Column>
        <Control>
          <Label>&nbsp;</Label>
          <Button color="primary" size="small" onClick={onReset}>
            Reset
          </Button>
        </Control>
      </Column>
    </Column.Group>
  </Box>
);
OrderTestsFilters.propTypes = {
  where: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onReset: PropTypes.func,
};
OrderTestsFilters.defaultProps = {
  onChange: () => null,
  onReset: () => null,
};
export default OrderTestsFilters;
