import { gql } from "@apollo/client";

export const UPDATE_USER_MUTATION = gql`
  mutation UPDATE_USER_MUTATION(
    $data: UsersUpdateInput!
    $where: UsersWhereUniqueInput!
  ) {
    updateUsers(data: $data, where: $where) {
      UserID
      Commission2
      Username
      Password
    }
  }
`;

export const CREATE_USER_MUTATION = gql`
  mutation CREATE_USER_MUTATION($data: UsersCreateInput!) {
    createUsers: createUsersWithSecurePassword(data: $data) {
      UserID
      Commission2
      Username
      Password
    }
  }
`;

export const UPDATE_PRIVATE_LABEL_USER_MUTATION = gql`
  mutation UPDATE_USER_LABEL_MUTATION(
    $data: PrivateLabelUsersUpdateInput!
    $where: PrivateLabelUsersWhereUniqueInput!
  ) {
    updatePrivateLabelUsers(data: $data, where: $where) {
      PrivateLabelUserID
      OwnerUserID
      Username
      Password
      RevShare
      Status
    }
  }
`;

export const UPDATE_PRIVATE_LABEL_USER_WITH_PASSWORD_HASHED_MUTATION = gql`
  mutation UPDATE_PRIVATE_LABEL_USER_WITH_PASSWORD_HASHED_MUTATION(
    $data: PrivateLabelUsersUpdateInput!
    $where: PrivateLabelUsersWhereUniqueInput!
  ) {
    updatePrivateLabelUserWithPasswordHashed(data: $data, where: $where) {
      PrivateLabelUserID
      OwnerUserID
      Username
      Password
      RevShare
      Status
    }
  }
`;

export const CREATE_PRIVATE_LABEL_USER_MUTATION = gql`
  mutation CREATE_PRIVATE_LABEL_USER_MUTATION(
    $data: PrivateLabelUsersCreateInput!
  ) {
    createPrivateLabelUsers(data: $data) {
      PrivateLabelUserID
      OwnerUserID
      Username
      Password
      RevShare
      Status
    }
  }
`;

export const CREATE_PRIVATE_LABEL_USER_WITH_PASSWORD_HASHED_MUTATION = gql`
  mutation CREATE_PRIVATE_LABEL_USER_WITH_PASSWORD_HASHED_MUTATION(
    $data: PrivateLabelUsersCreateInput!
  ) {
    createPrivateLabelUserWithPasswordHashed(data: $data) {
      PrivateLabelUserID
      OwnerUserID
      Username
      Password
      RevShare
      Status
    }
  }
`;

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation UPDATE_PASSWORD_MUTATION(
    $data: UpdatePasswordInput!
    $where: UsersWhereUniqueInput!
  ) {
    updatePassword(data: $data, where: $where)
  }
`;

export const EMAIL_NOTIFICATION_ACCOUNT_INQUIRY_MUTATION = gql`
  mutation EMAIL_NOTIFICATION_ACCOUNT_INQUIRY_MUTATION(
    $data: UsersCreateInput!
  ) {
    emailNotificationAccountInquiry(data: $data)
  }
`;

export const FORGOT_PASSWORD_RECOVERY_MUTATION = gql`
  mutation FORGOT_PASSWORD_RECOVERY_MUTATION($data: ForgotPasswordInput!) {
    forgotPasswordRecovery(data: $data)
  }
`;

export const RESET_PASSWORD_RECOVERY_MUTATION = gql`
  mutation RESET_PASSWORD_RECOVERY_MUTATION(
    $data: UsersUpdateInput!
    $where: UsersWhereInput!
  ) {
    resetPasswordRecovery(data: $data, where: $where)
  }
`;
