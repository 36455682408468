import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import PropTypes from "prop-types";

import { LIST_USERS_QUERY } from "../../../graphql/user";
import COLUMNS from "./columns";
import {
  PageHeader,
  ListSearchInput,
  IconButton,
  DataTable,
} from "../../../components";
import { useModal, useAuth } from "../../../context";
import AddEmployeeModal from "../components/AddEmployeeModal";
import { debounce, downloadCSV } from "../../../utils";
import EmployeeFilters from "../components/EmployeeFilters";

const INITIAL_STATE = {
  Type: { is: { CodeId: { in: ["1", "2", "3"] } } },
};

const EmployeeListPage = ({ routePermissions }) => {
  const history = useHistory();
  const { setModalOpen } = useModal();
  const client = useApolloClient();
  const { state: authState } = useAuth();

  const [where, setWhere] = useState(INITIAL_STATE);
  const [canUpdate, setCanUpdate] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [labID, setLabID] = useState("");

  const orderBy = [{ id: "Username", desc: false }];

  useEffect(() => {
    setLabID(authState.user.LabID);
    if (authState.user.LabID !== "9999") {
      setWhere((prev) => ({
        ...prev,
        LabID: {
          equals: parseInt(authState.user.LabID, 10),
        },
      }));
    }  
  }, [authState]);

  const handleRowClick = (clientItem) => {
    history.push(`/employees/${clientItem.UserID}`);
  };

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  const handleCreateEmployee = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModalOpen(
      true,
      <AddEmployeeModal
        loggedUserLabID={parseInt(labID, 10)}
        onComplete={() => {
          setModalOpen(false);
        }}
      />
    );
  };

  const onFilterChange = (name, value) => {
    setWhere((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setWhere(() => {
      if (labID.toString() !== "9999") {
        return {
          ...INITIAL_STATE,
          LabID: {
            equals: parseInt(labID, 10),
          },
        };
      }     
      return INITIAL_STATE;
    });
  };

  return (
    <div className="employee-list-page">
      <PageHeader title="Employees">
        <ListSearchInput
          onChange={debounce((name, value) => {
            if (value) {
              setWhere({
                ...where,
                AND: [
                  {
                    OR: [
                      { Type: { is: { CodeId: { equals: "1" } } } },
                      { Type: { is: { CodeId: { equals: "2" } } } },
                      { Type: { is: { CodeId: { equals: "3" } } } },
                    ],
                  },
                  {
                    OR: [
                      { Username: { contains: value } },
                      { FirstName: { contains: value } },
                      { LastName: { contains: value } },
                      { Email: { contains: value } },
                    ],
                  },
                ],
              });
            } else {
              handleReset();
            }
          }, 500)}
        />
        <IconButton
          icon="filter"
          onClick={(e) => {
            e.preventDefault();
            setShowFilters((prev) => !prev);
          }}
        />
        {canUpdate && (
          <React.Fragment>
            <IconButton icon="plus" onClick={handleCreateEmployee} />
            <IconButton
              icon="file-export"
              onClick={() =>
                downloadCSV(
                  client,
                  {
                    query: LIST_USERS_QUERY,
                    where,
                    orderByMulti: orderBy,
                  },
                  "findManyUsers",
                  COLUMNS
                )
              }
            />
          </React.Fragment>
        )}
      </PageHeader>
      {showFilters && (
        <EmployeeFilters
          canSearchByLab={labID.toString() === "9999"}
          inputs={where}
          onChange={onFilterChange}
          onReset={handleReset}
        />
      )}
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateUsers"
        columns={COLUMNS}
        name="findManyUsers"
        orderBy={orderBy}
        query={LIST_USERS_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

EmployeeListPage.propTypes = {
  routePermissions: PropTypes.array,
};

EmployeeListPage.defaultProps = {
  routePermissions: [],
};

export default EmployeeListPage;
