/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/prop-types */
/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import {
  Column,
  Field,
  Title,
  Box,
  Input,
  Textarea,
  Control,
  Label,
  Image,
  Button,
} from "rbx";
import Collapse, { Panel } from "rc-collapse";
import { orderBy } from "lodash";

import SystemCodeSelectWithMemory from "../SystemCodeSelectWithMemory";
import InputWithMemory from "../InputWithMemory";
import InputWithSelectAndMemory from "../UnitSelectWithMemory";
import {
  FileUploader,
  SystemCodeSelect,
  BooleanInput,
} from "../../../../../../components";
import MMTCInfoSelectWithMemory from "../MMTCInfoSelectWithMemory";
import CultivatorsMultiSelectWithMemory from "../CultivatorsMultiSelectWithMemory";
import { getDateEST } from "../../../../../../utils";
import { useAuth } from "../../../../../../context";

const OrderForm = ({
  inputs,
  onChange,
  disabled,
  uploadJobOrderPath,
  disableIntakeStatus,
  user,
  substanceIDs,
  JobData,
  onInternalNoteSubmit,
}) => {
  const { state: authState } = useAuth();
  const [isHempSelected, setIsHempSelected] = useState(true);
  const [isMJSelected, setIsMJSelected] = useState(true);
  const [displayNote, setDisplayNote] = useState(false);
  const [internalNote, setInternalNote] = useState("");

  useEffect(() => {
    setIsHempSelected(() => {
      if (!!inputs?.OverrideData?.SubstanceType) {
        return inputs?.OverrideData?.SubstanceType === substanceIDs.hempID;
      }
      if (
        inputs.SubstanceType === "Hemp" ||
        inputs.SubstanceType === String(substanceIDs.hempID)
      ) {
        return true;
      }
      return false;
    });

    setIsMJSelected(() => {
      if (!!inputs?.OverrideData?.SubstanceType) {
        return inputs?.OverrideData?.SubstanceType === substanceIDs.mjID;
      }
      if (
        inputs.SubstanceType === "MJ" ||
        inputs.SubstanceType === String(substanceIDs.mjID)
      ) {
        return true;
      }
      return false;
    });
  }, [inputs, substanceIDs]);

  const renderNotes = () => {
    const notes = [];
    try {
      const currentNotes = JSON.parse(inputs.InternalNotes);
      notes.push(...currentNotes);
    } catch (e) {
      if (inputs.InternalNotes) {
        const dateAdded = getDateEST({
          timeZone: "America/New_York",
          hour12: true,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });
        const newNote = {
          note: inputs.InternalNotes,
          userIDAdded: authState.user.Username,
          dateAdded,
        };
        notes.push(newNote);
      }
    }
    const orderedNotes = orderBy(
      notes.filter((note) => !!note),
      [(obj) => new Date(obj.dateAdded)],
      ["desc"]
    );

    return (
      <div className="notes-wrapper">
        {orderedNotes.length ? (
          orderedNotes.map((note) => (
            <div key={`${note.dateAdded}+${note.note}`}>
              <p className="note-metadata">
                {note.userIDAdded} {note.dateAdded}
              </p>
              <p>{note.note}</p>
              <hr />
            </div>
          ))
        ) : (
          <div>No Internal Notes Found</div>
        )}
      </div>
    );
  };

  return (
    <Column.Group multiline>
      <Column className="accordion-column" size={12}>
        <Collapse accordion>
          <Panel
            header={
              <Control>
                <Title size={5}>Order Info</Title>
              </Control>
            }
          >
            <div className="panel-columns-wrapper">
              <Column desktop={{ size: 6 }} mobile={{ size: 12 }}>
                <Field kind="group">
                  <InputWithMemory
                    disabled={disabled}
                    inputs={inputs}
                    label="Order Name"
                    name="OrderName"
                    placeholder="Order Name"
                    onChange={onChange}
                  />
                  <Control expanded>
                    <Label>Order Created</Label>
                    <Input
                      disabled
                      name="OrderCreateDate"
                      placeholder="Order Created"
                      size="small"
                      type="text"
                      value={format(
                        new Date(inputs.OrderCreateDate),
                        "MM/dd/yyyy hh:mm a"
                      )}
                      onChange={({ target: { name, value } }) =>
                        onChange(name, value, true)
                      }
                    />
                  </Control>
                </Field>
                <Field kind="group">
                  <InputWithMemory
                    disabled={disabled}
                    inputs={inputs}
                    label="Batch Number"
                    name="BatchOrderNumber"
                    placeholder="Batch Number"
                    onChange={onChange}
                  />
                  <SystemCodeSelectWithMemory
                    expanded
                    disabled={disabled}
                    inputs={inputs}
                    label="Test Type"
                    name="TestType"
                    where={{
                      Type: { equals: "Test" },
                      Category: { equals: "Type" },
                      CodeName: { equals: "TestType" },
                    }}
                    onChange={onChange}
                  />
                  <SystemCodeSelectWithMemory
                    expanded
                    disabled={disabled}
                    inputs={inputs}
                    label="Serving Size"
                    name="Serving Size"
                    where={{
                      Type: { equals: "Serving" },
                      Category: { equals: "Size" },
                      CodeName: { equals: "ServingSize" },
                    }}
                    onChange={onChange}
                  />
                </Field>
              </Column>
              <Column desktop={{ size: 6 }} mobile={{ size: 12 }}>
                <Field kind="group">
                  <SystemCodeSelectWithMemory
                    expanded
                    disabled={disabled || JobData?.Lab?.State !== "FL"}
                    inputs={inputs}
                    label="Substance"
                    name="SubstanceType"
                    placeholder="Substance"
                    where={{
                      Type: { equals: "Substance" },
                      Category: { equals: "Type" },
                      CodeName: { equals: "SubstanceType" },
                    }}
                    onChange={onChange}
                  />
                  <SystemCodeSelectWithMemory
                    expanded
                    disabled={disabled}
                    inputs={inputs}
                    label="Product Category"
                    name="ProductCategory"
                    where={{
                      Type: { equals: "Product" },
                      Category: { equals: "Category" },
                      CodeName: { equals: "ProductCategory" },
                    }}
                    onChange={onChange}
                  />
                </Field>
                <Field kind="group">
                  <InputWithMemory
                    disabled={disabled}
                    inputs={inputs}
                    label="Harvest/Lot ID"
                    name="CustomerSampleID"
                    placeholder="Harvest/Lot ID"
                    onChange={onChange}
                  />
                  <InputWithMemory
                    disabled={disabled}
                    inputs={inputs}
                    label="Seed-to-Sale #"
                    name="LotID"
                    placeholder="Seed-to-Sale #"
                    onChange={onChange}
                  />
                  <Control expanded>
                    <Label>&nbsp;</Label>
                    <BooleanInput
                      disabled={disabled}
                      label="Retail Batch Retest"
                      name="RetailBatchRetest"
                      size="small"
                      value={
                        (inputs.RetailBatchRetestChanged
                          ? inputs.OverrideData.RetailBatchRetest
                          : inputs.StateRetest) || false
                      }
                      onChange={(name, value) => {
                        if (inputs.RetailBatchRetestChanged) {
                          onChange(name, value);
                        } else {
                          onChange("RetailBatchRetestChanged", value, true);
                          onChange(name, value);
                        }
                      }}
                    />
                  </Control>
                </Field>
              </Column>
            </div>
            <Column size={12}>
              <Field kind="group">
                <Control expanded>
                  <Label>Client Notes</Label>
                  <Textarea
                    disabled
                    name="ClientNotes"
                    placeholder="Client Notes"
                    size="small"
                    value={!!inputs.ClientNotes ? inputs.ClientNotes : ""}
                    onChange={({ target: { name, value } }) =>
                      onChange(name, value, true)
                    }
                  />
                </Control>
              </Field>
            </Column>
          </Panel>
        </Collapse>
        <Collapse accordion>
          <Panel
            header={
              <Control>
                <Title size={5}>Client Info</Title>
              </Control>
            }
          >
            <Column.Group multiline>
              <Column
                className="mmtc-column-1"
                desktop={{ size: 12 }}
                mobile={{ size: 12 }}
              >
                <Field kind="group">
                  <MMTCInfoSelectWithMemory
                    expanded
                    disabled={disabled}
                    inputs={inputs}
                    label="Cultivation Facility"
                    name="CultivationFacility"
                    user={user}
                    onChange={onChange}
                  />
                  <MMTCInfoSelectWithMemory
                    expanded
                    disabled={disabled}
                    inputs={inputs}
                    label="Processing Facility"
                    name="ProcessingFacility"
                    user={user}
                    onChange={onChange}
                  />
                  <CultivatorsMultiSelectWithMemory
                    expanded
                    disabled={disabled}
                    inputs={inputs}
                    label="Cultivars"
                    name="Cultivators"
                    user={user}
                    onChange={onChange}
                  />
                </Field>
              </Column>
            </Column.Group>
          </Panel>
        </Collapse>
        <Collapse accordion>
          <Panel
            header={
              <Control>
                <Title size={5}>Batch Info</Title>
              </Control>
            }
          >
            <Column>
              <Field kind="group">
                <InputWithMemory
                  expanded
                  disabled={disabled}
                  inputs={inputs}
                  label="ID"
                  name="ID"
                  placeholder="ID"
                  onChange={onChange}
                />
                <InputWithMemory
                  expanded
                  disabled={disabled}
                  inputs={inputs}
                  label="Date"
                  name="Date"
                  placeholder="Date"
                  type="date"
                  onChange={onChange}
                />
                <InputWithMemory
                  expanded
                  disabled={disabled}
                  inputs={inputs}
                  label="Expiration Date"
                  name="ExpDate"
                  placeholder="Expiration Date"
                  type="date"
                  onChange={onChange}
                />
              </Field>
              <Field className="grouped-field-on-tablet">
                <InputWithMemory
                  expanded
                  disabled={disabled}
                  inputs={inputs}
                  label="Size"
                  name="Size"
                  placeholder="Size"
                  type="number"
                  onChange={onChange}
                />
                <InputWithSelectAndMemory
                  expanded
                  disabled={disabled}
                  inputs={inputs}
                  label="Size Unit"
                  name="BatchSizeUnit"
                  onChange={onChange}
                />
                <InputWithMemory
                  expanded
                  disabled={disabled}
                  inputs={inputs}
                  label="Quantity"
                  name="Qty"
                  placeholder="Quantity"
                  type="number"
                  onChange={onChange}
                />
              </Field>
            </Column>
          </Panel>
        </Collapse>
      </Column>
      <Column size={12}>
        <Box>
          <Field kind="group">
            <Control expanded>
              <SystemCodeSelect
                disabled={disabled || disableIntakeStatus}
                label="Intake Status"
                name="Status"
                required={isMJSelected || isHempSelected}
                value={inputs.Status || ""}
                where={{
                  Type: { equals: "Intake" },
                  Category: { equals: "Status" },
                  CodeName: { equals: "IntakeStatus" },
                }}
                onChange={(name, value) => onChange(name, value, true)}
              />
            </Control>
            <Control expanded>
              <Label>Net Weight</Label>
              <Input
                disabled={disabled}
                name="NetWeight"
                placeholder="Net Weight"
                required={isMJSelected}
                size="small"
                type="text"
                value={!!inputs.NetWeight ? inputs.NetWeight : ""}
                onChange={({ target: { name, value } }) =>
                  onChange(name, value, true)
                }
              />
            </Control>
          </Field>
          <Field kind="group">
            <SystemCodeSelect
              disabled={disabled}
              label="Net Weight Unit"
              name="NetWeightUnit"
              required={isMJSelected}
              value={inputs.NetWeightUnit || ""}
              where={{
                Type: { equals: "Product" },
                Category: { equals: "Unit" },
                CodeName: { equals: "ProductUnit" },
              }}
              onChange={(name, value) => onChange(name, value, true)}
            />
            <Control expanded>
              <Label>Sample Weight at Pickup</Label>
              <Input
                disabled={disabled}
                name="PickupWeight"
                placeholder="Pickup Weight"
                required={isMJSelected}
                size="small"
                type="text"
                value={!!inputs.PickupWeight ? inputs.PickupWeight : ""}
                onChange={({ target: { name, value } }) =>
                  onChange(name, value, true)
                }
              />
            </Control>
          </Field>
          <Field className="file-column" kind="group">
            <div className="picture-wrapper">
              <Control size="small">
                <FileUploader
                  allowOnlyImages
                  disabled={disabled}
                  label="Picture"
                  name="Picture"
                  uploadPath={uploadJobOrderPath}
                  value={inputs.Picture || ""}
                  onChange={(name, value) => onChange(name, value, true)}
                />
              </Control>
              <Control size="small">
                <Image.Container size={128}>
                  <Image src={inputs.Picture} />
                </Image.Container>
              </Control>
            </div>
            <div className="important-wrapper">
              <Control expanded>
                <Label>&nbsp;</Label>
                <BooleanInput
                  disabled={disabled}
                  label="Important"
                  name="Important"
                  value={inputs.Important}
                  onChange={(name, value) => onChange(name, value, true)}
                />
              </Control>
            </div>
          </Field>
        </Box>
      </Column>
      <Column className="accordion-column" size={12}>
        <div className="page-head-container">
          <div className="page-head">
            <div className="page-head-start">
              <Title>Internal Notes</Title>
            </div>
            <div className="page-head-end">
              <Button
                color="primary"
                size="small"
                type="button"
                onClick={async () => {
                  if (internalNote) {
                    await onInternalNoteSubmit(
                      internalNote,
                      inputs.InternalNotes
                    );
                    setInternalNote(null);
                    setDisplayNote(false);
                  } else {
                    setDisplayNote(true);
                  }
                }}
              >
                {internalNote ? "Save" : "New note"}
              </Button>
            </div>
          </div>
        </div>
        <Box>
          {displayNote && (
            <Field>
              <Control expanded>
                <Textarea
                  name="internalNote"
                  size="small"
                  value={internalNote}
                  onChange={(e) => setInternalNote(e.target.value)}
                />
              </Control>
            </Field>
          )}
          {renderNotes()}
        </Box>
      </Column>
    </Column.Group>
  );
};

OrderForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  uploadJobOrderPath: PropTypes.string.isRequired,
  disableIntakeStatus: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  substanceIDs: PropTypes.object,
  JobData: PropTypes.object,
  onInternalNoteSubmit: PropTypes.func,
};

OrderForm.defaultProps = {
  disabled: false,
  substanceIDs: {},
  JobData: {},
  onInternalNoteSubmit: () => null,
};

export default OrderForm;
