import { gql } from "@apollo/client";

export const FIND_FIRST_PRIVATE_LABEL_COA_QUERY = gql`
  query FIND_FIRST_PRIVATE_LABEL_COA_QUERY(
    $where: PrivateLabelCOAsWhereInput
    $orderBy: [PrivateLabelCOAsOrderByInput!]
  ) {
    findFirstPrivateLabelCOAs(where: $where, orderBy: $orderBy) {
      COAID
      OwnerUserID
      JobOrderID
      UserNotified
    }
  }
`;

export const SINGLE_PRIVATE_LABEL_COA_QUERY = gql`
  query SINGLE_PRIVATE_LABEL_COA_QUERY(
    $where: PrivateLabelCOAsWhereUniqueInput!
  ) {
    findUniquePrivateLabelCOAs(where: $where) {
      COAID
      JobOrderID
      OwnerUserID
      PrivateLabelUserID
      UserNotified
    }
  }
`;
