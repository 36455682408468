import React, { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Button, Box, Control, Title, Field } from "rbx";
import { useModal, useAuth } from "../../../../../context";
import {
  PageHeader,
  ListSearchInput,
  Loader,
  QrReaderModal,
} from "../../../../../components";
import { SINGLE_JOB_QUERY } from "../../../../../graphql";
import { customToast as toast } from "../../../../../utils";

import "./IntakeSearchPage.scss";

const IntakeSearchPage = () => {
  const history = useHistory();
  const { setModalOpen } = useModal();
  const client = useApolloClient();
  const { state: authState } = useAuth();

  const [loading, setLoading] = useState(false);
  const [where, setWhere] = useState({ ReceiveStatus: { equals: 0 } });

  const searchJob = async (e) => {
    if (e.key === "Enter" && where.JobID) {
      setLoading(true);
      const { data: jobsData } = await client.query({
        query: SINGLE_JOB_QUERY,
        variables: {
          where,
        },
      });
      setLoading(false);
      if (jobsData?.findUniqueJobs?.JobID) {
        if (authState?.user?.LabID === jobsData?.findUniqueJobs?.LabID) {
          history.push(
            `/lims/intake/${jobsData.findUniqueJobs.JobID}/${
              jobsData.findUniqueJobs?.JobOrders[0]?.JobOrderID || ""
            }`
          );
        } else {
          toast.error("This job belongs to a different lab");
        }
      } else {
        toast.error("Error. Please try a different Job ID");
      }
    }
  };

  const scanCode = (e) => {
    e.preventDefault();
    setModalOpen(
      true,
      <QrReaderModal
        onClose={({ result }) => setWhere({ JobID: parseInt(result, 10) })}
      />
    );
  };

  if (loading) return <Loader />;
  return (
    <div className="intake-list-page">
      <PageHeader title="Intake" />
      <div style={{ justifyContent: "center", display: "flex" }}>
        <Box style={{ textAlign: "center" }}>
          <Control style={{ marginBottom: 10 }}>
            <Title subtitle>Search by Job ID</Title>
            <ListSearchInput
              placeholder="Enter Job ID..."
              onChange={(name, value) => {
                setWhere({
                  JobID: parseInt(value, 10),
                });
              }}
              onKeyPress={searchJob}
            />
          </Control>
          <Field kind="group" style={{ justifyContent: "center" }}>
            <Control>
              <Button
                color="primary"
                onClick={() => searchJob({ key: "Enter" })}
              >
                Search
              </Button>
            </Control>
            <Control>
              <Button onClick={scanCode}>Scan QR Code</Button>
            </Control>
          </Field>
        </Box>
      </div>
    </div>
  );
};

IntakeSearchPage.propTypes = {};

export default IntakeSearchPage;
