import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

import CouponListPage from "./CouponListPage";

const CouponRouter = ({ routePermissions }) => (
  <Switch>
    <Route path="/coupons/:CouponID">
      <CouponListPage routePermissions={routePermissions} />
    </Route>
    <Route path="/coupons">
      <CouponListPage routePermissions={routePermissions} />
    </Route>
  </Switch>
);

CouponRouter.propTypes = {
  routePermissions: PropTypes.array,
};

CouponRouter.defaultProps = {
  routePermissions: [],
};

export default CouponRouter;
