import React, { useState } from "react";

import { useAuth } from "../../../context/AuthContext";
import { DataTable, PageHeader, ListSearchInput } from "../../../components";
import { ALL_JOB_ORDERS_AND_LEGACY_JOB_ORDERS_QUERY } from "../../../graphql";
import DEFAULT_COLUMNS from "./columns";
import { debounce } from "../../../utils";

const ClientOrderListPage = () => {
  const { state: authState } = useAuth();
  const {
    user: { UserID, ChildClientIDs },
  } = authState;

  const INITIAL_STATE = {
    UserID: {
      in: [UserID, ...ChildClientIDs],
    },
  };

  const [where, setWhere] = useState(INITIAL_STATE);

  const handleReset = () => {
    setWhere(INITIAL_STATE);
  };

  const orderBy = [{ id: "OrderCreateDate", desc: true }];

  return (
    <div>
      <PageHeader title="Orders">
        <ListSearchInput
          onChange={debounce((name, value) => {
            if (value) {
              const newWhere = {
                ...INITIAL_STATE,
                ...where,
              };
              if (/^[0-9]+$/.test(value)) {
                newWhere.JobID = { equals: parseInt(value, 10) };
                newWhere.JobOrderID = { equals: parseInt(value, 10) };
              } else {
                newWhere.OrderName = { contains: value };
                newWhere.BatchOrderNumber = { contains: value };
              }
              setWhere(newWhere);
            } else {
              handleReset();
            }
          }, 500)}
        />
      </PageHeader>
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateJobOrdersAndLegacyJobOrders"
        columns={DEFAULT_COLUMNS}
        hiddenColumns={!ChildClientIDs.length ? ["Username"] : []}
        name="findManyJobOrdersAndLegacyJobOrders"
        orderBy={orderBy}
        query={ALL_JOB_ORDERS_AND_LEGACY_JOB_ORDERS_QUERY}
        where={where}
      />
    </div>
  );
};

export default ClientOrderListPage;
