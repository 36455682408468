import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useQuery } from "@apollo/client";
import { orderBy } from "lodash";

import { ALL_SYSTEM_CODES_QUERY } from "../../graphql";

const SystemCodeSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
  where,
  removeEmpty,
}) => {
  const { data: getSystemCodeData } = useQuery(ALL_SYSTEM_CODES_QUERY, {
    variables: { where, orderBy: { RecId: "asc" } },
  });

  const systemCodes = getSystemCodeData?.findManySystemCodes
    ? orderBy(
        getSystemCodeData.findManySystemCodes,
        [(obj) => obj.CodeDescription],
        ["asc"]
      )
    : [];

  const indicateRequired = required && (value === "" || value === null);

  const handleChange = (e) => {
    onChange(
      e.target.name,
      name === "Item" || name === "NetWeightUnit"
        ? setValue(e.target.value || "")
        : setValue(e.target.value ? parseInt(e.target.value, 10) : "")
    );
  };

  return (
    <Control expanded>
      {label && <Label>{label}</Label>}
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(systemCodes) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          required={required}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          {!removeEmpty && <Select.Option value="" />}
          {Array.isArray(systemCodes) &&
            systemCodes.map((code) => (
              <Select.Option
                key={code.RecId}
                value={
                  name === "Item" || name === "NetWeightUnit"
                    ? code?.CodeID
                    : code?.RecId
                }
              >
                {code.CodeDescription}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

SystemCodeSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  required: PropTypes.bool,
  where: PropTypes.object,
  removeEmpty: PropTypes.bool,
};

SystemCodeSelect.defaultProps = {
  value: "",
  name: "",
  label: "",
  size: "small",
  required: false,
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  where: {},
  removeEmpty: false,
};

export default SystemCodeSelect;
