import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./IconButton.scss";

const IconButton = ({
  onClick,
  icon,
  color,
  disabled,
  size,
  tooltipClass,
  tooltipText,
}) => (
  <Button
    className={tooltipClass}
    color={color}
    data-tooltip={tooltipText || null}
    disabled={disabled}
    id="icon-button"
    size={size}
    style={{ overflow: "visible" }}
    onClick={onClick}
  >
    <Icon>
      <FontAwesomeIcon icon={icon} />
    </Icon>
  </Button>
);

IconButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  tooltipClass: PropTypes.string,
  tooltipText: PropTypes.string,
};

IconButton.defaultProps = {
  onClick: (e) => e,
  color: "primary",
  disabled: false,
  size: "normal",
  tooltipClass: "",
  tooltipText: "",
};

export default IconButton;
