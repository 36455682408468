/* eslint-disable max-lines-per-function */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Button, Control, Title, Field } from "rbx";

import { useModal, useAuth } from "../../../../../../context";
import {
  PageHeader,
  ListSearchInput,
  Loader,
  QrReaderModal,
  QualifierMultiSelect,
} from "../../../../../../components";
import {
  SINGLE_JOB_ORDER_TEST_SAMPLE_QUERY,
  UPDATE_JOB_ORDER_TEST_SAMPLE,
  CREATE_BATCH_LOG_MUTATION,
  ALL_QUALIFIERS_QUERY,
  FIND_FIRST_QC_SAMPLE_QUERY,
  SINGLE_BATCH_QUERY,
  UPDATE_QC_SAMPLE_MUTATION,
  SINGLE_BATCH_WITH_ANALYTES_QUERY,
  UPDATE_MANY_JOB_ORDER_SAMPLE_MUTATION,
} from "../../../../../../graphql";
import { customToast as toast } from "../../../../../../utils";
import AddQualifiersAnalytestTable from "../AddQualifiersAnalytesTable";

const AddQualifiersModal = ({
  onComplete,
  allBatchesSamples,
  BatchID: batchId,
  JobOrderTestSamples,
}) => {
  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();

  const client = useApolloClient();
  const [updateJobOrderTestSample] = useMutation(UPDATE_JOB_ORDER_TEST_SAMPLE);
  const [updateQCSample] = useMutation(UPDATE_QC_SAMPLE_MUTATION);
  const [createBatchLogs] = useMutation(CREATE_BATCH_LOG_MUTATION);
  const [updateManyJobOrderTestSample] = useMutation(
    UPDATE_MANY_JOB_ORDER_SAMPLE_MUTATION
  );
  const { data: batchAnalytesData } = useQuery(
    SINGLE_BATCH_WITH_ANALYTES_QUERY,
    {
      variables: {
        where: {
          BatchID: parseInt(batchId, 10),
        },
      },
    }
  );

  const [loading, setLoading] = useState(false);
  const [qcSample, setQCSample] = useState({});
  const [batch, setBatch] = useState({});
  const [where, setWhere] = useState({});
  const [jobOrderTestSample, setJobOrderTestSample] = useState({});
  const [analytes, setAnalytes] = useState([]);
  const [addAllQualifiers, setAddAllQualifiers] = useState([]);
  const [displayAllSamples, setDisplayAllSamples] = useState(false);

  useEffect(() => {
    if (batchAnalytesData?.findUniqueBatches && displayAllSamples) {
      const foundAnalytes =
        batchAnalytesData.findUniqueBatches?.TestCategory?.Analytes;
      const result = foundAnalytes.map(({ Name }) => ({
        name: Name,
        value: [],
      }));
      setAnalytes(result);
    }
  }, [batchAnalytesData, displayAllSamples]);

  const handleAddAllChange = (value) => {
    // check if user added
    value.forEach((val) => {
      const changedValue = addAllQualifiers.find(
        (qualifier) => qualifier.value === val.value
      );
      if (!changedValue) {
        setAnalytes((prev) => {
          const prevArr = [...prev];
          prevArr.forEach((item) => {
            if (Array.isArray(item.value)) {
              if (
                !item.value.some((currentVal) => currentVal.value === val.value)
              ) {
                item.value = [...item.value, val];
              }
            } else {
              item.value = [val];
            }
          });
          return prevArr;
        });
      }
    });

    // check if user deleted
    addAllQualifiers.forEach((qualifier) => {
      const changedValue = value.find((val) => val.value === qualifier.value);
      if (!changedValue) {
        setAnalytes((prev) => {
          const prevArr = [...prev];
          prevArr.forEach((item) => {
            if (Array.isArray(item.value)) {
              item.value = item.value.filter(
                (val) => val.value !== qualifier.value
              );
            }
          });
          return prevArr;
        });
      }
    });
    setAddAllQualifiers(value);
  };

  const searchJobOrder = async (e) => {
    if (e.key === "Enter") {
      if (!where.SessionID) {
        toast.error("Session ID can't be empty.");
        return;
      }
      setLoading(true);
      try {
        if (where.SessionID.equals.slice(0, 2) === "99") {
          const { data: qcSampleData } = await client.query({
            query: FIND_FIRST_QC_SAMPLE_QUERY,
            variables: {
              where,
            },
          });

          if (qcSampleData.findFirstQCSamples) {
            const BatchID = parseInt(
              qcSampleData.findFirstQCSamples.BatchID,
              10
            );

            const { data: batchData } = await client.query({
              query: SINGLE_BATCH_QUERY,
              variables: {
                where: {
                  BatchID,
                },
              },
            });

            if (batchData?.findUniqueBatches?.TestCategory?.Analytes?.length) {
              setQCSample(qcSampleData.findFirstQCSamples);
              setBatch(batchData.findUniqueBatches);
              const foundAnalytes =
                batchData.findUniqueBatches.TestCategory.Analytes;

              const { data: qualifiersData } = await client.query({
                query: ALL_QUALIFIERS_QUERY,
                variables: {
                  where: {
                    States: {
                      contains: batchData.findUniqueBatches?.Lab?.State,
                    },
                  },
                },
              });

              let currentQualifiers = [];
              if (qcSampleData?.findFirstQCSamples?.Qualifiers) {
                currentQualifiers = Object.keys(
                  qcSampleData.findFirstQCSamples.Qualifiers
                );
              }

              const analytesToRender = foundAnalytes.map(({ Name }) => {
                if (currentQualifiers.includes(Name)) {
                  const mappedValues =
                    qcSampleData.findFirstQCSamples.Qualifiers[
                      Name
                      // eslint-disable-next-line consistent-return
                    ].map((currentQualifier) => {
                      const foundVal = qualifiersData.findManyQualifiers?.find(
                        (qualifier) =>
                          qualifier.QualifierID === currentQualifier.toString()
                      );
                      return {
                        label: foundVal.Name,
                        value: foundVal.QualifierID,
                      };
                    });
                  return {
                    name: Name,
                    value: mappedValues,
                  };
                }
                return {
                  name: Name,
                  value: "",
                };
              });
              setAnalytes(analytesToRender);
            } else {
              toast.error("No analytes associated to this session ID.");
              return;
            }
          } else {
            toast.error("Session ID not found.");
            return;
          }
        } else {
          const { data: jobOrderTestSampleData } = await client.query({
            query: SINGLE_JOB_ORDER_TEST_SAMPLE_QUERY,
            variables: {
              where,
            },
            fetchPolicy: "network-only",
          });

          if (
            jobOrderTestSampleData?.findFirstJobOrderTestSamples
              ?.JobOrderTestSampleID
          ) {
            const sampleTests =
              jobOrderTestSampleData.findFirstJobOrderTestSamples.JobOrderTest
                .Test.TestTestCategories;
            if (!sampleTests.length) {
              toast.error("No test category associated to this session ID.");
              return;
            }
            if (!jobOrderTestSampleData?.findFirstJobOrderTestSamples.BatchID) {
              toast.error(
                "Please add this session ID to a batch before adding qualifiers."
              );
              return;
            }

            const foundAnalytesData = sampleTests[0].TestCategory?.Analytes;
            if (Array.isArray(foundAnalytesData) && !foundAnalytesData.length) {
              toast.error("No analytes associated to this session ID.");
            } else {
              const { data: qualifiersData } = await client.query({
                query: ALL_QUALIFIERS_QUERY,
                variables: {
                  where: {
                    States: {
                      contains:
                        jobOrderTestSampleData?.findFirstJobOrderTestSamples
                          ?.Job?.Lab?.State,
                    },
                  },
                },
              });

              setJobOrderTestSample(
                jobOrderTestSampleData.findFirstJobOrderTestSamples
              );
              let currentQualifiers = [];
              if (
                jobOrderTestSampleData.findFirstJobOrderTestSamples?.Qualifiers
              ) {
                currentQualifiers = Object.keys(
                  jobOrderTestSampleData.findFirstJobOrderTestSamples
                    ?.Qualifiers
                );
              }

              const analytesToRender = foundAnalytesData.map(({ Name }) => {
                if (currentQualifiers.includes(Name)) {
                  const mappedValues =
                    jobOrderTestSampleData.findFirstJobOrderTestSamples.Qualifiers[
                      Name
                    ].map((currentQualifier) => {
                      const foundVal = qualifiersData.findManyQualifiers?.find(
                        (qualifier) =>
                          qualifier.QualifierID === currentQualifier.toString()
                      );
                      return {
                        label: foundVal.Name,
                        value: foundVal.QualifierID,
                      };
                    });
                  return {
                    name: Name,
                    value: mappedValues,
                  };
                }
                return {
                  name: Name,
                  value: "",
                };
              });
              setAnalytes(analytesToRender);
            }
          } else {
            toast.error("Session ID not found.");
            return;
          }
        }
      } catch (err) {
        toast.error("An error ocurred. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  const scanCode = (e) => {
    e.preventDefault();
    setModalOpen(
      true,
      <QrReaderModal
        onClose={({ result }) => setWhere({ SessionID: { equals: result } })}
      />
    );
  };

  const handleQualifiersChange = (index, value) => {
    setAnalytes((prev) => {
      const newObj = [...prev];
      newObj[index].value = value;
      return newObj;
    });
  };

  const handleSave = async () => {
    setLoading(true);
    const BatchID = jobOrderTestSample?.BatchID || qcSample?.BatchID || batchId;
    let SessionID = jobOrderTestSample?.SessionID || qcSample?.SessionID;
    try {
      const newQualifiers = analytes.reduce((acc, curr) => {
        if (curr.value.length) {
          const selectedValues = curr.value.map((value) => value.value);
          acc[curr.name] = selectedValues;
          return acc;
        }
        return acc;
      }, {});

      if (Object.keys(qcSample).length && !displayAllSamples) {
        await updateQCSample({
          variables: {
            where: {
              QCSampleID: parseInt(qcSample.QCSampleID, 10),
            },
            data: {
              Qualifiers: newQualifiers,
            },
          },
          refetchQueries: [
            {
              query: FIND_FIRST_QC_SAMPLE_QUERY,
              variables: {
                where: {
                  SessionID: {
                    equals: qcSample.SessionID,
                  },
                },
              },
              fetchPolicy: "network-only",
            },
          ],
        });
      } else if (
        displayAllSamples &&
        Array.isArray(JobOrderTestSamples) &&
        JobOrderTestSamples.length
      ) {
        const { data: updatedResult } = await updateManyJobOrderTestSample({
          variables: {
            where: JobOrderTestSamples.map(
              (sample) => sample.JobOrderTestSampleID
            ),
            data: {
              Qualifiers: newQualifiers,
            },
          },
          refetchQueries: [
            {
              query: SINGLE_BATCH_QUERY,
              variables: {
                where: {
                  BatchID: parseInt(BatchID, 10),
                },
              },
            },
          ],
        });
        SessionID = JobOrderTestSamples.map((sample) => sample.SessionID).join(
          ", "
        );
        if (updatedResult?.updateManyJobOrderTestSampleQualifiers) {
          toast.success("Qualifiers added successfully");
        } else {
          toast.error("Error on adding Qualifiers");
        }
      } else {
        await updateJobOrderTestSample({
          variables: {
            where: {
              JobOrderTestSampleID: parseInt(
                jobOrderTestSample.JobOrderTestSampleID,
                10
              ),
            },
            data: {
              Qualifiers: newQualifiers,
            },
          },
          refetchQueries: [
            {
              query: SINGLE_JOB_ORDER_TEST_SAMPLE_QUERY,
              variables: {
                where: {
                  SessionID: {
                    equals: jobOrderTestSample.SessionID,
                  },
                },
              },
              fetchPolicy: "network-only",
            },
            {
              query: SINGLE_BATCH_QUERY,
              variables: {
                where: {
                  BatchID: parseInt(BatchID, 10),
                },
              },
            },
          ],
        });
      }
      await createBatchLogs({
        variables: {
          data: {
            Change: `Updated Qualifiers on Session ID ${SessionID}`,
            Batches: {
              connect: {
                BatchID: parseInt(BatchID, 10),
              },
            },
            CreatedBy: authState.user.Username,
          },
        },
      });
      toast.success("Qualifiers updated successfully.");
      onComplete();
    } catch (err) {
      toast.error("An error has occured.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="add-qualifiers-page">
      <PageHeader title="Add Qualifiers">
        <Button color="primary" size="small" onClick={onComplete}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!analytes.length}
          size="small"
          onClick={handleSave}
        >
          Save
        </Button>
      </PageHeader>
      {!analytes.length ? (
        <div style={{ justifyContent: "center", display: "flex" }}>
          <div style={{ width: "50%", textAlign: "center" }}>
            <Control style={{ marginBottom: 10 }}>
              <Title subtitle>Enter Session ID</Title>
              <ListSearchInput
                placeholder="Enter Session ID..."
                onChange={(name, value) => {
                  if (!value.length) {
                    setWhere({});
                  } else {
                    setWhere({
                      SessionID: {
                        equals: value,
                      },
                    });
                  }
                }}
                onKeyPress={searchJobOrder}
              />
            </Control>
            <Field kind="group" style={{ justifyContent: "center" }}>
              {allBatchesSamples && (
                <Control>
                  <Button
                    size="small"
                    type="button"
                    onClick={() => setDisplayAllSamples(true)}
                  >
                    All Batch Samples
                  </Button>
                </Control>
              )}
              <Control>
                <Button
                  color="primary"
                  size="small"
                  onClick={() => searchJobOrder({ key: "Enter" })}
                >
                  Search
                </Button>
              </Control>
              <Control>
                <Button size="small" onClick={scanCode}>
                  Scan QR Code
                </Button>
              </Control>
            </Field>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <QualifierMultiSelect
            label="Add to all analytes"
            name="Qualifiers"
            state={jobOrderTestSample?.Job?.Lab?.State || batch?.Lab?.State}
            value={addAllQualifiers}
            onChange={(name, value) => handleAddAllChange(value)}
          />
          <br />
          <AddQualifiersAnalytestTable
            data={analytes}
            state={jobOrderTestSample?.Job?.Lab?.State || batch?.Lab?.State}
            onChange={handleQualifiersChange}
          />
        </React.Fragment>
      )}
    </div>
  );
};

AddQualifiersModal.propTypes = {
  onComplete: PropTypes.func,
  allBatchesSamples: PropTypes.bool,
  BatchID: PropTypes.string,
  JobOrderTestSamples: PropTypes.array,
};

AddQualifiersModal.defaultProps = {
  onComplete: () => null,
  allBatchesSamples: false,
  BatchID: "",
  JobOrderTestSamples: [],
};

export default AddQualifiersModal;
