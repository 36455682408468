/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Button, Tag, Title, Dropdown, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams, useHistory } from "react-router-dom";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import TestStatusForm from "../components/TestStatusForm";
import AddQualifiersModal from "../components/AddQualifiersModal";
import PerUnitCalculator from "../components/PerUnitCalculator";
import {
  Loader,
  PageHeader,
  DataTable,
  ReasonModal,
} from "../../../../../components";
import { customToast as toast } from "../../../../../utils";
import { useAuth } from "../../../../../context/AuthContext";
import {
  UPDATE_JOB_MUTATION,
  SINGLE_JOB_QUERY,
  UPDATE_JOB_ORDER_MUTATION,
  SINGLE_FIRST_USER_TEST_QUERY,
  CREATE_JOB_ORDER_TEST_MUTATION,
  ALL_JOB_ORDER_TESTS_QUERY,
  CREATE_JOB_ORDER_TEST_SAMPLES,
  ALL_JOB_LOGS_QUERY,
  ALL_HOMOGENEITY_JOB_ORDER_TEST_RESULTS_QUERY,
  CANCEL_JOB_MUTATION,
  ALL_JOB_ORDER_TEST_SAMPLES_QUERY,
} from "../../../../../graphql";
import { useModal } from "../../../../../context";
import { COLUMNS } from "./jobLogColumns";
import TestResultModal from "../components/TestResultModal";
import "./TestStatusEditPage.scss";

const INITIAL_STATE = {
  OrderName: "",
  LabelClaimTHC: "",
  LabelClaimUnitTHC: "",
  LabelClaimCBD: "",
  LabelClaimUnitCBD: "",
  ActualLabelClaimTHC: "",
  ActualLabelClaimUnitTHC: "",
  ActualLabelClaimCBD: "",
  ActualLabelClaimUnitCBD: "",
  CultivationFacility: "",
  ProcessingFacility: "",
  CultivatorsNum: "",
  Cultivators: "",
  CouponID: "",
  ReceiveStatus: "",
  JobID: "",
  HidePerPackage: false,
  Coupon: {
    CouponID: "",
    Code: "",
    Percent: "",
    Discount: "",
    Type: "",
  },
  User: {
    UserID: "",
    Username: "",
    FirstName: "",
    LastName: "",
    Company: "",
    Notes: {
      ClientNotes: "",
      TestNotes: "",
    },
  },
};
const TestStatusEditPage = ({ routePermissions }) => {
  const history = useHistory();
  const { setModalOpen } = useModal();
  const { JobID, JobOrderID } = useParams();
  const { state: authState } = useAuth();
  const client = useApolloClient();
  const orderBy = [{ id: "CreatedDateTime", desc: true }];
  const [where, setWhere] = useState({});

  const [editMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canExportCsv, setCanExportCsv] = useState(false);
  const [allTestApproved, setAllTestApproved] = useState(false);
  const [canAddQualifiers, setCanAddQualifiers] = useState(false);
  const [
    selectedOrderIncludesFailedResult,
    setSelectedOrderIncludesFailedResult,
  ] = useState(false);

  const [getJobData, resultJobData] = useLazyQuery(SINGLE_JOB_QUERY, {
    fetchPolicy: "network-only",
  });
  const [updateJob] = useMutation(UPDATE_JOB_MUTATION);
  const [updateJobOrder] = useMutation(UPDATE_JOB_ORDER_MUTATION);
  const [createJobOrderTest] = useMutation(CREATE_JOB_ORDER_TEST_MUTATION);
  const [createJobOrderTestSamples] = useMutation(
    CREATE_JOB_ORDER_TEST_SAMPLES
  );
  const [cancelJob] = useMutation(CANCEL_JOB_MUTATION);
  const [getJobOrderTests, { data: jobOrderTestsData }] = useLazyQuery(
    ALL_JOB_ORDER_TESTS_QUERY
  );

  const canCancelJob = useMemo(() => {
    const job = resultJobData?.data?.findUniqueJobs;
    const currentOrder = job?.JobOrders?.find(
      (jobOrder) => jobOrder.JobOrderID === JobOrderID
    );
    return !!authState.permissions?.find(
      (p) =>
        p.Code === "CANCEL_JOB" &&
        p.Update &&
        currentOrder?.JobOrderStatus?.CodeDescription !== "Cancelled"
    );
  }, [authState.permissions, resultJobData, JobOrderID]);

  const isOrderCancelled = useMemo(() => {
    const job = resultJobData?.data?.findUniqueJobs;
    const orderStatus = job?.JobOrders[0]?.JobOrderStatus?.CodeDescription;
    return orderStatus === "Cancelled";
  }, [resultJobData]);

  useEffect(() => {
    setWhere({
      JobID: {
        equals: parseInt(JobID, 10),
      },
    });
  }, [JobID]);

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  useEffect(() => {
    if (Array.isArray(authState.permissions) && authState.permissions.length) {
      const found = authState.permissions.find(
        (permission) => permission.Code === "DATA_EXPORT"
      );
      if (found && found.Update) {
        setCanExportCsv(true);
      } else {
        setCanExportCsv(false);
      }
    } else {
      setCanExportCsv(false);
    }
  }, [authState]);

  useEffect(() => {
    if (Array.isArray(authState.permissions) && authState.permissions.length) {
      const found = authState.permissions.find(
        (permission) => permission.Code === "ADD_SAMPLE_QLFR"
      );
      if (found && found.Update) {
        setCanAddQualifiers(true);
      } else {
        setCanAddQualifiers(false);
      }
    } else {
      setCanAddQualifiers(false);
    }
  }, [authState]);

  useEffect(() => {
    if (JobOrderID) {
      getJobOrderTests({
        variables: {
          where: {
            JobOrderID: { equals: parseInt(JobOrderID, 10) },
          },
        },
      });
    }
  }, [getJobOrderTests, JobOrderID]);

  useEffect(() => {
    if (JobOrderID && inputs.JobOrders) {
      const jobOrrderSelected = inputs.JobOrders.find(
        (jobOrder) =>
          parseInt(jobOrder.JobOrderID, 10) === parseInt(JobOrderID, 10)
      );
      setSelectedOrderIncludesFailedResult(
        jobOrrderSelected ? jobOrrderSelected.OrderIncludesFailedResult : false
      );
    }
  }, [JobOrderID, inputs.JobOrders]);

  useEffect(() => {
    if (jobOrderTestsData?.findManyJobOrderTests) {
      const { findManyJobOrderTests } = jobOrderTestsData;
      const approvedOrArchived = findManyJobOrderTests.filter(
        (jobTest) => jobTest.Approved || jobTest.Archived
      );
      setAllTestApproved(
        findManyJobOrderTests.length === approvedOrArchived.length
      );
    }
  }, [jobOrderTestsData]);

  const uploadJobOrderPath = useMemo(() => {
    const date = new Date();
    return `/Images/JobOrders/${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${JobOrderID}`;
  }, [JobOrderID]);

  useEffect(() => {
    if (JobID) {
      getJobData({
        variables: {
          where: { JobID: parseInt(JobID, 10) },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [JobID, getJobData]);

  useEffect(() => {
    let findUniqueJobs = resultJobData?.data?.findUniqueJobs;
    if (findUniqueJobs) {
      const JobOrders = [...resultJobData?.data?.findUniqueJobs?.JobOrders];
      const JobOrdersUpdated = [];
      JobOrders &&
        Array.isArray(JobOrders) &&
        JobOrders.forEach((JobOrder) => {
          const { OverrideData } = JobOrder;
          let fieldsChanged = {};
          OverrideData &&
            Object.keys(OverrideData).reduce((acc, curr) => {
              fieldsChanged = {
                ...fieldsChanged,
                [`${curr}Changed`]: true,
              };
              return acc;
            }, {});
          JobOrdersUpdated.push({
            ...JobOrder,
            ...fieldsChanged,
            ...JobOrder.LabelClaim,
            ...JobOrder.MMTCInfo,
            ...JobOrder.BatchInfo,
          });
        });

      findUniqueJobs = { ...findUniqueJobs, JobOrders: JobOrdersUpdated };
      setInputs((prev) => ({
        ...prev,
        ...findUniqueJobs,
      }));
      setLoading(false);
    }
  }, [resultJobData?.data?.findUniqueJobs]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleOrderChange = (name, value, notOverrideData = false) => {
    if (name.includes("Changed")) {
      const nameChanged = name.replace("Changed", "");
      const orderToEdit = inputs.JobOrders.find(
        (jobOrder) => jobOrder.JobOrderID === JobOrderID
      );
      const OverrideData = { ...orderToEdit.OverrideData };
      if (!value) {
        delete OverrideData[nameChanged];
      } else {
        OverrideData[nameChanged] = orderToEdit[nameChanged];
      }
      setInputs((prev) => ({
        ...prev,
        JobOrders: prev.JobOrders.map((jobOrder) =>
          jobOrder.JobOrderID === JobOrderID
            ? { ...orderToEdit, OverrideData, [name]: value }
            : jobOrder
        ),
      }));
    } else if (!notOverrideData) {
      setInputs((prev) => ({
        ...prev,
        JobOrders: prev.JobOrders.map((jobOrder) =>
          jobOrder.JobOrderID === JobOrderID
            ? {
                ...jobOrder,
                OverrideData: { ...jobOrder.OverrideData, [name]: value },
              }
            : jobOrder
        ),
      }));
    } else if (notOverrideData) {
      setInputs((prev) => ({
        ...prev,
        JobOrders: prev.JobOrders.map((jobOrder) =>
          jobOrder.JobOrderID === JobOrderID
            ? {
                ...jobOrder,
                [name]: value,
              }
            : jobOrder
        ),
      }));
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setLoading(true);
      await updateJob({
        variables: {
          data: {
            JobOrders: {
              update: inputs.JobOrders.map((jobOrder) => ({
                where: {
                  JobOrderID: parseInt(jobOrder.JobOrderID, 10),
                },
                data: {
                  OverrideData:
                    jobOrder.OverrideData &&
                    Object.keys(jobOrder.OverrideData).length
                      ? {
                          Override: true,
                          ...jobOrder.OverrideData,
                        }
                      : {},
                  Picture: { set: jobOrder.Picture },
                  NetWeight: { set: parseFloat(jobOrder.NetWeight) },
                  NetWeightUnit: { set: jobOrder.NetWeightUnit },
                  PickupWeight: { set: jobOrder.PickupWeight },
                  InternalNotes: { set: jobOrder.InternalNotes },
                  COANotes: { set: jobOrder.COANotes },
                  HidePerPackage: { set: jobOrder.HidePerPackage },
                  ClientNotes: { set: jobOrder.ClientNotes },
                  ActualLabelClaims: {
                    upsert: jobOrder.ActualLabelClaims?.filter(
                      (lc) => lc.LabelClaim
                    )?.map((lc) => ({
                      where: {
                        ActualLabelClaimID:
                          parseInt(lc.ActualLabelClaimID, 10) || 0,
                      },
                      create: {
                        LabelClaim: lc.LabelClaim,
                        Analyte: { connect: { AnalyteID: lc.AnalyteID } },
                      },
                      update: {
                        LabelClaim: { set: lc.LabelClaim },
                      },
                    })),
                    deleteMany: jobOrder.ActualLabelClaims?.filter(
                      (lc) => lc.ActualLabelClaimID && !lc.LabelClaim
                    )?.map((lc) => ({
                      ActualLabelClaimID: {
                        equals: parseInt(lc.ActualLabelClaimID, 10) || 0,
                      },
                    })),
                  },
                },
              })),
            },
          },
          where: {
            JobID: parseInt(JobID, 10),
          },
        },
        refetchQueries: [
          // {
          //   query: ALL_JOB_ORDERS_QUERY,
          //   fetchPolicy: "network-only",
          // },
          {
            query: SINGLE_JOB_QUERY,
            variables: {
              where: { JobID: parseInt(JobID, 10) },
            },
            fetchPolicy: "network-only",
          },
          {
            query: ALL_HOMOGENEITY_JOB_ORDER_TEST_RESULTS_QUERY,
            variables: {
              where: {
                JobOrderID: { equals: parseInt(JobOrderID, 10) },
              },
            },
          },
        ],
      });
      toast.success("Job Order updated successfully");
    } catch (err) {
      toast.error("Error updating Job Order");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePerUnitCalculation = (jobOrder) => {
    const handleUpdateJobOrder = async (calculator) => {
      setModalOpen(false);
      await updateJobOrder({
        variables: {
          data: {
            DisplayUnit: calculator,
          },
          where: { JobOrderID: parseInt(jobOrder.JobOrderID, 10) },
        },
        refetchQueries: [
          {
            query: SINGLE_JOB_QUERY,
            variables: {
              where: { JobID: parseInt(JobID, 10) },
            },
          },
          {
            query: ALL_HOMOGENEITY_JOB_ORDER_TEST_RESULTS_QUERY,
            variables: {
              where: {
                JobOrderID: { equals: parseInt(JobOrderID, 10) },
              },
            },
          },
        ],
      });
    };
    setModalOpen(
      true,
      <PerUnitCalculator
        inputs={jobOrder.DisplayUnit}
        orderID={jobOrder.JobOrderID}
        type={jobOrder.SubstanceType}
        onComplete={handleUpdateJobOrder}
      />
    );
  };

  const handleAddTest = async (selectedTest) => {
    try {
      setLoading(true);
      const { data: userTest } = await client.query({
        query: SINGLE_FIRST_USER_TEST_QUERY,
        variables: {
          where: {
            UserID: { equals: parseInt(inputs.User?.UserID, 10) },
            TestID: { equals: parseInt(selectedTest, 10) },
            Active: { equals: 1 },
          },
        },
      });

      const newOrderTest = {
        JobID: parseInt(JobID, 10),
        JobOrder: {
          connect: {
            JobOrderID: parseInt(JobOrderID, 10),
          },
        },
        LabJobOrderTestID: 0,
        UserTestID: parseInt(userTest?.findFirstUserTests?.UserTestID, 10) || 0,
        UserID: parseInt(inputs.User?.UserID, 10),
        Test: {
          connect: {
            TestID: parseInt(selectedTest, 10),
          },
        },
        Weight: "0",
        UserPrice: parseFloat(userTest?.findFirstUserTests?.UserPrice) || 0,
        HideOnCoA: 0,
        TestStatus: 0,
        Approved: 0,
        UploadUserID: 0,
        RePrepFlag: 0,
        ResultToDisplay: 0,
        Result: null,
        ResultReprep: null,
      };
      const { data: jobOrderTestCreated } = await createJobOrderTest({
        variables: {
          data: newOrderTest,
        },
        refetchQueries: [
          {
            query: ALL_JOB_ORDER_TESTS_QUERY,
            variables: {
              where: {
                JobOrderID: { equals: parseInt(JobOrderID, 10) },
              },
              take: 25,
              skip: 0,
            },
          },
          {
            query: ALL_HOMOGENEITY_JOB_ORDER_TEST_RESULTS_QUERY,
            variables: {
              where: {
                JobOrderID: { equals: parseInt(JobOrderID, 10) },
              },
            },
          },
        ],
      });
      await createJobOrderTestSamples({
        variables: {
          data: {
            SessionID: `${JobID}_${JobOrderID}_${jobOrderTestCreated.createJobOrderTests.JobOrderTestID}`,
            JobOrderTest: {
              connect: {
                JobOrderTestID: parseInt(
                  jobOrderTestCreated.createJobOrderTests.JobOrderTestID,
                  10
                ),
              },
            },
            JobOrder: {
              connect: {
                JobOrderID: parseInt(JobOrderID, 10),
              },
            },
            Job: {
              connect: {
                JobID: parseInt(JobID, 10),
              },
            },
            PrepNo: 0,
            CreatedBy: authState?.user?.Username,
            ModifiedBy: authState?.user?.Username,
          },
        },
      });
    } catch (err) {
      toast.error("Connection error");
    } finally {
      setLoading(false);
    }
  };

  const handleOpenResultModal = (JobOrderTestID) => {
    setModalOpen(
      true,
      <TestResultModal
        JobOrderID={JobOrderID}
        JobOrderTestID={JobOrderTestID}
        onComplete={() => setModalOpen(false)}
      />
    );
  };

  const handleAddQualifiersModal = (e) => {
    e.preventDefault();
    setModalOpen(
      true,
      <AddQualifiersModal onComplete={() => setModalOpen(false)} />
    );
  };

  const handleCancelJob = (e) => {
    e.preventDefault();
    const performCancelJob = async (reason) => {
      const { data: cancelResultData } = await cancelJob({
        variables: {
          data: {
            JobOrderID: parseInt(JobOrderID, 10),
            JobID: parseInt(JobID, 10),
            Change: reason,
            CreatedBy: authState.user.Username,
          },
        },
        refetchQueries: [
          {
            query: SINGLE_JOB_QUERY,
            variables: {
              where: { JobID: parseInt(JobID, 10) },
            },
            fetchPolicy: "network-only",
          },
          {
            query: ALL_HOMOGENEITY_JOB_ORDER_TEST_RESULTS_QUERY,
            variables: {
              where: {
                JobOrderID: { equals: parseInt(JobOrderID, 10) },
              },
            },
          },
          {
            query: ALL_JOB_ORDER_TEST_SAMPLES_QUERY,
            variables: {
              where: {
                JobOrderID: { equals: parseInt(JobOrderID, 10) },
              },
            },
            fetchPolicy: "network-only",
          },
        ],
      });
      if (cancelResultData?.cancelJob) {
        toast.success("Job cancelled successfully!");
        setModalOpen(false);
      } else {
        toast.error("Error when trying to cancel Job");
      }
    };
    setModalOpen(
      true,
      <ReasonModal
        message="Are you sure you would like to cancel this Job and all Job Orders?"
        onCancel={() => setModalOpen(false, "")}
        onConfirm={(reason) => performCancelJob(reason)}
      />
    );
  };

  const handleCancelJobOrder = (e) => {
    e.preventDefault();
    const performCancelJob = async (reason) => {
      const { data: cancelResultData } = await cancelJob({
        variables: {
          data: {
            JobOrderID: parseInt(JobOrderID, 10),
            JobID: parseInt(JobID, 10),
            Change: reason,
            CreatedBy: authState.user.Username,
          },
          where: {
            JobOrderID: { equals: parseInt(JobOrderID, 10) },
          },
        },
        refetchQueries: [
          {
            query: SINGLE_JOB_QUERY,
            variables: {
              where: { JobID: parseInt(JobID, 10) },
            },
            fetchPolicy: "network-only",
          },
          {
            query: ALL_HOMOGENEITY_JOB_ORDER_TEST_RESULTS_QUERY,
            variables: {
              where: {
                JobOrderID: { equals: parseInt(JobOrderID, 10) },
              },
            },
          },
          {
            query: ALL_JOB_ORDER_TEST_SAMPLES_QUERY,
            variables: {
              where: {
                JobOrderID: { equals: parseInt(JobOrderID, 10) },
              },
            },
            fetchPolicy: "network-only",
          },
        ],
      });
      if (cancelResultData?.cancelJob) {
        toast.success(`Order ${JobOrderID} was cancelled successfully!`);
        setModalOpen(false);
      } else {
        toast.error("Error when trying to cancel Order");
      }
    };
    setModalOpen(
      true,
      <ReasonModal
        message={`Are you sure you would like to cancel Job Order ${JobOrderID}?`}
        onCancel={() => setModalOpen(false, "")}
        onConfirm={(reason) => performCancelJob(reason)}
      />
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="job-order-list-page">
      <PageHeader
        alert={
          selectedOrderIncludesFailedResult ? "Includes Failed Result" : ""
        }
        alertColor="danger"
        style={{
          paddingLeft: 0,
          position: "fixed",
          top: "3rem",
          width: "85%",
          zIndex: 998,
          paddingTop: 16,
          paddingRight: "1rem",
          background: "#f7fafd",
        }}
        title={`${JobID} - ${JobOrderID}`}
      >
        {!isOrderCancelled && (
          <Tag color={allTestApproved ? "cyan" : "warning"}>
            {allTestApproved
              ? "All Tests Approved"
              : "Waiting for Test Approval"}
          </Tag>
        )}
        {canUpdate && (
          <React.Fragment>
            {canAddQualifiers && (
              <Button
                color="primary"
                disabled={editMode}
                size="small"
                type="submit"
                onClick={handleAddQualifiersModal}
              >
                Add Qualifiers
              </Button>
            )}
            {canCancelJob && (
              <Dropdown>
                <Dropdown.Trigger>
                  <Button color="danger" size="small">
                    <span>Cancel</span>
                    <Icon size="small">
                      <FontAwesomeIcon icon="angle-down" />
                    </Icon>
                  </Button>
                </Dropdown.Trigger>
                <Dropdown.Menu>
                  <Dropdown.Content>
                    <Dropdown.Item onClick={handleCancelJobOrder}>
                      Cancel Order {JobOrderID}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleCancelJob}>
                      Cancel Job and all Orders
                    </Dropdown.Item>
                  </Dropdown.Content>
                </Dropdown.Menu>
              </Dropdown>
            )}
            <Button
              color="primary"
              disabled={editMode || !canUpdate}
              form="edit-job-order-form"
              size="small"
              type="submit"
              onClick={(e) => handleSave(e)}
            >
              Save
            </Button>
          </React.Fragment>
        )}
      </PageHeader>
      <div>
        <TestStatusForm
          JobData={resultJobData?.data?.findUniqueJobs}
          JobID={JobID}
          JobOrderID={JobOrderID}
          allTestApproved={allTestApproved}
          canExportCsv={canExportCsv}
          disabled={editMode || !canUpdate}
          formId="edit-job-order-form"
          handleOrderChange={handleOrderChange}
          history={history}
          inputs={inputs}
          updatePerUnitCalculation={handleUpdatePerUnitCalculation}
          uploadJobOrderPath={uploadJobOrderPath}
          onAddTestToJobOrder={handleAddTest}
          onChange={handleChange}
          onOpenResultModal={handleOpenResultModal}
        />
      </div>
      <br />
      <div className="page-head">
        <div className="page-head-start">
          <Title>Job Logs</Title>
        </div>
      </div>
      <br />
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateJobLogs"
        columns={COLUMNS}
        fetchPolicy="network-only"
        name="findManyJobLogs"
        orderBy={orderBy}
        query={ALL_JOB_LOGS_QUERY}
        where={where}
      />
    </div>
  );
};

TestStatusEditPage.propTypes = {
  routePermissions: PropTypes.array,
};

TestStatusEditPage.defaultProps = {
  routePermissions: [],
};

export default TestStatusEditPage;
