import { gql } from "@apollo/client";

export const ALL_COUPONS_QUERY = gql`
  query ALL_COUPONS_QUERY(
    $where: CouponsWhereInput
    $orderBy: [CouponsOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyCoupons(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      CouponID
      Code
      Percent
      Discount
      Used
      Type
      DiscountType {
        CodeId
      }
      Uses
      CreateDate
      StartDate
      ExpireDate
      UserID
      User {
        UserID
        Username
      }
      Lab {
        LabID
        Company
      }
    }
    aggregateCoupons(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const SINGLE_COUPON_QUERY = gql`
  query SINGLE_COUPON_QUERY($where: CouponsWhereUniqueInput!) {
    findUniqueCoupons(where: $where) {
      CouponID
      Code
      Percent
      Discount
      Used
      Type
      Uses
      LabID
      CreateDate
      ExpireDate
      StartDate
    }
  }
`;

export const CHECK_COUPON_VALIDITY_QUERY = gql`
  query CHECK_COUPON_VALIDITY_QUERY($where: CouponsWhereInput!) {
    checkCouponValidity(where: $where) {
      CouponID
      Code
      Percent
      Discount
      Used
      Type
      Uses
      CreateDate
      StartDate
      ExpireDate
    }
  }
`;
