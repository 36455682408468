import { gql } from "@apollo/client";

export const DELETE_ACTION_LIMIT_TEMPLATE_MUTATION = gql`
  mutation DELETE_ACTION_LIMIT_TEMPLATE_MUTATION(
    $where: ActionLimitTemplatesWhereUniqueInput!
  ) {
    deleteActionLimitTemplates(where: $where) {
      TemplateID
    }
  }
`;

export const CREATE_ACTION_LIMIT_TEMPLATE_MUTATION = gql`
  mutation CREATE_ACTION_LIMIT_TEMPLATE_MUTATION(
    $data: ActionLimitTemplatesCreateInput!
  ) {
    createActionLimitTemplates(data: $data) {
      TemplateID
    }
  }
`;

export const UPDATE_ACTION_LIMIT_TEMPLATE_MUTATION = gql`
  mutation UPDATE_ACTION_LIMIT_TEMPLATE_MUTATION(
    $data: ActionLimitTemplatesUpdateInput!
    $where: ActionLimitTemplatesWhereUniqueInput!
  ) {
    updateActionLimitTemplates(data: $data, where: $where) {
      TemplateID
    }
  }
`;

export const DELETE_ACTION_LIMIT_TEMPLATE_ON_CASCADE_MUTATION = gql`
  mutation DELETE_ACTION_LIMIT_TEMPLATE_ON_CASCADE_MUTATION(
    $where: ActionLimitTemplatesWhereUniqueInput!
  ) {
    deleteActionLimitTemplateOnCascade(where: $where) {
      TemplateID
    }
  }
`;
