import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

import EmployeeListPage from "./EmployeeListPage";
import EmployeeEditPage from "./EmployeeEditPage";

import "./Employee.scss";

const EmployeeRouter = ({ routePermissions }) => (
  <Switch>
    <Route path="/employees/:UserID">
      <EmployeeEditPage routePermissions={routePermissions} />
    </Route>
    <Route path="/employees">
      <EmployeeListPage routePermissions={routePermissions} />
    </Route>
  </Switch>
);

EmployeeRouter.propTypes = {
  routePermissions: PropTypes.array,
};

EmployeeRouter.defaultProps = {
  routePermissions: [],
};

export default EmployeeRouter;
