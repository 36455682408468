import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { ALL_QC_TARGETS_QUERY } from "../../../../../graphql";
import { COLUMNS } from "./columns";
import { PageHeader, DataTable, IconButton } from "../../../../../components";
import { useModal } from "../../../../../context/ModalContext";
import QCTargetModal from "../components/QCTargetModal";
import { useAuth } from "../../../../../context";

const QCTargetsListPage = ({ routePermissions }) => {
  const { QCTargetID } = useParams();
  const { state: authState } = useAuth();
  const history = useHistory();
  const { setModalOpen } = useModal();
  const [modalOpened, setModalOpened] = useState();
  const [canUpdate, setCanUpdate] = useState(true);
  const [where, setWhere] = useState({});
  const orderBy = [{ id: "QCSampleTypeID", desc: false }];

  useEffect(() => {
    if (authState.user.Lab?.Company !== "All Location") {
      setWhere((prev) => ({
        ...prev,
        States: {
          contains: authState.user.LabState,
        },
      }));
    }
  }, [authState]);

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  useEffect(() => {
    if (QCTargetID && !modalOpened) {
      setModalOpened(true);
      setModalOpen(
        true,
        <QCTargetModal
          QCTargetID={QCTargetID}
          disabled={!canUpdate}
          onComplete={() => {
            history.push("/lims/qc-targets");
            setModalOpen(false);
          }}
        />
      );
    }
  }, [QCTargetID, history, modalOpened, setModalOpen, canUpdate]);

  const handleCreateQCTarget = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push("/lims/qc-targets");
    setModalOpen(
      true,
      <QCTargetModal
        disabled={!canUpdate}
        onComplete={() => {
          history.push("/lims/qc-targets");
          setModalOpen(false);
        }}
      />
    );
  };

  const handleRowClick = (qcTargetItem) => {
    setModalOpened(false);
    history.push(`/lims/qc-targets/${qcTargetItem.QCTargetID}`);
  };

  return (
    <div className="qc-target-list-page">
      <PageHeader title="QC Target Values">
        {canUpdate && <IconButton icon="plus" onClick={handleCreateQCTarget} />}
      </PageHeader>
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateQCTargets"
        columns={COLUMNS}
        name="findManyQCTargets"
        orderBy={orderBy}
        query={ALL_QC_TARGETS_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

QCTargetsListPage.propTypes = {
  routePermissions: PropTypes.array,
};

QCTargetsListPage.defaultProps = {
  routePermissions: [],
};

export default QCTargetsListPage;
