import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Icon, Generic } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../../context/AuthContext";
import {
  ALL_PACKAGES_QUERY,
  CLONE_PACKAGE_FROM_TEMPLATE_MUTATION,
} from "../../../../graphql";
import { customToast as toast } from "../../../../utils";
import "./PackageTemplateModal.scss";

const PackageTemplateModal = ({ UserID, onComplete, packageName }) => {
  const { state: authState } = useAuth();
  const [userTestPackages, setUserTestPackages] = useState([]);
  const [getPackages, packagesData] = useLazyQuery(ALL_PACKAGES_QUERY);
  const [clonePackage] = useMutation(CLONE_PACKAGE_FROM_TEMPLATE_MUTATION);

  useEffect(() => {
    getPackages({
      variables: {
        where: {
          Type: { is: { CodeDescription: { equals: "Template" } } },
        },
        orderBy: [{ CreatedDateTime: "asc" }],
      },
      fetchPolicy: "network-only",
    });
  }, [getPackages]);

  useEffect(() => {
    if (Array.isArray(packagesData?.data?.findManyPackages)) {
      const packages = packagesData.data.findManyPackages;
      setUserTestPackages(packages);
    }
  }, [packagesData]);

  const handleClonePackage = async (packageToClone) => {
    try {
      await clonePackage({
        variables: {
          data: {
            UserID: parseInt(UserID, 10),
            Name: packageName,
            PackageID: parseInt(packageToClone.PackageID, 10),
            CreatedBy: authState.user.Username,
            ModifiedBy: authState.user.Username,
            Price: packageToClone.Price,
            DisplayAsTest: packageToClone.DisplayAsTest,
            MedicalType: parseInt(packageToClone.MedicalType, 10),
            Type: {
              connect: { RecId: 106 },
            },
          },
        },
        refetchQueries: [
          {
            query: ALL_PACKAGES_QUERY,
            variables: {
              where: {
                UserID: { equals: parseInt(UserID, 10) },
                Type: { is: { CodeDescription: { equals: "User" } } },
              },
              orderBy: [{ CreatedDateTime: "desc" }],
            },
          },
        ],
      });
      toast.success("Package created successfully");
      onComplete();
    } catch (err) {
      toast.error("Error cloning package");
    }
  };

  if (packagesData?.loading) return <h1>Loading...</h1>;

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {userTestPackages.map((packageItem) => (
        <div key={packageItem.PackageID} className="package-container">
          <div className="header">
            <h4>{packageItem.Name}</h4>
            <div style={{ marginTop: 10, width: "10%", textAlign: "center" }}>
              <Generic
                className="delete-package-button"
                onClick={() => handleClonePackage(packageItem)}
              >
                <Icon color="primary">
                  <FontAwesomeIcon icon="clone" />
                </Icon>
              </Generic>
            </div>
          </div>
          <div className="wrapper">
            {packageItem?.PackageTests?.map((ut) => (
              <div key={ut.PackageTestID} className="item-container">
                <div className="info-item">
                  <h1>{ut.Test.Name}</h1>
                  <span />
                  <p className="money">${parseFloat(ut.Price, 10)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
PackageTemplateModal.propTypes = {
  UserID: PropTypes.string.isRequired,
  onComplete: PropTypes.func,
  packageName: PropTypes.string.isRequired,
};
PackageTemplateModal.defaultProps = {
  onComplete: () => null,
};

export default PackageTemplateModal;
