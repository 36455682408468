/* eslint-disable no-param-reassign */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import PropTypes from "prop-types";

import { useAuth } from "../../../../context/AuthContext";
import { useModal } from "../../../../context/ModalContext";
import {
  LIST_TESTS_QUERY,
  ALL_PACKAGES_QUERY,
  CREATE_PACKAGE_MUTATION,
  DELETE_PACKAGE_WITH_TESTS_MUTATION,
  UPDATE_PACKAGE_MUTATION,
  CREATE_PACKAGE_TEST_MUTATION,
  UPDATE_PACKAGE_TEST_MUTATION,
  DELETE_PACKAGE_TEST_MUTATION,
} from "../../../../graphql";
import PackagesBoard from "../../../Client/components/PackagesBoard";
import Loader from "../../../../components/Loader";
import { customToast as toast } from "../../../../utils";
import Confirmation from "../../../../components/Confirmation";
import { IconButton, PageHeader } from "../../../../components";

const PackageTemplatePage = ({ routePermissions }) => {
  const { state: authState } = useAuth();
  const { setModalOpen } = useModal();

  const [userTestPackages, setUserTestPackages] = useState({
    columns: {},
    ordered: [],
    packages: [],
    edited: [],
    packagePrices: {},
    medicalTypes: {},
    names: {},
  });
  const [loading, setLoading] = useState(false);
  const [canUpdate, setCanUpdate] = useState(true);
  const [where, setWhere] = useState(null);

  const [getTests, testsData] = useLazyQuery(LIST_TESTS_QUERY, {
    fetchPolicy: "network-only",
  });
  const [getPackages, packagesData] = useLazyQuery(ALL_PACKAGES_QUERY, {
    fetchPolicy: "network-only",
  });
  const [createPackage] = useMutation(CREATE_PACKAGE_MUTATION);
  const [updatePackage] = useMutation(UPDATE_PACKAGE_MUTATION);
  const [deletePackage] = useMutation(DELETE_PACKAGE_WITH_TESTS_MUTATION);
  const [createPackageTest] = useMutation(CREATE_PACKAGE_TEST_MUTATION);
  const [updatePackageTest] = useMutation(UPDATE_PACKAGE_TEST_MUTATION);
  const [deletePackageTest] = useMutation(DELETE_PACKAGE_TEST_MUTATION);

  useEffect(() => {
    if (authState?.user?.Lab?.Company === "All Location") {
      setWhere({
        Type: { is: { CodeDescription: { equals: "Template" } } },
      });
    } else if (authState?.user) {
      setWhere({
        Type: { is: { CodeDescription: { equals: "Template" } } },
        State: { equals: authState.user.Lab.State },
      });
    }
  }, [authState]);

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  useEffect(() => {
    if (where) {
      getTests({
        variables: {
          where: { Active: { equals: true } },
          orderBy: [{ Name: "asc" }],
        },
      });
      getPackages({
        variables: {
          where,
          orderBy: [{ CreatedDateTime: "desc" }],
        },
      });
    }
  }, [getTests, getPackages, where]);

  useEffect(() => {
    if (
      Array.isArray(testsData?.data?.findManyTests) &&
      Array.isArray(packagesData?.data?.findManyPackages)
    ) {
      const packages = packagesData.data.findManyPackages;
      const previousPackages = packages.reduce(
        (previous, current) => ({
          ...previous,
          [current.PackageID]: current.PackageTests.map((pt) => ({
            id: `${current.Name}-${pt.TestID}`,
            content: pt,
          })),
        }),
        {}
      );

      const previousPrices = packages.reduce(
        (previous, current) => ({
          ...previous,
          [current.PackageID]: current.Price || 0,
        }),
        {}
      );
      const previousMedicalTypes = packages.reduce(
        (previous, current) => ({
          ...previous,
          [current.PackageID]: current.MedicalType || null,
        }),
        {}
      );
      const previousDisplayAsTests = packages.reduce(
        (previous, current) => ({
          ...previous,
          [current.PackageID]: current.DisplayAsTest || false,
        }),
        {}
      );
      const previousNames = packages.reduce(
        (previous, current) => ({
          ...previous,
          [current.PackageID]: current.Name,
        }),
        {}
      );
      const previousStates = packages.reduce(
        (previous, current) => ({
          ...previous,
          [current.PackageID]: current.State || null,
        }),
        {}
      );

      setUserTestPackages((prev) => ({
        ...prev,
        columns: {
          ...previousPackages,
          UserTest: testsData?.data?.findManyTests.map((test) => ({
            id: `main-${test.TestID}`,
            content: {
              Price: test.CategoryPrice,
              Test: test,
            },
          })),
        },
        ordered: ["UserTest", ...Object.keys(previousPackages)],
        packages: packages.map(({ PackageID, Name }) => ({
          PackageID,
          Name,
        })),
        packagePrices: previousPrices,
        medicalTypes: previousMedicalTypes,
        displayAsTests: previousDisplayAsTests,
        names: { ...previousNames, UserTest: "UserTest" },
        packageStates: previousStates,
      }));
    }
  }, [packagesData, testsData]);

  const handleAddPackage = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const countOfColumns = Object.keys(userTestPackages.columns).length;
      const packageName = `Template-${countOfColumns}`;
      await createPackage({
        variables: {
          data: {
            UserID: parseInt(authState.user.UserID, 10),
            Name: packageName,
            CreatedBy: authState.user.Username,
            ModifiedBy: authState.user.Username,
            Price: parseFloat(userTestPackages.packagePrices[packageName], 10),
            MedicalType: userTestPackages.medicalTypes[packageName],
            Type: {
              connect: { RecId: 105 },
            },
            State:
              authState.user.Lab.Company !== "All Location"
                ? authState.user.Lab.State
                : null,
          },
        },
        refetchQueries: [
          {
            query: ALL_PACKAGES_QUERY,
            variables: {
              where,
              orderBy: [{ CreatedDateTime: "desc" }],
            },
          },
        ],
      });
    } catch (err) {
      toast.error("Error creating Package");
    } finally {
      setLoading(false);
    }
  };

  const handlePriceChange = async (PackageID, value) => {
    try {
      await updatePackage({
        variables: {
          data: {
            Price: { set: parseFloat(value, 10) },
            ModifiedBy: { set: authState.user.Username },
          },
          where: {
            PackageID: parseInt(PackageID, 10),
          },
        },
        refetchQueries: [
          {
            query: ALL_PACKAGES_QUERY,
            variables: {
              where,
              orderBy: [{ CreatedDateTime: "asc" }],
            },
          },
        ],
      });
      toast.success("Package updated successfully");
    } catch (err) {
      toast.error("Error updating Package");
    }
  };

  const handleDisplayAsTestChange = async (PackageID, value) => {
    try {
      await updatePackage({
        variables: {
          data: {
            DisplayAsTest: { set: value },
            ModifiedBy: { set: authState.user.Username },
          },
          where: {
            PackageID: parseInt(PackageID, 10),
          },
        },
        refetchQueries: [
          {
            query: ALL_PACKAGES_QUERY,
            variables: {
              where,
              orderBy: [{ CreatedDateTime: "asc" }],
              fetchPolicy: "network-only",
            },
          },
        ],
      });

      toast.success("Package updated successfully");
    } catch (err) {
      toast.error("Error updating Package");
    }
  };

  const handleHeaderChange = async (oldName, newName) => {
    try {
      const { names } = userTestPackages;
      if (
        oldName !== newName &&
        Object.keys(names).find((key) => names[key] === newName)
      ) {
        if (document.getElementById(oldName)) {
          document.getElementById(oldName).value = oldName;
        }
        toast.error("That name already exists");
        return;
      }

      const packageToUpdate = userTestPackages.packages.find(
        ({ Name }) => Name === oldName
      );
      await updatePackage({
        variables: {
          data: {
            Name: { set: newName },
            ModifiedBy: { set: authState.user.Username },
          },
          where: {
            PackageID: parseInt(packageToUpdate.PackageID, 10),
          },
        },
        refetchQueries: [
          {
            query: ALL_PACKAGES_QUERY,
            variables: {
              where,
              orderBy: [{ CreatedDateTime: "asc" }],
            },
          },
        ],
      });
      toast.success("Package updated successfully");
    } catch (err) {
      toast.error("Error updating Package");
    }
  };

  const handleMedicalChange = async (PackageID, medicalValue) => {
    try {
      await updatePackage({
        variables: {
          data: {
            MedicalType: { set: medicalValue },
            ModifiedBy: { set: authState.user.Username },
          },
          where: {
            PackageID: parseInt(PackageID, 10),
          },
        },
        refetchQueries: [
          {
            query: ALL_PACKAGES_QUERY,
            variables: {
              where,
              orderBy: [{ CreatedDateTime: "asc" }],
            },
          },
        ],
      });
      toast.success("Package updated successfully");
    } catch (err) {
      toast.error("Error updating Package");
    }
  };

  const handlePackageStateChange = async (PackageID, stateValue) => {
    try {
      await updatePackage({
        variables: {
          data: {
            State: { set: stateValue },
            ModifiedBy: { set: authState.user.Username },
          },
          where: {
            PackageID: parseInt(PackageID, 10),
          },
        },
        refetchQueries: [
          {
            query: ALL_PACKAGES_QUERY,
            variables: {
              where,
              orderBy: [{ CreatedDateTime: "asc" }],
            },
          },
        ],
      });
      toast.success("Package updated successfully");
    } catch (err) {
      toast.error("Error updating Package");
    }
  };

  const removePackage = (PackageID) => {
    const performDelete = async () => {
      try {
        await deletePackage({
          variables: {
            where: {
              PackageID: parseInt(PackageID, 10),
            },
          },
          refetchQueries: [
            {
              query: ALL_PACKAGES_QUERY,
              variables: {
                where,
                orderBy: [{ CreatedDateTime: "asc" }],
              },
              fetchPolicy: "network-only",
            },
          ],
        });
        setModalOpen(false);
        toast.success("Package deleted successfully!");
      } catch (e) {
        toast.error("Error deleting Package Test");
      }
    };
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to remove this package and its contents?"
        onCancel={() => setModalOpen(false, "")}
        onConfirm={performDelete}
      />
    );
  };

  const removePackageTest = (source, itemToDelete) => {
    const performDelete = async () => {
      try {
        await deletePackageTest({
          variables: {
            where: {
              PackageTestID: parseInt(itemToDelete.content.PackageTestID, 10),
            },
          },
          refetchQueries: [
            {
              query: ALL_PACKAGES_QUERY,
              variables: {
                where,
                orderBy: [{ CreatedDateTime: "desc" }],
              },
            },
          ],
        });
        setModalOpen(false);
        toast.success("Package Test deleted successfully!");
      } catch (e) {
        toast.error("Error deleting Package Test");
      }
    };
    if (itemToDelete.content?.PackageTestID) {
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to remove this item?"
          onCancel={() => setModalOpen(false, "")}
          onConfirm={performDelete}
        />
      );
    }
  };

  const handleCreatePackageTest = async (packageToInsert, PackageID, Order) => {
    try {
      if (packageToInsert) {
        await createPackageTest({
          variables: {
            data: {
              Price: parseFloat(packageToInsert.content.Price, 10),
              CreatedBy: authState.user.Username,
              ModifiedBy: authState.user.Username,
              Test: {
                connect: {
                  TestID: parseInt(packageToInsert.content.Test.TestID, 10),
                },
              },
              PackageID: parseInt(PackageID, 10),
              Order,
            },
          },
          refetchQueries: [
            {
              query: ALL_PACKAGES_QUERY,
              variables: {
                where,
                orderBy: [{ CreatedDateTime: "asc" }],
              },
            },
          ],
        });
      }
    } catch (err) {
      toast.error("Error creating Package Test");
    }
  };

  const handleReorderPackageTests = async (source, destination) => {
    const packages = userTestPackages.columns[destination.droppableId];
    packages.move(source.index, destination.index);
    await Promise.all(
      packages.map(async (pkg, index) => {
        await updatePackageTest({
          variables: {
            where: {
              PackageTestID: parseInt(pkg.content.PackageTestID, 10),
            },
            data: {
              Order: { set: index },
            },
          },
          refetchQueries: [
            {
              query: ALL_PACKAGES_QUERY,
              variables: {
                where,
                orderBy: [{ CreatedDateTime: "asc" }],
              },
            },
          ],
        });
      })
    );
  };

  if (packagesData?.loading || testsData?.loading || loading) return <Loader />;

  return (
    <form style={{ width: "100%", paddingLeft: 10 }}>
      <PageHeader title="Template Packages">
        {canUpdate && <IconButton icon="plus" onClick={handleAddPackage} />}
      </PageHeader>
      {testsData?.data?.findManyTests?.length > 0 ? (
        <PackagesBoard
          isCombineEnabled
          withScrollableColumns
          createPackageTest={handleCreatePackageTest}
          disabled={!canUpdate}
          handleHeaderChange={handleHeaderChange}
          handleMedicalChange={handleMedicalChange}
          removePackage={removePackage}
          removePackageTest={removePackageTest}
          reorderPackageTests={handleReorderPackageTests}
          setState={setUserTestPackages}
          state={userTestPackages}
          onChange={handlePriceChange}
          onDisplayAsTestChange={handleDisplayAsTestChange}
          onPackageStateChange={handlePackageStateChange}
        />
      ) : (
        <h1>No data found</h1>
      )}
    </form>
  );
};

PackageTemplatePage.propTypes = {
  routePermissions: PropTypes.array,
};

PackageTemplatePage.defaultProps = {
  routePermissions: [],
};

PackageTemplatePage.defaultProps = {};

export default PackageTemplatePage;
