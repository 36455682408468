/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from "react";

import { Button, Input, Label, Control, Field, Column } from "rbx";
import { useParams, useHistory } from "react-router-dom";
import {
  useMutation,
  useQuery,
  useLazyQuery,
  useApolloClient,
} from "@apollo/client";

import NewTestForm from "./components/NewTestForm";
import { Loader, PageHeader } from "../../components";
import { customToast as toast, HomoTestsNeeded } from "../../utils";
import { useAuth } from "../../context";
import {
  ALL_CART_QUERY,
  UPDATE_CART_MUTATION,
  CREATE_CART_MUTATION,
  SINGLE_CART_QUERY,
  CART_COUNT_QUERY,
  ALL_SYSTEM_CODES_QUERY,
  LIST_USER_TESTS_QUERY,
  ALL_PACKAGES_QUERY,
  SINGLE_LAB_QUERY,
  ALL_TESTS_QUERY,
  GET_CART_SUMMARY_QUERY,
  ALL_JOB_ORDERS_QUERY,
} from "../../graphql";

import "./NewTestPage.scss";
import {
  productCategoryMatrix,
  homogeneityProductCategoryCodeDescriptions,
} from "../../constants";

const INITIAL_STATE = {
  OrderName: "",
  BatchOrderNumber: "",
  SubstanceType: "",
  ProductCategory: "",
  Tests: [""],
  ClientNotes: "",
  TestType: "",
  CustomerSampleID: "",
  LotID: "",
  StateRetest: false,
  OriginalCOA: "",
  Species: null,
  LabelClaim: {
    LabelClaimTHC: "",
    LabelClaimUnitTHC: "",
    LabelClaimCBD: "",
    LabelClaimUnitCBD: "",
  },
  MMTCInfo: {
    CultivationFacility: "",
    ProcessingFacility: "",
    CultivatorsNum: 1,
    Cultivators: [],
  },
  BatchInfo: {
    ID: "",
    Date: "",
    ExpDate: "",
    Size: "",
    Qty: "",
    BatchSizeUnit: "g",
  },
};

const NewTestPage = () => {
  const { tab, CartID } = useParams();
  const { state: authState } = useAuth();
  const history = useHistory();
  const client = useApolloClient();

  const [loading, setLoading] = useState(false);
  const [testsMerged, setTestsMerged] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [totals, setTotals] = useState({ price: 0 });
  const [tests, setTests] = useState([]);
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [substanceTypes, setSubstanceTypes] = useState([]);
  const [homogeneityProductCategories, setHomogeneityProductCategories] =
    useState([]);
  const [mjSubstanceType, setMjSubstanceType] = useState({});
  const [fixedSubstanceType, setFixedSubstanceType] = useState(false);
  const [complianceTestType, setComplianceTestType] = useState({});

  const [createCart] = useMutation(CREATE_CART_MUTATION);
  const [updateCart] = useMutation(UPDATE_CART_MUTATION);

  const UserID = authState.user.UserID || authState.user.OwnerUserID;

  const { data: userTestsData } = useQuery(LIST_USER_TESTS_QUERY, {
    variables: {
      where: {
        UserID: {
          equals: parseInt(UserID, 10),
        },
        Active: { equals: 1 },
      },
      orderBy: [{ ShowOrder: "asc" }],
    },
  });

  const { data: systemCodesData } = useQuery(ALL_SYSTEM_CODES_QUERY, {
    variables: {
      where: {},
    },
  });

  const { data: userLabData } = useQuery(SINGLE_LAB_QUERY, {
    variables: {
      where: {
        LabID: parseInt(authState.user.LabID, 10),
      },
    },
  });

  const { data: homoTestData } = useQuery(ALL_TESTS_QUERY, {
    variables: {
      where: {
        Code: {
          equals: "HOMO",
        },
      },
    },
  });

  const { data: moistureTestData } = useQuery(ALL_TESTS_QUERY, {
    variables: {
      where: {
        Code: {
          equals: "MOIST",
        },
      },
    },
  });

  const { data: getSystemCodeData } = useQuery(ALL_SYSTEM_CODES_QUERY, {
    variables: {
      where: {
        Type: { equals: "Product" },
        Category: { equals: "Category" },
        CodeName: { equals: "ProductCategory" },
      },
      orderBy: { RecId: "asc" },
    },
  });

  const [getFirstsCart, { data: firstsCartData }] = useLazyQuery(
    GET_CART_SUMMARY_QUERY
  );

  const [getCartData, resultCartData] = useLazyQuery(SINGLE_CART_QUERY);

  const isHomoTestCriteriaApplicable = () =>
    homogeneityProductCategories.includes(
      parseInt(inputs.ProductCategory, 10)
    ) &&
    parseInt(inputs.SubstanceType, 10) ===
      parseInt(mjSubstanceType.RecId, 10) &&
    parseInt(inputs.TestType, 10) === parseInt(complianceTestType.RecId, 10) &&
    inputs.BatchInfo.Qty !== "" &&
    userLabData?.findUniqueLabs?.State === "FL";

  useEffect(() => {
    if (systemCodesData?.findManySystemCodes) {
      setSubstanceTypes(() =>
        systemCodesData.findManySystemCodes.filter(
          (systemCode) =>
            systemCode.Type === "Substance" &&
            systemCode.Category === "Type" &&
            systemCode.CodeName === "SubstanceType"
        )
      );

      setComplianceTestType(() =>
        systemCodesData.findManySystemCodes.find(
          (systemCode) =>
            systemCode.Type === "Test" &&
            systemCode.Category === "Type" &&
            systemCode.CodeName === "TestType" &&
            systemCode.CodeId === "Compliance"
        )
      );

      setHomogeneityProductCategories(() =>
        systemCodesData.findManySystemCodes
          .filter(
            (systemCode) =>
              systemCode.Type === "Product" &&
              systemCode.Category === "Category" &&
              systemCode.CodeName === "ProductCategory" &&
              homogeneityProductCategoryCodeDescriptions.includes(
                systemCode.CodeDescription
              )
          )
          .map((systemCode) => parseInt(systemCode.RecId, 10))
      );
    }
  }, [systemCodesData]);

  useEffect(() => {
    if (substanceTypes.length) {
      setMjSubstanceType(() =>
        substanceTypes.find((substanceType) => substanceType.CodeId === "MJ")
      );
    }
  }, [substanceTypes]);

  useEffect(() => {
    const fetchTests = async () => {
      const result = userTestsData?.findManyUserTests;
      const { data: packagesAsTestsData } = await client.query({
        query: ALL_PACKAGES_QUERY,
        variables: {
          where: {
            UserID: { equals: parseInt(UserID, 10) },
            Type: { is: { CodeDescription: { equals: "User" } } },
            DisplayAsTest: { equals: true },
          },
        },
      });

      if (Array.isArray(result)) {
        let packagesAsTestsMapped = [];
        const testsMapped = result.map(({ Test, UserPrice }) => ({
          ...Test,
          UserPrice,
          showDescription: false,
          show: true,
        }));

        if (Array.isArray(packagesAsTestsData.findManyPackages)) {
          packagesAsTestsMapped = packagesAsTestsData.findManyPackages.map(
            (packageAsTest) => {
              const Description = packageAsTest.PackageTests.reduce(
                (acc, curr, index) => {
                  index < packageAsTest.PackageTests.length - 1
                    ? (acc += `${curr.Test.Name}, `)
                    : (acc += `${curr.Test.Name}. `);
                  return acc;
                },
                ""
              );

              return {
                UserPrice: packageAsTest.Price,
                Description,
                Image: null,
                TestID: packageAsTest.PackageID,
                Name: packageAsTest.Name,
                showDescription: false,
                isPackage: true,
                PackageTests: packageAsTest.PackageTests,
                show: true,
                MedicalType: packageAsTest.MedicalType,
              };
            }
          );
        }

        setTests([...testsMapped, ...packagesAsTestsMapped]);
      }
    };
    fetchTests();
  }, [userTestsData]);

  useEffect(() => {
    if (CartID) {
      setLoading(true);
      getCartData({
        variables: {
          where: { CartID: parseInt(CartID, 10) },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [CartID, getCartData]);

  useEffect(() => {
    const cart = resultCartData?.data?.cart;
    if (cart) {
      setInputs((prev) => ({
        ...prev,
        ...cart,
      }));
      setLoading(false);
    }
    if (cart?.Package && !cart?.Package?.DisplayAsTest) {
      setSelectedPackage(cart.Package);
    }
  }, [resultCartData?.data?.cart]);

  useEffect(() => {
    const cart = resultCartData?.data?.cart;
    if (cart && tests && !testsMerged) {
      setTestsMerged(true);
      setTests((prev) =>
        prev.map((test) => {
          if (
            cart.Tests.find(
              (TestID) => TestID.toString() === test.TestID.toString()
            ) ||
            (cart?.Package?.DisplayAsTest &&
              cart?.Package?.PackageID === test.TestID.toString())
          ) {
            return { added: true, ...test, showDescription: false };
          }
          return { added: false, ...test, showDescription: false };
        })
      );
      setLoading(false);
    }
  }, [resultCartData?.data?.cart, tests, testsMerged]);

  useEffect(() => {
    if (tab === "individual-test") {
      if (selectedPackage) {
        setTests((prev) => prev.map((test) => ({ ...test, added: false })));
      }
      setSelectedPackage(null);
    } else if (tab === "package-test") {
      setTests((prev) => prev.map((test) => ({ ...test, added: false })));
    }
  }, [tab]);

  useEffect(() => {
    const testAdded = tests.filter((test) => test.added);
    const price = selectedPackage
      ? (selectedPackage.Price || 0) +
        testAdded.map((test) => test.UserPrice).reduce((a, b) => a + b, 0)
      : testAdded.map((test) => test.UserPrice).reduce((a, b) => a + b, 0);

    setTotals({
      price,
    });
  }, [tests, selectedPackage]);

  useEffect(() => {
    if (!isHomoTestCriteriaApplicable()) {
      setTests((prev) =>
        prev.filter((test) => test.Code !== "HOMO" || test.isPackage)
      );
    } else if (
      tests.some(
        (test) => !!test.added && !test.isPackage && test.Code.includes("CANN")
      ) ||
      tests.some(
        (test) =>
          !!test.added &&
          test.isPackage &&
          test?.PackageTests.some((packageTest) =>
            packageTest.Test.Code.includes("CANN")
          )
      ) ||
      selectedPackage?.PackageTests.some((packageTest) =>
        packageTest.Test.Code.includes("CANN")
      )
    ) {
      const homoTestsNeeded = HomoTestsNeeded(
        parseInt(inputs.BatchInfo.Qty, 10)
      );

      if (
        homoTestData?.findManyTests &&
        tests.filter((test) => test.Code === "HOMO").length !== homoTestsNeeded
      ) {
        const homoTestsArr = [];
        const homoTest = homoTestData.findManyTests[0];
        const userHomoTest = userTestsData?.findManyUserTests?.find(
          (ut) => ut.Test?.Code === "HOMO"
        );

        const { TestID, Description, Name, Code, CategoryPrice } = homoTest;
        const { UserPrice } = userHomoTest;

        for (let i = 0; i < homoTestsNeeded; i++) {
          // eslint-disable-next-line prefer-destructuring
          homoTestsArr[i] = {
            TestID,
            UserPrice: UserPrice || CategoryPrice,
            Description,
            Name,
            added: true,
            show: false,
            Code,
          };
        }

        setTests((prev) => {
          const oldPrev = prev.filter(
            (test) => test.Code !== "HOMO" || test.isPackage
          );
          return [...oldPrev, ...homoTestsArr];
        });
      }
    }
  }, [
    inputs.BatchInfo.Qty,
    inputs.ProductCategory,
    inputs.SubstanceType,
    inputs.TestType,
  ]);

  useEffect(() => {
    if (authState?.user) {
      getFirstsCart({
        variables: {
          where: {
            UserID: { equals: parseInt(authState?.user.UserID, 10) },
          },
          take: 2,
          skip: 0,
        },
        fetchPolicy: "network-only",
      });
    }
  }, [authState]);

  useEffect(() => {
    if (
      (!CartID &&
        authState?.user?.LabState !== "FL" &&
        Array.isArray(firstsCartData?.carts) &&
        firstsCartData?.carts?.length) ||
      (CartID &&
        authState?.user?.LabState !== "FL" &&
        Array.isArray(firstsCartData?.carts) &&
        firstsCartData?.carts?.length > 1)
    ) {
      setFixedSubstanceType(true);
      setInputs((prev) => ({
        ...prev,
        SubstanceType: firstsCartData.carts[0].SubstanceType,
      }));
    }
  }, [authState, firstsCartData]);

  const createHomoTests = () => {
    const homoTestsNeeded = HomoTestsNeeded(parseInt(inputs.BatchInfo.Qty, 10));

    const homoTestsArr = [];
    const homoTest = homoTestData.findManyTests[0];
    const userHomoTest = userTestsData?.findManyUserTests?.find(
      (ut) => ut.Test?.Code === "HOMO"
    );

    const { TestID, Description, Name, Code, CategoryPrice } = homoTest;
    const { UserPrice } = userHomoTest;

    for (let i = 0; i < homoTestsNeeded; i++) {
      // eslint-disable-next-line prefer-destructuring
      homoTestsArr[i] = {
        TestID,
        UserPrice: UserPrice || CategoryPrice,
        Description,
        Name,
        added: true,
        show: false,
        Code,
      };
    }

    setTests((prev) => {
      const oldPrev = prev.filter(
        (test) => test.Code !== "HOMO" || test.isPackage
      );
      return [...oldPrev, ...homoTestsArr];
    });
  };

  const uploadCOAPath = () => {
    const date = new Date();
    return `/Images/JobOrderCOA/${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}`;
  };

  const handleChange = (name, value) => {
    const names = name.split(".");
    if (names.length === 1) {
      setInputs((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else if (name === "MMTCInfo.Cultivators") {
      setInputs((prev) => ({
        ...prev,
        [names[0]]: {
          ...prev[names[0]],
          [names[1]]: value,
          CultivatorsNum: value.length,
        },
      }));
    } else {
      setInputs((prev) => ({
        ...prev,
        [names[0]]: {
          ...prev[names[0]],
          [names[1]]: value,
        },
      }));
    }
  };

  const testRestrictions = (id) =>
    ({
      3: [19, 23],
      19: [3, 23],
      13: [4, 14, 16, 15, 17, 18, 22, 21],
      4: [19, 14, 16, 15, 17, 18, 22, 21],
      14: [13, 4, 22],
      16: [13, 4, 22],
      15: [13, 4, 22],
      17: [13, 4, 22],
      18: [13, 4, 22],
      21: [13, 4, 22],
      23: [3, 19],
      22: [21, 13, 4, 14, 16, 15, 17, 18],
    }[id]);

  const handleChangeTest = (name, value, TestID) => {
    const foundTest = tests.find((test) => TestID === test.TestID);

    // homo logic
    if (
      value &&
      ((foundTest?.Code?.includes("CANN") && !foundTest.isPackage) ||
        (foundTest?.isPackage &&
          foundTest.PackageTests.some((packageTest) =>
            packageTest.Test.Code.includes("CANN")
          ))) &&
      isHomoTestCriteriaApplicable()
    ) {
      createHomoTests();
    } else if (
      !value &&
      (foundTest?.Code?.includes("CANN") ||
        (foundTest?.isPackage &&
          foundTest.PackageTests.some((packageTest) =>
            packageTest.Test.Code.includes("CANN")
          )))
    ) {
      setTests((prev) =>
        prev.filter((test) => test.Code !== "HOMO" || test.isPackage)
      );
    }

    const restrictrions = testRestrictions(TestID);
    setTests((prev) =>
      prev.map((test) => {
        if (test.TestID === TestID) {
          return { ...test, [name]: value };
        }

        const found = tests.find(
          (currTest) => currTest.TestID === TestID && currTest.isPackage
        );

        if (found && value && test.isPackage && test.added) {
          return { ...test, added: false };
        }

        if (
          value &&
          restrictrions &&
          restrictrions.find((id) => id.toString() === test.TestID.toString())
        ) {
          return { ...test, added: false };
        }
        return test;
      })
    );
  };

  const testsOrPackageHaveCannOrCanna = () => {
    const packageTestIncludesCannOrCanna = selectedPackage?.PackageTests?.some(
      (packageTest) => packageTest.Test.Code.includes("CANN")
    );

    const testIncludesCannOrCanna = tests
      .filter((test) => test?.added)
      .some(
        (test) =>
          test.Code?.includes("CANN") ||
          (test.isPackage &&
            test?.PackageTests.some((packageTest) =>
              packageTest.Test.Code.includes("CANN")
            ))
      );

    return packageTestIncludesCannOrCanna || testIncludesCannOrCanna;
  };

  const testsDoNotHaveMoist = () => {
    const packageHaveMoist = selectedPackage?.packageAsTest?.some(
      (pkg) => pkg.code === "MOIST"
    );
    const selectedTestHaveMoist = tests
      ?.filter((test) => test?.added)
      ?.some(
        (test) =>
          test.Code === "MOIST" ||
          (test.isPackage &&
            test?.PackageTests.some(
              (packageTest) => packageTest.Test.Code === "MOIST"
            ))
      );
    return !packageHaveMoist && !selectedTestHaveMoist;
  };

  const validateMoist = () => {
    const flowerDescriptions = productCategoryMatrix.flower.map(
      (flowerDesc) => {
        const matchingSystemCode = getSystemCodeData?.findManySystemCodes?.find(
          (sc) => sc.CodeDescription === flowerDesc
        );
        return parseInt(matchingSystemCode?.RecId, 10);
      }
    );

    const inFlowersDescription = flowerDescriptions.includes(
      parseInt(inputs?.ProductCategory, 10)
    );

    return (
      inFlowersDescription &&
      testsOrPackageHaveCannOrCanna() &&
      testsDoNotHaveMoist()
    );
  };

  const createMoistureTest = () => {
    const moistureTest = moistureTestData.findManyTests[0];
    const { TestID, Description, Name, Code } = moistureTest;

    const moistureTestToAdd = {
      TestID,
      UserPrice: 0,
      Description,
      Name,
      added: true,
      show: false,
      Code,
    };
    return moistureTestToAdd;
  };

  const checkForDuplicateRetailBatchNumber = async () => {
    const { data: previousDuplicateOrdersData } = await client.query({
      query: ALL_JOB_ORDERS_QUERY,
      variables: {
        where: {
          UserID: { equals: parseInt(authState.user.UserID, 10) },
          SubstanceType: { equals: mjSubstanceType.RecId },
          TestType: { equals: complianceTestType.RecId },
          BatchOrderNumber: { equals: inputs.BatchOrderNumber.toString() },
        },
      },
      fetchPolicy: "network-only",
    });
    return !!previousDuplicateOrdersData?.findManyJobOrders?.length;
  };

  const handleSave = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      if (
        !inputs.StateRetest &&
        authState?.user?.LabState === "FL" &&
        parseInt(inputs.SubstanceType, 10) ===
          parseInt(mjSubstanceType.RecId, 10) &&
        parseInt(inputs.TestType, 10) ===
          parseInt(complianceTestType.RecId, 10) &&
        !authState?.user?.AllowDupeBatchOrderNumber
      ) {
        // check for duplicate Retail Batch Number
        const batchOrderNumberIsDuplicate =
          await checkForDuplicateRetailBatchNumber();
        if (batchOrderNumberIsDuplicate) {
          toast.error(
            "Please select State Required Retest to submit this Retail Batch Number"
          );
          return;
        }
      } else if (
        parseInt(inputs.SubstanceType, 10) ===
          parseInt(mjSubstanceType.RecId, 10) &&
        inputs.StateRetest &&
        !inputs.OriginalCOA
      ) {
        toast.error("Please upload the original COA file to continue");
        return;
      }
      if (
        inputs.BatchInfo.Date &&
        String(new Date(inputs.BatchInfo.Date).getFullYear()).length < 4
      ) {
        toast.error("Please, enter a valid Batch Date");
        return;
      }
      if (
        inputs.BatchInfo.ExpDate &&
        String(new Date(inputs.BatchInfo.ExpDate).getFullYear()).length < 4
      ) {
        toast.error("Please, enter a valid Expiration Date");
        return;
      }

      setLoading(true);
      let moistureTestToAdd = null;
      if (validateMoist()) {
        moistureTestToAdd = createMoistureTest();
      }
      const testsAdded = moistureTestToAdd
        ? [...tests.filter((test) => test.added), moistureTestToAdd]
        : tests.filter((test) => !!test.added);
      const OrderTotal = selectedPackage
        ? parseFloat(selectedPackage.Price, 10) +
          testsAdded.map((test) => test.UserPrice).reduce((a, b) => a + b, 0)
        : testsAdded.map((test) => test.UserPrice).reduce((a, b) => a + b, 0);
      const testWithMedicalType = testsAdded.find(
        (test) => test.MedicalType && test.isPackage
      );

      if (CartID) {
        const obj = Object.keys(INITIAL_STATE).reduce((acc, curr) => {
          if (inputs[curr] !== null) {
            if (curr === "SubstanceType" || curr === "TestType") {
              return {
                ...acc,
                [curr]: {
                  set: inputs[curr].toString(),
                },
              };
            }
            return {
              ...acc,
              [curr]: {
                set: inputs[curr],
              },
            };
          }
          return acc;
        }, {});

        if (selectedPackage?.PackageID) {
          obj.Package = {
            connect: {
              PackageID: parseInt(selectedPackage.PackageID, 10),
            },
          };
        } else if (
          !!resultCartData?.data?.cart?.PackageID &&
          !testsAdded.find((test) => test.isPackage)
        ) {
          obj.Package = {
            disconnect: true,
          };
        } else if (testsAdded.some((test) => test.isPackage)) {
          const foundPackageAsTest = testsAdded.find((test) => test.isPackage);
          obj.Package = {
            connect: {
              PackageID: parseInt(foundPackageAsTest.TestID, 10),
            },
          };
        }
        await updateCart({
          variables: {
            data: {
              ...obj,
              LabelClaim: inputs.LabelClaim,
              MMTCInfo: inputs.MMTCInfo,
              BatchInfo: inputs.BatchInfo,
              OrderTotal: { set: OrderTotal },
              Tests: testsAdded
                .filter((test) => !test.isPackage)
                .map((test) => test.TestID),
              MedicalType: {
                set:
                  selectedPackage?.MedicalType ||
                  testWithMedicalType?.MedicalType ||
                  null,
              },
            },
            where: { CartID: parseInt(CartID, 10) },
          },
          refetchQueries: [
            {
              query: ALL_CART_QUERY,
              variables: {
                where: {
                  UserID: { equals: parseInt(UserID, 10) },
                },
              },
              fetchPolicy: "network-only",
            },
            {
              query: SINGLE_CART_QUERY,
              variables: {
                where: { CartID: parseInt(CartID, 10) },
              },
            },
            {
              query: GET_CART_SUMMARY_QUERY,
              variables: {
                where: {
                  UserID: { equals: parseInt(authState?.user.UserID, 10) },
                },
                take: 2,
                skip: 0,
              },
              fetchPolicy: "network-only",
            },
          ],
        });
        history.push("/cart");
        toast.success("Order Test Edited successfully");
      } else {
        const data = {
          ...inputs,
          OrderTotal,
          User: {
            connect: {
              UserID: parseInt(UserID, 10),
            },
          },
          SubstanceType: inputs.SubstanceType.toString(),
          TestType: inputs.TestType.toString(),
          Tests: testsAdded
            .filter((test) => !test.isPackage)
            .map((test) => test.TestID),
          MedicalType:
            selectedPackage?.MedicalType ||
            testWithMedicalType?.MedicalType ||
            undefined,
        };

        if (selectedPackage?.PackageID) {
          data.Package = {
            connect: {
              PackageID: parseInt(selectedPackage.PackageID, 10),
            },
          };
        } else if (testsAdded.some((test) => test.isPackage)) {
          const foundPackageAsTest = testsAdded.find((test) => test.isPackage);
          data.Package = {
            connect: {
              PackageID: parseInt(foundPackageAsTest.TestID, 10),
            },
          };
        } else {
          data.Package = undefined;
        }

        await createCart({
          variables: {
            data,
          },
          refetchQueries: [
            {
              query: ALL_CART_QUERY,
              variables: {
                where: {
                  UserID: { equals: parseInt(UserID, 10) },
                },
              },
              fetchPolicy: "network-only",
            },
            {
              query: CART_COUNT_QUERY,
              variables: {
                where: {
                  UserID: parseInt(UserID, 10),
                },
              },
            },
            {
              query: GET_CART_SUMMARY_QUERY,
              variables: {
                where: {
                  UserID: { equals: parseInt(authState?.user.UserID, 10) },
                },
                take: 2,
                skip: 0,
              },
              fetchPolicy: "network-only",
            },
          ],
        });
        history.push("/cart");
        toast.success("Order Test Created successfully");
      }
    } catch (err) {
      toast.error(`Error ${CartID ? "updating" : "creating"} Order Test`);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectPackage = (pkg) => {
    if (
      !pkg.PackageTests.some((packageTest) =>
        packageTest.Test.Code.includes("CANN")
      )
    ) {
      setTests((prev) =>
        prev.filter((test) => test.Code !== "HOMO" || test.isPackage)
      );
    }

    if (
      pkg.PackageTests.some((packageTest) =>
        packageTest.Test.Code.includes("CANN")
      ) &&
      isHomoTestCriteriaApplicable()
    ) {
      createHomoTests();
    }
    setSelectedPackage(pkg);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="new-test-list-page">
      {!authState?.user?.isPastArizonaOrderTransitionDate ? (
        <React.Fragment>
          <div className="page-header">
            <PageHeader
              style={{ padding: 0, paddingRight: "1rem" }}
              title="Order New Test/Packages"
            >
              <Field kind="group">
                <Control expanded size="small">
                  <Label>Total Price</Label>
                  <Input
                    readOnly
                    placeholder="0.0"
                    size="small"
                    type="text"
                    value={`$${totals.price}`}
                  />
                </Control>
              </Field>
              <Button
                rounded
                color="primary"
                disabled={
                  !tests.some((test) => !!test.added) && !selectedPackage
                }
                form="new-test-order-packages"
                type="submit"
              >
                {CartID ? "Save" : "Add to Cart"}
              </Button>
            </PageHeader>
          </div>
          <form
            className="main-form"
            id="new-test-order-packages"
            onSubmit={handleSave}
          >
            <NewTestForm
              fixedSubstanceType={fixedSubstanceType}
              history={history}
              inputs={inputs}
              selectedPackage={selectedPackage}
              setInputs={setInputs}
              substanceTypes={substanceTypes || []}
              tab={tab}
              tests={tests}
              uploadCOAPath={uploadCOAPath}
              onChange={handleChange}
              onChangeTest={handleChangeTest}
              onPackageChange={handleSelectPackage}
              onSubmit={handleSave}
            />
          </form>
        </React.Fragment>
      ) : (
        <Column.Group centered>
          <Column size={10}>
            <div className="confirmation-container">
              <p
                style={{
                  fontSize: "2rem",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                All new orders can be placed through the Smithers CTS Arizona
                Confident Cannabis account{" "}
                <a
                  href="https://accounts.confidentcannabis.com/login?lab=smithersaz"
                  rel="noreferrer"
                  target="_blank"
                >
                  here
                </a>
                .
              </p>
            </div>
          </Column>
        </Column.Group>
      )}
    </div>
  );
};

NewTestPage.propTypes = {};

export default NewTestPage;
