import { format } from "date-fns";

// DEFAULT HEADER ROWS BY TEST CATEGORY

// SINGLE SAMPLE VIEW DEFAULTS

const SINGLE_HEADER_DEFAULT_ROWS_1 = [
  {
    field: "CreatedDateTime",
    label: "Date Analyzed",
    func: (val) =>
      val.CreatedDateTime
        ? format(new Date(val.CreatedDateTime), "MM-dd-yyyy")
        : "",
  },
  {
    field: "OrderName",
    label: "Name",
    func: (val) =>
      val?.JobOrder?.OverrideData?.OrderName || val?.OrderName || "",
  },
  {
    field: "Job.User.Username",
    label: "Client",
    func: (val) => val?.Job?.User?.Username || "",
  },
  {
    field: "Job.JobID",
    label: "Job ID",
    func: (val) => (val?.Job?.JobID ? val.Job.JobID : ""),
  },
  {
    field: "JobOrder.JobOrderID",
    label: "Order ID",
    func: (val) => val?.JobOrder?.JobOrderID || "",
  },
  {
    field: "SessionID",
    label: "Session ID",
    func: (val) => val.SessionID || "",
  },
  {
    field: "PrimarySampleSessionID",
    label: "Primary Session ID",
    func: (val) => val.PrimarySampleSessionID || "",
  },
  {
    field: "JobOrder.SubstanceType",
    label: "Substance Type",
    func: (val) =>
      val?.JobOrder?.OverrideDataCodeDescriptions &&
      JSON.parse(val?.JobOrder?.OverrideDataCodeDescriptions)?.SubstanceType
        ? JSON.parse(val?.JobOrder?.OverrideDataCodeDescriptions)?.SubstanceType
        : val.JobOrder?.Substance || "",
  },
  {
    field: "JobOrder.Category.CodeDescription",
    label: "Product Category",
    func: (val) =>
      val?.JobOrder?.OverrideDataCodeDescriptions &&
      JSON.parse(val?.JobOrder?.OverrideDataCodeDescriptions)?.ProductCategory
        ? JSON.parse(val?.JobOrder?.OverrideDataCodeDescriptions)
            ?.ProductCategory
        : val.JobOrder?.Category?.CodeDescription || "",
  },
];

const SINGLE_HEADER_DEFAULT_ROWS_2 = [
  { field: "Weight", label: "Sample Weight", units: "g" },
  { field: "Volume", label: "Extraction Volume", units: "ml" },
  { field: "Dilution", label: "Dilution" },
];

// MULTTIPLE SAMPLES VIEW DEFAULTS

const MULTIPLE_HEADER_DEFAULT_ROWS_1 = [
  {
    field: "OrderName",
    label: "Name",
    class: "default-header",
    header: true,
    func: (val) => [
      val?.JobOrder?.OverrideData?.OrderName || val.OrderName || "",
      val.SessionID || "",
      val.PrimarySampleSessionID || "",
      val.CreatedDateTime
        ? format(new Date(val.CreatedDateTime), "MM-dd-yyyy")
        : "",
    ],
  },
  {
    field: "JobOrder.Category.CodeDescription",
    label: "Product Category",
    func: (val) =>
      val?.JobOrder?.OverrideDataCodeDescriptions &&
      JSON.parse(val?.JobOrder?.OverrideDataCodeDescriptions)?.ProductCategory
        ? JSON.parse(val?.JobOrder?.OverrideDataCodeDescriptions)
            ?.ProductCategory
        : val.JobOrder?.Category?.CodeDescription || "",
  },
];

const MULTIPLE_HEADER_DEFAULT_ROWS_2 = [
  { field: "Weight", label: "Sample Weight", units: "g", editable: true },
  { field: "Volume", label: "Extraction Volume", units: "ml", editable: true },
  { field: "Dilution", label: "Dilution", editable: true, class: "" },
];

// SOLV SPECIFIC // SINGLE AND MULTI

const HEADER_DEFAULT_ROWS_SOLV = [
  { field: "Weight", label: "Sample Weight", units: "g" },
  { field: "Dilution", label: "Dilution", class: "" },
];

// CANN SPECIFIC // SINGLE

const SINGLE_HEADER_DEFAULT_ROWS_CANN = [
  {
    field: "DisplayUnit.Weight",
    label: "Serving Weight",
    func: (val) =>
      val?.JobOrder?.OverrideData?.Weight ||
      val?.JobOrder?.OverrideData?.Weight === 0
        ? val?.JobOrder?.OverrideData?.Weight
        : val?.JobOrder?.DisplayUnit?.Weight || "",
    units: "g",
  },
  {
    field: "JobOrder.DisplayUnit.Units",
    label: "Servings per Package",
    func: (val) =>
      val?.JobOrder?.OverrideData?.Units
        ? val?.JobOrder?.OverrideData?.Units
        : val?.JobOrder?.DisplayUnit?.Units || "",
  },
  {
    field: "Package Weight",
    label: "Package Weight",
    func: (val) => {
      if (
        val?.JobOrder?.OverrideData?.Weight &&
        val?.JobOrder?.OverrideData?.Units
      ) {
        return (
          val?.JobOrder?.OverrideData?.Weight *
          val?.JobOrder?.OverrideData?.Units
        ).toFixed(3);
      }
      if (
        val?.JobOrder?.DisplayUnit?.Weight &&
        val?.JobOrder?.DisplayUnit?.Units
      ) {
        return (
          val?.JobOrder?.DisplayUnit?.Weight * val?.JobOrder?.DisplayUnit?.Units
        ).toFixed(3);
      }
      return "";
    },
    units: "g",
  },
  {
    field: "Density",
    label: "Density",
    func: (val) =>
      val?.JobOrder?.OverrideData?.Density
        ? val?.JobOrder?.OverrideData?.Density
        : val?.JobOrder?.DisplayUnit?.Density || "",
  },
  {
    field: "Moisture",
    label: "Moisture Complete",
    icon: (val) => !!(val?.JobOrder?.Moisture || val?.JobOrder?.Moisture === 0),
  },
  {
    field: "Homogeneity",
    label: "Homogeneity",
    icon: (val) => val?.JobOrderTest?.Test?.Code === "HOMO",
  },
];

// CANN SPECIFIC // MULTI

const MULTIPLE_HEADER_DEFAULT_ROWS_CANN = [
  {
    field: "DisplayUnit.Weight",
    label: "Serving Weight",
    func: (val) =>
      val?.JobOrder?.OverrideData?.Weight ||
      val?.JobOrder?.OverrideData?.Weight === 0
        ? val?.JobOrder?.OverrideData?.Weight
        : val?.JobOrder?.DisplayUnit?.Weight || "",
    units: "g",
  },
  {
    field: "JobOrder.DisplayUnit.Units",
    label: "Servings per Package",
    func: (val) =>
      val?.JobOrder?.OverrideData?.Units
        ? val?.JobOrder?.OverrideData?.Units
        : val?.JobOrder?.DisplayUnit?.Units || "",
  },
  {
    field: "Package Weight",
    label: "Package Weight",
    func: (val) => {
      if (
        val?.JobOrder?.OverrideData?.Weight &&
        val?.JobOrder?.OverrideData?.Units
      ) {
        return (
          val?.JobOrder?.OverrideData?.Weight *
          val?.JobOrder?.OverrideData?.Units
        ).toFixed(3);
      }
      if (
        val?.JobOrder?.DisplayUnit?.Weight &&
        val?.JobOrder?.DisplayUnit?.Units
      ) {
        return (
          val?.JobOrder?.DisplayUnit?.Weight * val?.JobOrder?.DisplayUnit?.Units
        ).toFixed(3);
      }
      return "";
    },
    units: "g",
  },
  {
    field: "JobOrder.DisplayUnit.DisplayName",
    label: "Report Units",
    func: (val) =>
      val?.JobOrder?.OverrideData?.DisplayName ||
      val?.JobOrder?.DisplayUnit?.DisplayName ||
      "",
  },
  {
    field: "Density",
    label: "Density",
    func: (val) =>
      val?.JobOrder?.OverrideData?.Density
        ? val?.JobOrder?.OverrideData?.Density
        : val?.JobOrder?.DisplayUnit?.Density || "",
  },
  {
    field: "Moisture",
    label: "Moisture Complete",
    icon: (val) => !!(val?.JobOrder?.Moisture || val?.JobOrder?.Moisture === 0),
  },
  {
    field: "Homogeneity",
    label: "Homogeneity",
    icon: (val) => val?.JobOrderTest?.Test?.Code === "HOMO",
  },
];

// MICRO SPECIFIC // MULTI

const MULTIPLE_HEADER_DEFAULT_ROWS_MICRO_1 = [
  {
    field: "OrderName",
    label: "Name",
    class: "micro-header",
    header: true,
    func: (val) => [
      val?.JobOrder?.OverrideData?.OrderName || val.OrderName || "",
      val.SessionID || "",
      val.JobOrderTest?.Test?.Name || "",
      val.PrimarySampleSessionID || "",
      val.CreatedDateTime
        ? format(new Date(val.CreatedDateTime), "MM-dd-yyyy")
        : "",
    ],
  },
];

const MULTIPLE_HEADER_DEFAULT_ROWS_MICRO_2 = [
  {
    field: "JobOrder.SubstanceType",
    label: "Substance Type",
    func: (val) =>
      val?.JobOrder?.OverrideDataCodeDescriptions &&
      JSON.parse(val?.JobOrder?.OverrideDataCodeDescriptions)?.SubstanceType
        ? JSON.parse(val?.JobOrder?.OverrideDataCodeDescriptions)?.SubstanceType
        : val.JobOrder?.Substance || "",
  },
  {
    field: "JobOrder.Category.CodeDescription",
    label: "Product Category",
    func: (val) =>
      val?.JobOrder?.OverrideDataCodeDescriptions &&
      JSON.parse(val?.JobOrder?.OverrideDataCodeDescriptions)?.ProductCategory
        ? JSON.parse(val?.JobOrder?.OverrideDataCodeDescriptions)
            ?.ProductCategory
        : val.JobOrder?.Category?.CodeDescription || "",
  },
  {
    field: "JobOrder.DisplayUnit.DisplayName",
    label: "Report Units",
    func: (val) =>
      val?.JobOrder?.OverrideData?.DisplayName ||
      val?.JobOrder?.DisplayUnit?.DisplayName ||
      "",
  },
];

// BUILD AND RETURN HEADER ROWS

const FindBatchResultHeaderRows = (testCategoryCode, singleView) => {
  if (testCategoryCode === "CANN" || testCategoryCode === "CE") {
    return singleView
      ? [
          ...SINGLE_HEADER_DEFAULT_ROWS_1,
          ...MULTIPLE_HEADER_DEFAULT_ROWS_2,
          ...SINGLE_HEADER_DEFAULT_ROWS_CANN,
        ]
      : [
          ...MULTIPLE_HEADER_DEFAULT_ROWS_1,
          ...MULTIPLE_HEADER_DEFAULT_ROWS_2,
          ...MULTIPLE_HEADER_DEFAULT_ROWS_CANN,
        ];
  }
  if (testCategoryCode === "SOLV") {
    return singleView
      ? [...SINGLE_HEADER_DEFAULT_ROWS_1, ...HEADER_DEFAULT_ROWS_SOLV]
      : [...MULTIPLE_HEADER_DEFAULT_ROWS_1, ...HEADER_DEFAULT_ROWS_SOLV];
  }
  if (testCategoryCode?.includes("MICRO")) {
    return singleView
      ? [...SINGLE_HEADER_DEFAULT_ROWS_1, ...SINGLE_HEADER_DEFAULT_ROWS_2]
      : [
          ...MULTIPLE_HEADER_DEFAULT_ROWS_MICRO_1,
          ...MULTIPLE_HEADER_DEFAULT_ROWS_MICRO_2,
          ...MULTIPLE_HEADER_DEFAULT_ROWS_2,
        ];
  }
  // case "METAL":
  // case "SSI LC PEST MYCO":
  // case "AGILENT LC PEST MYCO":
  // case "GC PEST":
  // case "TERP":
  return singleView
    ? [...SINGLE_HEADER_DEFAULT_ROWS_1, ...SINGLE_HEADER_DEFAULT_ROWS_2]
    : [...MULTIPLE_HEADER_DEFAULT_ROWS_1, ...MULTIPLE_HEADER_DEFAULT_ROWS_2];
};

export default FindBatchResultHeaderRows;
