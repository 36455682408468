import React, { useContext, useState, useEffect } from "react";
import { Icon, Button } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { endOfMonth, startOfMonth } from "date-fns";
import PropTypes from "prop-types";

import { useAuth } from "../../../context";
import { ALL_CREDIT_LEDGER_QUERY } from "../../../graphql";
import { ModalContext, } from "../../../context/ModalContext";
import COLUMNS from "./columns";
import { PageHeader, IconButton, ListSearchInput, DataTable } from "../../../components";
import AddCreditModal from "../components/AddCreditModal";
import CreditLedgerFilters from "../components/CreditLedgerFilters";
import { debounce } from "../../../utils";

const INITIAL_STATE = {
  CreditDate: {
    gte: startOfMonth(new Date()),
    lte: endOfMonth(new Date()),
  },
};

const CreditLedgerPage = ({ routePermissions }) => {
  const [where, setWhere] = useState(INITIAL_STATE);
  const [showFilters, setShowFilters] = useState(false);
  const [canUpdate, setCanUpdate] = useState(true);
  const [labID, setLabID] = useState("");

  const { state: authState } = useAuth();

  const { setModalOpen } = useContext(ModalContext);

  const orderBy = [{ id: "CreditDate", desc: true }];

  useEffect(() => {
    setLabID(authState.user.LabID);
    if (authState.user.LabID !== "9999") {
      setWhere({
        ...INITIAL_STATE,
        User: {
          is: {
            LabID: {
              equals: parseInt(authState.user.LabID, 10),
            },
          },
        },
      });
    }  
  }, [authState]);
  
  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  const handleCreateCredit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModalOpen(
      true,
      <AddCreditModal
        onComplete={() => {
          setModalOpen(false);
        }}
      />
    );
  };

  const handleFilterChange = (name, value) => {
    setWhere((prev) => {
      if (value) {
        return {
          ...prev,
          [name]: value,
        };
      }

      if (labID?.toString() !== "9999") {
        return {
          ...prev,
          Job: {
            is: {
              LabID: { 
                ...prev.Job.is.LabID,
              },
            },
          },
        }
      }

      return Object.keys(prev)
        .filter((x) => x !== name)
        .reduce((a, c) => ({ ...a, [c]: prev[c] }), {});
    });
  };

  const handleFiltersReset = () => {
    if (labID.toString() !== "9999") {
      setWhere({
        ...INITIAL_STATE,
        User: {
          is: {
            LabID: {
              equals: parseInt(labID, 10),
            },
          },
        },
      });
    } else {
      setWhere(INITIAL_STATE);
    }
  };

  const toggleFilters = (e) => {
    e.preventDefault();
    setShowFilters((prev) => !prev);
  };
  
  return (
    <div className="job-list-page">
      <PageHeader title="Credit Ledger">
        <ListSearchInput
          onChange={debounce((name, value) => {
            if (value) {
              setWhere((prev) => ({
                ...prev,
                OR: [
                  { JobID: { equals: parseInt(value, 10) || undefined } },
                  { User: { is: { Username: { equals: value } } } },
                ],
              }));
            } else  {
              handleFiltersReset();
            }
          }, 500)}
        />
        <Button color="primary" onClick={toggleFilters}>
          <Icon>
            <FontAwesomeIcon icon="filter" />
          </Icon>
        </Button>
        {canUpdate && <IconButton icon="plus" onClick={handleCreateCredit} /> }
      </PageHeader>
      {showFilters && (
        <CreditLedgerFilters
          canSearchByLab={labID.toString() === "9999"}
          handleFilterChange={handleFilterChange}
          handleFiltersReset={handleFiltersReset}
          inputs={where}
        />
      )}
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateCreditLedger"
        columns={COLUMNS}
        name="creditLedgers"
        orderBy={orderBy}
        query={ALL_CREDIT_LEDGER_QUERY}
        where={where}
      />
    </div>
  );
};

CreditLedgerPage.propTypes = {
  routePermissions: PropTypes.array,
};

CreditLedgerPage.defaultProps = {
  routePermissions: [],
};

CreditLedgerPage.propTypes = {};

export default CreditLedgerPage;
