import React from "react";
import PropTypes from "prop-types";
import { Control, Field, Input, Label } from "rbx";

const UserGroupForm = ({ editing, inputs, onChange, disabled }) => (
  <div className="user-group-form">
    <Field>
      <Control>
        <Label>Name</Label>
        <Input
          disabled={disabled}
          name="Name"
          type="text"
          value={inputs.Name}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field className="span-3">
      <Control>
        <Label>Description</Label>
        <Input
          disabled={disabled}
          name="Description"
          type="text"
          value={inputs.Description}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
  </div>
);

UserGroupForm.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  editing: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
};

UserGroupForm.defaultProps = {
  inputs: {},
  editing: false,
  onChange: () => null,
};
export default UserGroupForm;
