import { gql } from "@apollo/client";

export const CREATE_TEST_CATEGORY_MUTATION = gql`
  mutation CREATE_TEST_CATEGORY_MUTATION($data: TestCategoriesCreateInput!) {
    createTestCategories(data: $data) {
      TestCategoryID
    }
  }
`;

export const UPDATE_TEST_CATEGORY_MUTATION = gql`
  mutation UPDATE_TEST_CATEGORY_MUTATION(
    $data: TestCategoriesUpdateInput!
    $where: TestCategoriesWhereUniqueInput!
  ) {
    updateTestCategories(data: $data, where: $where) {
      TestCategoryID
    }
  }
`;

export const DELETE_TEST_CATEGORY_MUTATION = gql`
  mutation DELETE_TEST_CATEGORY_MUTATION($where: TestCategoriesWhereUniqueInput!) {
    deleteTestCategories(where: $where) {
      TestCategoryID
    }
  }
`;
