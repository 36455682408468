import React from "react";
import "./Loader.scss";

const Loader = () => (
  <div className="sweet-loading">
    <div className="corners">
      <div className="corner corner--1" />
      <div className="corner corner--2" />
      <div className="corner corner--3" />
      <div className="corner corner--4" />
    </div>
  </div>
);

Loader.propTypes = {};

export default Loader;
