import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  useMutation,
  useLazyQuery,
  useApolloClient,
  useQuery,
} from "@apollo/client";
import { Title, Fieldset, Button } from "rbx";
import { customToast as toast } from "../../../../utils";
import CouponForm from "../CouponForm";
import Loader from "../../../../components/Loader";
import {
  CREATE_COUPON_MUTATION,
  ALL_COUPONS_QUERY,
  SINGLE_COUPON_QUERY,
  UPDATE_COUPON_MUTATION,
  ALL_SYSTEM_CODES_QUERY,
} from "../../../../graphql";

import { useAuth } from "../../../../context";

const INITIAL_STATE = {
  Code: "",
  Percent: "0",
  Discount: "0",
  Used: "0",
  Type: "",
  Uses: "0",
  CreateDate: "",
  StartDate: new Date(),
  ExpireDate: new Date(),
};

const convertInputToVariables = (variables, keys, adding = true) =>
  keys.reduce((acc, curr) => {
    if (variables[curr]) {
      acc[curr] = variables[curr];
    } else {
      acc[curr] = "";
    }
    return acc;
  }, {});

const CouponModal = ({ onComplete, CouponID }) => {
  const [loading, setLoading] = useState(!!CouponID);
  const [codeUnique, setCodeUnique] = useState(true);
  const [createCoupon] = useMutation(CREATE_COUPON_MUTATION);
  const [updateCoupon] = useMutation(UPDATE_COUPON_MUTATION);
  const { data: getCouponTypesData } = useQuery(ALL_SYSTEM_CODES_QUERY, {
    variables: {
      where: {
        Type: { equals: "System" },
        Category: { equals: "Coupon" },
        CodeName: { equals: "Type" },
      },
    },
  });

  const client = useApolloClient();
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState({
    ...INITIAL_STATE,
  });

  const [getCouponData, resultCouponData] = useLazyQuery(SINGLE_COUPON_QUERY);

  useEffect(() => {
    if (CouponID) {
      getCouponData({
        variables: {
          where: { CouponID: parseInt(CouponID, 10) },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [CouponID, getCouponData]);

  useEffect(() => {
    if (resultCouponData?.data?.findUniqueCoupons) {
      const {
        data: { findUniqueCoupons },
      } = resultCouponData;
      setInputs((prev) => ({
        ...prev,
        ...findUniqueCoupons,
        ActualCode: findUniqueCoupons.Code,
      }));
      setLoading(false);
    }
  }, [resultCouponData]);

  useEffect(() => {
    if (authState?.user?.Lab?.Company !== "All Location" && !CouponID) {
      setInputs((prev) => ({
        ...prev,
        LabID: authState.user.LabID,
      }));
    }
  }, [authState, CouponID]);

  const handleChange = (name, value) => {
    if (name === "ExpireDate" && new Date(value) < new Date(inputs.StartDate)) {
      toast.error("Expire Date should be after Start Date");
      return;
    }

    if (name === "StartDate" && new Date(value) > new Date(inputs.ExpireDate)) {
      setInputs((prev) => ({
        ...prev,
        ExpireDate: value,
      }));
    }

    if (name === "Uses" && value < 0) {
      setInputs((prev) => ({
        ...prev,
        ExpireDate: null,
        [name]: value,
      }));
    } else {
      setInputs((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const validateUniqueCode = async () => {
    if ((CouponID && inputs.Code !== inputs.ActualCode) || !CouponID) {
      const {
        data: { findUniqueCoupons },
      } = await client.query({
        query: SINGLE_COUPON_QUERY,
        variables: {
          where: { Code: inputs.Code },
        },
        fetchPolicy: "network-only",
      });
      setCodeUnique(!findUniqueCoupons);
    } else {
      setCodeUnique(true);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (codeUnique) {
        if (CouponID) {
          const obj = Object.keys(INITIAL_STATE).reduce((acc, curr) => {
            if (inputs[curr] !== null) {
              return {
                ...acc,
                [curr]: {
                  set: inputs[curr],
                },
              };
            }
            return acc;
          }, {});
          delete obj.Type;
          await updateCoupon({
            variables: {
              data: {
                ...obj,
                Percent: { set: parseInt(inputs.Percent, 10) },
                Discount: { set: parseFloat(inputs.Discount) },
                Uses: { set: parseInt(inputs.Uses, 10) },
                DiscountType: {
                  connect: {
                    RecId: parseInt(inputs.Type, 10),
                  },
                },
                ExpireDate: {
                  set: inputs.ExpireDate,
                },
                Lab: {
                  connect: {
                    LabID: parseInt(inputs.LabID, 10),
                  },
                },
              },
              where: {
                CouponID: parseInt(CouponID, 10),
              },
            },
            refetchQueries: [
              {
                query: ALL_COUPONS_QUERY,
                variables: {
                  where: {},
                },
                fetchPolicy: "network-only",
              },
              {
                query: SINGLE_COUPON_QUERY,
                variables: {
                  where: { CouponID: parseInt(CouponID, 10) },
                },
                fetchPolicy: "network-only",
              },
            ],
          });

          toast.success("Coupon updated successfully.");
        } else {
          const data = {
            ...convertInputToVariables(
              inputs,
              Object.keys(INITIAL_STATE),
              true
            ),
            Percent: parseInt(inputs.Percent, 10),
            Discount: parseFloat(inputs.Discount),
            Uses: parseInt(inputs.Uses, 10),
            User: {
              connect: { UserID: parseInt(authState?.user?.UserID, 10) },
            },
            CreateDate: new Date(),
            DiscountType: {
              connect: {
                RecId: parseInt(inputs.Type, 10),
              },
            },
            ExpireDate: inputs.ExpireDate,
            Lab: {
              connect: {
                LabID: parseInt(inputs.LabID, 10),
              },
            },
          };
          delete data.Type;
          await createCoupon({
            variables: {
              data,
            },
            refetchQueries: [
              {
                query: ALL_COUPONS_QUERY,
                variables: {
                  where: {},
                },
              },
            ],
          });
          toast.success("Coupon created successfully.");
        }
        onComplete();
      }
    } catch (err) {
      toast.error(`Error ${CouponID ? "updating" : "creating"} Coupon.`);
    } finally {
      setLoading(false);
    }
  };

  const isSaveDisabled = useMemo(
    () => !codeUnique || parseInt(inputs.Percent, 10) > 100 || !inputs.LabID,
    [codeUnique, inputs]
  );

  if (loading) {
    return <Loader style={{ height: "330px" }} />;
  }

  return (
    <React.Fragment>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>{CouponID ? "Update" : "Create"} Coupon</Title>
        </div>
        <div className="page-head-end">
          <Button.Group>
            <Button
              rounded
              color="primary"
              size="small"
              type="button"
              onClick={onComplete}
            >
              <span>Cancel</span>
            </Button>
            <Button
              rounded
              color="primary"
              disabled={isSaveDisabled}
              form="add-coupon-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Fieldset>
        <CouponForm
          adding={!CouponID}
          codeUnique={codeUnique}
          couponTypes={getCouponTypesData?.findManySystemCodes || []}
          formId="add-coupon-form"
          inputs={inputs}
          onBlurCode={validateUniqueCode}
          onChange={handleChange}
          onSubmit={handleSave}
        />
      </Fieldset>
      <hr />
    </React.Fragment>
  );
};

CouponModal.propTypes = {
  onComplete: PropTypes.func,
  CouponID: PropTypes.string,
};

CouponModal.defaultProps = {
  onComplete: (e) => e,
  CouponID: null,
};

export default CouponModal;
