import React from "react";
import PropTypes from "prop-types";
import { Column, Field, Title, Box, Input, Control, Label, Button } from "rbx";
import StateSelect from "../../../../components/StateSelect";

const BillingDetails = ({
  inputs,
  onChange,
  handleSave,
  copyBillingInfo,
  copyShippingInfo,
  disabled,
}) => (
  <form id="edit-client-form" onSubmit={handleSave}>
    <Column.Group>
      <Column size={12}>
        <Box>
          <div className="page-head">
            <div className="page-head-start">
              <Title subtitle>Billing Address</Title>
            </div>
            <div className="page-head-end">
              <Button
                color="primary"
                disabled={disabled}
                size="small"
                type="button"
                onClick={copyBillingInfo}
              >
                Copy from Account Details
              </Button>
            </div>
          </div>
          <hr />
          <Field kind="group">
            <Control expanded>
              <Label>First name</Label>
              <Input
                disabled={disabled}
                name="BillingFirstName"
                size="small"
                value={inputs?.BillingAddress?.BillingFirstName}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, "BillingAddress")
                }
              />
            </Control>
            <Control expanded>
              <Label>Last name</Label>
              <Input
                disabled={disabled}
                name="BillingLastName"
                size="small"
                value={inputs?.BillingAddress?.BillingLastName}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, "BillingAddress")
                }
              />
            </Control>
          </Field>
          <Field>
            <Control>
              <Label>Company</Label>
              <Input
                disabled={disabled}
                name="BillingCompany"
                size="small"
                value={inputs?.BillingAddress?.BillingCompany}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, "BillingAddress")
                }
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded>
              <Label>Address</Label>
              <Input
                disabled={disabled}
                name="BillingAddress"
                size="small"
                value={inputs?.BillingAddress?.BillingAddress}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, "BillingAddress")
                }
              />
            </Control>
            <Control expanded>
              <Label>Address 2</Label>
              <Input
                disabled={disabled}
                name="BillingAddress2"
                size="small"
                value={inputs?.BillingAddress?.BillingAddress2}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, "BillingAddress")
                }
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded>
              <Label>City</Label>
              <Input
                disabled={disabled}
                name="BillingCity"
                size="small"
                value={inputs?.BillingAddress?.BillingCity}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, "BillingAddress")
                }
              />
            </Control>
            <StateSelect
              disabled={disabled}
              id="BillingState"
              label="State/Province"
              name="BillingState"
              size="small"
              value={inputs?.BillingAddress?.BillingState}
              onChange={(name, value) =>
                onChange(name, value, "BillingAddress")
              }
            />
            <Control expanded>
              <Label>Zip/Postal</Label>
              <Input
                disabled={disabled}
                name="BillingZip"
                size="small"
                value={inputs?.BillingAddress?.BillingZip}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, "BillingAddress")
                }
              />
            </Control>
          </Field>
        </Box>
      </Column>
    </Column.Group>
  </form>
);

BillingDetails.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  handleSave: PropTypes.func,
  copyBillingInfo: PropTypes.func,
  copyShippingInfo: PropTypes.func,
  disabled: PropTypes.bool,
};

BillingDetails.defaultProps = {
  inputs: {},
  onChange: (e) => e,
  handleSave: (e) => e,
  copyBillingInfo: (e) => e,
  copyShippingInfo: (e) => e,
  disabled: false,
};

export default BillingDetails;
