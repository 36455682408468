/* eslint-disable react/prop-types */
import React from "react";
import { format } from "date-fns";
import { Tag, Button } from "rbx";
import { STATUS_COLORS } from "../../../constants";
import { TableCell } from "../../../components/DataTable";

const DEFAULT_COLUMNS = [
  {
    Header: "Username",
    id: "Username",
    accessor: "User.Username",
    disableSortBy: true,
  },
  {
    Header: "Job ID",
    id: "JobID",
    type: "number",
    accessor: "JobID",
  },
  {
    Header: "Order ID",
    id: "JobOrderID",
    type: "number",
    accessor: "JobOrderID",
  },
  {
    Header: "Order Name",
    id: "OrderName",
    accessor: "OrderName",
    Cell: ({ cell, row }) => <TableCell.Wrapped value={cell.value} />,
  },
  {
    Header: "Create Date",
    id: "OrderCreateDate",
    accessor: "OrderCreateDate",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM-dd-yyyy") : "",
  },
  {
    Header: "Status",
    id: "JobOrderStatus",
    accessor: "JobOrderStatus.CodeDescription",
    Cell: ({ cell }) => (
      <Tag color={STATUS_COLORS.Status.Legacy}>Legacy Order</Tag>
    ),
    disableSortBy: true,
  },
  {
    Header: "COA",
    id: "COA",
    accessor: "COA",
    Cell: ({ cell, row }) => (
      <a href={cell.value} rel="noreferrer" target="_blank">
        {cell.value}
      </a>
    ),
    disableSortBy: true,
  },
  {
    Header: "One Pager",
    id: "OP",
    accessor: "OP",
    Cell: ({ cell }) =>
      cell.value ? (
        <Button
          as="a"
          color="primary"
          href={cell.value}
          rel="noreferrer"
          style={{ borderRadius: "20px 0 20px 20px" }}
          target="_blank"
        >
          Download
        </Button>
      ) : (
        <span />
      ),
    disableSortBy: true,
  },
];

export { DEFAULT_COLUMNS, STATUS_COLORS };
