import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useQuery } from "@apollo/client";

import { ALL_EQUIPMENT_QUERY } from "../../graphql";

const EquipmentSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
  where,
  fullWidth,
  returnFullValue,
}) => {
  const { data: getEquipmentData } = useQuery(ALL_EQUIPMENT_QUERY, {
    variables: { where, orderBy: { Name: "asc" } },
    fetchPolicy: "network-only",
  });

  const equipments = getEquipmentData?.findManyEquipment;

  const indicateRequired = required && (value === "" || value === null);

  const handleChange = (e) => {
    if (returnFullValue) {
      const foundEquipment = equipments.find(
        (equipment) =>
          parseInt(equipment.EquipmentID, 10) === parseInt(e.target.value, 10)
      );
      onChange(e.target.name, setValue(foundEquipment || ""));
    } else {
      onChange(
        e.target.name,
        setValue(e.target.value ? parseInt(e.target.value, 10) : "")
      );
    }
  };

  return (
    <Control expanded={fullWidth}>
      <Label>{label}</Label>
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(equipments) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          required={required}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          <Select.Option value="" />
          {Array.isArray(equipments) &&
            equipments.map((equipment) => (
              <Select.Option
                key={equipment.EquipmentID}
                value={equipment.EquipmentID}
              >
                {equipment.Name}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

EquipmentSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  required: PropTypes.bool,
  where: PropTypes.object,
  fullWidth: PropTypes.bool,
  returnFullValue: PropTypes.bool,
};

EquipmentSelect.defaultProps = {
  value: "",
  name: "",
  label: "Equipment",
  size: "small",
  required: false,
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  where: {},
  fullWidth: false,
  returnFullValue: false,
};

export default EquipmentSelect;
