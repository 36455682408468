import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery, useApolloClient } from "@apollo/client";
import { Box, Button } from "rbx";
import {
  SINGLE_JOB_QUERY,
  GENERATE_JOB_RECEIPT_QUERY,
} from "../../graphql";
import { PageHeader, Loader } from "../../components";

const JobReceiptPage = () => {
  const { JobID } = useParams();
  const client = useApolloClient();

  const [getJobData, resultJobData] = useLazyQuery(SINGLE_JOB_QUERY);
  const [generateReceipt, receipt] = useLazyQuery(GENERATE_JOB_RECEIPT_QUERY);
  const [jobInfo, setJobInfo] = useState(undefined);
  const [fileDownloading, setFileDownloading] = useState(false);

  useEffect(() => {
    const generate = async () => {
      generateReceipt({
        variables: {
          where: { JobID: parseInt(JobID, 10) },
          data: { generatePDF: false },
        },
      });
    };
    if (JobID) {
      getJobData({
        variables: {
          where: { JobID: parseInt(JobID, 10) },
        },
        fetchPolicy: "network-only",
      });
      generate();
    }
  }, [JobID, getJobData, generateReceipt]);

  useEffect(() => {
    const findUniqueJobs = resultJobData?.data?.findUniqueJobs;
    if (findUniqueJobs) {
      setJobInfo({
        ...findUniqueJobs,
        BillingAddress: findUniqueJobs.BillingAddress
          ? JSON.parse(findUniqueJobs.BillingAddress)
          : null,
        ShippingAddress: findUniqueJobs.ShippingAddress
          ? JSON.parse(findUniqueJobs.ShippingAddress)
          : null,
      });
    }
  }, [resultJobData]);

  const downloadReceiptPDF = (filename) => {
    const el = document.createElement("a");
    el.setAttribute("href", filename);
    el.setAttribute("download", ``);
    el.style.display = "none";
    document.body.appendChild(el);
    el.click();
    document.body.removeChild(el);
    setFileDownloading(false);
  };

  const handleGeneratePDF = async (e) => {
    e.preventDefault();
    setFileDownloading(true);
    const { data: pdfResult } = await client.query({
      query: GENERATE_JOB_RECEIPT_QUERY,
      variables: {
        where: { JobID: parseInt(JobID, 10) },
        data: { generatePDF: true },
      },
    });
    downloadReceiptPDF(pdfResult.generateJobReceipt);
  };

  if (!jobInfo) return <Loader />;

  return (
    <div style={{ overflowX: "scroll" }}>
      <PageHeader title="Job Receipt">
        <Button
          color="primary"
          disabled={fileDownloading}
          size="small"
          onClick={handleGeneratePDF}
        >
          <span>Download Receipt PDF</span>
        </Button>
      </PageHeader>
      <Box
        style={{
          overflow: "visible",
          width: "calc(10in + 2rem)",
          margin: "auto",
        }}
      >
        {receipt?.data?.generateJobReceipt && (
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: receipt?.data?.generateJobReceipt,
            }}
            style={{ width: "10in" }}
          />
        )}
      </Box>
    </div>
  );
};
export default JobReceiptPage;
