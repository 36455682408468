import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import {
  LIST_SYSTEM_CODES_QUERY,
} from "../../../graphql";
import COLUMNS from "./columns";
import {
  PageHeader,
  DataTable,
  IconButton,
  ListSearchInput,
} from "../../../components";
import { useModal } from "../../../context/ModalContext";
import SystemCodeModal from "../components/SystemCodeModal";
import { debounce } from "../../../utils";

const INITIAL_WHERE = {
  OR: [
    {
      Category: {
        contains: "",
      },
    },
    {
      Type: {
        contains: "",
      },
    },
  ],
};

const SystemCodeListPage = (props) => {
  const { RecId } = useParams();
  const history = useHistory();
  const { setModalOpen } = useModal();
  const [where, setWhere] = useState(INITIAL_WHERE);
  const [modalOpened, setModalOpened] = useState();
  const orderBy = [{ id: "CodeName", desc: false }];
  const [canUpdate, setCanUpdate] = useState(true);

  const { routePermissions } = props;

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  useEffect(() => {
    if (RecId && !modalOpened && canUpdate) {
      setModalOpened(true);
      setModalOpen(
        true,
        <SystemCodeModal
          RecId={RecId}
          onComplete={() => {
            history.push("/system-codes");
            setModalOpen(false);
          }}
        />
      );
    }
  }, [RecId, history, modalOpened, setModalOpen, canUpdate]);

  const handleCreateSystemCode = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push("/system-codes");
    setModalOpen(
      true,
      <SystemCodeModal
        onComplete={() => {
          history.push("/system-codes");
          setModalOpen(false);
        }}
      />
    );
  };

  const handleRowClick = (systemCodeItem) => {
    if (canUpdate) {
      setModalOpened(false);
      history.push(`/system-codes/${systemCodeItem.RecId}`);
    }
  };

  const handleSearchInputChange = (value) => {
    if (value) {
      setWhere({
        OR: [
          {
            Category: {
              contains: value,
            },
          },
          {
            Type: {
              contains: value,
            },
          },
        ],
      });
    } else {
      setWhere(INITIAL_WHERE);
    }
  };

  return (
    <div className="system-code-list-page">
      <PageHeader title="System Codes">
        <ListSearchInput
          onChange={debounce((name, value) => {
            handleSearchInputChange(value);
          }, 500)}
        />
        {canUpdate && (
          <IconButton icon="plus" onClick={handleCreateSystemCode} />
        )}
      </PageHeader>
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateSystemCodes"
        columns={COLUMNS}
        name="findManySystemCodes"
        orderBy={orderBy}
        query={LIST_SYSTEM_CODES_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

SystemCodeListPage.propTypes = {
  routePermissions: PropTypes.array,
};

SystemCodeListPage.defaultProps = {
  routePermissions: [],
};

export default SystemCodeListPage;
