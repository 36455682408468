/* eslint-disable max-lines-per-function */
import React, { useEffect, useState, useCallback } from "react";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import { Icon, Title, Generic, Button, Column } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../../context";
import { useModal } from "../../../context/ModalContext";
import { customToast as toast } from "../../../utils";
import { PageHeader, Confirmation, Loader } from "../../../components";
import {
  ALL_CART_QUERY,
  DELETE_CART_MUTATION,
  CART_COUNT_QUERY,
  CHECK_COUPON_VALIDITY_QUERY,
  CREATE_JOB_AFTER_PAYMENT_MUTATION,
  ALL_CREDIT_LEDGER_QUERY,
  ALL_SYSTEM_CODES_QUERY,
  GET_CART_SUMMARY_QUERY,
} from "../../../graphql";
import { CartCheckoutForm, Payment } from "../components";
import "../Checkout.scss";

const INITIAL_STATE = {
  terms: false,
  useSavedBillingAddress: true,
  CouponCode: "",
  ProcessingOption: "",
  BillingFirstname: "",
  BillingLastname: "",
  BillingCompany: "",
  BillingAddress: "",
  BillingAddress2: "",
  BillingCity: "",
  BillingState: "",
  BillingZip: "",
  credits: false,
  TermsCode: "",
};

const CartPage = () => {
  const { state: authState } = useAuth();
  const history = useHistory();
  const client = useApolloClient();
  const { setModalOpen } = useModal();

  const [carts, setCarts] = useState([]);
  const [totals, setTotals] = useState({
    subtotal: 0,
    processing: 0,
    total: 0,
    usedCredits: 0,
  });
  const [loading, setLoading] = useState(false);
  const [creditLayers, setCreditLayers] = useState(0);
  const [inputs, setInputs] = useState({
    ...INITIAL_STATE,
    BillingFirstname:
      authState?.user?.BillingAddress?.BillingFirstname ||
      authState?.user.FirstName ||
      "",
    BillingLastname:
      authState?.user?.BillingAddress?.BillingLastname ||
      authState?.user.LastName ||
      "",
    BillingCompany:
      authState?.user?.BillingAddress?.BillingCompany ||
      authState?.user.Company ||
      "",
    BillingAddress:
      authState?.user?.BillingAddress?.BillingAddress ||
      authState?.user.Address ||
      "",
    BillingAddress2:
      authState?.user?.BillingAddress?.BillingAddress2 ||
      authState?.user.Address2 ||
      "",
    BillingCity:
      authState?.user?.BillingAddress?.BillingCity ||
      authState?.user.City ||
      "",
    BillingState:
      authState?.user?.BillingAddress?.BillingState ||
      authState?.user.State ||
      "",
    BillingZip:
      authState?.user?.BillingAddress?.BillingZip || authState?.user.Zip || "",
  });
  const [hasMicro, setHasMicro] = useState(false);

  const [getCarts, { data: cartsData, loading: resLoading }] =
    useLazyQuery(ALL_CART_QUERY);
  const [deleteCart] = useMutation(DELETE_CART_MUTATION);
  const [createJob] = useMutation(CREATE_JOB_AFTER_PAYMENT_MUTATION);
  const [getCreditLedgerData, resultCreditLedgerData] = useLazyQuery(
    ALL_CREDIT_LEDGER_QUERY
  );

  const getRepeatedTestsCount = useCallback(
    (cart, testID) =>
      cart.Tests.filter((cartTestID) => cartTestID === testID).length,
    []
  );

  useEffect(() => {
    getCarts({
      variables: {
        where: {
          UserID: { equals: parseInt(authState.user.UserID, 10) },
        },
      },
      fetchPolicy: "network-only",
    });
  }, [getCarts, authState]);

  useEffect(() => {
    if (cartsData?.carts) {
      setCarts(
        cartsData.carts.map((cart) => ({ ...cart, showDetails: false }))
      );
    }
  }, [cartsData]);

  useEffect(() => {
    if (cartsData?.carts) {
      const cartWithMicro = cartsData.carts.find((cart) => {
        if (Array.isArray(cart.TestItems) && cart.TestItems.length) {
          const testItem = cart.TestItems.find((item) => {
            const testTestCategory = item.TestTestCategories.find((category) =>
              category.TestCategory.Code.includes("MICRO")
            );
            return testTestCategory ? item : null;
          });
          return testItem ? cart : null;
        }
        if (cart.Package) {
          const packageTest = cart.Package.PackageTests.find((pkgTest) => {
            const testTestCategory = pkgTest.Test.TestTestCategories.find(
              (category) => category.TestCategory.Code.includes("MICRO")
            );
            return testTestCategory ? pkgTest : null;
          });
          return packageTest ? cart : null;
        }
        return null;
      });
      setHasMicro(!!cartWithMicro);
    }
  }, [cartsData]);

  useEffect(() => {
    const calculateProcessing = async () => {
      const subtotal = carts.reduce((a, b) => a + b.OrderTotal, 0);
      // client specific pricing
      const { ShippingMultiplier, Terms } = authState?.user;
      let expedited = 1;
      let urgent = 2;
      if (ShippingMultiplier) {
        const { Expedited, Urgent } = ShippingMultiplier;
        expedited = Expedited;
        urgent = Urgent;
      }
      // find selected processing system code
      const { data: systemCodes } = await client.query({
        query: ALL_SYSTEM_CODES_QUERY,
      });
      const selectedProcessingCode = systemCodes.findManySystemCodes.find(
        (code) => parseInt(code.RecId, 10) === inputs.ProcessingOption
      );

      // caclulate processing amount and totals
      let processing = 0;
      switch (selectedProcessingCode?.CodeId) {
        case "0":
          processing = 0;
          break;
        case "1":
          processing = subtotal * expedited;
          break;
        case "2":
          processing = subtotal * urgent;
          break;
        default:
          break;
      }

      if (!selectedProcessingCode && !inputs.ProcessingOption) {
        const standardProcessingCode = systemCodes.findManySystemCodes.find(
          (code) =>
            code.Category === "Job" &&
            code.CodeDescription === "Standard" &&
            code.CodeId === "0"
        );
        if (standardProcessingCode?.RecId) {
          setInputs((prev) => ({
            ...prev,
            ProcessingOption: parseInt(standardProcessingCode.RecId, 10),
          }));
        }
      }

      let discount = 0;
      if (inputs.CouponCode?.CouponID) {
        const couponType = systemCodes.findManySystemCodes.find(
          (code) => code.RecId === inputs.CouponCode.Type
        );
        if (couponType?.CodeId === "$") {
          discount = Math.min(
            parseFloat(inputs.CouponCode.Discount, 10),
            subtotal + processing
          );
        } else if (couponType?.CodeId === "%") {
          discount = Math.min(
            (parseFloat(inputs.CouponCode.Percent, 10) *
              (subtotal + processing)) /
              100,
            subtotal + processing
          );
        }
      }

      setTotals((prev) => {
        let { usedCredits } = prev;
        if (inputs.credits) {
          const { total } = prev;
          if (total <= creditLayers) {
            usedCredits = total;
          } else if (total > creditLayers) {
            usedCredits = creditLayers;
          }
        } else {
          usedCredits = 0;
        }
        return {
          ...prev,
          subtotal,
          processing,
          total: subtotal + processing - discount,
          discount,
          usedCredits,
        };
      });

      const termsSystemCode = systemCodes.findManySystemCodes.find(
        (code) => parseInt(code.RecId, 10) === Terms
      );
      if (termsSystemCode?.CodeId && !inputs.TermsCode) {
        setInputs((prev) => ({
          ...prev,
          TermsCode: termsSystemCode,
        }));
      }
    };

    calculateProcessing();
  }, [carts, creditLayers, inputs, authState, client]);

  useEffect(() => {
    if (authState?.user?.UserID) {
      getCreditLedgerData({
        variables: {
          where: {
            UserID: { equals: parseInt(authState.user.UserID, 10) },
          },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [authState, getCreditLedgerData]);

  useEffect(() => {
    if (resultCreditLedgerData?.data?.creditLedgers) {
      const totalOfCredit = resultCreditLedgerData.data.creditLedgers.reduce(
        (a, b) => a + b.Credit,
        0
      );
      setCreditLayers(totalOfCredit);
    }
  }, [resultCreditLedgerData]);

  const handleChange = async (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const applyCoupon = async (e) => {
    e.preventDefault();
    if (inputs.CouponCode) {
      try {
        if (typeof inputs.CouponCode === "string") {
          const { data: couponData } = await client.query({
            query: CHECK_COUPON_VALIDITY_QUERY,
            variables: {
              where: {
                Code: { equals: inputs.CouponCode },
              },
            },
          });
          if (couponData?.checkCouponValidity) {
            setInputs((prev) => ({
              ...prev,
              CouponCode: couponData.checkCouponValidity,
            }));
            toast.success("Your discount has been applied");
          } else {
            toast.error("The coupon entered is not valid");
          }
        } else {
          setInputs((prev) => ({
            ...prev,
            CouponCode: "",
          }));
        }
      } catch (err) {
        setInputs((prev) => ({
          ...prev,
          CouponCode: "",
        }));
        toast.error(err.message);
      }
    }
  };

  const handleCartDetailChange = (name, value, id) => {
    if (name === "showDetails") {
      setCarts((prev) =>
        prev.map((cart) =>
          cart.CartID === id
            ? { ...cart, showDetails: value }
            : { ...cart, showDetails: false }
        )
      );
    } else {
      setCarts((prev) =>
        prev.map((cart) =>
          cart.CartID === id ? { ...cart, [name]: value } : cart
        )
      );
    }
  };

  const handleCartDelete = (e, selectedCart) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      const handleDelete = async () => {
        setLoading(true);
        await deleteCart({
          variables: {
            where: {
              CartID: parseInt(selectedCart.CartID, 10),
            },
          },
          refetchQueries: [
            {
              query: ALL_CART_QUERY,
              variables: {
                where: {
                  UserID: { equals: parseInt(authState.user.UserID, 10) },
                },
              },
            },
            {
              query: CART_COUNT_QUERY,
              variables: {
                where: {
                  UserID: parseInt(authState.user.UserID, 10),
                },
              },
            },
            {
              query: GET_CART_SUMMARY_QUERY,
              variables: {
                where: {
                  UserID: { equals: parseInt(authState?.user.UserID, 10) },
                },
                take: 2,
                skip: 0,
              },
              fetchPolicy: "network-only",
            },
          ],
        });
        setLoading(false);
        setModalOpen(false);
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this item?"
          onConfirm={handleDelete}
        />
      );
    } catch (err) {
      toast.error("Error on delete");
    }
  };

  const handleEditItem = (e, cart) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(
      `/new-test/${
        cart.Package && !cart?.Package?.DisplayAsTest
          ? "package-test"
          : "individual-test"
      }/${cart.CartID}`
    );
  };

  const handleCheckout = () => {
    const finishTransaction = async (
      PaymentType,
      paymentInformation,
      { usedCredits }
    ) => {
      setModalOpen(false);
      setLoading(true);
      try {
        const newId = uuidv4();
        await createJob({
          variables: {
            data: {
              TempId: newId,
              UserID: parseInt(authState.user.UserID, 10),
              CouponCode: inputs.CouponCode?.Code || "",
              Shipping: inputs.ProcessingOption,
              BillingAddress: JSON.stringify({
                BillingFirstName: paymentInformation.billToFirstName,
                BillingLastName: paymentInformation.billToLastName,
                BillingCompany: paymentInformation.billToCompany,
                BillingAddress: paymentInformation.billToAddress,
                BillingAddress2: paymentInformation.billToAddress2,
                BillingCity: paymentInformation.billToCity,
                BillingState: paymentInformation.billToState,
                BillingZip: paymentInformation.billToZip,
              }),
              ShippingAddress: JSON.stringify({
                ShippingFirstName: paymentInformation.billToFirstName,
                ShippingLastName: paymentInformation.billToLastName,
                ShippingCompany: paymentInformation.billToCompany,
                ShippingAddress: paymentInformation.billToAddress,
                ShippingAddress2: paymentInformation.billToAddress2,
                ShippingCity: paymentInformation.billToCity,
                ShippingState: paymentInformation.billToState,
                ShippingZip: paymentInformation.billToZip,
              }),
              PaymentType,
              PaymentStatus: PaymentType === "credit" ? 1 : 0,
              PaymentAmount: totals.total - usedCredits,
              Credit: usedCredits,
              ZeroDollarOrder: false,
              LabID: 1,
              ShippingPrice: totals.processing,
              Subtotal: totals.subtotal,
              Net: totals.total,
              PaymentInfo: {
                token: paymentInformation.token,
                total: parseFloat(
                  parseFloat(totals.total - usedCredits)
                    .toFixed(2)
                    .replace(".", "")
                ),
                billToFirstName: paymentInformation.billToFirstName,
                billToLastName: paymentInformation.billToLastName,
                billToCompany: paymentInformation.billToCompany,
                billToAddress: paymentInformation.billToAddress,
                billToCity: paymentInformation.billToCity,
                billToState: paymentInformation.billToState,
                billToZip: paymentInformation.billToZip,
                billToCountry: paymentInformation.billToCountry,
              },
            },
          },
          refetchQueries: [
            {
              query: CART_COUNT_QUERY,
              variables: {
                where: {
                  UserID: parseInt(authState.user.UserID, 10),
                },
              },
            },
            {
              query: ALL_CREDIT_LEDGER_QUERY,
              variables: {
                where: {
                  UserID: { equals: parseInt(authState.user.UserID, 10) },
                },
              },
            },
            {
              query: GET_CART_SUMMARY_QUERY,
              variables: {
                where: {
                  UserID: { equals: parseInt(authState?.user.UserID, 10) },
                },
                take: 2,
                skip: 0,
              },
            },
          ],
        });
        setLoading(false);
        history.push("/cart/confirmation");
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
      }
    };
    setModalOpen(
      true,
      <Payment
        CouponCode={inputs?.CouponCode}
        billDetail={carts}
        checkoutInformation={inputs}
        client={authState.user}
        totals={{ ...totals, creditLayers }}
        onClose={() => setModalOpen(false)}
        onComplete={finishTransaction}
      />
    );
  };

  const getCultivators = (cultivators) => {
    const cultivatorsInfo = cultivators.map(
      (cultivator) => authState?.user?.MMTCInfo?.[cultivator]?.Name || null
    );
    return cultivatorsInfo.filter((cultivator) => !!cultivator).join(", ");
  };

  if (loading || resLoading) return <Loader />;

  return (
    <div className="new-test-list-page">
      {!authState?.user?.isPastArizonaOrderTransitionDate ? (
        <PageHeader title="Cart">
          <Button
            color="primary"
            onClick={() => history.push("/new-test/individual-test")}
          >
            Add Test
          </Button>
        </PageHeader>
      ) : null}

      {carts?.length > 0 &&
      !authState?.user?.isPastArizonaOrderTransitionDate ? (
        <div className="cart-grid">
          <div className="cart-details">
            {carts.map((cart) => (
              <Generic
                key={cart.CartID}
                className={
                  cart.showDetails ? "cart-item cart-item-opened" : "cart-item"
                }
                onClick={() =>
                  handleCartDetailChange(
                    "showDetails",
                    !cart.showDetails,
                    cart.CartID
                  )
                }
              >
                <Generic className="cart-item-header">
                  <Icon
                    color="primary"
                    role="button"
                    onClick={(e) => handleCartDelete(e, cart)}
                  >
                    <FontAwesomeIcon icon="times" />
                  </Icon>
                  <Title size={6}>{cart.OrderName}</Title>
                  <h2 size={4}>${cart.OrderTotal}</h2>
                  <Icon>
                    <FontAwesomeIcon
                      icon={cart.showDetails ? "chevron-up" : "chevron-down"}
                    />
                  </Icon>
                  <Icon role="button" onClick={(e) => handleEditItem(e, cart)}>
                    <FontAwesomeIcon icon="edit" />
                  </Icon>
                </Generic>
                <div className={cart.showDetails ? "show" : ""}>
                  <div className="cart-detailed-information">
                    {cart.BatchOrderNumber ? (
                      <small>
                        Retail Batch # <b>{cart.BatchOrderNumber}</b>
                      </small>
                    ) : null}
                    {cart.LotID ? (
                      <small>
                        Seed-to-Sale # <b>{cart.LotID}</b>
                      </small>
                    ) : null}
                    {cart.CustomerSampleID ? (
                      <small>
                        Harvest/Lot ID <b>{cart.CustomerSampleID}</b>
                      </small>
                    ) : null}
                    {cart.BatchInfo?.ID ? (
                      <small>
                        Batch ID <b>{cart.BatchInfo.ID}</b>
                      </small>
                    ) : null}
                    {cart.BatchInfo?.Date ? (
                      <small>
                        Batch Date <b>{cart.BatchInfo.Date}</b>
                      </small>
                    ) : null}
                    {cart.BatchInfo?.ExpDate ? (
                      <small>
                        Expiration Date <b>{cart.BatchInfo.ExpDate}</b>
                      </small>
                    ) : null}
                    {cart.BatchInfo?.Size ? (
                      <small>
                        Size <b>{cart.BatchInfo.Size}</b>
                      </small>
                    ) : null}
                    {cart.BatchInfo?.Qty ? (
                      <small>
                        Total Quantity <b>{cart.BatchInfo.Qty}</b>
                      </small>
                    ) : null}
                    {cart.MMTCInfo?.CultivationFacility ? (
                      <small>
                        Cultivation Facility{" "}
                        <b>{cart.MMTCInfo.CultivationFacility}</b>
                      </small>
                    ) : null}
                    {cart.MMTCInfo?.ProcessingFacility ? (
                      <small>
                        Processing Facility{" "}
                        <b>{cart.MMTCInfo.ProcessingFacility}</b>
                      </small>
                    ) : null}
                    {cart.MMTCInfo?.Cultivators?.length ? (
                      <small>
                        Cultivars{" "}
                        <b>{getCultivators(cart.MMTCInfo.Cultivators)}</b>
                      </small>
                    ) : null}
                  </div>
                </div>
                <div className={cart.showDetails ? "show" : ""}>
                  <ul style={{ listStyle: "square", padding: "0 1.5rem" }}>
                    {cart.TestItems.map((testItem) => {
                      const repeatedTests = getRepeatedTestsCount(
                        cart,
                        testItem.TestID
                      );
                      return (
                        <li key={`test-${testItem.TestID}-${cart.CartID}`}>
                          {`${testItem.Name} ${
                            repeatedTests > 1 ? `(x${repeatedTests})` : ""
                          }`}
                        </li>
                      );
                    })}
                    {cart?.Package &&
                      cart.Package.PackageTests.map((packageTest) => (
                        <li
                          key={`pkg-${packageTest.PackageTestID}-${cart.CartID}`}
                        >
                          {packageTest.Test.Name}
                        </li>
                      ))}
                  </ul>
                </div>
              </Generic>
            ))}
          </div>
          <div style={{ padding: 10 }}>
            <CartCheckoutForm
              applyCoupon={applyCoupon}
              creditLayers={creditLayers}
              hasMicro={hasMicro}
              inputs={inputs}
              totals={totals}
              user={authState?.user}
              onChange={handleChange}
              onCheckout={handleCheckout}
            />
          </div>
        </div>
      ) : (
        <Column.Group centered>
          <Column size={10}>
            <div className="confirmation-container">
              {!authState?.user?.isPastArizonaOrderTransitionDate ? (
                <React.Fragment>
                  <Title style={{ textAlign: "center" }}>
                    You do not have items on your cart. Please add some
                  </Title>
                  <div className="button-container">
                    <Button
                      color="primary"
                      onClick={() => {
                        history.push("/new-test/individual-test");
                      }}
                    >
                      Add New Test
                    </Button>
                  </div>
                </React.Fragment>
              ) : (
                <p
                  style={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  All new orders can be placed through the Smithers CTS Arizona
                  Confident Cannabis account{" "}
                  <a
                    href="https://accounts.confidentcannabis.com/login?lab=smithersaz"
                    rel="noreferrer"
                    target="_blank"
                  >
                    here
                  </a>
                  .
                </p>
              )}
            </div>
          </Column>
        </Column.Group>
      )}
    </div>
  );
};

CartPage.propTypes = {};

export default CartPage;
