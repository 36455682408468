import React from "react";
import PropTypes from "prop-types";
import { Field, Input, Label, Control } from "rbx";
import BooleanInput from "../../../../../../components/BooleanInput";

const InputWithMemory = ({
  inputs,
  label,
  name,
  onChange,
  placeholder,
  maxLength,
  disabled,
  expanded,
  children,
  required,
  type,
}) => (
  <Control expanded={expanded} size="small">
    <Label>{label}</Label>
    <Field.Body>
      <Field kind="addons">
        <Control>
          <BooleanInput
            disabled={disabled}
            name={`${name}Changed`}
            value={inputs[`${name}Changed`] || false}
            onChange={(nameChanged, checked) =>
              onChange(nameChanged, checked ? 1 : 0)
            }
          />
        </Control>
        <Control expanded={expanded}>
          <Input
            disabled={!inputs[`${name}Changed`] || false || disabled}
            maxLength={maxLength}
            name={name}
            placeholder={placeholder}
            required={required}
            size="small"
            type={type}
            value={
              (inputs[`${name}Changed`]
                ? inputs.OverrideData[name]
                : inputs[name]) || ""
            }
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
    </Field.Body>
  </Control>
);

InputWithMemory.propTypes = {
  type: PropTypes.string,
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  expanded: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  children: PropTypes.node,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
};

InputWithMemory.defaultProps = {
  disabled: false,
  required: false,
  expanded: true,
  maxLength: 249,
  label: "",
  placeholder: "",
  children: null,
  type: "text",
};

export default InputWithMemory;
