// TERP TOTAL CALCULATIONS

export const TERP_TOTAL_ROWS = (totalTerpenes) => [
  {
    field: "Total Terpenes",
    label: "Total Terpenes",
    displayValue: parseFloat(totalTerpenes)
      ? `${totalTerpenes} %`
      : totalTerpenes || "",
    group: "total",
  },
];

// CANN TOTAL ROWS

export const CANN_TOTAL_ROWS = (
  totalCannabinoidsPerUnit,
  totalCannabinoidsPerPackage,
  allLabelClaim,
  labelClaimAnalytes,
  sampleType
) => {
  const rows = labelClaimAnalytes.reduce((acc, name) => {
    const sampleObj = allLabelClaim.find((lc) => lc.analyte === name);
    const val = sampleObj?.resultLabelClaim;
    const totalPerUnit = sampleObj?.totalPerUnit;
    const totalPerPackage = sampleObj?.totalPerPackage;
    const dislayName = name === "d9-THC" ? "THC" : name;
    return [
      ...acc,
      {
        field: `Total ${name}`,
        label: `Total ${dislayName}`,
        displayValue: parseFloat(val) ? `${val} mg/g` : val || "",
        class: sampleObj ? "" : "black-out",
        group: "total",
      },
      {
        field: `Total ${name} per Serving`,
        label: `Total ${dislayName} per Serving`,
        displayValue: parseFloat(totalPerUnit)
          ? `${totalPerUnit} mg`
          : totalPerUnit || "",
        class: sampleObj ? "" : "black-out",
        group: "total",
      },
      {
        field: `Total ${name} per Package`,
        label: `Total ${dislayName} per Package`,
        displayValue: parseFloat(totalPerPackage)
          ? `${totalPerPackage} mg`
          : totalPerPackage || "",
        class: sampleObj ? sampleObj?.lcClass : "black-out",
        group: "total",
      },
    ];
  }, []);
  return [
    ...rows,
    {
      field: "Total Cannabinoids per Serving",
      label: "Total Cannabinoids per Serving",
      displayValue: parseFloat(totalCannabinoidsPerUnit)
        ? `${totalCannabinoidsPerUnit} mg`
        : totalCannabinoidsPerUnit || "",
      class: sampleType === "QCSample" ? " black-out" : "",
      group: "total",
    },
    {
      field: "Total Cannabinoids per Package",
      label: "Total Cannabinoids per Package",
      displayValue: parseFloat(totalCannabinoidsPerPackage)
        ? `${totalCannabinoidsPerPackage} mg`
        : totalCannabinoidsPerPackage || "",
      class: sampleType === "QCSample" ? " black-out" : "",
      group: "total",
    },
  ];
};
