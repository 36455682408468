import React from "react";
import { Switch, Route } from "react-router-dom";

import ClientOrderListPage from "./ClientOrderListPage";
import ClientOrderTestDetailsPage from "./ClientOrderTestDetailsPage";

const ClientOrderPage = () => (
  <Switch>
    <Route path="/orders/:JobOrderID/tests">
      <ClientOrderTestDetailsPage />
    </Route>
    <Route path="/orders">
      <ClientOrderListPage />
    </Route>
  </Switch>
);

export default ClientOrderPage;
