import { gql } from "@apollo/client";

export const ALL_EQUIPMENT_QUERY = gql`
  query ALL_EQUIPMENT_QUERY(
    $where: EquipmentWhereInput
    $orderBy: [EquipmentOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyEquipment(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      EquipmentID
      LabID
      Name
      Manufacturer
      SerialNumber
      ModelNumber
      CalibrationCycleID
      LastCalibrationDate
      MaintenanceCycleID
      LastMaintenanceDate
      ImageURL
      CalibrationDueDate
      MaintenanceDueDate
      Active
      SamplePrep
      Lab {
        Company
      }
      CalibrationCycleSystemCode {
        RecId
        CodeDescription
      }
      MaintenanceCycleSystemCode {
        RecId
        CodeDescription
      }
    }
    aggregateEquipment(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const SINGLE_EQUIPMENT_QUERY = gql`
  query SINGLE_EQUIPMENT_QUERY($where: EquipmentWhereUniqueInput!) {
    findUniqueEquipment(where: $where) {
      EquipmentID
      LabID
      Name
      Manufacturer
      SerialNumber
      ModelNumber
      CalibrationCycleID
      LastCalibrationDate
      MaintenanceCycleID
      LastMaintenanceDate
      ImageURL
      CalibrationDueDate
      MaintenanceDueDate
      SamplePrep
      Active
      Lab {
        Company
      }
    }
  }
`;

export const LIST_EQUIPMENT_QUERY = gql`
  query LIST_EQUIPMENT_QUERY(
    $where: EquipmentWhereInput
    $orderBy: [EquipmentOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyEquipment(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      EquipmentID
      Active
      Name
      CalibrationDueDate
      MaintenanceDueDate
      Lab {
        Company
      }
    }
    aggregateEquipment(where: $where) {
      count {
        _all
      }
    }
  }
`;