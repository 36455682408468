const employees = {
  name: "employees",
  label: "Employees",
  icon: "user-md",
  permissions: ["EMP"],
  active: (location) => {
    const [, p] = location.pathname.split("/");
    return /employees/.test(p);
  },
  to: "/employees",
};

export default employees;
