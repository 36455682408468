import { gql } from "@apollo/client";

export const ALL_BATCHES_QUERY = gql`
  query ALL_BATCHES_QUERY(
    $where: BatchesWhereInput
    $orderBy: [BatchesOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyBatches(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      BatchID
      UserID
      TestCategoryID
      Tests
      CreateDate
      Name
      ReadyForLoading
      User {
        Username
      }
      Diluents
      DilutedBy
      LoadedBy
      AnalyzedBy
      SoyLotNumber
      SoyLotExpiration
      SolutionLotNumber
      SolutionLotExpiration
      Note
    }
  }
`;

export const LIST_BATCHES_QUERY = gql`
  query LIST_BATCHES_QUERY(
    $where: BatchesWhereInput
    $orderBy: [BatchesOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyBatches(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      BatchID
      Tests
      JobOrderTestSamples {
        SessionID
        JobOrderTest {
          Test {
            Name
          }
        }
        JobOrder {
          ProductCategorySystemCode {
            CodeDescription
          }
        }
      }
      TestCategory {
        TestCategoryID
        Name
        Code
        Description
      }
    }
    aggregateBatches(where: $where) {
      count {
        BatchID
      }
    }
  }
`;

export const SINGLE_BATCH_QUERY = gql`
  query SINGLE_BATCH_QUERY($where: BatchesWhereUniqueInput!) {
    findUniqueBatches(where: $where) {
      BatchID
      UserID
      TestCategoryID
      LabID
      Tests
      Name
      ReadyForLoading
      Lab {
        LabID
        State
      }
      Lab {
        State
      }
      User {
        Username
        FirstName
        LastName
      }
      TestCategory {
        TestCategoryID
        Name
        Code
        Description
        Analytes {
          AnalyteID
          Name
        }
      }
      CreateDate
      Diluents
      DilutedBy
      LoadedBy
      AnalyzedBy
      SoyLotNumber
      SoyLotExpiration
      SolutionLotNumber
      IncubationStartDateTime
      IncubationStartedBy
      IncubationEndDateTime
      IncubationEndedBy
      Note
      SolutionLotExpiration
      JobOrderTestSamples {
        JobOrderTestSampleID
        SessionID
        JobOrderTest {
          Test {
            Code
          }
        }
      }
      TestSamples {
        QCSample {
          QCSampleID
          SessionID
          CreatedDateTime
          Result
          Qualifiers
          SystemQualifiers
          TargetValues
          IncludesFailedResult
          ReviewedBy
          ResultStatus {
            RecId
            CodeId
            CodeDescription
          }
          QCSampleType {
            Name
          }
        }
        JobOrderTestSample {
          JobOrderTestSampleID
          CreatedDateTime
          SessionID
          Result
          Qualifiers
          SystemQualifiers
          IncludesFailedResult
          ModifiedBy
          ReviewedBy
          JobOrderTest {
            JobOrderTestID
            TestID
            Test {
              TestID
              Name
              Code
              TestAnalytes {
                AnalyteID
                Analyte {
                  Active
                  TestCategoryID
                  Name
                }
              }
            }
          }
          JobOrder {
            JobOrderID
            NetWeight
            NetWeightUnit
            DisplayUnit
            LabelClaim
            SubstanceType
            Moisture
            OverrideDataCodeDescriptions
            Category {
              CodeDescription
            }
            Substance
            OverrideData
            ActualLabelClaims {
              ActualLabelClaimID
              LabelClaim
              Analyte {
                AnalyteID
                Name
              }
            }
          }
          Job {
            JobID
            User {
              Username
            }
            Lab {
              State
            }
          }
          ResultStatus {
            RecId
            CodeId
            CodeDescription
          }
        }
        PrimarySampleSessionID
        OrderName
        matrixSpike
        sampleDuplicate
        Weight
        Volume
        Dilution
        ModifiedBy
        ActionLimitTemplate {
          TemplateID
          TemplateName
          ActionLimitTemplateAnalytes {
            TemplateID
            AnalyteID
            ActionLevel
            LOQ
            LOD
            InSampleLOD
            InSampleLOQ
            AllowableCriteria
            UpperLimit
          }
        }
        TestCategory {
          TestCategoryID
          Code
          Analytes {
            AnalyteID
            Name
            Active
          }
        }
        QCTarget {
          TargetValues
        }
        JobOrderTest {
          JobOrderTestID
          TestID
          Test {
            TestID
            Name
            TestAnalytes {
              AnalyteID
            }
          }
        }
        JobOrder {
          JobOrderID
          OverrideDataCodeDescriptions
          Substance
          Category {
            CodeDescription
          }
        }
      }
      BatchStatus {
        RecId
        Category
        CodeName
        CodeId
        CodeDescription
      }
    }
  }
`;

export const CHECK_SESSION_ID_QUERY = gql`
  query CHECK_SESSION_ID_QUERY(
    $where: JobOrderTestSamplesWhereInput!
    $TestCategory: TestCategoriesWhereUniqueInput!
    $Lab: LabsWhereUniqueInput!
  ) {
    checkIfSessionIdExists(
      where: $where
      TestCategory: $TestCategory
      Lab: $Lab
    ) {
      JobOrderTestSampleID
      JobOrderTestID
      JobOrderID
      JobID
      SessionID
      JobOrder {
        OrderName
      }
      JobOrderTest {
        Test {
          Code
          Name
          TestAnalytes {
            Analyte {
              Active
              Name
              TestCategoryID
            }
          }
        }
      }
    }
  }
`;

export const CUSTOM_LIST_BATCHES_QUERY = gql`
  query CUSTOM_LIST_BATCHES_QUERY(
    $where: BatchCriteriaWhereInput
    $take: Int!
    $skip: Int!
  ) {
    findManyBatchesExtended(where: $where, take: $take, skip: $skip) {
      BatchID
      Name
      CreateDate
      ReadyForLoading
      User {
        UserID
        Username
      }
      TestCategory {
        TestCategoryID
        Code
      }
      Lab {
        Company
      }
      BatchStatus {
        RecId
        Category
        CodeName
        CodeId
        CodeDescription
      }
      JobOrderTestSamples {
        JobOrderTestSampleID
      }
    }
    aggregateBatchesExtended(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const GENERATE_BATCH_SUMMARY_PDF_QUERY = gql`
  query GENERATE_BATCH_SUMMARY_PDF_QUERY(
    $where: BatchesWhereUniqueInput!
    $data: BatchSummaryInput
  ) {
    generateBatchSummaryPDF(where: $where, data: $data)
  }
`;

export const SINGLE_BATCH_WITH_ANALYTES_QUERY = gql`
  query SINGLE_BATCH_WITH_ANALYTES_QUERY($where: BatchesWhereUniqueInput!) {
    findUniqueBatches(where: $where) {
      BatchID
      UserID
      TestCategoryID
      CreateDate
      Name
      JobOrderTestSamples {
        JobOrderTestSampleID
        Qualifiers
      }
      TestCategory {
        TestCategoryID
        Name
        Code
        Description
        Analytes {
          AnalyteID
          TestCategoryID
          Name
          Active
        }
      }
    }
  }
`;

export const ALL_BATCHES_EXTENDED_QUERY = gql`
  query ALL_BATCHES_EXTENDED_QUERY($where: BatchCriteriaWhereInput) {
    findAllBatchesExtended(where: $where) {
      BatchID
      Tests
      LabID
      JobOrderTestSamples {
        SessionID
        JobOrderTest {
          Test {
            Name
          }
        }
        JobOrder {
          ProductCategorySystemCode {
            CodeDescription
          }
        }
      }
      TestCategory {
        TestCategoryID
        Name
        Code
        Description
      }
    }
  }
`;
