import React, { useState } from "react";
import PropTypes from "prop-types";
import { Control, Select, Button } from "rbx";

const RePrepQtySelect = ({ handleClickReprep, row, disabled }) => {
  const [reprepQty, setReprepQty] = useState(1);
  const handleQtyChange = (val) => {
    setReprepQty(parseInt(val, 10));
  };
  return (
    <Control id="reprep-btn-group" size="small">
      <Button
        color="primary"
        disabled={disabled}
        size="small"
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          handleClickReprep(row.original, reprepQty);
        }}
      >
        <span>Create Replicate</span>
      </Button>
      <Select.Container size="small">
        <Select
          disabled={disabled}
          name="qty"
          value={reprepQty}
          onChange={(e) => {
            e.stopPropagation();
            handleQtyChange(e.target.value);
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {[...Array(10).keys()].map((x) => (
            <Select.Option key={x + 1} value={x + 1}>
              {x + 1}
            </Select.Option>
          ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

RePrepQtySelect.propTypes = {
  handleClickReprep: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

RePrepQtySelect.defaultProps = {
  disabled: false,
};

export default RePrepQtySelect;
