import React from "react";
import PropTypes from "prop-types";
import { Fieldset } from "rbx";
import ClientForm from "../../components/ClientForm";

const AccountDetails = ({
  inputs,
  handleChange,
  handleSave,
  uploadProfilePicPath,
  userID,
  disabled,
  parentClientAccountType,
}) => (
  <Fieldset>
    <ClientForm
      disabled={disabled}
      formId="edit-client-form"
      inputs={inputs}
      parentClientAccountType={parentClientAccountType}
      uploadProfilePicPath={uploadProfilePicPath}
      userID={userID}
      onChange={handleChange}
      onSubmit={handleSave}
    />
  </Fieldset>
);

AccountDetails.propTypes = {
  inputs: PropTypes.object,
  handleChange: PropTypes.func,
  handleSave: PropTypes.func,
  uploadProfilePicPath: PropTypes.string.isRequired,
  userID: PropTypes.string,
  disabled: PropTypes.bool,
  parentClientAccountType: PropTypes.object,
};

AccountDetails.defaultProps = {
  inputs: {},
  handleChange: () => null,
  handleSave: () => null,
  userID: "",
  disabled: false,
  parentClientAccountType: {},
};

export default AccountDetails;
