/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "@apollo/client";

import { customToast as toast, labelBuilder } from "../../../../../utils";
import {
  ALL_JOB_ORDERS_QUERY,
  ALL_JOB_ORDER_TESTS_QUERY,
  ALL_TESTS_QUERY,
  ALL_JOB_ORDER_TEST_SAMPLES_QUERY,
} from "../../../../../graphql";
import "./LabelDisplayPage.scss";
import { productCategoryMatrix } from "../../../../../constants";

const LabelDisplayPage = () => {
  const { id, repeat } = useParams();

  const [jobOrdersData, setJobOrdersData] = useState([]);
  const [jobOrderTestsData, setJobOrderTestsData] = useState([]);
  const [jobOrderTestSamplesData, setJobOrderTestSamplesData] = useState([]);

  const client = useApolloClient();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const [fieldName, valueId] = id.split("-");
        try {
          if (
            fieldName === "JobID" ||
            fieldName === "JobOrderID" ||
            fieldName === "ManyJobOrderID"
          ) {
            const options = {
              [fieldName === "ManyJobOrderID" ? ["JobOrderID"] : fieldName]: {
                equals: parseInt(valueId, 10),
              },
            };
            const { data: foundJobOrdersData } = await client.query({
              query: ALL_JOB_ORDERS_QUERY,
              variables: {
                where: options,
              },
            });

            if (
              Array.isArray(foundJobOrdersData?.findManyJobOrders) &&
              foundJobOrdersData?.findManyJobOrders.length
            ) {
              setJobOrdersData(foundJobOrdersData.findManyJobOrders);
            }

            const { data: foundJobOrdersTestsData } = await client.query({
              query: ALL_JOB_ORDER_TESTS_QUERY,
              variables: {
                where: options,
              },
            });

            if (
              Array.isArray(foundJobOrdersTestsData?.findManyJobOrderTests) &&
              foundJobOrdersData?.findManyJobOrders.length
            ) {
              const { data: foundTestsData } = await client.query({
                query: ALL_TESTS_QUERY,
                variables: {
                  where: {},
                },
              });
              const newJobOrderData =
                foundJobOrdersTestsData.findManyJobOrderTests.map(
                  (orderTest) => {
                    const { TestID, JobOrder, Batch } = orderTest;
                    const Test = foundTestsData.findManyTests.find(
                      (currentTest) => currentTest.TestID === TestID
                    );
                    return {
                      ...orderTest,
                      Test,
                      State: JobOrder?.Job?.Lab?.State,
                      TestCategory: Batch?.TestCategory?.Code,
                      ProductCategory:
                        JobOrder?.ProductCategorySystemCode?.CodeDescription,
                    };
                  }
                );

              setJobOrderTestsData(newJobOrderData);
            }

            if (["JobID", "JobOrderID", "ManyJobOrderID"].includes(fieldName)) {
              const { data: reprepJobOrderTestSamplesData } =
                await client.query({
                  query: ALL_JOB_ORDER_TEST_SAMPLES_QUERY,
                  variables: {
                    where: {
                      ...options,
                      PrepNo: {
                        gt: 1,
                      },
                      SessionID: {
                        contains: "r",
                      },
                    },
                  },
                });
              if (
                Array.isArray(
                  reprepJobOrderTestSamplesData?.findManyJobOrderTestSamples
                )
              ) {
                setJobOrderTestSamplesData(
                  reprepJobOrderTestSamplesData?.findManyJobOrderTestSamples
                );
              }
            }
          } else if (fieldName === "JobOrderTestID") {
            const { data: foundJobOrdersTestsData } = await client.query({
              query: ALL_JOB_ORDER_TESTS_QUERY,
              variables: {
                where: {
                  [fieldName]: {
                    equals: parseInt(valueId, 10),
                  },
                },
              },
            });

            if (Array.isArray(foundJobOrdersTestsData?.findManyJobOrderTests)) {
              const { data: foundTestsData } = await client.query({
                query: ALL_TESTS_QUERY,
                variables: {
                  where: {},
                },
              });
              const newJobOrderData =
                foundJobOrdersTestsData.findManyJobOrderTests.map(
                  (orderTest) => {
                    const { TestID } = orderTest;
                    const Test = foundTestsData.findManyTests.find(
                      (currentTest) => currentTest.TestID === TestID
                    );
                    return { ...orderTest, Test };
                  }
                );

              setJobOrderTestsData(newJobOrderData);
            }
            const { data: reprepJobOrderTestSamplesData } = await client.query({
              query: ALL_JOB_ORDER_TEST_SAMPLES_QUERY,
              variables: {
                where: {
                  [fieldName]: {
                    equals: parseInt(valueId, 10),
                  },
                  PrepNo: {
                    gt: 1,
                  },
                  SessionID: {
                    contains: "r",
                  },
                },
              },
            });
            if (
              Array.isArray(
                reprepJobOrderTestSamplesData?.findManyJobOrderTestSamples
              )
            ) {
              setJobOrderTestSamplesData(
                reprepJobOrderTestSamplesData?.findManyJobOrderTestSamples
              );
            }
          } else if (fieldName === "JobOrderTestSampleID") {
            const { data: foundJobOrdersTestSamplesData } = await client.query({
              query: ALL_JOB_ORDER_TEST_SAMPLES_QUERY,
              variables: {
                where: {
                  [fieldName]: {
                    equals: parseInt(valueId, 10),
                  },
                },
              },
            });
            if (
              Array.isArray(
                foundJobOrdersTestSamplesData?.findManyJobOrderTestSamples
              )
            ) {
              setJobOrderTestSamplesData(
                foundJobOrdersTestSamplesData?.findManyJobOrderTestSamples
              );
            }
          }
        } catch (err) {
          toast.error("Error. Please refresh your page");
        }
      }
    };
    fetchData();
  }, [client, id]);

  const getCounterInformation = (
    Code,
    State,
    ProductCategory,
    TestCategory
  ) => {
    const isMicro = TestCategory?.includes("MICRO");
    const orderMatrix = Object.keys(productCategoryMatrix).find((key) =>
      productCategoryMatrix[key].includes(ProductCategory)
    );
    let counter = 1;
    if (State === "FL" && !isMicro) {
      switch (Code) {
        case "TYMC":
        case "ASPE2":
        case "SALM":
        case "PECO":
        case "LIST":
        case "SMIC":
        case "FMIC":
        case "ENTE":
        case "COLI":
        case "TABC":
        case "STAT":
        case "SECO":
        case "CANN": {
          counter = 2;
          break;
        }
        case "CANNA":
        case "TERP":
        case "GCPEST": {
          counter = 3;
          break;
        }
        case "LCPEST": {
          counter = orderMatrix && orderMatrix === "flower" ? 3 : 2;
          break;
        }
        case "LCPESTTOX": {
          counter = 5;
          break;
        }
        default:
          counter = 1;
      }
    } else if (!isMicro) {
      switch (Code) {
        case "TYMC":
        case "ASPE2":
        case "SALM":
        case "PECO":
        case "LIST":
        case "SMIC":
        case "FMIC":
        case "ENTE":
        case "COLI":
        case "TABC":
        case "STAT":
        case "SECO": {
          counter = 2;
          break;
        }
        case "CANN":
        case "CANNA":
        case "TERP": {
          counter = 3;
          break;
        }
        case "GCPEST": {
          counter = 4;
          break;
        }
        case "LCPEST":
        case "LCPESTTOX": {
          counter = 5;
          break;
        }
        default:
          counter = 1;
      }
    }
    return counter;
  };

  const renderLabels = () => {
    let codes = [];
    if (jobOrdersData.length) {
      const jobOrderCodes = jobOrdersData.map(
        ({ JobID, JobOrderID, OrderName }) =>
          labelBuilder(JobOrderID, [JobID, JobOrderID, OrderName])
      );
      codes = jobOrderCodes;
    }

    if (jobOrderTestsData.length && !id.includes("ManyJobOrderID")) {
      const jobOrderTestCodes = jobOrderTestsData.map(
        ({
          JobID,
          JobOrderID,
          JobOrderTestID,
          Test: { Code },
          State,
          ProductCategory,
          TestCategory,
        }) => {
          const counter = getCounterInformation(
            Code,
            State,
            ProductCategory,
            TestCategory
          );
          if (counter === 1) {
            return [
              labelBuilder(`${JobID}_${JobOrderID}_${JobOrderTestID}`, [
                JobOrderID,
                JobOrderTestID,
                Code,
              ]),
            ];
          }
          const labels = [];
          for (let i = 0; i < counter; i++) {
            labels.push(
              labelBuilder(`${JobID}_${JobOrderID}_${JobOrderTestID}`, [
                JobOrderID,
                JobOrderTestID,
                Code,
              ])
            );
          }
          return [...labels];
        }
      );
      codes = [...codes, ...jobOrderTestCodes];
    }

    if (jobOrderTestSamplesData.length) {
      const jobOrderTestSampleCodes = jobOrderTestSamplesData.map(
        ({
          SessionID,
          JobOrderID,
          JobOrderTestID,
          JobOrderTest: { Test, Batch },
          PrepNo,
          JobOrder,
        }) => {
          const labState = JobOrder?.Job?.Lab?.State;
          const productCategory =
            JobOrder?.ProductCategorySystemCode?.CodeDescription;
          const testCategory = Batch?.TestCategory?.Code;

          const counter = getCounterInformation(
            Test.Code,
            labState,
            productCategory,
            testCategory
          );
          const testIdWithPrepNo =
            PrepNo === 1 ? JobOrderTestID : `${JobOrderTestID}_r${PrepNo - 1}`;
          if (counter === 1) {
            return [
              labelBuilder(SessionID, [
                JobOrderID,
                testIdWithPrepNo,
                Test.Code,
              ]),
            ];
          }
          const labels = [];
          for (let i = 0; i < counter; i++) {
            labels.push(
              labelBuilder(SessionID, [JobOrderID, testIdWithPrepNo, Test.Code])
            );
          }
          return [...labels];
        }
      );
      codes = [...codes, ...jobOrderTestSampleCodes];
    }

    return (
      <div
        className={`label-repeat-grid ${
          id.includes("ManyJobOrderID") ? "vertical" : "horizontal"
        }`}
      >
        {Array.from({ length: repeat ? parseInt(repeat, 10) : 1 }).map(() => (
          <div key={Math.random() * 200} className="item">
            {codes}
          </div>
        ))}
      </div>
    );
  };

  return <div className="labels-wrapper">{renderLabels()}</div>;
};

export default LabelDisplayPage;
