import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { Box, Image } from "rbx";

import { Loader, PageHeader } from "../../../components";
import { SINGLE_JOB_ORDERS_QUERY, ALL_TESTS_QUERY } from "../../../graphql";
import { customToast as toast, formatISODate } from "../../../utils";
import "./ClientOrderTestDetailsPage.scss";

const ClientOrderTestDetailsPage = () => {
  const { JobOrderID } = useParams();

  const client = useApolloClient();

  const [loading, setLoading] = useState(false);
  const [jobOrderData, setJobOrderData] = useState({});
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data: foundJobOrderData } = await client.query({
          query: SINGLE_JOB_ORDERS_QUERY,
          variables: {
            where: {
              JobOrderID: parseInt(JobOrderID, 10),
            },
          },
        });

        if (foundJobOrderData?.findUniqueJobOrders?.JobOrderTests.length) {
          setJobOrderData(foundJobOrderData.findUniqueJobOrders);
          const tests = await client.query({
            query: ALL_TESTS_QUERY,
          });

          const jobOrderTestsArr =
            foundJobOrderData.findUniqueJobOrders.JobOrderTests.map(
              (currentJobOrderTest) => {
                const testInfo = tests.data.findManyTests.find(
                  (test) => test.TestID === currentJobOrderTest.TestID
                );
                return {
                  Name: testInfo.Name,
                  TestID: testInfo.TestID,
                  TestStatus: currentJobOrderTest.TestStatus,
                };
              }
            );

          setData(jobOrderTestsArr);
        }
      } catch (err) {
        toast.error("Error. Please refresh your page");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [client, JobOrderID]);

  const renderTests = () =>
    data.map((test) => (
      <div className="individual-test-wrapper">
        <Image.Container size={128}>
          <Image
            src="/product-icon.png"
            style={{ height: "100%", width: "auto" }}
          />
        </Image.Container>
        <div className="individual-test-content-wrapper">
          <p>Name: {test.Name}</p>
          <p>ID: {test.TestID}</p>
        </div>
      </div>
    ));

  if (loading) return <Loader />;

  return (
    <div>
      <PageHeader title="Individual Test Statuses" />
      {!!Object.keys(jobOrderData) && data.length && (
        <Box>
          <div className="title-wrapper">
            <h1>Order # {jobOrderData.JobOrderID}</h1>
            <h2>Job # {jobOrderData.JobID}</h2>
          </div>
          <hr />
          <div className="subtitle-wrapper">
            <p>Order placed on {formatISODate(jobOrderData.OrderCreateDate)}</p>
            <p>Order name: {jobOrderData.OrderName}</p>
          </div>
          <hr />
          <div className="tests-wrapper">{renderTests()}</div>
        </Box>
      )}
    </div>
  );
};

export default ClientOrderTestDetailsPage;
