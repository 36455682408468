import { gql } from "@apollo/client";

export const LIST_LABS_QUERY = gql`
  query LIST_LABS_QUERY(
    $where: LabsWhereInput
    $orderBy: [LabsOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyLabs(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      LabID
      Company
      CorporateCustomerServiceEmail
      OnsiteCustomerServiceEmail
      Address
      City
      State
      Zip
      ShowQualifiers
    }
    aggregateLabs(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const ALL_LABS_QUERY = gql`
  query ALL_LABS_QUERY(
    $where: LabsWhereInput
    $orderBy: [LabsOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyLabs(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      LabID
      Company
      ProfilePic
      FirstName
      LastName
      Phone
      Status
      ShowQualifiers
      State
    }
  }
`;

export const SINGLE_LAB_QUERY = gql`
  query SINGLE_LAB_QUERY($where: LabsWhereUniqueInput!) {
    findUniqueLabs(where: $where) {
      LabID
      Company
      ProfilePic
      FirstName
      ContactEmail
      NotificationEmail
      SupportEmail
      CorporateCustomerServiceEmail
      OnsiteCustomerServiceEmail
      Address
      LastName
      Address2
      City
      State
      Zip
      BillingAddress
      ShippingAddress
      TimeZone
      Tests
      PaymentOptions
      API
      Phone
      Status
      ShowQualifiers
      LabDirectorOneName
      LabDirectorTwoTitle
      LabDirectorTwoSignature
      LabDirectorTwoName
      LabDirectorOneTitle
      LabDirectorOneSignature
      LicenseNumber
    }
  }
`;
