import React, { useState, useEffect } from "react";
import {
  Column,
  Field,
  Input,
  Label,
  Textarea,
  Box,
  Button,
  Control,
} from "rbx";
import { useMutation } from "@apollo/client";
import { useParams, useHistory } from "react-router-dom";
import { useAuth } from "../../../context";
import { SEND_CONTACT_JOB_ORDER_EMAIL_MUTATION } from "../../../graphql";
import { PageHeader } from "../../../components";
import { customToast as toast } from "../../../utils";

const INITIAL_STATE = {
  Names: "",
  Email: "",
  Subject: "",
  Message: "",
  LabName: "",
};

const ContactFormPage = () => {
  const history = useHistory();
  const { state: authState } = useAuth();
  const { JobID, JobOrderID } = useParams();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [sendContactEmail] = useMutation(SEND_CONTACT_JOB_ORDER_EMAIL_MUTATION);

  useEffect(() => {
    if (authState?.user) {
      setInputs((prev) => ({
        ...prev,
        Names: `${authState.user.FirstName} ${authState.user.LastName}`,
        Email: authState.user.Email,
        LabName: authState.user.LabName,
      }));
    }
  }, [authState]);

  useEffect(() => {
    if (JobID && JobOrderID) {
      setInputs((prev) => ({
        ...prev,
        Subject: `Job #${JobID} Order #${JobOrderID}`,
      }));
    }
  }, [JobID, JobOrderID]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const { data: emailResult } = await sendContactEmail({
        variables: {
          data: {
            subject: inputs.Subject,
            message: inputs.Message,
            name: inputs.Names,
            email: inputs.Email,
            labName: inputs.LabName,
            labState: authState.user.LabState,
          },
        },
      });
      if (emailResult?.sendJobOrderEmailInformation) {
        toast.success("Email sent successfully");
        history.push("/");
      } else {
        throw new Error("Error sending email");
      }
    } catch (err) {
      toast.error("Error sending email");
    }
  };

  return (
    <React.Fragment>
      <PageHeader title="Contact Green Scientific Labs">
        <Button color="primary" form="contact-form" type="submit">
          Send
        </Button>
      </PageHeader>
      <Box>
        <form
          className="column-group"
          id="contact-form"
          onSubmit={handleSubmit}
        >
          <Column.Group multiline>
            <Column size={12}>
              <Field kind="group">
                <Control expanded size="normal">
                  <Label>From</Label>
                  <Input
                    disabled
                    maxLength={250}
                    name="Names"
                    type="text"
                    value={inputs.Names}
                  />
                </Control>
              </Field>
            </Column>
            <Column size={12}>
              <Field horizontal kind="group">
                <Control expanded size="normal">
                  <Label>Email</Label>
                  <Input
                    disabled
                    required
                    maxLength={250}
                    name="Email"
                    type="text"
                    value={inputs.Email}
                  />
                </Control>
              </Field>
            </Column>
            <Column size={12}>
              <Field kind="group">
                <Control expanded size="normal">
                  <Label>Subject</Label>
                  <Input
                    required
                    maxLength={250}
                    name="Subject"
                    size="small"
                    type="text"
                    value={inputs.Subject}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                </Control>
              </Field>
            </Column>
            <Column size={12}>
              <Field kind="group">
                <Control expanded size="normal">
                  <Label>Message</Label>
                  <Textarea
                    required
                    name="Message"
                    size="normal"
                    value={inputs.Message}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                </Control>
              </Field>
            </Column>
          </Column.Group>
        </form>
      </Box>
    </React.Fragment>
  );
};

export default ContactFormPage;
