import { gql } from "@apollo/client";

export const UPDATE_BATCH_MUTATION = gql`
  mutation UPDATE_BATCH_MUTATION(
    $data: BatchesUpdateInput!
    $where: BatchesWhereUniqueInput!
  ) {
    updateBatches(data: $data, where: $where) {
      BatchID
    }
  }
`;

export const CREATE_BATCH_MUTATION = gql`
  mutation CREATE_BATCH_MUTATION($data: BatchesCreateInput!) {
    createBatches(data: $data) {
      BatchID
    }
  }
`;

export const DELETE_BATCH_MUTATION = gql`
  mutation DELETE_BATCH_MUTATION($where: BatchesWhereUniqueInput!) {
    deleteBatches(where: $where) {
      BatchID
    }
  }
`;

export const UPDATE_SAMPLE_SYSTEM_QUALIFIERS_MUTATION = gql`
  mutation UPDATE_SAMPLE_SYSTEM_QUALIFIERS_MUTATION(
    $data: BatchQualifiersInput!
  ) {
    updateSampleSystemQualifiers(data: $data)
  }
`;

export const UPDATE_MANY_JOB_ORDER_SAMPLE_MUTATION = gql`
  mutation UPDATE_MANY_JOB_ORDER_SAMPLE_MUTATION(
    $data: JobOrderTestSamplesUpdateManyMutationInput!
    $where: [String!]!
  ) {
    updateManyJobOrderTestSampleQualifiers(data: $data, where: $where)
  }
`;