import React from "react";
import PropTypes from "prop-types";
import { Field, Label, Control, Radio, Fieldset } from "rbx";
import BooleanInput from "../../../../../../components/BooleanInput";

import "./RadioWithMemory.scss";

const RadioWithMemory = ({
  inputs,
  label,
  name,
  onChange,
  disabled,
  expanded,
  options,
  children,
}) => (
  <React.Fragment>
    <Control className="radio-with-memory" expanded={expanded} size="small">
      <Label>{label}</Label>
      <Field.Body>
        <Field kind="addons">
          <Control>
            <BooleanInput
              disabled={disabled}
              name={`${name}Changed`}
              value={inputs[`${name}Changed`] || false}
              onChange={(nameChanged, checked) =>
                onChange(nameChanged, checked ? 1 : 0)
              }
            />
          </Control>
          <Control>
            {options.map((option) => (
              <Label key={option.value}>
                <Radio
                  defaultChecked={
                    (inputs[`${name}Changed`] &&
                      inputs.OverrideData[name] === option.value) ||
                    inputs[name] === option.value
                  }
                  disabled={!inputs[`${name}Changed`] || false || disabled}
                  name={name}
                  value={option.value}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                />
                &nbsp;
                {option.label}
              </Label>
            ))}
          </Control>
        </Field>
      </Field.Body>
    </Control>
    <Fieldset disabled={!inputs[`${name}Changed`] || false}>
      {children}
    </Fieldset>
  </React.Fragment>
);

RadioWithMemory.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  expanded: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  children: PropTypes.node,
};

RadioWithMemory.defaultProps = {
  disabled: false,
  expanded: true,
  label: "",
  children: null,
};

export default RadioWithMemory;
