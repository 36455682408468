import React, { useMemo } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

import {
  ALL_SAMPLE_TRACKINGS_QUERY,
  DELETE_SAMPLE_TRACKING_MUTATION,
} from "../../../../../graphql";
import { PageHeader, Confirmation, DataTable } from "../../../../../components";
import { useModal } from "../../../../../context/ModalContext";
import SampleTrackingModal from "../components/SampleTrackingModal";
import { customToast as toast } from "../../../../../utils";
import { generateColumns } from "./columns";

const SampleTrackingListPage = () => {
  const history = useHistory();
  const where = {};
  const [deleteSampleTracking] = useMutation(DELETE_SAMPLE_TRACKING_MUTATION);
  const orderBy = [{ id: "Name", desc: false }];
  const { setModalOpen } = useModal();

  const handleRowClick = (sampleTrackingItem) => {
    history.push(
      `/lims/sample-tracking/${sampleTrackingItem.SampleTrackingID}`
    );
  };

  const handleCreateSampleTracking = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push("/lims/sample-tracking");
    setModalOpen(
      true,
      <SampleTrackingModal
        onComplete={() => {
          history.push("/lims/sample-tracking");
          setModalOpen(false);
        }}
      />
    );
  };

  const COLUMNS = useMemo(() => {
    const handleDelete = (sampleTracking) => {
      if (sampleTracking.JobOrders.length === 0) {
        const performDelete = async () => {
          try {
            setModalOpen(false, "");
            await deleteSampleTracking({
              variables: {
                where: {
                  SampleTrackingID: parseInt(
                    sampleTracking.SampleTrackingID,
                    10
                  ),
                },
              },
              refetchQueries: [
                {
                  query: ALL_SAMPLE_TRACKINGS_QUERY,
                  variables: {
                    where: {},
                  },
                },
              ],
            });
            toast.success("Sample Tracking deleted successfully.");
          } catch (err) {
            toast.error(`Error deleting Sample Tracking.`);
          }
        };
        setModalOpen(
          true,
          <Confirmation
            message="Are you sure you want to delete this Sample Tracking?"
            onCancel={() => setModalOpen(false, "")}
            onConfirm={performDelete}
          />
        );
      } else {
        toast.warning(`You can not delete this Sample Tracking.`);
      }
    };
    return generateColumns(handleDelete);
  }, [deleteSampleTracking, setModalOpen]);

  return (
    <div className="sample-tracking-list-page">
      <PageHeader
        buttonText="Create Sample Tracking"
        title="Sample Tracking"
        onClick={handleCreateSampleTracking}
      />
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateSampleTracking"
        columns={COLUMNS}
        name="sampleTrackings"
        orderBy={orderBy}
        query={ALL_SAMPLE_TRACKINGS_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

SampleTrackingListPage.propTypes = {};

export default SampleTrackingListPage;
