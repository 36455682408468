import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageHeader, DataTable } from "../../../../components";
import { useModal } from "../../../../context";
import { useLocalStorage } from "../../../../hooks";
import NotificationModal from "../NotificationModal";
import DEFAULT_COLUMNS from "./columns";
import { ALL_JOB_ORDERS_AND_LEGACY_JOB_ORDERS_QUERY } from "../../../../graphql";
import "../../DashboardPage/DashboardPage.scss";

const ClientMainPage = ({
  onCreateOrderTest,
  UserID,
  ChildClientIDs,
  labState,
  authState,
}) => {
  const { setModalOpen } = useModal();
  const [displayWelcomeModal, setDisplayWelcomeModal] = useLocalStorage(
    `DISPLAY_WELCOME_MODAL`,
    true
  );

  useEffect(() => {
    if (displayWelcomeModal && labState === "AZ") {
      setModalOpen(
        true,
        <NotificationModal
          isPastTransitionDate={
            authState?.user?.isPastArizonaOrderTransitionDate
          }
          onCancel={async () => {
            await setDisplayWelcomeModal(false);
            setModalOpen(false, "");
          }}
          onConfirm={async () => {
            await setDisplayWelcomeModal(false);
            setModalOpen(false, "");
          }}
        />
      );
    }
  }, [
    displayWelcomeModal,
    setModalOpen,
    setDisplayWelcomeModal,
    labState,
    authState,
  ]);

  return (
    <React.Fragment>
      <div>
        <PageHeader title="Dashboard">
          {!authState?.user?.isPastArizonaOrderTransitionDate ? (
            <Button
              rounded
              color="primary"
              style={{ textAlign: "center" }}
              onClick={onCreateOrderTest}
            >
              <Icon>
                <FontAwesomeIcon icon="vial" />
              </Icon>
              <span>Order New Test</span>
            </Button>
          ) : null}
        </PageHeader>
        <DataTable
          aggregateKey="_all"
          aggregateName="aggregateJobOrdersAndLegacyJobOrders"
          columns={DEFAULT_COLUMNS}
          hiddenColumns={!ChildClientIDs.length ? ["Username"] : []}
          name="findManyJobOrdersAndLegacyJobOrders"
          orderBy={[{ id: "OrderCreateDate", desc: true }]}
          query={ALL_JOB_ORDERS_AND_LEGACY_JOB_ORDERS_QUERY}
          where={{ UserID: { in: [UserID, ...ChildClientIDs] } }}
        />
      </div>
    </React.Fragment>
  );
};
ClientMainPage.propTypes = {
  onCreateOrderTest: PropTypes.func.isRequired,
  UserID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  ChildClientIDs: PropTypes.array.isRequired,
  labState: PropTypes.string.isRequired,
  authState: PropTypes.object.isRequired,
};

export default ClientMainPage;
