import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const ALL_USER_PROFICIENCIES_QUERY = gql`
  query ALL_USER_PROFICIENCIES_QUERY(
    $where: UserProficienciesWhereInput
    $orderBy: [UserProficienciesOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyUserProficiencies(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      RecId
      UserID
      ProficiencyID
      CreatedDateTime
      ModifiedDateTime
      CreatedBy
      ModifiedBy
    }
  }
`;
