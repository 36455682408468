import { gql } from "@apollo/client";

export const LIST_TESTS_QUERY = gql`
  query LIST_TESTS_QUERY(
    $where: TestsWhereInput
    $orderBy: [TestsOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyTests(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      TestID
      Name
      Code
      Description
      CategoryPrice
      Active
    }
    aggregateTests(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const ALL_TESTS_QUERY = gql`
  query ALL_TESTS_QUERY(
    $where: TestsWhereInput
    $orderBy: [TestsOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyTests(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      TestID
      Name
      Code
      Description
      CategoryPrice
      Active
    }
  }
`;

export const LIST_USER_TESTS_QUERY = gql`
  query LIST_USER_TESTS_QUERY(
    $where: UserTestsWhereInput
    $orderBy: [UserTestsOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyUserTests(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      UserTestID
      UserID
      Active
      ShowOrder
      UserPrice
      Test {
        TestID
        Name
        Code
        Description
        Image
        CategoryPrice
        MedicalType
      }
    }
    aggregateUserTests(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const SINGLE_LAB_TEST_QUERY = gql`
  query SINGLE_LAB_TEST_QUERY($where: LabTestsWhereUniqueInput!) {
    findUniqueLabTests(where: $where) {
      LabTestID
      LabID
      TestID
      Plants
      Extracts
      Ingestibles
      Topicals
      AnimalProducts
      Other
      Test {
        TestID
        Name
        Code
        Description
        Image
      }
    }
  }
`;

export const LIST_LABS_TESTS_QUERY = gql`
  query LIST_LABS_TESTS_QUERY(
    $where: LabTestsWhereInput
    $orderBy: [LabTestsOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyLabTests(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      LabTestID
      LabID
      TestID
      Plants
      Extracts
      Ingestibles
      Topicals
      AnimalProducts
      Other
      Test {
        TestID
        Name
        Code
        Description
        Image
      }
    }
    aggregateLabTests(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const ALL_LABS_TESTS_QUERY = gql`
  query ALL_LABS_TESTS_QUERY(
    $where: LabTestsWhereInput
    $orderBy: [LabTestsOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyLabTests(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      LabTestID
      LabID
      TestID
      Plants
      Extracts
      Ingestibles
      Topicals
      AnimalProducts
      Other
      Test {
        TestID
        Name
        Code
        Description
        Image
      }
    }
  }
`;

export const SINGLE_TEST_QUERY = gql`
  query SINGLE_TEST_QUERY($where: TestsWhereUniqueInput!) {
    findUniqueTests(where: $where) {
      TestID
      Name
      Code
      Description
      CategoryPrice
      Active
      PackageTests {
        PackageTestID
      }
      TestAnalytes {
        TestAnalyteID
        AnalyteID
      }
      TestTestCategories {
        TestTestCategoryID
        TestCategory {
          TestCategoryID
          Name
        }
      }
    }
  }
`;

export const ALL_TESTS_WITH_QUEUE_COUNT_QUERY = gql`
  query ALL_TESTS_WITH_QUEUE_COUNT_QUERY($where: String!) {
    getTestsWithQueueCount(where: $where) {
      TestID
      Name
      Code
      Description
      sampleCount
    }
  }
`;
