const DEFAULT_COLUMNS = [
  {
    Header: "Username",
    id: "Username",
    accessor: "Username",
  },
  {
    Header: "First Name",
    id: "FirstName",
    accessor: "FirstName",
  },
  {
    Header: "Last Name",
    id: "LastName",
    accessor: "LastName",
  },
  {
    Header: "Account Level",
    id: "AccountLevel",
    accessor: "AccountLevel",
    disableSortBy: true,
  },
  {
    Header: "Email",
    id: "Email",
    accessor: "Email",
  },
  {
    Header: "Status",
    id: "Status",
    accessor: "Status",
    Cell: ({ cell }) => (cell?.value ? "Active" : "Inactive"),
  },
];

export default DEFAULT_COLUMNS;
