import { gql } from "@apollo/client";

export const DELETE_EQUIPMENT_ATTACHMENT_MUTATION = gql`
  mutation DELETE_EQUIPMENT_ATTACHMENT_MUTATION($where: EquipmentAttachmentsWhereUniqueInput!) {
    deleteEquipmentAttachments(where: $where) {
      EquipmentAttachmentID
    }
  }
`;

export const CREATE_EQUIPMENT_ATTACHMENT_MUTATION = gql`
  mutation CREATE_EQUIPMENT_ATTACHMENT_MUTATION($data: EquipmentAttachmentsCreateInput!) {
    createEquipmentAttachments(data: $data) {
      EquipmentAttachmentID
    }
  }
`;

export const UPDATE_EQUIPMENT_ATTACHMENT_MUTATION = gql`
  mutation UPDATE_EQUIPMENT_ATTACHMENT_MUTATION(
    $data:  EquipmentAttachmentsUpdateInput!
    $where: EquipmentAttachmentsWhereUniqueInput!
  ) {
    updateEquipmentAttachments(data: $data, where: $where) {
      EquipmentAttachmentID
    }
  }
`;