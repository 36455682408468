import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const CHECK_AUTH_QUERY = gql`
  query checkAuth {
    checkAuth {
      UserID
      Username
      FirstName
      LastName
      Email
      MMTCInfo
      LoginType
      Company
      Address
      City
      State
      Zip
      LabID
      Terms
      BillingAddress
      ShippingMultiplier
      SMSNotifications
      EmailNotifications
      ShowOnVerifyHemp
      VerifyHempCategories
      ChildClientIDs
      AllowDupeBatchOrderNumber
      UserGroupMemberships {
        UserGroupMembershipID
        UserGroup {
          GroupID
          UserGroupPermissions {
            UserGroupPermissionID
            Update
            View
            Permission {
              PermissionID
              Code
            }
          }
        }
      }
      Lab {
        LabID
        Company
        State
        PaymentOptions
      }
    }
  }
`;

export const CHECK_PRIVATE_LABEL_AUTH_QUERY = gql`
  query checkPrivateLabelAuth {
    checkPrivateLabelAuth {
      PrivateLabelUserID
      OwnerUserID
      Username
      RevShare
      Status
      CreateDate
    }
  }
`;

export const IS_USER_PASSWORD_VALID = gql`
  query IS_USER_PASSWORD_VALID($input: AuthInput!) {
    isUserPasswordValid(input: $input) {
      isValid
    }
  }
`;
