import React from "react";
import PropTypes from "prop-types";

import { Content, Button, Control, Field } from "rbx";

import { useModal } from "../../context/ModalContext";

const Confirmation = ({
  message,
  onConfirm,
  onCancel,
  confirmText,
  cancelText,
}) => {
  const { setModalOpen } = useModal();

  const handleCancel = () => {
    setModalOpen(false, "");
    if (onCancel && typeof onCancel === "function") {
      onCancel();
    }
  };

  return (
    <div>
      <Content size="medium" textAlign="centered">
        <p>{message}</p>
      </Content>
      <Field align="centered" kind="group">
        <Control>
          <Button onClick={handleCancel}>{cancelText}</Button>
        </Control>
        <Control>
          <Button color="primary" onClick={onConfirm}>
            {confirmText}
          </Button>
        </Control>
      </Field>
    </div>
  );
};

Confirmation.propTypes = {
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
};

Confirmation.defaultProps = {
  onCancel: () => null,
  confirmText: "Yes",
  cancelText: "No",
};

export default Confirmation;
