const dataExport = {
  name: "data-export",
  label: "Data Export",
  icon: "file-export",
  permissions: ["DATA_EXPORT"],
  active: (location) => {
    const [, p] = location.pathname.split("/");
    return /data-export/.test(p);
  },
  to: "/data-export/job-orders",
};

export default dataExport;
