import React, { useState } from "react";
import PropTypes from "prop-types";
import { Title, Button, Column, Control, Label, Field, Input } from "rbx";

import { formatCurrency, unmaskCurrency } from "../../../../utils";

const PaymentLedgerModal = ({ onComplete, onCancel, title, message }) => {
  const [amountPaid, setAmountPaid] = useState(0);

  return (
    <div>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>{title}</Title>
        </div>
        <div className="page-head-end">
          <Button.Group>
            <Button size="small" type="button" onClick={() => onCancel()}>
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={!amountPaid > 0}
              size="small"
              type="submit"
              onClick={() => onComplete(amountPaid)}
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <div style={{ textAlign: "center" }}>{message}</div>
      <Column.Group multiline>
        <Column desktop={{ size: 12 }} tablet={{ size: 12 }}>
          <Field kind="group">
            <Control expanded size="small" style={{ width: "50%" }}>
              <Label>Amount Paid</Label>
              <Input
                required
                autoComplete="off"
                maxLength={250}
                name="AmountPaid"
                placeholder="Amount Paid"
                size="small"
                value={formatCurrency(amountPaid)}
                onChange={(e) => setAmountPaid(unmaskCurrency(e.target.value))}
              />
            </Control>
          </Field>
        </Column>
      </Column.Group>
    </div>
  );
};

PaymentLedgerModal.propTypes = {
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
};

PaymentLedgerModal.defaultProps = {
  onComplete: (e) => e,
  onCancel: (e) => e,
  message: null,
};

export default PaymentLedgerModal;
