import React from "react";
import PropTypes from "prop-types";
import { Column, Field, Input, Label, Control, Textarea } from "rbx";

import {
  TestCategoryMultiSelect,
  CurrencyInput,
  BooleanInput,
} from "../../../../../components";

const TestForm = ({
  inputs,
  onChange,
  disabled,
  onSubmit,
  formId,
  isActiveDisabled,
}) => {
  const handleChange = (e) => {
    onChange(e.target.name, e.target.value);
  };

  return (
    <form id={formId} onSubmit={onSubmit}>
      <Column.Group multiline>
        <Column size={12}>
          <Field kind="group">
            <Control expanded size="small">
              <Label>Name</Label>
              <Input
                required
                disabled={disabled}
                maxLength={250}
                name="Name"
                placeholder="Name"
                size="small"
                value={inputs.Name}
                onChange={handleChange}
              />
            </Control>
            <TestCategoryMultiSelect
              disabled={disabled}
              label="Test Categories"
              name="TestCategories"
              value={inputs.TestCategories}
              onChange={onChange}
            />
          </Field>
          <Field kind="group">
            <Control expanded size="small">
              <Label>Code</Label>
              <Input
                required
                disabled={disabled}
                maxLength={250}
                name="Code"
                placeholder="Code"
                size="small"
                value={inputs.Code}
                onChange={handleChange}
              />
            </Control>
            <Control expanded size="small">
              <CurrencyInput
                required
                disabled={disabled}
                label="Default Price"
                maxLength={250}
                name="CategoryPrice"
                placeholder="CategoryPrice"
                size="small"
                value={inputs.CategoryPrice}
                onChange={onChange}
              />
            </Control>
            <Control expanded size="small" style={{ width: "5%" }}>
              <Label>Active</Label>
              <BooleanInput
                required
                disabled={disabled || isActiveDisabled}
                name="Active"
                value={inputs.Active}
                onChange={(name, checked) => onChange(name, checked)}
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded size="medium">
              <Label>Description</Label>
              <Textarea
                required
                disabled={disabled}
                maxLength={250}
                name="Description"
                placeholder="Description"
                size="small"
                value={inputs.Description}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
        </Column>
      </Column.Group>
    </form>
  );
};

TestForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  formId: PropTypes.string.isRequired,
  isActiveDisabled: PropTypes.bool,
};

TestForm.defaultProps = {
  onSubmit: () => null,
  disabled: false,
  isActiveDisabled: true,
};

export default TestForm;
