const BATCH_SAMPLE_ROWS_DEFAULT = [
  { OrderName: "Method Blank", Code: "MB" },
  { OrderName: "CCV", Code: "CCV" },
  { OrderName: "LFB", Code: "LFB" },
  { OrderName: "LFB Dupe", Code: "LFBD" },
  { OrderName: "Matrix Spike", Code: "MS" },
  { OrderName: "Matrix Spike Duplicate", Code: "MSD" },
  { OrderName: "Sample Duplicate", Code: "SD" },
  { OrderName: "", matrixSpike: true },
  { OrderName: "", sampleDuplicate: true },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "CCV", Code: "CCV" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
];

const BATCH_SAMPLE_ROWS_MICRO = [
  { OrderName: "", sampleDuplicate: true },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
];

const BATCH_SAMPLE_ROWS_DEFAULT_AZ = [
  { OrderName: "Method Blank", Code: "MB" },
  { OrderName: "CCV", Code: "CCV" },
  { OrderName: "LCS", Code: "LCS" },
  { OrderName: "LCS Dupe", Code: "LCSD" },
  { OrderName: "Matrix Spike", Code: "MS" },
  { OrderName: "", matrixSpike: true },
  { OrderName: "", sampleDuplicate: true },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "CCV", Code: "CCV" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
  { OrderName: "" },
];

const BatchTemplate = (testCategory, lab) => {
  // find template using state and test category
  if (testCategory.Code.includes("MICRO")) {
    return BATCH_SAMPLE_ROWS_MICRO;
  }
  if (lab.State === "AZ") {
    return BATCH_SAMPLE_ROWS_DEFAULT_AZ;
  }
  return BATCH_SAMPLE_ROWS_DEFAULT;
};

export default BatchTemplate;
