import { gql } from "@apollo/client";

export const DELETE_SYSTEM_CODE_MUTATION = gql`
  mutation DELETE_SYSTEM_CODE_MUTATION($where: SystemCodesWhereUniqueInput!) {
    deleteSystemCodes(where: $where) {
      RecId
    }
  }
`;

export const CREATE_SYSTEM_CODE_MUTATION = gql`
  mutation CREATE_SYSTEM_CODE_MUTATION($data: SystemCodesCreateInput!) {
    createSystemCodes(data: $data) {
      RecId
      CodeId
      CodeDescription
      CodeName
      Category
      Type
      Active
    }
  }
`;

export const UPDATE_SYSTEM_CODE_MUTATION = gql`
  mutation UPDATE_SYSTEM_CODE_MUTATION(
    $data: SystemCodesUpdateInput!
    $where: SystemCodesWhereUniqueInput!
  ) {
    updateSystemCodes(data: $data, where: $where) {
      RecId
      CodeId
      CodeDescription
      CodeName
      Category
      Type
      Active
    }
  }
`;
