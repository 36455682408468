/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Box, Field, Control, Label, Button, Column } from "rbx";
import { FilterInput } from "../../../../../components";

const TestCategoryFilters = ({ onChange, onReset, inputs }) => (
  <Box id="job-filters">
    <Column.Group>
      <Column>
        <Field>
          <FilterInput
            getValue={(x) => x?.contains || ""}
            label="Name"
            name="Name"
            placeholder="Name"
            setValue={(x) => (x ? { contains: x } : "")}
            value={inputs.Name || ""}
            onChange={onChange}
          />
        </Field>
      </Column>
      <Column>
        <Field>
          <FilterInput
            getValue={(x) => x?.contains || ""}
            label="Code"
            name="Code"
            placeholder="Code"
            setValue={(x) => (x ? { contains: x } : "")}
            value={inputs.Code || ""}
            onChange={onChange}
          />
        </Field>
      </Column>
      <Column>
        <Field>
          <FilterInput
            getValue={(x) => x?.contains || ""}
            label="Description"
            name="Description"
            placeholder="Description"
            setValue={(x) => (x ? { contains: x } : "")}
            value={inputs.Description || ""}
            onChange={onChange}
          />
        </Field>
      </Column>
      <Column>
        <Field className="filter-button-group" kind="group">
          <Control>
            <Label>&nbsp;</Label>
            <Button color="primary" size="small" onClick={onReset}>
              Reset
            </Button>
          </Control>
        </Field>
      </Column>
    </Column.Group>
  </Box>
);

TestCategoryFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  inputs: PropTypes.object,
};

TestCategoryFilters.defaultProps = {
  inputs: {},
};

export default TestCategoryFilters;
