import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { ALL_QUALIFIERS_QUERY } from "../../../../../graphql";
import { COLUMNS } from "./columns";
import {
  PageHeader,
  DataTable,
  IconButton,
  ListSearchInput,
  Loader,
} from "../../../../../components";
import { useModal, useAuth } from "../../../../../context";
import QualifierModal from "../components/QualifierModal";
import { debounce } from "../../../../../utils";

const INITIAL_WHERE = {
  OR: [
    {
      Name: {
        contains: "",
      },
    },
    {
      States: {
        contains: "",
      },
    },
  ],
};

const QualifierListPage = ({ routePermissions }) => {
  const { QualifierID } = useParams();
  const history = useHistory();
  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();
  const [where, setWhere] = useState(INITIAL_WHERE);
  const [modalOpened, setModalOpened] = useState();
  const orderBy = [{ id: "Name", desc: false }];
  const [canUpdate, setCanUpdate] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  useEffect(() => {
    if (QualifierID && !modalOpened && canUpdate) {
      setModalOpened(true);
      setModalOpen(
        true,
        <QualifierModal
          QualifierID={QualifierID}
          onComplete={() => {
            history.push("/lims/qualifiers");
            setModalOpen(false);
          }}
        />
      );
    }
  }, [QualifierID, history, modalOpened, setModalOpen, canUpdate]);

  useEffect(() => {
    if (
      authState?.user?.Lab?.State !== "AZ" &&
      authState?.user?.Lab?.Company !== "All Location"
    ) {
      history.push("/");
    } else {
      setLoading(false);
    }
  }, [authState, history]);

  const handleCreateQualifier = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push("/lims/qualifiers");
    setModalOpen(
      true,
      <QualifierModal
        onComplete={() => {
          history.push("/qualifiers");
          setModalOpen(false);
        }}
      />
    );
  };

  const handleRowClick = (qualifierItem) => {
    if (canUpdate) {
      setModalOpened(false);
      history.push(`/lims/qualifiers/${qualifierItem.QualifierID}`);
    }
  };

  const handleSearchInputChange = (value) => {
    if (value) {
      setWhere({
        OR: [
          {
            Name: {
              contains: value,
            },
          },
          {
            States: {
              contains: value,
            },
          },
        ],
      });
    } else {
      setWhere(INITIAL_WHERE);
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="qualifier-list-page">
      <PageHeader title="Qualifiers">
        <ListSearchInput
          onChange={debounce((name, value) => {
            handleSearchInputChange(value);
          }, 500)}
        />
        {canUpdate && (
          <IconButton icon="plus" onClick={handleCreateQualifier} />
        )}
      </PageHeader>
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateSystemCodes"
        columns={COLUMNS}
        name="findManyQualifiers"
        orderBy={orderBy}
        query={ALL_QUALIFIERS_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

QualifierListPage.propTypes = {
  routePermissions: PropTypes.array,
};

QualifierListPage.defaultProps = {
  routePermissions: [],
};

export default QualifierListPage;
