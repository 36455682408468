/* eslint-disable react/prop-types */
import { Generic, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const STATIC_COLUMNS = [
  {
    Header: "Name",
    id: "Name",
    accessor: "Name",
  },
  {
    Header: "Code",
    id: "Code",
    accessor: "Code",
  },
  {
    Header: "Description",
    id: "Description",
    accessor: "Description",
  },
];

const DEFAULT_COLUMNS = (handleDelete, canUpdate) =>
  canUpdate
    ? [
        ...STATIC_COLUMNS,
        {
          Header: "",
          id: "delete",
          Cell: (cell) => (
            <Generic
              style={{ textAlign: "center" }}
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(cell.row.original);
              }}
            >
              <Icon color="danger">
                <FontAwesomeIcon icon="times" />
              </Icon>
            </Generic>
          ),
        },
      ]
    : [...STATIC_COLUMNS];

export default DEFAULT_COLUMNS;
