/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import { Control, Title, Section } from "rbx";
import Collapse, { Panel } from "rc-collapse";
import PropTypes from "prop-types";
import "rc-collapse/assets/index.css";

import Table from "../../../../../components/DataTable/Table";
import { customToast as toast } from "../../../../../utils";
import { Confirmation } from "../../../../../components";
import {
  ALL_ANALYTES_QUERY,
  DELETE_ANALYTE_ON_CASCADE_MUTATION,
  UPDATE_ANALYTE_MUTATION,
  LIST_TEST_CATEGORIES_QUERY,
  DELETE_MANY_TEST_ANALYTES_MUTATION,
  DELETE_MANY_ACTION_LIMIT_TEMPLATE_ANALYTES,
} from "../../../../../graphql";
import { generateColumns } from "./columns";
import { useModal } from "../../../../../context/ModalContext";
import Loader from "../../../../../components/Loader";
import "./DisplayAnalytesTables.scss";

const DisplayAnalytesTables = ({ canUpdate }) => {
  const orderBy = [{ id: "Name", desc: false }];

  const [loading, setLoading] = useState(false);
  const [testCategoriesData, setTestCategoriesData] = useState({});
  const [orderByMulti, setOrderByMulti] = useState(orderBy);

  const [deleteAnalyte] = useMutation(DELETE_ANALYTE_ON_CASCADE_MUTATION);
  const [updateAnalyte] = useMutation(UPDATE_ANALYTE_MUTATION);
  const [deleteManyTestAnalytes] = useMutation(
    DELETE_MANY_TEST_ANALYTES_MUTATION
  );
  const [deleteManyActionLimitTemplateAnalytes] = useMutation(
    DELETE_MANY_ACTION_LIMIT_TEMPLATE_ANALYTES
  );
  const [getData, { data }] = useLazyQuery(ALL_ANALYTES_QUERY, {
    fetchPolicy: "network-only",
  });
  const { data: getTestCategoriesData } = useQuery(LIST_TEST_CATEGORIES_QUERY);

  const handleChangeSort = useCallback(async (sortBy) => {
    setOrderByMulti(sortBy);
  }, []);

  const where = {};

  const { setModalOpen } = useModal();

  const initializeState = () => {
    if (Array.isArray(getTestCategoriesData?.findManyTestCategories)) {
      getTestCategoriesData.findManyTestCategories.forEach((cat) => {
        setTestCategoriesData((prev) => ({
          ...prev,
          [cat.Name]: [],
        }));
      });
    }
  };

  const assignActionLimitTemplateAnalyteToArray = (obj) => {
    setTestCategoriesData((prev) => ({
      ...prev,
      [obj.TestCategory.Name]: [obj].concat(prev[obj.TestCategory.Name]),
    }));
  };

  useEffect(() => {
    getData({
      variables: {
        where,
        orderBy: orderByMulti.map((x) => {
          if (x.id.includes(":")) {
            const sorts = x.id.split(":");
            return { [sorts[0]]: { [sorts[1]]: x.desc ? "desc" : "asc" } };
          }
          return { [x.id]: x.desc ? "desc" : "asc" };
        }),
      },
    });
  }, [getData, orderByMulti]);

  useEffect(() => {
    initializeState();
    if (data?.findManyAnalytes) {
      data.findManyAnalytes.forEach((item) => {
        const obj = {
          AnalyteID: item.AnalyteID,
          Name: item.Name,
          Active: item.Active,
          TestCategory: item.TestCategory,
        };
        assignActionLimitTemplateAnalyteToArray(obj);
      });
    }
  }, [data]);

  const renderTables = () =>
    Object.keys(testCategoriesData).map((key) => (
      <div>
        <Collapse accordion={false}>
          <Panel
            header={
              <Control className="table-group-title">
                <Title size={5}>{key.toUpperCase()}</Title>
              </Control>
            }
            headerClass="my-header-class"
          >
            <Table
              columns={columns}
              data={testCategoriesData[key]}
              initialSortBy={orderByMulti}
              onChangeSort={handleChangeSort}
            />
          </Panel>
        </Collapse>
      </div>
    ));

  const handleUpdate = async (AnalyteID, value) => {
    setLoading(true);
    try {
      await updateAnalyte({
        variables: {
          where: { AnalyteID: parseInt(AnalyteID, 10) },
          data: {
            Active: {
              set: value,
            },
          },
        },
        refetchQueries: [
          {
            query: ALL_ANALYTES_QUERY,
            variables: {
              where,
            },
            fetchPolicy: "network-only",
          },
        ],
      });
      if (!value) {
        await deleteManyTestAnalytes({
          variables: {
            where: {
              AnalyteID: {
                equals: parseInt(AnalyteID, 10),
              },
            },
          },
        });

        await deleteManyActionLimitTemplateAnalytes({
          variables: {
            where: {
              AnalyteID: {
                equals: parseInt(AnalyteID, 10),
              },
            },
          },
        });
      }
      toast.success("Analyte updated successfully.");
    } catch (err) {
      toast.error("Error updating Analyte");
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(() => {
    const handleDelete = (analyte) => {
      const performDelete = async () => {
        setLoading(true);
        try {
          setModalOpen(false, "");
          await deleteAnalyte({
            variables: {
              where: {
                AnalyteID: parseInt(analyte.AnalyteID, 10),
              },
            },
            refetchQueries: [
              {
                query: ALL_ANALYTES_QUERY,
                variables: {
                  where: {},
                },
              },
            ],
          });
          toast.success("Analyte deleted successfully.");
        } catch (err) {
          toast.error(`Error deleting Analyte.`);
        } finally {
          setLoading(false);
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this Analyte? This will also delete this analyte from Analytes Templates"
          onCancel={() => setModalOpen(false, "")}
          onConfirm={performDelete}
        />
      );
    };
    return generateColumns(handleDelete, handleUpdate, canUpdate);
  }, [deleteAnalyte, setModalOpen, canUpdate]);

  if (!Array.isArray(data?.findManyAnalytes)) {
    return <Loader />;
  }

  if (
    Array.isArray(data?.findManyAnalytes) &&
    data?.findManyAnalytes?.length === 0
  ) {
    return (
      <Title subtitle style={{ color: "gray" }}>
        Nothing here...
      </Title>
    );
  }

  if (loading) {
    return <Loader style={{ height: "330px" }} />;
  }

  return (
    <Section className="table-section" id="analyte-table">
      <Control className="table-group-title">
        <Title size={4}>Analytes</Title>
      </Control>
      <hr />
      <div className="tablesWrapper">
        {!!Object.keys(testCategoriesData).length && renderTables()}
      </div>
    </Section>
  );
};

DisplayAnalytesTables.propTypes = {
  canUpdate: PropTypes.bool,
};

DisplayAnalytesTables.defaultProps = {
  canUpdate: true,
};

export default DisplayAnalytesTables;
