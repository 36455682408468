/* eslint-disable react/prop-types */
import React from "react";
import { Generic, Icon, Image } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const COLUMNS = (onDelete) => [
  {
    Header: "ImageURL",
    id: "ImageURL",
    accessor: "ImageURL",
    Cell: ({ cell }) =>
      cell.value ? (
        <Image.Container size={48}>
          <Image alt="img" src={cell.value} />
        </Image.Container>
      ) : null,
  },
  {
    Header: "Name",
    id: "Name",
    accessor: "Name",
    disableSortBy: true,
  },
  {
    Header: "",
    id: "delete",
    Cell: (cell) => (
      <Generic
        style={{ textAlign: "center" }}
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          onDelete(cell.row.original);
        }}
      >
        <Icon color="danger">
          <FontAwesomeIcon icon="times" />
        </Icon>
      </Generic>
    ),
  },
];

export default COLUMNS;
