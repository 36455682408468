/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import { Column, Field, Control, Label } from "rbx";

import { BooleanInput, SwitchInput } from "../../../../../../components";

const BatchResultHeaderColumn = ({
  singleResultTemplate,
  showQualifiers,
  onClickQualifiers,
  disabled,
  approveAllValue,
  rejectAllValue,
  onChangeStatusOfAll,
  dividerRefs,
  onDividerClick,
  labState,
  isMicro,
}) => (
  <Column className="header-column" size={2}>
    {singleResultTemplate.map((row, i) => {
      if (row.header) {
        return (
          <div
            key={`result-item-${i}`}
            className={`result-item result-item-header ${row.class || ""}`}
          >
            <div className="header-buttons">
              {labState && labState === "AZ" && (
                <Control id="qualifier-toggle" size="small">
                  <SwitchInput
                    name="showQualifiers"
                    size="small"
                    value={showQualifiers}
                    onChange={() => onClickQualifiers(true)}
                  />
                  <Label size="small">Qualifiers</Label>
                </Control>
              )}
              <div className="result-status">
                <Field kind="group">
                  <Control size="small">
                    <Label>Approve All</Label>
                    <BooleanInput
                      color="cyan"
                      disabled={disabled}
                      name="approveAll"
                      value={approveAllValue}
                      onChange={(name, value) =>
                        onChangeStatusOfAll(value ? "Approved" : null)
                      }
                    />
                  </Control>
                  <Control size="small">
                    <Label>Reject All</Label>
                    <BooleanInput
                      color="error"
                      disabled={disabled}
                      name="rejectAll"
                      value={rejectAllValue}
                      onChange={(name, value) =>
                        onChangeStatusOfAll(value ? "Rejected" : null)
                      }
                    />
                  </Control>
                </Field>
              </div>
            </div>
            <div className="header-name-label header-div">Name</div>
            <div className="header-div">Session ID</div>
            {isMicro && <div className="header-div">Test Name</div>}
            <div className="header-div">Primary Sample Session ID</div>
            <div className="header-div">Date Analyzed</div>
          </div>
        );
      }
      if (row.ref) {
        return (
          <button
            key={`${row.label}-${i}`}
            ref={dividerRefs.current[i]}
            className={`result-item ${row.class || ""}`}
            type="button"
            onClick={() => onDividerClick(i)}
          >
            {row.label}
          </button>
        );
      }
      return (
        <div
          key={`${row.label}-${i}`}
          ref={dividerRefs.current[i]}
          className={`result-item ${row.class || ""}`}
        >
          <b>{row.label}</b>&nbsp;
          <span>{row.units || ""}</span>
        </div>
      );
    })}
  </Column>
);

BatchResultHeaderColumn.propTypes = {
  singleResultTemplate: PropTypes.array.isRequired,
  showQualifiers: PropTypes.bool.isRequired,
  onClickQualifiers: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  approveAllValue: PropTypes.bool.isRequired,
  rejectAllValue: PropTypes.bool.isRequired,
  onChangeStatusOfAll: PropTypes.func.isRequired,
  dividerRefs: PropTypes.object.isRequired,
  onDividerClick: PropTypes.func.isRequired,
  labState: PropTypes.string.isRequired,
  isMicro: PropTypes.bool.isRequired,
};

BatchResultHeaderColumn.defaultProps = {};

export default BatchResultHeaderColumn;
