/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import PropTypes from "prop-types"

import { generateColumns } from "./columns";
import AddEditBatchAttachmentModal from "../../components/AddEditBatchAttachmentModal";
import { useModal } from "../../../../../../context/ModalContext";

import {
  DataTable,
  PageHeader,
  IconButton
} from "../../../../../../components";
import { ALL_BATCH_ATTACHMENTS_QUERY } from "../../../../../../graphql";

const Attachments = ({ BatchID, disabled }) => {  
  const where = {
    BatchID: {
      equals: parseInt(BatchID, 10),
    },
  };

  const orderBy = [{ id: "BatchID", desc: false }];
  const { setModalOpen } = useModal();

  const onRowClick = (attachmentItem) => {
    setModalOpen(
      true,
      <AddEditBatchAttachmentModal
        Attachment={attachmentItem}
        onComplete={() => {
          setModalOpen(false);
        }}
      />
    );
  };

  const handleCreateAttachment = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModalOpen(
      true,
      <AddEditBatchAttachmentModal
        BatchID={BatchID}
        onComplete={() => {
          setModalOpen(false);
        }}
      />
    );
  };

  const COLUMNS = useMemo(() => generateColumns(), [setModalOpen]);

  return (
    <div>
      <PageHeader buttonText="Create Attachment" title="Attachments">
        {!disabled && <IconButton icon="plus" onClick={handleCreateAttachment} />}
      </PageHeader>
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateBatchAttachments"
        columns={COLUMNS}
        name="findManyBatchAttachments"
        orderBy={orderBy}
        query={ALL_BATCH_ATTACHMENTS_QUERY}
        where={where}
        onRowClick={onRowClick}
      />
    </div>
  );
};

Attachments.propTypes = {
  BatchID: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

Attachments.defaultProps = {
  disabled: false,
};

export default Attachments;