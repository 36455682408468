import { gql } from "@apollo/client";

export const ALL_BATCH_ATTACHMENTS_QUERY = gql`
  query ALL_BATCH_ATTACHMENTS_QUERY(
    $where: BatchAttachmentsWhereInput
    $orderBy: [BatchAttachmentsOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyBatchAttachments(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      BatchAttachmentID
      BatchID
      Description
      AttachmentURL
      CreatedBy
      ModifiedBy
      CreatedDateTime
      ModifiedDateTime
    }
  }
`;

export const SINGLE_BATCH_ATTACHMENT_QUERY = gql`
  query SINGLE_BATCH_ATTACHMENT_QUERY($where: BatchAttachmentsWhereUniqueInput!) {
    findUniqueBatchAttachments(where: $where) {
      BatchAttachmentID
      BatchID
      Description
      AttachmentURL
      CreatedBy
      ModifiedBy
      CreatedDateTime
      ModifiedDateTime
    }
  }
`;
