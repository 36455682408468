const CLIENT_ROUTES = [
  {
    name: "new-test",
    label: "New Test",
    icon: "vial",
    active: (location) => {
      const [, p] = location.pathname.split("/");
      return /new-test/.test(p);
    },
    to: "/new-test/individual-test",
  },
  {
    name: "orders",
    label: "Orders",
    icon: "vials",
    active: (location) => {
      const [, p] = location.pathname.split("/");
      return /orders/.test(p);
    },
    to: "/orders",
  },
  {
    name: "coas",
    label: "CoAs",
    icon: "file",
    active: (location) => {
      const [, p] = location.pathname.split("/");
      return /coas/.test(p);
    },
    to: "/coas",
  },
  {
    name: "contact",
    label: "Contact",
    icon: "envelope",
    active: (location) => {
      const [, p] = location.pathname.split("/");
      return /contact/.test(p);
    },
    to: "/contact",
  },
  {
    name: "profile",
    label: "Profile",
    icon: "user",
    active: (location) => {
      const [, p] = location.pathname.split("/");
      return /profile/.test(p);
    },
    to: "/profile/account-details",
  },
  {
    name: "settings",
    label: "Settings",
    icon: "cog",
    active: (location) => {
      const [, p] = location.pathname.split("/");
      return /settings/.test(p);
    },
    to: "/client-settings",
  },
  {
    name: "cart",
    label: "Cart",
    icon: "cart",
    active: (location) => {
      const [, p] = location.pathname.split("/");
      return /cart/.test(p);
    },
    to: "/cart",
    hidden: true,
  },
];

export default CLIENT_ROUTES;
