/* eslint-disable no-prototype-builtins */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable max-classes-per-file */

// eslint-disable-next-line max-classes-per-file
class ParserError extends Error {}

export default class Parser {
  static groupBy(arr, fn) {
    return arr
      .map(typeof fn === "function" ? fn : (val) => val[fn])
      .reduce((acc, val, i) => {
        acc[val] = (acc[val] || []).concat(arr[i]);
        return acc;
      }, {});
  }

  static extractSessionId(input) {
    if (typeof input !== "string") return "";
    const [sessionId] = input.match(/\d+_\d+_\d+_r\d/) ||
      input.match(/\d+_\d+_\d+-r\d/) ||
      input.match(/\d+_\d+_\d+/) ||
      input.match(/\w+_\d+_\d+/) || [""];
    const formattedSessionId = sessionId.replace("-", "_");
    return formattedSessionId;
  }

  static parseGasChromatography(input) {
    const [, data] = input.split(/\[MS Quantitative Results\].*\r\n/);

    // terpenes
    if (!data) {
      const [, Terpdata] = input.split(/\[Compound Results.*\].*\r\n/);

      const [, TerprawHeaders, ...TerprawRows] = Terpdata.split(/\r\n/gi);
      const headers = TerprawHeaders.split(/\t/);
      const rows = TerprawRows.map((r) => {
        const rr = r.split(/\t/);
        return rr.reduce(
          (a, c, i) => ({
            ...a,
            [headers[i] || "UNKNOWN"]: c,
          }),
          {}
        );
      });
      // rename some terp analytes
      const renamedRows = rows.map((row) => {
        if (row.Name?.toUpperCase() === "A-BISABOLOL") {
          row.Name = "alpha-Bisobolol";
        } else if (row.Name?.toUpperCase() === "A-CEDRENE") {
          row.Name = "alpha-Cedrene";
        } else if (row.Name?.toUpperCase() === "A-HUMULENE") {
          row.Name = "alpha-Humulene";
        } else if (row.Name?.toUpperCase() === "A-PINENE") {
          row.Name = "alpha-Pinene";
        } else if (row.Name?.toUpperCase() === "A-TERPINENE") {
          row.Name = "alpha-Terpinene";
        } else if (row.Name?.toUpperCase() === "B-MYRCENE") {
          row.Name = "beta-Myrcene";
        } else if (row.Name?.toUpperCase() === "B-PINENE") {
          row.Name = "beta-Pinene";
        } else if (row.Name?.toUpperCase() === "BORNEOL (MIX OF ISOMERS)") {
          row.Name = "Borneol";
        } else if (row.Name?.toUpperCase() === "CAMPHOR (MIX OF ISOMERS)") {
          row.Name = "Camphor";
        } else if (row.Name?.toUpperCase() === "FENCHONE (MIX OF ISOMERS)") {
          row.Name = "Fenchone";
        } else if (row.Name?.toUpperCase() === "TERPINEOL (MIX OF ISOMERS)") {
          row.Name = "Terpineol";
        } else if (row.Name?.toUpperCase() === "ENDO-FENCHYL ALCOHOL") {
          row.Name = "Fenchyl alcohol";
        } else if (row.Name?.toUpperCase() === "PULEGONE (+)") {
          row.Name = "Pulegone";
        } else if (row.Name?.toUpperCase() === "P-MENTHA-1, 5-DIENE") {
          row.Name = "p-Mentha-1,5-diene";
        }
        return row;
      });

      const concs = renamedRows.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.Name]:
            curr?.Name && parseFloat(curr["Conc."] || 0) > 0
              ? parseFloat(curr["Conc."])
              : 0,
        }),
        {}
      );
      return {
        sessionId: this.extractSessionId(input),
        type: "TERP",
        result: concs,
      };
    }

    const [, rawHeaders, ...rawRows] = data
      .replace(/['"]+/g, "")
      .split(/\r\n/gi);

    const headers = rawHeaders.split(/\t/);
    const rows = rawRows.map((r) => {
      const rr = r.split(/\t/);
      return rr.reduce(
        (a, c, i) => ({
          ...a,
          [headers[i] || "UNKNOWN"]: c,
        }),
        {}
      );
    });

    const concs = rows.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.Name]:
          parseFloat(curr["Conc."] || 0) > 0 ? parseFloat(curr["Conc."]) : 0,
      }),
      {}
    );
    return {
      sessionId: this.extractSessionId(input),
      type: "To determine",
      result: concs,
    };
  }

  static parseInductivelyCoupledPlasmaMassSpectrometry(input) {
    // ICPMS - just a CSV
    const elementKey = "Analyte";
    const concentrationKey = "Concentration";
    const [rawHeaders, ...rawRows] = input.split(/\n/);

    const headers = rawHeaders.split(/,/);
    const result = rawRows.map((row) => {
      const arr = row.split(/,/);
      return arr.reduce(
        (acc, curr, i) => ({
          ...acc,
          [headers[i]]: curr,
        }),
        {}
      );
    });

    const rules = {
      AS: { Name: "Arsenic", Mass: 75 },
      CD: { Name: "Cadmium", Mass: 111 },
      HG: { Name: "Mercury", Mass: 201 },
      PB: { Name: "Lead", Mass: 208 },
    };

    const concs = this.groupBy(
      result
        .map((item) => ({
          SessionID: this.extractSessionId(item["Sample Name"]),
          Element: item[elementKey],
          Conc:
            parseFloat(item[concentrationKey]) > 0
              ? parseFloat(item[concentrationKey])
              : 0,
          Mass: parseFloat(item.Mass || 0),
        }))
        .filter((x) => !!x.SessionID),
      (x) => x.SessionID
    );

    return Object.keys(concs).map((key) => ({
      sessionId: key,
      type: "METALS",
      result: concs[key].reduce((acc, curr) => {
        if (
          !acc.hasOwnProperty(curr.Element) &&
          !acc.hasOwnProperty(rules[curr.Element.toUpperCase()]?.Name) &&
          rules.hasOwnProperty(curr.Element.toUpperCase()) &&
          rules[curr.Element.toUpperCase()]?.Mass === curr.Mass
        ) {
          return {
            ...acc,
            [rules[curr.Element.toUpperCase()]?.Name]: curr.Conc,
          };
        }

        return {
          ...acc,
        };
      }, {}),
    }));
  }

  static parseLiquidChromatography(input, isCANN) {
    if (isCANN) {
      const data = input
        .split(/ID#\t/gi)
        .map((test) => test.split(/\r\n/g).filter(Boolean))
        .filter(({ length }) => length);

      const result = data.reduce((acc, curr) => {
        const [rawId, rawName, rawHeaders, ...rawRows] = curr;
        const id = parseInt(rawId.replace(/\t/g, ""), 10);
        let name = rawName.replace(/Name|\t/g, "");
        if (name.toUpperCase() === "D10THC") {
          name = "d10-thc";
        }

        const headers = rawHeaders.split(/\t/);
        const rows = rawRows.map((r) => {
          const rr = r.split(/\t/);
          return rr.reduce(
            (a, c, i) => ({
              ...a,
              [i ? headers[i] : "Row"]: c,
            }),
            {}
          );
        });

        return [...acc, { id, name, headers, rows }];
      }, []);

      const concs = result.reduce((acc, curr) => {
        const sessionRows = curr.rows.filter(
          (row) =>
            /\d+_\d+_\d+/.test(row["Data Filename"]) ||
            /\w+_\d+_\d+/.test(row["Data Filename"])
        );

        sessionRows.forEach((row) => {
          const sessionId = this.extractSessionId(row["Data Filename"]);

          const key = Object.keys(row).find((k) => /Conc/i.test(k));
          const value =
            key && row && !/-/.test(row[key]) ? parseFloat(row[key]) : 0;

          acc[sessionId] = acc[sessionId]
            ? { ...acc[sessionId], [curr.name]: value > 0 ? value : 0 }
            : { [curr.name]: value > 0 ? value : 0 };
        });

        return acc;
      }, {});
      return Object.keys(concs).map((sessionId) => ({
        sessionId,
        type: "To determine",
        result: concs[sessionId],
      }));
    }
    const rows = input
      .slice(input.indexOf("\n") + 1)
      .split("\n")
      .map((element) => element.replace(/(\r\n|\n|\r)/gm, ""))
      .reduce((acc, curr) => {
        const separated = curr.split(",");
        const obj = separated.reduce((accSeparated, currSeparated, index) => {
          if (index === 0) {
            accSeparated.sessionID = currSeparated;
          }
          if (index === 1) {
            accSeparated.element = currSeparated;
          }
          if (index === 2) {
            accSeparated.conc = currSeparated;
          }
          return accSeparated;
        }, {});
        acc.push(obj);
        return acc;
      }, []);

    const concs = this.groupBy(
      rows
        .map((item) => ({
          SessionID: this.extractSessionId(item.sessionID),
          Element: item.element,
          Conc: parseFloat(item.conc) > 0 ? parseFloat(item.conc) : 0,
        }))
        .filter((x) => !!x.SessionID),
      (x) => x.SessionID
    );

    return Object.keys(concs).map((key) => ({
      sessionId: key,
      type: "To determine",
      result: concs[key].reduce(
        (acc, curr) => ({
          ...acc,
          [curr.Element]: curr.Conc,
        }),
        {}
      ),
    }));
  }

  static parseAgilent(input) {
    return {};
  }

  static parseMicroMG(input) {
    const [initialHeaders, ...initialRows] = input.split(/\r\n/gi);
    const headers = initialHeaders.split(/\t/);
    const rows = initialRows.map((r) => {
      const rr = r.split(/\t/);
      return rr.reduce(
        (a, c, i) => ({
          ...a,
          [headers[i]?.trim() || "UNKNOWN"]: c?.trim(),
        }),
        {}
      );
    });
    const concs = this.groupBy(
      rows
        .map((row) => ({
          SessionID: this.extractSessionId(row["Well Name"]),
          Element: row.Target,
          Conc: row["Cq (?R)"] || row["Cq (∆R)"],
        }))
        .filter((x) => !!x.SessionID),
      (x) => x.SessionID
    );
    return Object.keys(concs).map((key) => ({
      sessionId: key,
      type: "To determine",
      result: concs[key].reduce(
        (acc, curr) => ({
          ...acc,
          [curr.Element]: curr.Conc,
        }),
        {}
      ),
    }));
  }

  static parseMicroBmxGenupAspergillus(input) {
    const [rawTitle, initialHeaders, ...initialRows] = input.split(/\r\n/gi);
    const [, title] = rawTitle.split(/Selected Filter:/);
    const rules = {
      niger: "Aspergillus Niger",
      terreus: "Aspergillus Terreus",
      flavus: "Aspergillus Flavus",
      fumigatus: "Aspergillus Fumigatus",
    };
    const analyteKey = Object.keys(rules).find((key) =>
      title.toLowerCase().includes(key)
    );
    const analyte = rules[analyteKey];

    const headers = initialHeaders.split(/\t/);
    const rows = initialRows.map((r) => {
      const rr = r.split(/\t/);
      return rr.reduce(
        (a, c, i) => ({
          ...a,
          [headers[i]?.trim() || "UNKNOWN"]: c?.trim(),
        }),
        {}
      );
    });
    return rows
      .map((row) => ({
        sessionId: this.extractSessionId(row.Name),
        type: "MICRO BMX GENEUP",
        result: { [analyte]: row.Target },
      }))
      .filter((x) => !!x.sessionId);
  }

  static parseMicroBmxGenupSalmonella(input) {
    const data = input
      .slice(input.indexOf("\n") + 1)
      .split("\n")
      .map((element) => element.replace(/(\r\n|\n|\r)/gm, ""));

    const isMicro3M = data[0].split(",")[1] === "3M";

    const rows = isMicro3M
      ? data.reduce((acc, curr) => {
          const separated = curr.split(",");
          const obj = separated.reduce((accSeparated, currSeparated, index) => {
            if (index === 0) {
              accSeparated.sessionId = currSeparated;
            }
            if (index === 1) {
              accSeparated.type = "MICRO 3M ENUM";
            }
            if (index === 2) {
              accSeparated.result = currSeparated;
            }
            return accSeparated;
          }, {});
          acc.push(obj);
          return acc;
        }, [])
      : data.reduce((acc, curr) => {
          const separated = curr.split(",");
          const obj = separated.reduce((accSeparated, currSeparated, index) => {
            if (index === 0) {
              accSeparated.sessionId = currSeparated;
            }
            if (index === 10) {
              accSeparated.element = currSeparated;
            }
            if (index === 23) {
              accSeparated.result = currSeparated;
            }
            return accSeparated;
          }, {});
          acc.push(obj);
          return acc;
        }, []);

    if (isMicro3M) {
      return rows.filter((x) => !!x.sessionId);
    }

    // salmonella results use different strings than other BMX GENUP result files
    // convert to Presumed Absence or Pesumed Presence
    return rows
      .map((row) => {
        const { sessionId, element, result } = row;
        const parsedSessionId = this.extractSessionId(sessionId);
        let parsedResult = result;
        if (result?.toLowerCase()?.trim() === "negative") {
          parsedResult = "Presumed Absence";
        }
        if (result?.toLowerCase()?.trim() === "positive") {
          parsedResult = "Presumed Presence";
        }
        if (result?.toLowerCase()?.trim() === "success") {
          if (/^[PC_]/i.test(parsedSessionId)) {
            parsedResult = "Presumed Presence";
          }
          if (/^[NC_]/i.test(parsedSessionId)) {
            parsedResult = "Presumed Absence";
          }
        }
        if (result?.toLowerCase()?.trim() === "failure") {
          if (/^[PC_]/i.test(parsedSessionId)) {
            parsedResult = "Presumed Absence";
          }
          if (/^[NC_]/i.test(parsedSessionId)) {
            parsedResult = "Presumed Presence";
          }
        }
        return {
          sessionId: parsedSessionId,
          type: "MICRO BMX GENEUP",
          result: { [element]: parsedResult },
        };
      })
      .filter((x) => !!x.sessionId);
  }

  static parseMicroBmxTempo(input) {
    const [rawHeaders, ...rawRows] = input.replace(/['"]+/g, "").split(/\n/);
    const headers = rawHeaders.split(/;/);
    const rows = rawRows.map((row) => {
      const arr = row?.split(/;/);
      return arr.reduce(
        (acc, curr, i) => ({
          ...acc,
          [headers[i]]: curr,
        }),
        {}
      );
    });
    return rows
      .map((row) => ({
        sessionId: this.extractSessionId(row.Sample),
        type: "MICRO BMX TEMPO",
        result: { "Pathogenic e. coli": row.Result?.trim() },
      }))
      .filter((x) => !!x.sessionId);
  }

  static parse(input) {
    try {
      const flag = input.charAt(0);
      // CANN
      // SSI LC PEST MYCO
      if (flag === "I") {
        return this.parseLiquidChromatography(input, true);
      }
      // AGILENT LC PEST MYCO
      if (flag === "S" && input.startsWith("SampleName")) {
        return this.parseLiquidChromatography(input, false);
      }
      // GC PEST
      // TERP
      // SOLV
      if (flag === "[") return this.parseGasChromatography(input);
      // METAL
      if (flag === "B")
        return this.parseInductivelyCoupledPlasmaMassSpectrometry(input);
      // MICRO MG ENUM and MICRO MG INC
      if (flag === "W") {
        return this.parseMicroMG(input);
      }
      // MICRO BMX GENEUP (for AZ all four aspergillus)
      if (flag === "E") {
        return this.parseMicroBmxGenupAspergillus(input);
      }
      // MICRO BMX GENEUP (only for AZ salmonella stand alone)
      // MICRO 3M ENUM (one analyte per SessionID)
      if (flag === "S" && !input.startsWith("SampleName")) {
        return this.parseMicroBmxGenupSalmonella(input);
      }
      // MICRO BMX TEMPO (AZ E. Coli only)
      if (flag === '"') {
        return this.parseMicroBmxTempo(input);
      }
      throw new ParserError("Unable to determine machine type");
    } catch (err) {
      if (err instanceof ParserError) throw err;
      return err;
    }
  }
}
