import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Control, Label } from "rbx";
import Select from "react-select";
import { useQuery } from "@apollo/client";

import { ALL_SYSTEM_CODES_QUERY } from "../../graphql";

const SystemCodeMultiSelect = ({
  value,
  name,
  label,
  onChange,
  disabled,
  getValue,
  required,
  where,
}) => {
  const { data: getSystemCodeData } = useQuery(ALL_SYSTEM_CODES_QUERY, {
    variables: { where, orderBy: { CodeDescription: "asc" } },
  });

  const [options, setOptions] = useState([]);

  const systemCodes = getSystemCodeData?.findManySystemCodes;

  useEffect(() => {
    const op = systemCodes?.map((code) => ({
      label: code.CodeDescription,
      value: code.RecId,
    }));
    setOptions(op?.filter((item) => !value.includes(item.value)));
  }, [systemCodes, value]);

  const handleChange = async (e) => {
    onChange(name, e);
  };

  return (
    <Control>
      <Label>{label}</Label>
      <Select
        isMulti
        isClearable={false}
        isDisabled={disabled}
        isLoading={!Array.isArray(systemCodes)}
        name={name}
        options={options}
        required={required}
        styles={{
          indicatorsContainer: (provided) => ({
            ...provided,
            height: "inherit",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          container: (provided) => ({
            fontSize: 13,
          }),
          singleValue: (provided) => ({
            ...provided,
            top: "38%",
          }),
          control: (provided) => ({
            ...provided,
            width: "100%",
            minWidth: 180,
            minHeight: "2.20em",
            textTransform: "capitalize",
            height:
              Array.isArray(getValue(value)) && value.length > 0
                ? "auto"
                : "2.20em",
            border:
              required && Array.isArray(getValue(value)) && value.length === 0
                ? "1px #e63362 solid"
                : "",
            borderRadius: "4px",
            boxShadow:
              required && Array.isArray(getValue(value)) && value.length === 0
                ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
                : "",
            "&:hover": {
              borderColor: "hsl(0, 0%, 90%)",
              borderRadius: 4,
              cursor: disabled ? "not-allowed" : "pointer",
            },
          }),
          placeholder: (provided) => ({ ...provided, top: "38%" }),
          menu: (provided) => ({
            ...provided,
            zIndex: 999,
            textTransform: "capitalize",
          }),
        }}
        value={value}
        onChange={handleChange}
      />
    </Control>
  );
};
SystemCodeMultiSelect.propTypes = {
  value: PropTypes.array,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  required: PropTypes.bool,
  where: PropTypes.object,
};

SystemCodeMultiSelect.defaultProps = {
  value: [],
  name: "",
  label: "",
  required: false,
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  where: {},
};

export default SystemCodeMultiSelect;
