import React from "react";
import PropTypes from "prop-types";
import { Control, Select } from "rbx";
import { useQuery } from "@apollo/client";

import { ALL_VERIFY_HEMP_CATEGORIES_QUERY } from "../../graphql";

const VerifyHempCategorySelect = ({
  size,
  name,
  onChange,
  disabled,
  value,
  required,
  id,
  fullWidth,
  where,
  legacyValue,
}) => {
  const { data: getVerifyHempCategoriesData } = useQuery(
    ALL_VERIFY_HEMP_CATEGORIES_QUERY,
    {
      variables: { where, orderBy: { CategoryID: "asc" } },
    }
  );

  const categories =
    getVerifyHempCategoriesData?.findManyVerifyHempCategories || [];

  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  const handleChange = (e) => {
    onChange(e.target.name, e.target.value ? parseInt(e.target.value, 10) : "");
  };

  return (
    <Control expanded size={size}>
      <Select.Container fullwidth size={size}>
        <Select
          disabled={disabled}
          id={id}
          name={name}
          required={required}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={value || ""}
          onChange={handleChange}
        >
          <Select.Option value="">{legacyValue}</Select.Option>
          {categories.map((item) => (
            <Select.Option key={item.CategoryID} value={item.CategoryID}>
              {item.Name}
            </Select.Option>
          ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

VerifyHempCategorySelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  name: PropTypes.string,

  size: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  where: PropTypes.object,
  legacyValue: PropTypes.string,
};

VerifyHempCategorySelect.defaultProps = {
  size: "small",
  fullWidth: false,
  disabled: false,
  value: "",
  name: "",
  required: false,
  id: null,
  where: {},
  onChange: () => null,
  legacyValue: "",
};

export default VerifyHempCategorySelect;
