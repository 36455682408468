import React from "react";
import { Generic, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DEFAULT_COLUMNS = [
  {
    Header: "Name",
    id: "Name",
    accessor: "Name",
  },
  {
    Header: "Value",
    id: "Value",
    accessor: "Value",
  },
  {
    Header: "Lab Name",
    id: "Lab Name",
    accessor: "Lab.Company",
    disableSortBy: true,
  }
];

export const generateColumns = (handleDelete, canUpdate) => [
  ...DEFAULT_COLUMNS,
  {
    Header: "",
    id: "delete",
    Cell: (cell) => (
      canUpdate && (
        <Generic
          style={{ textAlign: "center" }}
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(cell.row.original);
          }}
        >
          <Icon color="danger">
            <FontAwesomeIcon icon="times" />
          </Icon>
        </Generic>
      )
    ),
  },
];

export default DEFAULT_COLUMNS;
