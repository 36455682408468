import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { useModal } from "../../../context/ModalContext";
import { LIST_LABS_QUERY } from "../../../graphql";
import COLUMNS from "./columns";
import AddLabModal from "../components/AddLabModal";
import { PageHeader, IconButton, DataTable } from "../../../components";

const LabListPage = ({ routePermissions }) => {
  const where = {};
  const history = useHistory();
  const { setModalOpen } = useModal();
  const orderBy = [{ id: "Company", desc: false }];

  const [canUpdate, setCanUpdate] = useState(true);

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  const handleRowClick = (labItem) => {
    history.push(`/labs/lab/${labItem.LabID}/account-details`);
  };

  const handleCreateLab = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModalOpen(
      true,
      <AddLabModal
        onComplete={() => {
          setModalOpen(false);
        }}
      />
    );
  };

  return (
    <div className="lab-list-page">
      <PageHeader title="Labs">
        {canUpdate && <IconButton icon="plus" onClick={handleCreateLab} />}
      </PageHeader>
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateLabs"
        columns={COLUMNS}
        name="findManyLabs"
        orderBy={orderBy}
        query={LIST_LABS_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

LabListPage.propTypes = {
  routePermissions: PropTypes.array,
};

LabListPage.defaultProps = {
  routePermissions: [],
};

LabListPage.propTypes = {};

export default LabListPage;
