import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Title, Fieldset, Button } from "rbx";
import {
  customToast as toast,
  convertInputToVariables,
} from "../../../../utils";
import EmployeeForm from "../EmployeeForm";
import { CREATE_USER_MUTATION, LIST_USERS_QUERY } from "../../../../graphql";

const DEFAULT_STATE = {
  Username: "",
  FirstName: "",
  LastName: "",
  AccountType: "2",
  Email: "",
  Phone: "",
  Status: 1,
  Address: "",
  Address2: "",
  City: "",
  State: "",
  Zip: "",
  ProfilePic: "",
  LabID: "",
  isPrivateLabel: "",
  Password: "",
};

const AddEmployeeModal = ({ onComplete, loggedUserLabID }) => {
  const [createEmployee] = useMutation(CREATE_USER_MUTATION);

  const [inputs, setInputs] = useState({
    ...DEFAULT_STATE,
  });

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (loggedUserLabID !== 9999) {
      setInputs((prev) => ({
        ...prev,
        LabID: loggedUserLabID,
      }));
    }
  }, [loggedUserLabID]);

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const LabID = parseInt(inputs.LabID, 10);
      const createData = {
        ...convertInputToVariables(inputs, Object.keys(DEFAULT_STATE), true),
        Type: {
          connect: {
            RecId: parseInt(inputs.AccountType, 10),
          },
        },
        Lab: {
          connect: {
            LabID,
          },
        },
        isPrivateLabel: 0,
      };
      delete createData.AccountType;
      delete createData.LabID;
      const { data: createEmployeeData } = await createEmployee({
        variables: {
          data: createData,
        },
        refetchQueries: [
          {
            query: LIST_USERS_QUERY,
            variables: {
              where: { Type: { is: { CodeId: { in: ["1", "2", "3"] } } } },
            },
          },
        ],
      });
      if (createEmployeeData?.createUsers?.UserID) {
        toast.success("Employee created successfully.");
        onComplete(createEmployeeData.createUsers.UserID);
      }
    } catch (err) {
      toast.error("Error creating Employee.");
    }
  };

  return (
    <React.Fragment>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Create Employee</Title>
        </div>
        <div className="page-head-end">
          <Button.Group>
            <Button
              color="primary"
              size="small"
              type="button"
              onClick={() => onComplete()}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={inputs.Password !== inputs.ConfirmPassword}
              form="add-employee-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Fieldset>
        <EmployeeForm
          adding
          formId="add-employee-form"
          inputs={inputs}
          isLabDisabled={loggedUserLabID !== 9999}
          onChange={handleChange}
          onSubmit={handleSave}
        />
      </Fieldset>
      <hr />
    </React.Fragment>
  );
};

AddEmployeeModal.propTypes = {
  onComplete: PropTypes.func,
  loggedUserLabID: PropTypes.number.isRequired,
};

AddEmployeeModal.defaultProps = {
  onComplete: (e) => e,
};

export default AddEmployeeModal;
