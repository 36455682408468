import React, { useState, useEffect } from "react";
import { Button, Box, Control, Title, Field } from "rbx";
import { useApolloClient } from "@apollo/client";
import PropTypes from "prop-types";

import Loader from "../../../../../components/Loader";
import { useModal, useAuth } from "../../../../../context";
import { PageHeader, ListSearchInput } from "../../../../../components";
import SamplePrepModal from "../components/SamplePrepModal";
import { customToast as toast } from "../../../../../utils";
import { ALL_BATCHES_EXTENDED_QUERY } from "../../../../../graphql";

const SamplePrepPage = ({ routePermissions }) => {
  const { state: authState } = useAuth();
  const { setModalOpen } = useModal();

  const client = useApolloClient();

  const [sessionId, setSessionId] = useState("");
  const [loading, setLoading] = useState(false);
  const [canUpdate, setCanUpdate] = useState(true);

  useEffect(() => {
    if (
      routePermissions.length &&
      !routePermissions.find((item) => item.Update)
    ) {
      setCanUpdate(false);
    }
  }, [routePermissions]);

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!sessionId.length) {
        throw Error("Session ID is required.");
      }

      const {
        data: { findAllBatchesExtended },
      } = await client.query({
        query: ALL_BATCHES_EXTENDED_QUERY,
        variables: {
          where: {
            criteria: sessionId,
          },
        },
        fetchPolicy: "network-only",
      });
      if (!findAllBatchesExtended.length) {
        throw Error("Session ID not found");
      }

      const differentLabOnBatches = findAllBatchesExtended.find(
        (batch) => batch.LabID !== authState?.user?.LabID
      );

      if (differentLabOnBatches) {
        throw Error("This sample belongs to a different lab");
      }

      setModalOpen(
        true,
        <SamplePrepModal
          batchesData={findAllBatchesExtended}
          disabled={!canUpdate}
          sessionId={sessionId}
          userID={authState.user.UserID}
          onComplete={() => setModalOpen(false)}
        />
      );
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="sample-prep-page">
      <PageHeader title="Sample Prep" />
      <div style={{ justifyContent: "center", display: "flex" }}>
        <Box style={{ width: "50%", textAlign: "center" }}>
          <Control style={{ marginBottom: 10 }}>
            <Title subtitle>Search by Session ID</Title>
            <ListSearchInput
              placeholder="Enter Session ID..."
              onChange={(name, value) => {
                setSessionId(value);
              }}
            />
          </Control>
          <Field kind="group" style={{ justifyContent: "center" }}>
            <Control>
              <Button color="primary" onClick={handleSearch}>
                Search
              </Button>
            </Control>
          </Field>
        </Box>
      </div>
    </div>
  );
};

SamplePrepPage.propTypes = {
  routePermissions: PropTypes.array,
};

SamplePrepPage.defaultProps = {
  routePermissions: [],
};

export default SamplePrepPage;
