/* eslint-disable react/no-array-index-key */
import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Column, Generic, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Loader } from "../../../../../../components";
import {
  BatchResultHeaderColumn,
  BatchResultSampleColumn,
} from "../../components";
import "../../BatchRecords.scss";

const Results = ({
  batchResults,
  BatchID,
  tests,
  onChange,
  onChangeStatusOfAll,
  onClickQualifiers,
  showQualifiers,
  disabled,
  labState,
  batchResultsTemplate,
}) => {
  const dividerRefs = useRef([]);
  const onDividerClick = (i) => {
    dividerRefs.current[i].current.scrollIntoView({
      block: "center",
    });
    dividerRefs.current[i + 2]?.current?.scrollIntoView({
      block: "center",
    });
  };

  if (!batchResultsTemplate || !batchResultsTemplate.length) {
    return <Loader />;
  }

  dividerRefs.current = batchResultsTemplate[0].singleResultTemplate.map(() =>
    React.createRef()
  );

  const allApproved = batchResults.filter(
    (result) =>
      result.Status === "Approved" &&
      result.OriginalStatus !== "Approved" &&
      result.needSave
  );

  const allRejected = batchResults.filter(
    (result) =>
      result.Status === "Rejected" &&
      result.OriginalStatus !== "Rejected" &&
      result.needSave
  );

  const availableBatchSamples = batchResultsTemplate
    .filter(
      ({ sample, missingRequiredField }) =>
        ((sample?.JobOrderTestSample?.JobOrder &&
          sample?.JobOrderTestSample?.JobOrderTestSampleID) ||
          sample?.QCSample?.QCSampleID) &&
        !missingRequiredField &&
        sample.Result &&
        sample.OriginalStatus !== "Approved" &&
        sample.OriginalStatus !== "Rejected"
    )
    .map(({ sample }) => sample.SessionID);

  const columnsWidth =
    (batchResultsTemplate?.length < 3
      ? batchResultsTemplate.length + 2
      : batchResultsTemplate.length + 1) * 300;

  return (
    <React.Fragment>
      <Generic id="batch-results-container">
        <Column.Group
          className="result-columns"
          style={{ width: columnsWidth }}
        >
          <BatchResultHeaderColumn
            approveAllValue={
              !!(
                availableBatchSamples.length &&
                availableBatchSamples.length === allApproved.length
              )
            }
            disabled={!availableBatchSamples.length || disabled}
            dividerRefs={dividerRefs}
            isMicro={batchResultsTemplate[0].isMicro}
            labState={labState}
            rejectAllValue={
              !!(
                availableBatchSamples.length &&
                availableBatchSamples.length === allRejected.length
              )
            }
            showQualifiers={showQualifiers}
            singleResultTemplate={batchResultsTemplate[0].singleResultTemplate}
            onChangeStatusOfAll={(status) =>
              onChangeStatusOfAll(status, availableBatchSamples)
            }
            onClickQualifiers={onClickQualifiers}
            onDividerClick={onDividerClick}
          />
          {batchResultsTemplate.map(
            (
              {
                singleResultTemplate,
                missingRequiredField,
                includesFailedAnalyte,
                includesQualifierAlert,
                hasTargetValues,
                isMicro,
                sample,
                originalIndex,
                columnClass,
              },
              columnIndex
            ) => (
              <Column
                key={`result-columns-${columnIndex}`}
                className={`results-column ${columnClass}`}
                size={2}
                style={columnIndex === 0 ? { paddingLeft: "1rem" } : null}
              >
                <BatchResultSampleColumn
                  isFullView
                  BatchID={BatchID}
                  batchResults={batchResults}
                  disabled={disabled}
                  hasTargetValues={hasTargetValues}
                  includesFailedAnalyte={includesFailedAnalyte}
                  includesQualifierAlert={includesQualifierAlert}
                  isMicro={isMicro}
                  labState={labState}
                  missingRequiredField={missingRequiredField}
                  originalIndex={originalIndex}
                  sample={sample}
                  showQualifiers={showQualifiers}
                  template={singleResultTemplate}
                  tests={tests}
                  onChange={onChange}
                />
              </Column>
            )
          )}
        </Column.Group>
      </Generic>
      <br />
      <div>* Sample Specific Target Value</div>
      <div>
        <Icon color="danger" size="small">
          <FontAwesomeIcon icon="exclamation" />
        </Icon>
        Failed Result
      </div>
      <div>
        <Icon color="warning" size="small">
          <FontAwesomeIcon icon="exclamation-triangle" />
        </Icon>
        Qualifier Alert
      </div>
    </React.Fragment>
  );
};

Results.propTypes = {
  batchResults: PropTypes.array,
  onChange: PropTypes.func,
  onChangeStatusOfAll: PropTypes.func,
  BatchID: PropTypes.number.isRequired,
  tests: PropTypes.array,
  onClickQualifiers: PropTypes.func.isRequired,
  showQualifiers: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  labState: PropTypes.string,
  batchResultsTemplate: PropTypes.array.isRequired,
};

Results.defaultProps = {
  batchResults: [],
  onChange: () => null,
  onChangeStatusOfAll: () => null,
  tests: [],
  labState: "",
};

export default Results;
