import { gql } from "@apollo/client";

export const DELETE_LAB_TEST_MUTATION = gql`
  mutation DELETE_LAB_TEST_MUTATION($where: LabTestsWhereUniqueInput!) {
    deleteLabTests(where: $where) {
      LabTestID
    }
  }
`;

export const CREATE_LAB_TEST_MUTATION = gql`
  mutation CREATE_LAB_TEST_MUTATION($data: LabTestsCreateInput!) {
    createLabTests(data: $data) {
      LabTestID
    }
  }
`;

export const UPDATE_TEST_MUTATION = gql`
  mutation UPDATE_TEST_MUTATION(
    $data: TestsUpdateInput!
    $where: TestsWhereUniqueInput!
  ) {
    updateTests(data: $data, where: $where) {
      TestID
    }
  }
`;

export const UPDATE_LAB_TEST_MUTATION = gql`
  mutation UPDATE_LAB_TEST_MUTATION(
    $data: LabTestsUpdateInput!
    $where: LabTestsWhereUniqueInput!
  ) {
    updateLabTests(data: $data, where: $where) {
      LabTestID
    }
  }
`;

export const CREATE_TEST_MUTATION = gql`
  mutation CREATE_TEST_MUTATION($data: TestsCreateInput!) {
    createTests(data: $data) {
      TestID
    }
  }
`;

export const UPDATE_USER_TEST_MUTATION = gql`
  mutation UPDATE_USER_TEST_MUTATION(
    $data: UserTestsUpdateInput!
    $where: UserTestsWhereUniqueInput!
  ) {
    updateUserTests(data: $data, where: $where) {
      UserTestID
      UserID
      UserPrice
    }
  }
`;

export const UPDATE_MANY_USER_TESTS_MUTATION = gql`
  mutation UPDATE_MANY_USER_TESTS_MUTATION(
    $data: UserTestsUpdateManyMutationInput!
    $where: UserTestsWhereInput
  ) {
    updateManyUserTests(data: $data, where: $where) {
      count
    }
  }
`;


export const CREATE_DIFFERENT_USER_TESTS = gql`
  mutation CREATE_DIFFERENT_USER_TESTS(
    $data: UserTestInput!
  ) {
    createDifferentUserTests(data: $data)
  }
`;

export const CREATE_MANY_USER_TESTS_MUTATION = gql`
  mutation CREATE_MANY_USER_TESTS_MUTATION($data: [UserTestsCreateManyInput!]!) {
    createManyUserTests(data: $data) {
      count
    }
  }
`;