/* eslint-disable eqeqeq */
/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import {
  Column,
  Field,
  Input,
  Label,
  Control,
  Textarea,
  Box,
  Title,
  Icon,
  Select,
} from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SINGLE_USER_QUERY } from "../../../../graphql";
import { useAuth } from "../../../../context";
import { customToast as toast } from "../../../../utils";
import {
  SystemCodeSelect,
  CultivatorsMultiSelect,
  MMTCInfoSelect,
  BooleanInput,
  FileUploader,
} from "../../../../components";
import NewTestTabs from "../NewTestTabs";

const NewTestForm = ({
  inputs,
  onChange,
  disabled,
  adding,
  history,
  tab,
  tests,
  onChangeTest,
  selectedPackage,
  onPackageChange,
  setInputs,
  substanceTypes,
  uploadCOAPath,
  fixedSubstanceType,
}) => {
  const { state: authState } = useAuth();

  const [labelClaimNotApplicable, setLabelClaimNotApplicable] = useState(
    () =>
      !!inputs.CartID &&
      Object.keys(inputs.LabelClaim).every(
        (key) => inputs.LabelClaim[key] === ""
      )
  );
  const [user, setUser] = useState({});

  const MJType = substanceTypes.find((type) => type.CodeDescription === "MJ");
  const HempType = substanceTypes.find(
    (type) => type.CodeDescription === "Hemp"
  );

  const { data: getUserData } = useQuery(SINGLE_USER_QUERY, {
    variables: {
      where: {
        UserID: parseInt(authState.user.UserID, 10),
      },
    },
  });

  useEffect(() => {
    if (getUserData?.findUniqueUsers) {
      setUser(getUserData.findUniqueUsers);
    }
  }, [getUserData]);

  const handleLabelClaimNotApplicableChange = (value) => {
    setLabelClaimNotApplicable(value);
    setInputs((prev) => ({
      ...prev,
      LabelClaim: {
        LabelClaimTHC: "",
        LabelClaimUnitTHC: "",
        LabelClaimCBD: "",
        LabelClaimUnitCBD: "",
      },
    }));
  };

  return (
    <div className="new-test-form">
      <Column.Group multiline>
        <Column size={12} tablet={{ size: 12 }}>
          <Column.Group multiline>
            <Column mobile={{ size: 12 }} size={3}>
              <Control expanded size="small">
                <Label>Order Name</Label>
                <Input
                  required
                  disabled={disabled}
                  maxLength={250}
                  name="OrderName"
                  placeholder="Enter order name here"
                  size="small"
                  type="text"
                  value={inputs.OrderName}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                />
              </Control>
            </Column>
            <Column mobile={{ size: 12 }} size={3}>
              <Control expanded size="small">
                <Label>Retail Batch Number</Label>
                <Input
                  required
                  disabled={disabled}
                  maxLength={250}
                  name="BatchOrderNumber"
                  placeholder="Enter your purchase order number here"
                  size="small"
                  type="text"
                  value={inputs.BatchOrderNumber}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                />
              </Control>
            </Column>
            <Column
              mobile={{ size: 12 }}
              size={
                Number(inputs.SubstanceType) === Number(MJType?.RecId) ? 2 : 3
              }
            >
              <Control expanded size="small">
                <Label style={{ display: "flex" }}>
                  Substance Type
                  {fixedSubstanceType && (
                    <Icon
                      className="has-tooltip-top has-tooltip-arrow has-tooltip-warning has-tooltip-text-centered"
                      color="warning"
                      data-tooltip={`To add a new ${
                        Number(inputs.SubstanceType) === Number(MJType?.RecId)
                          ? "Hemp"
                          : "MJ"
                      } test, please complete
the checkout process for any ${
                        Number(inputs.SubstanceType) === Number(MJType?.RecId)
                          ? "MJ"
                          : "Hemp"
                      } tests.`}
                      size="small"
                      style={{ paddingLeft: "0.5rem" }}
                    >
                      <FontAwesomeIcon icon="info-circle" />
                    </Icon>
                  )}
                </Label>
                <SystemCodeSelect
                  required
                  disabled={fixedSubstanceType || disabled}
                  name="SubstanceType"
                  size="small"
                  value={inputs.SubstanceType}
                  where={{
                    Type: { equals: "Substance" },
                    Category: { equals: "Type" },
                    CodeName: { equals: "SubstanceType" },
                  }}
                  onChange={onChange}
                />
              </Control>
            </Column>
            <Column
              mobile={{ size: 12 }}
              size={
                Number(inputs.SubstanceType) === Number(MJType?.RecId) ? 2 : 3
              }
            >
              <Control expanded size="small">
                <SystemCodeSelect
                  required
                  disabled={disabled}
                  label="Product Category"
                  name="ProductCategory"
                  size="small"
                  value={inputs.ProductCategory}
                  where={{
                    Type: { equals: "Product" },
                    Category: { equals: "Category" },
                    CodeName: { equals: "ProductCategory" },
                    Active: { equals: 1 },
                  }}
                  onChange={onChange}
                />
              </Control>
            </Column>
            {Number(inputs.SubstanceType) === Number(MJType?.RecId) && (
              <Column mobile={{ size: 12 }} size={2}>
                <Control expanded size="small">
                  <SystemCodeSelect
                    required
                    disabled={disabled}
                    label="Species"
                    name="Species"
                    size="small"
                    value={inputs.Species}
                    where={{
                      Type: { equals: "Species" },
                      Category: { equals: "Type" },
                      CodeName: { equals: "SpeciesType" },
                    }}
                    onChange={onChange}
                  />
                </Control>
              </Column>
            )}
          </Column.Group>
          <Column.Group>
            {Number(inputs.SubstanceType) !== Number(HempType?.RecId) && (
              <React.Fragment>
                <Column mobile={{ size: 12 }} size={inputs.StateRetest ? 6 : 2}>
                  <Field kind="group">
                    <Control expanded size="small">
                      <Label>Is this a State Required Retest?</Label>
                      <BooleanInput
                        name="StateRetest"
                        value={inputs.StateRetest}
                        onChange={(name, value) => onChange(name, value)}
                      />
                    </Control>
                    {inputs.StateRetest && (
                      <Control expanded>
                        <FileUploader
                          disabled={disabled}
                          label="Original COA"
                          name="OriginalCOA"
                          uploadPath={uploadCOAPath}
                          value={inputs.OriginalCOA}
                          onChange={(name, value) => onChange(name, value)}
                        />
                      </Control>
                    )}
                  </Field>
                </Column>
              </React.Fragment>
            )}
            <Column mobile={{ size: 12 }} size={2}>
              <Control expanded size="small">
                <Label style={{ height: 19 }}>Label Claim Not Applicable</Label>
                <BooleanInput
                  name="labelClaimNotApplicable"
                  value={labelClaimNotApplicable}
                  onChange={(name, value) =>
                    handleLabelClaimNotApplicableChange(value)
                  }
                />
              </Control>
            </Column>
            {!labelClaimNotApplicable && (
              <React.Fragment>
                <Column mobile={{ size: 12 }} size={inputs.StateRetest ? 2 : 4}>
                  <Control expanded size="small">
                    <Label style={{ height: 19 }}>
                      Label Claim and Unit (THC)
                    </Label>
                    <Field.Body>
                      <Field kind="addons">
                        <Control expanded>
                          <Input
                            required
                            disabled={disabled}
                            maxLength={250}
                            name="LabelClaim.LabelClaimTHC"
                            placeholder="Enter label claim"
                            size="small"
                            type="number"
                            value={inputs.LabelClaim.LabelClaimTHC}
                            onChange={(e) =>
                              onChange(e.target.name, e.target.value)
                            }
                          />
                        </Control>
                        <Control>
                          <SystemCodeSelect
                            required
                            disabled={disabled}
                            name="LabelClaim.LabelClaimUnitTHC"
                            size="small"
                            value={inputs.LabelClaim.LabelClaimUnitTHC}
                            where={{
                              Type: { equals: "Product" },
                              Category: { equals: "Unit" },
                              CodeName: { equals: "ProductUnit" },
                            }}
                            onChange={onChange}
                          />
                        </Control>
                      </Field>
                    </Field.Body>
                  </Control>
                </Column>
                <Column mobile={{ size: 12 }} size={inputs.StateRetest ? 2 : 4}>
                  <Control expanded size="small">
                    <Label style={{ height: 19 }}>
                      Label Claim and Unit (CBD)
                    </Label>
                    <Field.Body>
                      <Field kind="addons">
                        <Control expanded>
                          <Input
                            required
                            disabled={disabled}
                            maxLength={250}
                            name="LabelClaim.LabelClaimCBD"
                            placeholder="Enter label claim"
                            size="small"
                            type="number"
                            value={inputs.LabelClaim.LabelClaimCBD}
                            onChange={(e) =>
                              onChange(e.target.name, e.target.value)
                            }
                          />
                        </Control>
                        <Control>
                          <SystemCodeSelect
                            required
                            disabled={disabled}
                            name="LabelClaim.LabelClaimUnitCBD"
                            size="small"
                            value={inputs.LabelClaim.LabelClaimUnitCBD}
                            where={{
                              Type: { equals: "Product" },
                              Category: { equals: "Unit" },
                              CodeName: { equals: "ProductUnit" },
                            }}
                            onChange={onChange}
                          />
                        </Control>
                      </Field>
                    </Field.Body>
                  </Control>
                </Column>
              </React.Fragment>
            )}
          </Column.Group>
          {inputs.SubstanceType &&
          Number(inputs.SubstanceType) === Number(MJType?.RecId) ? (
            <React.Fragment>
              <Column.Group multiline>
                <Column mobile={{ size: 12 }} size={4}>
                  <Control expanded size="small">
                    <SystemCodeSelect
                      required
                      disabled={disabled}
                      label="Test Type"
                      name="TestType"
                      size="small"
                      value={inputs.TestType}
                      where={{
                        Type: { equals: "Test" },
                        Category: { equals: "Type" },
                        CodeName: { equals: "TestType" },
                      }}
                      onChange={onChange}
                    />
                  </Control>
                </Column>
                <Column mobile={{ size: 12 }} size={4}>
                  <Control expanded size="small">
                    <Label>Harvest/Lot ID</Label>
                    <Input
                      required
                      disabled={disabled}
                      maxLength={250}
                      name="CustomerSampleID"
                      placeholder="Enter a Harvest/Lot ID"
                      size="small"
                      type="text"
                      value={inputs.CustomerSampleID}
                      onChange={(e) => onChange(e.target.name, e.target.value)}
                    />
                  </Control>
                </Column>
                <Column mobile={{ size: 12 }} size={4}>
                  <Control expanded size="small">
                    <Label>Seed-to-Sale #</Label>
                    <Input
                      disabled={disabled}
                      maxLength={250}
                      name="LotID"
                      placeholder="Enter Seed-to-Sale #"
                      size="small"
                      type="text"
                      value={inputs.LotID}
                      onChange={(e) => onChange(e.target.name, e.target.value)}
                    />
                  </Control>
                </Column>
              </Column.Group>
              <Column.Group multiline>
                <Column
                  desktop={{ size: 6 }}
                  mobile={{ size: 12 }}
                  size={6}
                  tablet={{ size: 12 }}
                >
                  <Box>
                    <Title size={5}>Client Information</Title>
                    <Column.Group multiline style={{ display: "flex" }}>
                      <Column mobile={{ size: 12 }} size={4}>
                        <Control expanded>
                          <MMTCInfoSelect
                            required
                            disabled={disabled}
                            itemName="Cultivation Facility"
                            label="Cultivation Facility"
                            name="MMTCInfo.CultivationFacility"
                            size="small"
                            user={user}
                            value={inputs.MMTCInfo.CultivationFacility}
                            onChange={onChange}
                          />
                        </Control>
                      </Column>
                      <Column mobile={{ size: 12 }} size={4}>
                        <Control expanded>
                          <MMTCInfoSelect
                            required
                            disabled={disabled}
                            itemName="Processing Facility"
                            label="Processing Facility"
                            name="MMTCInfo.ProcessingFacility"
                            size="small"
                            user={user}
                            value={inputs.MMTCInfo.ProcessingFacility}
                            onChange={onChange}
                          />
                        </Control>
                      </Column>
                      <Column mobile={{ size: 12 }} size={4}>
                        <Control>
                          <Label>Cultivars</Label>
                          <CultivatorsMultiSelect
                            required
                            disabled={disabled}
                            label="Cultivars"
                            maxSelected={10}
                            name="MMTCInfo.Cultivators"
                            placeHolder="Cultivars"
                            user={user}
                            value={inputs.MMTCInfo.Cultivators}
                            onChange={onChange}
                          />
                        </Control>
                      </Column>
                    </Column.Group>
                  </Box>
                </Column>
                <Column
                  desktop={{ size: 6 }}
                  mobile={{ size: 12 }}
                  size={6}
                  tablet={{ size: 12 }}
                >
                  <Box>
                    <Title size={5}>Batch Information</Title>
                    <Column.Group multiline style={{ display: "flex" }}>
                      <Column mobile={{ size: 12 }} size={4}>
                        <Control expanded>
                          <Label>Batch ID</Label>
                          <Input
                            required
                            disabled={disabled}
                            maxLength={50}
                            name="BatchInfo.ID"
                            size="small"
                            type="text"
                            value={inputs.BatchInfo.ID}
                            onChange={(e) =>
                              onChange(e.target.name, e.target.value)
                            }
                          />
                        </Control>
                      </Column>
                      <Column mobile={{ size: 6 }} size={4}>
                        <Control expanded>
                          <Label>Batch Date</Label>
                          <Input
                            required
                            disabled={disabled}
                            maxLength={50}
                            name="BatchInfo.Date"
                            size="small"
                            type="date"
                            value={inputs.BatchInfo.Date}
                            onBlur={(e) => {
                              if (
                                e.target.value &&
                                inputs.BatchInfo.ExpDate &&
                                e.target.value > inputs.BatchInfo.ExpDate
                              ) {
                                toast.error(
                                  "Expiration Date should be after Batch Date"
                                );
                              }
                            }}
                            onChange={(e) =>
                              onChange(e.target.name, e.target.value)
                            }
                          />
                        </Control>
                      </Column>
                      <Column mobile={{ size: 6 }} size={4}>
                        <Control expanded>
                          <Label>Exp. Date</Label>
                          <Input
                            required
                            disabled={disabled}
                            maxLength={50}
                            name="BatchInfo.ExpDate"
                            size="small"
                            type="date"
                            value={inputs.BatchInfo.ExpDate}
                            onBlur={(e) => {
                              if (
                                e.target.value &&
                                inputs.BatchInfo.Date &&
                                e.target.value < inputs.BatchInfo.Date
                              ) {
                                toast.error(
                                  "Expiration Date should be after Batch Date"
                                );
                              }
                            }}
                            onChange={(e) =>
                              onChange(e.target.name, e.target.value)
                            }
                          />
                        </Control>
                      </Column>
                      <Column mobile={{ size: 6 }} size={4}>
                        <Field>
                          <Label style={{ width: "fit-content" }}>Size</Label>
                          <Field.Body>
                            <Field kind="addons">
                              <Control expanded>
                                <Input
                                  required
                                  disabled={disabled}
                                  maxLength={50}
                                  name="BatchInfo.Size"
                                  size="small"
                                  type="number"
                                  value={inputs.BatchInfo.Size}
                                  onChange={(e) =>
                                    onChange(e.target.name, e.target.value)
                                  }
                                />
                              </Control>
                              <Control>
                                <Select.Container size="small">
                                  <Select
                                    required
                                    disabled={disabled}
                                    name="BatchInfo.BatchSizeUnit"
                                    value={inputs.BatchInfo.BatchSizeUnit}
                                    onChange={(e) =>
                                      onChange(e.target.name, e.target.value)
                                    }
                                  >
                                    <Select.Option value="g">g</Select.Option>
                                    <Select.Option value="lb">lb</Select.Option>
                                  </Select>
                                </Select.Container>
                              </Control>
                            </Field>
                          </Field.Body>
                        </Field>
                      </Column>
                      <Column mobile={{ size: 6 }} size={4}>
                        <Control expanded>
                          <Label>Total Qty</Label>
                          <Input
                            required
                            disabled={disabled}
                            maxLength={50}
                            name="BatchInfo.Qty"
                            size="small"
                            type="number"
                            value={inputs.BatchInfo.Qty}
                            onChange={(e) =>
                              onChange(e.target.name, e.target.value)
                            }
                          />
                        </Control>
                      </Column>
                    </Column.Group>
                  </Box>
                </Column>
              </Column.Group>
            </React.Fragment>
          ) : null}
          {inputs.SubstanceType &&
          Number(inputs.SubstanceType) === Number(HempType?.RecId) ? (
            <Column.Group multiline>
              <Column mobile={{ size: 12 }} size={6}>
                <Box>
                  <Title size={5}>Batch Information</Title>
                  <Column.Group multiline style={{ display: "flex" }}>
                    <Column mobile={{ size: 12 }} size={4}>
                      <Control expanded>
                        <Label>Batch ID</Label>
                        <Input
                          disabled={disabled}
                          maxLength={50}
                          name="BatchInfo.ID"
                          size="small"
                          type="text"
                          value={inputs.BatchInfo.ID}
                          onChange={(e) =>
                            onChange(e.target.name, e.target.value)
                          }
                        />
                      </Control>
                    </Column>
                    <Column mobile={{ size: 6 }} size={4}>
                      <Control expanded>
                        <Label>Batch. Date</Label>
                        <Input
                          disabled={disabled}
                          maxLength={50}
                          name="BatchInfo.Date"
                          size="small"
                          type="date"
                          value={inputs.BatchInfo.Date}
                          onBlur={(e) => {
                            if (
                              e.target.value &&
                              inputs.BatchInfo.ExpDate &&
                              e.target.value > inputs.BatchInfo.ExpDate
                            ) {
                              toast.error(
                                "Expiration Date should be after Batch Date"
                              );
                            }
                          }}
                          onChange={(e) =>
                            onChange(e.target.name, e.target.value)
                          }
                        />
                      </Control>
                    </Column>
                    <Column mobile={{ size: 6 }} size={4}>
                      <Control expanded>
                        <Label>Exp. Date</Label>
                        <Input
                          disabled={disabled}
                          maxLength={50}
                          name="BatchInfo.ExpDate"
                          size="small"
                          type="date"
                          value={inputs.BatchInfo.ExpDate}
                          onBlur={(e) => {
                            if (
                              e.target.value &&
                              inputs.BatchInfo.Date &&
                              e.target.value < inputs.BatchInfo.Date
                            ) {
                              toast.error(
                                "Expiration Date should be after Batch Date"
                              );
                            }
                          }}
                          onChange={(e) =>
                            onChange(e.target.name, e.target.value)
                          }
                        />
                      </Control>
                    </Column>
                    <Column mobile={{ size: 6 }} size={4}>
                      <Field>
                        <Label style={{ width: "fit-content" }}>Size</Label>
                        <Field.Body>
                          <Field kind="addons">
                            <Control expanded>
                              <Input
                                disabled={disabled}
                                maxLength={50}
                                name="BatchInfo.Size"
                                size="small"
                                type="number"
                                value={inputs.BatchInfo.Size}
                                onChange={(e) =>
                                  onChange(e.target.name, e.target.value)
                                }
                              />
                            </Control>
                            <Control>
                              <Select.Container size="small">
                                <Select
                                  disabled={disabled}
                                  name="BatchInfo.Unit"
                                  value={inputs.BatchInfo.Unit}
                                  onChange={(e) =>
                                    onChange(e.target.name, e.target.value)
                                  }
                                >
                                  <Select.Option value="g">g</Select.Option>
                                  <Select.Option value="lb">lb</Select.Option>
                                </Select>
                              </Select.Container>
                            </Control>
                          </Field>
                        </Field.Body>
                      </Field>
                    </Column>
                    <Column mobile={{ size: 6 }} size={4}>
                      <Control expanded>
                        <Label>Total Qty</Label>
                        <Input
                          disabled={disabled}
                          maxLength={50}
                          name="BatchInfo.Qty"
                          size="small"
                          type="number"
                          value={inputs.BatchInfo.Qty}
                          onChange={(e) =>
                            onChange(e.target.name, e.target.value)
                          }
                        />
                      </Control>
                    </Column>
                  </Column.Group>
                </Box>
              </Column>
            </Column.Group>
          ) : null}
        </Column>
        <Column size={12} tablet={{ size: 12 }}>
          <NewTestTabs
            history={history}
            inputs={inputs}
            selectedPackage={selectedPackage}
            tab={tab}
            tests={tests}
            onChange={onChangeTest}
            onPackageChange={onPackageChange}
          />
        </Column>
        <Column size={12}>
          <Field>
            <Control expanded size="small">
              <Label>Special Notes for this Order:</Label>
              <Textarea
                disabled={disabled}
                name="ClientNotes"
                size="small"
                value={inputs.ClientNotes}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
        </Column>
      </Column.Group>
    </div>
  );
};

NewTestForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  adding: PropTypes.bool,
  tab: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  onChangeTest: PropTypes.func.isRequired,
  tests: PropTypes.array.isRequired,
  selectedPackage: PropTypes.object,
  onPackageChange: PropTypes.func.isRequired,
  setInputs: PropTypes.func.isRequired,
  substanceTypes: PropTypes.array.isRequired,
  uploadCOAPath: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  fixedSubstanceType: PropTypes.bool,
};

NewTestForm.defaultProps = {
  disabled: false,
  adding: false,
  selectedPackage: null,
  uploadCOAPath: () => {},
  fixedSubstanceType: false,
};

export default NewTestForm;
